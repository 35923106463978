/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';

import MenuIcon from '@material-ui/icons/Menu';
import { connect } from 'react-redux';
import LayoutBarMenu from './LayoutBarMenu';

const renderContent = ( logo, organizationname, almacen) => (
  <img
    src={`${almacen ? (almacen.image || logo) : logo}`}
    alt={almacen ? almacen.direccion : organizationname}
    style={{ width: '125px' }}
    className="align-self-center"
  />
);

const renderRightIcon = classes => (<LayoutBarMenu classes={classes} />);

const renderLeftIcon = (classes, dashboardMenuIsDisplayed, closeDashboardMenu, openDashboardMenu) => (
  <IconButton
    color="inherit"
    aria-label="Open drawer"
    onClick={
      dashboardMenuIsDisplayed
        ? closeDashboardMenu
        : openDashboardMenu
    }
    className={classes.menuButton}
  >
    <MenuIcon />
  </IconButton>
);

const LayoutBar = ({
  classes,
  closeDashboardMenu,
  dashboardMenuIsDisplayed,
  history,
  logoname,
  openDashboardMenu,
  organizationname,
  almacen,
}) => (
  <AppBar
    className={`${classNames(classes.appBar)} box_shadow_none positionRelative`}
    variant="static"
  >
    <Toolbar className="px-0">
      <div className={`${classes.grow} d-flex justify-content-between`}>
        {renderLeftIcon(classes, dashboardMenuIsDisplayed, closeDashboardMenu, openDashboardMenu, history)}
        {renderContent(logoname, organizationname, almacen)}
        {renderRightIcon(classes)}
      </div>
    </Toolbar>
    
  </AppBar>
);

LayoutBar.propTypes = {
  logoname: PropTypes.string,
  classes: PropTypes.object.isRequired,
  almacen: PropTypes.object.isRequired,
  organizationname: PropTypes.string.isRequired,
  openDashboardMenu: PropTypes.func.isRequired,
  closeDashboardMenu: PropTypes.func.isRequired,
  dashboardMenuIsDisplayed: PropTypes.bool.isRequired,
};

LayoutBar.defaultProps = {
  logoname: `${process.env.PUBLIC_URL}/img/logo.svg`,
};
export default connect()(LayoutBar);
