/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import '../../../ClientPage/components/SalesDetail/SalesDetail.scss';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDolly,
  faFileInvoice,
  faShareAlt,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Iconwidthbg from '../../../../components/Icons/IconWidthBG/IconWidthBG';
import { getColorStatusFDE, getColorStatusMDA } from '../../../../helpers/tools';
import ShareModal from '../../../../components/Share/components/ShareModal';
import appModules from '../../../../constant/appModules';
import { useHistory } from 'react-router';

const WPurchaseExpand = ({ purchase, openShare, orderReceiver }) => {
  const userName = `${purchase.assigned_user_data.first_name} ${purchase.assigned_user_data.last_name}`;
  const history = useHistory();

  return (
    <>
      <List component="nav" aria-label="main mailbox folders" dense>
        <ListItem className="pt-0">
          <ListItemText
            className="ml-2 text-left pt-0"
            primary={purchase.vendor_id_label}
            secondary={moment(purchase.createdtime).format('DD MMM, h:mm a')}
            classes={{
              primary: 'salesDetail-text-primary', // class name, e.g. `classes-nesting-root-x`
              secondary: 'salesDetail-text-secondary', // class name, e.g. `classes-nesting-label-x`
            }}
          />
        </ListItem>
        <ListItem selected classes={{ selected: 'salesDetail-list-selected' }}>
          <ListItemText className="ml-2 text-left" primary="Creado:" classes={{ primary: 'salesDetail-list-text-left' }} />
          <ListItemText className="p-0 text-right" primary={purchase.estado_fde} classes={{ primary: 'salesDetail-list-text-right' }} />
          <Iconwidthbg
            primaryColor={getColorStatusFDE(purchase.estado_fde)}
            secondaryColor="white"
            width={27}
            height={27}
            classes="ml-2"
            enabled
            square
            rounded
            actionClick={() => {}}
          >
            <FontAwesomeIcon style={{ fontSize: 15 }} icon={faFileInvoice} />
          </Iconwidthbg>
        </ListItem>

        <ListItem selected classes={{ selected: 'salesDetail-list-selected' }}>
          <ListItemText className="ml-2 text-left" primary="Estado de almacen:" classes={{ primary: 'salesDetail-list-text-left' }} />
          <ListItemText className="p-0 text-right" primary="Sin entregar" classes={{ primary: 'salesDetail-list-text-right' }} />
          <Iconwidthbg
            primaryColor={getColorStatusMDA(purchase.estado_mda)}
            secondaryColor="white"
            width={27}
            height={27}
            classes="ml-2"
            enabled
            square
            rounded
            actionClick={() => {}}
          >
            <FontAwesomeIcon style={{ fontSize: 15 }} icon={faDolly} />
          </Iconwidthbg>
        </ListItem>

        {/* <ListItem selected classes={{ selected: 'salesDetail-list-selected' }}>
          <ListItemText className="ml-2 text-left" primary="Lotes:" classes={{ primary: 'salesDetail-list-text-left' }} />
          <ListItemText className="p-0 text-right" primary="Sin crear" classes={{ primary: 'salesDetail-list-text-right' }} />
          <Iconwidthbg
            primaryColor={geteColorSosStatus('Approved')}
            secondaryColor="white"
            width={27}
            height={27}
            classes="ml-2"
            enabled
            square
            rounded
            actionClick={() => {}}
          >
            <FontAwesomeIcon style={{ fontSize: 15 }} icon={faHashtag} />
          </Iconwidthbg>
        </ListItem> */}

        <ListItem selected classes={{ selected: 'salesDetail-list-selected' }}>
          <ListItemText className="ml-2 text-left" primary="Asignado a:" classes={{ primary: 'salesDetail-list-text-left' }} />
          <ListItemText className="p-0 text-right" primary={userName} classes={{ primary: 'salesDetail-list-text-right' }} />
        </ListItem>

      </List>
      <List component="nav" aria-label="secondary mailbox folders" className="d-flex justify-content-around">
        <button
          type="button"
          onClick={() => openShare(purchase)}
          style={{
            backgroundColor: '#23A1DB', color: 'white', fontSize: 15, padding: '10px 20px', borderRadius: 17, border: 0,
          }}
        >
          <FontAwesomeIcon style={{ fontSize: 15 }} icon={faShareAlt} />
        </button>
        <button
          type="button"
          onClick={() => history.push(`/almacenes/recibir/${purchase.crmid}`)}
          style={{
            backgroundColor: '#23A1DB', color: 'white', fontSize: 15, padding: '10px 20px', borderRadius: 17, border: 0,
          }}
        >
          Recibir
        </button>
      </List>

    </>
  ); };
WPurchaseExpand.propTypes = {
  purchase: PropTypes.object.isRequired,
  openShare: PropTypes.func.isRequired,
  orderReceiver: PropTypes.func.isRequired,
};
export default WPurchaseExpand;
