/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileInvoice, faHandHoldingUsd, faDolly,
} from '@fortawesome/free-solid-svg-icons';
import {
  LinearProgress,
  Collapse,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import FabBtn from './components/FabBtn';
import NewExpenseDialog from './components/NewExpenseDialog';
import DetailExpenseDialog from './components/DetailExpenseDialog';
import Iconwidthbg from '../../components/Icons/IconWidthBG/IconWidthBG';
import ListItemGeneric from '../ClientPage/components/ListItemGeneric/ListItemGeneric';
import {
  formatMoney, geteColorSosStatus, getColorStatusMDA, getColorStatusFDE, getColorStatusSAT,
} from '../../helpers/tools';
import { fetchListExpenses } from '../../redux/actions/expensesActions';
import SatLogo from '../../components/Icons/SatLogo';


const Expenses = ({ history }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [openDetail, setOpenDetail] = React.useState(false);
  const loads = useSelector(state => state.loads);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenDetail = (id) => {
    // setOpenDetail(true);
    history.push(`/expenses/${id}`);
  };

  const handleCloseDetail = () => {
    setOpenDetail(false);
  };

  useEffect(() => {
    dispatch(fetchListExpenses(1, '', 0, false));
    // eslint-disable-next-line
  }, []);

  const gastos = useSelector(state => state.gastos.listaGastos);

  return (
    <div>
      {!loads.updateIsLoading && gastos.data.length > 0 && (
        gastos.data.map(item => (
          <div onClick={() => handleClickOpenDetail(item.crmid)} key={item.crmid}>
            <ListItemGeneric
              key={item.crmid}
              titleLeft={item.entity_label}
              subTitleLeft={moment(item.modifiedtime).format('DD MMM, h:mm a')}
              titleRight={`$${formatMoney(item.total)}`}
              subTitleRight={(
                <>
                  <Iconwidthbg
                    primaryColor={getColorStatusSAT(item.estadosat)}
                    height={27}
                    width={27}
                    classes="pr-2 pl-3"
                    square
                    rounded
                    enabled
                    actionClick={() => {}}
                  >
                    <SatLogo fill={`${item.estadosat === null ? '#B6BEC6' : 'white'}`} width="16px" height="16px" />
                  </Iconwidthbg>

                  <Iconwidthbg
                    primaryColor={getColorStatusMDA(item.estado_mda)}
                    secondaryColor={`${item.estado_mda === 'Sin Entregar' ? '#B6BEC6' : 'white'}`}
                    width={27}
                    height={27}
                    classes="ml-2"
                    enabled
                    square
                    rounded
                    actionClick={() => {}}
                  >
                    <FontAwesomeIcon style={{ fontSize: 15 }} icon={faDolly} />
                  </Iconwidthbg>

                  <Iconwidthbg
                    primaryColor={getColorStatusFDE(item.estado_fde)}
                    secondaryColor={`${item.estado_fde === 'Sin Pago/Cobro' ? '#B6BEC6' : 'white'}`}
                    width={27}
                    height={27}
                    classes="ml-2"
                    enabled
                    square
                    rounded
                    actionClick={() => {}}
                  >
                    <FontAwesomeIcon style={{ fontSize: 15 }} icon={faHandHoldingUsd} />
                  </Iconwidthbg>

                  <Iconwidthbg
                    primaryColor={geteColorSosStatus(item.sostatus)}
                    secondaryColor={`${item.sostatus === 'Created' ? '#B6BEC6' : 'white'}`}
                    width={27}
                    height={27}
                    classes="ml-2"
                    enabled
                    square
                    rounded
                    actionClick={() => {}}
                  >
                    <FontAwesomeIcon style={{ fontSize: 15 }} icon={faFileInvoice} />
                  </Iconwidthbg>

                </>
                  )}
            />
          </div>
        ))
      )}
      { loads.updateIsLoading && (
        <LinearProgress />
      )}
      <Collapse in={gastos.data.length === 0}>
        <List dense>
          <ListItem classes={{ root: 'bg-white' }}>
            <ListItemText
              primary="Sin gastos"
              secondary="No tiene gastos registrados"
            />
          </ListItem>
        </List>
      </Collapse>

      <DetailExpenseDialog open={openDetail} handleClose={handleCloseDetail} />
      <NewExpenseDialog open={open} handleClose={handleClose} />
      <FabBtn clickAction={handleClickOpen} />
    </div>

  );
};

export default withRouter(Expenses);
