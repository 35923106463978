/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import List from '@material-ui/core/List';
import { fetchAccountContactsPhones } from '../../../../../../redux/actions/accountActions';
import Phone from './components/phone';
import Whatsap from './components/whatsap';
import EmptyContact from './components/emptyContact';

const PhoneList = withRouter(({ match, action }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAccountContactsPhones(match.params.crmid));
    // eslint-disable-next-line
  }, []);

  const contact = useSelector(state => state.account.contact.phone);

  return (
    <List classes={{ root: 'py-0' }}>
      {
        (action === 'phone' && contact)
          ? (
            contact.length ? contact.map(item => (<Phone contact={item} key={item} />)) : (<EmptyContact action="phone" />)
          ) : null
      }
      {
        (action === 'whatsapp' && contact)
          ? (
            contact.length ? contact.map(item => (<Whatsap contact={item} key={item} />)) : (<EmptyContact action="whatsapp" />)
          ) : null
      }
    </List>
  ); });
PhoneList.prototype = {
  action: PropTypes.string,
};
export default PhoneList;
