import React from 'react';
import PropTypes from 'prop-types';
import './mda.scss';
import ScrollPaginator from '../../ScrollPaginator/ScrollPaginator';
import MdaModal from '../modal';
import ListMda from '../components/ListMda';
import ListMdaClient from '../components/ListMdaClient';
import { fetchListMda } from '../../../redux/actions/financeActions';

const Mda = ({
  dispatchCloseDiscountModal,
  dispatchFetchMda,
  mda,
  clientsMda,
  client,
  loading,
  modals,
  orderSelected,
  gasto,
  type,
}) => (
  <div>
    {type === 'modal' && (
    <MdaModal
      mda={mda}
      fetchMda={dispatchFetchMda}
      isOpen={Boolean(modals.mdaModalIsOpen)}
      loading={loading}
      onClose={dispatchCloseDiscountModal}
      orderSelected={orderSelected}
    />
    )}
    {type === 'clients' && (
      <div className="px-2 bg-white">
        <ScrollPaginator
          allItems={clientsMda.data}
          totalPages={clientsMda.pages}
          itemsPerPage={25}
          currentPage={1}
          childComponent={<ListMdaClient />}
          action={fetchListMda}
          clientId={client.crmid || ''}
        />
      </div>
    )}
    {type === 'gastos' && (
      <div className="px-2 bg-white">
        <ScrollPaginator
          allItems={clientsMda.data}
          totalPages={clientsMda.pages}
          itemsPerPage={25}
          currentPage={1}
          childComponent={<ListMdaClient />}
          action={fetchListMda}
          clientId={gasto ? gasto.crmid : ''}
        />
      </div>
    )}
    {type === 'list' && (
      <ListMda
        isOpen
        mda={mda}
        fetchMda={dispatchFetchMda}
        isLoading={loading}
      />
    )}
  </div>
);

Mda.propTypes = {
  dispatchCloseDiscountModal: PropTypes.func.isRequired,
  dispatchFetchMda: PropTypes.func.isRequired,
  mda: PropTypes.array.isRequired,
  clientsMda: PropTypes.object,
  client: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  modals: PropTypes.object.isRequired,
  gasto: PropTypes.object.isRequired,
  orderSelected: PropTypes.object.isRequired,
  type: PropTypes.string,
};

Mda.defaultProps = {
  type: 'modal',
  client: {},
  clientsMda: {},
  /* gasto: {}, */
};

export default Mda;
