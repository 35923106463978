/* eslint-disable react/prop-types */
/* eslint-disable import/no-cycle */
import React from 'react';
import './activity-detail.styles.scss';
import { useHistory } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft, faEllipsisV,
} from '@fortawesome/free-solid-svg-icons';
import Swip from '../../../../components/Swip';
import DrawerTop from '../../../ClientPage/components/DrawerTop/DrawerTop';
import Activity from '../Activity';
import ComingSoon from '../../../../components/ComingSoon/ComingSoon';
import List from '../../../../components/Files';
import CountUpTimer from '../../../../components/CountUp';
import TrackingTask from '../TrackingTask';
import { Typography } from '@material-ui/core';

const ActivityDetail = ({ open, handleClose }) => {
  const history = useHistory();
  const [openDrawer, setOpenDrawerBar] = React.useState(false);

  const handleSetOpenDrawerBarrOpen = () => {
    setOpenDrawerBar(true);
  };
  const handleSetOpenDrawerBarClose = () => {
    setOpenDrawerBar(false);
  };

  const tabs = [
    {
      icon: 'fas fas fa-info-circle',
      renderComponent: <Activity />,
      title: 'Actividad',
    },
    {
      icon: 'far far fa-clock',
      renderComponent: <TrackingTask />,
      title: 'Control de tiempo',
    },
    {
      icon: 'fas fas fa-file',
      renderComponent: <List type="task" />,
      title: 'Documentos',
    },
  ];

  return (
    <div>
      <AppBar className="appBar" color="inherit" style={{ position: 'relative' }}>
        <Toolbar variant="dense" disableGutters elevation={0} classes={{ root: 'toolbar-container d-flex justify-space-between' }}>
          <div className="d-flex justify-content-start align-items-center">
            <IconButton edge="start" onClick={() => history.goBack()} aria-label="close">
              <FontAwesomeIcon style={{ fontSize: 16 }} icon={faChevronLeft} />
            </IconButton>
            <Typography variant="h6" style={{ color: '#64707A' }}>
              Actividades
            </Typography>
          </div>
          <IconButton
            color="inherit"
            size="medium"
            onClick={() => {}}
          >
            <FontAwesomeIcon className="font-size-18" style={{ width: 20 }} icon={faEllipsisV} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Swip tabs={tabs} />
      <DrawerTop onClose={handleSetOpenDrawerBarClose} open={openDrawer} />
    </div>

  ); };

export default ActivityDetail;
