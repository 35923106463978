import fetch from 'cross-fetch';
import ConfigurationAPI from './ConfigurationAPI';

class TaskApi extends ConfigurationAPI {

  // Get all tasks
  getAllTasks= async (relatedTo = 0, page = 1, search = '', sort = 'createdtime', order = 'DESC') => {
    try {
      const response = await fetch(`${this.url}/list/ProjectTask?page=${page}&search_col=${JSON.stringify(search)}&related_to=${relatedTo}&sort=${sort}&order=${order}`, this.credentials);
      const json = await response.json();
      return json;
    } catch {
      return { success: false, message: 'Error en el Host' };
    }
  }

  // Get all projects
  getAllProjects= async (relatedTo = 0, page = 1, search = '') => {
    this.credentials.method = 'GET';

    try {
      const response = await fetch(`${this.url}/list/Project?page=${page}&search=${search}&related_to=${relatedTo}`, this.credentials);
      const json = await response.json();
      return json;
    } catch {
      return { success: false, message: 'Error en el Host' };
    }
  }

  getAllUsers= async (page = 1, search = '') => {
    try {
      const response = await fetch(`${this.url}/all/Users?page=${page}&search=${search}`, this.credentials);
      const json = await response.json();
      return json;
    } catch {
      return { success: false, message: 'Error en el Host' };
    }
  }

  editTask = async (task) => {
    this.credentials.method = 'PUT';
    this.credentials.body = JSON.stringify(task);

    try {
      const response = await fetch(`${this.url}/ProjectTask`, this.credentials);
      const json = await response.json();
      return { ...json };
    }

    catch {
      return { succes: false, result: [], message: 'Error en la API' };
    }
  };

  createTask = async (task) => {
    this.credentials.method = 'POST';
    this.credentials.body = JSON.stringify(task);

    try {
      const response = await fetch(`${this.url}/ProjectTask`, this.credentials);
      const json = await response.json();
      return { ...json };
    }

    catch {
      return { succes: false, result: [], message: 'Error en la API' };
    }
  };

  // Get element by id
  getObjectById = async (id) => {
    try {
      const response = await fetch(`${this.url}/id/${id}`, this.credentials);
      const json = await response.json();
      return json;
    } catch {
      return { success: false, message: 'Error en el Host' };
    }
  }

  // Start task time
  startTaskTimeTracking = async (id) => {
    try {
      const response = await fetch(`${this.url}/TreebesControlTiempo/start/${id}`, this.credentials);
      const json = await response.json();
      return json;
    } catch {
      return { success: false, message: 'Error en el Host' };
    }
  }

  // Stop task time
  stopTaskTimeTracking = async () => {
    try {
      const response = await fetch(`${this.url}/TreebesControlTiempo/stop`, this.credentials);
      const json = await response.json();
      return json;
    } catch {
      return { success: false, message: 'Error en el Host' };
    }
  }

  // Stop task time
  stopTaskTimeTracking = async () => {
    try {
      const response = await fetch(`${this.url}/TreebesControlTiempo/stop`, this.credentials);
      const json = await response.json();
      return json;
    } catch {
      return { success: false, message: 'Error en el Host' };
    }
  }

  // Task control time
  getTaskTimeTracking = async (page = 0, search = '', relatedTo = 0) => {
    try {
      const response = await fetch(`${this.url}/list/TreebesControlTiempo?page=${page}&search=${search}&related_to=${relatedTo}`, this.credentials);
      const json = await response.json();
      return json;
    } catch {
      return { success: false, message: 'Error en el Host' };
    }
  }
}

export default TaskApi;
