import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import { ToastContainer } from 'react-toastify';
import { library } from '@fortawesome/fontawesome-svg-core';
import { createBrowserHistory } from 'history';
import {
  faCaretDown,
  faCartArrowDown,
  faCashRegister,
  faChevronLeft,
  faClipboardList,
  faCoins,
  faCreditCard,
  faEllipsisH,
  faEllipsisV,
  faFile,
  faFileImage,
  faFileInvoice,
  faFileInvoiceDollar,
  faFilePdf,
  faFileSignature,
  faFilter,
  faHandHoldingUsd,
  faInfo,
  faInfoCircle,
  faMinus,
  faMoneyBillWave,
  faMoneyCheck,
  faPaste,
  faPercent,
  faPlus,
  faSave,
  faSearch,
  faShoppingCart,
  faStore,
  faSync,
  faThLarge,
  faThList,
  faTrashAlt,
  faUser,
  faUserPlus,
  faUserTimes,
  faUserCog,
  faTimes,
  faWeightHanging,
  faCommentAlt,
  faDollarSign,
  faDolly,
  faShareAlt,
  faPencilAlt,
  faSquare,
  faCheckSquare,
  faComment,
  faSignInAlt,
  faSignOutAlt,
  faLink,
  faHome,
  faMapMarkerAlt,
  faMapMarker,
  faPenSquare,
  faCertificate,
  faTruck,
  faClipboardCheck,
  faBookmark,
  faCopy,
  faHandHolding,
  faMoneyCheckAlt,
  faPhoneAlt,
} from '@fortawesome/free-solid-svg-icons';


import '../../scss/app.scss';
import { isMobile } from 'react-device-detect';
// eslint-disable-next-line import/no-cycle
import Router from '../../router';
import store from '../../redux/store';
import ScrollToTop from '../../components/ScrollToTop';
import LoadComponent from '../../components/Load/LoadComponent';

export const history = createBrowserHistory();

library.add(
  faCaretDown,
  faCartArrowDown,
  faCashRegister,
  faChevronLeft,
  faClipboardList,
  faCoins,
  faCreditCard,
  faEllipsisH,
  faEllipsisV,
  faFile,
  faFileImage,
  faFileInvoice,
  faFileInvoice,
  faFileInvoiceDollar,
  faFilePdf,
  faFileSignature,
  faFilter,
  faHandHoldingUsd,
  faInfo,
  faInfoCircle,
  faMinus,
  faMoneyBillWave,
  faMoneyCheck,
  faPaste,
  faPercent,
  faPlus,
  faSave,
  faSearch,
  faShoppingCart,
  faStore,
  faSync,
  faThLarge,
  faThList,
  faTrashAlt,
  faUser,
  faUserPlus,
  faUserTimes,
  faUserCog,
  faTimes,
  faWeightHanging,
  faCommentAlt,
  faDollarSign,
  faDolly,
  faShareAlt,
  faPencilAlt,
  faSquare,
  faCheckSquare,
  faComment,
  faSignInAlt,
  faSignOutAlt,
  faLink,
  faHome,
  faMapMarkerAlt,
  faMapMarker,
  faPenSquare,
  faCertificate,
  faTruck,
  faClipboardCheck,
  faBookmark,
  faCopy,
  faHandHolding,
  faMoneyCheckAlt,
  faPhoneAlt,
);

const comerciaTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#23a1db',
      light: '#70bbfd',
      dark: '#009cff',
      contrastText: '#fff',
    },
    secondary: {
      main: '#8152a0',
      light: '#7f50a0',
      dark: '#8152a0',
      contrastText: '#fff',
    },
  },
  typography: {
    useNextVariants: true,
  },
});

class App extends Component {
  constructor() {
    super();
    this.state = {
      loaded: false,
    };
  }

  componentDidMount() {
    // eslint-disable-next-line no-unused-expressions
    !isMobile ? window.location.href = 'https://webapp.comercia.io' : null;
    window.addEventListener('load', () => {
      this.setState({ loaded: true });
    });
  }

  render() {
    const { loaded } = this.state;
    return (
      <Provider store={store}>
        <MuiThemeProvider theme={comerciaTheme}>
          <BrowserRouter basename="/">
            <ScrollToTop>
              <>
                { !loaded && <LoadComponent /> }
                <Router history={history} />
                <ToastContainer
                  position="bottom-left"
                  // autoClose={2500}
                  hideProgressBar={false}
                  newestOnTop={false}
                  rtl={false}
                  closeOnClick
                  pauseOnVisibilityChange
                  draggable
                  pauseOnHover
                />
              </>
            </ScrollToTop>
          </BrowserRouter>
        </MuiThemeProvider>
      </Provider>
    );
  }
}

export default App;
