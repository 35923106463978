/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import {
  Button,
  Checkbox, CircularProgress, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, List, ListItem, ListItemSecondaryAction, ListItemText, Paper, Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import groupBy from 'lodash/groupBy';
import { toast } from 'react-toastify';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MdaAPI from '../../../../api/MdaApi';
import { formatString } from '../../../../helpers/tools';
import DeliverProductList from './CancelProducList';

const CancelDeliver = () => {
  const mdaApi = new MdaAPI();
  const [loading, setLoading] = useState(false);
  const [delivers, setDelivers] = useState({});
  const [deliverSelected, setDeliverSelected] = useState([]);
  const authUser = useSelector(state => state.authUser);
  const { user: { config: { store } } } = authUser;

  const cancelToDeliver = async () => {
    setLoading(true);
    const response = await mdaApi.cancelToDeliver(store.crmid);
    const { success, result } = response;

    if (success && result.length) {
      let mdaMovement = [];
      for (const item of result) {
        mdaMovement = [...mdaMovement, ...item.movimientos];
      }

      const grouping = groupBy(mdaMovement, n => n.de_documentoid_label);
      setDelivers(grouping);
    }
    setLoading(false);
  };

  const selectStoreToCancel = (id) => {
    if (deliverSelected.includes(id)) {
      const items = deliverSelected.filter(item => item !== id);
      setDeliverSelected(items);
    } else {
      setDeliverSelected([...deliverSelected, id]);
    }
  };

  const cancelarEntrega = async () => {
    setLoading(true);
    const response = await mdaApi.cancelDeliverOrder(deliverSelected);

    const { success } = response;

    if (success) {
      toast.success('Entregas externas canceladas');
      cancelToDeliver();
    } else {
      toast.error('Error intentando cancelar las entregas externas');
    }
    setLoading(false);
  };

  useEffect(() => {
    if (store) {
      cancelToDeliver();
    }
  }, [store]);

  return (
    <div className="w-100 mt-2">
      <div className="w-100 d-flex justify-content-between align-items-center mt-4 mb-3">
        <h4>Entregas programadas</h4>
        <div>
          {
            loading ? <CircularProgress />
              : (
                <Button disabled={!deliverSelected.length} variant="contained" onClick={() => cancelarEntrega()}>
                  Cancelar
                </Button>
              )
          }
        </div>
      </div>
      <div>

        {
          !loading
            ? Object.keys(delivers).map(k => (
              <Paper className="mb-2">
                <div style={{ padding: 14 }}>
                  <h4>{formatString(k)}</h4>
                </div>
                {
                    delivers[k].map(item => (
                      <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography>{item.referencia}</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                          <DeliverProductList storeId={store.crmid} reference={item.referencia} deliverSelected={deliverSelected} selectStoreToCancel={selectStoreToCancel} />
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    ))
                  }
              </Paper>
            ))
            : <p>Verificando y actualizando entregas programadas</p>

        }
      </div>
    </div>
  );
};

export default CancelDeliver;
