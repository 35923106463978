import React from 'react';
import PropTypes from 'prop-types';
import { formatMoney } from '../../../../../../../helpers/tools';

const DetailFooterLong = ({ cart, discount }) => {
  const getTotal = (_discount, _cart) => {
    let { total } = _cart;
    if (_discount.haveDiscount) {
      total = (total - _discount.total).toFixed(2);
      total = Number.parseFloat(total);
    }
    return formatMoney(total);
  };

  const generalTotal = (Number.parseFloat(cart.total) - Number.parseFloat(getTotal(cart, discount)));
  const withDiscount = (Number.parseFloat(cart.total) - (discount.total).toFixed(2));
  const generalBalance = (withDiscount - Number.parseFloat(cart.pagado));
  return (
    <div className="w-100 d-flex flex-column py-1 px-2">
      <div className="w-100 d-flex justify-content-between aling-items-center">
        <span className="font-size-16">Sub-total:</span>
        <span className="font-size-16 text-right">
          {`$${formatMoney(cart.subTotal)}`}
        </span>
      </div>
      {
        cart.totalIEPS !== 0 && (
          <div className="w-100 d-flex justify-content-between aling-items-center">
            <span className="font-size-16">IEPS:</span>
            <span className="font-size-16 text-right">
              {`$${formatMoney(cart.totalIEPS)}`}
            </span>
          </div>
        )
      }
      {
        cart.totalISR !== 0 && (
          <div className="w-100 d-flex justify-content-between aling-items-center">
            <span className="font-size-16">ISR:</span>
            <span className="font-size-16 text-right">
              {`$${formatMoney(cart.totalISR)}`}
            </span>
          </div>
        )
      }
      {
        cart.totalIsrRet !== 0 && (
          <div className="w-100 d-flex justify-content-between aling-items-center">
            <span className="font-size-16">ISR-RET:</span>
            <span className="font-size-16 text-right">
              {`$ -${formatMoney(cart.totalIsrRet)}`}
            </span>
          </div>
        )
      }
      {
        cart.totalIvaRet !== 0 && (
          <div className="w-100 d-flex justify-content-between aling-items-center">
            <span className="font-size-16">IVA-RET:</span>
            <span className="font-size-16 text-right">
              {`$ -${formatMoney(cart.totalIvaRet)}`}
            </span>
          </div>
        )
      }
      <div className="w-100 d-flex justify-content-between aling-items-center">
        <span className="font-size-16">IVA:</span>
        <span className="font-size-16 text-right">
          {`$${formatMoney(cart.totalIva)}`}
        </span>
      </div>
      {discount.haveDiscount && (
        <div className="w-100 d-flex justify-content-between aling-items-center">
          <span className="font-size-16">{`Descuento: ${discount.name}`}</span>
          <span className="font-size-16 text-right">
            {` ${discount.fixed ? formatMoney(discount.fixed) : ''} ${(discount.percentage !== 0) ? (`${discount.percentage} %`) : ('')}`}
          </span>
        </div>
      )}
      <div className="w-100 d-flex justify-content-between aling-items-center">
        <span className="font-size-16">Balance:</span>
        <span className="font-size-16 text-right">
          {`$${formatMoney(cart.balance === 0 ? (generalTotal) : (generalBalance))}`}
        </span>
      </div>
      <div className="w-100 d-flex justify-content-between aling-items-center">
        <span className="font-size-16">Total:</span>
        <span
          style={{ fontFamily: 'Segoe UI', fontWeight: 'bold' }}
          className="font-size-20 text-right"
        >
          {`$ ${getTotal(discount, cart)}`}
        </span>
      </div>
    </div>
  );
};

DetailFooterLong.propTypes = {
  cart: PropTypes.object,
  discount: PropTypes.object,
};

DetailFooterLong.defaultProps = {
  cart: {},
  discount: {},
};

export default DetailFooterLong;
