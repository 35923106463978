/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import './CheckoutModal.scss';
/* Material */
import { SwipeableDrawer, Collapse, LinearProgress, Drawer } from '@material-ui/core';
import { DragHandle } from '@material-ui/icons';
/* Reatcstrap */
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
/* Device */
import { isMobile } from 'react-device-detect';

import CheckOutConstructor from './constructor';

const CheckOutModal = ({
  customer,
  cart,
  defaultOrderStatus,
  dispatchEditOrder,
  handleMakeBill,
  isLoading,
  isOpen,
  makeBill,
  onSave,
  orderStatuses,
  paymentTypes,
  posCheckoutBill,
  title,
  toggle,
  totalToPay,
  handleOpen,
  handleClose,
}) => (
  <>
    {isMobile ? (
      <Drawer
        anchor="top"
        open={isOpen}
        onClose={handleClose || toggle}
        onOpen={handleOpen || toggle}
        className="checkout_drawer"
      >
        <Collapse in={isLoading}>
          <LinearProgress />
        </Collapse>
        {!isLoading && (
          <div className="pt-1 w-100" />
        )}
        <Collapse in={!isLoading}>
          <CheckOutConstructor
            cart={cart}
            customer={customer}
            defaultOrderStatus={defaultOrderStatus}
            dispatchEditOrder={dispatchEditOrder}
            handleMakeBill={handleMakeBill}
            isLoading={isLoading}
            makeBill={makeBill}
            onSave={onSave}
            orderStatuses={orderStatuses}
            paymentTypes={paymentTypes}
            posCheckoutBill={posCheckoutBill}
            totalToPay={totalToPay}
          />
        </Collapse>
        {/* {!isLoading && (
          <div className="pt-1 w-100 d-flex align-items-end justify-content-center text-muted">
            <DragHandle />
          </div>
        )} */}
      </Drawer>
    ) : (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        sizer="lg"
        className="checkout_modal"
      >
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody className="modifix-modal">
          <Collapse in={isLoading}>
            <LinearProgress />
          </Collapse>
          <Collapse in={!isLoading}>
            <CheckOutConstructor
              cart={cart}
              customer={customer}
              defaultOrderStatus={defaultOrderStatus}
              dispatchEditOrder={dispatchEditOrder}
              handleMakeBill={handleMakeBill}
              isLoading={isLoading}
              makeBill={makeBill}
              onSave={onSave}
              orderStatuses={orderStatuses}
              paymentTypes={paymentTypes}
              posCheckoutBill={posCheckoutBill}
              totalToPay={totalToPay}
            />
          </Collapse>
        </ModalBody>
      </Modal>
    )}
  </>
);

CheckOutModal.defaultProps = {
  title: 'Cobro',
  isLoading: false,
  handleOpen: () => {},
  handleClose: () => {},
};

CheckOutModal.propTypes = {
  customer: PropTypes.object.isRequired,
  cart: PropTypes.object.isRequired,
  defaultOrderStatus: PropTypes.string.isRequired,
  dispatchEditOrder: PropTypes.func.isRequired,
  handleMakeBill: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  makeBill: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  orderStatuses: PropTypes.array.isRequired,
  paymentTypes: PropTypes.array.isRequired,
  posCheckoutBill: PropTypes.string.isRequired,
  title: PropTypes.any,
  toggle: PropTypes.func.isRequired,
  totalToPay: PropTypes.number.isRequired,
  handleOpen: PropTypes.func,
  handleClose: PropTypes.func,
};

export default CheckOutModal;
