import React from 'react';
import PropTypes from 'prop-types';
import './HeaderTitle.scss';

const HeaderTitle = ({ title }) => (
  <div className="headerTitle">
    {title}
  </div>
);

HeaderTitle.propTypes = {
  title: PropTypes.string,
};

HeaderTitle.defaultProps = {
  title: 'Titulo',
};

export default HeaderTitle;
