import React from 'react';
import PropTypes from 'prop-types';
import './ProductMobile.scss';

import ProductList from './components/ProductList';

const ProductMobile = ({
  product,
  type,
  loading,
  fetchAll,
  getNextPage,
  addCartProduct,
}) => (
  <div className="productMobile">
    {type === 'list' && (
      <ProductList
        product={product}
        loading={loading}
        fetchAll={fetchAll}
        getNextPage={getNextPage}
        addCartProduct={addCartProduct}
      />
    )}
  </div>
);

ProductMobile.propTypes = {
  product: PropTypes.object,
  type: PropTypes.string,
  loading: PropTypes.bool,
  fetchAll: PropTypes.func,
  getNextPage: PropTypes.func,
  addCartProduct: PropTypes.func,
};

ProductMobile.defaultProps = {
  product: {},
  type: 'list',
  loading: false,
  fetchAll: () => {},
  getNextPage: () => {},
  addCartProduct: () => {},
};

export default ProductMobile;
