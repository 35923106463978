import React from 'react';

const ChequeIcon = props => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    width="100px"
    height="100px"
    viewBox="0 0 100 100"
    enableBackground="new 0 0 100 100"
    xmlSpace="preserve"
    {...props}
  >
    <g>
      <path d="M48.413,44.67c-11.207,0-22.29,0-33.474,0c0,0.374,0,0.676,0,0.982c0,9.041-0.001,18.084,0.003,27.128
          c0.001,1.42,0.511,1.95,1.911,1.95c22.02,0.002,44.034,0.003,66.053,0c1.72,0,2.147-0.451,2.147-2.197
          c0.001-8.725,0-17.456,0-26.184c0-0.519,0-1.041,0-1.66c-0.313-0.014-0.583-0.037-0.854-0.037
          c-2.297-0.006-4.588,0.012-6.879-0.022c-0.268-0.003-0.659-0.207-0.77-0.43c-1.982-3.899-3.92-7.814-5.871-11.726
          c-0.026-0.043-0.024-0.101-0.045-0.225c0.221-0.016,0.437-0.048,0.647-0.048c4.017-0.003,8.028-0.008,12.039,0
          c3.836,0.007,6.675,2.845,6.676,6.67C90,50.206,90,61.541,89.995,72.875c-0.001,4.027-2.808,6.802-6.855,6.801
          c-22.122-0.002-44.244-0.002-66.366,0c-3.927,0-6.769-2.806-6.77-6.727C10,61.585,9.999,50.227,10,38.868
          c0.001-3.797,2.799-6.653,6.602-6.664c8.153-0.025,16.307-0.002,24.467-0.017c0.494-0.001,0.72,0.18,0.938,0.603
          c1.566,3.056,3.147,6.106,4.757,9.143C47.237,42.826,47.803,43.666,48.413,44.67z"
      />
      <path d="M57.018,38.303c2.953-1.682,5.88-3.353,8.875-5.059c0.672,1.293,1.323,2.53,1.956,3.779
          c3.908,7.703,7.578,15.519,10.357,23.707c0.736,2.18,1.193,4.465,1.712,6.71c0.164,0.717,0.293,1.645-0.45,2.078
          c-0.731,0.42-1.426-0.217-1.899-0.712c-1.63-1.713-3.316-3.402-4.741-5.278c-5.899-7.764-10.85-16.146-15.572-24.658
          C57.17,38.714,57.115,38.541,57.018,38.303z"
      />
      <path d="M64.1,30.221c-2.931,1.681-5.859,3.357-8.888,5.098c-0.048-0.094-0.096-0.185-0.138-0.275
          c-2.462-4.902-4.959-9.783-7.35-14.714c-0.689-1.417-1.136-2.973-0.849-4.611c0.511-2.931,4.581-4.495,6.756-2.504
          c1.122,1.02,2.121,2.254,2.886,3.557c2.553,4.321,4.977,8.714,7.449,13.083C64.011,29.94,64.038,30.043,64.1,30.221z"
      />
      <path d="M69.796,67.167c-3.951,0-7.773,0-11.597,0c-6.537-0.001-13.067-0.002-19.604-0.004c-0.997,0-1.074-0.077-1.077-1.064
          c-0.003-0.961,0.037-1.929-0.007-2.889c-0.029-0.748,0.271-1,1.008-0.998c7.966,0.022,15.933,0.016,23.899,0.015
          c1.039,0,2.085-0.024,3.124,0.008c0.253,0.006,0.604,0.091,0.738,0.268C67.445,63.997,68.573,65.526,69.796,67.167z"
      />
      <path d="M27.459,67.19c0,0.591-0.007,1.082,0.002,1.573c0.01,0.616-0.275,0.924-0.9,0.905c-0.234-0.007-0.469-0.003-0.703-0.003
          c-0.58,0.001-0.851-0.291-0.848-0.87c0.002-0.521,0.157-1.187-0.098-1.519c-0.244-0.319-0.94-0.276-1.426-0.424
          c-1.597-0.481-2.767-1.436-3.27-3.066c-0.261-0.852-0.076-1.089,0.808-1.102c0.391-0.003,0.784,0.036,1.171-0.004
          c0.69-0.059,1.173,0.098,1.473,0.818c0.14,0.334,0.556,0.629,0.918,0.788c1.2,0.532,2.433,0.49,3.629-0.042
          c0.58-0.254,1.007-0.672,0.998-1.362c-0.01-0.707-0.483-1.099-1.087-1.27c-1.121-0.32-2.278-0.53-3.395-0.86
          c-0.971-0.288-1.961-0.574-2.844-1.05c-2.279-1.239-2.605-4.311-0.593-5.968c0.772-0.635,1.817-0.943,2.755-1.369
          c0.275-0.12,0.602-0.121,0.966-0.192c0-0.579-0.001-1.122,0-1.666c0.001-0.509,0.261-0.773,0.765-0.789
          c0.288-0.007,0.576-0.003,0.859-0.004c0.547-0.002,0.808,0.273,0.817,0.818c0.006,0.545-0.171,1.243,0.102,1.589
          c0.259,0.342,0.992,0.302,1.504,0.458c1.54,0.465,2.671,1.377,3.167,2.96c0.277,0.907,0.107,1.139-0.824,1.147
          c-0.313,0.003-0.627-0.027-0.937,0.005c-0.775,0.07-1.423,0.004-1.74-0.899c-0.1-0.286-0.494-0.538-0.815-0.676
          c-1.257-0.575-2.531-0.508-3.772,0.06c-0.549,0.257-0.926,0.688-0.891,1.351c0.027,0.627,0.41,1.027,0.968,1.188
          c1.148,0.33,2.323,0.563,3.462,0.904c0.969,0.291,1.972,0.563,2.843,1.045c2.382,1.325,2.648,4.424,0.488,6.117
          c-0.744,0.588-1.749,0.849-2.651,1.23C28.105,67.118,27.804,67.124,27.459,67.19z"
      />
      <path d="M62.68,57.162c-0.775,0-1.415,0-2.055,0c-7.318-0.001-14.629-0.002-21.947-0.004c-1.098,0-1.152-0.056-1.156-1.146
          c-0.003-0.992,0.017-1.977-0.009-2.968c-0.012-0.605,0.259-0.838,0.847-0.837c6.951,0.014,13.902,0.005,20.854,0.021
          c0.179,0,0.447,0.073,0.529,0.199C60.711,53.956,61.655,55.502,62.68,57.162z"
      />
      <path d="M55.941,42.679c0.008,1.199-0.696,1.646-1.637,1.176c-1.57-0.784-2.49-2.192-3.276-3.67
          c-1.859-3.49-3.69-6.997-5.483-10.521c-0.981-1.922-1.875-3.887-2.765-5.855c-0.55-1.213,0.252-2.509,1.601-2.514
          c0.392-0.001,0.973,0.362,1.154,0.718c3.411,6.641,6.779,13.306,10.147,19.967C55.815,42.251,55.894,42.555,55.941,42.679z"
      />
    </g>
  </svg>
);

export default ChequeIcon;
