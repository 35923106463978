/* eslint-disable camelcase */
/* eslint-disable import/no-cycle */
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
/* Component */
import Customer from '../../../Customer/components/CreateCustomer';
/* Actions */
import { createCustomer } from '../../../../redux/actions/customerActions';

const mapStateToProps = ({
  loads,
  customer,
}) => ({
  loads,
  customer,
});

const mapDispatchToProps = dispatch => ({
  createCustomer: (customer, address = []) => dispatch(createCustomer(customer, address)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Customer));
