/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import './DetailInfo.scss';

import DetailProducts from './DetailProducts';
import DetailCustomer from './DetailCustomer';
import HeaderTitle from '../../../../../../HeaderTitle';
import ListLoading from '../../../../../../List/ListLoading';

const DetailInfo = ({
  cart,
  domain,
  editCustomerOrder,
  inputChangeQty,
  loading,
  onChangeQty,
  removeProduct,
  setOrderStatus,
  setRowDiscount,
  setComment,
  showCustomer,
  showTitle,
  sostatus,
  title,
}) => (
  <div className="detailInfo">
    {showTitle && (
      <HeaderTitle title={title} />
    )}
    {showCustomer && (
      <DetailCustomer
        selection={cart.orderSelected}
        loading={loading}
        sostatus={sostatus}
        setOrderStatus={setOrderStatus}
        editCustomerOrder={editCustomerOrder}
      />
    )}
    <ListLoading isLoading={loading} />
    <DetailProducts
      cart={cart}
      domain={domain}
      loading={loading}
      inputChangeQty={inputChangeQty}
      setRowDiscount={setRowDiscount}
      onChangeQty={onChangeQty}
      removeProduct={removeProduct}
      setComment={setComment}
    />
  </div>
);

DetailInfo.propTypes = {
  cart: PropTypes.object,
  domain: PropTypes.string,
  editCustomerOrder: PropTypes.func,
  inputChangeQty: PropTypes.func,
  loading: PropTypes.bool,
  onChangeQty: PropTypes.func,
  removeProduct: PropTypes.func,
  setOrderStatus: PropTypes.func,
  setRowDiscount: PropTypes.func,
  setComment: PropTypes.func,
  showCustomer: PropTypes.bool,
  showTitle: PropTypes.bool,
  sostatus: PropTypes.array,
  title: PropTypes.string,
};

DetailInfo.defaultProps = {
  cart: {},
  domain: 'Venta',
  editCustomerOrder: () => {},
  inputChangeQty: () => {},
  loading: false,
  onChangeQty: () => {},
  removeProduct: () => {},
  setOrderStatus: () => {},
  setRowDiscount: () => {},
  setComment: () => {},
  showCustomer: true,
  showTitle: true,
  sostatus: [],
  title: 'Venta',
};

export default DetailInfo;
