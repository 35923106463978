import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import CartFab from './components/CartFab';
import SearchFab from './components/SearchFab';
import FilterFab from './components/FilterFab';

const MobileActionsBar = ({
  cart,
  loading,
  authUser,
  location,
  setFilter,
  fetchOrders,
  dispatchClearCart,
  dispatchFilterCotizaciones,
  dispatchFilterOrders,
  dispatchFilterProducts,
}) => {
  const { pathname } = location;
  return (
    <div className="main_actions_container w-100 d-flex justify-content-between align-items-center px-2 sticky-top">
      <SearchFab
        cart={cart}
        dispatchFilterOrders={dispatchFilterOrders}
        dispatchFilterProducts={dispatchFilterProducts}
        dispatchFilterCotizaciones={dispatchFilterCotizaciones}
      />
      {pathname === '/pos/caja' && (
      <CartFab
        cart={cart}
        dispatchClearCart={dispatchClearCart}
      />
      )}
      {pathname === '/pos/venta' && (
      <FilterFab
        authUser={authUser}
        setFilter={setFilter}
        loading={loading}
        fetchOrders={fetchOrders}
      />
      )}
    </div>
  );
};

MobileActionsBar.propTypes = {
  cart: PropTypes.object.isRequired,
  authUser: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  setFilter: PropTypes.func.isRequired,
  fetchOrders: PropTypes.func.isRequired,
  dispatchClearCart: PropTypes.func.isRequired,
  dispatchFilterCotizaciones: PropTypes.func.isRequired,
  dispatchFilterOrders: PropTypes.func.isRequired,
  dispatchFilterProducts: PropTypes.func.isRequired,
};

export default withRouter(MobileActionsBar);
