/* eslint-disable react/prop-types */
/* eslint-disable import/no-cycle */
import React from 'react';
import './DialogExpenseDetail.scss';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft, faEllipsisV,
} from '@fortawesome/free-solid-svg-icons';
import Typography from '@material-ui/core/Typography';
import { useSelector, useDispatch } from 'react-redux';
import Divider from '@material-ui/core/Divider';
import { withRouter } from 'react-router-dom';
import ExpenseDetailForm from '../ExpenseDetailForm';
import Swip from '../../../../../../components/Swip';
import CashFlow from '../../../../../../components/Finance/CashFlow';
import EditExpenseDialog from '../../../EditExpenseDialog';
import ExpenseInvoiceContainer from '../ExpenseInvoiceContainer';
import List from '../../../../../../components/Files';
import ShareModal from '../../../../../../components/Share/components/ShareModal';
import TopMenuDrawer from '../../../../../../components/TopMenuDrawer';
import appModules from '../../../../../../constant/appModules';
import SatLogo from '../../../../../../components/Icons/SatLogo';
import TimbrarExpense from '../../../timbrarExpense';
import { openModal, closeModal } from '../../../../../../redux/actions/modalActions';

const Transition = React.forwardRef((props, ref) => <Slide direction="down" ref={ref} {...props} />);


const DialogExpenseDetail = ({ open, handleClose, match }) => {
  const dispatch = useDispatch();
  const [editDrawer, setEditDrawerBar] = React.useState(false);
  const [opt, setOpt] = React.useState(false);
  const [openShare, setOpenShare] = React.useState(false);
  const toggle = () => setOpt(!opt);

  const toggleEditDrawerBar = () => {
    setEditDrawerBar(!editDrawer);
  };
  const handleSetDrawerBarClose = () => {
    setEditDrawerBar(false);
  };
  const handleShareOpen = () => {
    setOpenShare(true);
  };
  const handleShareClose = () => {
    setOpenShare(false);
  };

  const options = [
    {
      icon: 'pencil-alt',
      title: 'Editar gasto',
      onClick: () => {
        toggle();
        toggleEditDrawerBar();
      },
      disabled: Boolean(false),
    },
    {
      icon: 'share-alt',
      title: 'Compartir gasto',
      onClick: () => {
        toggle();
        handleShareOpen();
      },
      disabled: Boolean(false),
    },
    {
      icon: <SatLogo width="28px" fill="#ffffff" />,
      title: 'Timbrar',
      onClick: () => {
        dispatch(openModal('timbre'));
        toggle();
      },
      disabled: Boolean(false),
    },
  ];

  const gasto = useSelector(state => state.gastos.gastoSeleccionado);
  const loads = useSelector(state => state.loads);
  const isLoading = Boolean(loads && loads.emailTemplateIsLoading);
  const modals = useSelector(state => state.modals);

  const tabs = [
    {
      icon: 'fas fa-info-circle',
      renderComponent: <ExpenseDetailForm />,
      title: 'Gasto',
    },
    {
      icon: 'fas fa-hand-holding-usd',
      renderComponent: <CashFlow id={match.params.crmid} />,
      title: 'Flujo de efectivo',
    },
    {
      icon: 'fas fa-file-signature',
      renderComponent: <ExpenseInvoiceContainer crmid={match.params.crmid} />,
      title: 'Facturas',
    },
    {
      icon: 'fas fa-file',
      renderComponent: <List type="gastos" />,
      title: 'Documentos',
    },
  ];

  return (
    <>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition} classes={{ paperFullScreen: 'dialog-bg' }}>
        <AppBar className="appBar" color="inherit" style={{ position: 'relative', boxShadow: 'none' }}>
          <Toolbar variant="dense" disableGutters elevation={0} classes={{ root: 'toolbar-container' }}>
            <div className="d-flex justify-content-start align-items-center">
              <IconButton edge="start" onClick={handleClose} aria-label="close">
                <FontAwesomeIcon style={{ fontSize: 16 }} icon={faChevronLeft} />
              </IconButton>
              <Typography variant="h6" className="toolbar-title title-type">
                {gasto ? gasto.entity_label : ''}
              </Typography>
            </div>
            <IconButton
              color="inherit"
              size="medium"
              onClick={() => toggle()}
            >
              <FontAwesomeIcon className="font-size-18" style={{ width: 20 }} icon={faEllipsisV} />
            </IconButton>
          </Toolbar>
          <Divider />
        </AppBar>
        <Swip tabs={tabs} />
        <EditExpenseDialog onClose={handleSetDrawerBarClose} open={editDrawer} id={gasto ? gasto.crmid : ''} gasto={gasto || null} />
        <TopMenuDrawer
          opt={opt}
          toggle={toggle}
          options={options}
        />
      </Dialog>
      <ShareModal
        open={openShare}
        title={`Compartir gasto ${gasto ? gasto.entity_label : ''}`}
        handleClose={handleShareClose}
        order={gasto || {}}
        template={appModules.TreebesGTO}
        isLoading={isLoading}
      />
      <TimbrarExpense open={Boolean(modals.timbreModalIsOpen)} handleClose={() => dispatch(closeModal('timbre'))} />
    </>
  ); };

DialogExpenseDetail.prototype = {
  open: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

export default withRouter(DialogExpenseDetail);
