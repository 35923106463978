/* eslint-disable camelcase */
/* eslint-disable import/no-cycle */
import { connect } from 'react-redux';
/* Component */
import Customer from './Customer';
/* Actions */
import {
  addAddresForCustomer,
  createCustomer,
  deleteAddressByCustomer,
  editAddresForCustomer,
  editCustomer,
  editEnvioFactura,
  fetchAddressByCustomer,
  fetchAllCustomers,
  filterCustomers,
  removeCustomer,
  selectCustomer,
  setDefaultCustomer,
  setSchema,
} from '../../redux/actions/customerActions';
import {
  selectAddressOrder,
} from '../../redux/actions/sellActions';
import { setOrderCustomer } from '../../redux/actions/orderActions';
import { toggleModal, openModal } from '../../redux/actions/modalActions';

const mapStateToProps = ({
  cart,
  tabs,
  loads,
  modals,
  authUser,
  customer,
}) => ({
  cart,
  tabs,
  loads,
  modals,
  authUser,
  customer,
});

const mapDispatchToProps = dispatch => ({
  dispatchAddAddressByCustomer: (id, address) => dispatch(addAddresForCustomer(id, address)),
  dispatchCreateCustomer: (customer, address = []) => dispatch(createCustomer(customer, address)),
  dispatchDeleteAddressByCustomer: (customerId, crmid, label) => dispatch(deleteAddressByCustomer(customerId, crmid, label)),
  dispatchEditAddressByCustomer: (id, address) => dispatch(editAddresForCustomer(id, address)),
  dispatchEditCustomer: customer => dispatch(editCustomer(customer)),
  dispatchEditEnvioFactura: customer => dispatch(editEnvioFactura(customer)),
  dispatchEditOrder: account_id => dispatch(setOrderCustomer(account_id)),
  dispatchFetchAllCustomers: () => dispatch(fetchAllCustomers()),
  dispatchFilterCustomers: word => dispatch(filterCustomers(word)),
  dispatchGetAddressCustomer: id => dispatch(fetchAddressByCustomer(id)),
  dispatchGetSchema: () => dispatch(setSchema()),
  dispatchOpenModal: modal => dispatch(openModal(modal)),
  dispatchRemoveCustomer: () => dispatch(removeCustomer()),
  dispatchSelectCustomer: customer => dispatch(selectCustomer(customer)),
  dispatchSetDefaultCustomer: customer => dispatch(setDefaultCustomer(customer)),
  dispatchToggleModal: modal => dispatch(toggleModal(modal)),
  dispatchSelectAddres: (factura, envio) => dispatch(selectAddressOrder(factura, envio)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Customer);
