import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import './ListItemGeneric.scss';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';

const ListItemGeneric = ({
  appendIcon,
  prependIcon,
  titleLeft,
  subTitleLeft,
  titleRight,
  subTitleRight,
  expandData,
}) => {
  const [open, handleOpen] = useState(false);
  const handleClick = () => {
    handleOpen(!open);
  };
  const ref = useRef();

  return (
    <>
      <div className="listItemG-container" ref={ref} id={`item-${titleLeft}`}>
        <ListItem className={`${appendIcon ? 'pl-0 py-0' : ''}`}>
          <div className="appednIcon" onClick={expandData ? handleClick : null}>
            {appendIcon}
          </div>
          <ListItemText
            className="pl-0"
            primary={titleLeft}
            secondary={open ? null : subTitleLeft}
            onClick={expandData ? handleClick : null}
            classes={{
              primary: 'listItemG-text-primary',
              secondary: 'listItemG-text-secondary',
            }}
          />

          <div className="d-flex flex-column">

            <ListItemText
              onClick={expandData ? handleClick : null}
              className="d-flex flex-column align-items-end pr-0"
              primary={titleRight}
              classes={{
                primary: 'listItemG-text-primary2',
              }}
            />
            {open ? null : (

              <div className="d-flex justify-content-end listItemG-text-secondary">
                {subTitleRight}
              </div>
            )}
          </div>
          <div className="prependIcon d-flex flex-column pl-2">
            {prependIcon}
          </div>
        </ListItem>
        <Collapse in={open} timeout="auto" mountOnEnter unmountOnExit>
          {expandData}
        </Collapse>
        <Divider />
      </div>
    </>
  ); };

ListItemGeneric.defaultProps = {
  titleLeft: '-',
};

ListItemGeneric.propTypes = {
  appendIcon: PropTypes.node,
  prependIcon: PropTypes.node,
  titleLeft: PropTypes.string,
  subTitleLeft: PropTypes.string,
  titleRight: PropTypes.string,
  subTitleRight: PropTypes.any,
  expandData: PropTypes.any,
};
export default ListItemGeneric;
