import React from 'react';
import PropTypes from 'prop-types';
/* Reatcstrap */
import {
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from 'reactstrap';
import { Divider } from '@material-ui/core';
/* Components */
import Uploader from '../components/Uploader/Uploader';
import ListFiles from '../components/ListFiles';
import Pagination from '../components/Pagination';

const FileModal = ({
  download,
  files,
  getFiles,
  isOpen,
  loading,
  remove,
  title,
  toggle,
  upload,
}) => {
  React.useEffect(() => {
    if (isOpen) {
      getFiles(1, '');
    }
    // eslint-disable-next-line
  }, [isOpen]);
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      sizer="lg"
    >
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        <Uploader upload={upload} />
        <Divider className="my-1" />
        <ListFiles
          files={files.result}
          loading={loading}
          download={download}
          count={files.count}
          remove={remove}
        />
      </ModalBody>
      {files.count > 100 && (
        <ModalFooter>
          <Pagination files={files} />
        </ModalFooter>
      )}
    </Modal>
  );
};

FileModal.propTypes = {
  download: PropTypes.func.isRequired,
  files: PropTypes.object.isRequired,
  getFiles: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  remove: PropTypes.func.isRequired,
  title: PropTypes.string,
  toggle: PropTypes.func.isRequired,
  upload: PropTypes.func.isRequired,
};

FileModal.defaultProps = {
  title: 'Subir archivo',
};

export default FileModal;
