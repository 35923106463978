import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ModalOptions from './ModalOptions';

import { resetState } from '../../../../../redux/actions/orderActions';
import { addProductToCart } from '../../../../../redux/actions/cartActions';
import { fetchSubProducts } from '../../../../../redux/actions/productActions';

const mapStateToProps = (state) => {
  const {
    authUser, product, loads, order,
  } = state;
  const loading = Boolean(loads.subProductsIsLoading);
  const areOrders = Boolean(order.orders.length > 0);
  return ({
    authUser,
    product,
    loading,
    areOrders,
  });
};

const mapDispatchToProps = dispatch => ({
  getSubProducts: crmid => dispatch(fetchSubProducts(crmid)),
  addCartProduct: (element) => {
    const { crmid, parentid, quantity } = element.dataset;
    dispatch(addProductToCart(parentid, crmid, quantity));
    return true;
  },
  resetState: () => dispatch(resetState()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModalOptions));
