import {
  GET_UPDATES,
  GET_EMAIL_LIST,
  GET_ACCOUNT_BALANCE,
  GET_UPDATES_PAGINATOR,
  GET_ACCOUNT_DOCUMENTS,
  GET_EMAIL_LIST_PAGINATOR,
  GET_ACCOUNT_CONTACT_EMAILS,
  GET_ACCOUNT_CONTACT_PHONES,
  GET_ACCOUNT_CONTACT_ADDRESS,
  GET_ACCOUNT_DOCUMENTS_PAGINATOR,
} from '../types/accountActionsType';

const INITIAL_STATE = {
  updates: { data: [], pages: 1 },
  emailList: { data: [], pages: 1 },
  documents: { data: [], pages: 1 },
  balance: { data: [] },
  contact: { emails: [], phone: [], address: [] },
};

const accountReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_UPDATES:
      return {
        ...state,
        updates: { data: [...action.payload] },
      };
    case GET_UPDATES_PAGINATOR:
      return {
        ...state,
        updates: { data: [...state.updates.data, ...action.payload] },
      };
    case GET_EMAIL_LIST:
      return {
        ...state,
        emailList: { data: [...action.payload.result], pages: action.payload.pages },
      };
    case GET_EMAIL_LIST_PAGINATOR:
      return {
        ...state,
        emailList: { data: [...state.emailList.data, ...action.payload.result], pages: action.payload.pages },
      };
    case GET_ACCOUNT_DOCUMENTS:
      return {
        ...state,
        documents: { data: [...action.payload.result], pages: action.payload.pages },
      };
    case GET_ACCOUNT_DOCUMENTS_PAGINATOR:
      return {
        ...state,
        documents: { data: [...state.documents.data, ...action.payload.result], pages: action.payload.pages },
      };
    case GET_ACCOUNT_BALANCE:
      return {
        ...state,
        balance: { data: [...action.payload] },
      };
    case GET_ACCOUNT_CONTACT_EMAILS:
      return {
        ...state,
        contact: { emails: [...action.payload] },
      };
    case GET_ACCOUNT_CONTACT_PHONES:
      return {
        ...state,
        contact: { phone: [...action.payload] },
      };
    case GET_ACCOUNT_CONTACT_ADDRESS:
      return {
        ...state,
        contact: { address: [...action.payload] },
      };

    default:
      return state;
  }
};
export default accountReducer;
