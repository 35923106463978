export const GET_EXPENSE_SCHEMA = 'GET_EXPENSE_SCHEMA';

export const GET_EXPENSE = 'GET_EXPENSE';
export const GET_EXPENSES = 'GET_EXPENSES';
export const GET_EXPENSES_PAGINATOR = 'GET_EXPENSES_PAGINATOR';

export const GET_EXPENSE_TYPE = 'GET_EXPENSE_TYPE';
export const GET_EXPENSES_TYPE = 'GET_EXPENSES_TYPE';
export const GET_EXPENSES_TYPE_PAGINATOR = 'GET_EXPENSES_TYPE_PAGINATOR';

export const GET_VENDOR = 'GET_VENDOR';
export const GET_VENDORS = 'GET_VENDORS';
export const GET_VENDORS_PAGINATOR = 'GET_VENDORS_PAGINATOR';

export const GET_ALMACEN = 'GET_ALMACEN';
export const GET_ALMACENES = 'GET_ALMACENES';
export const GET_ALMACENES_PAGINATOR = 'GET_ALMACENES_PAGINATOR';

export const GET_INVOICES_BY_EXPENSE_PROVIDER = 'GET_INVOICES_BY_EXPENSE_PROVIDER';
export const GET_INVOICES_BY_EXPENSE_PROVIDER_PAGINATOR = 'GET_INVOICES_BY_EXPENSE_PROVIDER_PAGINATOR';

export const GET_EXPENSES_DOCUMENTS = 'GET_EXPENSES_DOCUMENTS';
export const GET_EXPENSES_DOCUMENTS_PAGINATOR = 'GET_EXPENSES_DOCUMENTS_PAGINATOR';
