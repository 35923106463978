import {
  SET_SEARCH, SET_ORDER, SET_DISCOUNT_TAXES, SET_FILTER_VALUE,
} from '../types/filterActions.type';

const INITIAL_STATE = {
  searchBy: 'Cuenta',
  orderBy: 'Mas nueva',
  filterBy: '',
  wichFilterChange: '',
  discountTaxes: false,
};

const filterReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SEARCH:
      return {
        ...state,
        searchBy: action.payload,
        wichFilterChange: 'search',
      };
    case SET_ORDER:
      return {
        ...state,
        orderBy: action.payload,
        wichFilterChange: 'order',
      };
    case SET_FILTER_VALUE:
      return {
        ...state,
        filterBy: action.payload,
        wichFilterChange: 'filter',
      };

    case SET_DISCOUNT_TAXES:
      return {
        ...state,
        discountTaxes: !state.discountTaxes,
      };

    default:
      return state;
  }
};
export default filterReducer;
