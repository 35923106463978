/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */

import React from 'react';
import PropTypes from 'prop-types';

import WharehouseForm from '../../Wharehouse/components/WharehouseForm';
import FullDialog from '../../FullDialog';


const DeliverDialog = ({
  loads,
  table,
  title,
  isOpen,
  onSave,
  onClose,
  authUser,
  isLoading,
  getOrderDocument,
  deliverEnableAction,
  dispatchSelectEmpty,
  dispatchChangeComment,
  dispatchChangeFieldValue,
  dispatchSelectToRefunded,
  dispatchSelectToDelivered,
}) => (
  <FullDialog
    title={title}
    isOpen={isOpen}
    isLoading={isLoading}
    onClose={onClose}
    onSave={onSave}
    enableAction={Boolean(deliverEnableAction)}
  >
    <WharehouseForm
      loads={loads}
      table={table}
      activity="deliver"
      authUser={authUser}
      getOrderDocument={getOrderDocument}
      dispatchSelectEmpty={dispatchSelectEmpty}
      dispatchChangeComment={dispatchChangeComment}
      dispatchChangeFieldValue={dispatchChangeFieldValue}
      dispatchSelectToRefunded={dispatchSelectToRefunded}
      dispatchSelectToDelivered={dispatchSelectToDelivered}
    />
  </FullDialog>
);

DeliverDialog.propTypes = {
  onSave: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  loads: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  table: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  authUser: PropTypes.object.isRequired,
  getOrderDocument: PropTypes.func.isRequired,
  deliverEnableAction: PropTypes.bool.isRequired,
  dispatchSelectEmpty: PropTypes.func.isRequired,
  dispatchChangeComment: PropTypes.func.isRequired,
  dispatchChangeFieldValue: PropTypes.func.isRequired,
  dispatchSelectToRefunded: PropTypes.func.isRequired,
  dispatchSelectToDelivered: PropTypes.func.isRequired,
};

export default DeliverDialog;
