import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Divider,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core';
import { Storage, HowToReg } from '@material-ui/icons';
import LayoutUserInfo from '../../../../../../components/Layout/components/LayoutUserInfo';
import Connecction from '../../../../../../components/Layout/components/Connection';

const Status = ({ authUser, generalTitle }) => (
  <Card
    className="w-100 mb-2"
  >
    <div className="settings__title px-4 py-2">{generalTitle}</div>
    <div className="settings_status">
      <LayoutUserInfo authUser={authUser} />
      <Divider />
      <ListItem dense>
        <ListItemIcon>
          <Storage />
        </ListItemIcon>
        <ListItemText
          primary="Dominio"
          secondary={authUser.domain}
        />
      </ListItem>
      <ListItem dense>
        <ListItemIcon>
          <HowToReg />
        </ListItemIcon>
        <ListItemText
          primary="Cliente por defecto"
          secondary={`${authUser.user.account_default.firstname} ${
            authUser.user.account_default.lastname
          }`}
        />
      </ListItem>
      <Divider />
      <Connecction authUser={authUser} />
    </div>
  </Card>
);

Status.propTypes = {
  generalTitle: PropTypes.string,
  authUser: PropTypes.object.isRequired,
};

Status.defaultProps = {
  generalTitle: 'Información y Estatus Actual',
};

export default Status;
