import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import WhatsapIcon from '../../../../../../../components/Icons/WhatsappIcon';

const Whatsap = (contact) => {
  const {
    phone, mobile, firstname, lastname, crmid,
  } = contact.contact;

  return (
    <>
      {phone ? (
        <a href={`whatsapp://send?text=&phone=${phone}`} key={crmid} target="_blank" rel="noopener noreferrer">
          <ListItem button classes={{ root: 'p-0' }}>
            <ListItemAvatar>
              <Avatar variant="square" className="modal-avatar">
                <WhatsapIcon fill="white" width="26px" height="26px" />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={`${firstname} ${lastname}`.substring(0, 16).concat('...')} secondary={phone} classes={{ root: 'px-2', primary: 'modal-primary-text', secondary: 'modal-secondary-text' }} />
          </ListItem>
        </a>
      ) : null}

      {
            mobile ? (
              <a href={`whatsapp://send?text=&phone=${phone}`} key={crmid} target="_blank" rel="noopener noreferrer">
                <ListItem button classes={{ root: 'p-0' }}>
                  <ListItemAvatar>
                    <Avatar variant="square" className="modal-avatar">
                      <WhatsapIcon fill="white" width="26px" height="26px" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={`${firstname} ${lastname}`.substring(0, 16).concat('...')} secondary={mobile} classes={{ root: 'px-2', primary: 'modal-primary-text', secondary: 'modal-secondary-text' }} />
                </ListItem>
              </a>
            ) : null
        }
    </>
  );
};
Whatsap.prototype = {
  contact: PropTypes.object,
};
export default Whatsap;
