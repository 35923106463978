/* eslint-disable import/no-cycle */
import React from 'react';
import { useSelector } from 'react-redux';
import {
  Collapse,
  LinearProgress,
} from '@material-ui/core';
import SearchTopBar from '../SearchTopBar';

const TaskType = ({
  type,
}) => {
  const loads = useSelector(state => state.loads);
  return (
    <div className="w-100">
      <SearchTopBar type={type} />
      { loads.taskIsLoading && (
        <Collapse in={loads.taskIsLoading}>
          <LinearProgress />
        </Collapse>
      )}
    </div>
  );
};

export default TaskType;
