/* eslint-disable no-tabs */
import React from 'react';

const CreditCardIcon = props => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    width="100px"
    height="100px"
    viewBox="0 0 100 100"
    enableBackground="new 0 0 100 100"
    xmlSpace="preserve"
    {...props}
  >
    <g>
      <path d="M83.319,18.929H16.681c-3.694,0-6.681,2.946-6.681,6.627v48.889c0,3.681,2.986,6.627,6.681,6.627h66.639
		c3.694,0,6.681-2.946,6.681-6.627V25.556C90,21.875,87.014,18.929,83.319,18.929z M83.393,73.611c0,0.458-0.449,0.853-0.907,0.853
		H17.514c-0.458,0-0.907-0.395-0.907-0.853V50h66.786V73.611z M83.393,32.143H16.607v-5.754c0-0.458,0.449-0.853,0.907-0.853h64.972
		c0.458,0,0.907,0.395,0.907,0.853V32.143z"
      />
      <path d="M36.786,60.595c0-0.92-0.746-1.667-1.667-1.667H25.06c-0.92,0-1.667,0.746-1.667,1.667v5.595
		c0,0.92,0.746,1.667,1.667,1.667h10.06c0.92,0,1.667-0.747,1.667-1.667V60.595z"
      />
      <path d="M63.214,60.595c0-0.92-0.746-1.667-1.667-1.667h-18.81c-0.921,0-1.667,0.746-1.667,1.667v5.595
		c0,0.92,0.746,1.667,1.667,1.667h18.81c0.92,0,1.667-0.747,1.667-1.667V60.595z"
      />
    </g>
  </svg>
);

export default CreditCardIcon;
