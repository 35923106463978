import React from 'react';
import PropTypes from 'prop-types';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { Label, LabelOutlined } from '@material-ui/icons';

const PricebookSelect = ({
  item, loading, onClick, select,
}) => (
  <ListItem
    dense
    button
    disabled={loading}
    style={{ paddingLeft: '2.4em' }}
    onClick={(() => onClick())}
  >
    <ListItemIcon>
      {select ? <Label /> : <LabelOutlined /> }
    </ListItemIcon>
    <ListItemText primary={item.entity_label} />
  </ListItem>
);

PricebookSelect.propTypes = {
  item: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  select: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
};

PricebookSelect.defaultProps = {
  onClick: () => {},
};

export default PricebookSelect;
