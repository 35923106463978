import {
  Collapse, Divider, LinearProgress, List, ListItem, ListItemText, Paper,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ListMdaItem from '../../Mda/components/ListMda/ListMdaItem';

const OrderMda = ({ mda, loading }) => {
  const [arr, setArr] = React.useState([]);

  const initMda = (_mda) => {
    const array = [..._mda];
    let _arr = [];
    const labelArr = array.map(x => (x.entity_label));
    labelArr.forEach((x) => {
      if (!(_arr.includes(x))) {
        _arr = [..._arr, x];
      }
    });
    const mdaArr = _arr.map(x => ({
      entity_label: x,
      createdtime: array.filter(el => el.entity_label === x)[0].createdtime,
      de_documentoid_label: array.filter(el => el.entity_label === x)[0].de_documentoid_label,
      a_documentoid_label: array.filter(el => el.entity_label === x)[0].a_documentoid_label,
      items: array.filter(el => el.entity_label === x),
    }));

    setArr(mdaArr);
  };

  useEffect(() => {
    initMda(mda);
  }, [mda]);

  return (
    <div className="deliver__mda">
      <h4 className="mb-1">Movimientos de almacén</h4>
      <Paper className="w-100 h-auto mda-list" elevation={0}>
        <Collapse in={loading}>
          <LinearProgress />
        </Collapse>
        <Collapse in={!loading}>
          {arr.length > 0 ? (
            <List className="mda_list">
              {arr.map((item, i) => (
                <div key={Math.random()} className="w-100">
                  {i !== 0 && (
                  <Divider />
                  )}
                  <ListMdaItem item={item} />
                </div>
              ))}
            </List>
          ) : (
            <List dense>
              <ListItem>
                <ListItemText
                  primary="Sin movimientos de almacén"
                  secondary="La orden no cuenta con movimientos de almacén"
                />
              </ListItem>
            </List>
          )}
        </Collapse>
      </Paper>
    </div>
  ); };

OrderMda.propTypes = {
  mda: PropTypes.array.isRequired,
  loading: PropTypes.bool,
};

OrderMda.defaultProps = {
  loading: false,
};

export default OrderMda;
