import React from 'react';
import PropTypes from 'prop-types';

import {
  List,
  Menu,
  Checkbox,
  MenuItem,
  ListItem,
  ListItemText,
} from '@material-ui/core';

const ConfVentas = ({
  title,
  loading,
  columnDef,
  columnsDefs,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleList(e) {
    const { currentTarget } = e;
    setAnchorEl(currentTarget);
  }

  return (
    <>
      <List component="nav" aria-label="Device settings">
        <ListItem
          dense
          button
          aria-label={title}
          onClick={handleList}
          aria-haspopup="true"
          aria-controls="columnState"
        >
          <ListItemText
            primary={title}
            secondary={columnsDefs.map(a => a.children.filter(b => !(b.hide)).map(c => c.headerName).join(', ')).join(', ')}
          />
        </ListItem>
      </List>
      <Menu
        id="columnState"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          style: { maxHeight: '30vh', width: '40vh' },
        }}
      >
        {columnsDefs.map(({ headerName, children }) => (
          <div
            key={`key_${headerName}`}
            className="w-100 h-auto"
          >
            <MenuItem disabled>{ headerName }</MenuItem>
            {children.map(option => (
              <MenuItem
                dense
                disabled={loading}
                className="d-flex flex-row"
                key={`key_column option_${headerName}_ ${option.headerName}`}
                onClick={() => columnDef(option.field)}
              >
                <Checkbox checked={!(option.hide)} />
                <span>{option.headerName}</span>
              </MenuItem>
            ))}
          </div>
        ))}
      </Menu>
    </>
  );
};

ConfVentas.propTypes = {
  title: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  columnDef: PropTypes.func.isRequired,
  columnsDefs: PropTypes.array.isRequired,
};

ConfVentas.defaultProps = {
  title: 'Columnas de Ventas',
};

export default ConfVentas;
