/* eslint-disable camelcase */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AppBar, Collapse, Dialog, IconButton, LinearProgress, Slide, Toolbar,
} from '@material-ui/core';
import React from 'react';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import MdaAPI from '../../../../api/MdaApi';
import OrderAPI from '../../../../api/OrderAPI';
import appModules from '../../../../constant/appModules';
import ShareModal from '../../../Share/components/ShareModal';
import TopMenuDrawer from '../../../TopMenuDrawer';
import ShipmentSwipe from './ShipmentSwipe';


const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const ShipmentDetail = () => {
  const history = useHistory();
  const params = useParams();
  const [open, setOpen] = React.useState(false);
  const [openShare, setOpenShare] = React.useState(false);
  const [order, setOrder] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [opt, setOpt] = React.useState(false);
  const toggle = () => setOpt(!opt);

  const getDetail = async (id) => {
    setLoading(true);
    const orderApi = new OrderAPI();
    // const productApi = new ProductAPI();
    // const ord = await productApi.getSingle(id);
    const response = await orderApi.orderDeliverRequestDetail(id);
    // dispatch(add(ord.result));

    const { success, result } = response;
    if (success && Object.keys(result).length > 0) {
      setOrder(result);
      setOpen(true);
    }
    setLoading(false);
  };

  const goToMap = (order) => {
    const url = `https://www.google.com/maps/search/?api=1&query=${order.orig_latitude || ''}, ${order.orig_longitude || ''}`;
    window.open(url, '_blank');
  };

  const falseDeliver = async () => {
    const mdaApi = new MdaAPI();
    const response = await mdaApi.falseDeliver(order.crmid);
    const { success } = response;
    if (success) {
      toast.success('Se realizó la operacion con exito');
    } else {
      toast.error('Error realizando la operación');
    }
  };

  React.useEffect(() => {
    if (params.id) {
      getDetail(params.id);
    }
  }, [params]);


  const options = [
    {
      icon: 'share-alt',
      title: 'Compartir',
      onClick: () => {
        setOpenShare(true);
        toggle();
      },
      disabled: !order,
    },
    {
      icon: 'map-marker-alt',
      title: 'Ver en mapa',
      onClick: () => {
        goToMap(order);
        toggle();
      },
      disabled: !order,
    },
    {
      icon: 'phone-alt',
      title: 'Llamar',
      onClick: () => {
        window.location.href = `tel://${order.dest_phone}`;
        toggle();
      },
      disabled: !order,
    },
    {
      icon: 'money-check-alt',
      title: 'Entrega en falso',
      onClick: () => {
        falseDeliver();
        toggle();
      },
      disabled: order.tse_estado_i !== '5- En transito',
    },
    /* {
      icon: 'bookmark',
      title: 'Imprimir',
      onClick: () => {
        toggle();
      },
      disabled: true,
    }, */
  ];

  return (
    <div>
      {!loading && (
        <Dialog
          open={open}
          fullScreen
          TransitionComponent={Transition}
          onClose={() => history.goBack()}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <AppBar
            className="modalDetail_appBar"
            style={{ backgroundColor: '#ffffff' }}
            position="static"
          >
            <Toolbar className="d-flex px-0" classes={{ root: 'toolbar' }}>
              <IconButton
                edge="start"
                color="inherit"
                onClick={async () => {
                  history.push('/entregas');
                }}
                aria-label="close"
                style={{ width: '48px', height: '48px' }}
              >
                <FontAwesomeIcon color="#313C52" icon="chevron-left" />
              </IconButton>
              <span className="modalDetail_header">
                Detalles
                {' '}
                {order.referencia}
              </span>
              <IconButton
                edge="start"
                color="inherit"
                className="ml-auto"
                aria-label="close"
                style={{ width: '48px', height: '48px' }}
                onClick={
                      () => toggle()
                    }
              >
                <FontAwesomeIcon color="#313C52" icon="ellipsis-v" />
              </IconButton>
            </Toolbar>
          </AppBar>
          <div className="orderDetail_container">
            <ShipmentSwipe order={order} />
          </div>
          <TopMenuDrawer
            opt={opt}
            toggle={toggle}
            options={options}
          />
        </Dialog>
      )}

      {loading && (
      <Collapse in={loading}>
        <LinearProgress />
      </Collapse>
      )}

      <ShareModal
        open={openShare}
        title={`Compartir ${order ? order.referencia : ''}`}
        handleClose={() => setOpenShare(false)}
        order={order || {}}
        template={appModules.TreebesSolicitudesEnvio}
        isLoading={loading}
      />
    </div>
  );
};

ShipmentDetail.propTypes = {};

ShipmentDetail.defaultProps = {};

export default ShipmentDetail;
