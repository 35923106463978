/* eslint-disable import/no-cycle */
import { connect } from 'react-redux';
import Deliver from './Deliver';

import { changeFieldValue } from '../../../../redux/actions/tableActions';

const mapStateToProps = (state) => {
  const {
    loads,
    modals,
    table,
  } = state;
  return ({
    loading: Boolean(loads.orderIsLoading || loads.tableIsLoading),
    modal: Boolean(modals.deliverModalIsOpen),
    table,
  });
};

const mapDispatchToProps = dispatch => ({
  dispatchChangeField: obj => dispatch(changeFieldValue(obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Deliver);
