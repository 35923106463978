/* eslint-disable array-callback-return */
/* eslint-disable radix */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
/* eslint camelcase: 0 */
/* eslint-disable no-underscore-dangle */
/* eslint-disable object-curly-newline */
/* eslint-disable prefer-destructuring */

import {
  SET_DESC,
  CLEAR_CART,
  SET_COMMENT,
  ALERT_PRODUCTS,
  SET_PROMOTIONS,
  ACTIVE_PROMOTIONS,
  DESACTIVE_PROMOTIONS,
  RESET_PROMOTIONS,
  UPDATE_PROMOTIONS,
  ADD_ORDER_TO_CART,
  UPDATE_CART_STATE,
  ADD_PRODUCT_TO_CART,
  SET_SINGLE_DISCOUNT,
  VERIFY_PAYPAL_SERVICE,
  REMOVE_PRODUCT_IN_CART,
  ADD_PRODUCT_TO_CART_QTY,
  INCREASE_PRODUCT_IN_CART,
  DECREASE_PRODUCT_IN_CART,
  UPDATE_PRODUCT_QTY_IN_CART,
  UPDATE_PRODUCT_PRICE_IN_CART,
  UPDATE_SERVICE_PRICE_BY_WEIGHT_CART,
  ADD_SHIPPING_SERVICE,
  ADD_SELECTED_GUIDE,
  CLEAR_SELECTED_GUIDE,
  ADD_SELECTED_PACKING,
  CLEAR_SELECTED_PACKING,
  SET_EDIT_PRODUCT_COMMENT,
} from '../actions/cartActions';

import { cartHelper, toolsHelper } from '../../helpers';
import { formatMoney } from '../../helpers/tools';

const defaultState = () => ({
  products: [],
  subTotal: 0,
  totalIva: 0,
  anotherTaxes: 0,
  balance: 0,
  totalWithOutServices: 0,
  total: 0,
  totalProducts: 0,
  totalWeight: 0,
  cartProductsById: {},
  cartProductsByArray: [],
  orderSelected: {},
  alertProducts: [],
  description: '',
  lastProductAdded: {},
  pagado: 0,
  promotions: {},
  productsWidthpromotions: [],
  activePromotions: false,
  selectedGuide: {},
  selectedPacking: {},
  updateProductsComments: false,
});

// Add shipping services to cart.
const addShippingService = (state, { product }) => {
  const productosId = state.cartProductsById;
  const index = Object.values(state.cartProductsById).findIndex(prod => prod.crmid === product.crmid);
  if (index !== -1) {
    delete productosId[index + 1];
  }

  const priceWithTaxes = cartHelper.getPriceWithTaxesUnitPrice(product);
  const row = Object.keys(productosId).length + 1;
  product.quantity = product.quantity || 1;
  product.comment = '';
  product.discount_percent = 0;
  product.discount_amount = 0;
  product.priceWithTaxes = priceWithTaxes;
  product.row = row;
  productosId[row] = product;

  const newState = cartHelper.transformCartProductsToArrayAndGetTotals(
    productosId,
  );

  return {
    ...state,
    ...newState,
    cartProductsById: productosId,
  };
};

const togglePromotions = (state) => {
  let newState = {};
  if (state.activePromotions) {
    newState = { ...state, promotions: {}, productsWidthpromotions: [] };
  } else {
    newState = { ...state };
  }
  return { ...newState, activePromotions: !state.activePromotions };
};

const updatePromotions = (state, { TreebesPromociones, products }) => {
  const updateProducts = [...state.products];
  const updateProductsById = { ...state.cartProductsById };

  products.map((prod) => {
    const index = updateProducts.findIndex(p => p.crmid === prod.productid);
    if (
      index !== -1
      && updateProducts[index].unit_price !== prod.TreebesPromociones_unit_price
    ) {
      updateProducts[index].unit_price = formatMoney(prod.TreebesPromociones_unit_price);
      updateProducts[index].priceWithTaxes = prod.TreebesPromociones_unit_price_with_taxes;

      // Sele suma 1 pq hace referencia a la fila de la table q comienza en 1.
      updateProductsById[index + 1].unit_price = formatMoney(prod.TreebesPromociones_unit_price);
      updateProductsById[index + 1].priceWithTaxes = prod.TreebesPromociones_unit_price_with_taxes;
    }
  });
  const newState = cartHelper.transformCartProductsToArrayAndGetTotals(
    updateProductsById,
    state.discountTaxes,
  );

  return {
    ...state,
    ...newState,
    promotions: { ...TreebesPromociones },
    productsWidthpromotions: [...products],
    products: [...updateProducts],
    cartProductsById: { ...updateProductsById },
  };
};

const addProductToCartQty = (
  state,
  { parentId, crmid, productsById, quantity, inStock, discountTaxes },
) => {
  let lastProductAdded = {};
  const cartProductsById = { ...state.cartProductsById };
  const cartProductsByArray = [...state.cartProductsByArray];
  const existProductByCrmid = cartProductsByArray.find(
    x => x.crmid === crmid,
  );
  if (existProductByCrmid) {
    const cartProduct = { ...existProductByCrmid };
    const { row } = cartProduct;
    if (quantity && cartProduct.quantity !== quantity) {
      cartProduct.quantity += quantity;
      cartProduct.comment = '';
      cartProductsById[row] = cartProduct;
    } else {
      cartProduct.quantity += 1;
      cartProduct.comment = '';
      cartProduct.inStock = inStock;
      cartProductsById[row] = cartProduct;
    }
  } else if (
    parentId in productsById
    && crmid in productsById[parentId].Products
  ) {
    const product = { ...productsById[parentId].Products[crmid] };
    const priceWithTaxes = cartHelper.getPriceWithTaxesUnitPrice(product);
    const row = Object.keys(cartProductsById).length + 1;
    product.quantity = quantity || 1;
    product.comment = '';
    product.inStock = inStock;
    product.discount_percent = 0;
    product.discount_amount = 0;
    product.priceWithTaxes = priceWithTaxes;
    product.row = row;
    product.image = productsById[parentId].image;
    cartProductsById[row] = product;
    lastProductAdded = product;
  } else {
    return state;
  }
  const newState = cartHelper.transformCartProductsToArrayAndGetTotals(
    cartProductsById,
    discountTaxes,
  );
  return { ...state, ...newState, cartProductsById, lastProductAdded };
};

const addProductToCart = (
  state,
  { parentId, crmid, productsById, quantity, inStock, discountTaxes },
) => {
  const cartProductsById = { ...state.cartProductsById };
  let lastProductAdded = {};
  if (parentId in productsById && crmid in productsById[parentId].Products) {
    const product = { ...productsById[parentId].Products[crmid] };
    const priceWithTaxes = cartHelper.getPriceWithTaxesUnitPrice(product);
    const row = Object.keys(cartProductsById).length + 1;
    product.quantity = quantity || 1;
    product.comment = '';
    product.inStock = inStock;
    product.discount_percent = 0;
    product.discount_amount = 0;
    product.priceWithTaxes = priceWithTaxes;
    product.row = row;
    product.image = productsById[parentId].image || '';
    cartProductsById[row] = product;
    lastProductAdded = product;
  } else {
    return state;
  }
  const newState = cartHelper.transformCartProductsToArrayAndGetTotals(
    cartProductsById,
    discountTaxes,
  );
  return { ...state, ...newState, cartProductsById, lastProductAdded };
};

const addOrderToCart = (state, { order, discountTaxes }) => {
  const { LineItems, LineItems_FinalDetails } = order;

  let productswithImages = {};
  Object.entries(LineItems_FinalDetails).forEach((x) => {
    productswithImages = {
      ...productswithImages,
      [x[1].hdnProductId1]: x[1].productImage1,
    };
  });
  const cartProductsById = { ...state.cartProductsById };

  LineItems.forEach((product) => {
    const row = Object.keys(cartProductsById).length + 1;
    const quantity = toolsHelper.numberFormat(product.quantity);
    const price = toolsHelper.numberFormat(product.listprice);
    const preDiscountAmount = quantity * (price * (product.discount_percent / 100));
    const discountAmount = parseFloat(product.discount_percent) === 0
      ? product.discount_amount
      : preDiscountAmount;
    const priceWithTaxes = cartHelper.getPriceWithTaxes(product);
    const id = product.productid.split('x').pop();
    cartProductsById[row] = { ...product };
    cartProductsById[row].productname = product.product_name;
    cartProductsById[row].quantity = quantity;
    cartProductsById[row].row = row;
    cartProductsById[row].unit_price = price;
    cartProductsById[row].priceWithTaxes = priceWithTaxes;
    cartProductsById[row].comment = product.comment;
    cartProductsById[row].discount_percent = product.discount_percent === '' ? 0 : parseInt(product.discount_percent);
    cartProductsById[row].discount_amount = discountAmount;
    cartProductsById[row].image = productswithImages[id];
  });

  const newState = cartHelper.transformCartProductsToArrayAndGetTotals(
    cartProductsById,
    discountTaxes,
  );
  const description = order.description;
  const orderSelected = { order };
  const { treebesalmid, account_id } = order;
  orderSelected.cartStateWarehouse = order.estado_mda;
  orderSelected.almid = treebesalmid ? treebesalmid.split('x').pop() : 0;
  orderSelected.account_id = account_id.split('x').pop();
  orderSelected.balance = Number.parseFloat(order.tfde_balance);
  orderSelected.total = Number.parseFloat(order.hdnGrandTotal);
  orderSelected.entregado = order.p_entregado;
  orderSelected.status = order.sostatus || order.quotestage;
  orderSelected.crmid = order.crmid;
  orderSelected.description = order.description;
  orderSelected.f_ultima_factura = order.f_ultima_factura;
  orderSelected.cfdis = order.cfdis;
  orderSelected.estadosat = order.estadosat;
  orderSelected.treebessatcfdiid = order.treebessatcfdiid;

  const balance = order.tfde_balance;
  const pagado = order.tfde_pagado;
  return {
    ...state,
    ...newState,
    pagado,
    balance,
    description,
    orderSelected,
    cartProductsById,
  };
};

const increaseProductInCart = (state, { row, inStock, discountTaxes }) => {
  const cartProductsById = { ...state.cartProductsById };
  const product = cartProductsById[row];
  if (product) {
    product.quantity += 1;
    product.inStock = inStock;
    cartProductsById[row] = product;
    const newState = cartHelper.transformCartProductsToArrayAndGetTotals(
      cartProductsById,
      discountTaxes,
    );
    return { ...state, ...newState, cartProductsById };
  }
  return state;
};

const decreaseProductInCart = (state, { row, inStock }) => {
  const cartProductsById = { ...state.cartProductsById };
  const product = cartProductsById[row];
  if (product) {
    product.quantity -= 1;
    product.inStock = inStock;
    cartProductsById[row] = product;
    const newState = cartHelper.transformCartProductsToArrayAndGetTotals(
      cartProductsById,
    );
    return { ...state, ...newState, cartProductsById };
  }
  return state;
};

const updateProductPriceInCart = (state, { row, price }) => {
  const cartProductsById = { ...state.cartProductsById };
  const product = cartProductsById[row];
  if (product) {
    cartProductsById[row].unit_price = parseFloat(price);
    cartProductsById[
      row
    ].priceWithTaxes = cartHelper.getPriceWithTaxesUnitPrice(
      cartProductsById[row],
    );
    cartProductsById[row].totalPrice = cartHelper.getPriceWithTaxesUnitPrice(
      cartProductsById[row],
    );
    const newState = cartHelper.transformCartProductsToArrayAndGetTotals(
      cartProductsById,
    );
    return { ...state, ...newState, cartProductsById };
  }
  return state;
};

const updateProductQuantityInCart = (state, { row, qty }) => {
  const cartProductsById = { ...state.cartProductsById };
  const product = cartProductsById[row];
  if (product) {
    if (!Number.isNaN(qty) && qty > 0) {
      cartProductsById[row].quantity = qty;
      const newState = cartHelper.transformCartProductsToArrayAndGetTotals(
        cartProductsById,
      );
      return { ...state, ...newState, cartProductsById };
    }
    return state;
  }
  return state;
};

function updateServicePriceByWeightCart(state, { cen }) {
  const cartProductsById = { ...state.cartProductsById };
  const {
    id_envio_aereo,
    envio_aereo,
    id_envio_terrestre,
    envio_terrestre,
  } = cen;
  let { totalWeight } = state;

  if (totalWeight === Math.floor(totalWeight)) {
    totalWeight = totalWeight === 0 ? 0 : totalWeight - 1;
  }

  totalWeight = Math.floor(totalWeight);
  totalWeight = totalWeight > 100 ? 100 : totalWeight;

  if (id_envio_aereo in cartProductsById) {
    const envioAereo = cartProductsById[id_envio_aereo];
    const price = envio_aereo[totalWeight] || 0;
    envioAereo.unit_price = price;
    envioAereo.totalPrice = price;
    cartProductsById[id_envio_aereo] = envioAereo;
  }

  if (id_envio_terrestre in cartProductsById) {
    const envioTerrestre = cartProductsById[id_envio_terrestre];
    const price = envio_terrestre[totalWeight] || 0;
    envioTerrestre.unit_price = price;
    envioTerrestre.totalPrice = price;
    cartProductsById[id_envio_terrestre] = envioTerrestre;
  }

  if (
    id_envio_aereo in cartProductsById
    || id_envio_terrestre in cartProductsById
  ) {
    const newState = cartHelper.transformCartProductsToArrayAndGetTotals(
      cartProductsById,
    );
    return { ...state, ...newState, cartProductsById };
  }

  return state;
}

const removeProductInCart = (state, { row }) => {
  const cartProductsById = { ...state.cartProductsById };
  if (cartProductsById[row]) {
    delete cartProductsById[row];
    const newCartProductsById = {};
    Object.entries(cartProductsById).forEach((x, i) => {
      const product = x[1];
      const newRow = i + 1;
      product.row = newRow;
      newCartProductsById[newRow] = product;
    });
    const newState = cartHelper.transformCartProductsToArrayAndGetTotals(
      newCartProductsById,
    );
    return { ...state, ...newState, cartProductsById: newCartProductsById };
  }
  return state;
};

const clearCart = () => {
  const cart = defaultState();
  cart.products = [];
  return cart;
};

const modifyWeightByPayPalService = (state) => {
  const { totalWeight } = state;
  const newState = cartHelper.calculateWeightByPaypalService(totalWeight);
  return { ...state, ...newState };
};

const alertStock = (state, { productsWithOutStock }) => {
  const alertProducts = productsWithOutStock;
  return { ...state, alertProducts };
};

const setDesc = (state, { description }) => ({ ...state, description });

const setComment = (state, { product }) => {
  const { row, comment } = product;
  const { cartProductsById } = state;
  cartProductsById[row].comment = comment;
  const newState = cartHelper.transformCartProductsToArrayAndGetTotals(
    cartProductsById,
  );
  return { ...state, ...newState, cartProductsById, updateProductsComments: true };
};

const setSingleDiscount = (
  state,
  { row, percentage, amount, discountTaxes },
) => {
  const cartProductsById = { ...state.cartProductsById };
  if (cartProductsById[row]) {
    if (!Number.isNaN(percentage) && percentage > 0) {
      cartProductsById[row].discount_amount = amount;
      cartProductsById[row].discount_percent = percentage;
      cartProductsById[
        row
      ].priceWithTaxes = cartHelper.getPriceWithTaxesUnitPrice(
        cartProductsById[row],
      );
    } else {
      cartProductsById[row].discount_amount = 0;
      cartProductsById[row].discount_percent = 0;
      cartProductsById[
        row
      ].priceWithTaxes = cartHelper.getPriceWithTaxesUnitPrice(
        cartProductsById[row],
      );
    }
    const newState = cartHelper.transformCartProductsToArrayAndGetTotals(
      cartProductsById,
      discountTaxes,
    );
    return { ...state, ...newState, cartProductsById };
  }
  return state;
};

const setCartUpdate = (
  state,
  { cartProductsById, orderSelected, discountTaxes },
) => {
  const newState = cartHelper.transformCartProductsToArrayAndGetTotals(
    cartProductsById,
    discountTaxes,
  );
  return { ...state, ...newState, cartProductsById, orderSelected };
};

const updateProductCommnet = (state, action) => ({
  ...state,
  updateProductsComments: action.payload,
});

export default function (state = defaultState(), action) {
  const { type } = action;
  switch (type) {
    case ADD_PRODUCT_TO_CART:
      return addProductToCart(state, action);
    case ADD_PRODUCT_TO_CART_QTY:
      return addProductToCartQty(state, action);
    case ADD_ORDER_TO_CART:
      return addOrderToCart(state, action);
    case INCREASE_PRODUCT_IN_CART:
      return increaseProductInCart(state, action);
    case DECREASE_PRODUCT_IN_CART:
      return decreaseProductInCart(state, action);
    case UPDATE_PRODUCT_PRICE_IN_CART:
      return updateProductPriceInCart(state, action);
    case UPDATE_SERVICE_PRICE_BY_WEIGHT_CART:
      return updateServicePriceByWeightCart(state, action);
    case UPDATE_PRODUCT_QTY_IN_CART:
      return updateProductQuantityInCart(state, action);
    case REMOVE_PRODUCT_IN_CART:
      return removeProductInCart(state, action);
    case VERIFY_PAYPAL_SERVICE:
      return modifyWeightByPayPalService(state);
    case ALERT_PRODUCTS:
      return alertStock(state, action);
    case SET_DESC:
      return setDesc(state, action);
    case SET_COMMENT:
      return setComment(state, action);
    case SET_SINGLE_DISCOUNT:
      return setSingleDiscount(state, action);
    case UPDATE_CART_STATE:
      return setCartUpdate(state, action);
    case CLEAR_CART:
      return clearCart();
    case SET_PROMOTIONS:
      return togglePromotions(state);
    case ACTIVE_PROMOTIONS:
      return {
        ...state,
        activePromotions: true,
      };
    case SET_EDIT_PRODUCT_COMMENT:
      return updateProductCommnet(state, action);
    case DESACTIVE_PROMOTIONS:
      return {
        ...state,
        activePromotions: false,
      };
    case RESET_PROMOTIONS:
      return {
        ...state,
        promotions: {},
        productsWidthpromotions: [],
      };
    case UPDATE_PROMOTIONS:
      return updatePromotions(state, action);
    case ADD_SHIPPING_SERVICE:
      return addShippingService(state, action);
    case ADD_SELECTED_GUIDE:
      return {
        ...state,
        selectedGuide: action.guide,
      };
    case CLEAR_SELECTED_GUIDE:
      return {
        ...state,
        selectedGuide: {},
      };
    case ADD_SELECTED_PACKING:
      return {
        ...state,
        selectedPacking: action.packing,
      };
    case CLEAR_SELECTED_PACKING:
      return {
        ...state,
        selectedPacking: {},
      };
    default:
      return state;
  }
}
