/* eslint-disable import/no-cycle */
import { connect } from 'react-redux';
/* Actions */
import { addPricebook } from '../../redux/actions/priceBookActions';
import { fetchAllProducts } from '../../redux/actions/productActions';
/* Component */
import PriceBook from './PriceBook';

const mapStateToProps = (state) => {
  const { loads, authUser } = state;
  return ({
    auth: authUser,
    loading: Boolean(loads.authUserIsLoading || loads.productIsLoading),
  });
};

const mapDispatchToProps = dispatch => ({
  setPricebook: async (pricebook) => {
    await dispatch(addPricebook(pricebook));
    await dispatch(fetchAllProducts());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PriceBook);
