import React from 'react';
import PropTypes from 'prop-types';
import {
  List,
  Radio,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  ListItemSecondaryAction,
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';

const AddressList = ({
  arr,
  deleteAddress,
  onSelect,
  handleBooleanParam,
}) => (
  <List dense>
    <ListItem dense>
      <ListItemText
        primary="Estas son la direcciones del cliente, no de la orden/cotización."
        secondary="Al generar la orden/cotización, se guardará con la dirección de envío predeterminada del cliente, posterior a su generación podras cambiarlas."
      />
    </ListItem>
    <div className="w-100 d-flex mb-1 border-bottom">
      <span className="font-size-10 text-dark mr-2">Dir. de Facturación:</span>
      <span className="font-size-10 text-dark mr-2">Dir. de Envío:</span>
      <span className="font-size-10 text-dark ml-2 mr-auto">Dirección:</span>
      <span className="font-size-10 text-dark">Remover: </span>
    </div>
    {arr.map(el => (
      <ListItem
        dense
        button
        key={`key_place_${el.direccion}_${el.num_ext}_${Math.random()}`}
        onClick={() => onSelect(el.id)}
      >
        <ListItemIcon>
          <Radio
            checked={el.factura}
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleBooleanParam(el.id, 'factura');
            }}
          />
        </ListItemIcon>
        <ListItemIcon>
          <Radio
            checked={el.envio}
            color="secondary"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleBooleanParam(el.id, 'envio');
            }}
          />
        </ListItemIcon>
        <ListItemText
          primary={`${el.direccion} #${el.num_ext}`}
          secondary={`${el.city} - ${el.state}, ${el.country}`}
        />
        <ListItemSecondaryAction>
          <IconButton
            onClick={() => deleteAddress(el.id)}
          >
            <Delete />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    ))}
  </List>
);

AddressList.propTypes = {
  arr: PropTypes.array,
  deleteAddress: PropTypes.func,
  handleBooleanParam: PropTypes.func,
  onSelect: PropTypes.func,
};

AddressList.defaultProps = {
  arr: [],
  deleteAddress: () => {},
  handleBooleanParam: () => {},
  onSelect: () => {},
};

export default AddressList;
