import React from 'react';
import './HeaderTitle.scss';

const HeaderTitle = ({ title, children }) => (
  <div className="headerTitle_container">
    <span className="headerTitle_title">{title}</span>
    {children}
  </div>
);

export default HeaderTitle;
