import React, { useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress } from '@material-ui/core';
import ListLoading from '../../../../List/ListLoading';
import OrderListItem from '../OrderListItem';

const OrderList = ({
  order,
  location,
  history,
  loading,
  fetchAll,
  getNextPage,
}) => {
  const {
    paginatedOrders, actualPage, totalPages, orders,
  } = order;
  const hasMore = Boolean(actualPage < totalPages);
  const [arr, setArr] = React.useState([]);
  const observer = useRef();
  const lastElement = useCallback((node) => {
    if (hasMore) {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) getNextPage(actualPage + 1);
      });
      if (node) observer.current.observe(node);
    }
    // eslint-disable-next-line
    }, [loading, hasMore]);

  const openOrderDetail = crmid => history.push(`/pos/venta/${crmid}`);

  useEffect(() => {
    if (!loading && paginatedOrders.length > 0) {
      setArr(paginatedOrders);
    }
    // eslint-disable-next-line
  }, [loading, paginatedOrders]);

  useEffect(() => {
    if (location.pathname === '/pos/venta' && paginatedOrders.length === 0) {
      fetchAll();
      window.scroll(0, 0);
    }
    // eslint-disable-next-line
  }, [location, order]);

  const renderList = (_arr) => {
    const elements = _arr.map((item, i) => {
      if (hasMore && arr.length === (i + 5)) {
        return (
          <OrderListItem
            key={`key_advOne_${item.crmid}_${Math.random()}`}
            item={item}
            ref={lastElement}
            onClick={openOrderDetail}
          />
        );
      }
      return (
        <OrderListItem
          key={`key_advOne_${item.crmid}_${Math.random()}`}
          item={item}
          onClick={openOrderDetail}
        />
      );
    });
    elements.unshift(<ListLoading key={`key_advOne_loading_${Math.random()}`} isLoading={loading} />);
    return elements;
  };
  if (orders.length > 0 && arr.length > 0) {
    return renderList(arr);
  }
  return (
    <div className="w-100 mt-4 h-100 d-flex align-items-center justify-content-center flex-column">
      {loading ? (
        <CircularProgress />
      ) : (
        <FontAwesomeIcon color="#b1b1b1" size="3x" icon="file-invoice-dollar" />
      )}
      <span
        className="font-size-12 align-items-center"
        style={{ color: '#b1b1b1' }}
      >
        {loading ? ('Cargando ...') : ('Sin Ordenes de venta')}
      </span>
    </div>
  );
};


OrderList.propTypes = {
  order: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  loading: PropTypes.bool,
  fetchAll: PropTypes.func,
  getNextPage: PropTypes.func,
};

OrderList.defaultProps = {
  order: {},
  location: {},
  history: {},
  loading: false,
  fetchAll: () => {},
  getNextPage: () => {},
};

export default withRouter(OrderList);
