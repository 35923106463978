import React from 'react';
import PropTypes from 'prop-types';
import './GenericButton.scss';
import { Button } from '@material-ui/core';

const GenericButton = (props) => {
  const {
    label, backgroundColor, height, disabled,
  } = props;
  return (
    <Button
      {...props}
      backgroundcolor={backgroundColor}
      className="genericButton m-1"
      style={{
        backgroundColor,
        height,
      }}
      disabled={disabled}
    >
      {label}
    </Button>
  );
};

GenericButton.propTypes = {
  label: PropTypes.any,
  backgroundColor: PropTypes.string,
  height: PropTypes.string,
  disabled: PropTypes.bool,
};

GenericButton.defaultProps = {
  label: PropTypes.string,
  backgroundColor: '#23a1db',
  height: '100%',
  disabled: false,
};

export default GenericButton;
