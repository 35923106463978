import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Radio from '@material-ui/core/Radio';
import { actionTypes, defaultOrderData } from '../../config';

const Filtering = ({
  data,
  orderChange,
  openComponent,
  orderFilterValue,
  handleClickOpenComponent,
}) => (
  <List component="nav" classes={{ root: 'filter-list' }}>
    <ListItem button onClick={() => handleClickOpenComponent('filtrar')}>
      <ListItemText primary="Filtrar por:" secondary={orderFilterValue} classes={{ primary: 'filter-item-title', secondary: 'filter-item-subtitle' }} />
      <ListItemText primary={openComponent === 'filtrar' ? <ExpandLess /> : <ExpandMore />} classes={{ root: 'pr-0', primary: 'd-flex justify-content-end' }} />
    </ListItem>
    <Collapse in={openComponent === 'filtrar'} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {
            data.map(item => (
              <ListItem button divider classes={{ root: 'filter-item-bg' }} key={item} onClick={() => orderChange(item, actionTypes.filtrar)}>
                <ListItemText primary={item} classes={{ primary: 'filter-item-subItem' }} />
                <ListItemSecondaryAction>
                  <Radio
                    color="primary"
                    onChange={() => orderChange(item, actionTypes.filtrar)}
                    classes={{ root: 'filter-item-checkbox', colorPrimary: 'filter-item-checkbox', checked: 'filter-item-checkbox' }}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))
          }
      </List>
    </Collapse>
  </List>
);

Filtering.prototype = {
  openComponent: PropTypes.string.isRequired,
  handleClickOpenComponent: PropTypes.func.isRequired,
  data: PropTypes.array,
};

Filtering.defaultProps = {
  data: defaultOrderData,
};

export default Filtering;
