/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './SummaryContainer.scss';
import { withRouter } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import SaleSummary from '../SaleSummary/SaleSummary';
import ActivitiesItem from '../ActivitiesItem/ActivitiesItem';
import CommentsContainer from '../CommentsContainer/CommentsContainer';
import { fetchAccountBalance } from '../../../../redux/actions/accountActions';
import ComingSoonList from '../../../../components/ComingSoonList';
import Balance from '../Balance';
import ClientCredit from '../ClientCredit';
import PolizaItem from '../PolizaItem/PolizaItem';

const SummaryContainer = withRouter(({ match, client }) => {
  const dispatch = useDispatch();
  /*  const activities = [
    {
      update_time: '15 min',
      update_time_title: '4 Jul',
      what: 'Arreglar header',
      who: 'fas fa-user',
    },
    {
      update_time: '2 min',
      update_time_title: '10 Jun',
      what: 'Implementar fuentes',
      who: 'fas fa-user',
    },
    {
      update_time: '1 h',
      update_time_title: '12 Dec',
      what: 'Imagenes dumy',
      who: 'fas fa-user',
    },
    {
      update_time: '3 day',
      update_time_title: '6 oct',
      what: 'Colores enlaces',
      who: 'fas fa-user',
    },
  ]; */
  const balance = useSelector(state => state.account.balance.data);

  useEffect(() => {
    dispatch(fetchAccountBalance(match.params.crmid));
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="SummaryContainer_secction">
        <Card className="mt-2 p-4 w-100 ">
          <div className="resume-section">
            <small className="resume-section__header">Razón social</small>
            <p className="resume-section__description mt-0 mb-2">{client?.accountname}</p>
          </div>
          <div className="resume-section">
            <small className="resume-section__header">RFC</small>
            <p className="resume-section__description mt-0 mb-2">{client?.siccode || '-'}</p>
          </div>
          <div className="resume-section">
            <small className="resume-section__header">Teléfono</small>
            <p className="resume-section__description mt-0 mb-2">{client?.phone || '-'}</p>
          </div>
          <div className="resume-section">
            <small className="resume-section__header">Primera venta</small>
            <p className="resume-section__description mt-0 mb-2">{client?.esp_fprimerventa || '-'}</p>
          </div>
          <div className="resume-section">
            <small className="resume-section__header">Ultima venta</small>
            <p className="resume-section__description mt-0 mb-2">{client?.esp_fultimaventa || '-'}</p>
          </div>
        </Card>
      </div>
      <Balance />
      <ClientCredit />
      {/* <div className="SummaryContainer_secction">
        <Card className="mt-2 w-100">
          <h3 className="m-2 d-flex justify-content-center SummaryContainer_title">Ventas</h3>
          {
            balance.length
              ? balance.map(item => (<SaleSummary currency={item.currency_code} total={item.total} pay={item.pagado} balance={item.balance} key={item} />))
              : (<SaleSummary currency="MXN" total={0} pay={0} balance={0} />)
          }
        </Card>
      </div> */}

      <div className="SummaryContainer_secction">
        <Card className="mt-2 w-100">
          <h3 className="m-2 d-flex justify-content-center SummaryContainer_title">Pólizas</h3>
          <Divider />
          <PolizaItem title="Poliza de soporte" total={client?.total_contratado || 0} amount={client?.total_consumido || 0} />
          <Divider />
        </Card>
      </div>

      {/*
      // se utilizaran mas adelante
      <div className="SummaryContainer_secction">
        <ActivitiesItem activities={[]} title />
      </div>

      <div className="SummaryContainer_secction">
        <CommentsContainer />
      </div> */}
    </>
  );
});

export default SummaryContainer;
