import React from 'react';
import './swip.scss';
import SwipeableViews from 'react-swipeable-views';
import HeaderTitle from '../HeaderTitle';
import SwipHeader from './components/SwipHeader';

const Swip = ({ tabs }) => {
  const [activeTab, setActiveTab] = React.useState(0);

  const handleChangeIndex = (index) => {
    setActiveTab(index);
  };

  const onSwipeAction = (e) => {
    const deltaX = Math.abs(e.deltaX);
    if (deltaX < 100) return false;
    switch (e.dir) {
      case 'Left':
        const v1 = activeTab === tabs.length - 1 ? 0 : activeTab + 1;
        handleChangeIndex(v1);
        break;
      case 'Right':
        const v = activeTab === 0 ? tabs.length - 1  : activeTab - 1;
        handleChangeIndex(v);
        break;
      default:
        break;
    }
  };

  const handleChange = (event, newValu) => {
    handleChangeIndex(newValu);
  };
  return (
    <div className="swip-container" style={{ height: 'inherit' }}>
      <SwipHeader activeTab={activeTab} handleChange={handleChange} onSwipeAction={onSwipeAction} tabs={tabs} />
      <HeaderTitle title={tabs[activeTab].title} />
      <SwipeableViews
        index={activeTab}
        className="d-flex flex-column f-1 h-100"
        onChangeIndex={handleChangeIndex}
      >
        {
          tabs.map((item, idx) => (
            <div className={`tab ${idx}`} style={{ marginTop: 1 }} key={item.title}>
              {activeTab === idx ? item.renderComponent : item.renderComponent }
            </div>
          ))
        }
      </SwipeableViews>
    </div>

  );
};

export default Swip;
