/* eslint-disable import/no-cycle */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-underscore-dangle */
import {
  ADD_CUSTOMERS,
  FILTER_CUSTOMERS,
  GET_ADDRESS,
  REMOVE_CUSTOMER,
  SELECT_CUSTOMER,
  SET_DEFAULT_CUSTOMER,
  SET_SCHEMA,
  SET_LAST_ORDER_CUSTOMER_ID,
} from '../actions/customerActions';

const defaultState = () => ({
  customers: [],
  customersById: {},
  customersByArray: [],
  defaultCustomer: {},
  selectedCustomer: {},
  isDefault: true,
  customerFormSchema: [],
  address: [],
  lastOrderCustomerId: undefined,
});

const setSchema = (state, customerFormSchema) => ({
  ...state,
  customerFormSchema,
});

const addCustomers = (state, customers) => ({
  ...state,
  customers,
  customersByArray: customers,
});

const addAddress = (state, address) => ({
  ...state,
  address,
});

const setLastCustomerId = (state, customerId) => ({
  ...state,
  lastOrderCustomerId: customerId,
});

const selectCustomer = (state, customer) => ({
  ...state,
  selectedCustomer: customer,
  isDefault: false,
});

const removeCustomer = (state) => {
  const customer = { ...state };
  customer.selectedCustomer = customer.defaultCustomer;
  customer.isDefault = true;
  return { ...state, ...customer };
};

const slugify = (str) => {
  const map = {
    '-' : ' ',
    '-' : '_',
    'a' : 'á|à|ã|â|À|Á|Ã|Â',
    'e' : 'é|è|ê|É|È|Ê',
    'i' : 'í|ì|î|Í|Ì|Î',
    'o' : 'ó|ò|ô|õ|Ó|Ò|Ô|Õ',
    'u' : 'ú|ù|û|ü|Ú|Ù|Û|Ü',
    'c' : 'ç|Ç',
    'n' : 'ñ|Ñ'
  };
  str = str.toLowerCase();
  for (const pattern in map) {
    str = str.replace(new RegExp(map[pattern], 'g'), pattern);
  }

  return str;
};

const filterCustomers = (state, target) => {
  const { customersByArray } = { ...state };
  const word = target.toLowerCase();

  if (word.length > 2) {
    const customers = customersByArray.filter(customer => (
      (customer.siccode || '').toLowerCase().indexOf(word) !== -1
      || slugify(customer.firstname || '').toLowerCase().indexOf(word) !== -1
      || slugify(customer.lastname || '').toLowerCase().indexOf(word) !== -1
      || slugify(customer.accountname || '').toLowerCase().indexOf(word) !== -1
      || slugify(customer.razon_social || '').toLowerCase().indexOf(word) !== -1
    ));

    return { ...state, customers };
  }
  return { ...state, customers: customersByArray };
};

export default function (state = defaultState(), action) {
  const { type } = action;
  switch (type) {
    case ADD_CUSTOMERS:
      return addCustomers(state, action.customers);
    case SET_DEFAULT_CUSTOMER:
      return { ...state, defaultCustomer: action.customer };
    case SELECT_CUSTOMER:
      return selectCustomer(state, action.customer);
    case REMOVE_CUSTOMER:
      return removeCustomer(state);
    case FILTER_CUSTOMERS:
      return filterCustomers(state, action.word);
    case SET_SCHEMA:
      return setSchema(state, action.customerFormSchema);
    case GET_ADDRESS:
      return addAddress(state, action.result);
    case SET_LAST_ORDER_CUSTOMER_ID:
      return setLastCustomerId(state, action.customerId);
    default:
      return state;
  }
}
