import React from 'react';
import PropTypes from 'prop-types';
/* Material */
import { Button, Collapse, LinearProgress } from '@material-ui/core';
/* Reactstrap */
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';

/* ReactToPrint */
import ReactToPrint from 'react-to-print';

import TicketToPrint from './tickets/TicketToPrint';

const TicketsModal = ({
  tabs,
  cart,
  title,
  print,
  toggle,
  isOpen,
  discount,
  customer,
  authUser,
  isLoading,
}) => {
  const toPrintRef = React.useRef();
  const discoutnExist = Boolean(cart.products.some(product => product.discount_percent > 0));
  React.useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        const btnPrint = document.querySelector('#button_to_print');
        // @ts-ignore
        if (btnPrint) btnPrint.click();
      }, 500);
    }
  }, [isOpen]);
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="sm" className="tickets_modal">
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody className="tickets_modal_body w-100 d-flex justify-content-center">
        {isLoading && <LinearProgress />}
        <Collapse in={!isLoading}>
          <TicketToPrint
            tabs={tabs}
            cart={cart}
            ref={toPrintRef}
            discount={discount}
            authUser={authUser}
            customer={customer}
            discoutnExist={discoutnExist}
          />
        </Collapse>
      </ModalBody>
      <ModalFooter>
        <ReactToPrint
          removeAfterPrint
          copyStyles
          trigger={() => (
            <Button
              id="button_to_print"
              color="primary"
              className="w-100"
              variant="contained"
            >
              {print}
            </Button>
          )}
          content={() => toPrintRef.current}
          onAfterPrint={() => toggle()}
        />
      </ModalFooter>
    </Modal>
  );
};

TicketsModal.propTypes = {
  print: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  tabs: PropTypes.object.isRequired,
  cart: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  customer: PropTypes.object.isRequired,
  authUser: PropTypes.object.isRequired,
  discount: PropTypes.object.isRequired,
};
TicketsModal.defaultProps = {
  print: 'Imprimir Ticket',
};

export default TicketsModal;
