import React from 'react';
import {
  List, ListItem, ListItemAvatar, Avatar, ListItemText,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBellSlash, faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { dom } from '@fortawesome/fontawesome-svg-core';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import moment from 'moment';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Typography from '@material-ui/core/Typography';

const UpdateList = ({ data }) => {
  const [expanded, setExpanded] = React.useState({});
  dom.watch();

  const formatShowDate = (date, action) => {
    const idx = date.indexOf('at');
    let fixDate = '';
    let subtring = '';
    if (idx !== -1) {
      fixDate = moment(date.replace('at', '')).format('DD MMM, h:mm a');
    } else {
      fixDate = moment(date.replace('en', '')).format('DD MMM, h:mm a');
    }
    subtring = fixDate.split(',');
    if (action === 'date') {
      return subtring[0];
    }

    return subtring[1];
  };

  const handleExpandClick = (item) => {
    expanded === item ? setExpanded({}) : setExpanded(item);
  };

  return (
    <div className="update-container">
      {
          data.length > 0
            ? (
              <List classes={{ root: 'py-0' }} className="update-container-list">
                {data.map((item, i) => (
                  <div key={i}>
                    <ListItem divider button>
                      <div className="update-time d-flex flex-column pr-2">
                        <span>{ formatShowDate(item.update_time_title, 'date')}</span>
                        <span>{formatShowDate(item.update_time_title, 'time')}</span>
                      </div>
                      <ListItemAvatar>
                        {
                          item.icon
                            ? (
                              <Avatar classes={{ root: 'update-avatar-bg' }}>
                                <span className="update-icon">
                                  <i className={item.icon} />
                                </span>
                              </Avatar>
                            )
                            : (<Avatar classes={{ root: 'update-avatar-bg' }} src="/img/ava.png" />)
                      }
                      </ListItemAvatar>
                      <ListItemText primary={item.who} secondary={item.what} classes={{ primary: 'update-title', secondary: 'update-subtitle' }} />
                      {
                        item.fields && item.fields.length
                          ? (
                            <IconButton
                              onClick={() => handleExpandClick(item)}
                              aria-expanded={expanded}
                              aria-label="show more"
                            >
                              {
                          expanded === item
                            ? (
                              <FontAwesomeIcon
                                color="#64707A"
                                icon={faAngleUp}
                              />
                            )
                            : (
                              <FontAwesomeIcon
                                color="#64707A"
                                icon={faAngleDown}
                              />
                            )
                        }

                            </IconButton>
                          )
                          : null
                      }

                    </ListItem>
                    <Collapse in={expanded === item} timeout="auto" mountOnEnter unmountOnExit>
                      <div>
                        <Stepper orientation="vertical">
                          {(item.fields && item.fields.length) ? item.fields.map(label => (
                            <Step key={item} active>
                              <StepLabel
                                classes={{ active: 'activity-title' }}
                                StepIconProps={{ classes: { root: 'colorBlue' } }}
                              >
                                {`${label.name} ${label.what}`}
                              </StepLabel>
                              <StepContent>
                                <Typography>{`${label.lbl_from}: ${label.prevalue}`}</Typography>
                                <Typography>{`${label.lbl_to}: ${label.postvalue}`}</Typography>
                              </StepContent>
                            </Step>
                          )) : null}
                        </Stepper>
                      </div>
                    </Collapse>
                  </div>
                ))
                    }
              </List>
            )
            : (
              <div className="empty-email">
                <FontAwesomeIcon
                  style={{
                    fontSize: 40, display: 'flex', justifyContent: 'center', alignItems: 'center',
                  }}
                  color="#64707A"
                  icon={faBellSlash}
                />
                <span>Sin actualizaciones </span>
              </div>
            )
        }
    </div>
  );
};

export default UpdateList;
