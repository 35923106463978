import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Radio from '@material-ui/core/Radio';
import { actionTypes, componentTypes, defaultSearchData } from '../../config';

const Searching = ({
  data,
  searchChange,
  openComponent,
  searchFilterValue,
  handleClickOpenComponent,
}) => {
  const selectFilter = useSelector(state => state.filters.searchBy);
  return (
    <List component="nav" classes={{ root: 'filter-list' }}>
      <ListItem button onClick={() => handleClickOpenComponent('buscar')}>
        <ListItemText primary="Buscar por:" secondary={searchFilterValue || ''} classes={{ primary: 'filter-item-title', secondary: 'filter-item-subtitle' }} />
        <ListItemText primary={openComponent === 'buscar' ? <ExpandLess /> : <ExpandMore />} classes={{ root: 'pr-0', primary: 'd-flex justify-content-end' }} />
      </ListItem>
      <Collapse in={openComponent === 'buscar'} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {
            data.map(item => (
              <ListItem button divider classes={{ root: 'filter-item-bg' }} key={item} onClick={() => searchChange(item, actionTypes.buscar)}>
                <ListItemText primary={item} classes={{ primary: 'filter-item-subItem' }} />
                <ListItemSecondaryAction>
                  <Radio
                    color="primary"
                    onChange={() => searchChange(item, actionTypes.buscar)}
                    checked={searchFilterValue === item || item === selectFilter}
                    classes={{ root: 'filter-item-checkbox', colorPrimary: 'filter-item-checkbox', checked: 'filter-item-checkbox' }}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))
            }
        </List>
      </Collapse>
    </List>
  ); };

Searching.prototype = {
  openComponent: PropTypes.string.isRequired,
  handleClickOpenComponent: PropTypes.func.isRequired,
  data: PropTypes.array,
};
Searching.defaultProps = {
  data: defaultSearchData,
};

export default Searching;
