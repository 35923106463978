import {
  setItem, findItem, removeItem,
} from './storage';

const storageId = 'pricebook';

export const setStorage = (obj) => { setItem(storageId, obj, false); };

export const getStorage = () => findItem(storageId);

export const removeStorage = () => removeItem(storageId);

export default {
  setStorage,
  getStorage,
  removeStorage,
};
