/* eslint-disable no-tabs */
import React from 'react';

const TransferIcon = props => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    width="100px"
    height="100px"
    viewBox="0 0 100 100"
    enableBackground="new 0 0 100 100"
    xmlSpace="preserve"
    {...props}
  >
    <g>
      <path d="M54.876,43.756c0,0.82-0.01,1.508,0.002,2.195c0.016,0.858-0.378,1.288-1.25,1.259c-0.327-0.011-0.654-0.005-0.981-0.004
		c-0.813,0.001-1.186-0.403-1.182-1.216c0.004-0.724,0.219-1.647-0.139-2.113c-0.342-0.445-1.312-0.39-1.992-0.596
		c-2.222-0.673-3.854-1.997-4.56-4.279c-0.368-1.188-0.106-1.521,1.13-1.53c0.545-0.004,1.095,0.045,1.634-0.006
		c0.961-0.09,1.636,0.129,2.053,1.137c0.194,0.469,0.777,0.878,1.279,1.1c1.677,0.74,3.396,0.686,5.067-0.056
		c0.804-0.356,1.404-0.943,1.39-1.903c-0.015-0.986-0.675-1.533-1.516-1.774c-1.567-0.447-3.179-0.737-4.74-1.2
		c-1.349-0.399-2.737-0.795-3.962-1.46c-3.181-1.727-3.638-6.015-0.833-8.328c1.076-0.887,2.538-1.315,3.846-1.905
		c0.381-0.171,0.838-0.175,1.351-0.272c0-0.811-0.002-1.568,0-2.327c0.002-0.705,0.358-1.077,1.064-1.097
		c0.399-0.011,0.799-0.004,1.199-0.006c0.764-0.004,1.126,0.38,1.135,1.141c0.009,0.76-0.232,1.731,0.145,2.219
		c0.364,0.472,1.381,0.421,2.1,0.638c2.146,0.649,3.728,1.919,4.413,4.13c0.391,1.262,0.153,1.586-1.145,1.599
		c-0.436,0.004-0.875-0.032-1.308,0.006c-1.08,0.097-1.987,0.007-2.43-1.249c-0.142-0.404-0.692-0.749-1.132-0.949
		c-1.754-0.796-3.535-0.704-5.265,0.091c-0.766,0.352-1.291,0.959-1.248,1.878c0.042,0.875,0.575,1.435,1.354,1.66
		c1.6,0.463,3.239,0.788,4.833,1.267c1.347,0.403,2.745,0.777,3.96,1.452c3.329,1.848,3.693,6.178,0.684,8.536
		c-1.042,0.816-2.443,1.183-3.698,1.714C55.779,43.655,55.361,43.664,54.876,43.756z"
      />
      <g>
        <path d="M26.527,38.803c0,5.435,0.079,10.871-0.033,16.303c-0.056,2.737,0.362,5.321,1.293,7.881
			c0.958,2.63,1.762,5.315,2.717,8.23c0.499-0.538,0.864-0.89,1.182-1.279c4.125-5.04,8.243-10.086,12.365-15.13
			c2.044-2.499,5.337-2.967,8.014-1.143c2.588,1.762,3.248,4.936,1.595,7.724c-5.118,8.631-10.096,17.349-15.437,25.838
			c-1.907,3.03-4.633,5.561-7.106,8.207c-1.586,1.698-3.732,1.837-5.841,1.25c-4.083-1.134-8.126-2.407-12.192-3.606
			c-2.195-0.648-3.027-2.368-3.041-4.375c-0.067-9.682-0.052-19.365,0.018-29.047c0.015-1.947,0.861-3.628,2.21-5.143
			c4.409-4.955,8.712-10.003,13.051-15.019c0.261-0.302,0.493-0.63,0.738-0.946C26.217,38.633,26.371,38.718,26.527,38.803z"
        />
        <path d="M47.948,77.481c1.538-2.528,3.028-5.089,4.661-7.555c0.258-0.391,1.16-0.488,1.768-0.494
			c4.839-0.038,9.678-0.021,14.518-0.021c0.649,0,1.297,0,2.216,0c0-1.444,0-2.775,0-4.181c1.204,0,2.14,0,3.398,0
			c-0.172,2.592,0.459,5.184-0.626,7.71c-1,2.328-2.744,3.912-5.242,4.089c-6.785,0.479-13.583,0.748-20.375,1.1
			C48.16,77.913,48.053,77.698,47.948,77.481z"
        />
        <path d="M82.385,50.905c-4.912,0-9.72,0-14.624,0c0-1.088,0-1.958,0-3.034c4.878,0,9.69,0,14.623,0
			c0.073-1.642,0.137-3.093,0.229-5.152c2.671,2.397,4.935,4.431,7.387,6.632c-2.425,2.196-4.686,4.245-6.947,6.294
			c-0.154-0.074-0.312-0.147-0.467-0.222C82.522,53.985,82.459,52.549,82.385,50.905z"
        />
        <path d="M57.468,58.794c2.416-2.171,4.669-4.196,7.342-6.597c0.09,2.017,0.153,3.408,0.226,5.018c4.897,0,9.751,0,14.717,0
			c0,1.082,0,1.942,0,3.04c-4.865,0-9.716,0-14.688,0c-0.082,1.668-0.153,3.115-0.251,5.11
			C62.179,63.007,59.915,60.982,57.468,58.794z"
        />
        <path
          fill="#FFFFFF"
          d="M52.964,7.237c0.418,0.002,0.838-0.024,1.253,0.007c1.117,0.082,1.966,0.715,1.734,1.798
			c-0.143,0.67-0.966,1.451-1.654,1.686c-0.855,0.293-1.972,0.31-2.817,0.005c-0.733-0.265-1.247-1.142-1.858-1.747
			c0.637-0.584,1.218-1.252,1.931-1.713C51.892,7.055,52.486,7.235,52.964,7.237z"
        />
        <path d="M74.48,10.576c-0.017-3.778-2.81-6.532-6.678-6.866c-9.96-0.859-19.917-1.024-29.881,0.004
			c-4.036,0.416-6.726,3.144-6.733,7.19c-0.026,16.671-0.021,33.342,0.012,50.013c0.002,0.945,0.357,1.892,0.576,2.979
			c2.053-1.678,2.87-3.426,2.85-5.895c-0.122-14.16-0.063-28.299-0.059-42.46c0-0.575,0.06-1.1,0.095-1.754
			c12.181,0,24.282,0,36.498,0c0,9.816,0,19.415,0,29.231c1.309,0,2.183,0,3.229,0c0.043-0.654,0.105-0.855,0.106-1.203
			C74.5,31.419,74.529,20.974,74.48,10.576z"
        />
      </g>
    </g>
  </svg>
);

export default TransferIcon;
