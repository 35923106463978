import React from 'react';
import PropTypes from 'prop-types';

/* Material */
import { CompareArrows } from '@material-ui/icons';

/* CountUp */
import CountTo from '../../../../CountTo/count-to';

const AmountTendered = ({
  title,
  falta,
  cambio,
  charge,
  paidOut,
  totalToPay,
}) => {
  const inputMoney = Number(paidOut + charge);
  const balance = Number(Math.abs(totalToPay - inputMoney));
  return (
    <>
      <div className="w-100 checkoutGeneralTitle title_grid">
        <span>{title}</span>
        <span>{(totalToPay > inputMoney) ? (falta) : (cambio)}</span>
      </div>
      <div className="w-100 h-auto amount_tendered">
        <div className="amount_tendered_total">
          <div className="amount_tendered_symbol">$</div>
          <div className="amount_tendered_field">
            <CountTo to={inputMoney} />
          </div>
        </div>
        <CompareArrows />
        <div className={totalToPay >= inputMoney ? 'amount_tendered_balance_falta' : 'amount_tendered_balance_cambio'}>
          <div className={totalToPay >= inputMoney ? 'amount_tendered_balance_falta_symbol' : 'amount_tendered_balance_cambio_symbol'}>$</div>
          <div className="amount_tendered_field_orange">
            <CountTo to={balance} />
          </div>
        </div>
      </div>
    </>
  );
};

AmountTendered.propTypes = {
  title: PropTypes.string,
  falta: PropTypes.string,
  cambio: PropTypes.string,
  charge: PropTypes.number,
  paidOut: PropTypes.number.isRequired,
  totalToPay: PropTypes.number.isRequired,
};

AmountTendered.defaultProps = {
  charge: 0,
  title: 'Total',
  falta: 'Balance',
  cambio: 'Cambio',
};

export default AmountTendered;
