import fetch from 'cross-fetch';
import ConfigurationAPI from './ConfigurationAPI';

class DocumentsAPI extends ConfigurationAPI {
  getDocuments = async (crmid, page = 1, search = '') => {
    try {
      const response = await fetch(
        `${this.url}/list/Documents?related_to=${crmid}&page=${page}&search=${search}`,
        this.credentials,
      );
      const json = await response.json();
      return json;
    } catch (err) {
      return { succes: false, result: [], message: err };
    }
  }

    uploadFileToDocuments = async (crmid, files) => {
      this.credentials.method = 'POST';
      const data = {
        salesorderid: crmid,
        files,
      };
      this.credentials.body = JSON.stringify(data);
      try {
        const response = await fetch(
          `${this.url}/TreebesGTO/${crmid}/document`,
          this.credentials,
        );
        const json = await response.json();
        return json;
      } catch {
        return { succes: false, result: [], message: 'Error al subir archivo(s)' };
      }
    }


    uploadFileToTask = async (crmid, files) => {
      this.credentials.method = 'POST';
      const data = {
        salesorderid: crmid,
        files,
      };
      this.credentials.body = JSON.stringify(data);
      try {
        const response = await fetch(
          `${this.url}/ProjectTask/${crmid}/document`,
          this.credentials,
        );
        const json = await response.json();
        return json;
      } catch {
        return { succes: false, result: [], message: 'Error al subir archivo(s)' };
      }
    }

  deleteDocument = async (crmid) => {
    this.credentials.method = 'DELETE';
    try {
      const response = await fetch(`${this.url}/Documents/${crmid}`, this.credentials);
      const json = await response.json();
      return json;
    } catch (err) {
      return { succes: false, result: [], message: `Error al borra un archivo: ${err}` };
    }
  }
}

export default DocumentsAPI;
