/* eslint-disable react/prop-types */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import './timbrarExpense.scss';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import MoreVert from '@material-ui/icons/MoreVert';
import Close from '@material-ui/icons/Close';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Share from '@material-ui/icons/Share';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { fetchListCashFlow } from '../../../../redux/actions/financeActions';
import { toolsHelper } from '../../../../helpers';
import VerificarCobro from '../../../../components/Fde/components/timbrar/VerificarCobro';
import Sharemenu from '../../../../components/Fde/components/timbrar/Sharemenu';
import SatLogo from '../../../../components/Icons/SatLogo';


const TimbrarExpense = ({ open, handleClose, match }) => {
  const dispatch = useDispatch();
  const data = useSelector(state => state.finance.cashFlow.data);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [fdeSelected, setFdeSelected] = React.useState(null);
  const [fdeSelectedObj, setFdeSelectedObj] = React.useState(null);
  const [optionSelected, setOptionSelected] = React.useState(null);
  const openit = Boolean(anchorEl);
  const [openVerification, setOpenVerification] = React.useState(false);
  const [openShare, setOpenShare] = React.useState(false);

  const handleClick = (event, item) => {
    setFdeSelected(item.crmid);
    setFdeSelectedObj(item);
    setAnchorEl(event.currentTarget);
  };
  const handleClickOpen = () => {
    setOpenVerification(true);
  };
  const handleClickOpenShare = () => {
    setOpenShare(true);
  };
  const handleCloseVerificacion = () => {
    setOpenVerification(false);
  };
  const handleCloseShare = () => {
    setOpenShare(false);
  };

  const closeAll = () => {
    handleCloseVerificacion();
    handleCloseShare();
    handleClose();
    /* onClose(); */
  };
  const handleCloseit = (option) => {
    setOptionSelected(option);
    setAnchorEl(null);
    if (option === 'Timbrar') {
      handleClickOpen();
    }
    if (option === 'Compartir') {
      handleClickOpenShare();
    }
  };

  React.useEffect(() => {
    if (open) {
      dispatch(fetchListCashFlow(0, '', match.params.crmid));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  return (
    <>
      <Dialog fullScreen onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
        <div className="fde-header d-flex justify-content-between align-items-center">
          <div>Flujo de efectivo</div>
          <div>
            <IconButton edge="end" aria-label="comments" onClick={handleClose}>
              <Close />
            </IconButton>
          </div>
        </div>
        { data.length === 0 && (
        <List dense>
          <ListItem classes={{ root: 'bg-white' }}>
            <ListItemText
              primary="Sin Flijo de efectivo"
              secondary="El gasto no cuenta con flijo de efectivo"
            />
          </ListItem>
        </List>
        )}
        { data.length > 0 && (
        <List>
          {
          data.map(value => (
            <ListItem key={`key_${value.crmid}`}>
              <div className="w-100 h-100 d-flex justify-content-between align-items-center">
                <div
                  className={
                      `fde_list_icon ${
                        value.tfde_tipo === 'Ingreso' ? (
                          'fde_list_icon_in'
                        ) : (
                          'fde_list_icon_out'
                        )
                      }`
                    }
                >
                  {value.tfde_tipo === 'Ingreso' ? (
                    <FontAwesomeIcon icon="sign-out-alt" />
                  ) : (
                    <FontAwesomeIcon
                      icon="sign-in-alt"
                      style={{
                        transform: 'rotate(180deg)',
                      }}
                    />
                  )}
                </div>
                <div className="d-flex flex-column justify-content-between mr-auto ml-2">
                  <span className="fde_list_meth">{value.tfde_metodo}</span>
                  <span className="fde_list_acco">{value.cuenta}</span>
                </div>
                <div className="d-flex flex-column justify-content-between">
                  <span className="fde_list_mont">
                    {`$ ${toolsHelper.formatMoney(
                      value.monto,
                    )}`}
                  </span>
                  <span className="fde_list_date">
                    {`${value.createdtime}`}
                  </span>
                </div>
                <div>
                  <IconButton edge="end" aria-label="comments" onClick={event => handleClick(event, value)}>
                    <MoreVert />
                  </IconButton>
                  <Menu
                    id="long-menu"
                    className="horiz-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={openit}
                    onClose={() => handleCloseit()}
                  >
                    <MenuItem onClick={() => handleCloseit('Compartir')}>
                      <Share fill="#65707a" />
                          Compartir
                    </MenuItem>
                    <MenuItem disabled={value.xmlpago !== undefined} onClick={() => handleCloseit('Timbrar')}>
                      <SatLogo fill="#65707a" />
                          Timbrar
                    </MenuItem>
                  </Menu>
                </div>
              </div>
            </ListItem>
          ))
        }
        </List>
        )}
      </Dialog>
      <VerificarCobro open={openVerification} handleClose={handleCloseVerificacion} typo={optionSelected} fde={fdeSelected} closeAll={closeAll} />
      <Sharemenu open={openShare} handleClose={handleCloseShare} typo={optionSelected} fde={fdeSelectedObj} />
    </>
  );
};


export default withRouter(TimbrarExpense);
