/* eslint-disable import/no-cycle */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListSubheader from '@material-ui/core/ListSubheader';
import Chip from '@material-ui/core/Chip';
import { isMobile } from 'react-device-detect';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileExcel,
  faFilePdf,
  faFileWord,
  faFileImage,
  faTimes,
  faDownload,
} from '@fortawesome/free-solid-svg-icons';
import AccountAPI from '../../../../../../api/AccountAPI';
import { downloadFile } from '../../../../../../redux/actions/orderActions';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const EmailModalDetail = (props) => {
  const authUser = useSelector(state => state.authUser);
  const [load, setLoad] = React.useState(false);
  const [loadDownload, setLoadDownload] = React.useState(false);
  const [attachment, setAttachment] = React.useState([]);
  const accountApi = new AccountAPI();
  const dispatch = useDispatch();
  const {
    onClose, open, email,
  } = props;

  const handleClose = () => {
    onClose();
  };
  const getEmailAttach = async (id) => {
    setLoad(true);
    const response = await accountApi.getEmailAttach(id);
    if (response.result.attachments.length) {
      await setAttachment(response.result.attachments);
    }
    setLoad(false);
  };

  const DownloadFile = async (item) => {
    setLoadDownload(true);
    dispatch(downloadFile(item.url, item.name));
    setLoadDownload(false);
  };

  const getIconByExtension = (extension) => {
    switch (extension) {
      case 'pdf':
        return (<FontAwesomeIcon style={{ fontSize: 23 }} color="#313C52" icon={faFilePdf} />);
      case 'doc':
        return (<FontAwesomeIcon style={{ fontSize: 23 }} color="#313C52" icon={faFileWord} />);
      case 'xlsx':
        return (<FontAwesomeIcon style={{ fontSize: 23 }} color="#313C52" icon={faFileExcel} />);
      default:
        return (<FontAwesomeIcon style={{ fontSize: 23 }} color="#313C52" icon={faFileImage} />);
    }
  };

  const renderAttachmentList = () => {
    if (attachment.length) {
      return (
        <List subheader={<ListSubheader>Adjuntos</ListSubheader>} classes={{ root: 'py-0 w-100' }}>
          {
            attachment.map(item => (
              <ListItem dense key={item}>
                <ListItemIcon>
                  {getIconByExtension(item.type)}
                </ListItemIcon>
                <ListItemText
                  primary={item.name}
                  classes={{ root: 'py-0' }}
                />
                <ListItemText
                  primary={
                    /* isMobile
                      ? (
                        <a className="d-flex justify-content-end" href={`pdf://download?token=${authUser.token}&nombre=${item.name}&url=${item.url}`}>
                          <IconButton edge="end" aria-label="comments">
                            {
                            loadDownload ? <CircularProgress /> : <FontAwesomeIcon style={{ fontSize: 15 }} icon={faDownload} />
                          }
                          </IconButton>
                        </a>
                      )
                      :  */(
                        <div className="d-flex justify-content-end" onClick={() => DownloadFile(item)}>
                          <IconButton edge="end" aria-label="comments">
                            {
                            loadDownload ? <CircularProgress /> : <FontAwesomeIcon style={{ fontSize: 15 }} icon={faDownload} />
                          }
                          </IconButton>
                        </div>
                      )
                }
                  classes={{ root: 'pr-0' }}
                />
              </ListItem>
            ))
          }
        </List>
      );
    }
  };
  useEffect(() => {
    if (email) {
      getEmailAttach(email.crmid);
    }
  // eslint-disable-next-line
  }, [email]);


  return (

    <Dialog
      open={open}
      fullScreen
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      {
            email ? (
              <>
                <AppBar style={{ backgroundColor: '#f6f6f7' }}>
                  <Toolbar className="d-flex justify-content-between" classes={{ root: 'toolbar' }}>
                    <Typography variant="h6" style={{ color: '#64707a' }}>
                        Detalles del correo
                    </Typography>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                      <FontAwesomeIcon color="#64707a" icon={faTimes} />
                    </IconButton>
                  </Toolbar>
                </AppBar>
                <div className="d-flex flex-column justify-content-between h-100" style={{ marginTop: 48 }}>
                  <List>
                    <ListItem>
                      <ListItemText primary="De:" secondary={<Chip component="span" label={email.from_email} />} />
                    </ListItem>
                    <ListItem>
                      <ListItemText classes={{ root: 'pr-0', secondary: 'text-justify' }} primary={email.subject} secondary={<span dangerouslySetInnerHTML={{ __html: email.body }} />} />
                    </ListItem>
                  </List>
                  <>
                    {
                          load
                            ? (<div className="d-flex justify-content-center align-items-center"><CircularProgress /></div>)
                            : renderAttachmentList()
                      }
                  </>
                </div>
              </>
            )
              : (
                <CircularProgress />
              )
        }

    </Dialog>
  );
};
EmailModalDetail.prototype = {};
export default EmailModalDetail;
