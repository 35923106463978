import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import {
  Card,
  Switch,
  Collapse,
  ListItem,
  ListItemText,
  ListItemIcon,
  LinearProgress,
  ListItemSecondaryAction,
} from '@material-ui/core';
import {
  Check,
  Close,
} from '@material-ui/icons';
/* Components */
import RadioSettings from '../../RadioSettings';
import { getSosStatus } from '../../../../../../helpers/tools';

const ConfGeneral = ({
  loads,
  authUser,
  concepts,
  tabSellDesc,
  generalTitle,
  tabSellTitle,
  conceptsEnable,
  printSizeTitle,
  conceptsDisable,
  printSizeOptions,
  dispatchHandleSellTab,
  dispatchHandlePrintSize,
  dispatchHandleCartConcept,
  dispatchChangeAlmAuthUser,
  dispatchChangeSellOrderStatusAuthUser,
}) => {
  const { user } = authUser;
  const { config } = user;
  const loading = Boolean(loads.profileIsLoading);
  const generalDisable = Boolean(config.pos_config_alm);
  const sellTabBool = Boolean(config.pos_sell_tab);
  const conceptBool = Boolean(config.pos_cart_concepts);
  const deliverOptions = user.pos_auto_alm.filter(x => x.id !== 'Preguntar');
  return (
    <Card
      className={
        isMobile ? 'settings__cardMobile px-4 py-2' : 'settings__card px-4 py-2'
      }
    >
      <div className="settings__title">{generalTitle}</div>
      <Collapse in={loading}>
        <LinearProgress />
      </Collapse>
      <ListItem dense>
        <ListItemIcon>
          {user.config.pos_sininv ? <Check /> : <Close />}
        </ListItemIcon>
        <ListItemText
          primary="Permite Venta sin existencia"
          secondary={user.config.pos_sininv ? 'Si' : 'No'}
        />
      </ListItem>
      <ListItem
        dense
        button
        disabled={loading || generalDisable}
        onClick={() => dispatchHandleCartConcept(!conceptBool)}
      >
        <ListItemText
          primary={concepts}
          secondary={conceptBool ? (conceptsEnable) : (conceptsDisable)}
        />
        <ListItemSecondaryAction>
          <Switch
            disabled={loading || generalDisable}
            checked={conceptBool}
            onClick={() => dispatchHandleCartConcept(!conceptBool)}
          />
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem
        dense
        button
        disabled={loading || generalDisable}
        onClick={() => dispatchHandleSellTab(!sellTabBool)}
      >
        <ListItemText
          primary={tabSellTitle}
          secondary={tabSellDesc}
        />
        <ListItemSecondaryAction>
          <Switch
            disabled={loading || generalDisable}
            checked={sellTabBool}
            onClick={() => dispatchHandleSellTab(!sellTabBool)}
          />
        </ListItemSecondaryAction>
      </ListItem>
      <RadioSettings
        loading={loading}
        disabled={generalDisable}
        options={user.sostatus}
        value={getSosStatus(user.config.sostatus)}
        title="Estado de una Orden al ser creada"
        setOption={dispatchChangeSellOrderStatusAuthUser}
        isOrderStatus
      />
      <RadioSettings
        loading={loading}
        disabled={generalDisable}
        title="Entrega automatica posterior al cobro"
        options={deliverOptions}
        value={user.config.pos_auto_alm}
        setOption={dispatchChangeAlmAuthUser}
      />
      <RadioSettings
        loading={loading}
        disabled={generalDisable}
        title={printSizeTitle}
        options={printSizeOptions}
        value={user.config.pos_print_size}
        setOption={dispatchHandlePrintSize}
      />
    </Card>
  );
};

ConfGeneral.propTypes = {
  concepts: PropTypes.string,
  tabSellDesc: PropTypes.string,
  tabSellTitle: PropTypes.string,
  generalTitle: PropTypes.string,
  printSizeTitle: PropTypes.string,
  conceptsEnable: PropTypes.string,
  conceptsDisable: PropTypes.string,
  printSizeOptions: PropTypes.array,
  loads: PropTypes.object.isRequired,
  authUser: PropTypes.object.isRequired,
  dispatchHandleSellTab: PropTypes.func.isRequired,
  dispatchHandlePrintSize: PropTypes.func.isRequired,
  dispatchHandleCartConcept: PropTypes.func.isRequired,
  dispatchChangeAlmAuthUser: PropTypes.func.isRequired,
  dispatchChangeSellOrderStatusAuthUser: PropTypes.func.isRequired,
};

ConfGeneral.defaultProps = {
  concepts: 'Crear partidas',
  generalTitle: 'Configuración General',
  tabSellTitle: 'Ver orden de venta porterior al cobro',
  printSizeTitle: 'Cambiar Tamaño del ticket de impresión',
  tabSellDesc: 'Permite ver la orden de venta una vez generada (cobrara o guardada).',
  conceptsDisable: 'No permite crear partidas de producto, si agrega un producto que ya existe en el carrito, solo cambiará la cantidad.',
  conceptsEnable: 'Permite crear varias partidas de un mismo producto; si agrega un producto que ya existe en su carrito, este se añadira como un concepto adicional, no cambia cantidades.',
  printSizeOptions: [
    {
      id: '7cm',
      value: '7cm',
    },
    {
      id: '8cm',
      value: '8cm',
    },
    {
      id: '9cm',
      value: '9cm',
    },
  ],
};

export default ConfGeneral;
