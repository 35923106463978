import React from 'react';
import PropTypes from 'prop-types';

import { Drawer } from '@material-ui/core';
import CartMobileOptionsItem from './CartMobileOptionsItem';

const CartMobileOptions = ({
  opt,
  toggle,
  options,
}) => (
  <Drawer
    anchor="top"
    open={opt}
    onClose={toggle}
    onOpen={toggle}
    className="checkout_drawer"
    disableSwipeToOpen={false}
  >
    <div className="w-100 d-flex p-3 flex-wrap align-items-center">
      {options.map(x => (<CartMobileOptionsItem key={`cart_options_${x.title}`} {...x} />))}
    </div>
  </Drawer>
);

CartMobileOptions.propTypes = {
  opt: PropTypes.bool,
  toggle: PropTypes.func,
  options: PropTypes.array,
};

CartMobileOptions.defaultProps = {
  opt: false,
  toggle: () => {},
  options: [],
};

export default CartMobileOptions;
