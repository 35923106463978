/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import {
  handleCheckoutBill,
  handlePrintAfterSell,
  changeGenericButtonCaja,
} from '../../../../../../redux/actions/authUserActions';

import {
  configProductColumnDef,
} from '../../../../../../redux/actions/productActions';

/* Components */
import ConfCaja from '../components/ConfCaja';
import ConfFastSearch from '../components/ConfFastSearch';
import ConfColumnasProducts from '../components/ConfColumnasProducts';

const Caja = ({
  authUser,
  dispatchChangeGenericButtonCaja,
  dispatchHandlePrintAfterSell,
  dispatchHanldeCheckoutBill,
  dispatchProductColumnDef,
  loads,
  product,
}) => (
  <div className="settings_general">
    <ConfCaja
      loads={loads}
      authUser={authUser}
      dispatchHanldeCheckoutBill={dispatchHanldeCheckoutBill}
      dispatchChangeGenericButtonCaja={dispatchChangeGenericButtonCaja}
      dispatchHandlePrintAfterSell={dispatchHandlePrintAfterSell}
    />
    <ConfColumnasProducts
      loads={loads}
      product={product}
      authUser={authUser}
      dispatchProductColumnDef={dispatchProductColumnDef}
    />
    <ConfFastSearch authUser={authUser} />
  </div>
);

Caja.propTypes = {
  authUser: PropTypes.object.isRequired,
  dispatchChangeGenericButtonCaja: PropTypes.func.isRequired,
  dispatchHandlePrintAfterSell: PropTypes.func.isRequired,
  dispatchHanldeCheckoutBill: PropTypes.func.isRequired,
  dispatchProductColumnDef: PropTypes.func.isRequired,
  loads: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  loads: state.loads,
  product: state.product,
  authUser: state.authUser,
});

const mapDispatchToProps = dispatch => ({
  dispatchProductColumnDef: field => dispatch(configProductColumnDef(field)),
  dispatchHanldeCheckoutBill: label => dispatch(handleCheckoutBill(label.value)),
  dispatchChangeGenericButtonCaja: label => dispatch(changeGenericButtonCaja(label.value)),
  dispatchHandlePrintAfterSell: mode => dispatch(handlePrintAfterSell(mode)),
});


export default connect(mapStateToProps, mapDispatchToProps)(Caja);
