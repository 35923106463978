/* eslint-disable import/no-cycle */
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PosMobile from './PosMobile';

const mapStateToProps = (state) => {
  const { authUser, loads } = state;
  const someLoading = Boolean(loads.productIsLoading || loads.orderIsLoading || loads.cotizacionIsLoading);
  return ({
    authUser,
    someLoading,
  });
};

export default withRouter(connect(mapStateToProps)(PosMobile));
