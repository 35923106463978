import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import List from '@material-ui/core/List';
import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import NotificationImportant from '@material-ui/icons/NotificationImportant';
import moment from 'moment';
import ListItemSimple1 from '../../../../components/ListItemSimple1/index.jsx';

const ClientList = withRouter(({ data, history }) => {
  const onClickItem = (id) => {
    history.push(`clients/${id}`);
  };
  const selectFilter = useSelector(state => state.filters.orderBy);

  useEffect(() => {
    switch (selectFilter) {
      case 'Mas nueva':
        data.sort((a, b) => moment(b.modifiedtime).diff(moment(a.modifiedtime)));
        break;
      case 'Mas antigua':
        data.sort((a, b) => moment(a.modifiedtime).diff(moment(b.modifiedtime)));
        break;
      case 'Alfabético A-Z':
        data.sort((a, b) => b.accountname.localeCompare(a.accountname));
        break;
      case 'Alfabético Z-A':
        data.sort((a, b) => a.accountname.localeCompare(b.accountname));
        break;

      default:
        break;
    }
  // eslint-disable-next-line
  }, [selectFilter]);

  return (
    data.length === 0
      ? (
        <List className="bg-white" classes={{ root: 'py-0' }}>
          <ListItem>
            <Avatar className="size-30 bg-avatar"><NotificationImportant /></Avatar>
            <ListItemText className="br-break" primary="No encontrado" />
          </ListItem>
        </List>
      )
      : (
        <List className="" classes={{ root: 'py-0' }}>
          {
            data.map(client => <ListItemSimple1 key={client.crmid} client={client} onClickItem={onClickItem} />)
          }
        </List>
      )

  );
});

ClientList.propTypes = {
  data: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
};
ClientList.defaultProps = {
  data: [],
  history: {},
};

export default ClientList;
