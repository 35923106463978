/* eslint-disable no-useless-escape */
/* eslint-disable react/jsx-no-duplicate-props  */

import React from 'react';
import PropTypes from 'prop-types';

import {
  Chip,
  Tooltip,
  TextField,
  ButtonBase,
  InputAdornment,
} from '@material-ui/core';
import {
  Search,
  FastForward,
} from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

let timeout = null;

const SearchBar = ({
  cart,
  classes,
  searchQty,
  searchVal,
  searchMode,
  setQtyState,
  placeholder,
  tooltipTitle,
  handleOnKeyPress,
  handleSearchMode,
  desableSearchMode,
  handleChangeSearch,
  dispatchSelectFastOption,
  dispatchUpdateProductQuantityInCart,
}) => {
  const [open, setOpen] = React.useState(false);
  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  return (
    <Tooltip
      open={open}
      enterDelay={500}
      leaveDelay={100}
      placement="top"
      title={tooltipTitle}
      disableFocusListener
      disableHoverListener
      disableTouchListener
    >
      <TextField
        id="search_bar_query"
        autoComplete={searchMode ? 'off' : 'on'}
        onChange={(event) => {
          handleChangeSearch(event);
        }}
        className="search_bar"
        type="search"
        classes={{
          root: classes.inputRoot,
        }}
        variant="outlined"
        onKeyUp={(e) => {
          // @ts-ignore
          const { value } = e.target;
          e.stopPropagation();
          const code = e.keyCode || e.which;
          if (code === 9) {
            handleSearchMode();
            // @ts-ignore
            e.target.select();
            e.stopPropagation();
          } else if (code === 13) {
            e.preventDefault();
            if (searchMode) {
              if (searchVal === '' && searchQty > 0) {
                const { lastProductAdded } = cart;
                if (Object.keys(lastProductAdded).length > 0) {
                  const { row } = lastProductAdded;
                  dispatchUpdateProductQuantityInCart(row, searchQty);
                }
              } else {
                dispatchSelectFastOption(searchQty);
                // @ts-ignore
                e.target.select();
                e.stopPropagation();
              }
            } else {
              setTimeout(() => {
                // @ts-ignore
                const row = document.querySelector('.ag-center-cols-container').querySelector("div[row-index='0']");
                if (row) {
                  // @ts-ignore
                  row.click();
                }
              }, 500);
            }
          } else if (code !== 40) {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
              handleOnKeyPress(value);
            }, 100);
          } else {
            const agGridTable = document.querySelector('#agProductTable');
            if (agGridTable) {
              // @ts-ignore
              e.currentTarget.blur();
              // @ts-ignore
              agGridTable.focus();
              setQtyState(0);
            }
          }
        }}
        onFocus={(e) => {
          e.target.select();
          setQtyState(0);
          setTimeout(() => {
            handleTooltipOpen();
            setTimeout(() => {
              handleTooltipClose();
            }, 5000);
          }, 2500);
        }}
        onBlur={() => handleTooltipClose()}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <ButtonBase
                disabled={desableSearchMode}
                className="search_bar_modebtn"
                onClick={() => handleSearchMode()}
              >
                {searchMode ? (
                  <FastForward color="primary" />
                ) : (
                  <Search />
                )}
              </ButtonBase>
            </InputAdornment>
          ),
          endAdornment: (searchQty > 0 && (
            <InputAdornment position="end">
              <Chip label={searchQty} />
            </InputAdornment>
          )),
        }}
        inputProps={{
          style: { padding: '0.8em 0' },
          pattern: '([\w|\s|\n])*\w+',
        }}
        // @ts-ignore
        placeholder={placeholder}
      />
    </Tooltip>
  );
};

SearchBar.propTypes = {
  cart: PropTypes.object,
  searchMode: PropTypes.bool,
  setQtyState: PropTypes.func,
  placeholder: PropTypes.string,
  tooltipTitle: PropTypes.string,
  searchVal: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  handleOnKeyPress: PropTypes.func,
  handleChangeSearch: PropTypes.func,
  classes: PropTypes.object.isRequired,
  searchQty: PropTypes.number.isRequired,
  handleSearchMode: PropTypes.func.isRequired,
  desableSearchMode: PropTypes.bool.isRequired,
  dispatchSelectFastOption: PropTypes.func.isRequired,
  dispatchUpdateProductQuantityInCart: PropTypes.func.isRequired,
};

SearchBar.defaultProps = {
  cart: {},
  searchVal: '',
  searchMode: false,
  placeholder: 'Buscar ...',
  setQtyState: () => {},
  handleOnKeyPress: () => {},
  handleChangeSearch: () => {},
  tooltipTitle: 'Busqueda',
};

// @ts-ignore
export default withStyles(styles)(SearchBar);
