/* eslint-disable no-unused-vars */
/* eslint-disable import/no-cycle */
/* eslint-disable array-callback-return */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  IconButton,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import './ShareUrl.style.scss';
import { useHistory } from 'react-router';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';

import Fab from '@material-ui/core/Fab';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import UserAPI from '../../api/UserAPI';
import WhatsapIcon from '../Icons/WhatsappIcon';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const ShareUrl = ({
  isOpen,
  close,
}) => {
  const history = useHistory();
  const tabs = useSelector(state => state.tabs);
  const customer = useSelector(state => state.customer);
  const cart = useSelector(state => state.cart);
  const user = useSelector(state => state.authUser.user);
  const { products, orderSelected: { order } } = cart;
  const { posTypeTab } = tabs;
  const { config } = user;
  const { selectedCustomer } = customer;
  const [showLink, setShowLink] = React.useState(false);
  const [open, setOpen] = React.useState(isOpen);

  const [source, setSource] = React.useState('');
  const [url, setUrl] = React.useState('');
  const [hasLogin, setHasLogin] = React.useState(false);
  const [loadingCart, setloadingCart] = React.useState(false);
  const [loadingSell, setloadingSell] = React.useState(false);
  const [loadingHome, setloadingHome] = React.useState(false);

  React.useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleClose = () => {
    setOpen(false);
    setHasLogin(false);
    setSource('');
    setUrl('');
    setShowLink(false);
    close();
  };

  const handleChangeCheckbox = () => {
    setHasLogin(!hasLogin);
  };

  const generateUrl = async (action) => {
    let data = null;
    switch (action) {
      case 'Carrito':
        setloadingCart(true);
        if (cart.products.length !== 0) {
          const productsItems = {};
          products.map((product) => {
            productsItems[`${product.crmid}`] = {
              accountid: selectedCustomer.crmid || config.id, tpoc_id: `${product.parentId}`, productid: `${product.crmid}`, quantity: `${product.quantity}`,
            };
          });
          data = {
            accountid: hasLogin ? selectedCustomer.crmid : config.id,
            rel: productsItems,
            type: 'Cart',
            invited: hasLogin,
            source: 'TPV',
            userid: config.id,
          };
        } else {
          toast.error('Debes tener productos en el carrito antes de generar la url');
          return;
        }

        break;
      case 'Venta':
        setloadingSell(true);
        if (order) {
          data = {
            accountid: selectedCustomer.crmid,
            rel: `${order.crmid}`,
            type: 'Payment',
            invited: true,
            source: 'TPV',
            userid: config.id,
          };
        } else {
          toast.error('Debes seleccionar una venta antes de generar la url');
          return;
        }
        break;
      case 'Home':
        setloadingHome(true);
        data = {
          accountid: hasLogin ? selectedCustomer.crmid : config.id,
          rel: '',
          type: 'Accounts',
          invited: hasLogin,
          source: 'TPV',
          userid: config.id,
        };
        break;

      default:
        toast.error('Debes seleccionar un destino');
        return;
    }

    const userAPI = new UserAPI();
    const { success, result } = await userAPI.generateUrl(data);

    switch (action) {
      case 'Carrito':
        setloadingCart(false);
        break;
      case 'Venta':
        setloadingSell(false);
        break;
      case 'Home':
        setloadingHome(false);
        break;

      default:
        return;
    }

    if (success) {
      setUrl(result.url);
      setShowLink(true);
    } else {
      console.log('error obteniendo la url');
    }
  };

  const copyToClipboard = () => {
    /* Get the text field */
    const copyText = document.getElementById('urlId');
    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999);

    /* Copy the text inside the text field */
    document.execCommand('copy');

    /* Alert the copied text */
    toast.success(`Url copiada : ${copyText.value}`);
  };

  const redirectToWhatsapp = () => {
    window.open(`https://wa.me/${selectedCustomer.phone}/?text=${url}`, '_blank');
  };


  return (
    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
      <AppBar style={{ position: 'relative' }}>
        <Toolbar variant="dense" classes={{ root: 'd-flex justify-content-between' }}>
          <p />
          <Typography variant="h6" style={{ color: '#FFF' }}>
            Compartir enlace
          </Typography>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <div className="d-flex justify-content-center align-items-center" style={{ width: '100%' }}>
        <div className="" style={{ width: 320 }}>

          <AppBar style={{ position: 'relative', backgroundColor: '#F6F6F7', marginTop: 20 }}>
            <Toolbar variant="dense" classes={{ root: 'tollbar-header' }}>
              <Typography variant="h6" style={{ color: '#64707A' }}>
                Generar Enlaces
              </Typography>
            </Toolbar>
          </AppBar>
          {!showLink && (
            <div className="d-flex flex-column justify-content-between my-4">
              {
                selectedCustomer.accountname !== 'PUBLICO EN GENERAL' && (
                  <div className="d-flex justify-content-between my-4">
                    <h4>Activar login</h4>
                    <div className="d-flex justify-content-between flex-column">
                      <input className="align-self-end mb-1" defaultChecked={hasLogin} onChange={handleChangeCheckbox} type="checkbox" />
                      <span>El enlace durará 30 min</span>
                    </div>
                  </div>
                )
              }
              <div className="d-flex justify-content-around align-items-center my-4">

                <button disabled={cart.products.length === 0} type="button" className="option-btn d-flex flex-column justify-content-center align-items-center" onClick={() => generateUrl('Carrito')}>
                  {
                      !loadingCart && (
                        <FontAwesomeIcon size="3x" icon="shopping-cart" className="mb-1" />
                      )
                    }
                  {
                      loadingCart && (
                        <CircularProgress />
                      )
                    }
                  Carrito
                </button>
                {
                  history.location.pathname.includes('/pos/venta') && order && (
                    <button disabled={Boolean(order.ship_street === '')} type="button" className="option-btn d-flex flex-column justify-content-center align-items-center" onClick={() => generateUrl('Venta')}>
                      {
                          !loadingSell && (
                            <FontAwesomeIcon size="3x" icon="hand-holding-usd" className="mb-1" />
                          )
                        }
                        {
                          loadingSell && (
                            <CircularProgress />
                          )
                        }
                      Venta
                    </button>
                  )
                }
                <button type="button" className="option-btn d-flex flex-column justify-content-center align-items-center" onClick={() => generateUrl('Home')}>
                  {
                        !loadingHome && (
                          <FontAwesomeIcon size="3x" icon="home" className="mb-1" />
                        )
                      }
                  {
                        loadingHome && (
                          <CircularProgress />
                        )
                      }
                  Home
                </button>
              </div>
            </div>
          )}
          {showLink && (
            <div className="success my-4">
              <div className="my-4" style={{ position: 'relative' }}>
                <input
                  type="text"
                  readOnly
                  id="urlId"
                  value={url}
                  style={{
                    width: '100%',
                    borderRadius: '10px',
                    height: '35px',
                    border: '1px solid #e6e6e6',
                    backgroundColor: '#f6f6f7',
                    padding: '0 10px',
                  }}
                />
                <FontAwesomeIcon
                  icon={faCopy}
                  onClick={copyToClipboard}
                  style={{
                    position: 'absolute',
                    fontSize: '18px',
                    right: '8px',
                    top: '8px',
                    cursor: 'pointer',
                  }}
                />
              </div>
              <div className="d-flex justify-content-between align-items-center my-4">
                <span style={{ fontSize: 18 }}>Compartir por:</span>
                <Fab variant="extended" onClick={redirectToWhatsapp}>
                  <WhatsapIcon fill="#0000008a" style={{ width: 18 }} />
                </Fab>
              </div>
              <Button variant="contained" style={{ backgroundColor: '#f6f6f7', color: 'black', width: '100%' }} className="my-5" onClick={() => setShowLink(false)}>
                Nuevo enlace
              </Button>
            </div>
          )}
        </div>

      </div>
    </Dialog>
  ); };

ShareUrl.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func.isRequired,
};
ShareUrl.defaultProps = {
  isOpen: false,
};

export default ShareUrl;
