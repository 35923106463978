import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';


const SectionTitle = ({ classes, title }) => (
  <div className={classes.titleStyle}>
    <span>
      {title}
    </span>
  </div>
);
export default (withStyles(styles)(SectionTitle));
