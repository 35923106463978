/* eslint-disable import/no-cycle */
import React, { PureComponent } from 'react';
import {
  Card,
  ListItem,
  ListItemText,
  ListItemIcon,
  LinearProgress,
} from '@material-ui/core';
import {
  Check,
  Close,
} from '@material-ui/icons';

import { isMobile } from 'react-device-detect';

import { withRouter } from 'react-router-dom';

import './Profile.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// eslint-disable-next-line import/no-cycle
import {
  setOrderFilter,
  changeAlmAuthUser,
  changeGenericButtonCaja,
  changeGenericButtonHistorico,
  changeSellOrderStatusAuthUser,
} from '../../../redux/actions/authUserActions';

// eslint-disable-next-line import/no-cycle
import { configSellColumnDef } from '../../../redux/actions/orderActions';
// eslint-disable-next-line import/no-cycle
import { configProductColumnDef } from '../../../redux/actions/productActions';


import authUserProps from '../../../propTypes/authUserProps';

/* Components */
import Constructor from './components/constructor';
import ConfVentas from './components/ConfVentas';
import ConfProductos from './components/ConfProductos';
import FilterSettings from './components/FilterSettings';
import RadioSettings from './components/RadioSettings';

class Profile extends PureComponent {
  static propTypes = {
    authUser: authUserProps.isRequired,
    defaultCajaOptions: PropTypes.array,
    defaultVentaOptions: PropTypes.array,
    dispatchChangeAlmAuthUser: PropTypes.func.isRequired,
    dispatchChangeGenericButtonCaja: PropTypes.func.isRequired,
    dispatchChangeGenericButtonHistorico: PropTypes.func.isRequired,
    dispatchChangeSellOrderStatusAuthUser: PropTypes.func.isRequired,
    dispatchColumnDef: PropTypes.func.isRequired,
    dispatchProductColumnDef: PropTypes.func.isRequired,
    dispatchSetOrderFilter: PropTypes.func.isRequired,
    fdeOptions: PropTypes.array,
    fdeTitle: PropTypes.string,
    generalTitle: PropTypes.string,
    history: PropTypes.object.isRequired,
    loads: PropTypes.object.isRequired,
    mdaOptions: PropTypes.array,
    mdaTitle: PropTypes.string,
    order: PropTypes.object.isRequired,
    product: PropTypes.object.isRequired,
    stateOptions: PropTypes.array,
    stateTitle: PropTypes.string,
    tabs: PropTypes.object.isRequired,
  };

  static defaultProps = {
    defaultCajaOptions: [
      {
        id: 'Cobrar',
        value: 'Cobrar',
      },
      {
        id: 'Cotizar',
        value: 'Cotizar',
      },
      {
        id: 'Guardar',
        value: 'Guardar',
      },
    ],
    defaultVentaOptions: [
      {
        id: 'Cobrar',
        value: 'Cobrar',
      },
      {
        id: 'Entregar',
        value: 'Entregar',
      },
      {
        id: 'Ambas',
        value: 'Ambas',
      },
    ],
    fdeOptions: [
      {
        label: 'Pagado/Cobrado',
        value: 'Pagado/Cobrado',
        column: 'estado_fde',
      },
      {
        label: 'Sin Pago/Cobro',
        value: 'Sin Pago/Cobro',
        column: 'estado_fde',
      },
      {
        label: 'Parcialmente Pagado/Cobrado',
        value: 'Parcialmente Pagado/Cobrado',
        column: 'estado_fde',
      },
    ],
    mdaOptions: [
      {
        label: 'Entregado',
        value: 'Entregado',
        column: 'estado_mda',
      },
      {
        label: 'Sin Entregar',
        value: 'Sin Entregar',
        column: 'estado_mda',
      },
      {
        label: 'Parcialmente Entregado',
        value: 'Parcialmente Entregado',
        column: 'estado_mda',
      },
    ],
    stateOptions: [
      {
        label: 'Creados',
        value: 'Creado',
        column: 'state',
      },
      {
        label: 'Apartados',
        value: 'Apartado',
        column: 'state',
      },
      {
        label: 'Autorizados',
        value: 'Autorizado',
        column: 'state',
      },
      {
        label: 'Cancelados',
        value: 'Cancelado',
        column: 'state',
      },
    ],
    fdeTitle: 'Filtrar por Flujo de Efectivo',
    mdaTitle: 'Filtrar por Movimiento de Almacén',
    stateTitle: 'Filtrar por Estado de la Orden',
    generalTitle: 'Configuración General',
  };

  render() {
    const {
      authUser,
      defaultCajaOptions,
      defaultVentaOptions,
      dispatchChangeAlmAuthUser,
      dispatchChangeGenericButtonCaja,
      dispatchChangeGenericButtonHistorico,
      dispatchChangeSellOrderStatusAuthUser,
      dispatchColumnDef,
      dispatchProductColumnDef,
      dispatchSetOrderFilter,
      fdeOptions,
      fdeTitle,
      generalTitle,
      history,
      loads,
      mdaOptions,
      mdaTitle,
      order,
      product,
      stateOptions,
      stateTitle,
      tabs,
    } = this.props;

    const { columnsDefs } = order;
    const { user: { config } } = authUser;
    const fdeFilterValue = (config.estado_fde ? (config.estado_fde) : (fdeOptions[0].value));
    const mdaFilterValue = (config.estado_mda ? (config.estado_mda) : (mdaOptions[0].value));
    const stateFilterValue = (config.state ? (config.state) : (stateOptions[0].value));
    const loading = Boolean(loads.profileIsLoading);

    const switchView = Boolean(tabs.profileViewTab && (tabs.profileViewTab));

    return (
      <>
        {!switchView ? (
          <Constructor history={history} authUser={authUser} />
        ) : (
          <div className="settings h-100 d-flex justify-content-center flex-wrap os-y">
            <Card className={isMobile ? 'settings__cardMobile px-4 py-2' : 'settings__card px-4 py-2'}>
              <div className="settings__title">
                {generalTitle}
              </div>
              <ListItem>
                <ListItemIcon>
                  {authUser.user.config.pos_sininv ? (<Check />) : (<Close />)}
                </ListItemIcon>
                <ListItemText
                  primary="Permite Venta sin existencia"
                  secondary={authUser.user.config.pos_sininv ? 'Si' : 'No'}
                />
              </ListItem>
              <RadioSettings
                loading={loading}
                options={authUser.user.sostatus}
                value={authUser.user.config.sostatus}
                title="Estado de una Orden al ser creada"
                setOption={dispatchChangeSellOrderStatusAuthUser}
              />
              <RadioSettings
                loading={loading}
                title="Entrega automatica posterior al cobro"
                options={authUser.user.pos_auto_alm}
                value={authUser.user.config.pos_auto_alm}
                setOption={dispatchChangeAlmAuthUser}
              />
            </Card>
            <Card className={isMobile ? 'settings__cardMobile px-4 py-2' : 'settings__card px-4 py-2'}>
              <div className="settings__title">Preferencias</div>
              <RadioSettings
                loading={loading}
                title="Boton por default de la caja"
                options={defaultCajaOptions}
                value={authUser.user.config.pos_botoncaja}
                setOption={dispatchChangeGenericButtonCaja}
              />
              <RadioSettings
                loading={loading}
                title="Boton por default en las ventas"
                options={defaultVentaOptions}
                value={authUser.user.config.pos_botonventas}
                setOption={dispatchChangeGenericButtonHistorico}
              />
            </Card>
            <Card className={isMobile ? 'settings__cardMobile px-4 py-2' : 'settings__card px-4 py-2'}>
              <div className="settings__title">Filtros de Ventas</div>
              {loading && (<LinearProgress />)}
              <FilterSettings
                title={fdeTitle}
                options={fdeOptions}
                setFilter={dispatchSetOrderFilter}
                filter="estado_fde"
                value={fdeFilterValue}
                loading={loading}
              />
              <FilterSettings
                title={mdaTitle}
                options={mdaOptions}
                setFilter={dispatchSetOrderFilter}
                filter="estado_mda"
                value={mdaFilterValue}
                loading={loading}
              />
              <FilterSettings
                title={stateTitle}
                options={stateOptions}
                setFilter={dispatchSetOrderFilter}
                filter="state"
                value={stateFilterValue}
                loading={loading}
              />
            </Card>
            <Card className={isMobile ? 'settings__cardMobile px-4 py-2' : 'settings__card px-4 py-2'}>
              <div className="settings__title">Edición de Tablas</div>
              {loading && (<LinearProgress />)}
              <ConfVentas
                loading={loading}
                columnDef={dispatchColumnDef}
                columnsDefs={columnsDefs || []}
              />
              <ConfProductos
                loading={loading}
                columnDef={dispatchProductColumnDef}
                columnsDefs={product.columnsDefs || []}
              />
            </Card>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  tabs: state.tabs,
  loads: state.loads,
  order: state.order,
  product: state.product,
  authUser: state.authUser,
});

const mapDispatchToProps = dispatch => ({
  /* Generic Options */
  dispatchColumnDef: field => dispatch(configSellColumnDef(field)),
  dispatchProductColumnDef: field => dispatch(configProductColumnDef(field)),
  dispatchChangeAlmAuthUser: alm => dispatch(changeAlmAuthUser(alm.id)),
  dispatchChangeSellOrderStatusAuthUser: status => dispatch(changeSellOrderStatusAuthUser(status.id)),
  /* Default Buttons */
  dispatchChangeGenericButtonCaja: label => dispatch(changeGenericButtonCaja(label.value)),
  dispatchChangeGenericButtonHistorico: label => dispatch(changeGenericButtonHistorico(label.value)),
  /* Order Filters */
  dispatchSetOrderFilter: option => dispatch(setOrderFilter(option)),
});

// @ts-ignore
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Profile));
