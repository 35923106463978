import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import {
  Card,
} from '@material-ui/core';
/* Components */
import RadioSettings from '../../RadioSettings';

const ConfCaja = ({
  authUser,
  defaultCajaOptions,
  dispatchChangeGenericButtonCaja,
  dispatchHandlePrintAfterSell,
  dispatchHanldeCheckoutBill,
  facturacionOptions,
  generalTitle,
  loads,
  afterSellTitle,
  afterSellDesc,
}) => {
  const { user } = authUser;
  const { config } = user;
  const generalDisable = Boolean(config.pos_config_alm);
  const loading = Boolean(loads.profileIsLoading);
  return (
    <Card
      className={
        isMobile ? 'settings__cardMobile px-4 py-2' : 'settings__card px-4 py-2'
      }
    >
      <div className="settings__title">{generalTitle}</div>
      <RadioSettings
        loading={loading}
        disabled={generalDisable}
        title="Boton por default de la caja"
        options={defaultCajaOptions}
        value={user.config.pos_botoncaja}
        setOption={dispatchChangeGenericButtonCaja}
      />
      <RadioSettings
        loading={loading}
        disabled={generalDisable}
        title="Facturación en el cobro"
        options={facturacionOptions}
        value={user.config.pos_checkout_bill}
        setOption={dispatchHanldeCheckoutBill}
      />
    </Card>
  );
};

ConfCaja.propTypes = {
  authUser: PropTypes.object.isRequired,
  defaultCajaOptions: PropTypes.array,
  dispatchChangeGenericButtonCaja: PropTypes.func.isRequired,
  dispatchHandlePrintAfterSell: PropTypes.func.isRequired,
  dispatchHanldeCheckoutBill: PropTypes.func.isRequired,
  facturacionOptions: PropTypes.array,
  generalTitle: PropTypes.string,
  afterSellTitle: PropTypes.string,
  afterSellDesc: PropTypes.string,
  loads: PropTypes.object.isRequired,
};

ConfCaja.defaultProps = {
  generalTitle: 'Preferencias',
  afterSellTitle: 'Imprimir al terminar el cobro.',
  afterSellDesc: 'Se imprimirá ticket de la venta una vez completado el cobro satisfactoriamente.',
  defaultCajaOptions: [
    {
      id: 'Cobrar',
      value: 'Cobrar',
    },
    {
      id: 'Cotizar',
      value: 'Cotizar',
    },
    {
      id: 'Guardar',
      value: 'Guardar',
    },
  ],
  facturacionOptions: [
    {
      id: 'Siempre',
      value: 'Siempre',
    },
    {
      id: 'Preguntar',
      value: 'Preguntar',
    },
  ],
};

export default ConfCaja;
