/* eslint-disable radix */
/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
import { toast } from 'react-toastify';
import { loading, loaded } from './loadActions';
import { updateTotalDiscount } from './discountActions';
// @ts-ignore
import { cartHelper, productHelper } from '../../helpers';
import { updateProductsWithCart } from './productActions';

import { getStorage } from '../../helpers/pricebook';

import ProductAPI from '../../api/ProductAPI';
import { setLastOrderCustomerId } from './customerActions';

export const ADD_ORDER_TO_CART = 'ADD_ORDER_TO_CART';
export const ADD_PRODUCT_TO_CART = 'ADD_PRODUCT_TO_CART';
export const ADD_PRODUCT_TO_CART_QTY = 'ADD_PRODUCT_TO_CART_QTY';
export const ALERT_PRODUCTS = 'ALERT_PRODUCTS';
export const CLEAR_CART = 'CLEAR_CART';
export const UPDATE_CART_STATE = 'UPDATE_CART_STATE';
export const DECREASE_PRODUCT_IN_CART = 'DECREASE_PRODUCT_IN_CART';
export const DELIVER_STATUS_CHANGE = 'DELIVER_STATUS_CHANGE';
export const INCREASE_PRODUCT_IN_CART = 'INCREASE_PRODUCT_IN_CART';
export const REMOVE_PRODUCT_IN_CART = 'REMOVE_PRODUCT_IN_CART';
export const SET_COMMENT = 'SET_COMMENT';
export const SET_EDIT_PRODUCT_COMMENT = 'SET_EDIT_PRODUCT_COMMENT';
export const SET_DESC = 'SET_DESC';
export const SET_SINGLE_DISCOUNT = 'SET_SINGLE_DISCOUNT';
export const UPDATE_PRODUCT_PRICE_IN_CART = 'UPDATE_PRODUCT_PRICE_IN_CART';
export const UPDATE_PRODUCT_QTY_IN_CART = 'UPDATE_PRODUCT_QTY_IN_CART';
export const UPDATE_SERVICE_PRICE_BY_WEIGHT_CART = 'UPDATE_SERVICE_PRICE_BY_WEIGHT_CART';
export const VERIFY_PAYPAL_SERVICE = 'VERIFY_PAYPAL_SERVICE';
export const VERIFY_SECURITY_SERVICE = 'VERIFY_SECURITY_SERVICE';
export const VERIFY_SHIPPING_SERVICE = 'VERIFY_SHIPPING_SERVICE';
export const UPDATE_PROMOTIONS = 'UPDATE_PROMOTIONS';
export const SET_PROMOTIONS = 'SET_PROMOTIONS';
export const RESET_PROMOTIONS = 'RESET_PROMOTIONS';
export const ACTIVE_PROMOTIONS = 'ACTIVE_PROMOTIONS';
export const DESACTIVE_PROMOTIONS = 'DESACTIVE_PROMOTIONS';
export const ADD_SHIPPING_SERVICE = 'ADD_SHIPPING_SERVICE';

// Guide
export const ADD_SELECTED_GUIDE = 'ADD_SELECTED_GUIDE';
export const CLEAR_SELECTED_GUIDE = 'CLEAR_SELECTED_GUIDE';

// Packing
export const ADD_SELECTED_PACKING = 'ADD_SELECTED_PACKING';
export const CLEAR_SELECTED_PACKING = 'CLEAR_SELECTED_PACKING';

/* Discount */
export const SET_DISCOUNT_SHOW = 'SET_DISCOUNT_SHOW';
export const SET_DISCOUNT_HIDE = 'SET_DISCOUNT_HIDE';

const isCEN = () => (dispatch, getState) => {
  const { authUser } = getState();
  const { user } = authUser;
  if (user.cen !== undefined) {
    dispatch(verifyPaypalService());
    dispatch(verifyShippingService());
    dispatch(verifySecurityService());
  }
  return null;
};

export function togglePromotions() {
  return {
    type: SET_PROMOTIONS,
  };
}

export function setActivePromotions() {
  return {
    type: ACTIVE_PROMOTIONS,
  };
}

export function desactivePromotions() {
  return {
    type: DESACTIVE_PROMOTIONS,
  };
}

export function resetPromotions() {
  return {
    type: RESET_PROMOTIONS,
  };
}


export function updatePromotions() {
  return async (dispatch, getState) => {
    const ProductApi = new ProductAPI();
    const { cart, authUser } = getState();
    const { activePromotions } = cart;
    const priceBookSelected = getStorage();
    const currentStore = authUser.isAuth ? authUser.user.config.store : {};
    const data = {
      origen: 'TPV',
      treebesalmid: currentStore.crmid,
      pricebookid: priceBookSelected.crmid || 0,
      products: [],
    };

    /**
     * Verifica promociones
     */
    if (activePromotions) {
      // eslint-disable-next-line array-callback-return
      cart.products.map((product) => {
        if (product.unit_price < 0.02) {
          // eslint-disable-next-line
          return;
        }
        const productid = product.productid && product.productid.indexOf('x') !== -1
          ? product.productid.split('x').pop()
          : product.productid;

        data.products.push({
          productid: product.crmid || productid,
          quantity: product.quantity,
          unit_price: product.unit_price,
        });
      });

      if (data.products.length) {
        const promotions = await ProductApi.getPromotions(data);
        const {
          result: { TreebesPromociones, products },
        } = promotions;
        await dispatch({
          type: UPDATE_PROMOTIONS,
          TreebesPromociones,
          products,
        });
      }
    } /* else {
      await dispatch({
        type: RESET_PROMOTIONS,
      });
    } */
  };
}

export function evaluateStock() {
  return async (dispatch, getState) => {
    try {
      const ProductApi = new ProductAPI();
      dispatch(loading('order'));
      toast.info('Actualizando existencias');
      const { cart, filters } = getState();
      const { discountTaxes } = filters;
      const {
        cartProductsById,
        orderSelected,
      } = cart;
      const requests = Object.entries(cartProductsById)
        .map((product) => {
          let productid = '';
          if (product[1].productid) {
            productid = product[1].productid.indexOf('x') !== -1
              ? product[1].productid.split('x').pop()
              : product[1].productid;
          }
          const crmid = product[1].crmid ? product[1].crmid : productid;
          return ProductApi.getWithData(crmid, { data: product[1] });
        });
      await Promise.all(requests)
        .then(async (stockRes) => {
          const response = stockRes.map(x => ({
            ...x.data,
            qtyinstock: x.result.qtyinstock,
          }));
          let jsonById = {};
          response.forEach((result) => {
            const inStock = {
              sumQty: result.quantity,
              inStock: parseInt(result.qtyinstock),
              status: Boolean(result.quantity <= parseInt(result.qtyinstock)),
            };
            const product = { ...result, inStock };
            jsonById = { ...jsonById, [result.row]: { ...product } };
            dispatch(clearCart());
            dispatch({
              type: UPDATE_CART_STATE,
              cartProductsById: jsonById,
              orderSelected,
              discountTaxes,
            });
          });
        })
        .catch((evaluateStockErr) => {
          console.log({ evaluateStockErr });
          toast.warn(`Al momento de varificar stock del carrito en el sistema, encontramos un error: ${evaluateStockErr}`);
        });
    } catch (err) {
      toast.warn(`Al momento de actualizar stock en el carrito, encontramos un error: ${err}`);
    } finally {
      await dispatch(loaded('order'));
    }
  };
}

export function addProductToCart(parentId, crmid, quantity = null) {
  return async (dispatch, getState) => {
    dispatch(loading('order'));
    try {
      await new Promise(resolve => setTimeout(resolve));
      const { authUser, product } = getState();
      const { user: { config: { pos_cart_concepts, pos_sininv } } } = authUser;
      let type = '';
      if (pos_cart_concepts) {
        type = ADD_PRODUCT_TO_CART;
      } else { type = ADD_PRODUCT_TO_CART_QTY; }
      const { productsById } = product;
      const { cart, filters } = getState();
      const { discountTaxes } = filters;
      let inStock = {};
      if (!pos_sininv) {
        const ProductApi = new ProductAPI();
        const result = await ProductApi.get(crmid);
        inStock = await productHelper.haveStockBD(result, crmid, quantity, cart);
      } else inStock = { status: true, sumQty: quantity || 1, inStock: 10000 };
      await dispatch({
        type,
        crmid,
        parentId,
        quantity,
        productsById,
        pos_cart_concepts,
        inStock,
        discountTaxes,
      });
      await dispatch(updateProductsWithCart());
      await dispatch(updateTotalDiscount());
      await dispatch(updateServicePriceByWeightCart());
      await dispatch(isCEN());
      await dispatch(updatePromotions());
    } catch (err) {
      toast.warn(`Al momento de añadir un producto al carrito, encontramos un error: ${err}`);
    } finally {
      await dispatch(loaded('order'));
    }
  };
}

export function addOrderToCart(order) {
  return (dispatch, getState) => {
    const { filters } = getState();
    const { discountTaxes } = filters;
    dispatch(clearCart());
    dispatch({
      type: ADD_ORDER_TO_CART,
      order,
      discountTaxes,
    });
    const customerId = order.account_id.split('x').pop();
    dispatch(setLastOrderCustomerId(customerId));
  };
}

export function uploadDiscountAmount(row) {
  return async (dispatch, getState) => {
    const { cart: { cartProductsById } } = getState();
    const percentage = cartProductsById[row].discount_percent;
    await dispatch(setSingleDiscount(row, percentage));
  };
}

export function increaseProductInCart(row) {
  return async (dispatch, getState) => {
    try {
      await new Promise(resolve => setTimeout(resolve));
      const { cart, authUser } = getState();
      const { crmid } = cart.cartProductsById[row];
      const productid = cart.cartProductsById[row].productid
        && cart.cartProductsById[row].productid.indexOf('x') !== -1
        ? cart.cartProductsById[row].productid.split('x').pop()
        : cart.cartProductsById[row].productid;
      const setCrmid = crmid || productid;

      const { user: { config: { pos_sininv } } } = authUser;
      let inStock = {};
      if (!pos_sininv) {
        const ProductApi = new ProductAPI();
        const result = await ProductApi.get(setCrmid);
        inStock = await productHelper.haveStockBD(result, setCrmid, 1, cart);
      } else inStock = { status: true, sumQty: 1, inStock: 10000 };
      await dispatch({
        type: INCREASE_PRODUCT_IN_CART,
        row,
        inStock,
      });
      await dispatch(updateProductsWithCart());
      await dispatch(uploadDiscountAmount(row));
      await dispatch(updateTotalDiscount());
      await dispatch(updateServicePriceByWeightCart());
      await dispatch(isCEN());
      await dispatch(updatePromotions());
    } catch (err) {
      toast.warn(`Al momento de incrementar cantidad de un producto, encontramos un error: ${err}`);
    }
  };
}

export function decreaseProductInCart(row) {
  return async (dispatch, getState) => {
    try {
      const { cart, authUser } = getState();
      const { crmid } = cart.cartProductsById[row];
      const productid = cart.cartProductsById[row].productid
        && cart.cartProductsById[row].productid.indexOf('x') !== -1
        ? cart.cartProductsById[row].productid.split('x').pop()
        : cart.cartProductsById[row].productid;

      const setCrmid = crmid || productid;
      const { user: { config: { pos_sininv } } } = authUser;
      let inStock = {};
      if (!pos_sininv) {
        const ProductApi = new ProductAPI();
        const result = await ProductApi.get(setCrmid);
        inStock = await productHelper.haveStockBD(result, setCrmid, 1, cart);
      } else inStock = { status: true, sumQty: 1, inStock: 10000 };
      await dispatch({
        type: DECREASE_PRODUCT_IN_CART,
        row,
        inStock,
      });
      await dispatch(updateProductsWithCart());
      await dispatch(uploadDiscountAmount(row));
      await dispatch(updateTotalDiscount());
      await dispatch(updateServicePriceByWeightCart());
      await dispatch(isCEN());
      await dispatch(updatePromotions());
    } catch (err) {
      toast.warn(`Al momento de reducir cantidad de un producto, encontramos un error: ${err}`);
    }
  };
}

export function updateProductQuantityInCart(row, qty) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_PRODUCT_QTY_IN_CART,
      row,
      qty,
    });
    dispatch(updateProductsWithCart());
    dispatch(updateTotalDiscount());
    dispatch(updateServicePriceByWeightCart());
    dispatch(isCEN());
    dispatch(updatePromotions());
  };
}

export function updatePriceByPricebook(row, pricebook = null) {
  return async (dispatch, getState) => {
    try {
      await new Promise(resolve => setTimeout(resolve));
      await dispatch(loading('order'));
      const { cart } = getState();
      // const { crmid } = cart.cartProductsById[row];
      const product = cart.cartProductsById[row];
      const productid = product.productid && product.productid.indexOf('x') !== -1
        ? product.productid.split('x').pop()
        : product.productid;
      const crmid = product.crmid || productid;
      const ProductApi = new ProductAPI();
      const response = await ProductApi.getWithPricebook(crmid, pricebook);
      if (response.success) {
        const {
          result: { unit_price },
        } = response;
        const price = unit_price.toFixed(2);
        await dispatch(updateProductPriceInCart(row, price));
        await dispatch(uploadDiscountAmount(row));
        await dispatch(updateTotalDiscount());
      } else {
        const { message } = response;
        toast.warn(message.warning || message.error);
      }
    } catch (err) {
      toast.warn(
        `Al momento de cambiar el precio de un producto, encontramos un error: ${err}`,
      );
    } finally {
      await dispatch(loaded('order'));
    }
  };
}

export function updateProductPriceInCart(row, price) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_PRODUCT_PRICE_IN_CART,
      row,
      price,
    });
    dispatch(updateTotalDiscount());
  };
}

export function updateServicePriceByWeightCart() {
  return (dispatch, getState) => {
    const { authUser } = getState();
    const { user } = authUser;
    const isValid = Object.prototype.hasOwnProperty.call(user, 'cen');
    if (isValid) {
      const { cen } = user;
      dispatch({
        type: UPDATE_SERVICE_PRICE_BY_WEIGHT_CART,
        cen,
      });
      dispatch(updateTotalDiscount());
    }
  };
}

export const setProductsById = product => async (dispatch) => {
  await dispatch({
    type: ADD_SHIPPING_SERVICE,
    product,
  });
};

export function removeProductInCart(row) {
  return (dispatch) => {
    dispatch({
      type: REMOVE_PRODUCT_IN_CART,
      row,
    });
    dispatch(updateProductsWithCart());
    dispatch(updateTotalDiscount());
    dispatch(updateServicePriceByWeightCart());
    dispatch(updatePromotions());
  };
}

export function verifyPaypalService() {
  return (dispatch, getState) => {
    const { cart: { cartProductsByArray, totalWithOutServices } } = getState();
    const array = cartProductsByArray.filter(x => x.crmid === '55235');
    if (array.length > 0 && cartProductsByArray.length > 1) {
      const shippingRow = array[0].row;
      const payPalPrice = Math.round((((0.025) * totalWithOutServices)) * 100) / 100;
      dispatch({ type: VERIFY_PAYPAL_SERVICE });
      dispatch(updateProductPriceInCart(shippingRow, payPalPrice));
    }
  };
}

export function verifySecurityService() {
  return (dispatch, getState) => {
    const { cart: { cartProductsByArray, totalWithOutServices } } = getState();
    const array = cartProductsByArray.filter(x => x.crmid === '152666');
    if (array.length > 0 && cartProductsByArray.length > 1) {
      const shippingRow = array[0].row;
      const securityServices = Math.round((((0.05) * totalWithOutServices)) * 100) / 100;
      dispatch({ type: VERIFY_SECURITY_SERVICE });
      dispatch(updateProductPriceInCart(shippingRow, securityServices));
    }
  };
}

export function verifyShippingService() {
  return async (dispatch, getState) => {
    const { cart: { cartProductsByArray, totalWeight } } = getState();
    const { authUser: { user: { cen } } } = getState();
    const { id_envio_aereo, id_envio_terrestre } = cen;
    const array = cartProductsByArray.filter(x => (x.crmid === String(id_envio_aereo) || x.crmid === String(id_envio_terrestre)));
    if (array.length > 0 && cartProductsByArray.length > 1) {
      const shippingRow = array[0].row;
      const shippingCrmid = Number.parseInt(array[0].crmid);
      let elements = [];
      if (cen.id_envio_terrestre === shippingCrmid) {
        elements = cen.envio_terrestre;
      } else {
        elements = cen.envio_aereo;
      }
      const shippingOption = await cartHelper.getShippingData(totalWeight, elements);
      dispatch(updateProductPriceInCart(shippingRow, shippingOption[0].x));
    }
  };
}

export function alertProducts(noHayDe) {
  return (dispatch) => {
    const productsWithOutStock = Object.entries(noHayDe).map(x => ({ id: x[0], qty: x[1] }));
    dispatch({
      type: ALERT_PRODUCTS,
      productsWithOutStock,
    });
  };
}

export function adjustStock() {
  return (dispatch, getState) => {
    try {
      const { cart } = getState();
      cart.alertProducts.forEach((x) => {
        if (x.qty <= 0) {
          dispatch(removeProductInCart(x.id));
        }
        dispatch(updateProductQuantityInCart(x.id, x.qty));
      });
    } catch (err) {
      /* Error */
      console.log('adjustStock err: ', err);
    }
  };
}

export function setDesc(description) {
  return async (dispatch) => {
    await dispatch({
      type: SET_DESC,
      description,
    });
  };
}

export function setComment(product) {
  return async (dispatch) => {
    await dispatch({
      type: SET_COMMENT,
      product,
    });
  };
}

export function setEditProductsComments(value) {
  return async (dispatch) => {
    await dispatch({
      type: SET_EDIT_PRODUCT_COMMENT,
      payload: value,
    });
  };
}

export function setSingleDiscount(row, percentage) {
  return async (dispatch, getState) => {
    const { cart: { cartProductsById }, filters } = getState();
    const { discountTaxes } = filters;
    const product = cartProductsById[row];
    const { unit_price, quantity } = product;
    const priceByQty = (unit_price * quantity);
    const amount = (priceByQty * (percentage / 100));
    await dispatch({
      type: SET_SINGLE_DISCOUNT,
      row,
      amount,
      percentage,
      discountTaxes,
    });
  };
}

export function setDiscount(percentage, show = false) {
  return async (dispatch, getState) => {
    const { cart: { products } } = getState();
    if (show) {
      products.forEach(async (product) => {
        const { unit_price, row } = product;
        const discount = (unit_price * (percentage / 100));
        const newPrice = unit_price - discount;
        await dispatch(updateProductPriceInCart(row, newPrice));
      });
    } else {
      products.forEach(async (product) => {
        const { row } = product;
        await dispatch(setSingleDiscount(row, percentage));
      });
    }
  };
}

export function clearCart() {
  return {
    type: CLEAR_CART,
  };
}

// Guide
export function addSelectedGuide(guide) {
  return (dispatch) => {
    dispatch({
      type: ADD_SELECTED_GUIDE,
      guide,
    });
  };
}


export function clearSelectedGuide() {
  return {
    type: CLEAR_SELECTED_GUIDE,
  };
}

// Packing
export function addSelectedPacking(packing) {
  return (dispatch) => {
    dispatch({
      type: ADD_SELECTED_PACKING,
      packing,
    });
  };
}


export function clearSelectedPacking() {
  return {
    type: CLEAR_SELECTED_PACKING,
  };
}


// Update cart product price when client change
export function updateProductsInCartWhenChangePriceBook(priceBookId) {
  return (dispatch, getState) => {
    const {
      cart: { cartProductsByArray },
    } = getState();
    if (cartProductsByArray.length) {
      toast.info('Actualizando precios de productos');
      cartProductsByArray.map(product => dispatch(updatePricebook(product.row, priceBookId)));
    }
  };
}

const updatePricebook = (row, pricebook) => async (dispatch) => {
  await dispatch(updatePriceByPricebook(row, pricebook));
};
