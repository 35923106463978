/* eslint-disable array-callback-return */
/* eslint-disable import/no-cycle */
import {
  Avatar, Collapse, Divider, List, ListItem, ListItemAvatar, ListItemText, Paper,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import MdaAPI from '../../../../../api/MdaApi';
import { formatString } from '../../../../../helpers/tools';
import FullDialog from '../../../../FullDialog';
import ListMdaItem from '../../../../Mda/components/ListMda/ListMdaItem';
import TransferProcessDetailProducts from './TransferProcessDetailProducts';

const TransferProcessDetail = () => {
  const history = useHistory();
  const params = useParams();
  const authUser = useSelector(state => state.authUser);

  const [isDeliver, setIsDeliver] = useState(false);
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [order, setOrder] = useState({});
  const [selectedProducts, setSelectedProducts] = useState([]);

  const mdaApi = new MdaAPI();
  const {
    user: {
      config: { store },
    },
  } = authUser;


  const transferProcessDetails = async () => {
    setLoading(true);
    const response = await mdaApi.getProcessTransferDetail(
      store.crmid,
      params.orderId,
    );

    if (response.success === false) {
      toast.error('Error cargando la orden');
      setLoading(false);

      return;
    }


    if (response.result[0].detail?.length) {
      const formatProducts = [];
      response.result[0].detail.map((prod) => {
        formatProducts.push({
          ...prod,
          por_recibir: 0,
        });
      });
      setProducts(formatProducts);
    }

    if (response.result.length) {
      const { detail, ...rest } = response.result[0];
      const transfer = {
        ...rest,
      };

      setOrder(transfer);
    }

    setLoading(false);
  };


  const selectAll = async () => {
    await setSelectedProducts([]);
    const selectProducts = [];
    products.map((prod) => {
      selectProducts.push(prod.treebesmdaid);
    });

    setSelectedProducts([...selectProducts]);
  };

  const selectNone = () => {
    setSelectedProducts([]);
  };

  const changeInput = (prod) => {
    if (!selectedProducts.includes(prod.treebesmdaid)) {
      setSelectedProducts([...selectedProducts, prod.treebesmdaid]);
    } else {
      const filtered = selectedProducts.filter(p => p !== prod.treebesmdaid);
      setSelectedProducts([...filtered]);
    }
  };


  const processTransfer = async () => {
    if (!selectedProducts.length) {
      toast.warn('Debe seleccionar al menos un producto antes de processar la transferencia');

      return;
    }

    setIsDeliver(true);
    const response = await mdaApi.processTransfer(selectedProducts);

    if (response.message.success) {
      toast.success(response.message.success);
    }
    if (response.message.error) {
      toast.error(response.message.error);
    }
    setIsDeliver(false);
    history.push('/almacenes');
  };

  useEffect(() => {
    if (params.orderId && store.crmid) {
      transferProcessDetails();
    }
  }, []);

  return (
    <FullDialog
      title="Procesar Tras. para Doc."
      isOpen
      isLoading={isDeliver}
      onClose={() => history.goBack()}
      onSave={processTransfer}
      enableAction={!loading && !isDeliver && products.length > 0}
    >
      <div className="deliver">
        <Collapse in={Boolean(!loading)} className="w-100">
          <Paper className="deliver__client">
            {Object.keys(order).length > 0 && (
              <ListMdaItem
                item={{
                  createdtime: order.fecha,
                  entity_label: order.referencia,
                  de_documentoid_label: order?.referencia,
                  a_documentoid_label: store.name,
                }}
                isCollapse={false}
                sc="Entrega"
                ec="Traspaso"
              />
            )}
            <Divider />
            {Object.keys(order).length > 0 && (
              <List className="w-100">
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>{order.assigned_user_label.substring(0, 1)}</Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={formatString(order.assigned_user_label)}
                    secondary="Cliente"
                  />
                </ListItem>
              </List>
            )}
          </Paper>
        </Collapse>
        {
          products.length > 0 && (
            <TransferProcessDetailProducts
              products={products}
              changeInput={changeInput}
              loading={loading}
              selectAll={selectAll}
              selectNone={selectNone}
              selectedProducts={selectedProducts}
            />
          )
        }
      </div>
    </FullDialog>
  );
};

export default TransferProcessDetail;
