/* eslint-disable no-unused-expressions */
/* eslint-disable import/prefer-default-export */
import { toast } from 'react-toastify';
import TaskApi from '../../api/TaskApi';
import { loading, loaded } from './loadActions';

import {
  GET_ALL_TASK,
  SELECT_TASK,
  GET_TASK_DOCUMENTS,
  GET_TASK_DOCUMENTS_PAGINATOR,
  SET_PROJECTS,
  SET_PROJECT,
  SET_PROJECT_SEARCH,
  TOGGLE_TIMER,
  SET_TIMER,
  SET_TIMER_TRACKING,
  SET_USERS,
  SET_USER,
  SET_STATUS_FILTER,
  SET_PROJECT_FILTER,
  SET_USERS_FILTER,
  SET_TASK_SEARCH,
} from '../types/taskActions.type';
import DocumentsAPI from '../../api/DocumentsApi';


export function setProjectSearchCriteria(search) {
  return {
    type: SET_PROJECT_SEARCH,
    payload: search,
  };
}

export function setTaskSearchCriteria(search) {
  return {
    type: SET_TASK_SEARCH,
    payload: search,
  };
}

export function addAllTasks(tasks) {
  return {
    type: GET_ALL_TASK,
    payload: tasks,
  };
}

export function selectTasks(task) {
  return {
    type: SELECT_TASK,
    payload: task,
  };
}

export function setAllProjects(projects) {
  return {
    type: SET_PROJECTS,
    payload: projects,
  };
}

export function setProject(projects) {
  return {
    type: SET_PROJECT,
    payload: projects,
  };
}

export function setAllUsers(users) {
  return {
    type: SET_USERS,
    payload: users,
  };
}

export function setUser(user) {
  return {
    type: SET_USER,
    payload: user,
  };
}

export function setStatusFilter(filters) {
  return {
    type: SET_STATUS_FILTER,
    payload: filters,
  };
}

export function setProjectFilter(filters) {
  return {
    type: SET_PROJECT_FILTER,
    payload: filters,
  };
}

export function setUserFilter(filters) {
  return {
    type: SET_USERS_FILTER,
    payload: filters,
  };
}


export function GetTaskDocuments(documents, pagination) {
  return {
    type: pagination ? GET_TASK_DOCUMENTS_PAGINATOR : GET_TASK_DOCUMENTS,
    payload: documents,
  };
}

export function toggleTimer() {
  return {
    type: TOGGLE_TIMER,
  };
}

export function setTimer(time) {
  return {
    type: SET_TIMER,
    payload: time,
  };
}

export function setTimerTracking(tracking) {
  return {
    type: SET_TIMER_TRACKING,
    payload: tracking,
  };
}

export const fetchAllTasks = (page = 1, search = '', relatedTo = 0, sort = 'createdtime', order = 'DESC') => async (dispatch) => {
  const taskApi = new TaskApi();
  dispatch(loading('task'));
  try {
    const response = await taskApi.getAllTasks(relatedTo, page, search, sort, order);
    const { success, result } = response;
    if (process.env.NODE_ENV === 'development') {
      console.log('===================fetchAllTask=================');
      console.log({ result });
      console.log('====================================');
    }
    if (success) {
      dispatch(addAllTasks(result));
    }
    else {
      console.error('Error obteniendo las guias de la orden');
    }
  } catch (err) {
    toast.error(err);
  }
  finally {
    dispatch(loaded('task'));
  }
};

export function getDocumentsByTask(page = 1, search = '', pagination = false) {
  const documentsAPI = new DocumentsAPI();
  return async (dispatch, getState) => {
    await dispatch(loading('order'));
    try {
      const { tasks } = getState();
      const { selectedTask } = tasks;
      if (Object.keys(selectedTask).length > 0) {
        const { crmid } = selectedTask;
        const response = await documentsAPI.getDocuments(crmid, page, search);
        const {
          success, result, pages, count, message,
        } = response;
        if (success) {
          dispatch(GetTaskDocuments({ result, pages, count }, pagination));
        } else {
          /*  toast.warn(response.message.warning); */
          message.error ? toast.error(message.error) : '';
          message.warning ? toast.warn(message.warning) : '';
        }
      } else {
        toast.info('Seleccione una tarea primero');
      }
    } catch (err) {
      console.log({ getFilesByOrderErr: err });
    } finally {
      await dispatch(loaded('order'));
    }
  };
}

export function uploadFilesToTask(files, id = '') {
  console.log('entrooooooo');
  const documentsAPI = new DocumentsAPI();
  return async (dispatch, getState) => {
    let res = null;
    dispatch(loading('order'));
    try {
      if (id !== '') {
        res = await documentsAPI.uploadFileToTask(id, files);
      } else {
        const { tasks } = getState();
        const { selectedTask: { crmid } } = tasks;
        res = await documentsAPI.uploadFileToTask(crmid, files);
      }
      const { success, message } = res;
      if (success) {
        toast.success(res.message.success);
        await dispatch(getDocumentsByTask(1, ''));
      } else {
        /* toast.warn(res.message.error); */
        message.error ? toast.error(message.error) : '';
        message.warning ? toast.warn(message.warning) : '';
      }
    } catch (err) {
      console.log('uploadFiles err: ', err);
    } finally {
      dispatch(loaded('order'));
    }
  };
}

export function fetchAllProject(relatedTo = 0, page = 1, search = '', pagination = false) {
  const taskApi = new TaskApi();
  return async (dispatch) => {
    dispatch(loading('clients'));
    try {
      const response = await taskApi.getAllProjects(relatedTo, page, search);
      if (process.env.NODE_ENV === 'development') {
        console.log('===================fetchAllProjects=================');
        console.log({ response });
        console.log('====================================');
      }
      const { success, result, pages } = response;
      if (success) {
        dispatch(setAllProjects(result));
      }
      else {
        toast.error('Error en la api');
      }
    }
    catch (err) {
      toast.error(err);
    }

    finally {
      dispatch(loaded('clients'));
    }
  };
}

export function fetchProject(project) {
  const taskApi = new TaskApi();
  return async (dispatch) => {
    dispatch(loading('proyecto'));
    try {
      const response = await taskApi.getObjectById(project.crmid);
      if (process.env.NODE_ENV === 'development') {
        console.log('===================fetchProject=================');
        console.log({ response });
        console.log('====================================');
      }
      const { success, result, pages } = response;
      if (success) {
        dispatch(setProject(result));
      }
      else {
        toast.error('Error en la api');
      }
    }
    catch (err) {
      toast.error(err);
    }

    finally {
      dispatch(loaded('proyecto'));
    }
  };
}

export function fetchAllUsers(page = 1, search = '') {
  const taskApi = new TaskApi();
  return async (dispatch) => {
    dispatch(loading('clients'));
    try {
      const response = await taskApi.getAllUsers(page, search);
      if (process.env.NODE_ENV === 'development') {
        console.log('===================fetchAllUsers=================');
        console.log({ response });
        console.log('====================================');
      }
      const { success, result } = response;
      if (success) {
        dispatch(setAllUsers(result));
      }
      else {
        toast.error('Error en la api');
      }
    }
    catch (err) {
      toast.error(err);
    }

    finally {
      dispatch(loaded('clients'));
    }
  };
}

export function fetchUser(user) {
  const taskApi = new TaskApi();
  return async (dispatch) => {
    dispatch(loading('proyecto'));
    try {
      const response = await taskApi.getObjectById(user.id);
      if (process.env.NODE_ENV === 'development') {
        console.log('===================fetchUser=================');
        console.log({ response });
        console.log('====================================');
      }
      const { success, result, pages } = response;
      if (success) {
        dispatch(setUser(result));
      }
      else {
        toast.error('Error en la api');
      }
    }
    catch (err) {
      toast.error(err);
    }

    finally {
      dispatch(loaded('user'));
    }
  };
}


export function updateTaskInState(task) {
  return async (dispatch, getState) => {
    dispatch(loading('order'));
    try {
      const { tasks: { tasks } } = getState();
      const findIndex = tasks.findIndex(t => t.crmid === task.crmid);
      if (findIndex !== -1) {
        tasks.splice(findIndex, 1, task);
        const newTasks = [...tasks];
        dispatch(addAllTasks(newTasks));
      }
    } catch (err) {
      console.log('uploadFiles err: ', err);
    } finally {
      dispatch(loaded('order'));
    }
  };
}
