/* eslint-disable import/no-cycle */
import { connect } from 'react-redux';
import { setDesc } from '../../redux/actions/cartActions';

/* Main Component */
import Comment from './Comment';

/* Redux Func */
const mapStateToProps = (state) => {
  const { cart, loads, order } = state;
  const { description } = cart;
  const orderNo = cart.orderSelected
    && cart.orderSelected.order
    ? (cart.orderSelected.order.salesorder_no || cart.orderSelected.order.label)
    : '';
  const enableEditOrder = Boolean((orderNo) ? (order.enableEditOrder) : (true));
  return ({
    description,
    enableEditOrder,
    loads,
  });
};

const mapDispatchToProps = dispatch => ({
  sendDesc: description => dispatch(setDesc(description)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Comment);
