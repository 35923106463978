/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';

import {
  List,
  Paper,
  Divider,
  ListItem,
  Typography,
  withStyles,
  CssBaseline,
  ListItemText,
  ListSubheader,
  Collapse,
} from '@material-ui/core';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 5,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  fullWidth: {
    width: '100%',
  },
});

const StoreForm = ({
  classes,
  selectBC,
  authUser,
  flagMethods,
  selectStore,
  titleMethods,
}) => {
  const { user } = authUser;
  const { almacenes } = user;
  return (
    <section className={classes.main}>
      <CssBaseline />
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h5">
          Configuraciones previas
        </Typography>
        <Collapse in className="w-100">
          <List
            className={classes.fullWidth}
            subheader={(
              <ListSubheader component="div">
                Selecciona un Almacén
              </ListSubheader>
            )}
          >
            {
              almacenes.map(store => (
                <ListItem
                  key={`StoreForm-${store.crmid}`}
                  button
                  component="button"
                  onClick={(event) => {
                    event.preventDefault();
                    const { currentTarget } = event;
                    selectStore(currentTarget);
                  }}
                  data-crmid={store.crmid}
                  data-name={store.almacen}
                  data-telefono={store.telefono}
                  data-email={store.email}
                  data-url_web={store.url_web}
                  data-image={(store.images && store.images.length > 0) ? store.images[0] : ''}
                  data-campos={store.talm_tpv_campos_en_ticket}
                  data-direccion={`${store.direccion || ''} ${store.num_ext || ''} , Colonia ${store.neighborhood || ''}, ${store.postcode || ''} ${store.city || ''}, ${store.state || ''} - ${store.country || ''}`}
                  data-direccionid={store.treebesdireccionenvioid}
                  data-forzaenvios={store.pos_forzaenvio}
                >
                  <ListItemText primary={store.almacen} />
                </ListItem>
              ))
            }
          </List>
        </Collapse>
        {/* <Collapse in={flagMethods.length > 0} className="w-100">
          <List
            className={classes.fullWidth}
            subheader={(
              <ListSubheader component="div">
                {titleMethods}
              </ListSubheader>
            )}
          >
            <Divider />
            {flagMethods.map(method => (
              <List
                dense
                key={`key_sublist_method_${method.method}`}
                className={classes.fullWidth}
                subheader={(
                  <ListSubheader component="div">
                    {method.method}
                  </ListSubheader>
                )}
              >
                {method.body.map(cb => (
                  <ListItem
                    key={`key_sublist_cb_${cb.crmid}`}
                    button
                    component="button"
                    data-crmid={cb.crmid}
                    data-parent={method.method}
                    className="pl-4"
                    onClick={(event) => {
                      event.preventDefault();
                      const { currentTarget } = event;
                      selectBC(currentTarget);
                    }}
                  >
                    <ListItemText primary={cb.referencia} />
                  </ListItem>
                ))}
              </List>
            ))}
          </List>
        </Collapse> */}
      </Paper>
    </section>
  );
};

StoreForm.propTypes = {
  selectBC: PropTypes.func,
  selectStore: PropTypes.func,
  flagMethods: PropTypes.array,
  titleMethods: PropTypes.string,
  classes: PropTypes.object.isRequired,
  authUser: PropTypes.object.isRequired,
};

StoreForm.defaultProps = {
  flagMethods: [],
  selectBC: () => {},
  selectStore: () => {},
  titleMethods: 'Seleccione caja/banco para Metodos de Pago',
};

// @ts-ignore
export default withStyles(styles)(StoreForm);
