import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Collapse,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Radio,
  Checkbox,
  LinearProgress,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

const FilterItem = ({
  primary,
  secondary,
  type,
  obj,
  setFilter,
  loading,
}) => {
  const [open, setOpen] = useState(false);
  const { data, select, filter } = obj;

  function setOption(item) {
    let newArray = [];
    if (select.some(x => x.value === item.value)) {
      newArray = select.filter(x => x.value !== item.value);
    } else {
      newArray = [...select, item];
    }
    const option = { values: newArray, filter };
    setFilter(option);
  }

  return (
    <List
      component="nav"
      classes={{ root: 'filter-list' }}
      style={{ minWidth: '160px' }}
    >
      <ListItem button onClick={() => setOpen(!open)}>
        <ListItemText
          primary={primary}
          secondary={<div style={{ maxWidth: '100px' }}>{secondary}</div>}
          classes={{ primary: 'filter-item-title', secondary: 'filter-item-subtitle' }}
        />
        <ListItemText
          primary={open ? <ExpandLess /> : <ExpandMore />}
          classes={{ root: 'pr-0', primary: 'd-flex justify-content-end' }}
        />
      </ListItem>
      {loading && (<LinearProgress className="w-100" />)}
      <Collapse in={open && !loading} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {
            data.map(option => (
              <ListItem
                button
                divider
                onClick={() => setOption(option)}
                classes={{ root: 'filter-item-bg' }}
                key={`key_filter_${option.value}`}
              >
                <ListItemText primary={option.label} classes={{ primary: 'filter-item-subItem' }} />
                <ListItemSecondaryAction>
                  {type === 'radio' && (
                    <Radio
                      color="primary"
                      onChange={() => setOption(option)}
                      checked={select.some(x => x.value === option.value)}
                      classes={{
                        root: 'filter-item-checkbox',
                        colorPrimary: 'filter-item-checkbox',
                        checked: 'filter-item-checkbox',
                      }}
                    />
                  )}
                  {type === 'check' && (
                    <Checkbox
                      color="primary"
                      onChange={() => setOption(option)}
                      checked={select.some(x => x.value === option.value)}
                      classes={{
                        root: 'filter-item-checkbox',
                        colorPrimary: 'filter-item-checkbox',
                        checked: 'filter-item-checkbox',
                      }}
                    />
                  )}
                </ListItemSecondaryAction>
              </ListItem>
            ))
            }
        </List>
      </Collapse>
    </List>
  );
};

FilterItem.propTypes = {
  primary: PropTypes.string,
  secondary: PropTypes.string,
  type: PropTypes.string,
  obj: PropTypes.object,
  setFilter: PropTypes.func,
  loading: PropTypes.bool,
};

FilterItem.defaultProps = {
  primary: 'Filtrar',
  secondary: '',
  type: 'check',
  obj: {},
  setFilter: () => {},
  loading: false,
};

export default FilterItem;
