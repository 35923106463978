import fetch from 'cross-fetch';
import ConfigurationAPI from './ConfigurationAPI';

class ExpensesAPI extends ConfigurationAPI {
    getExpensesList = async (page = 1, search = '', accountId = 0) => {
      try {
        const response = await fetch(`${this.url}/list/TreebesGTO?page=${page}&search=${search}&related_to=${accountId}`, this.credentials);
        const json = await response.json();
        return json;
      }

      catch {
        return { success: false, message: 'Error en el Host' };
      }
    };

    getExpense = async (crmid) => {
      try {
        const response = await fetch(`${this.url}/id/${crmid}`, this.credentials);
        const json = await response.json();
        return json;
      }

      catch {
        return { success: false, message: 'Error en el Host' };
      }
    };

    getExpenseInvoicesByProvider = async (page = 1, search = '', accountId = 0) => {
      try {
        const response = await fetch(`${this.url}/list/TreebesFacturasProv?page=${page}&search=${search}&related_to=${accountId}`, this.credentials);
        const json = await response.json();
        return json;
      }

      catch {
        return { success: false, message: 'Error en el Host' };
      }
    };

    getVendors = async (page = 1, search = '') => {
      try {
        const response = await fetch(`${this.url}/list/Vendors?page=${page}&search=${search}`, this.credentials);
        const json = await response.json();
        return json;
      }

      catch {
        return { success: false, message: 'Error en el Host' };
      }
    };

    getExpensesType = async (page = 1, search = '') => {
      try {
        const response = await fetch(`${this.url}/list/TreebesTdG?page=${page}&search=${search}`, this.credentials);
        const json = await response.json();
        return json;
      }

      catch {
        return { success: false, message: 'Error en el Host' };
      }
    };

    getAlmacen = async (page = 1, search = '') => {
      try {
        const response = await fetch(`${this.url}/list/TreebesALM?page=${page}&search=${search}`, this.credentials);
        const json = await response.json();
        return json;
      }

      catch {
        return { success: false, message: 'Error en el Host' };
      }
    };

    getAddShemaExpense = async () => {
      try {
        const response = await fetch(`${this.url}/TreebesGTO/schema`, this.credentials);
        const json = await response.json();
        return json;
      }

      catch {
        return { success: false, message: 'Error en el Host' };
      }
    };

    getEditShemaExpense = async (id) => {
      try {
        const response = await fetch(`${this.url}/TreebesGTO/schema/${id}`, this.credentials);
        const json = await response.json();
        return json;
      }

      catch {
        return { success: false, message: 'Error en el Host' };
      }
    };

    createExpense = async (body, method = 'POST') => {
      const url = `${this.url}/TreebesGTO`;
      this.credentials.method = method;
      this.credentials.body = JSON.stringify(body);
      try {
        const response = await fetch(url, this.credentials);
        const json = await response.json();
        return { ...json, send: body };
      } catch (err) {
        return {
          success: false,
          result: [],
          message: `Error al crear una gasto: ${err}`,
        };
      }
    };

    payExpense = async (body) => {
      const url = `${this.url}/TreebesFdE`;
      this.credentials.method = 'POST';
      this.credentials.body = JSON.stringify(body);
      try {
        const response = await fetch(url, this.credentials);
        const json = await response.json();
        return { ...json, send: body };
      } catch (err) {
        return {
          success: false,
          result: [],
          message: `Error al pagar un gasto: ${err}`,
        };
      }
    };
}

export default ExpensesAPI;
