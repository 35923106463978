import React from 'react';
import { useSelector } from 'react-redux';

import { Paper } from '@material-ui/core';
import ChequeIcon from '../../../../Icons/ChequeIcon';
import CashIcon from '../../../../Icons/CashIcon';
import CreditCardIcon from '../../../../Icons/CreditCardIcon';
import DebitCardIcon from '../../../../Icons/DebitCardIcon';
import RewardsPointsIcon from '../../../../Icons/RewardsPointsIcon';
import TransferIcon from '../../../../Icons/TransferIcon';

const MethodCheckOut = ({ payment, selectPayment }) => {
  const metohodName = {
    Efectivo: 'Efectivo',
    'Tarjeta de Debito': 'Debito',
    'Tarjeta de Credito': 'Credito',
    'Transferencia (SPEI)': '(SPEI)',
    Cheque: 'Cheque',
  };

  const paymentIcon = {
    Efectivo: <CashIcon height={50} width={50} fill={payment?.method === 'Efectivo' ? '#8252a0' : '#b7bfc6'} />,
    'Tarjeta de Debito': <DebitCardIcon height={50} width={50} fill={payment?.method === 'Tarjeta de Debito' ? '#8252a0' : '#b7bfc6'} />,
    'Tarjeta de Credito': <CreditCardIcon height={50} width={50} fill={payment?.method === 'Tarjeta de Credito' ? '#8252a0' : '#b7bfc6'} />,
    'Transferencia (SPEI)': <TransferIcon height={50} width={50} fill={payment?.method === 'Transferencia (SPEI)' ? '#8252a0' : '#b7bfc6'} />,
    Cheque: <ChequeIcon height={50} width={50} fill={payment?.method === 'Cheque' ? '#8252a0' : '#b7bfc6'} />,
  };
  const authUser = useSelector(state => state.authUser);
  const { user: { metodos_pagos: metodoPagos } } = authUser;


  return (
    <>
      <div className="w-100 mt-2">
        <p>Selecciona metodo de pago</p>
        <span />
      </div>
      <div className="d-flex" style={{ gap: 10, overflowX: 'auto', padding: 8 }}>
        {
          metodoPagos.map((method) => {
            if (method.method !== 'Intermediario pagos') {
              return (
                <Paper
                  elevation={1}
                  key={Math.random()}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    padding: '5px',
                    border: payment?.method === method.method ? '2px solid #8252a0' : '',
                  }}
                  onClick={() => selectPayment(method)}
                  onTouchStart={() => selectPayment(method)}
                >
                  {paymentIcon[`${method.method}`]}
                  <div className="d-flex justify-content-center align-items-center">
                    <span style={{ fontSize: 11, textAlign: 'center' }}>{metohodName[method.method]}</span>
                  </div>
                </Paper>
              );
            }
          })
        }
      </div>
    </>
  );
};

export default MethodCheckOut;
