/* eslint-disable max-len */
/* eslint-disable import/no-cycle */
import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import './filter.scss';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { setSearchFilter, setOrderFilter, setFilterValue } from '../../redux/actions/filtersActions';

/* Components */
import Searching from './components/Searching';
import Ordering from './components/Ordering';
import {
  componentTypes, categoriesDefault, defaultOrderData, defaultSearchData, defaultTaskStatusData, actionTypes,
} from './config';
import Filtering from './components/Filtering';

const Filter = ({
  id, open, anchorEl, close, categories, type,
}) => {
  const dispatch = useDispatch();
  const [openCollapse, setOpenCollapse] = React.useState('');
  /* filters */
  const [buscarFilter, setBuscarFilter] = React.useState('');
  const [ordenarFilter, stOrdenarFilter] = React.useState('');
  const [filtrarFilter, setFiltrarFilter] = React.useState('');


  const handleClickList = (filter) => {
    if (openCollapse === filter) {
      setOpenCollapse('');
    } else {
      setOpenCollapse(filter);
    }
  };

  const handleSetFilter = (filter, accionType) => {
    switch (accionType) {
      case actionTypes.buscar:
        setBuscarFilter(filter);
        dispatch(setSearchFilter(filter));
        break;
      case actionTypes.ordenar:
        stOrdenarFilter(filter);
        dispatch(setOrderFilter(filter));
        break;
      case actionTypes.filtrar:
        setFiltrarFilter(filter);
        dispatch(setFilterValue(filter));
        break;

      default:
        break;
    }
  };

  const getDefaltFilterDataByModule = () => {
    let defaultValuesDataByModule = [];
    switch (type) {
      case componentTypes.task:
        defaultValuesDataByModule = defaultTaskStatusData;
        break;
      case componentTypes.order:
        defaultValuesDataByModule = defaultOrderData;
        break;
      case componentTypes.search:
        defaultValuesDataByModule = defaultSearchData;
        break;
      default:
        break;
    }

    return defaultValuesDataByModule;
  };


  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={close()}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <List component="nav" classes={{ root: 'filter-list' }}>
        <ListItem divider>
          <ListItemText primary="Acciones" classes={{ primary: 'filter-title' }} />
        </ListItem>
      </List>
      {
        categories.map(item => (item.name === componentTypes.search ? (
          <Searching
            key={item}
            searchFilterValue={buscarFilter}
            searchChange={handleSetFilter}
            handleClickOpenComponent={value => handleClickList(value)}
            openComponent={openCollapse}
            data={item.values ? item.values : getDefaltFilterDataByModule()}
          />
        ) : null))
      }
      {
        categories.map(item => (item.name === actionTypes.ordenar ? (
          <Ordering
            key={item}
            orderFilterValue={ordenarFilter}
            orderChange={handleSetFilter}
            openComponent={openCollapse}
            data={item.values ? item.values : getDefaltFilterDataByModule()}
            handleClickOpenComponent={value => handleClickList(value)}
          />
        ) : null))
      }
      {
        categories.map(item => (item.name === actionTypes.filtrar ? (
          <Filtering
            key={item}
            orderFilterValue={filtrarFilter}
            orderChange={handleSetFilter}
            openComponent={openCollapse}
            data={item.values ? item.values : []}
            handleClickOpenComponent={value => handleClickList(value)}
          />
        ) : null))
      }

      {
        categories.map(item => (item.name === componentTypes.ordenar ? (
          <Ordering
            key={item}
            orderFilterValue={ordenarFilter}
            orderChange={handleSetFilter}
            openComponent={openCollapse}
            data={item.values ? item.values : defaultTaskStatusData}
            handleClickOpenComponent={value => handleClickList(value)}
          />
        ) : null))
      }
    </Popover>
  ); };

Filter.prototype = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
};

Filter.defaultProps = {
  categories: categoriesDefault,
};

export default Filter;
