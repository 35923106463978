import React from 'react';
import PropTypes from 'prop-types';

import { toolsHelper } from '../../../../helpers';

class TicketCCToPrint extends React.PureComponent {
  static propTypes = {
    authUser: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
  };

  renderRetention = (det = []) => {
    const { authUser } = this.props;
    const {
      user: { config },
    } = authUser;
    const scale = config.pos_print_size ? config.pos_print_size : '7cm';
    const index = det.findIndex(d => d.referencia.includes('MANTENER EFECTIVO'))
    if (index !== -1) {
      const firstRetention = det[index];
      return (
        <table
          className="table_detalles"
          style={{
            maxWidth: scale,
            minWidth: scale,
            marginLeft: '2em',
            marginTop: '0.4em',
            marginBottom: '0.4em',
          }}
        >
          <tbody>
            <tr className="font-size-14">
              <td>
                <div className="w-100 font-weight-bold font-size-14">Retencion: {`$${toolsHelper.formatMoney(Math.abs(firstRetention.monto))}`}</div>
              </td>
              <td />
            </tr>
          </tbody>
        </table>
      );
    }
    return;
  }

  render() {
    const { authUser, data, title } = this.props;
    const {
      user: { config, logoname, organizationname },
    } = authUser;
    const currentStore = config.store.name || config.store.almacen;
    const scale = config.pos_print_size ? config.pos_print_size : '7cm';
    return (
      <table
        style={{ maxWidth: scale, minWidth: scale }}
        className="ticketsCC_table"
      >
        <thead>
          <tr>
            <th colSpan={5}>
              <img
                src={config.store.image || logoname}
                alt={organizationname}
                className="ticketsCC_table_logo"
              />
            </th>
          </tr>
        </thead>
        <thead>
          <tr>
            <th colSpan={5}>{`Sucursal : ${currentStore}`}</th>
          </tr>
          <tr>
            <th className="ticketsCC_table_type" colSpan={5}>
              {title}
            </th>
          </tr>
        </thead>
        {data.map(x => (
          <tbody>
            <tr>
              <td colSpan={5}>
                <span className="mr-1">En</span>
                <span className="font-weight-bold mr-1">{x.label}</span>
                <span className="font-weight-bold mr-1">
                  {x.label === 'DOCS' ? '' : `$${toolsHelper.formatMoney(x.total)}`}
                </span>
                <span className="mr-1">{`(${x.cantidad})`}</span>
              </td>
            </tr>
            {
              x.label !== 'DOCS' ? (
                <>
                  {x.movimientos.map(mov => (
                    <table
                      className="table_movimientos"
                      style={{ maxWidth: scale, minWidth: scale, marginLeft: '1.4em' }}
                    >
                      <tbody>
                        <tr>
                          <td colSpan={5}>
                            <span className="mr-1">En</span>
                            <span className="font-weight-bold mr-1">{mov.label}</span>
                            {/* <span className="font-weight-bold mr-1">
                              {mov.label === 'DOCS' ? '' : `$${toolsHelper.formatMoney(mov.total)}`}
                            </span> */}
                            <span className="mr-1">{`(${mov.cantidad})`}</span>
                          </td>
                        </tr>
                      </tbody>
                      {
                        this.renderRetention(mov.detalles)
                      }
                      {mov.detalles.map(det => (
                        <table
                          className="table_detalles"
                          style={{
                            maxWidth: scale,
                            minWidth: scale,
                            marginLeft: '2em',
                            marginTop: '0.4em',
                            marginBottom: '0.4em',
                            display: (det.mantener_efe || det.referencia.includes('MANTENER EFECTIVO')) ? 'none' : '',
                          }}
                        >
                          <tbody>
                            <tr className="font-size-14">
                              <td className="w-100">
                                <div className="w-100 font-weight-bold font-size-14">{det.referencia}</div>
                                <div className="w-100">{det.createdtime}</div>
                              </td>
                              <td>
                                <div className="w-100 text-right font-weight-bold font-size-16">
                                  {`$${toolsHelper.formatMoney(det.monto)}`}
                                </div>
                                <div className="w-100 text-right"  style={{ whiteSpace: 'nowrap' }}>{det.banco}</div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      ))}
                    </table>
                  ))}
                </>
              ) : (
                <>
                  {x.documentos.map(mov => (
                    <table
                      className="table_movimientos"
                      style={{ maxWidth: scale, minWidth: scale, marginLeft: '1.4em' }}
                    >
                      <tbody>
                        <tr>
                          <td colSpan={5}>
                            {/* <span className="mr-1">En</span> */}
                            <span className="font-weight-bold mr-1">{mov.tipo}</span>
                            <span className="font-weight-bold mr-1">
                              {`( ${mov.id} )`}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                      <table
                        className="table_detalles"
                        style={{
                          maxWidth: scale,
                          minWidth: scale,
                          marginLeft: '2em',
                          marginTop: '0.4em',
                          marginBottom: '0.4em',
                        }}
                      >
                        <tbody>
                          <tr className="font-size-14">
                            <td className="w-100">
                              <div className="w-100 font-weight-bold font-size-14">{mov.venta}</div>
                              <div className="w-100">{mov.fecha_realizado}</div>
                            </td>
                            <td>
                              <div className="w-100 text-right font-weight-bold font-size-16">
                                {mov.referencia_cc}
                              </div>
                              <div className="w-100 text-right" style={{ whiteSpace: 'nowrap' }}>{mov.cliente}</div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </table>
                  ))}
                </>
              )}
          </tbody>
        ))}
      </table>
    );
  }
}

export default TicketCCToPrint;
