import React from 'react';
import PropTypes from 'prop-types';
import {
  ListItem, ListItemText, ListItemIcon,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelopeOpenText,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
const EmailItem = (props) => {
  // const { data } = props;
  const { email, clickAction } = props;
  return (
    <ListItem button divider key={email.crmid} onClick={() => clickAction(email)}>
      <ListItemIcon classes={{ root: 'mr-0' }}>
        <FontAwesomeIcon style={{ fontSize: 23 }} color="#313C52" icon={faEnvelopeOpenText} />
      </ListItemIcon>
      <ListItemText primary={email.subject} secondary={`${`${email.from_email}`} - ${moment(email.modifiedtime).format('DD MMM, h:mm a')}`} classes={{ primary: 'email-title', secondary: 'email-subtitle' }} />
      {/* <ListItemText primary={`${email.from_email}`} classes={{ primary: 'email-subtitle' }} /> */}
    </ListItem>
  );
};
EmailItem.prototype = {
  email: PropTypes.array.isRequired,
};
export default EmailItem;
