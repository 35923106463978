/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
/* Actions */
import {
  handleSellTab,
  handlePrintSize,
  changeAlmAuthUser,
  handleCartConcepts,
  changeSellOrderStatusAuthUser,
} from '../../../../../../redux/actions/authUserActions';

/* Components */
import ConfGeneral from '../components/ConfGeneral';

const General = ({
  loads,
  authUser,
  dispatchHandleSellTab,
  dispatchHandlePrintSize,
  dispatchChangeAlmAuthUser,
  dispatchHandleCartConcept,
  dispatchChangeSellOrderStatusAuthUser,
}) => (
  <div className="settings_general">
    <ConfGeneral
      loads={loads}
      authUser={authUser}
      dispatchHandleSellTab={dispatchHandleSellTab}
      dispatchHandlePrintSize={dispatchHandlePrintSize}
      dispatchChangeAlmAuthUser={dispatchChangeAlmAuthUser}
      dispatchHandleCartConcept={dispatchHandleCartConcept}
      dispatchChangeSellOrderStatusAuthUser={dispatchChangeSellOrderStatusAuthUser}
    />
  </div>
);

General.propTypes = {
  loads: PropTypes.object.isRequired,
  authUser: PropTypes.object.isRequired,
  dispatchHandleSellTab: PropTypes.func.isRequired,
  dispatchHandlePrintSize: PropTypes.func.isRequired,
  dispatchChangeAlmAuthUser: PropTypes.func.isRequired,
  dispatchHandleCartConcept: PropTypes.func.isRequired,
  dispatchChangeSellOrderStatusAuthUser: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  loads: state.loads,
  authUser: state.authUser,
});

const mapDispatchToProps = dispatch => ({
  dispatchHandleSellTab: mode => dispatch(handleSellTab(mode)),
  dispatchHandlePrintSize: mode => dispatch(handlePrintSize(mode.value)),
  dispatchChangeAlmAuthUser: alm => dispatch(changeAlmAuthUser(alm.id)),
  dispatchHandleCartConcept: bool => dispatch(handleCartConcepts(bool)),
  dispatchChangeSellOrderStatusAuthUser: status => dispatch(changeSellOrderStatusAuthUser(status.id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(General);
