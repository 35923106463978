/* eslint-disable import/no-cycle */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  List,
  Button,
  Divider,
  Collapse,
  ListItem,
  ButtonBase,
  ListItemText,
  LinearProgress,
} from '@material-ui/core';
import {
  ExpandLess,
  ExpandMore,
  Cached,
} from '@material-ui/icons';

/* Fixes */
import { useSelector } from 'react-redux';
import CC from './components/cc';
import ACC from './components/acc';
import MCC from './components/mcc';

import { toolsHelper } from '../../helpers';

const Balance = ({
  balance,
  balanceIsLoading,

  dispatchFetchBalance,

  dispatchGetCC,
  dialogCCIsOpen,
  dialogCCIsLoading,
  closeDialogCC,
  dispatchSaveCC,

  dispatchGetACC,
  dialogACCIsOpen,
  dialogACCIsLoading,
  closeDialogACC,
  dispatchSaveACC,

  sendTicket,
  dispatchGetMCC,
  closeDialogMCC,
  dialogMCCIsOpen,
  dialogMCCIsLoading,
}) => {
  const [array, setArray] = React.useState([]);
  const { user } = useSelector(state => state.authUser);

  useEffect(() => {
    const { datos } = balance;
    setArray(datos);
  }, [balance]);

  const handleClick = (tipo) => {
    const array_ = [...array];
    array_.map((x) => {
      if (x.label === tipo) {
        const y = x;
        y.open = !x.open;
        return y;
      }
      return x;
    });
    setArray(array_);
  };

  return (
    <>
      <div className="balance w-100 h-auto os-y d-flex flex-column">
        <div className="w-100 h-auto flex-column">
          <div className="w-100 d-flex justify-content-between">
            {array.length > 0 && (
              <Button
                color="primary"
                className="w-100"
                onClick={() => {
                  dispatchGetCC();
                }}
              >
                Solicitar CC
              </Button>
            )}
            {
              (user?.config.bloquear_recibir_corte && user?.config.bloquear_recibir_corte === '0') && (
                <Button
                  color="secondary"
                  className="w-100"
                  onClick={() => {
                    dispatchGetACC();
                  }}
                >
                  Recibir CC
                </Button>
              )
            }
          </div>
          <div className="w-100">
            <Button
              color="inherit"
              className="w-100"
              onClick={() => dispatchGetMCC()}
            >
              Modifica CC
            </Button>
          </div>
        </div>
      </div>
      <Divider />
      <Collapse in={balanceIsLoading}>
        <LinearProgress />
      </Collapse>
      <Collapse in={!balanceIsLoading}>
        {
          (array.length > 0)
            ? (
              <div className="balance w-100 h-auto os-y d-flex flex-column">
                <Divider />
                <List dense>
                  {
                    array.map(curr => (
                      <>
                        <div key={`key_${curr.label}`} className="w-100 h-auto">
                          <ListItem
                            button
                            onClick={() => {
                              handleClick(curr.label);
                            }}
                          >
                            <ListItemText primary={curr.label} />
                            <ListItemText
                              primary={curr.label === 'docs' ? curr.TOTAL : `$ ${toolsHelper.formatMoney(curr.TOTAL)}`}
                              className="balance_total"
                            />
                            {
                              curr.label === 'docs' ? (
                                <ListItemText
                                  primary={curr.metodos[0]?.value}
                                  className="balance_total"
                                />
                              ) : (
                                <>
                                  {curr.open ? <ExpandLess /> : <ExpandMore />}
                                </>
                              )
                            }

                          </ListItem>
                          {
                            curr.label !== 'docs' && (
                            <Collapse in={curr.open} timeout="auto" unmountOnExit>
                              <List dense disablePadding>
                                {
                                  curr.metodos.map(account => (
                                    <ListItem key={`key_${account.label}`}>
                                      <ListItemText secondary={curr.label === 'docs' ? ' ' : account.label} />
                                      <ListItemText
                                        key={`key_${account.label} value`}
                                        edge="end"
                                        style={{ textAlign: 'right' }}
                                        primary={curr.label === 'docs' ? account.value : `$ ${toolsHelper.formatMoney(account.value)}`}
                                      />
                                    </ListItem>
                                  ))
                                }
                              </List>
                              { curr.length > 1 && <Divider /> }
                            </Collapse>
                            )
                          }
                        </div>
                      </>
                    ))
                  }
                </List>
                <Divider />
              </div>
            )
            : !balanceIsLoading && (
              <>
                <div className="w-100 d-flex justify-content-center">
                  Sin datos de Balance
                </div>
                <div className="w-100 d-flex justify-content-center">
                  <ButtonBase
                    style={{
                      width: '100%',
                      height: '4em',
                    }}
                    onClick={() => {
                      dispatchFetchBalance();
                    }}
                  >
                    <Cached />
                  </ButtonBase>
                </div>
              </>
            )
        }
      </Collapse>
      <CC
        isOpen={dialogCCIsOpen}
        onClose={closeDialogCC}
        isLoading={dialogCCIsLoading}
        corteDeCaja={balance.cc}
        reloadCC={(date) => {
          dispatchGetCC(date);
        }}
        onSave={(data) => {
          dispatchSaveCC(data);
        }}
      />
      <ACC
        isOpen={dialogACCIsOpen}
        onClose={closeDialogACC}
        isLoading={dialogACCIsLoading}
        corteDeCaja={balance.acc}
        reloadACC={() => {
          dispatchGetACC();
        }}
        onSave={(data) => {
          dispatchSaveACC(data);
        }}
      />
      <MCC
        cortes={balance.mcc}
        isOpen={dialogMCCIsOpen}
        onClose={closeDialogMCC}
        isLoading={dialogMCCIsLoading}
        sendTicket={sendTicket}
        onSave={(data) => {
          dispatchSaveCC(data, true);
        }}
      />
    </>
  );
};

Balance.propTypes = {
  balance: PropTypes.object.isRequired,
  balanceIsLoading: PropTypes.bool.isRequired,

  dispatchFetchBalance: PropTypes.func.isRequired,

  dialogCCIsOpen: PropTypes.bool.isRequired,
  dialogCCIsLoading: PropTypes.bool.isRequired,
  dispatchGetCC: PropTypes.func.isRequired,
  closeDialogCC: PropTypes.func.isRequired,
  dispatchSaveCC: PropTypes.func.isRequired,

  dialogACCIsOpen: PropTypes.bool.isRequired,
  dialogACCIsLoading: PropTypes.bool.isRequired,
  dispatchGetACC: PropTypes.func.isRequired,
  closeDialogACC: PropTypes.func.isRequired,
  dispatchSaveACC: PropTypes.func.isRequired,

  sendTicket: PropTypes.func.isRequired,
  dispatchGetMCC: PropTypes.func.isRequired,
  closeDialogMCC: PropTypes.func.isRequired,
  dialogMCCIsOpen: PropTypes.bool.isRequired,
  dialogMCCIsLoading: PropTypes.bool.isRequired,
};

export default Balance;
