/* eslint-disable camelcase */
/* eslint-disable import/no-cycle */
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DetailInfo from './DetailInfo';

import {
  changeOrderStatus,
  setOrderCustomer,
} from '../../../../../../../redux/actions/orderActions';

import {
  setComment,
  setSingleDiscount,
  removeProductInCart,
  decreaseProductInCart,
  increaseProductInCart,
  updateProductQuantityInCart,
  setEditProductsComments,
} from '../../../../../../../redux/actions/cartActions';

const mapStateToProps = (state) => {
  const { loads, cart, authUser } = state;
  const { user: { sostatus } } = authUser;
  const { domain } = authUser;
  const loading = Boolean(loads.orderIsLoading);
  return ({
    loading, cart, sostatus, domain,
  });
};

const mapDispatchToProps = dispatch => ({
  setOrderStatus: id => dispatch(changeOrderStatus(id)),
  editCustomerOrder: account_id => dispatch(setOrderCustomer(account_id)),

  /* Cart */
  inputChangeQty: (row, inc = false) => {
    if (inc) dispatch(increaseProductInCart(row));
    else dispatch(decreaseProductInCart(row));
  },
  onChangeQty: (row, value = '0') => {
    const qty = Number(value);
    dispatch(updateProductQuantityInCart(row, qty));
  },
  setRowDiscount: (row, value) => {
    const percentage = (parseFloat(value) || 0);
    dispatch(setSingleDiscount(row, percentage));
  },
  removeProduct: row => dispatch(removeProductInCart(row)),
  setComment: (product) => {
    dispatch(setEditProductsComments(true));
    dispatch(setComment(product));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DetailInfo));
