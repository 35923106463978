/* eslint-disable no-console */
/* eslint-disable consistent-return */
/* eslint-disable camelcase */
/* eslint-disable import/no-cycle */
import { toast } from 'react-toastify';
import OrderApi from '../../api/OrderAPI';
import ClientAPI from '../../api/ClientAPI';
import TableAPI from '../../api/TableAPI';
import FdeAPI from '../../api/flujo';
import MdaAPI from '../../api/MdaApi';
import FacturasAPI from '../../api/FacturasAPI';
import DownloadAPI from '../../api/DownloadAPI';
import ProductAPI from '../../api/ProductAPI';
import { loading, loaded } from './loadActions';
import { orderHelper, toolsHelper } from '../../helpers';
import { setGeneralColumnDefs } from './authUserActions';
import { addDiscount, removeDiscount } from './discountActions';
import {
  addOrderToCart,
  clearCart,
  alertProducts,
  evaluateStock,
  updatePromotions,
} from './cartActions';
import {
  selectCustomer,
  fetchAllCustomers,
  /* fetchAddressByCustomer, */
} from './customerActions';
import { editarCotizacion } from './cotizarActions';

export const ADD_ORDERS = 'ADD_ORDERS';
export const CLEAR_MULTIFILTER_ORDER = 'CLEAR_MULTIFILTER_ORDER';
export const CONFIG_SELL_COLUMN_DEF = 'CONFIG_SELL_COLUMN_DEF';
export const DEFINE_COLUMN_ORDER = 'DEFINE_COLUMN_ORDER';
export const FETCH_ALL_ORDERS = 'FETCH_ALL_ORDERS';
export const FETCH_FACTURAS = 'FETCH_FACTURAS';
export const FETCH_FDE = 'FETCH_FDE';
export const FETCH_FILTER = 'FETCH_FILTER';
export const FETCH_MDA = 'FETCH_MDA';
export const FILTER_ORDERS = 'FILTER_ORDERS';
export const GET_COLUMN_DEF_ORDER = 'GET_COLUMN_DEF_ORDER';
export const GET_FILES = 'GET_FILES';
export const GET_NEXT_PAGE_ORDER = 'GET_NEXT_PAGE_ORDER';
export const GET_ORDERS_BY_PAGE = 'GET_ORDERS_BY_PAGE';
export const HANDLE_EDIT_ORDER = 'HANDLE_EDIT_ORDER';
export const REMOVE_MULTIFILTER_ORDER = 'REMOVE_MULTIFILTER_ORDER';
export const RESET_COLUMN_DEF = 'RESET_COLUMN_DEF';
export const RESET_EDIT_ORDER = 'RESET_EDIT_ORDER';
export const RESET_STATE = 'RESET_STATE';
export const SELECT_ORDER = 'SELECT_ORDER';
export const SET_COLUMN_DEF = 'SET_COLUMN_DEF';
export const SET_MULTIFILTER_ORDER = 'SET_MULTIFILTER_ORDER';
export const SET_PRESELECT_ORDER = 'SET_PRESELECT_ORDER';
export const IS_EDIT_ORDER = 'IS_EDIT_ORDER';
export const COMBINED_PRODUCT_ID = 'COMBINED_PRODUCT_ID';
export const SET_ORDER_VIEW_DATA = 'SET_ORDER_VIEW_DATA';

export function addOrders(orders) {
  return {
    type: ADD_ORDERS,
    orders,
  };
}

export function isEditMobileOrders(isEditOrder) {
  return {
    type: IS_EDIT_ORDER,
    isEditOrder,
  };
}

export function setCombinedProductId(combinedProductId) {
  return {
    type: COMBINED_PRODUCT_ID,
    combinedProductId,
  };
}

export function addPreselectOrder(order) {
  return {
    type: SET_PRESELECT_ORDER,
    order,
  };
}

export function getOrdersByPage(page = 0) {
  return async (dispatch) => {
    dispatch(loading('order'));
    try {
      await new Promise(resolve => setTimeout(resolve));
      dispatch({
        type: GET_ORDERS_BY_PAGE,
        page,
      });
    } catch {
      /* Continue regardless of error */
    } finally {
      dispatch(loaded('order'));
    }
  };
}

export function getNextOrderPage(page = 0) {
  return async (dispatch) => {
    dispatch(loading('order'));
    try {
      await new Promise(resolve => setTimeout(resolve));
      dispatch({
        type: GET_NEXT_PAGE_ORDER,
        page,
      });
    }
    catch {
      /* Continue regardless of error */
    }
    finally {
      dispatch(loaded('order'));
    }
  };
}

export function fetchFilter() {
  return async (dispatch, getState) => {
    const { order } = getState();
    try {
      await new Promise(resolve => setTimeout(resolve));
      dispatch({ type: FETCH_FILTER, order });
    } catch (err) {
      console.log('fetchFilter err: ', err);
    }
  };
}

export function setMultiFilterOrder(item) {
  return async (dispatch, getState) => {
    const { order } = getState();
    const { filters } = order;
    try {
      await new Promise(resolve => setTimeout(resolve));
      dispatch({
        type: SET_MULTIFILTER_ORDER,
        filters,
        item,
      });
    } catch (err) {
      console.log('setMultiFilterOrder err: ', err);
    }
  };
}

export function removeMultiFilterOrder(word) {
  return async (dispatch, getState) => {
    const { order } = getState();
    const { filters } = order;
    dispatch(loading('order'));
    try {
      await new Promise(resolve => setTimeout(resolve));
      dispatch({
        type: REMOVE_MULTIFILTER_ORDER,
        filters,
        word,
      });
    } catch (err) {
      console.log('removeMultiFilter err: ', err);
    } finally {
      dispatch(loaded('order'));
    }
  };
}

export function clearAllFiltersOrder() {
  return {
    type: CLEAR_MULTIFILTER_ORDER,
  };
}

export function fetchAllOrders(search = '') {
  const orderApi = new OrderApi();
  return async (dispatch, getState) => {
    const { authUser } = getState();
    await dispatch(loading('order'));
    await dispatch({
      type: FETCH_ALL_ORDERS,
    });
    try {
      const { user: { config } } = authUser;
      const fde = orderHelper.getFdeFilters(config.pos_filtrofde, 'estado_fde[]=');
      const mda = orderHelper.getFdeFilters(config.pos_filtromda, 'estado_mda[]=');
      const sos = orderHelper.getStateFilters(config.pos_filtrostate);
      const response = await orderApi.getAll(fde, mda, sos, search);
      await dispatch(addOrders(response.result));
    } catch (err) {
      await dispatch(addOrders([], authUser));
    } finally {
      await dispatch(clearAllFiltersOrder());
      await dispatch(loaded('order'));
    }
  };
}

export function selectOrder(crmid) {
  return async (dispatch, getState) => {
    dispatch(removeDiscount());
    dispatch(loading('order'));
    try {
      const { order, customer } = getState();
      const { customersByArray } = customer;
      let foundOrder = order.orders.find(element => element.crmid === crmid);
      if (foundOrder === undefined) {
        const productApi = new ProductAPI();
        const response = await productApi.getSingle(crmid);
        if (response.success) {
          foundOrder = response.result;
        } else {
          const { message } = response;
          toast.warning(message.warning, message.error);
          return;
        }
      }
      const crmidUser = foundOrder.account_id.split('x').pop();
      let foundCustomer = customersByArray.find(item => item.crmid === crmidUser);
      if (foundCustomer !== undefined) {
        if (toolsHelper.stringIsEmpty(foundOrder.hdnDiscountAmount)
          || toolsHelper.stringIsEmpty(foundOrder.hdnDiscountPercent)) {
          const result = orderHelper.getDiscountStateFromOrder(foundOrder);
          dispatch(addDiscount(result));
        }
        dispatch({ type: SELECT_ORDER });
        await dispatch(addOrderToCart(foundOrder));
        await dispatch(selectCustomer(foundCustomer));
      } else {
        const clientApi = new ClientAPI();
        const response = await clientApi.getAccount(crmidUser);
        const { success } = response;
        if (success) {
          foundCustomer = response.result;
        } else {
          await toast.success('Cliente encontrado sin RFC y sin correo electrónico');
          const { accountname } = foundOrder;
          foundCustomer = { accountname };
        }
      }
      if (toolsHelper.stringIsEmpty(foundOrder.hdnDiscountAmount)
            || toolsHelper.stringIsEmpty(foundOrder.hdnDiscountPercent)) {
        const result = orderHelper.getDiscountStateFromOrder(foundOrder);
        dispatch(addDiscount(result));
      }
      dispatch({ type: SELECT_ORDER });
      await dispatch(addOrderToCart(foundOrder));
      await dispatch(selectCustomer(foundCustomer));
    } catch (selectOrderErr) {
      console.log({ selectOrderErr });
    } finally {
      dispatch(loaded('order'));
    }
  };
}

export function filterOrder(word) {
  return async (dispatch, getState) => {
    dispatch(loading('order'));
    const { tabs } = getState();
    try {
      await new Promise(resolve => setTimeout(resolve));
      switch (tabs.posDisplayTab) {
        case 'grid':
          dispatch({
            type: FILTER_ORDERS,
            word,
          });
          break;
        case 'list':
          dispatch(setMultiFilterOrder({ title: 'search', value: word, type: 'search' }));
          break;
        default:
          dispatch({
            type: FILTER_ORDERS,
            word,
          });
      }
    } catch {
      /* Continue regardless of error */
    } finally {
      dispatch(loaded('order'));
    }
  };
}

/**
 * [setActivityToOrder Deliver or Refund a order in a lot of concepts: Wharehouse Moves, Money, others.]
 *
 * @param   {string}  crmid     [crmid Ordr CRMID
 * @param   {string}  activity  [activityThe actvity that should be done]
 *
 */
export function setActivityToOrder(crmid, activity, history = {}) {
  console.log('MOVIMIENTO DE ALMACEN');
  const orderApi = new OrderApi();
  const tableApi = new TableAPI();
  return async (dispatch, getState) => {
    dispatch(loading('order'));
    const {
      cart,
      table,
      // timbrar: { makeBill },
      // authUser,
    } = getState();
    let response;
    try {
      switch (activity) {
        case 'Entregar':
          response = await tableApi.setMDA(table, cart, activity);
          break;
        case 'Devolver Dineros':
          response = await orderApi.refundOrder(crmid, 'money');
          break;
        case 'Devolver Productos':
          response = await tableApi.setMDA(table, cart, activity);
          break;
        default:
          return dispatch(loaded('order'));
      }
      if (response) {
        await dispatch(fetchAllCustomers());
        await dispatch(clearCart());
        await dispatch(fetchAllOrders());
        toast.success(` ✔  ${response.message} `);
        if (activity === 'Entregar') {
          if (Object.keys(history).length > 0) {
            history.push('/pos/venta');
            history.push(`/pos/venta/${crmid}`);
          }
          // const { user: { config: { pos_checkout_bill } } } = authUser;
          // const billConfig = Boolean(pos_checkout_bill === 'Siempre');
          // const { modals } = getState();
          // if (!modals.deliverModalIsOpen) {
          //   if (billConfig) {
          //     toast.info('Facturación automatica posterior a la entrega');
          //     setTimeout(async () => {
          //       await dispatch(fetchTimbrado(crmid));
          //     }, 2000);
          //   } else if (makeBill) {
          //     toast.info('Facturación posterior a la entrega');
          //     setTimeout(async () => {
          //       await dispatch(fetchTimbrado(crmid));
          //     }, 2000);
          //   }
          // }
        }
      } else {
        toast.warn(`❌ ${response.message}`);
      }
    } catch (err) {
      toast.error(' ❌ Error - No se pudo completar el proceso');
    } finally {
      dispatch(loaded('order'));
    }
  };
}

export function configSellColumnDef(field) {
  return async (dispatch, getState) => {
    await dispatch({
      type: CONFIG_SELL_COLUMN_DEF,
      field,
    });
    const { order: { columnsDefs } } = getState();
    const defs = columnsDefs;
    await dispatch(setGeneralColumnDefs(defs, false));
  };
}

export function setColumnsDefs(defs) {
  return async (dispatch) => {
    await dispatch({
      type: SET_COLUMN_DEF,
      defs,
    });
    await dispatch(setGeneralColumnDefs(defs, false));
  };
}

export function resetColumnsDefs() {
  return async (dispatch) => {
    dispatch({ type: RESET_COLUMN_DEF });
  };
}

export function getConfigOrderDef() {
  return async (dispatch, getState) => {
    await dispatch({ type: GET_COLUMN_DEF_ORDER });
    const { order: { columnsDefs } } = getState();
    const defs = columnsDefs;
    await dispatch(setGeneralColumnDefs(defs, false));
  };
}

export function changeOrderStatus(sostatus) {
  const orderApi = new OrderApi();
  return async (dispatch, getState) => {
    dispatch(loading('order'));
    try {
      const { cart: { orderSelected: { order: { crmid }, order } } } = getState();
      // eslint-disable-next-line no-prototype-builtins
      const isQuote = order && order.hasOwnProperty('quote_no') ? true : false;
      const { success, message, result } = await orderApi.setStatus(crmid, sostatus, isQuote);
      if (success) {
        toast.success(` ✔  ${message.success} `);
        await dispatch(clearCart());
        await dispatch(fetchAllOrders());
        await dispatch(selectOrder(crmid));
      } else {
        await dispatch(evaluateStock());
        toast.warn(message.error);
      }
      if (result.noHayDe && Object.keys(result.noHayDe).length > 0) {
        await dispatch(alertProducts(result.noHayDe));
      }
    } catch (error) {
      console.log('changeOrderStatus error: ', error);
    } finally {
      dispatch(loaded('order'));
    }
  };
}

export function downloadFile(url, name) {
  return async (dispatch) => {
    await dispatch(loading('order'));
    try {
      const downloadApi = new DownloadAPI();
      await downloadApi.download(url, name);
    } catch (err) {
      console.log({ downloadFile: err });
    } finally {
      await dispatch(loaded('order'));
    }
  };
}

export function getFilesByOrder(page = 1, search = '', isOrder = true) {
  const orderApi = new OrderApi();
  return async (dispatch, getState) => {
    await dispatch(loading('order'));
    try {
      const { cart } = getState();
      const { orderSelected } = cart;
      if (Object.keys(orderSelected).length > 0) {
        const { crmid } = orderSelected;
        const response = await orderApi.getFiles(crmid, page, search, isOrder);
        if (response.success) {
          await dispatch({
            type: GET_FILES,
            payload: {
              result: response.result,
              pages: response.pages,
              count: response.count,
              actualPage: page,
            },
          });
        } else {
          toast.warn(response.message.warning);
        }
      } else {
        toast.info('Seleccione una orden primero');
      }
    } catch (err) {
      console.log({ getFilesByOrderErr: err });
    } finally {
      await dispatch(loaded('order'));
    }
  };
}

export function deleteFile(crmid, label) {
  return async (dispatch) => {
    await dispatch(loading('order'));
    try {
      const orderApi = new OrderApi();
      const response = await orderApi.deleteFile(crmid);
      if (response.success) {
        await dispatch(getFilesByOrder(1, ''));
        await toast.success(`Archivo: ${label} eliminado`);
      } else {
        toast.warning(response.message.error || response.message.warning);
      }
    } catch (err) {
      console.log({ deleteFile: err });
    } finally {
      await dispatch(loaded('order'));
    }
  };
}

export function uploadFilesToOrder(files) {
  const orderApi = new OrderApi();
  return async (dispatch, getState) => {
    dispatch(loading('order'));
    try {
      const { cart } = getState();
      const { orderSelected: { crmid, order } } = cart;
      // eslint-disable-next-line no-prototype-builtins
      const isOrder = !(order && order.hasOwnProperty('quote_no'));
      const res = await orderApi.uploadFile(crmid, files, isOrder);
      const { success } = res;
      if (success) {
        toast.success(res.message.success);
        await dispatch(getFilesByOrder(1, ''));
      } else {
        toast.warn(res.message.error);
      }
    } catch (err) {
      console.log('uploadFiles err: ', err);
    } finally {
      dispatch(loaded('order'));
    }
  };
}

export function setOrderCustomer(account_id) {
  const orderApi = new OrderApi();
  return async (dispatch, getState) => {
    await dispatch(loading('order'));
    try {
      toast.info('Cambio de cliente ...');
      const { cart } = getState();
      const { orderSelected: { crmid, order } } = cart;
      // eslint-disable-next-line no-prototype-builtins
      const isQuote = (order && order.hasOwnProperty('quote_no')) ? true : false;
      const res = await orderApi.setAccount(crmid, account_id, isQuote);
      const { success } = res;
      if (success) {
        toast.success(res.message.success);
        if (isQuote) {
          await dispatch(editarCotizacion());
        } else {
          await dispatch(fetchAllOrders());
          await dispatch(selectOrder(crmid));
        }
        // const { customer } = getState();
        // const {
        //   selectedCustomer: {
        //     treebesdireccionenvioid,
        //     treebesdireccionfacturaid,
        //   },
        // } = customer;
        // if (treebesdireccionenvioid !== '' || treebesdireccionfacturaid !== '') {
        //   await dispatch(fetchAddressByCustomer(customer.selectedCustomer.crmid));
        //   await dispatch(openModal('alertAddressCustomer'));
        // }
      } else {
        toast.warn(res.message.warning);
      }
    } catch (err) {
      console.log('setOrderCustomer err: ', err);
    } finally {
      await dispatch(loaded('order'));
    }
  };
}

export function fetchFde() {
  const fdeApi = new FdeAPI();
  return async (dispatch, getState) => {
    await new Promise(resolve => setTimeout(resolve));
    await dispatch(loading('order'));
    try {
      const { cart } = getState();
      const { orderSelected } = cart;
      if (Object.keys(orderSelected).length > 0) {
        const { order } = orderSelected;
        const response = await fdeApi.get(order.crmid);
        const { success } = response;
        if (success) {
          const fde = response.result;
          await dispatch({
            type: FETCH_FDE,
            fde,
          });
        } else {
          toast.warn(response.messsage.error || response.messsage.warning);
        }
      } else {
        toast.info('Seleccione una orden primero');
      }
    } catch (fetchFdeErr) {
      console.log({ fetchFdeErr });
      toast.warn(`Mientras cargabamos los flujos de efectivo surgió este error: ${fetchFdeErr}`);
    } finally {
      await dispatch(clearAllFiltersOrder());
      await dispatch(loaded('order'));
    }
  };
}


export function fetchMda() {
  const mdaApi = new MdaAPI();
  return async (dispatch, getState) => {
    await new Promise(resolve => setTimeout(resolve));
    await dispatch(loading('order'));
    try {
      const { cart } = getState();
      const { orderSelected } = cart;
      if (Object.keys(orderSelected).length > 0) {
        const { order } = orderSelected;
        const response = await mdaApi.get(order.crmid);
        const { success } = response;
        if (success) {
          const mda = response.result;
          await dispatch({
            type: FETCH_MDA,
            mda,
          });
        } else {
          toast.warn(response.messsage.error || response.messsage.warning);
        }
      } else {
        toast.info('Seleccione una orden primero');
      }
    } catch (fetchMdaErr) {
      console.log({ fetchMdaErr });
      toast.warn(`Mientras cargabamos los movimientos de almacén surgió este error: ${fetchMdaErr}`);
    } finally {
      await dispatch(clearAllFiltersOrder());
      await dispatch(loaded('order'));
    }
  };
}

export function fetchFactura() {
  const facturasAPI = new FacturasAPI();
  return async (dispatch, getState) => {
    await new Promise(resolve => setTimeout(resolve));
    await dispatch(loading('order'));
    try {
      const { cart } = getState();
      const { orderSelected } = cart;
      if (Object.keys(orderSelected).length > 0) {
        const { order } = orderSelected;
        const response = await facturasAPI.get(order.crmid);
        const { success } = response;
        if (success) {
          const facturas = response.result;
          await dispatch({
            type: FETCH_FACTURAS,
            facturas,
          });
        } else {
          toast.warn(response.messsage.error || response.messsage.warning);
        }
      } else {
        toast.info('Seleccione una orden primero');
      }
    } catch (fetchMdaErr) {
      console.log({ fetchMdaErr });
      toast.warn(`Mientras cargabamos los movimientos de almacén surgió este error: ${fetchMdaErr}`);
    } finally {
      await dispatch(clearAllFiltersOrder());
      await dispatch(loaded('order'));
    }
  };
}

export function defineOrderColumns(config) {
  return async (dispatch) => {
    await dispatch({ type: DEFINE_COLUMN_ORDER, config });
  };
}

export function handleEditOrder() {
  return async (dispatch) => {
    await dispatch({ type: HANDLE_EDIT_ORDER });
    await dispatch(updatePromotions());
  };
}

/*
export function handleEditOrder() {
  return { type: HANDLE_EDIT_ORDER };
} */

export function resetEditOrder() {
  return { type: RESET_EDIT_ORDER };
}
export function resetState() {
  return { type: RESET_STATE };
}

export function setOrderViewData(orderView) {
  return {
    type: SET_ORDER_VIEW_DATA,
    orderView,
  };
}

export function fetchOrderView(cvid, page = 1, searchParams = [], orderBy = 'createdtime', sortorder) {
  const order = orderBy || 'createdtime';
  const orderApi = new OrderApi();
  return async (dispatch, getState) => {
    const { order: { lists: { default: cvidDefault } }, tabs: { posTypeTab } } = getState();
    const cvidList = cvid || cvidDefault;
    const isOrder = posTypeTab === 'Historico';

    if (cvidList && (posTypeTab === 'Historico' || posTypeTab === 'Cotizaciones')) {
      dispatch(loading('list'));
      const response = await orderApi.getListView(isOrder, cvidList, page, searchParams, order, sortorder);
      const { success, result } = response;

      if (success) {
        dispatch(setOrderViewData(result));
      } else {
        toast.error('Error obteniendo la data de la lista seleccionada');
      }

      dispatch(loaded('list'));
    } else {
      console.log('No hay lista seleccionada');
    }
    dispatch(loaded('refreshOrders'));
  };
}
