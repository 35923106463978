import React from 'react';
import PropTypes from 'prop-types';
import { Search, AttachMoney } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import CountTo from '../../../../../components/CountTo/count-to';

let timeout = null;

const SearchFab = (
  {
    cart,
    location,
    dispatchFilterOrders,
    dispatchFilterProducts,
    dispatchFilterCotizaciones,
  },
) => {
  const inputRef = React.createRef();
  const [toggle, setToggle] = React.useState(false);
  const [search, setSearch] = React.useState('');
  const isEditOrder = useSelector(state => state.order.isEditOrder);

  const slickSearchIcon = () => {
    if (!toggle) inputRef.current.focus();
    setToggle(!toggle);
  };

  const handleChangeSearch = (event) => {
    setSearch(event.target.value);
  };

  const evaluateTab = () => {
    switch (location.pathname) {
      case '/pos/caja':
        dispatchFilterProducts(search);
        break;
      case '/pos/venta':
      case '/almacenes':
        dispatchFilterOrders(search);
        break;
      case '/pos/cotizacion':
        dispatchFilterCotizaciones(search);
        break;
      default:
        if (isEditOrder) {
          dispatchFilterProducts(search);
        }
        return null;
    }
    window.scroll(0, 0);
    return null;
  };

  const classAppear = 'searchFab__circle d-flex align-items-center justify-content-start';
  const classTotal = 'searchFab__total d-flex align-items-end justify-content-end py-1';

  return (
    <div className="searchFab d-flex align-items-center justify-content-between w-100 h-100">
      <div className={toggle ? `${classAppear} extended` : classAppear}>
        <IconButton onClick={() => { slickSearchIcon(); }}>
          <Search id="actions_search_tag" />
        </IconButton>
        <input
          id="mobile_input_search"
          style={{ fontSize: 16 }}
          autoComplete="false"
          ref={inputRef}
          type="text"
          placeholder="Buscar producto"
          value={search}
          onFocus={() => { setToggle(true); }}
          onChange={(event) => { handleChangeSearch(event); }}
          onBlur={() => { slickSearchIcon(); }}
          onKeyUp={(e) => {
            clearTimeout(timeout);
            if (e.keyCode !== 13) {
              timeout = setTimeout(() => {
                evaluateTab();
              }, 750);
            } else {
              evaluateTab();
            }
          }}
        />
      </div>
      {
        location.pathname === '/pos/caja' && (
          <div className={toggle || cart.total <= 0 ? classTotal : `${classTotal} totalExtension`}>
            <div className="searchFab__total_price d-flex flex-row align-items-end">
              <AttachMoney fontSize="small" className="pb-1" />
              <CountTo to={cart.total} />
            </div>
          </div>
        )
      }
    </div>
  );
};

SearchFab.propTypes = {
  cart: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  dispatchFilterOrders: PropTypes.func.isRequired,
  dispatchFilterProducts: PropTypes.func.isRequired,
  dispatchFilterCotizaciones: PropTypes.func.isRequired,
};

export default withRouter(SearchFab);
