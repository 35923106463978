import React from 'react';
import './Number.scss';
import PropTypes from 'prop-types';

import { ButtonBase, Zoom } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const InputNumber = ({
  anim,
  circleDiameter,
  disabledInput,
  disabledMinus,
  disabledPlus,
  fontSize,
  height,
  number,
  onChange,
  onClickMinus,
  onClickPlus,
  onDanger,
  onWarning,
  onlyNumber,
  width,
}) => {
  if (anim) {
    return (
      <div className="inputContainer">
        <Zoom in={onlyNumber} mountOnEnter unmountOnExit>
          <div className="onlyNumber">
            <div
              className="onlyNumber_circle"
              style={{
                fontSize: `${fontSize}px`,
                width: circleDiameter,
                height: circleDiameter,
              }}
            >
              {number}
            </div>
          </div>
        </Zoom>
        <Zoom in={!onlyNumber} mountOnEnter unmountOnExit>
          <div
            className={`inputNumber ${
              onDanger && ('inputNumber_dang')
            } ${
              onWarning && ('inputNumber_warn')
            }`}
            style={{
              width,
              height,
            }}
          >
            <ButtonBase
              disabled={disabledMinus}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onClickMinus();
              }}
            >
              <FontAwesomeIcon
                icon="minus"
                style={{
                  width: `${fontSize}px`,
                  heigth: `${fontSize}px`,
                  color: `${disabledMinus ? ('#c6c6c6') : ('#303c52')}`,
                }}
              />
            </ButtonBase>
            <input
              type="number"
              step="0.1"
              className="inputNumber_number"
              style={{
                fontSize: `${fontSize}px`,
                minWidth: '50px',
              }}
              value={number}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              onFocus={(e) => {
                e.preventDefault();
                e.stopPropagation();
                e.target.select();
              }}
              onChange={(e) => {
                const { target: { value } } = e;
                if (!disabledInput) onChange(value);
              }}
              disabled={disabledInput}
            />
            <ButtonBase
              disabled={disabledPlus}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onClickPlus();
              }}
            >
              <FontAwesomeIcon
                icon="plus"
                style={{
                  width: `${fontSize}px`,
                  heigth: `${fontSize}px`,
                  color: `${disabledPlus ? ('#c6c6c6') : ('#303c52')}`,
                }}
              />
            </ButtonBase>
          </div>
        </Zoom>
      </div>
    );
  }
  return (
    <div className="inputContainer">
      {onlyNumber ? (
        <div className="onlyNumber">
          <div
            className="onlyNumber_circle"
            style={{
              fontSize: `${fontSize}px`,
              width: circleDiameter,
              height: circleDiameter,
            }}
          >
            {number}
          </div>
        </div>

      ) : (
        <div
          className={`inputNumber ${
            onDanger && ('inputNumber_dang')
          } ${
            onWarning && ('inputNumber_warn')
          }`}
          style={{
            width,
            height,
          }}
        >
          <ButtonBase
            disabled={disabledMinus}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onClickMinus();
            }}
          >

            <FontAwesomeIcon
              icon="minus"
              style={{
                width: `${fontSize}px`,
                heigth: `${fontSize}px`,
                color: `${disabledMinus ? ('#c6c6c6') : ('#303c52')}`,
              }}
            />
          </ButtonBase>
          <input
            className="inputNumber_number"
            type="number"
            step="0.1"
            style={{
              fontSize: `${fontSize}px`,
              minWidth: '50px',
            }}
            value={number}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onFocus={(e) => {
              e.preventDefault();
              e.stopPropagation();
              e.target.select();
            }}
            onChange={(e) => {
              const { target: { value } } = e;
              if (!disabledInput) onChange(value);
            }}
            disabled={disabledInput}
          />
          <ButtonBase
            disabled={disabledPlus}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onClickPlus();
            }}
          >
            <FontAwesomeIcon
              icon="plus"
              style={{
                width: `${fontSize}px`,
                heigth: `${fontSize}px`,
                color: `${disabledPlus ? ('#c6c6c6') : ('#303c52')}`,
              }}
            />
          </ButtonBase>
        </div>
      )}
    </div>
  );
};

InputNumber.propTypes = {
  anim: PropTypes.bool,
  circleDiameter: PropTypes.string,
  disabledInput: PropTypes.bool,
  disabledMinus: PropTypes.bool,
  disabledPlus: PropTypes.bool,
  fontSize: PropTypes.number,
  height: PropTypes.string,
  number: PropTypes.number,
  onChange: PropTypes.func,
  onClickMinus: PropTypes.func,
  onClickPlus: PropTypes.func,
  onDanger: PropTypes.bool,
  onWarning: PropTypes.bool,
  onlyNumber: PropTypes.bool,
  width: PropTypes.string,
};

InputNumber.defaultProps = {
  anim: true,
  circleDiameter: '36px',
  disabledInput: false,
  disabledMinus: false,
  disabledPlus: false,
  fontSize: 18,
  height: '100%',
  number: 0,
  onChange: () => {},
  onClickMinus: () => {},
  onClickPlus: () => {},
  onDanger: false,
  onWarning: false,
  onlyNumber: false,
  width: '100%',
};

export default InputNumber;
