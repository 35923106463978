import React from 'react';
import PropTypes from 'prop-types';

import {
  Drawer,
  Button,
  Collapse,
  TextField,
} from '@material-ui/core';

const Comment = ({
  done,
  label,
  close,
  isOpen,
  button,
  sendDesc,
  labelStill,
  description,
  enableEditOrder,
}) => {
  /* Hooks */
  const [desc, setDesc] = React.useState('');
  const onChange = (e) => {
    const { value } = e.target;
    setDesc(value);
  };
  const handleDesc = () => {
    sendDesc(desc);
  };
  React.useEffect(() => {
    setDesc(description);
  }, [description]);
  /* Render */
  return (
    <Drawer
      anchor="bottom"
      open={isOpen}
      className="checkout_drawerUp"
      onClose={close}
    >
      <Collapse in={enableEditOrder}>
        <div className="w-100 p-2 d-flex flex-column">
          <TextField
            id="text_description"
            color="primary"
            label={label}
            multiline
            rowsMax="4"
            fullWidth
            autoFocus
            value={desc}
            onChange={onChange}
          />
        </div>
      </Collapse>
      <Collapse in={description !== ''}>
        <div className="w-100 p-2 d-flex flex-column">
          <TextField
            color="secondary"
            label={labelStill}
            fullWidth
            multiline
            disabled
            value={description}
          />
        </div>
      </Collapse>
      <Collapse className="w-100" in={(description !== desc)}>
        <div className="w-100 p-2 d-flex">
          <Button
            fullWidth
            color="secondary"
            variant="contained"
            onClick={handleDesc}
          >
            {button}
          </Button>
        </div>
      </Collapse>
      <Collapse in={(desc === description)}>
        <div className="w-100 p-2 d-flex">
          <Button
            fullWidth
            color="primary"
            variant="contained"
            onClick={close}
          >
            {done}
          </Button>
        </div>
      </Collapse>
    </Drawer>
  );
};

Comment.propTypes = {
  done: PropTypes.string,
  label: PropTypes.string,
  button: PropTypes.string,
  labelStill: PropTypes.string,
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  sendDesc: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
  enableEditOrder: PropTypes.bool.isRequired,
};

Comment.defaultProps = {
  button: 'Añadir',
  done: 'Listo',
  label: 'Añade una Descripción',
  labelStill: 'Descripción actual',
};

export default Comment;
