/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import '../../../NewExpenseForm';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import {
  Collapse,
  LinearProgress,
} from '@material-ui/core';
import { formatMoney } from '../../../../../../helpers/tools';
import SearchDialog from '../../../SearchDialog';
import { openModal } from '../../../../../../redux/actions/modalActions';
import ExpensesApi from '../../../../../../api/ExpensesApi';

const EditExpenseForm = withRouter(({ history, schema, gasto }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [type, setType] = React.useState('');
  /* const [openDetail, setOpenDetail] = React.useState(false); */

  /* form */
  const [referencia, setReferencia] = React.useState(gasto.referencia);
  const [description, setDescription] = React.useState(gasto.description);
  const [total, setTotal] = React.useState(formatMoney(gasto.total));

  const proveedor = useSelector(state => state.gastos.proveedor);
  const tipoGasto = useSelector(state => state.gastos.tipoGastoSeleccionado);
  const almacen = useSelector(state => state.gastos.almacenSeleccionado);

  const expensesApi = new ExpensesApi();

  const handleCloseSearch = () => {
    setOpen(false);
  };

  const openSearchDialog = (tipo) => {
    setType(tipo);
    dispatch(openModal('search'));
    setOpen(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'referencia':
        setReferencia(value);
        break;
      case 'total':
        setTotal(value);
        break;
      case 'description':
        setDescription(value);
        break;

      default:
        break;
    }
  };

  const validarPago = (pago) => {
    if (pago.referencia === '' || pago.referencia === null) {
      toast.error('El campo referencia es obligatorio');
      return false;
    }
   /*  if (pago.treebestdgid === '' || pago.treebestdgid !== tipoGasto.crmid || pago.treebestdgid === undefined) {
      toast.error('El campo tipo de gasto es obligatorio');
      return false;
    }
    if (pago.treebesalmid === '' || pago.treebesalmid !== almacen.crmid || pago.treebesalmid === undefined) {
      toast.error('El campo almacen es obligatorio');
      return false;
    }
    if (pago.description === '' || pago.description === null || pago.total === undefined) {
      toast.error('El campo descripción es obligatorio');
      return false;
    } */
    if (pago.total === '' || pago.total === null || pago.total === undefined) {
      toast.error('El campo total es obligatorio');
      return false;
    }

    return true;
  };

  const handleSubmit = async (e, pay = false) => {
    e.preventDefault();
    setLoading(true);
    const today = new Date();
    const date = `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`;

    const editGasto = {
      crmid: gasto.crmid,
      referencia,
      vendorid: proveedor ? proveedor.crmid : '',
      treebestdgid: tipoGasto ? tipoGasto.crmid : '',
      currency_id: 1,
      conversion_rate: 1,
      treebesalmid: almacen ? almacen.crmid : '',
      total,
      description,
      fecha: date,
    };
    if (validarPago(editGasto)) {
      const response = await expensesApi.createExpense(editGasto, 'PUT');
      const { success, message } = response;
      if (success) {
        toast.success(message.success);
        if (pay) {
          dispatch(openModal('pay'));
        }
        history.push('/expenses');
      }
    }
    setLoading(false);
  };

  // eslint-disable-next-line consistent-return
  const getInputValue = (name) => {
    switch (name) {
      case 'referencia':
        return referencia;
      case 'total':
        return total;
      case 'description':
        return description;
      default:
        break;
    }
  };

  return (
    <>
      { loading && (
      <Collapse in={loading === true}>
        <LinearProgress />
      </Collapse>
      )}
      <Collapse in={schema !== null}>
        <div className="new-expense-form">
          <div className="new-expense-form__action-form">
            <form onSubmit={e => handleSubmit(e)}>
              {
              schema.map(item => (
                <>
                  <div className="new-expense-form__action-form-custom-form-group">
                    {/* Vendedor */}
                    {item.name === 'vendorid' && (
                      <div className="new-expense-form__action-form-custom-form-group">
                        <FontAwesomeIcon
                          style={{
                            fontSize: 15, position: 'absolute', top: '55%', zIndex: 9, left: '95%',
                          }}
                          icon={faChevronRight}
                        />
                        <label htmlFor="" className="custom-label" />
                        <input
                          readOnly
                          type={item.type}
                          name={item.name}
                          onChange={() => {}}
                          placeholder="Proveedor"
                          required={item.required}
                          className="custom-input slim-input"
                          value={proveedor ? proveedor.vendorname : ''}
                          onClick={() => openSearchDialog('proveedor')}
                        />
                      </div>
                    )}
                    {/* Almacen */}
                    {item.name === 'treebesalmid' && (
                      <div className="new-expense-form__action-form-custom-form-group">
                        <FontAwesomeIcon
                          style={{
                            fontSize: 15, position: 'absolute', top: '55%', zIndex: 9, left: '95%',
                          }}
                          icon={faChevronRight}
                        />
                        <label htmlFor="" className="custom-label" />
                        <input
                          readOnly
                          type="text"
                          required={item.required}
                          className="custom-input slim-input"
                          placeholder="Almacen"
                          onChange={() => {}}
                          onClick={() => openSearchDialog('almacen')}
                          value={almacen ? almacen.entity_label : ''}
                        />
                      </div>
                    )}
                    {/* Tipo de gasto */}
                    {item.name === 'treebestdgid' && (
                      <div className="new-expense-form__action-form-custom-form-group">
                        <FontAwesomeIcon
                          style={{
                            fontSize: 15, position: 'absolute', top: '55%', zIndex: 9, left: '95%',
                          }}
                          icon={faChevronRight}
                        />
                        <label htmlFor="" className="custom-label" />
                        <input
                          readOnly
                          type="text"
                          onChange={() => {}}
                          placeholder="Tipo de gasto"
                          required={item.required}
                          value={tipoGasto ? tipoGasto.nombre : ''}
                          onClick={() => openSearchDialog('gasto')}
                          className="custom-input slim-input"
                        />
                      </div>
                    )}
                    {(item.name === 'fecha' || item.name === 'currency_id' || item.name === 'conversion_rate') && (
                    <input className="custom-input" name={item.name} type={item.type} value={item.value} hidden onChange={() => {}} />
                    )}
                    {(item.name === 'referencia' || item.name === 'description' || item.name === 'total') && (
                    <div className="new-expense-form__action-form-custom-form-group mt-2">
                      <label htmlFor="" className="custom-label">{item.label}</label>
                      <input
                        className="custom-input"
                        type={item.type}
                        value={
                          getInputValue(item.name)
                        }
                        name={item.name}
                        onChange={e => handleChange(e)}
                        required={item.required}
                      />
                    </div>
                    )}
                  </div>
                </>
              ))
            }
              <div className="new-expense-form__action-btn">
                <button className="btn-detail" type="submit" onClick={() => {}}>Editar</button>
              </div>
            </form>
          </div>
        </div>

      </Collapse>
      <SearchDialog open={open} handleClose={handleCloseSearch} type={type} />
    </>
  ); });

export default EditExpenseForm;
