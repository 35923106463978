/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Label,
  FormGroup,
  UncontrolledCollapse,
} from 'reactstrap';
import { useDispatch } from 'react-redux';
import {
  Slide,
  Button,
  Collapse,
  TextField,
  InputLabel,
  LinearProgress,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MaskedInput from 'react-text-mask';
import AddressList from './address/AddressList';
import CreateAddress from './CreateAddress';
import { toggleModal as closeModal } from '../../../redux/actions/modalActions';

const Transition = React.forwardRef((props, ref) => <Slide direction="left" ref={ref} {...props} />);

const EditCustomer = ({
  address,
  buttonGuardar,
  deleteAddress,
  direccionTitle,
  dispatchAddAddressByCustomer,
  dispatchEditAddressByCustomer,
  dispatchEditCustomer,
  dispatchEditEnvioFactura,
  dispatchGetAddressCustomer,
  fiscalTitle,
  generalTitle,
  loads,
  history,
  selectedCustomer,
  title,
}) => {
  /* Hooks */
  const dispatch = useDispatch();
  const [customer, setCustomer] = React.useState({
    nombre: '',
    apellidos: '',
    telefono: '',
    email: '',
    razonSocial: '',
    rfc: '',
    calle: '',
    numExt: '',
    numInt: '',
    colonia: '',
    pais: '',
    estado: '',
    delegacion: '',
    codigoPostal: '',
    treebesdireccionenvioid: '',
    treebesdireccionfacturaid: '',
  });
  const [addressList, setAddressList] = React.useState([]);
  const [addModal, setAddModal] = React.useState(false);
  const [select, setSelect] = React.useState({
    city: '',
    country: '',
    dir_references: '',
    direccion: '',
    latitude: '',
    longitude: '',
    neighborhood: '',
    num_ext: '',
    num_int: '',
    postcode: '',
    state: '',
    id: 0,
    point_card_number: '',
  });

  /* Init functions */
  const getAddress = (crmid) => {
    dispatchGetAddressCustomer(crmid);
  };

  const setAddress = (_address) => {
    const elements = _address.map(x => ({
      ...x,
      id: Math.random(),
      factura: Boolean(x.crmid === customer.treebesdireccionfacturaid),
      envio: Boolean(x.crmid === customer.treebesdireccionenvioid),
    }));
    setAddressList([...elements]);
  };

  React.useEffect(() => {
    // debugger;
    if (selectedCustomer) {
      // eslint-disable-next-line camelcase
      const { account_no, crmid, accountname } = selectedCustomer;
      const newCustomer = {
        crmid,
        account_no,
        accountname,
        nombre: selectedCustomer.firstname,
        apellidos: selectedCustomer.lastname,
        telefono: selectedCustomer.phone,
        email: selectedCustomer.email1,
        razonSocial: selectedCustomer.razon_social,
        rfc: selectedCustomer.siccode,
        treebesdireccionenvioid: selectedCustomer.treebesdireccionenvioid,
        treebesdireccionfacturaid: selectedCustomer.treebesdireccionfacturaid,
        point_card_number: selectedCustomer.point_card_number,
      };
      setCustomer(newCustomer);
    }
    // eslint-disable-next-line
  }, [selectedCustomer]);

  React.useEffect(() => {
    if (selectedCustomer) {
      getAddress(selectedCustomer.crmid);
    }
    // eslint-disable-next-line
  }, [selectedCustomer]);

  React.useEffect(() => {
    setAddress(address);
    // eslint-disable-next-line
  }, [address]);

  /* Functions */
  const toggleModal = () => {
    if (history) {
      if (history?.location.pathname.includes('editCustomer')) {
        history.goBack();
      } else {
        dispatch(closeModal('customerEdit'));
      }
    } else {
      dispatch(closeModal('customerEdit'));
    }
  };

  const toggleAddModal = () => {
    setAddModal(!addModal);
    setSelect({});
  };

  const addAddress = async (add) => {
    if (!addressList.some(x => x.id === add.id)) {
      const _add = { ...add };
      dispatchAddAddressByCustomer(customer.crmid, _add);
      setAddModal(false);
    } else {
      setAddModal(false);
      const element = addressList.find(x => x.id === add.id);
      if (element.crmid) {
        const elToFetch = { ...element, ...add };
        delete elToFetch.createdtime;
        delete elToFetch.id;
        delete elToFetch.modifiedtime;
        delete elToFetch.relatedtoid_label;
        await dispatchEditAddressByCustomer(customer.crmid, elToFetch);
        if (elToFetch.factura || elToFetch.envio) {
          const treebesdireccionfacturaid = addressList.find(x => x.factura === true);
          const treebesdireccionenvioid = addressList.find(x => x.envio === true);
          const setCustomerDefaults = {
            crmid: customer.crmid,
            accountname: customer.accountname,
            nombre: customer.nombre,
            apellidos: customer.apellidos,
            treebesdireccionfacturaid: treebesdireccionfacturaid.crmid,
            treebesdireccionenvioid: treebesdireccionenvioid.crmid,
          };
          await dispatchEditEnvioFactura(setCustomerDefaults);
        }
      } else {
        const __address = addressList.map((x) => {
          let el = { ...x };
          if (x.id === add.id) {
            el = { ...add };
          }
          return el;
        });
        setAddressList(__address);
        setAddModal(false);
      }
    }
  };

  const handleBooleanParam = (id, flag = '') => {
    if (addressList.some(x => x.id === id)) {
      const find = addressList.find(x => x.id === id);
      const idAddress = find !== undefined ? find.crmid : '';
      const setCustomerDefaults = {
        crmid: customer.crmid,
        accountname: customer.accountname,
        nombre: customer.nombre,
        apellidos: customer.apellidos,
        treebesdireccionfacturaid: flag === 'factura' ? idAddress : customer.treebesdireccionfacturaid,
        treebesdireccionenvioid: flag === 'envio' ? idAddress : customer.treebesdireccionenvioid,
      };
      dispatchEditEnvioFactura(setCustomerDefaults);
    }
  };

  const onSelect = (id) => {
    const element = addressList.find(x => x.id === id);
    if (element) {
      setSelect({ ...element });
      setAddModal(true);
    }
  };

  const onDeleteAddress = (id) => {
    const element = addressList.find(x => x.id === id);
    if (element) {
      deleteAddress(customer.crmid, element.crmid, element.direccion);
    }
  };

  const onChangeField = (e) => {
    const { value, name } = e.target;
    setCustomer({ ...customer, [name]: value });
  };

  const onKeyPress = (event) => {
    if (event.which === 13 /* Enter */) event.preventDefault();
  };

  const saveCustomer = async (event) => {
    event.preventDefault();
    const newCustomer = customer;
    await dispatchEditCustomer(newCustomer);
    toggleModal();
  };

  /* Constants */
  const phoneMask = ['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  return (
    <Dialog
      open
      fullScreen
      onClose={toggleModal}
      TransitionComponent={Transition}
    >
      <AppBar
        className="modalDetail_appBar"
        style={{ backgroundColor: '#23A1DB' }}
        position="static"
      >
        <Toolbar className="d-flex px-0" classes={{ root: 'toolbar' }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="close"
            style={{ width: '48px', height: '48px' }}
            onClick={toggleModal}
          >
            <FontAwesomeIcon color="#ffffff" icon="chevron-left" />
          </IconButton>
          <div
            style={{ color: '#ffffff' }}
            className="w-100 font-size-18 d-flex align-items-center"
          >
            {title}
          </div>
        </Toolbar>
      </AppBar>
      <div className="p-2 w-100 d-flex flex-column h-auto">
        <Collapse in={loads?.editCustomerIsLoading}>
          <LinearProgress />
        </Collapse>
        <Collapse in={!loads?.editCustomerIsLoading}>
          <Form onKeyPress={onKeyPress} onSubmit={saveCustomer}>
            <Label>{generalTitle}</Label>
            <FormGroup>
              <TextField
                id="acount"
                label="Nombre comercial"
                name="accountname"
                className="w-100"
                required
                value={customer.accountname}
                onChange={onChangeField}
              />
            </FormGroup>
            <FormGroup>
              <TextField
                id="name"
                label="Nombre"
                name="nombre"
                className="w-100"
                required
                value={customer.nombre}
                onChange={onChangeField}
              />
            </FormGroup>
            <FormGroup>
              <TextField
                id="last-name"
                label="Apellidos"
                name="apellidos"
                className="w-100"
                required
                value={customer.apellidos}
                onChange={onChangeField}
              />
            </FormGroup>
            <FormGroup>
              <InputLabel htmlFor="phone">Teléfono</InputLabel>
              <MaskedInput
                showMask
                mask={phoneMask}
                id="phone"
                name="telefono"
                value={customer.telefono}
                className="customer_number"
                onChange={onChangeField}
              />
            </FormGroup>
            <FormGroup>
              <TextField
                id="email"
                label="Correo"
                name="email"
                type="email"
                className="w-100"
                required
                value={customer.email}
                onChange={onChangeField}
              />
            </FormGroup>
            <FormGroup>
              <TextField
                id="cardNumber"
                label="Número de tarjeta de puntos"
                name="point_card_number"
                value={customer.point_card_number}
                className="w-100"
                onChange={onChangeField}
              />
            </FormGroup>
            <Button id="fiscal" className="w-100 mb-2">
              <span>
                <span>{fiscalTitle}</span>
                <FontAwesomeIcon icon="caret-down" />
              </span>
            </Button>
            <UncontrolledCollapse toggler="#fiscal">
              <FormGroup>
                <TextField
                  id="socialReason"
                  label="Razón Social"
                  name="razonSocial"
                  value={customer.razonSocial}
                  className="w-100"
                  onChange={onChangeField}
                />
              </FormGroup>
              <FormGroup>
                <TextField
                  id="rfc"
                  label="RFC"
                  name="rfc"
                  value={customer.rfc}
                  className="w-100"
                  pattern="([a-zA-Z&]{3,4})(\d{2})(0[1-9]|1[1-2])([0][1-9]|[1-2][0-9]|3[0-1])([a-zA-Z0-9]{3})"
                  onChange={onChangeField}
                />
              </FormGroup>
            </UncontrolledCollapse>
            <Button
              id="direccion"
              className="w-100 mb-2"
              onClick={() => toggleAddModal()}
            >
              <span>
                <span className="mr-2">{direccionTitle}</span>
                <FontAwesomeIcon icon="plus" />
              </span>
            </Button>
            <Collapse in={loads?.addressIsLoading}>
              <LinearProgress />
            </Collapse>
            <Collapse in={!loads?.addressIsLoading}>
              {addressList.length > 0 ? (
                <AddressList
                  arr={addressList}
                  onSelect={onSelect}
                  handleBooleanParam={handleBooleanParam}
                  deleteAddress={onDeleteAddress}
                />
              ) : (
                <div className="w-100 text-center">
                  <h5>Cuenta sin direcciones</h5>
                </div>
              )}
            </Collapse>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              className="w-100 my-2"
            >
              {buttonGuardar}
            </Button>
          </Form>
        </Collapse>
      </div>
      <CreateAddress
        open={addModal}
        toggle={toggleAddModal}
        onSave={addAddress}
        title={(Object.keys(select).length === 0 || select.id === 0)
          ? 'Crear Dirección'
          : `Editar Dirección: ${select.direccion}`
          }
        {...select}
      />
    </Dialog>
  );
};

EditCustomer.propTypes = {
  address: PropTypes.array,
  buttonGuardar: PropTypes.string,
  deleteAddress: PropTypes.func.isRequired,
  direccionTitle: PropTypes.string,
  dispatchAddAddressByCustomer: PropTypes.func.isRequired,
  dispatchEditAddressByCustomer: PropTypes.func.isRequired,
  dispatchEditCustomer: PropTypes.func.isRequired,
  dispatchEditEnvioFactura: PropTypes.func.isRequired,
  dispatchGetAddressCustomer: PropTypes.func.isRequired,
  fiscalTitle: PropTypes.string,
  generalTitle: PropTypes.string,
  loads: PropTypes.object.isRequired,
  selectedCustomer: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  title: PropTypes.string,
};

EditCustomer.defaultProps = {
  address: [],
  buttonGuardar: 'Guardar',
  direccionTitle: 'Añadir Dirección',
  fiscalTitle: 'Datos Fiscales',
  generalTitle: 'Datos Generales',
  title: 'Editar Cliente',
};

export default EditCustomer;
