/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable camelcase */
import fetch from 'cross-fetch';
import axios from 'axios';
import ConfigurationAPI from './ConfigurationAPI';

class UserAPI extends ConfigurationAPI {
  _formatToArray = (sostatus) => {
    const orders = [];
    for (const id in sostatus) {
      const value = sostatus[id];
      orders.push({ id, value });
    }
    return orders;
  }

  _formatConfig = ({ config, almacenes }) => {
    const newConfig = { ...config };
    const { pos_auto_alm, pos_sininv, sostatus,  } = newConfig;
    newConfig.pos_auto_alm = pos_auto_alm || '';
    newConfig.pos_sininv = pos_sininv === '1';
    newConfig.sostatus = sostatus || '';
    newConfig.store = almacenes.length === 1 ? almacenes[0] : '';
    return newConfig;
  }

  logIn = async (domain, token) => {
    this.credentials.headers.Authorization = `Basic ${token}`;
    delete this.credentials.headers.pricebook;
    try {
      const response = await fetch(`${this.protocol}://${domain}/${this.module}/login`, this.credentials);
      const json = await response.json();
      const { success, error, result } = json;

      if (result) {
        result.sostatus = this._formatToArray(result.sostatus);
        result.pos_auto_alm = this._formatToArray(result.pos_auto_alm);
        result.config = this._formatConfig(result);
      }

      return {
        success,
        message: success ? `Bienvenido ${result.first_name} ${result.last_name}` : error.message,
        user: result || {},
      };
    }

    catch {
      return { success: false, message: 'Error en el Host' };
    }
  }

  verify = async () => {
    try {
      const response = await fetch(`${this.url}/login`, this.credentials);
      const json = await response.json();
      const { success, error, result } = json;

      if (result) {
        result.sostatus = this._formatToArray(result.sostatus);
        result.pos_auto_alm = this._formatToArray(result.pos_auto_alm);
        result.config = this._formatConfig(result);
      }

      return {
        success,
        message: success ? '' : (error.message || 'Sesión Expirada'),
        user: result || {},
      };
    }

    catch {
      return { success: false, message: 'Error en el Host' };
    }
  }

  updateConfig = async (config) => {
    this.credentials.method = 'POST';
    this.credentials.body = JSON.stringify(config);

    try {
      const response = await fetch(`${this.url}/Users`, this.credentials);
      const json = await response.json();
      const { success, error, result } = json;

      return {
        success,
        message: success ? 'Cambios Guardados' : error.message,
        result,
      };
    }

    catch {
      return { success: false, message: 'Error en el Host' };
    }
  }

  // sendEmail = async (data) => { 
  //   const {
  //     crmid,
  //     type,
  //     templateid,
  //     moduleT,
  //   } = data;
  //   const { ...morData } = data;
  //   this.credentials.method = 'POST';
  //   this.credentials.body = JSON.stringify(morData);
  //   try {
  //     const response = await fetch(`${this.url}/${moduleT}/${type}/${templateid}/${crmid}`, this.credentials);
  //     const json = await response.json();
  //     return json;
  //   }

  //   catch {
  //     return { success: false, message: 'Error en el Host' };
  //   }
  // }

  sendEmail = data => (new Promise((resolve, reject) => {
    console.log('bandera');
    const config = {
      withCredentials: true,
      headers: this.credentials.headers,
    };
    const {
      crmid,
      type,
      templateid,
      moduleT,
    } = data;
    const { ...morData } = data;
    axios.post(`${this.url}/${moduleT}/${type}/${templateid}/${crmid}`, JSON.stringify(morData), config).then((result) => {
      resolve(result.data);
    }).catch(err => reject(err.response));
  }))


  getOrderDocument = async (data, download) => { 
    const {
      crmid,
      type,
      moduleT,
      orderNo,
      templateid,
      templatename,
    } = data;
    this.credentials.responseType = 'blob';
    try {
      const response = await fetch(`${this.url}/${moduleT}/${type}/${templateid}/${crmid}`, this.credentials);
      if (response.status === 200) {
        await response.blob().then((blob) => {
          const objectURL = URL.createObjectURL(blob);
          if (download) {
            const link = document.createElement('a');
            link.href = objectURL;
            link.setAttribute('download', `${templatename} - ${orderNo}.pdf`);
            document.body.appendChild(link);
            link.click();
          } else {
            window.open(objectURL);
          }
        }).catch(() => ({ success: false, message: 'Error en el Host' }));
      } else {
        const json = await response.json();
        return json;
      }
      const message = { success: `PDF - ${orderNo} Listo` };
      return { success: true, message };
    }
    catch {
      return { success: false, message: 'Error en el Host' };
    }
  }

  generateUrl = async (params) => {
    this.credentials.method = 'POST';
    this.credentials.body = JSON.stringify(params);

    try {
      const response = await fetch(`${this.url}/user/store/url`, this.credentials);
      const json = await response.json();
      const { success, error, result } = json;

      return {
        success,
        error,
        result,
      };
    }

    catch {
      return { success: false, message: 'Error en el Host' };
    }
  }

  getCustomFields = async (module, crmid) => {
    try {
      const response = await fetch(`${this.url}/${module}/${crmid}/custom-fields`, this.credentials);
      const json = await response.json();
      return json;
    } catch (err) {
      return { succes: false, result: [], message: `Error en la petición del producto: ${err}` };
    }
  };

  setCustomFields = async (params, module, crmid) => {
    this.credentials.method = 'PUT';
    this.credentials.body = JSON.stringify(params);

    try {
      const response = await fetch(`${this.url}/${module}/${crmid}/custom-fields`, this.credentials);
      const json = await response.json();
      return json;
    }

    catch {
      return { success: false, message: 'Error en el Host' };
    }
  }
}

export default UserAPI;
