import {
  GET_EXPENSE,
  GET_VENDOR,
  GET_VENDORS,
  GET_EXPENSES,
  GET_ALMACEN,
  GET_ALMACENES,
  GET_EXPENSE_TYPE,
  GET_EXPENSES_TYPE,
  GET_VENDORS_PAGINATOR,
  GET_EXPENSES_DOCUMENTS,
  GET_EXPENSES_PAGINATOR,
  GET_ALMACENES_PAGINATOR,
  GET_EXPENSES_TYPE_PAGINATOR,
  GET_INVOICES_BY_EXPENSE_PROVIDER,
  GET_EXPENSES_DOCUMENTS_PAGINATOR,
  GET_INVOICES_BY_EXPENSE_PROVIDER_PAGINATOR,
} from '../types/expensesActionsType';

const INITIAL_STATE = {
  listaGastos: { data: [], pages: 0 },
  gastoSeleccionado: null,
  facturasPorProveedor: { data: [], pages: 0 },

  proveedores: { data: [], pages: 0 },
  proveedor: {},

  listaTipoGastos: { data: [], pages: 0 },
  tipoGastoSeleccionado: {},

  listaAlmacen: { data: [], pages: 0 },
  almacenSeleccionado: {},

  documentos: { result: [], pages: 0, count: 0 },
};

const expensesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_EXPENSES:
      return {
        ...state,
        listaGastos: { data: [...action.payload.result], pages: action.payload.pages },
      };
    case GET_EXPENSES_PAGINATOR:
      return {
        ...state,
        listaGastos: { data: [...state.gastos.data, ...action.payload.result], pages: action.payload.pages },
      };
    case GET_EXPENSE:
      return {
        ...state,
        gastoSeleccionado: action.payload,
      };
    case GET_INVOICES_BY_EXPENSE_PROVIDER:
      return {
        ...state,
        facturasPorProveedor: { data: [...action.payload.result], pages: action.payload.pages },
      };
    case GET_INVOICES_BY_EXPENSE_PROVIDER_PAGINATOR:
      return {
        ...state,
        facturasPorProveedor: { data: [...state.facturasPorProveedor.data, ...action.payload.result], pages: action.payload.pages },
      };
    case GET_VENDOR:
      return {
        ...state,
        proveedor: action.payload,
      };
    case GET_VENDORS:
      return {
        ...state,
        proveedores: { data: [...action.payload.result], pages: action.payload.pages },
      };
    case GET_VENDORS_PAGINATOR:
      return {
        ...state,
        proveedores: { data: [...state.proveedores.data, ...action.payload.result], pages: action.payload.pages },
      };
    case GET_EXPENSE_TYPE:
      return {
        ...state,
        tipoGastoSeleccionado: action.payload,
      };
    case GET_EXPENSES_TYPE:
      return {
        ...state,
        listaTipoGastos: { data: [...action.payload.result], pages: action.payload.pages },
      };
    case GET_EXPENSES_TYPE_PAGINATOR:
      return {
        ...state,
        listaTipoGastos: { data: [...state.listaTipoGastos.data, ...action.payload.result], pages: action.payload.pages },
      };
    case GET_ALMACEN:
      return {
        ...state,
        almacenSeleccionado: action.payload,
      };
    case GET_ALMACENES:
      return {
        ...state,
        listaAlmacen: { data: [...action.payload.result], pages: action.payload.pages },
      };
    case GET_ALMACENES_PAGINATOR:
      return {
        ...state,
        listaAlmacen: { data: [...state.listaAlmacen.data, ...action.payload.result], pages: action.payload.pages },
      };
    case GET_EXPENSES_DOCUMENTS:
      return {
        ...state,
        documentos: { result: [...action.payload.result], pages: action.payload.pages, count: action.payload.count },
      };
    case GET_EXPENSES_DOCUMENTS_PAGINATOR:
      return {
        ...state,
        documentos: { result: [...state.documentos.data, ...action.payload.result], pages: action.payload.pages, count: action.payload.count },
      };

    default:
      return state;
  }
};

export default expensesReducer;
