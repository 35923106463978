import React from 'react';
import PropTypes from 'prop-types';
import {
  ButtonBase,
  List,
  ListItem,
  ListItemText,
  Popover,
  Zoom,
} from '@material-ui/core';
import { faSlidersH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import FilterItem from './FilterItem';

const FilterFab = ({
  authUser,
  stateOptions,
  fetchOrders,
  setFilter,
  loading,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'order-filter-popover' : undefined;

  const { user: { config } } = authUser;
  const { state } = config;

  const statusFilters = {
    obj: {
      data: stateOptions,
      select: state,
      filter: 'state',
    },
    primary: 'Estado: ',
    secondary: state.map(x => x.value).join(', '),
    type: 'check',
    setFilter,
  };
  const elements = [
    statusFilters,
  ];

  return (
    <span>
      <Zoom in>
        <ButtonBase
          aria-describedby={id}
          style={{ height: '48px', width: '48px' }}
          onClick={e => setAnchorEl(e.currentTarget)}
        >
          <FontAwesomeIcon style={{ fontSize: 26 }} icon={faSlidersH} />
        </ButtonBase>
      </Zoom>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
          fetchOrders();
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List component="nav" classes={{ root: 'filter-list' }}>
          <ListItem divider>
            <ListItemText primary="Filtrar" classes={{ primary: 'filter-title' }} />
          </ListItem>
        </List>
        {elements.map(x => (
          <FilterItem {...x} loading={loading} key={Math.random()} />
        ))}
      </Popover>
    </span>
  );
};

FilterFab.propTypes = {
  authUser: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
  fetchOrders: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  fdeOptions: PropTypes.array,
  mdaOptions: PropTypes.array,
  stateOptions: PropTypes.array,
};

FilterFab.defaultProps = {
  fdeOptions: [
    {
      label: 'Pagado/Cobrado',
      value: 'Pagado/Cobrado',
      column: 'estado_fde',
    },
    {
      label: 'Sin Pago/Cobro',
      value: 'Sin Pago/Cobro',
      column: 'estado_fde',
    },
    {
      label: 'Parcialmente Pagado/Cobrado',
      value: 'Parcialmente Pagado/Cobrado',
      column: 'estado_fde',
    },
  ],
  mdaOptions: [
    {
      label: 'Entregado',
      value: 'Entregado',
      column: 'estado_mda',
    },
    {
      label: 'Sin Entregar',
      value: 'Sin Entregar',
      column: 'estado_mda',
    },
    {
      label: 'Parcialmente Entregado',
      value: 'Parcialmente Entregado',
      column: 'estado_mda',
    },
  ],
  stateOptions: [
    {
      label: 'Creados',
      value: 'Creado',
      column: 'state',
    },
    {
      label: 'Apartados',
      value: 'Apartado',
      column: 'state',
    },
    {
      label: 'Autorizados',
      value: 'Autorizado',
      column: 'state',
    },
    {
      label: 'Cancelados',
      value: 'Cancelado',
      column: 'state',
    },
  ],
};

export default FilterFab;
