/* eslint-disable import/no-cycle */

export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const CLOSE_MODALS = 'CLOSE_MODALS';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';

export function openModal(scope) {
  return {
    type: OPEN_MODAL,
    scope,
  };
}

export function closeModal(scope) {
  return {
    type: CLOSE_MODAL,
    scope,
  };
}

export function toggleModal(scope) {
  return {
    type: TOGGLE_MODAL,
    scope,
  };
}

export function closeModals() {
  return { type: CLOSE_MODALS };
}
