import React from 'react';
import PropTypes from 'prop-types';
/* Material */
import { IconButton, Collapse } from '@material-ui/core';
import { RemoveCircle } from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard } from '@fortawesome/fontawesome-free-regular';

/* Transition Group */
import TransitionGroup from 'react-transition-group/TransitionGroup';
import { Flip } from 'react-reveal';
import SpeiIcon from '../../../../Icons/SpeiIcon';

import { toolsHelper } from '../../../../../helpers';

const groupProps = {
  appear: true,
  enter: true,
  exit: true,
};

const AmountRegistry = ({
  title,
  payments,
  onSubmit,
  removePayment,
}) => {
  const returnIcon = (flag) => {
    switch (flag) {
      case ('Transferencia (SPEI)'):
        return (<SpeiIcon />);
      case ('Tarjeta de Credito'):
        return (<FontAwesomeIcon icon={faCreditCard} />);
      case ('Tarjeta de Debito'):
        return (<FontAwesomeIcon icon="credit-card" />);
      case ('Cheque'):
        return (<FontAwesomeIcon icon="money-check" />);
      default:
        return (<FontAwesomeIcon icon="money-bill-wave" />);
    }
  };
  return (
    <>
      <Collapse in={payments.length > 0}>
        <div className="w-100 checkoutGeneralTitle">{title}</div>
      </Collapse>
      <div className="w-100 h-auto amount_registry">
        <TransitionGroup {...groupProps}>
          {payments.map((payment, i) => (
            <Flip
              key={`key_${payment.metodo}_${payment.tcybid}_${payment.monto}`}
              collapse
              bottom
            >
              <div
                id={`key_${payment.metodo}_${payment.tcybid}_${payment.monto}`}
                role="button"
                className="w-100 amount_registry_item"
                data-tab={i}
                tabIndex={0}
                onKeyDown={(e) => {
                  const { keyCode } = e;
                  if (keyCode === 46) {
                    removePayment(i);
                    const metaInput = document.querySelector('#payable_dollar');
                    // @ts-ignore
                    if (metaInput) metaInput.focus();
                  } else if (keyCode === 13) {
                    onSubmit();
                  }
                }}
              >
                <div className="amount_registry_item_method">
                  {returnIcon(payment.metodo)}
                </div>
                <div className="amount_registry_item_cb">
                  <div
                    className="d-flex flex-column align-items-start justify-content-center paymentType"
                  >
                    <div className="font-size-14 font-weight-700">{payment.metodo}</div>
                    <div className="font-size-12">{payment.referencia}</div>
                  </div>
                </div>
                <div className="amount_registry_item_money">
                  {`$ ${toolsHelper.formatMoney(payment.monto)}`}
                </div>
                <IconButton
                  className="remove_register_button"
                  onClick={() => removePayment(i)}
                  tabIndex={-1}
                >
                  <RemoveCircle />
                </IconButton>
              </div>
            </Flip>
          ))}
        </TransitionGroup>
      </div>
    </>
  );
};

AmountRegistry.propTypes = {
  title: PropTypes.string,
  charge: PropTypes.number,
  onSubmit: PropTypes.func.isRequired,
  payments: PropTypes.array.isRequired,
  removePayment: PropTypes.func.isRequired,
};

AmountRegistry.defaultProps = {
  charge: 0,
  title: 'Registro',
};

export default AmountRegistry;
