/* eslint-disable import/no-cycle */
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ModalDetail from './ModalDetail';

import { clearCart } from '../../../../../redux/actions/cartActions';

const mapStateToProps = (state) => {
  const { cart } = state;
  const { orderSelected } = cart;
  const title = (Object.keys(orderSelected).length > 0 ? (orderSelected.order.entity_label) : 'Venta');
  return ({
    title,
  });
};

const mapDispatchToProps = dispatch => ({
  clearCart: () => dispatch(clearCart()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModalDetail));
