import React, { useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ProductListItem from '../ProductListItem';
import ListLoading from '../../../../List/ListLoading';
import { setCombinedProductId } from '../../../../../redux/actions/orderActions';

const ProductList = ({
  product,
  location,
  history,
  loading,
  fetchAll,
  getNextPage,
  addCartProduct,
}) => {
  const dispatch = useDispatch();
  const isEditOrder = useSelector(state => state.order.isEditOrder);
  const combinedProductId = useSelector(state => state.order.combinedProductId);
  const { products, actualPage, totalPages } = product;
  const hasMore = Boolean(actualPage < totalPages);
  const [arr, setArr] = React.useState([]);

  const observer = useRef();
  const lastElement = useCallback((node) => {
    if (hasMore) {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) getNextPage(actualPage + 1);
      });
      if (node) observer.current.observe(node);
    }
    // eslint-disable-next-line
  }, [loading, hasMore]);

  const selectProduct = (id) => {
    if (isEditOrder && combinedProductId === null) {
      dispatch(setCombinedProductId(id));
    } else {
      history.push(`/pos/caja/${id}`);
    }
  };
  const selectSingle = (item) => {
    const { Products } = item;
    const element = {
      dataset: {
        crmid: Products[0].crmid,
        parentid: Products[0].parentId,
      },
    };
    addCartProduct(element);
  };

  useEffect(() => {
    if (!loading && products.length > 0) {
      setArr(products);
    }
    // eslint-disable-next-line
  }, [loading, products]);

  useEffect(() => {
    if (location.pathname === '/pos/caja' && products.length === 0) {
      fetchAll();
      window.scroll(0, 0);
    }
    // eslint-disable-next-line
  }, [location, products]);
  return (
    <div className="productMobile_list">
      <ListLoading isLoading={loading} />
      {arr.length > 0 && (
        <div className="w-100 h-auto" style={{ marginBottom: 80 }}>
          {arr.map((item, i) => {
            if (hasMore && arr.length === (i + 5)) {
              return (
                <ProductListItem
                  key={`key_product_mobile_${item.crmid}`}
                  item={item}
                  selectProduct={() => {
                    if (item.Products.length > 1) {
                      selectProduct(item.crmid);
                    } else selectSingle(item);
                  }}
                  ref={lastElement}
                />
              );
            }
            return (
              <ProductListItem
                key={`key_product_mobile_${item.crmid}`}
                item={item}
                selectProduct={() => {
                  if (item.Products.length > 1) {
                    selectProduct(item.crmid);
                  } else selectSingle(item);
                }}
              />
            );
          })}
        </div>
      )}
      {!loading && arr.length === 0 && (
        <div className="w-100 mt-4 h-100 d-flex align-items-center justify-content-center flex-column">
          <FontAwesomeIcon color="#b1b1b1" size="3x" icon="shopping-cart" />
          <span
            className="font-size-12 align-items-center"
            style={{ color: '#b1b1b1' }}
          >
            Sin productos
          </span>
        </div>
      )}
      <ListLoading isLoading={loading} />
    </div>
  );
};


ProductList.propTypes = {
  product: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  loading: PropTypes.bool,
  fetchAll: PropTypes.func,
  getNextPage: PropTypes.func,
  addCartProduct: PropTypes.func,
};

ProductList.defaultProps = {
  product: {},
  location: {},
  history: {},
  loading: false,
  fetchAll: () => {},
  getNextPage: () => {},
  addCartProduct: () => {},
};

export default withRouter(ProductList);
