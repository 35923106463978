import {
  Button,
  Collapse, LinearProgress, Paper, Tooltip,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import InputNumber from '../../../Input/Number';
import useSound from '../../../../hooks/sound/useSound';

const DeliverOrderProducts = ({
  stock, products, changeInput, rest, add, loading, selectAll, selectNone,
}) => {
  const { playSound } = useSound();
  const getPartidas = () => {
    let count = 0;
    products.map((item) => {
      if (item.por_entregar !== 0) {
        count += 1;
      }
    });

    return count;
  };

  const getCantidad = () => {
    let count = 0;
    count = products.reduce((acc, item) => acc + item.por_entregar, 0);

    return count;
  };

  const handleReadBarCode = (e) => {
    const barCode = localStorage.getItem('barCode') || '';
    if (e.keyCode === 13 && barCode && products.length) {
      const findIndex = products.findIndex(prod => prod.codigo_barras === barCode);
      if (findIndex !== -1) {
        // Product
        const item = products[findIndex];
        if (parseFloat(item['por entregar']) >= item.por_entregar + 1) {
          add(item.id);
          playSound('success');
          toast.success('Producto seleccionado');
          localStorage.setItem('barCode', '');
        } else {
          playSound('error');
          toast.warn('Cantidad máxima agregada');
          localStorage.setItem('barCode', '');
        }
      } else {
        playSound('invalid');
        toast.error('No existe el codigo');
        localStorage.setItem('barCode', '');
      }
    } else {
      localStorage.setItem('barCode', `${barCode}${e.key}`);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleReadBarCode, false);
    localStorage.setItem('barCode', '');
    return () => {
      document.removeEventListener('keydown', handleReadBarCode, false);
      localStorage.setItem('barCode', '');
    };
  }, [products]);


  return (
    <div className="deliver__products">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <h4>Productos</h4>
        <div className="d-flex">
          <div className="d-flex justify-content-center align-items-center mr-2">
            <span className="mr-2">
              Partidas:
              {' '}
              {getPartidas()}
            </span>
            <span>
              Cantidad:
              {' '}
              {getCantidad()}
            </span>
          </div>
          <Button disabled={loading} className="mr-1" variant="outlined" onClick={selectAll}>
            Entregar todo
          </Button>
          <Button disabled={loading} variant="outlined" onClick={selectNone}>
            Entregar nada
          </Button>
        </div>
      </div>
      <Collapse in={loading}>
        <LinearProgress />
      </Collapse>
      <Paper className="w-100 product-list">
        {
        products.length > 0 && products.map((item, index) => (
          <div className={`${item.clase} refund_product`} key={item.id} style={{ border: stock.length && stock[index]?.inventario < item.por_entregar ? '2px solid red' : 'white' }}>
            <div className="refund_product_stock">
              {stock.length ? stock[index].inventario : 0}
              <span>Stock</span>
            </div>
            <div className="refund_product_detail">
              <div className="refund_product_title">
                <Tooltip placement="left-start" title={item.producto || ''}>
                  <span>{item.producto}</span>
                </Tooltip>
              </div>
              <div className="refund_product_desc">
                {`Por Entregar: ${parseFloat(item['por entregar'])}`}
              </div>
              <div className="refund_product_desc">
                {`codigo: ${item.codigo}`}
              </div>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center mb-2">
              <div>
                <span>Código de barra</span>
              </div>
              <input className="scanInput" type="text" readOnly value={item.codigo_barras} />
            </div>
            <div className="refund_product_qty">
              <div>
                <span style={{ whiteSpace: 'nowrap', marginRight: 15 }}>
                  {`Cantidad: ${parseFloat(item['por entregar'])}`}
                </span>
              </div>
              <InputNumber
                number={(stock.length && stock[index].inventario > 0) ? item.por_entregar : 0}
                disabledMinus={parseFloat(item['por entregar']) === 0 || item.por_entregar === 0 || (stock.length > 0 && stock[index].inventario <= 0)}
                disabledPlus={parseFloat(item['por entregar']) === item.por_entregar || parseFloat(item['por entregar']) === 0 || (stock.length > 0 && stock[index].inventario <= 0)}
                onClickMinus={() => rest(item.id)}
                onClickPlus={() => add(item.id)}
                circleDiameter="48px"
                onChange={(value) => {
                  changeInput(parseFloat(value), item.id);
                }}
                onWarning={item.field === 0}
                onDanger={item.por_entregar > item.cantidad}
              />
            </div>
          </div>
        ))
      }
      </Paper>
    </div>
  );
};

DeliverOrderProducts.propTypes = {
  stock: PropTypes.array,
  products: PropTypes.array,
  loading: PropTypes.bool,
  changeInput: PropTypes.func,
  rest: PropTypes.func,
  add: PropTypes.func,
  selectAll: PropTypes.func,
  selectNone: PropTypes.func,
};

DeliverOrderProducts.defaultProps = {
  stock: [],
  products: [],
  loading: false,
  changeInput: () => {},
  rest: () => {},
  add: () => {},
  selectAll: () => {},
  selectNone: () => {},
};

export default DeliverOrderProducts;
