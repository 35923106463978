/* eslint-disable camelcase */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Serialize from 'form-serialize';
import {
  Form,
  FormGroup,
  UncontrolledCollapse,
} from 'reactstrap';

import {
  Slide,
  Input,
  InputLabel,
  Divider,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import MaskedInput from 'react-text-mask';
import loadsProps from '../../../propTypes/loadsProps';

import LoadComponent from '../../Load/LoadComponent';

/* Modal */
import CreateAddress from './CreateAddress';
import AddressList from './address/AddressList';

const Transition = React.forwardRef((props, ref) => <Slide direction="left" ref={ref} {...props} />);

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

class CreateCustomer extends PureComponent {
  static propTypes = {
    loads: loadsProps.isRequired,
    history: PropTypes.object.isRequired,
    customer: PropTypes.object.isRequired,
    createCustomer: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.calle = null;
    this.numeroExterior = null;
    this.colonia = null;
    this.delegacion = null;
    this.estado = null;
    this.pais = null;
    this.codigoPostal = null;
    this.state = {
      addModal: false,
      address: [],
      select: {
        city: '',
        country: '',
        dir_references: '',
        direccion: '',
        latitude: '',
        longitude: '',
        neighborhood: '',
        num_ext: '',
        num_int: '',
        postcode: '',
        state: '',
        id: 0,
        factura: false,
        envio: false,
      },
    };
  }

  setRef = (node) => {
    if (node !== null) {
      const { name, tagName } = node;
      if (tagName === 'INPUT' && name in this) {
        this[name] = node;
      }
      else if (tagName === 'INPUT' && name === 'numExt') {
        this.numeroExterior = node;
      }
    }
  }

  toggleModal = () => {
    const { history } = this.props;
    history.goBack();
  }

  toggleAddModal = () => {
    this.setState(prev => ({
      addModal: !prev.addModal,
      select: {},
    }));
  }

  addAddress = (add) => {
    const { address } = this.state;
    if (!address.some(x => x.id === add.id)) {
      const _add = { ...add };
      _add.id = Math.random();
      _add.factura = Boolean(address.length === 0);
      _add.envio = Boolean(address.length === 0);
      this.setState(prev => ({
        address: [...prev.address, _add],
        addModal: false,
      }));
    } else {
      const _address = address.map((x) => {
        let el = { ...x };
        if (x.id === add.id) {
          el = { ...add };
        }
        return el;
      });
      this.setState({
        address: _address,
        addModal: false,
      });
    }
  }

  handleBooleanParam = (id, tag) => {
    const { address } = this.state;
    if (address.some(x => x.id === id)) {
      const _address = address.map(x => ({
        ...x,
        [tag]: (Boolean(x.id === id)),
      }));
      this.setState({ address: _address });
    }
  }

  onSelect = (id) => {
    const { address } = this.state;
    const element = address.find(x => x.id === id);
    if (element) {
      this.setState(prev => ({
        select: {
          ...prev.select,
          ...element,
        },
        addModal: true,
      }));
    }
  }

  onDeleteAddress = (id) => {
    const { address } = this.state;
    if (id !== '') {
      let _address = address.filter(x => x.id !== id);
      if (_address.length === 1) {
        _address = _address.map(x => ({ ...x, factura: true, envio: true }));
      }
      this.setState({
        address: _address,
      });
    }
  }

  sendCustomer = (event) => {
    event.preventDefault();
    const { createCustomer } = this.props;
    const customer = Serialize(event.target, { hash: true });
    const { address } = this.state;
    createCustomer(customer, address);
  }

  onKeyPress = (event) => {
    if (event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  }

  render() {
    const { loads } = this.props;
    const { addModal, address, select } = this.state;
    return (
      <Dialog
        open
        fullScreen
        onClose={this.toggleModal}
        TransitionComponent={Transition}
      >
        <AppBar
          className="modalDetail_appBar"
          style={{ backgroundColor: '#23A1DB' }}
          position="static"
        >
          <Toolbar className="d-flex px-0" classes={{ root: 'toolbar' }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="close"
              style={{ width: '48px', height: '48px' }}
              onClick={this.toggleModal}
            >
              <FontAwesomeIcon color="#ffffff" icon="chevron-left" />
            </IconButton>
            <div
              style={{ color: '#ffffff' }}
              className="w-100 font-size-24 d-flex align-items-center"
            >
              Crear Cliente
            </div>
          </Toolbar>
        </AppBar>
        <div className="p-2 w-100 d-flex flex-column h-auto">
          <Form onKeyPress={this.onKeyPress} onSubmit={this.sendCustomer}>
            <FormGroup>
              <TextField
                id="name"
                label="Nombre"
                name="nombre"
                className="w-100"
                required
              />
            </FormGroup>
            <FormGroup>
              <TextField
                id="last-name"
                label="Apellidos"
                name="apellidos"
                className="w-100"
                required
              />
            </FormGroup>
            <FormGroup>
              <InputLabel htmlFor="phone">Teléfono</InputLabel>
              <Input
                id="phone"
                label="Telefono"
                name="telefono"
                className="w-100"
                required
                inputComponent={TextMaskCustom}
              />
            </FormGroup>
            <FormGroup>
              <TextField
                id="email"
                label="Correo"
                name="email"
                type="email"
                className="w-100"
                required
              />
            </FormGroup>
            <FormGroup>
              <TextField
                id="cardNumber"
                label="Número de tarjeta de puntos"
                name="point_card_number"
                className="w-100"
                required={false}
              />
            </FormGroup>
            <Button id="fiscal" className="w-100">
              <span>
                <span>Datos Fiscales </span>
                <FontAwesomeIcon icon="caret-down" />
              </span>
            </Button>
            <UncontrolledCollapse toggler="#fiscal">
              <FormGroup>
                <TextField
                  id="socialReason"
                  label="Razón Social"
                  name="razonSocial"
                  className="w-100"
                />
              </FormGroup>
              <FormGroup>
                <TextField
                  id="rfc"
                  label="RFC"
                  name="rfc"
                  className="w-100"
                  pattern="([a-zA-Z&]{3,4})(\d{2})(0[1-9]|1[1-2])([0][1-9]|[1-2][0-9]|3[0-1])([a-zA-Z0-9]{3})"
                />
              </FormGroup>
            </UncontrolledCollapse>
            <Button
              id="toggler"
              className="w-100"
              onClick={() => this.toggleAddModal()}
            >
              <span>
                <span className="mr-2">Añade una Dirección</span>
                <FontAwesomeIcon icon="plus" />
              </span>

            </Button>
            {address.length > 0 && (
            <div className="w-100 text-dark font-size-10">
              {`Num. direcciones: ${address.length}`}
            </div>
            )}
            <Divider />
            {address.length > 0 && (
            <AddressList
              arr={address}
              onSelect={this.onSelect}
              handleBooleanParam={this.handleBooleanParam}
              deleteAddress={this.onDeleteAddress}
            />
            )}
            <Button
              type="submit"
              color="primary"
              variant="contained"
              className="w-100 my-2"
            >
              Crear
            </Button>
          </Form>
        </div>
        {
            loads.createCustomerIsLoading
              ? <LoadComponent />
              : ''
          }
        <CreateAddress
          open={addModal}
          toggle={this.toggleAddModal}
          onSave={this.addAddress}
          title={(Object.keys(select).length === 0 || select.id === 0)
            ? 'Crear Dirección'
            : `Editar Dirección: ${select.direccion}`
          }
          {...select}
        />
      </Dialog>
    );
  }
}

export default CreateCustomer;
