import React from 'react';
import PropTypes from 'prop-types';
import './Fde.scss';

import FdeModal from '../modal';
import FdeList from '../components/List';

const Fde = ({
  dispatchCloseDiscountModal,
  dispatchFetchFde,
  fde,
  loading,
  modals,
  orderSelected,
  type,
}) => (
  <div>
    {type === 'modal' && (
    <FdeModal
      fde={fde}
      fetchFde={dispatchFetchFde}
      isOpen={Boolean(modals.fdeModalIsOpen)}
      loading={loading}
      onClose={dispatchCloseDiscountModal}
      orderSelected={orderSelected}
    />
    )}
    {type === 'list' && (
    <FdeList
      fde={fde}
      fetchFde={dispatchFetchFde}
      isOpen
      isLoading={loading}
    />
    )}
  </div>
);

Fde.propTypes = {
  dispatchCloseDiscountModal: PropTypes.func.isRequired,
  dispatchFetchFde: PropTypes.func.isRequired,
  fde: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  modals: PropTypes.object.isRequired,
  orderSelected: PropTypes.object.isRequired,
  type: PropTypes.string,
};

Fde.defaultProps = {
  type: 'modal',
};

export default Fde;
