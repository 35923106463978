import React from 'react';
import PropTypes from 'prop-types';
import './IconButton.scss';
import {
  ButtonBase,
  CircularProgress,
  Tooltip,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const IconButtonInput = ({
  color,
  colorEnable,
  enable,
  fontSize,
  height,
  icon,
  iconSize,
  id,
  isLoading,
  label,
  onClick,
  padding,
  tooltip,
  width,
}) => (
  <Tooltip
    title={tooltip}
    placement="left"
  >
    <span>
      <ButtonBase
        id={id}
        disabled={!enable}
        onClick={onClick}
        className="inputIconButton"
        style={{ width, height, padding }}
      >
        {isLoading ? (
          <CircularProgress
            className={color === '#B6BEC6' ? 'flag_circular_gray' : 'flag_circular_white'}
            size={32}
          />
        ) : (
          <span>
            {typeof (icon) === 'string' ? (
              <FontAwesomeIcon
                style={{
                  color: enable ? colorEnable : color,
                  fontSize: iconSize,
                }}
                icon={icon}
              />
            ) : (icon)}
          </span>
        )}
        <span
          style={{
            fontSize,
            padding: '0 0.2em',
          }}
        >
          {label}
        </span>
      </ButtonBase>
    </span>
  </Tooltip>
);

IconButtonInput.propTypes = {
  color: PropTypes.string,
  colorEnable: PropTypes.string,
  enable: PropTypes.bool.isRequired,
  fontSize: PropTypes.string,
  height: PropTypes.string,
  icon: PropTypes.any,
  iconSize: PropTypes.string,
  id: PropTypes.string,
  isLoading: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  padding: PropTypes.string,
  tooltip: PropTypes.string,
  width: PropTypes.string,
};
IconButtonInput.defaultProps = {
  color: '#00000042',
  colorEnable: '#313C52',
  fontSize: '10px',
  height: '60px',
  icon: 'cash-register',
  iconSize: '13px',
  id: `icon_button_${Math.random()}`,
  isLoading: false,
  label: 'Label',
  padding: '0px',
  tooltip: 'Regresar a Público en General',
  width: '60px',
};

export default IconButtonInput;
