import React from 'react';
import PropTypes from 'prop-types';

import { Drawer } from '@material-ui/core';
import TopMenuItem from './components/TopMenuItem';

const TopMenuDrawer = ({
  opt,
  toggle,
  options,
}) => (
  <Drawer
    anchor="top"
    open={opt}
    onClose={toggle}
    onOpen={toggle}
    className="checkout_drawer"
  >
    <div className="w-100 d-flex p-3 flex-wrap align-items-center">
      {options.map(x => (<TopMenuItem {...x} key={Math.random()} />))}
    </div>
  </Drawer>
);

TopMenuDrawer.propTypes = {
  opt: PropTypes.bool,
  toggle: PropTypes.func,
  options: PropTypes.array,
};

TopMenuDrawer.defaultProps = {
  opt: false,
  toggle: () => {},
  options: [],
};

export default TopMenuDrawer;
