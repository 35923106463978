/* eslint-disable no-undef */
/* eslint-disable camelcase */
import React from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  faDolly,
  faFileInvoice,
  faHandHoldingUsd,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Collapse,
} from '@material-ui/core';
import Iconwidthbg from '../../../../components/Icons/IconWidthBG/IconWidthBG';
import {
  getColorStatusFDE,
  getColorStatusMDA,
  geteColorSosStatus,
} from '../../../../helpers/tools';
import ListItemGeneric from '../../../ClientPage/components/ListItemGeneric/ListItemGeneric';
import WSalesExpand from './WSalesExpand';
import { fetchSalesOrderSummary } from '../../../../redux/actions/financeActions';
import { fetchAllOrders } from '../../../../redux/actions/orderActions';

const WSales = () => {
  const dispatch = useDispatch();
  const [filteredOrders, setFilteredOrders] = React.useState([]);
  const salesOrdersSummary = useSelector(state => state.order.orders);
  const { pos_filtrostate } = useSelector(
    state => state.authUser.user.config,
  );
  const loads = useSelector(state => state.loads);

  const returnFilteredOrders = async () => {
    const filters = pos_filtrostate ? pos_filtrostate.split(' |##| ') : [];
    const ordersByFilter = salesOrdersSummary.filter(order => filters.includes(order.sostatus));
    setFilteredOrders([...ordersByFilter]);
  };

  React.useEffect(() => {
    if (!salesOrdersSummary.length) {
      dispatch(fetchAllOrders());
    }
  }, []);

  React.useEffect(() => {
    returnFilteredOrders();
  }, [pos_filtrostate, salesOrdersSummary]);

  return (
    <>
      {!loads.orderIsLoading && (
        <div>
          {filteredOrders.length ? (
            <div style={{ paddingBottom: 66 }}>
              {filteredOrders.map(sale => (
                <ListItemGeneric
                  key={`${sale.crmid}_key_${Math.random()}`}
                  titleLeft={sale.entity_label}
                  expandData={<WSalesExpand sale={sale} />}
                  subTitleLeft={moment(sale.createdtime).format(
                    'DD MMM, h:mm a',
                  )}
                  subTitleRight={(
                    <>
                      <Iconwidthbg
                        primaryColor={geteColorSosStatus(sale.sostatus)}
                        secondaryColor={`${
                          sale.sostatus === 'Created'
                          || sale.sostatus === 'Creado'
                            ? '#B6BEC6'
                            : 'white'
                        }`}
                        width={27}
                        height={27}
                        classes="ml-2"
                        enabled
                        square
                        rounded
                        actionClick={() => {}}
                      >
                        <FontAwesomeIcon
                          style={{ fontSize: 15 }}
                          icon={faFileInvoice}
                        />
                      </Iconwidthbg>

                      <Iconwidthbg
                        primaryColor={getColorStatusMDA(sale.estado_mda)}
                        secondaryColor={`${
                          sale.estado_mda === 'Sin Entregar'
                            ? '#B6BEC6'
                            : 'white'
                        }`}
                        width={27}
                        height={27}
                        classes="ml-2"
                        enabled
                        square
                        rounded
                        actionClick={() => {}}
                      >
                        <FontAwesomeIcon
                          style={{ fontSize: 15 }}
                          icon={faDolly}
                        />
                      </Iconwidthbg>

                      <Iconwidthbg
                        primaryColor={getColorStatusFDE(sale.estado_fde)}
                        secondaryColor={`${
                          sale.estado_fde === 'Sin Pago/Cobro'
                            ? '#B6BEC6'
                            : 'white'
                        }`}
                        width={27}
                        height={27}
                        classes="ml-2"
                        enabled
                        square
                        rounded
                        actionClick={() => {}}
                      >
                        <FontAwesomeIcon
                          style={{ fontSize: 15 }}
                          icon={faHandHoldingUsd}
                        />
                      </Iconwidthbg>
                    </>
                  )}
                />
              ))}
            </div>
          ) : (
            <List dense>
              <ListItem classes={{ root: 'bg-white' }}>
                <ListItemText
                  primary="No se encontraron ventas"
                />
              </ListItem>
            </List>
          )}
        </div>
      )}

      {loads.orderIsLoading && (
        <Collapse in={loads.orderIsLoading }>
          <LinearProgress />
        </Collapse>
      )}
    </>
  );
};

export default WSales;
