/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import './ModalDetail.scss';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import {
  Slide,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector, useDispatch } from 'react-redux';
import OrderDetail from '../OrderDetail';
import SatLogo from '../../../../Icons/SatLogo';
import TopMenuDrawer from '../../../../TopMenuDrawer';
import {
  addOrderToCart, setActivePromotions, desactivePromotions, resetPromotions, updatePromotions,
} from '../../../../../redux/actions/cartActions';
import { setDiscountTaxes } from '../../../../../redux/actions/filtersActions';
import ShareUrl from '../../../../ShareLinks/ShareUrl';
import { closeModal, openModal } from '../../../../../redux/actions/modalActions';
import { isEditMobileOrders, setCombinedProductId } from '../../../../../redux/actions/orderActions';
import { fetchAllOrderGuide } from '../../../../../redux/actions/guideActions';
import Address from '../../../../Address';
import CustomFields from '../../../../customFields';
import appModules from '../../../../../constant/appModules';
import ShareModal from '../../../../Share/components/ShareModal';
import MobileOrderPoducts from '../../../../mobile-product';
import Shipping from '../../../../Cart/components/controls/components/shipping/ShippingAction';
import { Book } from '@material-ui/icons';
import PriceBook from '../../../../Pricebook';
import PricebookListModal from '../../../../Pricebook/components/PricebookListModal';
import { changeSalesOrderStatus, saveSell } from '../../../../../redux/actions/sellActions';
import EditCustomer from '../../../../Customer/components/EditCustomer';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const ModalDetail = ({
  match,
  history,
  title,
  clearCart,
}) => {
  const [link, setLink] = React.useState(false);
  const [field, setField] = React.useState(false);
  const [openShare, setOpenShare] = React.useState(false);
  const [openProducts, setOpenProducts] = React.useState(false);
  const [openGuide, setOpenGuide] = React.useState(false);
  const [openPriceBook, setOpenPriceBook] = React.useState(false);
  React.useEffect(() => {
    window.onpopstate = (e) => {
      const { path } = e;
      if (path) {
        const route = path[0].location.pathname;
        if (route.includes('venta') || route.includes('bill')) clearCart();
      }
    };
  // eslint-disable-next-line
  }, []);
  const { orderSelected, activePromotions, products } = useSelector(state => state.cart);
  const { order } = orderSelected;
  const dispatch = useDispatch();
  const discountTaxes = useSelector(state => state.filters.discountTaxes);
  const authUser = useSelector(state => state.authUser);
  const loads = useSelector(state => state.loads);
  const modals = useSelector(state => state.modals);
  // eslint-disable-next-line camelcase
  const { user, user: { config: { pos_promo_cart } } } = authUser;
  const isLoading = Boolean(loads && loads.emailTemplateIsLoading);
  const isLoadingProducts = Boolean(loads && loads.productIsLoading);
  const isOrder = order && order.hasOwnProperty('salesorder_no');

  const { pos_allowed_sostatus } = user.config;
  const allowOrderStatus = pos_allowed_sostatus.split(' |##| ');
  const enableEdit = Boolean(
    order ? ((order.estado_mda === 'Sin Entregar' || order.estado_mda === 'N/A' || order.estado_mda === 'Sin Recibir')
        && allowOrderStatus.includes(order.sostatus)
        && (order.estadosat === 'Cancelado' || order.estadosat === 'Sin Factura' || order.estadosat == null)
        && (order.sostatus === 'Creado' || order.sostatus === 'Apartado')
        && order.estado_fde === 'Sin Pago/Cobro'
    ) : false,
  );

  const toggleTaxesDiscount = () => {
    dispatch(addOrderToCart(order));
  };

  const handleModal = (modal = '') => {
    dispatch(openModal(modal));
  };

  const handleShareOpen = () => {
    setOpenShare(true);
  };
  const handleShareClose = () => {
    setOpenShare(false);
    setOpenProducts(false);
    dispatch(isEditMobileOrders(false));
    dispatch(setCombinedProductId(null));
  };

  const addProductsOpen = () => {
    dispatch(isEditMobileOrders(true));
    setOpenProducts(true);
  };

  const [open, setOpen] = React.useState(false);

  const handleModalAction = () => {
    setOpen(!open);
  };

  const goToMap = () => {
    const url = `https://www.google.com/maps/search/?api=1&query=${order.ship_street || ''}, ${order.ship_pobox || ''}, ${order.ship_country || ''}, ${order.ship_state || ''}, ${order.ship_code || ''}`;
    window.open(url, '_blank');
  };

  const togglepromotions = async () => {
    if (activePromotions) {
      await dispatch(desactivePromotions());
      await dispatch(resetPromotions());
    } else {
      await dispatch(setActivePromotions());
    }
    await dispatch(updatePromotions());
  };

  React.useEffect(() => {
    // eslint-disable-next-line camelcase
    if (products.length === 0 && Object.entries(orderSelected).length === 0 && pos_promo_cart === '1') {
      dispatch(setActivePromotions());
    }
    if (!products.length) {
      dispatch(desactivePromotions());
    }

    if (Object.entries(orderSelected).length !== 0) {
      dispatch(fetchAllOrderGuide(orderSelected.order.crmid));
    }
    // eslint-disable-next-line
  }, [products, orderSelected]);

  const [opt, setOpt] = React.useState(false);
  const toggle = () => setOpt(!opt);

  // Clone order
  const dispatchMakeSell = () => {
    dispatch(changeSalesOrderStatus(order.sostatus));
    dispatch(saveSell(history));
    history.push('/pos/ventas');
  };

  const options = [
    {
      icon: 'share-alt',
      title: 'Compartir',
      onClick: () => {
        toggle();
        handleShareOpen();
      },
      disabled: Boolean(false),
    },
    {
      icon: <SatLogo width="28px" fill="#ffffff" />,
      title: 'Timbrar',
      onClick: () => {
        toggle();
        handleModalAction();
        const { params } = match;
        if (params.id) history.push(`/pos/bill/${params.id}`);
        else toast.info('Necesitamos una orden seleccionada para timbrar');
      },
      disabled: Boolean(false),
    },
    {
      icon: 'link',
      title: 'Enlace',
      onClick: () => {
        toggle();
        setLink(true);
      },
    },
    {
      icon: discountTaxes ? 'check-square' : 'square',
      title: 'Sin impuestos',
      onClick: () => {
        toggle();
        dispatch(setDiscountTaxes());
        toggleTaxesDiscount();
      },
      disabled: !orderSelected,
    },
    {
      icon: 'map-marker',
      title: 'Dir. de envio',
      onClick: () => {
        toggle();
        handleModal('addressCustomer');
      },
    },
    {
      icon: 'map-marker-alt',
      title: 'Ver en mapa',
      onClick: () => {
        toggle();
        goToMap();
      },
      disabled: !order,
    },
    {
      icon: 'pen-square',
      title: 'Campos personalizados',
      onClick: () => {
        toggle();
        setField(true);
      },
      disabled: !order,
    },
    {
      icon: 'certificate',
      title: activePromotions ? 'Promociones activas' : 'Promociones desactivadas',
      onClick: () => {
        toggle();
        togglepromotions();
      },
    },
    {
      icon: 'shopping-cart',
      title: 'Agregar producto',
      onClick: () => {
        toggle();
        addProductsOpen();
      },
      disabled: !order,
    },
    {
      icon: 'truck',
      title: 'Agregar guía',
      onClick: () => {
        toggle();
        setOpenGuide(true);
      },
      disabled: !order,
    },
    {
      icon: 'bookmark',
      title: 'Cambiar precios',
      onClick: () => {
        toggle();
        setOpenPriceBook(true);
      },
      disabled: !enableEdit,
    },
    {
      icon: 'copy',
      title: 'Clonar',
      onClick: () => {
        dispatchMakeSell();
        toggle();
      },
    },
  ];

  const handleClose = () => {
    setOpenGuide(false);
    setOpenPriceBook(false);
    dispatch(closeModal('shipping'));
  };

  return (
    <>
      <Dialog
        open
        fullScreen
        keepMounted
        TransitionComponent={Transition}
        onClose={() => history.goBack()}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <AppBar
          className="modalDetail_appBar"
          style={{ backgroundColor: '#ffffff' }}
          position="static"
        >
          <Toolbar className="d-flex px-0" classes={{ root: 'toolbar' }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={async () => {
                clearCart();
                history.push('/pos/venta');
              }}
              aria-label="close"
              style={{ width: '48px', height: '48px' }}
            >
              <FontAwesomeIcon color="#313C52" icon="chevron-left" />
            </IconButton>
            <span className="modalDetail_header">
              {title}
            </span>
            <IconButton
              edge="start"
              color="inherit"
              className="ml-auto"
              aria-label="close"
              style={{ width: '48px', height: '48px' }}
              onClick={
                () => toggle()
              }
            >
              <FontAwesomeIcon color="#313C52" icon="ellipsis-v" />
            </IconButton>
          </Toolbar>
        </AppBar>
        <OrderDetail />
        <TopMenuDrawer
          opt={opt}
          toggle={toggle}
          options={options}
        />
        <ShareUrl isOpen={link} close={() => setLink(!link)} />
        <Address />
        <CustomFields isOpen={field} close={() => setField(!field)} module={ isOrder ? appModules.SalesOrder: appModules.Quotes} />
        <ShareModal
          open={openShare}
          title={`Compartir ${order ? order.salesorder_no : ''}`}
          handleClose={handleShareClose}
          order={order || {}}
          template={appModules.SalesOrder}
          isLoading={isLoading}
        />
        <MobileOrderPoducts
          open={openProducts}
          title="Agregar productos"
          handleClose={handleShareClose}
          isLoading={isLoadingProducts}
        />

      </Dialog>
      <Dialog fullScreen open={openGuide || modals.shippingModalIsOpen} onClose={handleClose} TransitionComponent={Transition}>
        <Shipping closeModal={() => handleClose()} />
      </Dialog>
      // Add edit customer modal here
      <PricebookListModal open={openPriceBook} onClose={handleClose} />
    </>
  );
};

ModalDetail.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  title: PropTypes.string,
  clearCart: PropTypes.func,
};

ModalDetail.defaultProps = {
  history: {},
  match: {},
  title: '',
  clearCart: () => {},
};

export default withRouter(ModalDetail);
