import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileExcel,
  faFilePdf,
  faFileWord,
  faFileImage,
  faFile,
} from '@fortawesome/free-solid-svg-icons';

const DocumentsItems = ({ data }) => {

  const getIconByExtension = (extension) => {
    switch (extension) {
      case 'pdf':
        return (<FontAwesomeIcon style={{ fontSize: 23 }} color="#313C52" icon={faFilePdf} />);
      case 'doc':
        return (<FontAwesomeIcon style={{ fontSize: 23 }} color="#313C52" icon={faFileWord} />);
      case 'xlsx':
        return (<FontAwesomeIcon style={{ fontSize: 23 }} color="#313C52" icon={faFileExcel} />);
      default:
        return (<FontAwesomeIcon style={{ fontSize: 23 }} color="#313C52" icon={faFileImage} />);
    }
  };

  return (
    <div className="documents-container">
      {
            data.length
              ? (
                <List classes={{ root: 'py-0' }}>
                  {
                        data.map(item => (
                          <div key={item.crmid}>
                            <ListItem divider className="document-item">
                              <ListItemIcon>
                                {
                                getIconByExtension(item.extension)
                            }
                              </ListItemIcon>
                              <ListItemText
                                primary={item.filename}
                                classes={{
                                  primary: 'document-title',
                                  secondary: 'document-subtitle',
                                }}
                              />
                            </ListItem>
                          </div>
                        ))
                    }
                </List>
              )
              : (
                <div className="empty-email">
                  <FontAwesomeIcon
                    style={{
                      fontSize: 40, display: 'flex', justifyContent: 'center', alignItems: 'center',
                    }}
                    color="#64707A"
                    icon={faFile}
                  />
                  <span>Sin documentos </span>
                </div>
              )
        }
    </div>
  );
};

export default DocumentsItems;
