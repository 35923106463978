/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import './PosMobile.scss';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import SwipeableRoutes from 'react-swipeable-routes';

import ScrollTopButton from '../../../../components/ScrollToTopButton';
import PosMobileActionsBar from '../PosMobileActionsBar';
import BottomNavegation from './components/BottomNavegation';
import DeliverModal from './components/DeliverModal';
import Product from '../../../../components/Product/mobile';
import OrderMobile from '../../../../components/Order/mobile';
import CartMobile from '../../../../components/Cart/mobile';
import AddCustomer from '../../../../components/Cart/mobile/AddCustomer';
import EditCustomer from '../../../../components/Cart/mobile/EditCustomer';
import ModalDetail from '../../../../components/Order/mobile/components/ModalDetail/index';
import ModalOptions from '../../../../components/Product/mobile/components/Options';
import Timbre from '../../../../components/Timbre';
import QuotesMobile from '../../../Quotes';
import QuoteModal from '../../../Quotes/components/QuoteModalDetail/QuoteModal';

import ComingSoon from '../../../../components/ComingSoon/ComingSoon';

// eslint-disable-next-line no-unused-vars
const ComingSoonView = () => (
  <div style={{ height: 750 }}>
    <ComingSoon />
  </div>
);

const swipeableProps = {
  axis: 'x',
  replace: true,
  resistance: true,
};

const PosMobile = ({
  authUser,
  someLoading,
}) => (
  <div className="posMobile">
    <Router>
      <PosMobileActionsBar pathName={window.location.pathname} />
      <SwipeableRoutes {...swipeableProps}>
        <Route exact path="/pos/caja" component={Product} />
        <Route exact path="/pos/venta" component={OrderMobile} />
        <Route exact path="/pos/cotizacion" component={QuotesMobile} />
      </SwipeableRoutes>
      <Route path="/pos/caja/:id" component={ModalOptions} />
      <Route path="/pos/cart" component={CartMobile} />
      <Route path="/pos/addCustomer" component={AddCustomer} />
      <Route path="/pos/editCustomer" component={EditCustomer} />
      <Route path="/pos/venta/:id" component={ModalDetail} />
      <Route path="/pos/cotizacion/:id" component={QuoteModal} />
      <Route path="/pos/deliver/" component={DeliverModal} />
      <Route path="/pos/bill/:id" component={Timbre} />
      <ScrollTopButton loading={someLoading} />
      <BottomNavegation authUser={authUser} />
    </Router>
  </div>
);

PosMobile.propTypes = {
  history: PropTypes.object,
  authUser: PropTypes.object,
  someLoading: PropTypes.bool,
};

PosMobile.defaultProps = {
  history: {},
  authUser: {},
  someLoading: false,
};

export default PosMobile;
