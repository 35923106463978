import React from 'react';
import PropTypes from 'prop-types';
import {
  Tooltip,
  ButtonBase,
  CircularProgress,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Flag.scss';

const Flag = ({
  icon,
  title,
  disabled,
  onClick,
  color,
  isLoading,
  backgroundColor,
  width,
  height,
  size,
  ariaOwns,
}) => (
  <Tooltip title={title} interactive>
    <span>
      <ButtonBase
        onClick={onClick}
        disabled={disabled}
        className="flag"
        aria-haspopup="true"
        aria-owns={ariaOwns}
        style={{
          backgroundColor,
          width,
          height,
        }}
      >
        {isLoading ? (
          <CircularProgress
            className={color === '#B6BEC6' ? 'flag_circular_gray' : 'flag_circular_white'}
          />
        ) : (
          <FontAwesomeIcon style={{ color }} size={size} icon={icon} />
        )}
      </ButtonBase>
    </span>
  </Tooltip>
);

Flag.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  backgroundColor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  size: PropTypes.string,
  ariaOwns: PropTypes.string,
};

Flag.defaultProps = {
  icon: 'cash-register',
  title: '',
  disabled: false,
  isLoading: false,
  onClick: () => {},
  color: '#B6BEC6',
  backgroundColor: '#F6F6F7',
  width: '4vh',
  height: '4vh',
  size: '',
  ariaOwns: '',
};

export default Flag;
