import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { Card } from '@material-ui/core';
/* Components */
import ConfVentas from '../../ConfVentas';

const ConfColumnasVentas = ({
  loads,
  order,
  authUser,
  generalTitle,
  dispatchColumnDef,
}) => {
  const { user } = authUser;
  const { config } = user;
  const generalDisable = Boolean(config.pos_config_alm);
  const loading = Boolean(loads.profileIsLoading);
  const { columnsDefs } = order;
  return (
    <Card
      className={
        isMobile ? 'settings__cardMobile px-4 py-2' : 'settings__card px-4 py-2'
      }
    >
      <div className="settings__title">{generalTitle}</div>
      <ConfVentas
        loading={loading || generalDisable}
        columnDef={dispatchColumnDef}
        columnsDefs={columnsDefs || []}
      />
    </Card>
  );
};

ConfColumnasVentas.propTypes = {
  generalTitle: PropTypes.string,
  loads: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  authUser: PropTypes.object.isRequired,
  dispatchColumnDef: PropTypes.func.isRequired,
};

ConfColumnasVentas.defaultProps = {
  generalTitle: 'Tabla de Ventas',
};

export default ConfColumnasVentas;
