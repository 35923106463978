import React from 'react';

const CashIcon = props => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    width="100px"
    height="100px"
    viewBox="0 0 100 100"
    enableBackground="new 0 0 100 100"
    xmlSpace="preserve"
    {...props}
  >
    <path d="M90,30.017c0-2.209-1.791-4-4-4H14c-2.209,0-4,1.791-4,4v39.967c0,2.208,1.791,4,4,4h72c2.209,0,4-1.791,4-4V30.017z
    M14.915,67.712v-7.966c4.407,0,8.068,3.56,8.068,7.966H14.915z M14.915,39.746V31.78h8.068
    C22.983,36.187,19.322,39.746,14.915,39.746z M48.983,61.746c-5.524,0-10-5.374-10-12c0-6.628,4.477-12,10-12
    c5.522,0,10,5.373,10,12C58.983,56.375,54.504,61.746,48.983,61.746z M83.051,67.712h-8.067c0-4.407,3.661-7.966,8.067-7.966V67.712
    z M83.051,39.746c-4.407,0-8.067-3.559-8.067-7.966h8.067V39.746z"
    />
  </svg>
);

export default CashIcon;
