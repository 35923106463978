import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import List from '@material-ui/core/List';
import { formatMoney, getColorStatusQuote } from '../../../../../helpers/tools';
import Iconwidthbg from '../../../../../components/Icons/IconWidthBG/IconWidthBG';
import ListItemGeneric from '../../ListItemGeneric/ListItemGeneric';
import Quotes from '../../../../Quotes/components/Quotes';

const Cotizacion = ({ data }) => (
  <div>
    <Quotes data={data} />
  </div>
);
Cotizacion.prototype = {
  data: PropTypes.array.isRequired,
};
export default Cotizacion;
