import React from 'react';
import './QuoteSwipe.styles.scss';
import { useSelector } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Info from '@material-ui/icons/Info';
import InsertDriveFile from '@material-ui/icons/InsertDriveFile';
import DetailInfo from '../../../../components/Order/mobile/components/OrderDetail/components/DetailInfo';
import Files from '../../../../components/Files';
import DetailFooter from '../../../../components/Order/mobile/components/OrderDetail/components/DetailFooter/DetailFooter';

function QuoteSwipe() {
  const cart = useSelector(state => state.cart);
  const authUser = useSelector(state => state.authUser);
  const discount = useSelector(state => state.discount);
  const [value, setValue] = React.useState(0);
  const { user: { quotestage } } = authUser;

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  function handleChangeIndex(index) {
    setValue(index);
  }
  return (
    <div className="">
      <AppBar position="static" color="default">
        <Tabs
          classes={{ indicator: 'indicator-bg' }}
          style={{ backgroundColor: '#FFF' }}
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="inherit"
          variant="fullWidth"
        >
          <Tab icon={<Info />} />
          <Tab icon={<InsertDriveFile />} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis="x"
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <div className="quote-content">
          <div>
            <DetailInfo title="Cotización" cart={cart} sostatus={quotestage} />
          </div>
          <DetailFooter discount={discount} cart={cart} showDeliver={false} style={{ zIndex: 2 }} />
        </div>
        <div>
          <Files type="list" />
        </div>
      </SwipeableViews>
    </div>
  );
}

export default QuoteSwipe;
