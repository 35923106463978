/* eslint-disable camelcase */
import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Card from '@material-ui/core/Card';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import ProgresBar from '../ProgresBar/ProgresBar';
import { formatMoney } from '../../../../helpers/tools';

const ClientCredit = () => {
  const [open, setOpen] = React.useState(false);
  const selectClient = useSelector(state => state.client.selectClient);
  return (
    <div className="SummaryContainer_secction">
      <Card className="mt-2 w-100">
        <h3 className="m-2 d-flex justify-content-center SummaryContainer_title">Creditos</h3>
        <div className="m-3">
          <div className="d-flex justify-content-between align-item-center mb-2 text-bold">
            <h4>MXN</h4>
            <span>
              Limite:
              {` $${formatMoney(selectClient?.credito_m || 0)}`}
            </span>
          </div>
          <ProgresBar title="Shopify" total={selectClient?.credito_m || 0} pay={selectClient?.saldo_credito || 0} money />
          <p style={{ color: '#64707A', fontSize: 15, fontWeight: 500 }}>
            <span>
              Disponible:
              {' $'}
              {parseFloat(selectClient?.credito_m || 0) - Math.abs(parseFloat(selectClient?.saldo_credito || 0))}
            </span>
          </p>
          <p style={{ color: '#313C52', fontSize: 15, fontWeight: 500 }}>
            <span>
              Fecha limite de pago:
              {' '}
              {selectClient?.fecha_deuda ? moment(selectClient?.fecha_deuda).add(selectClient?.credito_d || 0, 'days').format('DD MMM YYYY') : moment().add(selectClient?.credito_d || 0, 'days').format('DD MMM YYYY')}
            </span>
          </p>
        </div>
        <div className="mb-2 mx-3" />
        <Collapse in={open} timeout="auto" mountOnEnter unmountOnExit>
          <div className="m-3">
            <p style={{ color: '#313C52', fontSize: 15, fontWeight: 500 }}>
              <span>
                Dias de credito:
                {' '}
                {selectClient?.credito_d || 0}
              </span>
            </p>
            <p style={{ color: '#313C52', fontSize: 15, fontWeight: 500 }}>
              <span>
                Deuda mas antigua:
                {' '}
                {selectClient?.fecha_deuda ? moment(selectClient?.fecha_deuda).format('DD MMM YYYY') : moment().format('DD MMM YYYY')}
              </span>
            </p>
          </div>
        </Collapse>
        <div>
          <Button className="w-100" onClick={() => setOpen(!open)}>
            {
                !open ? (<ArrowDropDown />) : (<ArrowDropUp />)
            }

          </Button>
        </div>
      </Card>
    </div>
  ); };

export default ClientCredit;
