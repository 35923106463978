/* eslint-disable camelcase */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import React from 'react';
import PropTypes from 'prop-types';
import { Fade } from 'react-reveal';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Table,
  TableBody,
} from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatMoney } from '../../../../../../../helpers/tools';
import {
  getTotalPrice,
  canDecreaseProductInCart,
} from '../../../../../../../helpers/cart';

import Promotions from '../../../../../../Promotions';
import ListProduct from '../../../../../../List/ListProduct';
import InputNumber from '../../../../../../Input/Number';
import InputPercentage from '../../../../../../Input/Percentage';
import InputText from '../../../../../../Input/Text';
import GenericButton from '../../../../../../Input/GenericButton';
import { updateProductPriceInCart } from '../../../../../../../redux/actions/cartActions';

const noImage = `${process.env.PUBLIC_URL}/img/no_image.jpg`;

const DetailDiscount = ({ item }) => (
  <span className="detailInfo_discount">
    <span>{`$${formatMoney(item.unit_price)}`}</span>
    <span>{`-${item.discount_percent}%`}</span>
  </span>
);

DetailDiscount.propTypes = {
  item: PropTypes.object,
};

DetailDiscount.defaultProps = {
  item: {},
};

const DetailProducts = ({
  cart,
  domain,
  inputChangeQty,
  onChangeQty,
  removeProduct,
  setRowDiscount,
  setComment,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const authUser = useSelector(state => state.authUser);
  const { totalProducts, orderSelected, cartProductsById } = cart;
  const { order } = orderSelected;
  const { pos_allowed_sostatus } = authUser.user.config;
  const { user: { shipping_id } } = authUser;
  const allowOrderStatus = pos_allowed_sostatus.split(' |##| ');

  if (totalProducts > 0) {
    const orderMode = Boolean(Object.keys(cartProductsById).length > 0);
    const { products } = cart;
    const { config } = authUser.user;
    const bgInput = config.pos_can_change_price !== '1' ? 'eaeaea' : '#fff';
    const renderFooter = (_item) => {
      const items = [''];
      if (_item.comment) {
        items.push(
          <span key={`key_commentP_${_item.productid}`} className="detailInfo_comment">
            <FontAwesomeIcon icon="comment-alt" />
          </span>,
        );
      }
      if (_item.discount_percent > 0) { items.push(<DetailDiscount
        key={`key_discountP_${_item.productid}`}
        item={_item}
      />); }
      return items;
    };

    const inputChangePrice = (row, price) => {
      dispatch(updateProductPriceInCart(row, price));
    };

    const enableEdit = Boolean(
      order ? ((order.estado_mda === 'Sin Entregar' || order.estado_mda === 'N/A' || order.estado_mda === 'Sin Recibir')
          && allowOrderStatus.includes(order.sostatus)
          && (order.estadosat === 'Cancelado' || order.estadosat === 'Sin Factura')
          && order.sostatus === 'Creado'
          && order.estado_fde === 'Sin Pago/Cobro'
      ) : false,
    );

    const renderCollapse = _item => (
      <div className="detailInfo_collapse">
        <div className="detailInfo_qtys">
          <InputNumber
            number={_item.quantity}
            onClickPlus={() => inputChangeQty(_item.row, true)}
            disabledPlus={!orderMode}
            onClickMinus={() => inputChangeQty(_item.row)}
            onDanger={Boolean(_item.inStock && !_item.inStock.status)}
            disabledMinus={!orderMode || !canDecreaseProductInCart(_item)}
            onChange={val => onChangeQty(_item.row, val)}
            disabledInput={!orderMode}
            anim={false}
          />
          <InputPercentage
            number={_item.discount_percent > 0 ? _item.discount_percent : 0}
            onBlur={val => setRowDiscount(_item.row, val)}
            disabledInput={!orderMode}
          />
        </div>
        {
          config.pos_can_change_price === '1' && (
            <div className="change-price d-flex justify-content-between align-items-center">
              <p style={{ width: '40vw', fontSize: 16 }}>Modificar precio</p>
              <input
                type="number"
                disabled={config.pos_can_change_price !== '1'}
                onChange={
                  event => inputChangePrice(_item.row, event.target.value)
                }
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                style={{
                  padding: '0.8em',
                  borderRadius: '20em',
                  backgroundColor: bgInput,
                  width: '40vw',
                  height: '100%',
                  fontSize: 16,
                  border: 0,
                  textAlign: 'center',
                }}
              />
            </div>
          )
         }
        <div className="message-container">
          <textarea
            cols="5"
            rows="5"
            placeholder="Agregar comentario"
            className="w-100 message-container__input"
            defaultValue={_item.comment !== '' ? _item.comment : ''}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onKeyPress={(e) => {
              const { target, key } = e;
              const { row } = _item;
              if (key === 'Enter') setComment({ row, comment: target.value });
            }}
            onKeyUp={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onBlur={(e) => {
              const { target } = e;
              const { row } = _item;
              setComment({ row, comment: target.value });
            }}
            disabled={Boolean(!orderMode)}
          />
        </div>
        <div>
          <GenericButton
            label={<FontAwesomeIcon icon="trash-alt" size="lg" />}
            backgroundColor="#F05B70"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              removeProduct(_item.row);
            }}
            /* disabled={!orderMode} */
          />
        </div>
      </div>
    );

    const loadImage = (_item) => {
      if (!_item.image.includes('https')) return `https://${domain}/${_item.image}`;
      return _item.image;
    };

    const loadImageOfProduct = (product) => {
      let image = '';
      if (product.images && product.images.length) {
        // eslint-disable-next-line prefer-destructuring
        image = product.images[0];
      } else if (product.image) {
        image = loadImage(product);
      } else {
        image = noImage;
      }
      return image;
    };

    const isQuote = !!(order && order.hasOwnProperty('quote_no'));

    const canEdit = Boolean((config.pos_no_editar_so !== '1' && enableEdit) || (location.pathname.includes('pos/cart')));


    return (
      <div className="detailInfo_products">
        {
        products.map(item => (
          /* if (item.unit_price !== 0.01 && item.crmid !== shipping_id) { */
          <ListProduct
            key={`key_item_product_${item.crmid}`}
            isCollapse={isQuote ? true : canEdit}
            image={loadImageOfProduct(item)}
              /* image={item.image ? loadImage(item) : noImage} */
            primary={item.product_name || item.entity_label}
            secondary={item.tpo_id_label}
            label={`$${formatMoney(getTotalPrice(item))}`}
            badge={item.quantity}
            footer={renderFooter(item)}
            collapseChild={renderCollapse(item)}
            onDanger={Boolean(item.inStock && !item.inStock.status)}
          />
          /* } */
        ))}
        <Table className="cart_list_table">
          <TableBody>
            <Promotions />
          </TableBody>
        </Table>
      </div>
    );
  }
  return (
    <Fade delay={5500}>
      <ListItem>
        <ListItemIcon>
          <Warning />
        </ListItemIcon>
        <ListItemText
          primary="Sin productos"
          secondary="Parece no haber productos referentes a esta orden de venta/cotización"
        />
      </ListItem>
    </Fade>
  );
};

DetailProducts.propTypes = {
  cart: PropTypes.object,
  domain: PropTypes.string,
  inputChangeQty: PropTypes.func,
  onChangeQty: PropTypes.func,
  removeProduct: PropTypes.func,
  setRowDiscount: PropTypes.func,
  setComment: PropTypes.func,
};

DetailProducts.defaultProps = {
  cart: {},
  domain: '',
  inputChangeQty: () => {},
  onChangeQty: () => {},
  removeProduct: () => {},
  setRowDiscount: () => {},
  setComment: () => {},
};

export default DetailProducts;
