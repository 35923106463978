import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar, Tab, Tabs,
} from '@material-ui/core';
import Info from '@material-ui/icons/InfoOutlined';
import ProductList from '@material-ui/icons/List';
import InsertDriveFile from '@material-ui/icons/InsertDriveFileOutlined';
import { useDispatch, useSelector } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import { toast } from 'react-toastify';
import Files from '../../../Files';
import DetailFooter from '../../../Order/mobile/components/OrderDetail/components/DetailFooter';
import ShipmentProducts from './ShipmentProducts';
import ShipmentInfo from './ShipmentInfo';
import FooterMenu from './FooterMenu';
import FileList from '../../../Files/list/FileList';
import { downloadFile, getFilesByOrder } from '../../../../redux/actions/orderActions';
import OrderAPI from '../../../../api/OrderAPI';

const ShipmentSwipe = ({ order }) => {
  const dispatch = useDispatch();
  const orderApi = new OrderAPI();
  const [products, setProducts] = useState([]);
  const [files, setFiles] = useState({ result: [] });
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  function handleChangeIndex(index) {
    setValue(index);
  }


  const rest = (prodId) => {
    const prods = products;
    const prodIndex = prods.findIndex(p => p.crmid === prodId);
    prods[prodIndex].cantidad -= 1;
    setProducts([...prods]);
  };

  const add = (prodId) => {
    const prods = products;
    const prodIndex = prods.findIndex(p => p.crmid === prodId);
    prods[prodIndex].cantidad += 1;

    setProducts([...prods]);
  };

  const formatProd = () => {
    if (order && order.products.length) {
      const formatProducts = [];
      order.products.map((prod) => {
        formatProducts.push({
          ...prod,
          quantity: parseFloat(prod.quantity),
          cantidad: parseFloat(prod.quantity),
          precio_neto: parseFloat(prod.precio_neto),
        });
      });
      setProducts(formatProducts);
    }
  };


  const changeInput = (value, prodId) => {
    const prods = products;
    const prodIndex = prods.findIndex(p => p.crmid === prodId);

    if (
      parseFloat(value) > parseFloat(prods[prodIndex].quantity)
      || parseFloat(value < 1)
    ) {
      setProducts([...prods]);
      toast.warn(
        `El valor no puede ser mayor que ${parseFloat(
          prods[prodIndex].cantidad,
        )} o menor que 0`,
      );

      return;
    }

    prods[prodIndex].cantidad = value;

    setProducts([...prods]);
  };

  const getDeliverFiles = async (page = 1, search = '') => {
    setLoading(true);
    const response = await orderApi.getFiles(order.relatedtoid, page, search);
    const { success } = response;
    if (success) {
      setFiles(response);
    }
    setLoading(false);
  };

  const deleteDeliverFile = async (docId, label) => {
    setLoading(true);
    const response = await orderApi.deleteFile(docId);
    const { success } = response;
    if (success) {
      getDeliverFiles(1, '');
    }
    setLoading(false);
  };

  const uploadDeliverFile = async (fileList) => {
    setLoading(true);
    if (fileList.length && order.tse_tipo_pago === 'Credito' && !files.result.length) {
      // eslint-disable-next-line no-param-reassign
      fileList[0].para_cc = 1;
    }
    await orderApi.uploadFile(order.relatedtoid, fileList, true);
    getDeliverFiles(1, '');
    setLoading(false);
  };

  const downloadDeliverFile = async (url, filename) => {
    dispatch(downloadFile(url, filename));
  };

  useEffect(() => {
    formatProd();
  }, [order]);


  return (
    <div className="">
      <AppBar position="static" color="default">
        <Tabs
          classes={{ indicator: 'indicator-bg' }}
          style={{ backgroundColor: '#FFF' }}
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="inherit"
          variant="fullWidth"
        >
          <Tab icon={<ProductList />} />
          <Tab icon={<Info />} />
          <Tab icon={<InsertDriveFile />} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis="x"
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        {/* Productos */}
        <div className="quote-content">
          <ShipmentProducts order={order} products={products} rest={rest} add={add} changeInput={changeInput} />
        </div>
        {/* Information */}
        <div>
          <ShipmentInfo order={order} />
        </div>
        <div>
          {/* <Files type="list" /> */}
          <FileList
            download={downloadDeliverFile}
            files={files}
            getFiles={getDeliverFiles}
            isOpen
            loading={loading}
            remove={deleteDeliverFile}
            upload={uploadDeliverFile}
          />
        </div>
      </SwipeableViews>
      <FooterMenu products={products} order={order} style={{ zIndex: 2 }} files={files} addFile={() => setValue(2)} />
    </div>
  );
};

ShipmentSwipe.propTypes = {
  order: PropTypes.object.isRequired,
};


export default ShipmentSwipe;
