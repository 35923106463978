import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPhone} from "@fortawesome/free-solid-svg-icons";
import {ListItem} from "@material-ui/core";

const getIcon = (name) => {
  switch (name) {
      case 'call':
          return faPhone;
      default:
          return null;
  }
};
const CallItem = ({call}) => {
    return (
        <ListItem button>
            <div className="font-white text-center position-relative"
                 style={{backgroundColor: '#313A51', width: 40, height: 40}}>
                <div className="position-absolute align-center">
                    <FontAwesomeIcon icon={getIcon(call.icon)}/>
                </div>
            </div>
            <div className="ml-3">
                <div className="font-size-18 font-weight-500">{call.name}</div>
                <div className="font-size-12">{call.phone}</div>
            </div>
        </ListItem>
    )
};
export default CallItem;
