import React, { Component } from 'react';
import List from '@material-ui/core/List';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Divider } from '@material-ui/core';
import ListItemSimple2 from '../../../components/ListItemSimple2';
import HeaderTop from './components/HeaderTop';

const options = [
  { id: 1, name: 'Persona', icon: 'user' },
  { id: 2, name: 'Empresa', icon: 'building' },
  { id: 3, name: 'Cuenta', icon: 'car' }];

class Configuration extends Component {
  render() {
    return (
      <>
        <HeaderTop history={this.props.history} />
        <div className="">
          <List>
            {options.map(option => (
                  <>
                      <ListItemSimple2 key={option.id} option={option} />
                      <Divider />
                    </>
                ))}
          </List>
        </div>
      </>
    );
  }
}
export default withRouter(connect()(Configuration));
