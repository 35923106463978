import errorSound from './audio/error.mp3';
import beepSound from './audio/beep.mp3';
import invalidSound from './audio/invalid.mp3';

export default function useSound() {
  let audio = new Audio(beepSound);
  const playSound = (soundType) => {
    switch (soundType) {
      case 'success':
        audio.play();
        break;
      case 'error':
        audio = new Audio(errorSound);
        audio.play();
        break;
      case 'invalid':
        audio = new Audio(invalidSound);
        audio.play();
        break;

      default:
        break;
    }
  };

  return {
    playSound,
  };
}
