/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
/* eslint-disable object-curly-newline */

import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { Redirect } from 'react-router-dom';
import Serialize from 'form-serialize';

import { connect } from 'react-redux';
import {
  selectBC,
  selectStore,
  authenticate,
} from '../../../redux/actions/authUserActions';

import authUserProps from '../../../propTypes/authUserProps';
import loadsProps from '../../../propTypes/loadsProps';

import LogInForm from '../../../components/LogIn/LogInForm';
import StoreForm from '../../../components/LogIn/StoreForm';
import LoadComponent from '../../../components/Load/LoadComponent';

import { userHelper } from '../../../helpers';
import { toast } from 'react-toastify';

const LogIn = ({
  loads,
  authUser,
  dispatchSelectBC,
  dispatchSelectStore,
  dispatchAuthenticate,
}) => {
  const { user: { metodos_pagos } } = authUser;
  let flagMethods = [];
  if (authUser.isAuth) {
    flagMethods = userHelper.existUnselectMethod(metodos_pagos);
  }
  return (
    <>
      { !authUser.isAuth && <LogInForm onSubmit={dispatchAuthenticate} /> }
      { authUser.isAuth
        && (!authUser.user.config.store)
        && <StoreForm authUser={authUser} flagMethods={flagMethods} selectStore={dispatchSelectStore} selectBC={dispatchSelectBC} /> }
      { loads.authUserIsLoading && <LoadComponent /> }
      { authUser.isAuth && authUser.user.config.store && <Redirect to="/" /> }
    </>
  );
};

LogIn.propTypes = {
  loads: loadsProps.isRequired,
  authUser: authUserProps.isRequired,
  dispatchSelectBC: PropTypes.func.isRequired,
  dispatchSelectStore: PropTypes.func.isRequired,
  dispatchAuthenticate: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  authUser: state.authUser,
  loads: state.loads,
});

const mapDispatchToProps = dispatch => ({
  dispatchAuthenticate: async (event) => {
    event.preventDefault();
    const formData = Serialize(event.target, { hash: true });
    const {
      domain,
      username,
      password,
    } = formData;

    const response = await fetch(`https://comercia.io/ingreso/index.php?funcion=dameSite&cuenta=${domain}`);
    const { ok, status } = response;

    if (!ok || status !== 200) {
      toast.error(`No se encontro el dominio: ${domain}`);

      return;
    }

    const realDomain = await response.text();
    const token = btoa(`${username}:${password}`);
    dispatch(authenticate(token, realDomain, Boolean(isMobile)));
  },
  dispatchSelectStore: (element) => {
    console.log('element', element.dataset);
    const { crmid, name, image, direccion, telefono, email, url_web, campos, direccionid, forzaenvios } = element.dataset;
    dispatch(selectStore(crmid, name, image, direccion, telefono, email, url_web, campos, direccionid, {}, forzaenvios));
  },
  dispatchSelectBC: (element) => {
    const { crmid, parent } = element.dataset;
    dispatch(selectBC(crmid, parent));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LogIn);
