export const getDefaultAddress = () => ({
  city: '',
  country: '',
  dir_references: '',
  direccion: '',
  latitude: '',
  longitude: '',
  neighborhood: '',
  num_ext: '',
  num_int: '',
  postcode: '',
  state: '',
  id: 0,
  factura: false,
  envio: false,
});

export default {
  getDefaultAddress,
};
