import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import './CustomFields.styles.scss';
import {
  IconButton,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';
import Fab from '@material-ui/core/Fab';
import SaveIcon from '@material-ui/icons/Save';
import CircularProgress from '@material-ui/core/CircularProgress';
import UserAPI from '../../api/UserAPI';
import appModules from '../../constant/appModules';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const CustomFields = ({
  isOpen,
  close,
  module = '',
}) => {
  const history = useHistory();
  const [open, setOpen] = React.useState(isOpen);
  const [loading, setLoading] = React.useState(false);
  const [fields, setFields] = React.useState(null);
  const userAPI = new UserAPI();
  const cart = useSelector(state => state.cart);
  const customer = useSelector(state => state.customer);
  const { selectedCustomer } = customer;
  const { orderSelected: { crmid } } = cart;

/* 
  const getFields = async () => {
    let module = '';
    if (history.location.pathname.includes('/pos/venta')) {
      module = 'SalesOrder';
    }
    if (module !== '') {
      setLoading(true);
      const response = await userAPI.getCustomFields(module, crmid);
      const { success, result } = response;
      if (success) {
        setFields(Object.values(result));
      }
      setLoading(false);
    }
  }; */


  const getFields = async () => {
    if (module !== '') {
      setLoading(true);
      let response = null;
      if (module === appModules.Accounts) {
        response = await userAPI.getCustomFields(module, selectedCustomer.crmid);
      } else {
        response = await userAPI.getCustomFields(module, crmid);
      }
      const { success, result } = response;
      if (success) {
        setFields(Object.values(result));
      }
      setLoading(false);
    }
  };

  const handleClose = () => {
    setFields(null);
    close();
  };

  const handleInputChange = (event, index) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    fields[index].value = value;
  };
/* 
  const submitForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    let module = '';
    if (history.location.pathname.includes('/pos/venta')) {
      module = 'SalesOrder';
    }
    const newValues = {};
    // eslint-disable-next-line array-callback-return
    fields.map((field) => {
      newValues[field.name] = field.value;
    });
    await userAPI.setCustomFields(newValues, module, crmid);
    setLoading(false);
  }; */
  const submitForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    const newValues = {};
    fields.map((field) => {
      newValues[field.name] = field.value;
    });
    const crmId = module === appModules.Accounts ? selectedCustomer.crmid : crmid;
    await userAPI.setCustomFields(newValues, module, crmId);
    setLoading(false);
    handleClose();
  };

  React.useEffect(() => {
    setOpen(isOpen);
    getFields();
    // eslint-disable-next-line
  }, [isOpen]);

  return (

    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
      <AppBar style={{ position: 'relative' }}>
        <Toolbar classes={{ root: 'd-flex justify-content-between' }}>
          <p />
          <Typography variant="h6" style={{ color: '#FFF' }}>
            Campos personalizados
          </Typography>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <div className="d-flex justify-content-center align-items-start" style={{ width: '100%', height: '100%', backgroundColor: '#efeff4' }}>
        <form className="mt-5" onSubmit={e => submitForm(e)} style={{ width: '100%', height: '75vh', paddingLeft: 20, paddingRight: 20, overflow: 'scroll' }}>
          <div style={{ maxWidth: 900 }}>
            <div className="row">
              {
                  fields && fields.map((field, index) => (
                    <div key={`index${index}`} className="mt-2 col-12">
                      {
                        field.type !== 'checkbox' && (
                          <label htmlFor="" className="custom-label">{field.label}</label>
                        )
                      }
                      {
                        field.type === 'picklist' && (
                          <select key={`select${index}`} name={field.name} className="custom-input custom-input-sm" onChange={e => handleInputChange(e, index)}>
                            {
                              field.picklistvalues && Object.values(field.picklistvalues).map((option, index) => (
                                <option key={`option${index}`} defaultValue={option}>{option}</option>
                              ))
                            }
                          </select>
                        )
                      }
                      {
                        field.type === 'textarea' && (
                          <textarea className="custom-input custom-input-textarea py-2" type={field.type} defaultValue={field.value || field.defaultvalue} name={field.name} required={field.required} cols="30" rows="3" onChange={e => handleInputChange(e, index)} />
                        )
                      }
                      {
                        field.type === 'checkbox' && (
                          <div className="d-flex align-items-center">
                            <input className="" type={field.type} defaultValue={field.value || field.defaultvalue} name={field.name} required={field.required} onChange={e => handleInputChange(e, index)} />
                            <p className="ml-2 mt-0">{field.label}</p>
                          </div>
                        )
                      }
                      {
                        field.type !== 'picklist' && field.type !== 'textarea' && field.type !== 'checkbox' && (
                          <input className="custom-input custom-input-sm" type={field.type} defaultValue={field.value || field.defaultvalue} name={field.name} required={field.required} onChange={e => handleInputChange(e, index)} />
                        )
                      }
                    </div>
                  ))
                }
              {
                  fields && fields.length === 0 && (
                    <p>No hay campos personalizados</p>
                  )
                }
            </div>
          </div>
          <Fab color="primary" type="submit" style={{ position: 'absolute', right: 20, bottom: 20 }}>
            {
                loading && (
                  <CircularProgress style={{ color: 'white' }} />
                )
              }
            {
                !loading && (
                  <SaveIcon />
                )
              }
          </Fab>
        </form>
      </div>
    </Dialog>
  );
};

CustomFields.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func.isRequired,
};
CustomFields.defaultProps = {
  isOpen: false,
};

export default CustomFields;
