
import React from 'react';
import './SaleSummary.scss';
import PropTypes from 'prop-types';
// import SaleDetail from '../SalesDetail/SalesDetail';
import ProgresBar from '../ProgresBar/ProgresBar';
import { formatMoney } from '../../../../helpers/tools';

const SaleSummary = (props) => {
  const {
    currency, total, pay, balance,
  } = props;
  return (
    <div className="saleSummary_container">
      <hr />
      <div className="saleSumary__header  px-4">
        <div className="saleSummary__header_title">
          <div className="saleSummary__header_title_container">
            <span className="saleSummary__primary-title mb-2">{currency}</span>
            <span className="saleSummary__header_title_amount">
              {/* $
              { total } */}
            </span>
          </div>
        </div>
        <div>
          <ProgresBar title="Shopify" total={total} pay={pay} money />
          <div title="">
            <span className="saleSummary__header_title_amount_second mt-2">
              <span className="saleSummary_left">
                {` $${formatMoney(balance)} `}
              </span>
              <span className="saleSummary_total">
                {` / $${formatMoney(total)}`}
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

SaleSummary.prototype = {
  currency: PropTypes.string,
  total: PropTypes.string,
  pay: PropTypes.string,
  balance: PropTypes.string,
};

export default SaleSummary;
