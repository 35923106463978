export default theme => ({
  titleStyle: {
    fontFamily: 'Roboto',
    padding: 10,
    backgroundColor: '#f6f6f7',
    color: '#64707a',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 20,
    fontWeight: 'bold',
    width: '100%',
    boxShadow: '0px 3px 6px #00000029',
  },
});
