/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Divider,
  TextField,
  Dialog,
  Slide,
  AppBar,
  Toolbar,
  IconButton,
} from '@material-ui/core';
import GoogleMapsPlacesSearch from '../../GoogleMapsPlacesSearch';

const Transition = React.forwardRef((props, ref) => <Slide direction="left" ref={ref} {...props} />);

const CreateAddress = ({
  city,
  country,
  dir_references,
  direccion,
  latitude,
  longitude,
  neighborhood,
  num_ext,
  num_int,
  postcode,
  state,

  open,
  toggle,
  onSave,
  title,

  id,
  envio,
  factura,
}) => {
  const [add, setAdd] = React.useState({
    city: '',
    country: '',
    dir_references: '',
    direccion: '',
    latitude: '',
    longitude: '',
    neighborhood: '',
    num_ext: '',
    num_int: '',
    postcode: '',
    state: '',
    id: 0,
    envio: false,
    factura: false,
  });

  const [err, setErr] = React.useState({
    city: false,
    country: false,
    direccion: false,
    neighborhood: false,
    num_ext: false,
    postcode: false,
    state: false,
  });

  const initFunction = () => {
    setAdd({
      city,
      country,
      dir_references,
      direccion,
      latitude,
      longitude,
      neighborhood,
      num_ext,
      num_int,
      postcode,
      state,
      id,
      envio,
      factura,
    });
  };

  const onChagneField = (e) => {
    const { target: { name, value } } = e;
    const _add = { ...add, [name]: value };
    setAdd(_add);
    const _err = { ...err, [name]: false };
    setErr(_err);
  };

  const onPlacesChanged = (places) => {
    if (places.length === 0) {
      return false;
    }
    const _add = { ...add };
    const { address_components, geometry } = places[0];
    Object.keys(address_components).forEach((addressId) => {
      const address = address_components[addressId];
      const { types, long_name } = address;
      types.forEach((type) => {
        if (type === 'route') {
          _add.direccion = long_name;
        }
        else if (type === 'street_number') {
          _add.num_ext = long_name;
        }
        else if (type === 'sublocality' || type === 'sublocality_level_1') {
          _add.neighborhood = long_name;
        }
        else if (type === 'locality') {
          _add.city = long_name;
        }
        else if (type === 'administrative_area' || type === 'administrative_area_level_1') {
          _add.state = long_name;
        }
        else if (type === 'country') {
          _add.country = long_name;
        }
        else if (type === 'postal_code') {
          _add.postcode = long_name;
        }
      });
    });
    /* Old way */
    /* _add.latitude = geometry.viewport.pa.g;
    _add.longitude = geometry.viewport.ka.g; */
    _add.latitude = geometry.location.lat;
    _add.longitude = geometry.location.lng;
    setAdd(_add);
    return true;
  };

  const evaluate = (json) => {
    if (json.city === ''
    || json.country === ''
    || json.direccion === ''
    || json.neighborhood === ''
    || json.num_ext === ''
    || json.postcode === ''
    || json.state === '') {
      setErr({
        city: (Boolean(json.city === '')),
        country: (Boolean(json.country === '')),
        direccion: (Boolean(json.direccion === '')),
        neighborhood: (Boolean(json.neighborhood === '')),
        num_ext: (Boolean(json.num_ext === '')),
        postcode: (Boolean(json.postcode === '')),
        state: (Boolean(json.state === '')),
      });
      return false;
    }
    return true;
  };

  const onSaveAddress = (_add) => {
    if (evaluate(_add)) {
      onSave(_add);
      const __add = {
        city: '',
        country: '',
        dir_references: '',
        direccion: '',
        latitude: '',
        longitude: '',
        neighborhood: '',
        num_ext: '',
        num_int: '',
        postcode: '',
        state: '',
        id: 0,
      };
      setAdd(__add);
    }
  };

  React.useEffect(() => {
    if (open) {
      initFunction();
    }
  // eslint-disable-next-line
  }, [open]);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={toggle}
      TransitionComponent={Transition}
    >
      <AppBar
        className="modalDetail_appBar"
        style={{ backgroundColor: '#8051A1' }}
        position="static"
      >
        <Toolbar className="d-flex px-0" classes={{ root: 'toolbar' }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="close"
            style={{ width: '48px', height: '48px' }}
            onClick={toggle}
          >
            <FontAwesomeIcon color="#ffffff" icon="chevron-left" />
          </IconButton>
          <div
            style={{ color: '#ffffff' }}
            className="w-100 font-size-24 d-flex align-items-center"
          >
            {title}
          </div>
        </Toolbar>
      </AppBar>
      <div className="p-2 w-100 d-flex flex-column h-auto">
        <FormGroup>
          <br />
          <GoogleMapsPlacesSearch
            apiKey="AIzaSyBkfEwSjhWtPHMjYAJH3poS1eK7bOgO8FY"
            onPlacesChanged={onPlacesChanged}
          />
          <br />
        </FormGroup>
        <FormGroup>
          <TextField
            id="direccion"
            label="Dirección *"
            name="direccion"
            className="w-100"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={e => onChagneField(e)}
            error={Boolean(err.direccion)}
            value={add.direccion || ''}
          />
        </FormGroup>
        <FormGroup>
          <TextField
            id="num_ext"
            label="Número Exterior *"
            name="num_ext"
            className="w-100"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={e => onChagneField(e)}
            error={Boolean(err.num_ext)}
            value={add.num_ext || ''}
          />
        </FormGroup>
        <FormGroup>
          <TextField
            id="num_int"
            label="Número Interior"
            name="num_int"
            className="w-100"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={e => onChagneField(e)}
            value={add.num_int || ''}
          />
        </FormGroup>
        <FormGroup>
          <TextField
            id="neighborhood"
            label="Colonia *"
            name="neighborhood"
            className="w-100"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={e => onChagneField(e)}
            error={Boolean(err.neighborhood)}
            value={add.neighborhood || ''}
          />
        </FormGroup>
        <FormGroup>
          <TextField
            id="city"
            label="Ciudad o Delegación *"
            name="city"
            className="w-100"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={e => onChagneField(e)}
            error={Boolean(err.city)}
            value={add.city || ''}
          />
        </FormGroup>
        <FormGroup>
          <TextField
            id="state"
            label="Estado *"
            name="state"
            className="w-100"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={e => onChagneField(e)}
            error={Boolean(err.state)}
            value={add.state || ''}
          />
        </FormGroup>
        <FormGroup>
          <TextField
            id="country"
            label="País *"
            name="country"
            className="w-100"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={e => onChagneField(e)}
            error={Boolean(err.country)}
            value={add.country || ''}
          />
        </FormGroup>
        <FormGroup>
          <TextField
            id="postcode"
            label="Código Postal *"
            name="postcode"
            className="w-100"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={e => onChagneField(e)}
            error={Boolean(err.postcode)}
            value={add.postcode || ''}
          />
        </FormGroup>
        <Divider />
        <FormGroup>
          <TextField
            id="dir_references"
            label="Referencias"
            name="dir_references"
            className="w-100"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={e => onChagneField(e)}
            value={add.dir_references || ''}
            multiline
            rowsMax="4"
          />
        </FormGroup>
        <Divider />
        <Button
          color="secondary"
          variant="contained"
          className="w-100 my-2"
          onClick={() => onSaveAddress(add)}
        >
          {`Guardar ${add.direccion || ''}`}
        </Button>
      </div>
    </Dialog>
  );
};

CreateAddress.propTypes = {
  city: PropTypes.string,
  country: PropTypes.string,
  dir_references: PropTypes.string,
  direccion: PropTypes.string,
  latitude: PropTypes.string,
  longitude: PropTypes.string,
  neighborhood: PropTypes.string,
  num_ext: PropTypes.string,
  num_int: PropTypes.string,
  postcode: PropTypes.string,
  state: PropTypes.string,

  open: PropTypes.bool,
  toggle: PropTypes.func,
  onSave: PropTypes.func,
  title: PropTypes.string,

  id: PropTypes.number,
  envio: PropTypes.bool,
  factura: PropTypes.bool,
};

CreateAddress.defaultProps = {
  city: process.env.NODE_ENV === 'development' ? 'Zapopan' : '',
  country: process.env.NODE_ENV === 'development' ? 'México' : '',
  dir_references: '',
  direccion: process.env.NODE_ENV === 'development' ? 'Dirección' : '',
  latitude: '',
  longitude: '',
  neighborhood: process.env.NODE_ENV === 'development' ? 'Colonia' : '',
  num_ext: process.env.NODE_ENV === 'development' ? '1' : '',
  num_int: '',
  postcode: process.env.NODE_ENV === 'development' ? '45000' : '',
  state: process.env.NODE_ENV === 'development' ? 'Jalisco' : '',

  open: false,
  toggle: () => {},
  onSave: () => {},
  title: 'Crear Dirección',

  id: 0,
  envio: false,
  factura: false,
};

export default CreateAddress;
