import React from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, ListItemText } from '@material-ui/core';
import moment from 'moment';
import ListLoading from '../../../List/ListLoading';

/* const products = [
  {
    "crmid": "73454",
    "entity_label": "VIA1563",
    "createdtime": "2023-02-23 12:52:13",
    "modifiedtime": "2023-02-23 12:52:19",
    "referencia": "VIA1563",
    "chofer": "0",
    "relatedtoid": "73453",
    "fecha_sol": "2023-02-23",
    "hora_sol": "18:00:00",
    "businessid": "68813",
    "treebessolicitudesenvio_no": "VIA1563",
    "weight": "10000.00000000",
    "last_modified": null,
    "cf_3019": "1- Por asignar",
    "treebesalmid": "54571",
    "tse_tipo_iep": null,
    "shipment_number": "",
    "carrier_service_code": "TS-0",
    "status_shipment": "Pendiente",
    "quote_amount": "0.0000",
    "shipment_method": "Otros",
    "shipment_content": "Productos varios",
    "carrier_pickup_number": "",
    "status_pickup": null,
    "treebesempaquesid": "",
    "date_added": null,
    "pickup_date": null,
    "label_s": null,
    "label_share_link": null,
    "carrier": "envio gratuito",
    "label_url": null,
    "json_detail": [
        {
            "id": "73454",
            "productid": "66506",
            "quantity": "10.000",
            "weight_s": "1000.00000000",
            "height_s": "0.00000000",
            "width_s": "0.00000000",
            "length_s": "0.00000000"
        }
    ],
    "items_quantity": "10.000",
    "items_count": "1",
    "items_count_distinct": "1",
    "vehiculo": "0",
    "f_origen": null,
    "t_origen": "",
    "f_destino": null,
    "t_destino": "",
    "distancia": "0.00",
    "cfdisrel": null,
    "tse_estado_i": null,
    "tse_tipo_pago": "Contra Entrega",
    "orig_street": "EMILIANO ZAPATA",
    "dest_street": "CALLE REPuBLICA DE PERu",
    "orig_num_ext": "226",
    "dest_num_ext": "173",
    "orig_num_int": "",
    "dest_num_int": "",
    "orig_neighborhood": "Las Juntas",
    "dest_neighborhood": "Colonial Tlaquepaque",
    "orig_city": "TLAQUEPAQUE",
    "dest_city": "SAN PEDRO TLAQUEPAQUE",
    "orig_state": "JALISCO",
    "dest_state": "JALISCO",
    "orig_country": "MEXICO",
    "dest_country": "MeXICO",
    "orig_postcode": "45500",
    "dest_postcode": "45570",
    "orig_alias": "VANGUARDIA LIQUIDOS CEDIS",
    "dest_alias": "PURIFICADORA .",
    "orig_phone": "",
    "dest_phone": "(331) 905-8810",
    "orig_latitude": "20.604584880180813",
    "dest_latitude": "20.6295742",
    "orig_longitude": "-103.34114614112724",
    "dest_longitude": "-103.3074371",
    "relatedtoid_label": "PED1578",
    "businessid_label": "REPUBLICA DE PERU 173",
    "treebesempaquesid_label": null,
    "vehiculo_label": null,
    "chofer_label": null,
    "treebesalmid_label": "CEDIS",
    "products": [
        {
            "crmid": "66506",
            "treebessolicitudesenvioid": "73454",
            "productname": "OSMOSIS 1,000 LTS - C P S E",
            "product_no": "PRO16",
            "productcode": "OSM-C P S E",
            "quantity": "10.000",
            "unit_price": "258.62069000",
            "tpoc_id": "66293",
            "weight_s": "1000.00000000",
            "height_s": "0.00000000",
            "width_s": "0.00000000",
            "length_s": "0.00000000",
            "listprice": "125.00000000",
            "precio_neto": "125.00000000",
            "image": false
        }
    ],
    "fecha_hora_solicitud": "2023-02-23 18:00:00"
}
]; */


const ShipmentInfo = ({ loading, order }) => {
  const getDirection = (tipo, item) => {
    if (tipo === 'origen') {
      return `Calle ${item?.orig_state}, #ext ${item?.orig_num_ext} #int ${item?.orig_num_int}, ${item?.orig_neighborhood} codigo postal ${item?.orig_postcode}, ${item?.orig_city}, ${item?.orig_state}`;
    }
    return `Calle ${item?.dest_state}, #ext ${item?.dest_num_ext} #int ${item?.dest_num_int}, ${item?.dest_neighborhood} codigo postal ${item?.dest_postcode}, ${item?.dest_city}, ${item?.dest_state}`;
  };
  return (
    <div>
      <ListLoading isLoading={loading} />
      <div className="detailInfo_products">
        <List className="bg-white" component="nav" aria-label="main mailbox folders" dense>
          <ListItem divider classes={{ selected: 'salesDetail-list-selected' }}>
            <ListItemText className="p-0 text-left" primary="Referencia" secondary={order.referencia} classes={{ primary: 'salesDetail-list-text-left' }} />
            <ListItemText className="p-0 text-right" primary="Relacionado a" secondary={order.relatedtoid_label} classes={{ primary: 'salesDetail-list-text-right' }} />
          </ListItem>
          <ListItem divider classes={{ selected: 'salesDetail-list-selected' }}>
            <ListItemText className="p-0 text-left" primary="Estado" secondary={`${order.tse_estado_i}`.split('-').pop()} classes={{ primary: 'salesDetail-list-text-left' }} />
            <ListItemText className="p-0 text-right" primary="Almacen" secondary={order.treebesalmid_label} classes={{ primary: 'salesDetail-list-text-right' }} />
          </ListItem>

          <ListItem divider classes={{ selected: 'salesDetail-list-selected' }}>
            <ListItemText className="p-0 text-left" primary="Carrier" secondary={order.carrier} classes={{ primary: 'salesDetail-list-text-left' }} />
            <ListItemText className="p-0 text-right" primary="Carrier código" secondary={order.carrier_service_code} classes={{ primary: 'salesDetail-list-text-right' }} />
          </ListItem>

          <ListItem divider classes={{ selected: 'salesDetail-list-selected' }}>
            <ListItemText className="p-0 text-left" primary="Tipo pago" secondary={order.tse_tipo_pago} classes={{ primary: 'salesDetail-list-text-left' }} />
            <ListItemText className="p-0 text-right" primary="Peso" secondary={order.weight} classes={{ primary: 'salesDetail-list-text-right' }} />
          </ListItem>

          <ListItem divider classes={{ selected: 'salesDetail-list-selected' }}>
            <ListItemText className="p-0 text-left" primary="Fecha creada" secondary={moment(order.createdtime).format('DD-MM-YYYY')} classes={{ primary: 'salesDetail-list-text-left' }} />
            <ListItemText className="p-0 text-right" primary="Fecha solicitud" secondary={moment(order.fecha_hora_solicitud).format('DD-MM-YYYY')} classes={{ primary: 'salesDetail-list-text-right' }} />
          </ListItem>

          <ListItem divider classes={{ selected: 'salesDetail-list-selected' }}>
            <ListItemText className="p-0 text-left" primary="Origen" secondary={getDirection('origen', order)} classes={{ primary: 'salesDetail-list-text-left' }} />
          </ListItem>

          <ListItem classes={{ selected: 'salesDetail-list-selected' }}>
            <ListItemText className="p-0 text-left" primary="Destino" secondary={getDirection('destino', order)} classes={{ primary: 'salesDetail-list-text-right' }} />
          </ListItem>
        </List>
      </div>
    </div>
  ); };


ShipmentInfo.propTypes = {
  loading: PropTypes.bool,
  order: PropTypes.object.isRequired,
};

ShipmentInfo.defaultProps = {
  loading: false,
};

export default ShipmentInfo;
