/* eslint-disable react/prop-types */
import React from 'react';
import './CommentItem.scss';
import PropTypes from 'prop-types';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Edit from '@material-ui/icons/Edit';
/* import DeleteIcon from '@material-ui/icons/Delete'; */
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import moment from 'moment';


const CommentItem = ({
  commentId, avatar, name, time, comment, setActiveComment,
}) => {
  return (
    <>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt="Remy Sharp" src={`${avatar || '/img/ava.png'}`} />
        </ListItemAvatar>
        <ListItemText
          primary={name}
          secondary={moment(time).format('lll')}
          classes={{
            primary: 'comment-title',
            secondary: 'comment-subtitle',
          }}
        />

        {/* for edit comment
        <ListItemSecondaryAction>
          <IconButton edge="end" aria-label="delete">
            <DeleteIcon ontSize="small" style={{ color: '#b7bfc7' }} />
          </IconButton>
          <IconButton edge="end" aria-label="delete" onClick={() => setActiveComment(commentId)}>
            <Edit ontSize="small" style={{ color: '#b7bfc7' }} />
          </IconButton>
        </ListItemSecondaryAction> */}

      </ListItem>
      <div className="px-4 text-justify comment-comment">
        {comment.replace(/<[^>]+>/g, '')}
      </div>
    </>
  ); };

CommentItem.prototype = {
  avatar: PropTypes.string,
  name: PropTypes.string,
  time: PropTypes.string,
  comment: PropTypes.string,
};
export default CommentItem;
