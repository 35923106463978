/* eslint-disable import/no-cycle */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import FullDialog from '../FullDialog';
import {
  saveTimbrado,
  fetchTimbrado,
} from '../../redux/actions/TimbrarActions';
import { openModal } from '../../redux/actions/modalActions';
import Confirm from '../Confirm';
import TimbreDialog from './components/TimbreDialog';

const Timbre = ({
  title,
  match,
  onSave,
  timbrar,
  history,
  isLoading,
  dispatchFetchTimbrado,
}) => {
  const [data, setData] = useState({});
  const [confirmIsOpen, changeConfirm] = useState(false);
  const { confirma } = timbrar;
  useEffect(() => {
    const { params } = match;
    dispatchFetchTimbrado(params.id, history);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <FullDialog
      isOpen
      title={title}
      isLoading={isLoading}
      onClose={() => history.goBack()}
      onSave={
        () => {
          changeConfirm(true);
        }
      }
    >
      <div className="p-2" style={{ maxWidth: '1200px' }}>
        <TimbreDialog timbrar={timbrar} data={data} setData={setData} />
      </div>
      <Confirm
        isOpen={confirmIsOpen}
        title="Confirmar"
        description={(
          <div className="p-1">
            <p>
              <strong>Factura: </strong>
              <span>
                { confirma.Factura }
              </span>
            </p>
            <p>
              <strong>Modo: </strong>
              <span>
                { confirma.Modo }
              </span>
            </p>
            <p>
              <strong>RFC Emisor: </strong>
              <span>
                { confirma['RFC Emisor'] }
              </span>
            </p>
          </div>
        )}
        onClose={() => { changeConfirm(false); }}
        onConfirm={
          (response) => {
            if (response) {
              const { crmid } = timbrar;
              history.goBack();
              onSave({ ...data, crmid }, history);
            }
          }
        }
      />
    </FullDialog>
  );
};

Timbre.propTypes = {
  match: PropTypes.bool.isRequired,
  title: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  timbrar: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  dispatchFetchTimbrado: PropTypes.func.isRequired,
};

Timbre.defaultProps = {
  title: 'Timbrar Orden',
};

const mapStateToProps = (state) => {
  const { cart, loads } = state;
  const { orderSelected } = cart;
  const existOrder = Boolean(
    Object.keys(orderSelected).length > 0
    && orderSelected.order,
  );
  const orderNo = (existOrder ? orderSelected.order.label : '');
  const title = `Timbrar orden: ${orderNo}`;
  const isLoading = Boolean(loads.timbrarIsLoading);
  return ({
    title,
    isLoading,
    timbrar: state.timbrar,
  });
};

const mapDispatchToProps = dispatch => ({
  /* Timbrar Actions */
  onSave: (timbre, history = {}) => dispatch(saveTimbrado(timbre, history)),
  dispatchFetchTimbrado: (crmid, history = {}) => dispatch(fetchTimbrado(crmid, history)),
  dispatchOpenTimbrarModal: () => dispatch(openModal('timbrar')),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Timbre));
