import React from 'react';
import PropTypes from 'prop-types';
import {
  List,
  Avatar,
  Collapse,
  ListItem,
  IconButton,
  ListItemText,
  ListItemAvatar,
  LinearProgress,
  ListItemSecondaryAction,
} from '@material-ui/core';

import { Edit } from '@material-ui/icons';

import {
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';

import modalsProps from '../../../propTypes/modalsProps';
import customerProps from '../../../propTypes/customerProps';
import loadsProps from '../../../propTypes/loadsProps';

import SearchBar from '../../SearchBar';

const SelectCustomer = ({
  customer,
  dispatchEditOrder,
  dispatchFetchAllCustomers,
  dispatchFilterCustomers,
  dispatchSelectCustomer,
  dispatchToggleModal,
  loads,
  modals,
  orderSelected,
}) => {
  const toggleModal = () => { dispatchToggleModal('customerSelect'); };
  return (
    <Modal
      backdrop="static"
      toggle={toggleModal}
      className="customer__modal"
      isOpen={modals.customerSelectModalIsOpen}
      onOpened={() => { dispatchFetchAllCustomers(); }}
    >
      <ModalHeader toggle={toggleModal}>Clientes</ModalHeader>
      <ModalBody>
        <SearchBar
          desableSearchMode
          handleOnKeyPress={(word) => { dispatchFilterCustomers(word); }}
        />
        <Collapse in={loads.selectCustomerIsLoading}>
          <LinearProgress />
        </Collapse>
        <Collapse in={!loads.selectCustomerIsLoading}>
          <List>
            {
              customer.customers && customer.customers.map(_customer => (
                <ListItem
                  button
                  key={`SelectCustomer-${_customer.crmid}`}
                  onClick={async () => {
                    await dispatchSelectCustomer(_customer);
                    if (Object.keys(orderSelected).length > 0) {
                      await dispatchEditOrder(_customer.crmid);
                    }
                  }}
                >
                  <ListItemAvatar>
                    <Avatar>
                      { _customer.accountname.charAt(0) }
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={_customer.accountname} secondary={_customer.siccode} />
                  <ListItemSecondaryAction>
                    <IconButton
                      onClick={async () => {
                        await dispatchSelectCustomer(_customer);
                        await dispatchToggleModal('customerEdit');
                      }}
                    >
                      <Edit />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))
            }
          </List>
        </Collapse>
      </ModalBody>
    </Modal>
  );
};

SelectCustomer.propTypes = {
  customer: customerProps.isRequired,
  dispatchEditOrder: PropTypes.func.isRequired,
  dispatchFetchAllCustomers: PropTypes.func.isRequired,
  dispatchFilterCustomers: PropTypes.func.isRequired,
  dispatchSelectCustomer: PropTypes.func.isRequired,
  dispatchToggleModal: PropTypes.func.isRequired,
  orderSelected: PropTypes.object,
  loads: loadsProps.isRequired,
  modals: modalsProps.isRequired,
};

SelectCustomer.defaultProps = {
  orderSelected: {},
};

export default SelectCustomer;
