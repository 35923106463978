export const GET_ALL_TASK = 'GET_ALL_TASK';
export const SELECT_TASK = 'SELECT_TASK';
export const GET_TASK_DOCUMENTS = 'GET_TASK_DOCUMENTS';
export const GET_TASK_DOCUMENTS_PAGINATOR = 'GET_TASK_DOCUMENTS_PAGINATOR';
export const SET_PROJECTS = 'SET_PROJECTS';
export const SET_PROJECT = 'SET_PROJECT';
export const SET_PROJECT_SEARCH = 'SET_PROJECT_SEARCH';
export const SET_TASK_SEARCH = 'SET_TASK_SEARCH';
export const TOGGLE_TIMER = 'TOGGLE_TIMER';
export const SET_TIMER = 'SET_TIMER';
export const SET_TIMER_TRACKING = 'SET_TIMER_TRACKING';
export const SET_USERS = 'SET_USERS';
export const SET_USER = 'SET_USER';
export const SET_STATUS_FILTER = 'SET_STATUS_FILTER';
export const SET_PROJECT_FILTER = 'SET_PROJECT_FILTER';
export const SET_USERS_FILTER = 'SET_USERS_FILTER';
