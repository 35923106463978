export const ADD_SALES_ORDER = 'ADD_SALES_ORDER';
export const ADD_SALES_ORDER_SUMMARY = 'ADD_SALES_ORDER_SUMMARY';
export const ADD_LIST_INVOICE = 'ADD_LIST_INVOICE';
export const ADD_TRANSFERS = 'ADD_TRANSFERS';
export const ADD_MDA = 'ADD_MDA';
export const ADD_CREDIT_NOTES = 'ADD_CREDIT_NOTES';
export const ADD_CASH_FLOW = 'ADD_CASH_FLOW';
export const ADD_QUOTES = 'ADD_QUOTES';

export const ADD_SALES_ORDER_PAGINATOR = 'ADD_SALES_ORDER_PAGINATOR';
export const ADD_SALES_ORDER_SUMMARY_PAGINATOR = 'ADD_SALES_ORDER_SUMMARY_PAGINATOR';
export const ADD_LIST_INVOICE_PAGINATOR = 'ADD_LIST_INVOICE_PAGINATOR';
export const ADD_TRANSFERS_PAGINATOR = 'ADD_TRANSFERS_PAGINATOR';
export const ADD_MDA_PAGINATOR = 'ADD_MDA_PAGINATOR';
export const ADD_CREDIT_NOTES_PAGINATOR = 'ADD_CREDIT_NOTES_PAGINATOR';
export const ADD_CASH_FLOW_PAGINATOR = 'ADD_CASH_FLOW_PAGINATOR';
export const ADD_QUOTES_PAGINATOR = 'ADD_QUOTES_PAGINATOR';
