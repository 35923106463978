import React from 'react';
import PropTypes from 'prop-types';
import './InputText.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const InputText = ({
  inputProps,
  showIcon,
  styleIcon,
  sizeIcon,
  icon,
  onChange,
  onKeyPress,
  onKeyUp,
  onClick,
  onBlur,
}) => (
  <div className="inputText">
    <input
      type="text"
      onChange={onChange}
      onKeyPress={onKeyPress}
      onKeyUp={onKeyUp}
      onClick={onClick}
      onBlur={onBlur}
      {...inputProps}
    />

    {showIcon && (
    <div className="inputText_icon">
      <FontAwesomeIcon
        style={{ ...styleIcon }}
        size={sizeIcon}
        icon={icon}
      />
    </div>
    )}
  </div>
);

InputText.propTypes = {
  inputProps: PropTypes.object,
  showIcon: PropTypes.bool,
  styleIcon: PropTypes.object,
  sizeIcon: PropTypes.string,
  icon: PropTypes.string,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  onKeyUp: PropTypes.func,
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
};

InputText.defaultProps = {
  inputProps: {},
  showIcon: true,
  styleIcon: {},
  sizeIcon: 'xs',
  icon: 'comment-alt',
  onChange: () => {},
  onKeyPress: () => {},
  onKeyUp: () => {},
  onClick: () => {},
  onBlur: () => {},
};

export default InputText;
