/* eslint-disable camelcase */
/* eslint-disable import/no-cycle */
import { connect } from 'react-redux';
import DeliverModal from './DeliverModal';

import { setActivityToOrder } from '../../../../../../redux/actions/orderActions';
import {
  addItemsToTable,
  chnageComment,
  changeFieldValue,
  selectToRefunded,
  selectToDelivered,
  selectEmptyToTable,
} from '../../../../../../redux/actions/tableActions';
import { getOrderPdf } from '../../../../../../redux/actions/posActions';

const mapStateToProps = (state) => {
  const {
    table,
    loads,
    authUser,
    modals,
    cart,
  } = state;
  const isLoading = Boolean(loads.orderIsLoading);
  const orderNo = cart.orderSelected && cart.orderSelected.order ? cart.orderSelected.order.salesorder_no || cart.orderSelected.order.label : '';
  const isOrder = Boolean(orderNo);
  const title = isOrder ? (`Entregar de Orden: ${orderNo}`) : ('Entrega');
  return {
    table,
    authUser,
    modals,
    cart,
    isLoading,
    orderNo,
    isOrder,
    title,
    loads,
  };
};

const mapDispatchToProps = dispatch => ({
  /* Deliver and Refound Dispatches */
  setActivityToOrder: (crmid, type, history) => dispatch(setActivityToOrder(crmid, type, history)),
  addItemsToTable: (typeAction, history) => dispatch(addItemsToTable(typeAction, history)),

  /* Table Actions */
  selectEmpty: () => dispatch(selectEmptyToTable()),
  changeComment: comment => dispatch(chnageComment(comment)),
  changeFieldValue: obj => dispatch(changeFieldValue(obj)),
  selectToRefunded: () => dispatch(selectToRefunded()),
  selectToDelivered: () => dispatch(selectToDelivered()),
  getOrderDocument: data => dispatch(getOrderPdf(data)),

});

export default connect(mapStateToProps, mapDispatchToProps)(DeliverModal);
