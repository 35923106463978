/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { Popover } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import Flag from '../../../../../../Flag';
import SearchCustomer from '../../../../../../Customer/components/SearchCustomer';

const controlProps = {
  width: '100%',
  height: '54px',
  borderRadius: '17px',
  border: 'none',
  fontSize: '16px',
};

const singleProps = {
  color: '#64707A',
  fontSize: '14px',
};

const DetailCustomer = ({
  selection,
  loading,
  sostatus,
  setOrderStatus,
  editCustomerOrder,
}) => {
  const authUser = useSelector(state => state.authUser);
  const { config } = authUser.user;
  const { pos_no_editar_so, pos_allowed_sostatus } = config;
  const allowedStatuses = pos_allowed_sostatus.split(' |##| ');
  const cart = useSelector(state => state.cart);
  const { user: { quotestage } } = authUser;
  const { orderSelected } = cart;
  const { order } = orderSelected;
  // eslint-disable-next-line no-prototype-builtins
  const isQuote = !!((order && order.hasOwnProperty('quote_no')));
  const [anchorEl, setAchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const isOrder = Boolean(Object.keys(selection).length > 0);
  const isBilled = Boolean(selection.estadosat && selection.estadosat !== 'Sin Factura' && selection.estadosat !== 'null');

  const filteredOrderStatuses = !isQuote ? sostatus : sostatus.filter(status => allowedStatuses.includes(status.value));

  const enableEdit = Boolean(
    (order?.estado_fde === 'Sin Pago/Cobro'
      && (order.estado_mda === 'Sin Entregar' || order.estado_mda === 'N/A')
      && (order.estado_mda === 'Sin Entregar' || order.estado_mda === 'N/A' || order.estado_mda === 'Sin Recibir')
        && order.sostatus === 'Creado'
        && (order.estadosat === 'Cancelado' || order.estadosat === 'Sin Factura' || order.estadosat === null)
    ),
  );

  const canEdit = Boolean(config.pos_no_editar_so !== '1' && (isQuote ? true : enableEdit));

  const renderBackgroundColor = (_selection) => {
    if (!loading) {
      const { status } = _selection;
      switch (status) {
        case 'Autorizado':
        case 'Approved':
          return '#23A1DB';
        case 'Apartado':
        case 'Aceptado':
        case 'Delivered':
          return '#F7C379';
        case 'Cancelado':
        case 'Cancelled':
        case 'Rechazado':
          return '#F05B70';
        default:
          return '#b6bec6';
      }
    } else {
      return '#ffffff';
    }
  };

  const renderBackgroundColorItem = (_item) => {
    let estado = null;
    if (isQuote) {
      estado = _item;
    } else {
      const { id } = _item;
      estado = id;
    }
    switch (estado) {
      case 'Delivered':
      case 'Autorizado':
        return '#23A1DB';
      case 'Approved':
      case 'Accepted':
      case 'Aceptado':
        return '#F7C379';
      case 'Cancelled':
      case 'Rejected':
      case 'Rechazado':
        return '#F05B70';
      case 'Revisado':
      case 'Reviewed':
        return '#8152a0';
      default:
        return '#b6bec6';
    }
  };

  const renderColor = (_selection) => {
    const { status } = _selection;
    switch (status) {
      case 'Created':
      case 'Creado':
        return '#FFFFFF';
      default:
        return '#FFFFFF';
    }
  };

  const renderColorItem = (_item) => {
    const { id } = _item;
    switch (id) {
      case 'Created':
      case 'Creado':
        return '#FFFFFF';
      default:
        return '#FFFFFF';
    }
  };

  return (
    <div className="detailInfo_customer">
      <span>
        <span>Estado: </span>
        <div className="detailInfo_customer_flag">
          {!isOrder && !loading ? (
            <Info />
          ) : (
            <Flag
              width="42px"
              height="42px"
              size="2x"
              icon="file-invoice-dollar"
              ariaOwns={open ? 'status-popover' : undefined}
              color={renderColor(selection)}
              isLoading={loading}
              disabled={Boolean((!isOrder || loading || isBilled) && !canEdit)}
              onClick={e => setAchorEl(e.currentTarget)}
              backgroundColor={renderBackgroundColor(selection)}
            />
          )}
        </div>
      </span>
      <div className="w-100 ml-4">
        <span className="ml-2 font-size-14">
          Cliente:
        </span>
        <div className="detailInfo_customer_search">
          <SearchCustomer
            control={{ ...controlProps }}
            single={{ ...singleProps }}
            orderSelected={selection}
            dispatchEditOrder={editCustomerOrder}
            disabled={Boolean(!isOrder || loading || isBilled || !canEdit)}
          />
        </div>
      </div>
      <Popover
        id="status-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAchorEl(null)}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        className="detailInfo_customer_paper"
      >
        <div
          className="detailInfo_customer_popover detailInfo_customer_popoend"
        >
          {
            !isQuote
              ? filteredOrderStatuses.map(stat => (
                <span key={`key_stat_${stat.id}`}>
                  <Flag
                    width="42px"
                    height="42px"
                    size="2x"
                    icon="file-invoice-dollar"
                    ariaOwns={open ? 'status-popover' : undefined}
                    color={renderColorItem(stat)}
                    backgroundColor={renderBackgroundColorItem(stat)}
                    onClick={() => {
                      setOrderStatus(stat.id);
                      setAchorEl(null);
                    }}
                  />
                  <span>
                    {stat.value}
                  </span>
                </span>
              ))
              : Object.entries(quotestage).map(([key, value]) => (
                <span key={`key_stat_${key}`}>
                  <Flag
                    width="42px"
                    height="42px"
                    size="2x"
                    icon="file-invoice-dollar"
                    ariaOwns={open ? 'status-popover' : undefined}
                    color={renderColorItem(value)}
                    backgroundColor={renderBackgroundColorItem(key)}
                    onClick={() => {
                      setOrderStatus(key);
                      setAchorEl(null);
                    }}
                  />
                  <span>
                    {value}
                  </span>
                </span>
              ))
          }
        </div>
      </Popover>
    </div>
  );
};

DetailCustomer.propTypes = {
  selection: PropTypes.object,
  loading: PropTypes.bool,
  sostatus: PropTypes.array,
  setOrderStatus: PropTypes.func,
  editCustomerOrder: PropTypes.func,
};

DetailCustomer.defaultProps = {
  selection: {},
  loading: false,
  sostatus: [],
  setOrderStatus: () => {},
  editCustomerOrder: () => {},
};

export default DetailCustomer;
