/* eslint-disable no-prototype-builtins */
import React from 'react';
import { withRouter } from 'react-router-dom';
import './QuoteModal.styles.scss';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import TopMenuDrawer from '../../../../components/TopMenuDrawer';
import QuoteSwipe from '../QuoteSwipeMenu/QuoteSwipe';
import {
  clearCart,
  addOrderToCart,
  setActivePromotions,
  desactivePromotions,
  resetPromotions,
  updatePromotions,
} from '../../../../redux/actions/cartActions';
import { setDiscountTaxes } from '../../../../redux/actions/filtersActions';
import CustomFields from '../../../../components/customFields';
import ShareUrl from '../../../../components/ShareLinks/ShareUrl';
import appModules from '../../../../constant/appModules';
import ShareModal from '../../../../components/Share/components/ShareModal';
import { editOrder } from '../../../../redux/actions/sellActions';
import { isEditMobileOrders, selectOrder } from '../../../../redux/actions/orderActions';

import MobileOrderPoducts from '../../../../components/mobile-product';
import PricebookListModal from '../../../../components/Pricebook/components/PricebookListModal';
import { cloneQuote, fetchAllCotizaciones, fetchListQuotes } from '../../../../redux/actions/cotizarActions';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const QuoteModal = ({match}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [open, setOpen] = React.useState(true);
  const [openShare, setOpenShare] = React.useState(false);
  const [link, setLink] = React.useState(false);
  const [field, setField] = React.useState(false);
  const [openProducts, setOpenProducts] = React.useState(false);
  const [openPriceBook, setOpenPriceBook] = React.useState(false);

  const discountTaxes = useSelector(state => state.filters.discountTaxes);
  const cart = useSelector(state => state.cart);
  const authUser = useSelector(state => state.authUser);

  // eslint-disable-next-line camelcase
  const { user: { config: { pos_promo_cart } } } = authUser;
  const {
    orderSelected: { order },
    activePromotions,
    orderSelected,
    products,
  } = cart;

  const loads = useSelector(state => state.loads);
  const isLoading = Boolean(loads && loads.emailTemplateIsLoading);
  const isLoadingProducts = Boolean(loads && loads.productIsLoading);

  const isQuote = orderSelected.order && orderSelected.order.hasOwnProperty('quote_no');

  React.useEffect(() => {
    if (match.params.id) {
      dispatch(selectOrder(match.params.id));
    }
  // eslint-disable-next-line
  }, []);

  const togglePromotions = async () => {
    if (activePromotions) {
      await dispatch(desactivePromotions());
      await dispatch(resetPromotions());
    } else {
      await dispatch(setActivePromotions());
    }
    await dispatch(updatePromotions());
  };

  const toggleTaxesDiscount = () => {
    dispatch(addOrderToCart(order));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClosePricebook = () => {
    setOpenPriceBook(false);
  };

  const handleModalAction = () => {
    setOpenShare(!openShare);
  };

  const handleShareClose = () => {
    setOpenShare(false);
    setOpenProducts(false);
    dispatch(isEditMobileOrders(false));
  };


  const addProductsOpen = () => {
    dispatch(isEditMobileOrders(true));
    setOpenProducts(true);
  };

  const [opt, setOpt] = React.useState(false);
  const toggle = () => setOpt(!opt);
  const options = [
    {
      icon: 'save',
      title: 'Guardar',
      onClick: () => {
        toggle();
        dispatch(editOrder());
      },
      disabled: !order,
    },
    {
      icon: 'share-alt',
      title: 'Compartir',
      onClick: () => {
        toggle();
        handleModalAction();
      },
      disabled: !order,
    },
    {
      icon: 'link',
      title: 'Enlace',
      onClick: () => {
        toggle();
        setLink(true);
      },
    },
    {
      icon: discountTaxes ? 'check-square' : 'square',
      title: 'Sin impuestos',
      onClick: () => {
        toggle();
        dispatch(setDiscountTaxes());
        toggleTaxesDiscount();
      },
      disabled: !order,
    },
    {
      icon: 'pen-square',
      title: 'Campos personalizados',
      onClick: () => {
        toggle();
        setField(true);
      },
      disabled: !order,
    },
    {
      icon: 'certificate',
      title: activePromotions ? 'Promociones activas' : 'Promociones desactivadas',
      onClick: () => {
        toggle();
        togglePromotions();
      },
    },
    {
      icon: 'shopping-cart',
      title: 'Agregar producto',
      onClick: () => {
        toggle();
        addProductsOpen();
      },
      disabled: !order,
    },
    {
      icon: 'bookmark',
      title: 'Cambiar precios',
      onClick: () => {
        toggle();
        setOpenPriceBook(true);
      },
    },
    {
      icon: 'copy',
      title: 'Clonar',
      onClick: () => {
        dispatch(cloneQuote());
        toggle();
      },

      disabled: !isQuote,
    },
  ];

  React.useEffect(() => {
    // eslint-disable-next-line camelcase
    if (products.length === 0 && Object.entries(orderSelected).length === 0 && pos_promo_cart === '1') {
      dispatch(setActivePromotions());
    }
    if (!products.length) {
      dispatch(desactivePromotions());
    }
    // eslint-disable-next-line
  }, [products, orderSelected]);

  return (
    <div>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar
          className="modalDetail_appBar"
          style={{ backgroundColor: '#ffffff' }}
          position="static"
        >
          <Toolbar className="d-flex px-0" classes={{ root: 'toolbar' }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={async () => {
                await dispatch(clearCart());
                await dispatch(fetchAllCotizaciones());
                history.push('/pos/cotizacion');
              }}
              aria-label="close"
              style={{ width: '48px', height: '48px' }}
            >
              <FontAwesomeIcon color="#313C52" icon="chevron-left" />
            </IconButton>
            <span className="modalDetail_header">
              Detalles
              {' '}
              {order?.quote_no}
            </span>
            <IconButton
              edge="start"
              color="inherit"
              className="ml-auto"
              aria-label="close"
              style={{ width: '48px', height: '48px' }}
              onClick={
                () => toggle()
              }
            >
              <FontAwesomeIcon color="#313C52" icon="ellipsis-v" />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div className="orderDetail_container">
          <QuoteSwipe />
        </div>

        <TopMenuDrawer
          opt={opt}
          toggle={toggle}
          options={options}
        />
        <ShareUrl isOpen={link} close={() => setLink(!link)} />
        <CustomFields isOpen={field} close={() => setField(!field)} />
        <ShareModal
          open={openShare}
          title={`Compartir ${order ? order.quote_no : ''}`}
          handleClose={handleShareClose}
          order={order || {}}
          template={appModules.Quotes}
          isLoading={isLoading}
        />
        <MobileOrderPoducts
          open={openProducts}
          title="Agregar productos"
          handleClose={handleShareClose}
          isLoading={isLoadingProducts}
        />
        <PricebookListModal open={openPriceBook} onClose={handleClosePricebook} />
      </Dialog>
    </div>
  );
};

QuoteModal.propTypes = {

};

export default withRouter(QuoteModal);
