/* eslint-disable react/prop-types */
import { List, ListItem, ListItemText } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import appModules from '../../../../constant/appModules';
import GenericFullModal from '../../../GenericFullModal';
import ShareModal from '../../../Share/components/ShareModal';
import ShippingGuideList from '../../../ShippingGuide/ShippingGuideList';
import { formatMoney } from '../../../../helpers/tools';
import { selectOrder } from '../../../../redux/actions/orderActions';

const ShipmentExpand = ({ sale }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loads = useSelector(state => state.loads);
  const isLoading = Boolean(loads && loads.emailTemplateIsLoading);
  const [openShare, setOpenShare] = React.useState(false);
  const [openGuide, setOpenGuide] = React.useState(false);

  const handleShareClose = () => {
    setOpenShare(false);
    setOpenGuide(false);
  };

  const entregarOrden = async () => {
    // dispatch(selectOrder(sale.crmid));
    history.push(`/entregas/detalle/${sale.crmid}`);

  };

  const goToMap = (order) => {
    let url = '';
    console.log('order', order);
    if (order.dest_latitude && order.dest_latitude !== '' && order.dest_longitude && order.dest_longitude !== '') {
      url = `https://www.google.com/maps/search/?api=1&query=${order.dest_latitude || ''}, ${order.dest_longitude || ''}`;
    } else {
      url = `https://www.google.com/maps/search/?api=1&query=${
        order.dest_street || ''
      }, ${order.dest_country || ''}, ${
        order.dest_state || ''
      }, ${order.dest_postcode || ''}`;
    }
    window.open(url, '_blank');
  };

  return (
    <>
      <List component="nav" aria-label="main mailbox folders" dense>
        <ListItem classes={{ selected: 'salesDetail-list-selected' }}>
          <ListItemText className="ml-2 text-left" primary="Estado:" classes={{ primary: 'salesDetail-list-text-left' }} />
          <ListItemText className="p-0 text-right" primary={`${sale.tse_estado_i}`.split('-').pop()} classes={{ primary: 'salesDetail-list-text-right' }} />
        </ListItem>
        <ListItem classes={{ selected: 'salesDetail-list-selected' }}>
          <ListItemText className="ml-2 text-left" primary="Sku Diferentes:" classes={{ primary: 'salesDetail-list-text-left' }} />
          <ListItemText className="p-0 text-right" primary={sale.sku_diferentes} classes={{ primary: 'salesDetail-list-text-right' }} />
        </ListItem>

        <ListItem classes={{ selected: 'salesDetail-list-selected' }}>
          <ListItemText className="ml-2 text-left" primary="Sku Cantidad:" classes={{ primary: 'salesDetail-list-text-left' }} />
          <ListItemText className="p-0 text-right" primary={sale.sku_cantidad} classes={{ primary: 'salesDetail-list-text-right' }} />
        </ListItem>

        <ListItem classes={{ selected: 'salesDetail-list-selected' }}>
          <ListItemText className="ml-2 text-left" primary="Balance:" classes={{ primary: 'salesDetail-list-text-left' }} />
          <ListItemText className="p-0 text-right" primary={formatMoney(sale.balance)} classes={{ primary: 'salesDetail-list-text-right' }} />
        </ListItem>

        <ListItem classes={{ selected: 'salesDetail-list-selected' }}>
          <ListItemText className="ml-2 text-left" primary="Peso:" classes={{ primary: 'salesDetail-list-text-left' }} />
          <ListItemText className="p-0 text-right" primary={sale.peso} classes={{ primary: 'salesDetail-list-text-right' }} />
        </ListItem>

      </List>
      <List component="nav" aria-label="secondary mailbox folders" className="d-flex justify-content-around">
        <button
          type="button"
          onClick={() => goToMap(sale)}
          style={{
            backgroundColor: '#23A1DB', color: 'white', fontSize: 15, padding: '10px 20px', borderRadius: 17, border: 0,
          }}
        >
          Ver en mapa
        </button>
        <a
          href={`tel:${sale.dest_phone}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            backgroundColor: '#23A1DB', color: 'white', fontSize: 15, padding: '10px 20px', borderRadius: 17, border: 0,
          }}
        >
          Llamar
        </a>
        <button
          type="button"
          onClick={() => entregarOrden()}
          style={{
            backgroundColor: '#23A1DB', color: 'white', fontSize: 15, padding: '10px 20px', borderRadius: 17, border: 0,
          }}
        >
          Detalles
        </button>
      </List>

      <ShareModal
        open={openShare}
        title={`Compartir venta ${sale ? sale.entity_label : ''}`}
        handleClose={handleShareClose}
        order={sale || {}}
        template={appModules.SalesOrder}
        isLoading={isLoading}
      />

      <GenericFullModal
        open={openGuide}
        title={`Venta ${sale ? sale.entity_label : ''}`}
        handleClose={handleShareClose}
        componentToShow={<ShippingGuideList itemCrmid={sale.crmid} />}
      />
    </>
  ); };

ShipmentExpand.propType = {
  sale: PropTypes.object.isRequired,
};

export default ShipmentExpand;
