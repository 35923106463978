import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Collapse,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import ScrollPagination from '../../../../../../components/ScrollPaginator/ScrollPaginator';
import Invoice from '../../../../../ClientPage/components/FinanceContainer/components/Invoice';
import {
  fetchListExpensesInvoices,
} from '../../../../../../redux/actions/expensesActions';


const ExpenseInvoiceContainer = ({ crmid }) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchListExpensesInvoices(0, '', crmid));
    // eslint-disable-next-line
  }, []);

  const invoices = useSelector(state => state.gastos.facturasPorProveedor);
  const loads = useSelector(state => state.loads);
  return (
    <>
      { loads.updateIsLoading && (
        <Collapse in={loads.updateIsLoading}>
          <LinearProgress />
        </Collapse>
      )}
      { !loads.updateIsLoading && invoices.data.length === 0 && (
        <Collapse in={!loads.updateIsLoading && invoices.data.length === 0}>
          <List dense>
            <ListItem classes={{ root: 'bg-white' }}>
              <ListItemText
                primary="Sin facturas"
                secondary="El gasto no cuenta con facturas"
              />
            </ListItem>
          </List>
        </Collapse>
      )}

      { !loads.updateIsLoading && (
        <Collapse in={!loads.updateIsLoading}>
          <ScrollPagination
            allItems={invoices.data || []}
            totalPages={invoices.pages}
            itemsPerPage={25}
            currentPage={1}
            childComponent={<Invoice />}
            action={fetchListExpensesInvoices}
            clientId={crmid || ''}
          />
        </Collapse>
      )}
    </>
  );
};

export default ExpenseInvoiceContainer;
