/* eslint-disable no-return-assign */
/* eslint-disable radix */
/* eslint-disable camelcase */
/* eslint-disable import/no-cycle */
import { toast } from 'react-toastify';
import ProductAPI from '../../api/ProductAPI';
import { storageHelper } from '../../helpers';
import { loading, loaded } from './loadActions';
import { addProductToCart } from './cartActions';
import { setGeneralColumnDefs } from './authUserActions';
import { normalizeProducts, getChildrenProducts } from '../../helpers/product';

export const ADD_PRODUCTS = 'ADD_PRODUCTS';
export const CLEAR_MULTIFILTER_PRODUCT = 'CLEAR_MULTIFILTER_PRODUCT';
export const CONFIG_PRODUCT_COLUMN_DEF = 'CONFIG_PRODUCT_COLUMN_DEF';
export const DEFINE_COLUMN_PRODUCT = 'DEFINE_COLUMN_PRODUCT';
export const FAST_OPTIONS_CLEAN = 'FAST_OPTIONS_CLEAN';
export const FAST_SEARCH_OPTIONS = 'FAST_SEARCH_OPTIONS';
export const FETCH_ALL_PRODUCTS = 'FETCH_ALL_PRODUCTS';
export const FILTER_PRODUCTS = 'FILTER_PRODUCTS';
export const GET_COLUMN_DEF_PRODUCT = 'GET_COLUMN_DEF_PRODUCT';
export const GET_NEXT_PAGE_PRODUCTS = 'GET_NEXT_PAGE_PRODUCTS';
export const GET_PRODUCTS_BY_PAGE = 'GET_PRODUCTS_BY_PAGE';
export const GET_SUBPRODUCTS_LIST = 'GET_SUBPRODUCTS_LIST';
export const REMOVE_MULTIFILTER_PRODUCT = 'REMOVE_MULTIFILTER_PRODUCT';
export const RESET_COLUMN_DEF_PRODUCT = 'RESET_COLUMN_DEF_PRODUCT';
export const SET_COLUMN_DEF_PRODUCT = 'SET_COLUMN_DEF_PRODUCT';
export const SET_MULTIFILTER_PRODUCT = 'SET_MULTIFILTER_PRODUCT';
export const SET_QUICK_MULTIFILTER = 'SET_QUICK_MULTIFILTER';
export const UPDATE_PRODUCTS_WITH_CART = 'UPDATE_PRODUCTS_WITH_CART';

export function addProducts(products) {
  return {
    products,
    type: ADD_PRODUCTS,
  };
}


export function getSubProducts(subProducts, crmid, label = '') {
  return {
    type: GET_SUBPRODUCTS_LIST,
    subProducts,
    crmid,
    label,
  };
}

export function getProductsByPage(page = 0) {
  return async (dispatch) => {
    dispatch(loading('product'));
    try {
      await new Promise(resolve => setTimeout(resolve));
      dispatch({
        type: GET_PRODUCTS_BY_PAGE,
        page,
      });
    }

    catch {
      /* Continue regardless of error */
    }

    finally {
      dispatch(loaded('product'));
    }
  };
}

export function getNextProductPage(page = 0) {
  return async (dispatch) => {
    dispatch(loading('product'));
    try {
      await new Promise(resolve => setTimeout(resolve));
      dispatch({
        type: GET_NEXT_PAGE_PRODUCTS,
        page,
      });
    }
    catch {
      /* Continue regardless of error */
    }
    finally {
      dispatch(loaded('product'));
    }
  };
}

export function fetchSubProducts(crmid = null) {
  const productApi = new ProductAPI();
  return async (dispatch, getState) => {
    dispatch(loading('subProducts'));
    try {
      let orderId = '';
      if (crmid) {
        orderId = crmid;
      } else {
        const { product: { productSelected } } = getState();
        orderId = productSelected;
      }
      productApi.getSingle(orderId).then(async (response) => {
        const json = response.result[orderId].Products;
        const label = response.result[orderId].entity_label;
        const arr = Object.entries(json).map(x => x[1]);
        await dispatch(getSubProducts(arr, orderId, label));
        await dispatch(loaded('subProducts'));
      }).catch((err) => {
        const { status } = err;
        if (status === 400) {
          toast.info('No se pudieron actualizar las cantidades de las opciones del producto, esto debido a que la petición no se realizó correctamente');
        } else {
          toast.warn('No se encontraron las opciones del producto');
        }
        dispatch(loaded('subProducts'));
      });
    } catch (err) {
      toast.error('Error desconocido al traer las cantidades de los productos');
      console.log(`fetchSubProducts - err : ${err}`);
      await dispatch(loaded('subProducts'));
    }
  };
}

export function fetchAllProducts(search = '') {
  const productApi = new ProductAPI();
  return async (dispatch, getState) => {
    dispatch(loading('product'));
    dispatch({
      type: FETCH_ALL_PRODUCTS,
    });
    try {
      const { authUser } = getState();
      const { domain } = authUser;
      const { user: { config: { store } } } = authUser;
      const response = await productApi.getAll(search);
      const products = await normalizeProducts(response.result);
      if (response.count <= 4000) {
        const file = { products, store };
        storageHelper.setItem(`products.${domain}`, file, true);
      }
      dispatch(addProducts(products));
    }
    catch {
      dispatch(addProducts([]));
    }

    finally {
      dispatch(loaded('product'));
    }
  };
}

export function updateProductsWithCart() {
  return (dispatch, getState) => {
    const { cart } = getState();
    const cartProductsById = { ...cart.cartProductsById };
    dispatch({
      type: UPDATE_PRODUCTS_WITH_CART,
      cartProductsById,
    });
  };
}

export function setMultiFilterProduct(item) {
  return async (dispatch, getState) => {
    const { product } = getState();
    const { filters } = product;
    try {
      await new Promise(resolve => setTimeout(resolve));
      dispatch({
        type: SET_MULTIFILTER_PRODUCT,
        filters,
        item,
      });
    } catch (err) {
      console.log('setMultiFilter err: ', err);
    }
  };
}

export function removeMultiFilterProduct(word) {
  return async (dispatch, getState) => {
    const { product } = getState();
    const { filters } = product;
    dispatch(loading('product'));
    try {
      await new Promise(resolve => setTimeout(resolve));
      dispatch({
        type: REMOVE_MULTIFILTER_PRODUCT,
        filters,
        word,
      });
    } catch (err) {
      console.log('removeMultiFilter err: ', err);
    } finally {
      dispatch(loaded('product'));
    }
  };
}

export function clearAllFiltersProduct() {
  return async (dispatch) => {
    try {
      await new Promise(resolve => setTimeout(resolve));
      dispatch({
        type: CLEAR_MULTIFILTER_PRODUCT,
      });
    } catch (err) {
      console.log('clearMultiFilter err: ', err);
    }
  };
}

export function filterProducts(word) {
  return async (dispatch, getState) => {
    await dispatch(loading('product'));
    const { tabs } = getState();
    try {
      await new Promise(resolve => setTimeout(resolve));
      switch (tabs.posDisplayTab) {
        case 'grid':
          dispatch({
            type: FILTER_PRODUCTS,
            word,
          });
          break;
        case 'list':
          dispatch(setMultiFilterProduct({ title: 'search', value: word, type: 'search' }));
          break;
        default:
          dispatch({
            type: FILTER_PRODUCTS,
            word,
          });
      }
    }
    catch {
      /* Continue regardless of error */
    }
    finally {
      dispatch(loaded('product'));
    }
  };
}

export function setColumnsDefsProduct(defs) {
  return async (dispatch) => {
    await dispatch({
      type: SET_COLUMN_DEF_PRODUCT,
      defs,
    });
    await dispatch(setGeneralColumnDefs(defs, true));
  };
}

export function resetColumnsDefsProducts() {
  return async (dispatch) => {
    dispatch({ type: RESET_COLUMN_DEF_PRODUCT });
  };
}

export function configProductColumnDef(field) {
  return async (dispatch, getState) => {
    await dispatch({
      type: CONFIG_PRODUCT_COLUMN_DEF,
      field,
    });
    const { product: { columnsDefs } } = getState();
    const defs = columnsDefs;
    await dispatch(setGeneralColumnDefs(defs, true));
  };
}

export function getConfigProductDef() {
  return async (dispatch, getState) => {
    await dispatch({ type: GET_COLUMN_DEF_PRODUCT });
    const { product: { columnsDefs } } = getState();
    const defs = columnsDefs;
    await dispatch(setGeneralColumnDefs(defs, true));
  };
}

export function defineProductColumns(config) {
  return async (dispatch) => {
    await dispatch({ type: DEFINE_COLUMN_PRODUCT, config });
  };
}

export function fastOptionProductSearch(search) {
  return async (dispatch, getState) => {
    await dispatch({ type: FAST_OPTIONS_CLEAN });
    if (search.length > 4) {
      const { product: { productChildrens: { productChildren } } } = getState();
      const { authUser: { user: { config: { post_fast_column } } } } = getState();
      const { field } = post_fast_column;
      const options = getChildrenProducts(productChildren, field, search);
      if (options.length > 0) {
        await dispatch({
          type: FAST_SEARCH_OPTIONS,
          search,
          options,
        });
      }
    }
  };
}

export function selectFastOption(searchQty = 1) {
  return async (dispatch, getState) => {
    const { product: { productOptions } } = getState();
    try {
      if (productOptions.length > 0) {
        await new Promise(resolve => setTimeout(resolve));
        const { parentId, crmid } = productOptions[0];
        const qty = (searchQty === 0 ? 1 : searchQty);
        await dispatch(addProductToCart(parentId, crmid, qty));
      } else {
        const { authUser: { user: { config: { post_fast_column } } } } = getState();
        const { headerName } = post_fast_column;
        toast.warn(`Producto no encontrado, busqueda realizada via: ${headerName}`);
      }
    } catch (err) {
      console.log('selectFastOption err: ', err);
    }
  };
}
