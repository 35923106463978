/* eslint-disable import/no-cycle */
import { connect } from 'react-redux';
import { fetchFde } from '../../../../redux/actions/orderActions';
import { refundAll } from '../../../../redux/actions/sellActions';
import {
  changeFieldValue,
  resetToOrigin,
} from '../../../../redux/actions/tableActions';

import RefundConstructor from './RefundConstructor';

const mapStateToProps = (state) => {
  const {
    cart,
    loads,
    modals,
    order,
    table,
    authUser,
  } = state;
  const { fde } = order;
  const { orderSelected } = cart;
  return ({
    fde,
    loading: Boolean(loads.orderIsLoading || loads.tableIsLoading),
    modal: Boolean(modals.refundModalIsOpen),
    modals: state.modals,
    orderSelected,
    table,
    authUser,
  });
};

const mapDispatchToProps = dispatch => ({
  refundAll: (object, all) => dispatch(refundAll(object, all)),
  dispatchFetchFde: () => dispatch(fetchFde()),
  dispatchResetOrigin: () => dispatch(resetToOrigin()),
  dispatchChangeField: obj => dispatch(changeFieldValue(obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RefundConstructor);
