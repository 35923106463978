/* eslint-disable import/no-cycle */
import { toast } from 'react-toastify';
import ClientAPI from '../../api/ClientAPI';
import AccountAPI from '../../api/AccountAPI';
import { loading, loaded } from './loadActions';
import { selectCustomer } from './customerActions';

import {
  GET_CLIENTS,
  GET_CLIENT_DETAIL,
  GET_CLIENTS_PAGINATOR,
} from '../types/clientActions.type';

export function getClients(clients, pagination) {
  return {
    type: pagination ? GET_CLIENTS_PAGINATOR : GET_CLIENTS,
    payload: clients,
  };
}

export const addClientDetail = selectClient => ({
  type: GET_CLIENT_DETAIL,
  payload: selectClient,
});

export function fetchAllClients(page = 1, search = '', pagination = false) {
  const clientApi = new ClientAPI();
  return async (dispatch) => {
    dispatch(loading('clients'));
    try {
      const response = await clientApi.getAll(page, search);
      if (process.env.NODE_ENV === 'development') {
        console.log('===================fetchAllClients=================');
        console.log({ response });
        console.log('====================================');
      }
      const { success, result, pages } = response;
      if (success) {
        dispatch(getClients({ result, pages }, pagination));
      }
      else {
        toast.error('Error en la api');
      }
    }
    catch (err) {
      toast.error(err);
    }

    finally {
      dispatch(loaded('clients'));
    }
  };
}

export function fetchClientDetail(id) {
  const accountApi = new AccountAPI();
  return async (dispatch) => {
    dispatch(loading('getClient'));
    try {
      const response = await accountApi.getAccountById(id);
      if (process.env.NODE_ENV === 'development') {
        console.log('===================fetchClientDetail from account api=================');
        console.log({ response });
        console.log('====================================');
      }
      const { success, result } = response;
      if (success) {
        dispatch(addClientDetail(result));
        dispatch(selectCustomer(result));
      }
      else {
        toast.error('Error en la api');
      }
    }
    catch (err) {
      toast.error(err);
    }

    finally {
      dispatch(loaded('getClient'));
    }
  };
}
