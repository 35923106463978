import React from 'react';
import PropTypes from 'prop-types';

import {
  Menu,
  List,
  Radio,
  ListItem,
  MenuItem,
  ListItemText,
} from '@material-ui/core';

const BCMenu = ({
  metodo,
  loading,
  selectBC,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  function handleList(e) {
    const { currentTarget } = e;
    setAnchorEl(currentTarget);
  }
  const { method } = metodo;
  const body = [...metodo.body];
  const bodyL = body.length;
  const methodSelected = body.find(x => x.select);
  return (
    <div
      id={`key_method_select_${method}`}
      className="w-100 h-auto"
    >
      <List dense component="nav" aria-label="StoreCatalog-method">
        <ListItem
          dense
          button
          className="pl-4"
          aria-label={method}
          onClick={handleList}
          aria-haspopup="true"
          aria-controls={`selectable_method${method}`}
        >
          <ListItemText
            primary={method}
            secondary={`Seleccionado: ${methodSelected.referencia}`}
          />
        </ListItem>
      </List>
      <Menu
        id={`selectable_method${method}`}
        keepMounted
        className="w-100"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {body.map(cb => (
          <MenuItem
            id={`key_radio_poayment_${cb.crmid}`}
            key={`key_radio_poayment_${cb.crmid}`}
            dense
            data-crmid={cb.crmid}
            data-parent={metodo.method}
            disabled={loading || bodyL === 1}
            onClick={(event) => {
              event.preventDefault();
              const { currentTarget } = event;
              selectBC(currentTarget);
              setAnchorEl(null);
            }}
          >
            <Radio checked={cb.select} />
            <span>{cb.referencia}</span>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

BCMenu.propTypes = {
  loading: PropTypes.bool,
  selectBC: PropTypes.func,
  metodo: PropTypes.object.isRequired,
};

BCMenu.defaultProps = {
  loading: false,
  selectBC: () => {},
};

export default BCMenu;
