/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-cycle */
/* eslint-disable array-callback-return */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import './ShipingAction.styles.scss';
import {
  IconButton,
  LinearProgress,
  Collapse,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDolly,
  faRoute,
  faMapMarkerAlt,
} from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import Avatar from '@material-ui/core/Avatar';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import OrderAPI from '../../../../../../api/OrderAPI';
import ProductAPI from '../../../../../../api/ProductAPI';
import GuideApi from '../../../../../../api/GuideApi';
import { calculateTotals } from '../../../../../../helpers/cart';
import { numberFormat } from '../../../../../../helpers/tools';
import {
  setProductsById, addSelectedGuide, addSelectedPacking,
} from '../../../../../../redux/actions/cartActions';
import { handleEditOrder } from '../../../../../../redux/actions/orderActions';
import { fetchAllOrderGuide } from '../../../../../../redux/actions/guideActions';
import { loading, loaded } from '../../../../../../redux/actions/loadActions';
import Iconwidthbg from '../../../../../Icons/IconWidthBG/IconWidthBG';
import {
  toggleModal,
} from '../../../../../../redux/actions/modalActions';
import { selectCustomer } from '../../../../../../redux/actions/customerActions';

const Shipping = ({
  closeModal,
}) => {
  const ProductApi = new ProductAPI();
  const guideApi = new GuideApi();
  const dispatch = useDispatch();
  const customer = useSelector(state => state.customer);
  const tabs = useSelector(state => state.tabs);
  const cart = useSelector(state => state.cart);
  const user = useSelector(state => state.authUser.user);
  const guide = useSelector(state => state.guide);
  const loads = useSelector(state => state.loads);
  const {
    cartProductsByArray, cartProductsById, total, subTotal, totalWeight, descuentos, orderSelected,
  } = cart;
  const { guides } = guide;
  // eslint-disable-next-line camelcase
  const { shipping_id, config: { store }, almacenes } = user;
  const { posTypeTab } = tabs;
  const { address, selectedCustomer, defaultCustomer } = customer;
  const [open, setOpen] = React.useState(false);
  const [loadin, setLoading] = React.useState(false);
  const [isFull, setIsFull] = React.useState(false);
  const [generate, setGenerate] = React.useState(false);
  const [shippingProviders, setShippingProviders] = React.useState([]);
  const [weight, setWeight] = React.useState(totalWeight);
  const [customProducts, setCustomProducts] = React.useState([]);
  const [stateTotal, setStateTotal] = React.useState(total);
  const [stateSubTotal, setStateSubTotal] = React.useState(subTotal);
  const [stateDiscount, setStateDiscount] = React.useState(descuentos);
  const [shippingSelected, setShippingSelected] = React.useState(null);
  const [storeDirection, setStoreDirection] = React.useState(null);
  const modals = useSelector(state => state.modals);

  // Packing
  const [packingList, setPackingList] = React.useState([]);
  const [selectedPacking, setSelectedPacking] = React.useState(null);
  const [clientAddress, setClientAddress] = React.useState(null);

  const btnsRef = React.useRef(null);

  const selectShippments = (provider) => {
    setShippingSelected(provider);
    // eslint-disable-next-line no-unused-expressions
    btnsRef.current?.scrollIntoView({ behavior: 'smooth' });
  };


  const getOnlyProducts = () => {
    const productsWidthRealQuantity = [];
    let onlyProducts = [];
    const productsInArray = [];
    let qtyMax = 0;
    // eslint-disable-next-line no-restricted-syntax
    for (const key in cartProductsById) {
      if (Object.hasOwnProperty.call(cartProductsById, key)) {
        productsInArray.push(cartProductsById[key]);
      }
    }

    if (orderSelected && Object.keys(orderSelected).length > 0 && orderSelected.constructor === Object) {
      onlyProducts = productsInArray.filter((prod) => {
        // eslint-disable-next-line no-nested-ternary
        const productid = prod.crmid ? prod.crmid : (prod.productid.indexOf('x') !== -1
          ? prod.productid.split('x').pop()
          : prod.productid);

        return productid !== shipping_id;
      });
    } else {
      onlyProducts = [...productsInArray];
    }

    // Guide products array
    let productsInGuide = [];

    if (orderSelected && Object.keys(orderSelected).length > 0 && orderSelected.constructor === Object) {
      const orderGuides = guide.guides;

      orderGuides.map((g) => {
        if (g.status_shipment !== 'Cancelado') {
          const { products } = g;
          productsInGuide = [...productsInGuide, ...products];
        }
      });
    }

    const productQty = {};
    if (productsInGuide.length) {
      productsInGuide.map((p) => {
        productQty[`${p.crmid}`] = (productQty[`${p.crmid}`] || 0) + parseFloat(p.quantity);
      });
    }

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < onlyProducts.length; i++) {
      // eslint-disable-next-line no-nested-ternary
      const productid = onlyProducts[i].crmid ? onlyProducts[i].crmid : (onlyProducts[i].productid.indexOf('x') !== -1
        ? onlyProducts[i].productid.split('x').pop()
        : onlyProducts[i].productid);

      onlyProducts[i].productid = productid;
      const updatedProduct = onlyProducts[i];
      if (productsInGuide.length) {
        updatedProduct.sendQuantity = onlyProducts[i].quantity - (productQty[productid] || 0);
        updatedProduct.sendQuantityMax = onlyProducts[i].quantity - (productQty[productid] || 0);
      } else {
        updatedProduct.sendQuantity = onlyProducts[i].quantity;
        updatedProduct.sendQuantityMax = onlyProducts[i].quantity;
      }
      qtyMax += updatedProduct.sendQuantityMax;
      productsWidthRealQuantity.push(updatedProduct);
    }

    setCustomProducts([...productsWidthRealQuantity]);
    let totalProductsWeight = 0;
    productsWidthRealQuantity.map((prod) => {
      const prodWeight = numberFormat(prod.weight);
      const qty = numberFormat(prod.sendQuantity || 0);
      totalProductsWeight += prodWeight * qty;
    });

    setWeight(totalProductsWeight);
    if (qtyMax === 0 || qtyMax < 0) {
      toast.info('No quedan productos por enviar');
      setIsFull(true);
    }
  };


  const getStoreDirection = async (id) => {
    let storeDirectionId = null;
    if ((!id || id === undefined || id === null) && almacenes.length) {
      const storeIdx = almacenes.findIndex(alm => alm.crmid === store.crmid);
      if (storeIdx !== -1) {
        storeDirectionId = almacenes[storeIdx].treebesdireccionenvioid;
      }
    } else {
      storeDirectionId = id;
    }
    const storeAddress = await guideApi.getObjectById(storeDirectionId);
    setStoreDirection(storeAddress.result);
  };

  const getAllPacking = async () => {
    const packings = await guideApi.getPackingList();
    const { result, success } = packings;
    if (success && result.length > 0) {
      // eslint-disable-next-line no-nested-ternary
      result.sort((a, b) => ((a.peso_maximo > b.peso_maximo) ? 1 : ((b.peso_maximo > a.peso_maximo) ? -1 : 0)));
      const index = result.findIndex(pack => pack.peso_maximo >= (weight || totalWeight));
      if (index !== -1) {
        setSelectedPacking(result[index]);
        dispatch(addSelectedPacking(result[index]));
      } else {
        setSelectedPacking(result[result.length - 1]);
        dispatch(addSelectedPacking(result[result.length - 1]));
      }
      setPackingList(result);
    } else {
      toast.info('No tienes empaques para cotizar envios');
    }
  };

  const fetchAllGuides = async (id) => {
    await dispatch(fetchAllOrderGuide(orderSelected.order.crmid));
    getOnlyProducts();
  };

  const handleClose = () => {
    setShippingProviders([]);
    setShippingSelected(null);
    setGenerate(false);
    setLoading(false);
    closeModal();
  };


  const updateClientAddress = async () => {
    const newAddress = address.find(
      add => add.crmid === selectedCustomer.treebesdireccionenvioid,
    );
    if (newAddress) {
      await setClientAddress(newAddress);
    } else {
      await setClientAddress(null);
    }

  };


  const getClientAddress = async () => {
    updateClientAddress();
  };


  React.useEffect(() => {
    if (orderSelected && Object.keys(orderSelected).length > 0 && orderSelected.constructor === Object) {
      fetchAllGuides();
    } else {
      getOnlyProducts();
    }

    getStoreDirection(store.direccionid);
    getAllPacking();
    getClientAddress();
    // eslint-disable-next-line
  }, [almacenes, store]);

  React.useEffect(() => {
    // setWeight(totalWeight);
    setStateTotal(total);
    setStateSubTotal(subTotal);
    setStateDiscount(descuentos);
    // eslint-disable-next-line
  }, [totalWeight, cartProductsByArray,]);


  const formaDelivery = (delivery) => {
    let providerData = [];
    const deliveryType = Object.values(delivery);
    if (deliveryType.length) {
      deliveryType.map((provider) => {
        Object.values(provider).map((item) => {
          if (item) {
            providerData = [...providerData, ...Object.values(item)];
          }
        });
      });
    } else {
      console.log('delivery empty');
    }
    setShippingProviders([...providerData]);
  };

  const shippingCuots = async () => {
    if (isFull) {
      return;
    }
    setLoading(true);
    const prods = [];
    const orderAPI = new OrderAPI();
    customProducts.map((prod) => {
      // eslint-disable-next-line no-nested-ternary
      const productid = prod.crmid
        ? prod.crmid
        : prod.productid.indexOf('x') !== -1
          ? prod.productid.split('x').pop()
          : prod.productid;

      if (prod.sendQuantity > 0) {
        prods.push({
          productid,
          productname: prod.productname,
          sku: prod.sku,
          length: parseFloat(prod.length) || 0,
          height: parseFloat(prod.height) || 0,
          width: parseFloat(prod.width) || 0,
          weight: parseFloat(prod.weight) || 0,
          quantity: prod.sendQuantity,
          requires_shipping: '1',
          treebes_sat_prod: prod.treebes_sat_prod,
        });
      }
    });

    if (selectedCustomer) {
      const data = {
        source: 'TPV',
        accountid: selectedCustomer.crmid,
        treebesalmid: store.crmid,
        products: [...prods],
        totals: {
          subtotal: stateSubTotal,
          total: stateTotal,
          discount: descuentos,
          total_fde: stateTotal,
          weight,
        },
      };

      data.address = {
        treebesdireccionesid: selectedCustomer.treebesdireccionenvioid || '',
        direccion: selectedCustomer.address || '',
        relatedtoid: selectedCustomer.crmid || '',
        neighborhood: selectedCustomer.bill_pobox || '',
        country: selectedCustomer.bill_country || '',
        state: selectedCustomer.bill_state || '',
        city: selectedCustomer.bill_city || '',
        postcode: selectedCustomer.bill_code || '',
        num_ext: selectedCustomer.bill_num_ext || '',
        num_int: selectedCustomer.bill_num_int || '',
        dir_references: selectedCustomer.dir_references || '',
        latitude: '',
        longitude: '',
        countrycode: selectedCustomer.countrycode || '',
        defaultaddress: selectedCustomer.address || '',
        phone: selectedCustomer.phone,
        accountname: selectedCustomer.accountname,
        countryCode: 'MX',
      };

      const { success, result } = await orderAPI.shippingCuots(data);
      if (success) {
        // Change when pass alex pr
        setShippingProviders(result);
      } else {
        toast.error('Error obteniendo los servicios de envío');
        handleClose();
      }
    } else {
      toast.warn(
        'Debes seleccionar un cliente',
      );
      // handleClose();
    }
    setLoading(false);
  };

  // Calculate total wi
  const recalculateTotalWeight = async (productId, quantity) => {
    const editedProducts = [...customProducts];
    const index = customProducts.findIndex(prod => prod.productid === productId);
    editedProducts[index].sendQuantity = parseFloat(quantity);
    await setCustomProducts(editedProducts);
    let totalProductsWeight = 0;
    editedProducts.map((prod) => {
      const prodWeight = numberFormat(prod.weight);
      const qty = numberFormat(prod.sendQuantity || 0);

      totalProductsWeight += prodWeight * qty;
    });

    setWeight(totalProductsWeight);

    if (totalProductsWeight > 0) {
      setIsFull(false);
    } else {
      setIsFull(true);
    }

    const totals = calculateTotals(customProducts);
    // setWeight(totals.totalWeight);
    setStateTotal(totals.total);
    setStateSubTotal(totals.subTotal);
    setStateDiscount(totals.discountAmount);
    setShippingProviders([]);
  };

  // Create send request for selected order
  const handleCreateShipmentRequest = async () => {
    dispatch(loading('guide'));
    const { order } = orderSelected;
    const response = await guideApi.create(order, shippingSelected, customer, selectedPacking);
    const { success, result } = response;
    if (success) {
      await guideApi.createProductRequest(result.crmid, store.direccionid, customProducts);
      await dispatch(fetchAllOrderGuide(order.crmid));
      toast.info('Solicitud de envio creada');
    }
    dispatch(loaded('guide'));
  };

  const setShippingService = async () => {
    setGenerate(true);
    if (orderSelected && Object.keys(orderSelected).length > 0 && orderSelected.constructor === Object) {
      handleCreateShipmentRequest();
    } else {
      const service = await ProductApi.get(shipping_id);
      const { result, success } = service;
      // Set selected pack service
      dispatch(addSelectedGuide(shippingSelected));
      result.unit_price = parseFloat(shippingSelected.total_amount);
      result.quantity = 1;
      result.totalPrice = parseFloat(shippingSelected.total_amount);
      result.opcion = shippingSelected.carrier;
      result.f_limite_embarque = shippingSelected.packageDate;
      result.f_entrega_estimada = shippingSelected.estimated_delivery;
      if (success && shippingSelected !== null) {
        await dispatch(setProductsById(result));
        if (posTypeTab !== 'Vender') {
          await dispatch(handleEditOrder());
        }
      }
    }
    setGenerate(false);
    handleClose();
  };

  const handleChange = (event) => {
    const selectPack = packingList.find(pack => pack.crmid === event.target.value);
    setSelectedPacking(selectPack);
    dispatch(addSelectedPacking(selectPack));
  };

  const handleChangeQuantity = (event) => {
    const { dataset, value } = event.target;
    recalculateTotalWeight(dataset.productid, value);
  };


  React.useEffect(() => {
    if (!isFull || storeDirection !== null || clientAddress !== null || selectedPacking !== null) {
      shippingCuots();
    }

    // eslint-disable-next-line
  }, [customer, modals.shippingModalIsOpen, isFull, storeDirection, clientAddress , selectedPacking]);


  return (
    <>
      <AppBar style={{ position: 'relative' }}>
        <Toolbar classes={{ root: 'd-flex justify-content-between' }}>
          <p />
          <Typography variant="h6" style={{ color: '#FFF' }}>
            Cotizar envíos
          </Typography>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Collapse in={loads.guideIsLoading}>
        <LinearProgress />
      </Collapse>
      <div className="mx-3 my-2">
        <p className="d-flex align-items-center my-3" style={{ fontSize: 14 }}>
          <Iconwidthbg
            primaryColor="#23a1db"
            secondaryColor="white"
            width={27}
            height={27}
            enabled
            square
            rounded
            actionClick={() => {}}
          >
            <FontAwesomeIcon style={{ fontSize: 15 }} icon={faDolly} />
          </Iconwidthbg>
          <strong className="mx-2">Almacén: </strong>
          <span>{store.name}</span>
        </p>
        <p className="d-flex align-items-center my-3" style={{ fontSize: 14 }}>
          <Iconwidthbg
            primaryColor="#23a1db"
            secondaryColor="white"
            width={27}
            height={27}
            enabled
            square
            rounded
            actionClick={() => {}}
          >
            <FontAwesomeIcon style={{ fontSize: 15 }} icon={faMapMarkerAlt} />
          </Iconwidthbg>
          <strong className="mx-2">Origen: </strong>
          {
            storeDirection?.direccion
              ? (
                <span>{`${storeDirection?.direccion || ''}, #${storeDirection?.num_ext || ''}, ${storeDirection?.neighborhood || ''}, C.P.${storeDirection?.postcode || ''}, ${storeDirection?.city || ''}, ${storeDirection?.state || ''}, ${storeDirection?.country || ''}`}</span>
              )
              : (
                <span>Necesitas una dirección</span>
            )
          }
        </p>
        <p className="d-flex align-items-center my-3" style={{ fontSize: 14 }}>
          <Iconwidthbg
            primaryColor="#23a1db"
            secondaryColor="white"
            width={27}
            height={27}
            enabled
            square
            rounded
            actionClick={() => {}}
          >
            <FontAwesomeIcon style={{ fontSize: 15 }} icon={faRoute} />
          </Iconwidthbg>
          <strong className="mx-2">Destino: </strong>
          <span>{clientAddress ? `${clientAddress?.direccion}, #${clientAddress?.num_ext}, ${clientAddress?.neighborhood}, C.P ${clientAddress?.postcode}, ${clientAddress?.city}, ${clientAddress?.state}, ${clientAddress?.country}` : selectedCustomer.address}</span>
          <span className="ml-2">
            <Button
              type="button"
              size="small"
              variant="outlined"
              disabled={selectedCustomer.crmid === defaultCustomer.crmid}
              onClick={async () => {
                await dispatch(selectCustomer(selectedCustomer, false));
                await dispatch(toggleModal('customerEdit'));
              }}
            >
              Cambiar dirección
            </Button>
          </span>
        </p>
      </div>
      <div className="packing-list mx-3 mb-2 d-flex align-items-center">
        <p>
          <strong className="mr-2">Empaques: </strong>
        </p>
        <select className="packing-input" id="packing" style={{ minWidth: 100 }} onChange={handleChange}>
          {
            packingList.length > 0 && packingList.map(pack => (
              <option selected={selectedPacking?.crmid === pack.crmid} value={pack.crmid}>{pack.nombreinterno}</option>
            ))
          }
        </select>
      </div>
      <div className="d-flex justify-content-start align-items-center mb-4" style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
        <span className="mr-2">
          <b>
            Largo:
          </b>
          {` ${selectedPacking?.largo}`}
          {' '}
          cm
        </span>
        <span className="mr-2">
          <b>
            Alto:
          </b>
          {` ${selectedPacking?.alto}`}
          {' '}
          cm
        </span>
        <span className="mr-2">
          <b>
            Ancho:
          </b>
          {` ${selectedPacking?.ancho}`}
          {' '}
          cm
        </span>
        <span>
          <b>
            Peso:
          </b>
          {` ${selectedPacking?.peso_maximo}`}
          {' '}
          kg
        </span>
      </div>
      <div className="d-flex justify-content-center align-items-center" style={{ width: '100%' }}>
        <div className="mx-2 w-100">
          <List subheader={(
            <ListSubheader component="div" id="nested-list-subheader">
              Productos
            </ListSubheader>
            )}
          >
            {customProducts.map(product => (
              <ListItem divider>
                <ListItemAvatar>
                  <Avatar
                    classes={{ root: 'avatar-container' }}
                    className="avatar-square"
                    variant="square"
                    alt="Imagen producto"
                    src={(product.images && product.images.length) ? product.images[0] : product.image}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={product.productname}
                  secondary={(
                    <div className="w-100 d-flex justify-content-between align-items-center">
                      <span>
                        Cantidad:
                        {` ${product.sendQuantityMax}`}
                      </span>
                      <span>
                        Cantidad a enviar:
                        {
                          (orderSelected && Object.keys(orderSelected).length > 0 && orderSelected.constructor === Object)
                            ? (
                              <input
                                className="qty-input"
                                id={`key_${product.crmid}`}
                                type="number"
                                min="0"
                                max={product.sendQuantityMax}
                                data-id={product.crmid}
                                defaultValue={product.sendQuantity}
                                data-productid={product.productid}
                                form="novalidatedform"
                                onChange={handleChangeQuantity}
                              />
                            )

                            : product.quantity

                        }

                      </span>
                    </div>
              )}
                />
              </ListItem>
            ))}
          </List>

          <div className="d-flex justify-content-end align-items-center my-2">
            <span>
              Peso
              {' '}
              {numberFormat(weight) }
              {' '}
              KG
            </span>
          </div>
          {
                !!(shippingProviders && shippingProviders.length) && (
                  <div className="my-2 w-100">
                    <h3>Cotización de envíos</h3>
                    <List>
                      {shippingProviders.map(provider => (
                        <ListItem onClick={() => selectShippments(provider)} style={shippingSelected && shippingSelected.carrier_service_code === provider.carrier_service_code ? { backgroundColor: '#dee2e6' } : {}}>
                          <ListItemText
                            primary={(
                              <div className="w-100 d-flex justify-content-start align-items-center">
                                {
                                  (provider.carrier_logo_url !== null && provider.carrier_logo_url !== '') && (
                                    <Avatar
                                      className="mr-3"
                                      classes={{ img: 'img-responsive' }}
                                      variant="square"
                                      style={{
                                        borderRadius: 'none', backgroundColor: 'transparent', width: 80, height: 60,
                                      }}
                                      src={provider.carrier_logo_url}
                                      sizes="medium"
                                    />
                                  )
                                }
                                <span>{provider.carrier}</span>
                              </div>
                            )}
                            secondary={(
                              <div className="w-100 d-flex justify-content-between align-items-center">
                                <span>
                                  <strong className="mr-2">Costo: </strong>
                                  $
                                  {' '}
                                  { provider.total_amount }
                                </span>
                                <span>
                                  <strong className="mr-2">Fecha estimada: </strong>
                                  {moment(provider.estimated_delivery).format('DD MMMM YYYY')}
                                </span>
                              </div>
                        )}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </div>
                )
              }
          <div ref={btnsRef} className="action-btn d-flex justify-content-end align-items-center my-2">

            <div clasname="mx-2">
              {
                loadin ? (<CircularProgress />) : (
                  <Button
                    variant="contained"
                    disabled={
                    isFull
                    || storeDirection === null
                    || selectedPacking === null
                  }
                    className="mr-2"
                    onClick={() => shippingCuots()}
                  >
                    Cotizar envios
                  </Button>
                )}
            </div>
            <div className="mx-2">
              {
                generate ? (<CircularProgress />) : (<Button disabled={shippingSelected === null} variant="contained" color="primary" onClick={() => setShippingService()}>Generar</Button>)
              }
            </div>
          </div>
        </div>

      </div>
    </>
  ); };

Shipping.propTypes = {
  closeModal: PropTypes.func,
};
Shipping.defaultProps = {
  closeModal: () => {},
};

export default Shipping;
