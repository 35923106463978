import React from 'react';
import PropTypes from 'prop-types';
import { Divider } from '@material-ui/core';
/* Components */
import Uploader from '../components/Uploader/Uploader';
import ListFiles from '../components/ListFiles';
import Pagination from '../components/Pagination';

const FileList = ({
  download,
  files,
  getFiles,
  isOpen,
  loading,
  remove,
  upload,
}) => {
  React.useEffect(() => {
    if (isOpen) {
      getFiles(1, '');
    }
    // eslint-disable-next-line
  }, [isOpen]);
  return (
    <div>
      <div className="py-2">
        <div className="px-3 py-2">
          <Uploader upload={upload} />
        </div>
        <Divider className="my-1" />
        <ListFiles
          files={files.result}
          loading={loading}
          download={download}
          count={files.count}
          remove={remove}
        />
      </div>
      {files.count > 100 && (
        <div>
          <Pagination files={files} />
        </div>
      )}
    </div>
  );
};

FileList.propTypes = {
  download: PropTypes.func.isRequired,
  files: PropTypes.object.isRequired,
  getFiles: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  remove: PropTypes.func.isRequired,
  upload: PropTypes.func.isRequired,
};

FileList.defaultProps = {};

export default FileList;
