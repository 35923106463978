export const componentTypes = {
  search: 'search',
  order: 'order',
};

export const actionTypes = {
  buscar: 'buscar',
  filtrar: 'filtrar',
  ordenar: 'ordenar',
};


export const defaultSearchData = [
  'RFC',
  'Nombre',
  'Cuenta',
  'Apellido',
  'Razón Social',
];

export const defaultOrderData = [
  'Mas nueva',
  'Mas antigua',
  'Alfabético A-Z',
  'Alfabético Z-A',
];

export const defaultTaskStatusData = [
  'ASC',
  'DESC',
];


export const categoriesDefault = [
  {
    name: componentTypes.search,
    values: defaultSearchData,
  },
  {
    name: componentTypes.order,
    values: defaultOrderData,
  },
];
