import { faSlidersH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  List, ListItem, ListItemText, Popover,
} from '@material-ui/core';
import React from 'react';
import './taskFilter.styles.scss';
import { useSelector, useDispatch } from 'react-redux';
import TaskStatusFilter from './components/TaskStatusFilter';
import TaskProjectFilter from './components/TaskProjectFilter';
import { fetchAllProject, fetchAllUsers } from '../../../../redux/actions/taskActions';
import TaskUserFilter from './components/TaskUserFilter';

function TaskFilter() {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openCollapse, setOpenCollapse] = React.useState('');
  const selectedFilter = useSelector(state => state.tasks.statusFilter);
  const projectFilter = useSelector(state => state.tasks.projectFilter);
  const userFilter = useSelector(state => state.tasks.userFilter);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const setCollapse = (item) => {
    if (openCollapse === item) {
      setOpenCollapse('');
    } else {
      setOpenCollapse(item);
    }
  };

  React.useEffect(() => {
    dispatch(fetchAllProject());
    dispatch(fetchAllUsers());
  }, []);

  return (
    <div className="filter-container">
      <div className={(selectedFilter.length || projectFilter.length || userFilter.length) ? 'active-filters' : ''} />
      <FontAwesomeIcon style={{ fontSize: 26 }} icon={faSlidersH} aria-describedby={id} onClick={handleClick} />
      <Popover
        id={id}
        open={open}
        classes={{ paper: 'pop-container' }}
        anchorEl={anchorEl}
        onClose={() => handleClose()}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <List component="nav" classes={{ root: 'filter-list' }}>
          <ListItem divider>
            <ListItemText primary="Filtrar por: " classes={{ primary: 'filter-title' }} />
          </ListItem>
        </List>
        <TaskStatusFilter openComponent={openCollapse} handleClickOpenComponent={setCollapse} selectedFilters={selectedFilter} />
        <TaskProjectFilter openComponent={openCollapse} handleClickOpenComponent={setCollapse} selectedProjects={projectFilter} />
        <TaskUserFilter openComponent={openCollapse} handleClickOpenComponent={setCollapse} selectedUsers={userFilter} />
      </Popover>
    </div>
  );
}

export default TaskFilter;
