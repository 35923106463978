/* eslint-disable camelcase */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import {
  Slide,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
} from '@material-ui/core';
import { faComment } from '@fortawesome/fontawesome-free-regular';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import Comment from '../../Comment';
import Customer from '../../Customer';
import CartMobileOptions from './CartMobileOptions';
import SearchCustomer from '../../Customer/components/SearchCustomer';
import DetailInfo from '../../Order/mobile/components/OrderDetail/components/DetailInfo';
import DetailFooter from '../../Order/mobile/components/OrderDetail/components/DetailFooter';
import { setDiscountTaxes } from '../../../redux/actions/filtersActions';
import ShareUrl from '../../ShareLinks/ShareUrl';
import {
  setActivePromotions, desactivePromotions, addProductToCart, resetPromotions, updatePromotions,
} from '../../../redux/actions/cartActions';
import Shipping from '../components/controls/components/shipping/ShippingAction';
import EditCustomer from './EditCustomer';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const menuListValue = {
  color: '#23A1DB',
  fontSize: '14px',
};
const singleValue = {
  color: '#23A1DB',
  fontSize: '15px',
};
const controlProps = {
  width: '100%',
  height: '48px',
  borderRadius: '17px',
  border: 'none',
  fontSize: '16px',
};
const containerProps = {
  width: '100%',
  height: '48px',
  borderRadius: '17px',
  border: 'none',
};

const CartMobile = ({
  authUser,
  cart,
  clearCart,
  history,
  loading,
  makeSell,
  makeQuote,
  sync,
}) => {
  const [comment, setcomment] = React.useState(false);
  const [link, setLink] = React.useState(false);
  const [opt, setOpt] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const toggle = () => setOpt(!opt);
  // eslint-disable-next-line camelcase
  const {
    user: {
      config: { sostatus, pos_promo_cart, store }, sin_impuestos: { SalesOrder }, almacenes, shipping_id,
    },
  } = authUser;
  const {
    products, totalProducts, orderSelected, activePromotions,
  } = cart;
  const getShippingProduct = products.find(prod => prod.crmid === shipping_id);
  const { cartProductsById } = useSelector(state => state.cart);
  const discountTaxes = useSelector(state => state.filters.discountTaxes);
  const modals = useSelector(state => state.modals);
  const dispatch = useDispatch();

  const toggleTaxesDiscount = () => {
    dispatch(clearCart());
    Object.values(cartProductsById).map(product => dispatch(addProductToCart(product.parentId, product.crmid, product.quantity)));
  };

  const togglepromotions = async () => {
    if (activePromotions) {
      await dispatch(desactivePromotions());
      await dispatch(resetPromotions());
    } else {
      await dispatch(setActivePromotions());
    }
    await dispatch(updatePromotions());
    toggle();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const necesitaEnvio = () => {
    const selectedStore = almacenes.length > 0 ? almacenes.find(almacen => almacen.crmid === store.crmid) : null;
    if (selectedStore && selectedStore.pos_forzaenvio === '1' && !getShippingProduct) {
      return true;
    }
    return false;
  };

  React.useEffect(() => {
    // eslint-disable-next-line camelcase
    if (products.length === 0 && Object.entries(orderSelected).length === 0 && pos_promo_cart === '1') {
      dispatch(setActivePromotions());
    }
    if (!products.length) {
      dispatch(desactivePromotions());
    }
    // eslint-disable-next-line
  }, [products, orderSelected]);

  const options = [
    {
      icon: 'save',
      title: 'Generar Orden',
      onClick: () => {
        toggle();
        /* if (necesitaEnvio()) {
          toast.error('Necesitas seleccionar un servicio de envío para completar la acción');
        } else {
        } */
        if (store.forzaEnvio === '1') {
          const shippingIsSelected = cart.products.findIndex(prod => prod.crmid === shipping_id);
          if (shippingIsSelected === -1) {
            toast.info('Debes seleccionar un envío  antes de cobrar');
            // dispatch(openModal('shipping'));
            return;
          }
        }
        makeSell(sostatus, history);
      },
      disabled: Boolean(totalProducts < 1 && !loading),
    },
    {
      icon: 'file-invoice-dollar',
      title: 'Generar Cotización',
      onClick: () => {
        toggle();
        if (store.forzaEnvio === '1') {
          const shippingIsSelected = cart.products.findIndex(prod => prod.crmid === shipping_id);
          if (shippingIsSelected === -1) {
            toast.info('Debes seleccionar un envío  antes de cobrar');
            // dispatch(openModal('shipping'));
            return;
          }
        }
        makeQuote(history);
      },
      disabled: Boolean(totalProducts < 1 && !loading),
    },
    {
      icon: 'sync',
      title: 'Sincronizar carrito',
      onClick: () => {
        toggle();
        sync();
      },
      disabled: Boolean(totalProducts < 1 && !loading),
    },
    {
      icon: faComment,
      title: 'Descripción',
      onClick: () => {
        toggle();
        setcomment(!comment);
      },
    },
    {
      icon: 'trash-alt',
      title: 'Limpiar carrito',
      onClick: () => {
        toggle();
        clearCart();
      },
    },
    {
      icon: 'link',
      title: 'Enlace',
      onClick: () => {
        toggle();
        setLink(true);
      },
    },
    {
      icon: discountTaxes ? 'check-square' : 'square',
      title: 'Sin impuestos',
      onClick: () => {
        toggle();
        dispatch(setDiscountTaxes());
        toggleTaxesDiscount();
      },
      disabled: !SalesOrder,
    },
    {
      icon: 'certificate',
      title: activePromotions ? 'Promociones activas' : 'Promociones desactivadas',
      onClick: () => {
        toggle();
        togglepromotions();
      },
    },
    {
      icon: 'truck',
      title: 'Envíos',
      onClick: () => {
        toggle();
        handleClickOpen();
      },
    },
  ];

  return (
    <Dialog
      open
      fullScreen
      keepMounted
      TransitionComponent={Transition}
      onClose={() => history.goBack()}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      style={{ backgroundColor: '#EFEFF4' }}
    >
      <AppBar
        className="modalDetail_appBar"
        style={{ backgroundColor: '#FFFFFF' }}
        position="static"
      >
        <Toolbar className="d-flex px-0" classes={{ root: 'toolbar' }}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => history.goBack()}
            aria-label="close"
            style={{ width: '48px', height: '48px' }}
          >
            <FontAwesomeIcon color="#313C52" icon="chevron-left" />
          </IconButton>
          <SearchCustomer
            single={{ ...singleValue }}
            control={{ ...controlProps }}
            container={{ ...containerProps }}
            menuList={{ ...menuListValue }}
            orderSelected={{}}
            dispatchEditOrder={() => {}}
          />
          <Customer />
          <IconButton
            edge="start"
            color="inherit"
            aria-label="close"
            style={{ width: '48px', height: '48px' }}
            onClick={() => toggle()}
          >
            <FontAwesomeIcon color="#313C52" icon="ellipsis-v" />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DetailInfo showCustomer={false} showTitle={false} />
      <DetailFooter showDeliver={false} />
      <CartMobileOptions
        opt={opt}
        toggle={toggle}
        options={options}
      />
      <Comment
        isOpen={comment}
        close={() => setcomment(!comment)}
      />
      <ShareUrl isOpen={link} close={() => setLink(!link)} />
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <Shipping closeModal={() => handleClose()} />
      </Dialog>
      <Dialog fullScreen open={Boolean(modals.customerEditModalIsOpen)} onClose={handleClose} TransitionComponent={Transition}>
        <EditCustomer />
      </Dialog>
    </Dialog>
  );
};

CartMobile.propTypes = {
  authUser: PropTypes.object,
  cart: PropTypes.object,
  clearCart: PropTypes.func,
  history: PropTypes.object,
  loading: PropTypes.bool,
  makeSell: PropTypes.func,
  makeQuote: PropTypes.func,
  sync: PropTypes.func,
};

CartMobile.defaultProps = {
  authUser: {},
  cart: {},
  clearCart: () => {},
  history: {},
  loading: false,
  makeSell: () => {},
  makeQuote: () => {},
  sync: () => {},
};

export default withRouter(CartMobile);
