import fetch from 'cross-fetch';
import ConfigurationAPI from './ConfigurationAPI';

class CommentsAPI extends ConfigurationAPI {
  getAllComments = async (module = 'ModComments', relatedTo = 0) => {
    try {
      const response = await fetch(`${this.url}/${module}?related_to=${relatedTo}`, this.credentials);
      const json = await response.json();
      return json;
    } catch (e) {
      return { success: false, message: 'Error en el Host' };
    }
  };

  addComment = async (module = 'ModComments', relatedTo = 0, comment) => {
    this.credentials.method = 'POST';
    this.credentials.body = JSON.stringify(comment);

    try {
      const response = await fetch(`${this.url}/${module}?related_to=${relatedTo}`, this.credentials);
      const json = await response.json();
      return { ...json };
    }

    catch {
      return { succes: false, result: [], message: 'Error en la API' };
    }
  };

  editComment = async (module = 'ModComments', relatedTo = 0, comment) => {
    this.credentials.method = 'PUT';
    this.credentials.body = JSON.stringify(comment);

    try {
      const response = await fetch(`${this.url}/${module}?related_to=${relatedTo}`, this.credentials);
      const json = await response.json();
      return { ...json };
    }

    catch {
      return { succes: false, result: [], message: 'Error en la API' };
    }
  };
}

export default CommentsAPI;
