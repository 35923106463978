import React from 'react';
import './full-page-dialog.scss';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import EditExpenseForm from './components/EditExpenseForm';
import ExpensesApi from '../../../../api/ExpensesApi';
import { fetchSelectedAlmacen, fetchSelectedExpenseType, fetchVendor } from '../../../../redux/actions/expensesActions';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);


const EditExpenseDialog = ({
  open, onClose, id, gasto,
}) => {
  const dispatch = useDispatch();
  const [schema, setSchema] = React.useState(null);
  const expensesApi = new ExpensesApi();
  // Obteniendo campos para editar gasto
  const getShema = async (ids) => {
    const response = await expensesApi.getEditShemaExpense(ids);
    const { success, result } = response;
    if (success) {
      setSchema(result[0].fields);
    }
  };

  // Obteniendo almacen asociado al gasto a editar
  const getAlmacen = async (ids) => {
    // El metodo getExpense se le pasa un crmid y te devuelve ya sea el almacen, gasto, vendedor ....
    const response = await expensesApi.getExpense(ids);
    const { success, result } = response;
    if (success) {
      dispatch(fetchSelectedAlmacen(result));
    }
  };

  // Obteniendo proveedor asociado al gasto a editar
  const getProveedor = async (ids) => {
    // El metodo getExpense se le pasa un crmid y te devuelve ya sea el almacen, gasto, vendedor ....
    const response = await expensesApi.getExpense(ids);
    const { success, result } = response;
    if (success) {
      dispatch(fetchVendor(result));
    }
  };

  // Obteniendo tipo de gasto asociado al gasto a editar
  const getTipoGasto = async (ids) => {
    // El metodo getExpense se le pasa un crmid y te devuelve ya sea el almacen, gasto, vendedor ....
    const response = await expensesApi.getExpense(ids);
    const { success, result } = response;
    if (success) {
      dispatch(fetchSelectedExpenseType(result));
    }
  };

  React.useEffect(() => {
    getShema(id);
    if (gasto !== null) {
      getAlmacen(gasto.treebesalmid);
      getProveedor(gasto.vendorid);
      getTipoGasto(gasto.treebestdgid);
    }
    // eslint-disable-next-line
  }, [id, gasto]);

  return (
    <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition} classes={{ paperFullScreen: 'dialog-bg' }}>
      <AppBar className="appBar" color="inherit" style={{ position: 'relative', boxShadow: 'none' }}>
        <Toolbar variant="dense" disableGutters elevation={0}>
          <IconButton edge="start" onClick={onClose} aria-label="close">
            <FontAwesomeIcon style={{ fontSize: 16 }} icon={faChevronLeft} />
          </IconButton>
          <Typography variant="h6" className="toolbar-title title-type">
              Editar Gasto
          </Typography>
        </Toolbar>
      </AppBar>
      <EditExpenseForm schema={schema} gasto={gasto} />
    </Dialog>

  ); };

EditExpenseDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  gasto: PropTypes.object.isRequired,
};


export default EditExpenseDialog;
