/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSlidersH,
} from '@fortawesome/free-solid-svg-icons';
import Filter from '../../../../components/Filter';


import SearchFab from './components/SearchFab';

const HeaderTop = (props) => {
  const { history, dispatch } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;


  return (
    <div
      className="main_actions_container w-100 d-flex align-items-center px-4 sticky-top"
    >
      <SearchFab dispatch={dispatch} history={history} />
      <FontAwesomeIcon style={{ fontSize: 26 }} icon={faSlidersH} aria-describedby={id} onClick={handleClick} />

      <Filter
        id={id}
        open={open}
        anchorEl={anchorEl}
        close={() => handleClose}
        categories={[
          {
            name: 'search',
          }, {
            name: 'order',
          },
        ]}
      />
    </div>
  ); };

HeaderTop.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default HeaderTop;
