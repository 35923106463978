import React from 'react';
import PropTypes from 'prop-types';
import {
  List,
  Checkbox,
  Collapse,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import CCDetalle from '../detalle';
import { formatMoney } from '../../../../../../helpers/tools';

const CCMovimientoItem = ({
  cantidad,
  click,
  countCheck,
  detalles,
  enable,
  id,
  label,
  totalCheck,
  type,
}) => {
  const [open, setOpen] = React.useState(false);
  return (
    <List key={id}>
      <ListItem
        button
        className="detalle_box detalle_box_divider"
        onClick={() => setOpen(!open)}
      >
        {open ? (<ExpandLess />) : (<ExpandMore />)}
        <ListItemText
          primary={(
            <>
              <Typography component="span" variant="body2" color="textPrimary">
                <div className="balance-detalles__flex-space">
                  <strong>{label}</strong>
                  <span className="detalle_cantidad">{`${countCheck} / ${cantidad}`}</span>
                  <strong>{`$ ${formatMoney(totalCheck)}`}</strong>
                </div>
              </Typography>
            </>
          )}
        />
        <Checkbox
          className="balance-detalles__no-padding-lr"
          checked={Boolean(enable)}
          data-id={id}
          data-kind={type}
          onClick={(e) => {
            const { currentTarget } = e;
            click(currentTarget);
            e.preventDefault();
            e.stopPropagation();
          }}
        />
      </ListItem>
      <Collapse in={open} timeout="auto" className="detalle_box-wrapper">
        <CCDetalle
          detalles={detalles}
          click={click}
        />
      </Collapse>
    </List>
  );
};

CCMovimientoItem.propTypes = {
  cantidad: PropTypes.number,
  click: PropTypes.func.isRequired,
  countCheck: PropTypes.number,
  detalles: PropTypes.array,
  enable: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  totalCheck: PropTypes.number,
  type: PropTypes.string,
};

CCMovimientoItem.defaultProps = {
  cantidad: 0,
  countCheck: 0,
  detalles: [],
  enable: false,
  label: '',
  totalCheck: 0,
  type: '',
};

export default CCMovimientoItem;
