import { connect } from 'react-redux';
import { fetchListVendors, fetchListExpensesType, fetchListAlmacen } from '../../redux/actions/expensesActions';
import Search from './constructor/Search';
import { fetchAllProject, fetchAllUsers } from '../../redux/actions/taskActions';

const mapStateToProps = (state) => {
  const {
    loads, gastos,
  } = state;
  const { gastoSeleccionado } = gastos;
  return ({
    loading: Boolean(loads.orderIsLoading),
    gasto: gastoSeleccionado,
  });
};

const mapDispatchToProps = dispatch => ({
  dispatchFetchListVendors: () => dispatch(fetchListVendors()),
  dispatchFetchListExpensesType: () => dispatch(fetchListExpensesType()),
  dispatchFetchListAlmacen: () => dispatch(fetchListAlmacen()),
  dispatchFetchListProjects: () => dispatch(fetchAllProject()),
  dispatchFetchListUser: () => dispatch(fetchAllUsers()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Search);
