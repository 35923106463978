/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
/* eslint-disable radix */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { LinearProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';
import FullDialog from '../../../FullDialog';
import BalanceCorte from '../BalanceCorte';

const MCC = ({
  cortes,
  isLoading,
  isOpen,
  onClose,
  onSave,
  sendTicket,
}) => {
  const mcc = useSelector(state => state.balance.mcc);
  const [corte, setCorte] = React.useState([]);
  const [opciones, setOpciones] = React.useState([]);
  const [solicitudes, setSolicitudes] = React.useState({});

  const setInitElements = async (object) => {
    if (Object.keys(object).length > 0) {
      const arr = await Object.keys(object).map(x => x).reverse();
      const _opciones = arr.map(label => ({ value: label, label }));
      let initValues = [];
      for (let i = 0; i < 5; i++) {
        const index = parseInt(arr[i]);
        if (object[index] === undefined) {
          break;
        }
        initValues = [...initValues, ({ corte: object[index], title: index })];
      }
      setSolicitudes({ ...object });
      setOpciones([..._opciones]);
      setCorte(initValues);
    }
  };

  const onChangeSelect = (select) => {
    let _corte = {};
    let _title = '';
    if (select) {
      _title = select.value;
      const id = parseInt(_title);
      _corte = solicitudes[id];
    }
    setCorte([{ corte: _corte, title: _title }]);
  };

  React.useEffect(() => {
    if (!isLoading) {
      setInitElements(cortes);
    }
  }, [isLoading, cortes]);

  React.useEffect(() => {
    if (!Object.keys(mcc).length) {
      setOpciones(Object.keys(mcc));
    }
  }, [mcc]);

  return (
    <FullDialog
      title="Modificación de Cortes de Caja"
      isOpen={isOpen}
      isLoading={isLoading}
      onClose={onClose}
      onSave={() => {}}
      enableAction={false}
      color="inherit"
    >
      <div className="w-100 h-auto">
        {!isLoading ? (
          <div className="balance__contenedor h-auto">
            <div className="w-100 d-flex flex-column justify-content-center align-items-center h-auto">
              <Select
                className="basic-single user_search"
                classNamePrefix="select"
                isSearchable
                name="cortes"
                options={opciones}
                onChange={onChangeSelect}
              />
              {corte.length > 0 ? (
                <div className="w-100 d-flex align-items-center justify-content-center user_search mt-2 flex-column">
                  {corte.map(cor => (
                    <BalanceCorte
                      key={`key_${Math.random()}`}
                      corteDeCaja={cor}
                      title={cor.title}
                      isOpen={corte.length === 1}
                      onSave={onSave}
                      sendTicket={sendTicket}
                    />
                  ))}
                </div>
              ) : (
                <div className="w-100 d-flex align-items-center justify-content-center user_search mt-2">
                  Seleccione una solicitud
                </div>
              )}
            </div>
          </div>
        ) : (
          <LinearProgress />
        )}
      </div>
    </FullDialog>
  );
};

MCC.propTypes = {
  cortes: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.object.isRequired,
  sendTicket: PropTypes.func.isRequired,
};

MCC.defaultProps = {
  cortes: {},
};

export default MCC;
