import React from 'react';
import PropTypes from 'prop-types';
// import moment from 'moment';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Collapse,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Divider,
} from '@material-ui/core';

import ListMdaItem from './ListFacturasItem';

const ListFacturas = ({
  facturas,
  fetchFacturas,
  isLoading,
  isOpen,
}) => {
  const [arr, setArr] = React.useState([]);

  const initFacturas = (_facturas) => {
    setArr(_facturas);
  };

  React.useEffect(() => {
    if (isOpen) {
      fetchFacturas();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  React.useEffect(() => {
    initFacturas(facturas);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facturas]);
  return (
    <div className="w-100 h-auto">
      <Collapse in={isLoading}>
        <LinearProgress />
      </Collapse>
      <Collapse in={!isLoading}>
        {arr.length > 0 ? (
          <List classes={{ root: 'bg-white' }}>
            {arr.map((item, i) => (
              <div key={`key_factura_${item.crmid}`} className="w-100">
                {i !== 0 && (
                  <Divider />
                )}
                <ListMdaItem item={item} />
              </div>
            ))}
          </List>
        ) : (
          <List dense>
            <ListItem>
              <ListItemText
                primary="Sin facturas"
                secondary="La orden no cuenta con facturas relacionadas"
              />
            </ListItem>
          </List>
        )}
      </Collapse>
    </div>
  );
};

ListFacturas.propTypes = {
  facturas: PropTypes.array.isRequired,
  fetchFacturas: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default ListFacturas;
