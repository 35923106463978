import React from 'react';
// import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTachometerAlt,
  faClock,
  faCalendar,
  faShoppingCart,
  faBriefcase,
  faEnvelope,
  faLifeRing,
  faFolderOpen,
} from '@fortawesome/free-solid-svg-icons';
import { Swipeable } from 'react-swipeable';

const SwipMenu = (props) => {
  const {
    activeTab, handleChange, onSwipeAction, load,
  } = props;
  return (
    <>
      <div className="w-100">
        <Swipeable
          className="d-flex flex-column f-1"
          onSwipedLeft={load ? null : eventData => onSwipeAction(eventData)}
          onSwipedRight={load ? null : eventData => onSwipeAction(eventData)}
        >
          <div>
            <Tabs
              value={activeTab}
              onChange={handleChange}
              variant="fullWidth"
              indicatorColor="primary"
              textColor="primary"
              scrollButtons="on"
              TabIndicatorProps={{ style: { background: '#313C52', height: 3 } }}
            >
              <Tab className="tab" icon={<FontAwesomeIcon style={{ fontSize: 15 }} color={`${activeTab === 0 ? '#313C52' : '#B6BEC6'}`} icon={faTachometerAlt} />} disabled={!!load} />
              <Tab className="tab" icon={<FontAwesomeIcon style={{ fontSize: 15 }} color={`${activeTab === 1 ? '#313C52' : '#B6BEC6'}`} icon={faClock} />} disabled={!!load} />
              <Tab className="tab" icon={<FontAwesomeIcon style={{ fontSize: 15 }} color={`${activeTab === 2 ? '#313C52' : '#B6BEC6'}`} icon={faCalendar} />} disabled={!!load} />
              <Tab className="tab" icon={<FontAwesomeIcon style={{ fontSize: 15 }} color={`${activeTab === 3 ? '#313C52' : '#B6BEC6'}`} icon={faShoppingCart} />} disabled={!!load} />
              <Tab className="tab" icon={<FontAwesomeIcon style={{ fontSize: 15 }} color={`${activeTab === 4 ? '#313C52' : '#B6BEC6'}`} icon={faBriefcase} />} disabled={!!load} />
              <Tab className="tab" icon={<FontAwesomeIcon style={{ fontSize: 15 }} color={`${activeTab === 5 ? '#313C52' : '#B6BEC6'}`} icon={faEnvelope} />} disabled={!!load} />
              <Tab className="tab" icon={<FontAwesomeIcon style={{ fontSize: 15 }} color={`${activeTab === 6 ? '#313C52' : '#B6BEC6'}`} icon={faLifeRing} />} disabled={!!load} />
              <Tab className="tab" icon={<FontAwesomeIcon style={{ fontSize: 15 }} color={`${activeTab === 7 ? '#313C52' : '#B6BEC6'}`} icon={faFolderOpen} />} disabled={!!load} />
            </Tabs>
          </div>
        </Swipeable>
      </div>
    </>
  );
};
export default withRouter(SwipMenu);
