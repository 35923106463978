/* eslint-disable camelcase */
import fetch from 'cross-fetch';
import moment from 'moment';
import ConfigurationAPI from './ConfigurationAPI';

class SellAPI extends ConfigurationAPI {
  create = async (cart, discount, customer, sostatus, payments, deliver = false, shippingId = null) => {
    console.log('CREATE: ');
    this.credentials.method = 'POST';

    const totalCart = cart.total;
    const totalDiscount = discount.total;
    const totalToPay = Number((totalCart - totalDiscount).toFixed(2));
    const { promotions } = cart;

    const products = [];
    cart.products.forEach((product) => {
      const productid = (product.productid && product.productid.indexOf('x') !== -1)
        ? product.productid.split('x').pop()
        : product.productid;
      const custom = {
        option: [
          {
            product_option_value_id: product.crmid || productid,
          },
        ],
        discount_percent: product.discount_percent,
        price: product.priceWithTaxes / (1 - product.discount_percent / 100),
        pu: product.unit_price,
        product_id: product.parentId,
        quantity: product.quantity,
        productid: product.crmid || productid,
        comment: product.comment,
      };
      products.push(custom);
    });

    /**
     * Working on promotions
     */
    if (promotions && promotions.free_products) {
      promotions.free_products.forEach((product) => {
        const promotionProduct = {
          option: [
            {
              product_option_value_id: product.productid,
            },
          ],
          discount_percent: 0,
          price: product.unit_price,
          pu: product.unit_price,
          product_id: null,
          quantity: product.quantity,
          productid: product.productid,
          comment: '',
        };
        products.push(promotionProduct);
      });
    }
    const data = {
      products,
      subtotal: cart.subTotal,
      total: totalToPay,
      account_no: customer.selectedCustomer.account_no,
      tpv_payment: payments,
      tpv_discount_amount: discount.fixed,
      tpv_discount_percentage: discount.percentage,
      order_status: sostatus,
      comment: cart.description,
      treebesdireccionenvioid: customer.selectedCustomer.treebesdireccionenvioid,
      treebesdireccionfacturaid: customer.selectedCustomer.treebesdireccionfacturaid,
      deliver,
      TreebesPromociones_Aplicadas:
        promotions && promotions.applied_promos
          ? Object.keys(promotions.applied_promos)
          : [],
    };

    /* Set deliver dates */
    if (shippingId) {
      const shippingProduct = cart.products.find(prod => prod.crmid === shippingId);
      if (shippingProduct) {
        console.log('shippingProduct', shippingProduct);
        data.f_limite_embarque = moment(shippingProduct.f_limite_embarque).format('YYYY-MM-DD');
        data.f_entrega_estimada = moment(shippingProduct.f_entrega_estimada).format('YYYY-MM-DD');
      }
    }

    this.credentials.body = JSON.stringify(data);
    const response = await fetch(`${this.url}/SalesOrder`, this.credentials);
    let json = {};
    try {
      json = await response.json();
      return json;
    }
    catch (err) {
      return {
        success: false,
        status: response.status,
        message: response.error.message,
      };
    }
  }

  collect = async (total, prods, customer, sostatus, payments, deliver = false) => {
    console.log('Collect: ');
    this.credentials.method = 'POST';

    const totalToPay = Number((total).toFixed(2));

    const products = [];
    prods.forEach((product) => {
      const custom = {
        option: [
          {
            product_option_value_id: product.crmid,
          },
        ],
        discount_percent: 0,
        price: product.precio_neto,
        pu: product.precio_neto,
        product_id: '',
        quantity: product.cantidad,
        productid: product.crmid,
        comment: '',
      };
      products.push(custom);
    });

    const data = {
      products,
      subtotal: totalToPay,
      total: totalToPay,
      account_no: customer.selectedCustomer.account_no,
      tpv_payment: payments,
      tpv_discount_amount: 0,
      tpv_discount_percentage: 0,
      order_status: sostatus,
      comment: '',
      deliver,
      TreebesPromociones_Aplicadas: [],
    };
    console.log('cobro', data);

    this.credentials.body = JSON.stringify(data);
    const response = await fetch(`${this.url}/SalesOrder`, this.credentials);
    let json = {};
    try {
      json = await response.json();
      return json;
    }
    catch (err) {
      return {
        success: false,
        status: response.status,
        message: response.error.message,
      };
    }
  }

  _fde = async (type, payments, related_to) => {
    const data = {
      type,
      flow: payments,
      related_to,
    };

    this.credentials.method = 'POST';
    this.credentials.body = JSON.stringify(data);

    try {
      const response = await fetch(`${this.url}/TreebesFdE`, this.credentials);
      const json = await response.json();
      const information = json.success ? 'Envio con exito' : json.error.message;
      const message = { ...json.message, information };
      return { ...json, message };
    }

    catch {
      return { success: false, message: 'Error en el Host' };
    }
  }

  deliver = async (payments, related_to) => {
    const result = await this._fde('I', payments, related_to);
    return result;
  }

  refund = async (payments, related_to) => {
    const result = await this._fde('O', payments, related_to);
    return result;
  }
}

export default SellAPI;
