/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { CustomInput } from 'reactstrap';
import {
  ListItem,
  List,
  IconButton,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { uploadFilesToDocument } from '../../../../../redux/actions/expensesActions';

export const UploadFile = ({ expenseId }) => {
  const dispatch = useDispatch();
  const [filesArray, setFilesArray] = React.useState([]);

  React.useEffect(() => {
    if (expenseId && expenseId !== '' && filesArray.length) {
      dispatch(uploadFilesToDocument(filesArray, expenseId));
    }
    // eslint-disable-next-line
  }, [expenseId]);

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result.toString().replace(/^data:(.*,)?/, ''));
    };
    reader.onerror = error => reject(error);
  });
  async function onChangeFileInput(event) {
    const {
      target: { files },
    } = event;
    if (files.length > 0) {
      await Object.keys(files).forEach(async (key) => {
        const meta = files[key];
        const { name } = meta;
        toBase64(meta).then(async (file) => {
          await setFilesArray([...filesArray, { name, file }]);
        });
      });
    }
  }
  const renderIcon = (name) => {
    const ext = name.split('.')[1];
    switch (ext) {
      case 'pdf':
        return 'file-pdf';
      case 'png':
        return 'file-image';
      case 'jpg':
        return 'file-image';
      default:
        return 'file';
    }
  };
  const remove = async (index) => {
    const arrayF = filesArray;
    arrayF.splice(index, 1);
    await setFilesArray([...arrayF]);
  };

  return (
    <div>
      <CustomInput
        id="inputFileByOrder"
        type="file"
        className="w-100"
        onChange={onChangeFileInput}
      />
      <div>
        {filesArray.length > 0 && (
          <List>
            {filesArray.map((el, index) => (
              <ListItem
                button
              >
                <ListItemIcon className="file_icon">
                  <FontAwesomeIcon icon={renderIcon(el.name)} />
                </ListItemIcon>
                <ListItemText
                  className="file_text"
                  primary={el.name}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    onClick={() => remove(index)}
                  >
                    <Delete />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        )}
      </div>
    </div>
  );
};

UploadFile.propTypes = {
  expenseId: PropTypes.string.isRequired,
};

export default UploadFile;
