import { setStorage, removeStorage } from '../../helpers/pricebook';

export const ADD_PRICEBOOK = 'ADD_PRICEBOOK';
export const REMOVE_PRICEBOOK = 'REMOVE_PRICEBOOK';

export function addPricebook(pricebook) {
  setStorage(pricebook);
  return (dispatch) => {
    dispatch({
      type: ADD_PRICEBOOK,
      pricebook,
    });
  };
}

export function removePricebook() {
  removeStorage();
  return (dispatch) => {
    dispatch({ type: REMOVE_PRICEBOOK });
  };
}
