import React from 'react';
import '../quotes.style.scss';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import moment from 'moment';
import { InView } from 'react-intersection-observer';
import {
  LinearProgress,
  Collapse,
  Button,
} from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileInvoiceDollar,
} from '@fortawesome/free-solid-svg-icons';
import { fetchAllCotizaciones, fetchListQuotes } from '../../../redux/actions/cotizarActions';
import { formatMoney, getColorStatusQuote } from '../../../helpers/tools';
import { selectOrder } from '../../../redux/actions/orderActions';
import Iconwidthbg from '../../../components/Icons/IconWidthBG/IconWidthBG';
import ShareModal from '../../../components/Share/components/ShareModal';
import appModules from '../../../constant/appModules';

const Quotes = ({ data, isLoading }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { cotizaciones } = useSelector(state => state.cotizacion);
  const [selectedQuote, setSelectedQuote] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const title = `Compartir Cotización ${selectedQuote?.quote_no}`;

  const paginator = useSelector(state => state.cotizacion.paginator);
  const paginate = () => {
    if (paginator.actualPage < paginator.totalPages) {
      dispatch(fetchListQuotes(paginator.actualPage + 1));
    }
  };

  const loadMoreQuotes = (lastElement) => {
    if (lastElement) {
      paginate();
    }
  };

  const openQuotesDetail = async (crmid) => {
    await dispatch(selectOrder(crmid));
    history.push(`/pos/cotizacion/${crmid}`);
  };

  const selectQuote = async (quote) => {
    if (selectedQuote && quote.crmid === selectedQuote.crmid) {
      setSelectedQuote(null);
    } else {
      setSelectedQuote(quote);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    dispatch(fetchAllCotizaciones());
  }, []);


  return (
    <div className="quotes-mobile">
      <Collapse in={isLoading}>
        <LinearProgress />
      </Collapse>
      <div>
        <List classes={{ root: 'bg-white p-0' }}>
          {
          cotizaciones.length ? cotizaciones.map(quote => (
            <>
              <ListItem divider className="quote" key={quote.crmid} onClick={() => selectQuote(quote)}>
                <ListItemText
                  classes={{ primary: 'quote__title', secondary: 'quote__subtitle' }}
                  primary={quote.quote_no}
                  secondary={(
                    <div>
                      <span>{quote.accountname}</span>
                      <br />
                      <span>{`${moment(quote.modifiedtime).format('DD MMM YYYY h:mm a')} ${quote.quote_no !== quote.subject ? quote.subject : ''}`}</span>
                    </div>
                  )}
                />
                <ListItemText
                  classes={{ primary: 'quote__money' }}
                  primary={`$${formatMoney(quote.hdnGrandTotal)} ${quote.currency_code}`}
                  secondary={(

                    <ListItemIcon className="m-0 d-flex justify-content-end">
                      <Iconwidthbg
                        primaryColor={getColorStatusQuote(quote.quotestage)}
                        secondaryColor="white"
                        width={27}
                        height={27}
                        classes="ml-2"
                        enabled
                        square
                        rounded
                        actionClick={() => {}}
                      >
                        <FontAwesomeIcon style={{ fontSize: 15 }} icon={faFileInvoiceDollar} />
                      </Iconwidthbg>
                    </ListItemIcon>
                  )}
                />
              </ListItem>
              <Collapse in={selectedQuote && selectedQuote.crmid === quote.crmid} style={{ backgroundColor: '#f7f7f7' }}>
                <List>
                  <ListItem divider className="quote">
                    <ListItemText style={{ width: '50%' }} classes={{ secondary: 'quote-detail__subtitle quote-detail__subtitle-left' }} secondary="Estado: " />
                    <ListItemText
                      classes={{ secondary: 'quote-detail__subtitle quote-detail__subtitle-right' }}
                      secondary={
                        quote.quotestage
                      }
                    />
                    <ListItemIcon className="m-0">
                      <Iconwidthbg
                        primaryColor={getColorStatusQuote(quote.quotestage)}
                        secondaryColor="white"
                        width={27}
                        height={27}
                        classes="ml-2"
                        enabled
                        square
                        rounded
                        actionClick={() => {}}
                      >
                        <FontAwesomeIcon style={{ fontSize: 15 }} icon={faFileInvoiceDollar} />
                      </Iconwidthbg>
                    </ListItemIcon>
                  </ListItem>
                  <ListItem divider className="quote">
                    <ListItemText style={{ width: '50%' }} classes={{ secondary: 'quote-detail__subtitle quote-detail__subtitle-left' }} secondary="Cliente:" />
                    <ListItemText classes={{ secondary: 'quote-detail__subtitle quote-detail__subtitle-right' }} secondary={quote.accountname} />
                  </ListItem>
                  <ListItem divider className="quote">
                    <ListItemText style={{ width: '50%' }} classes={{ secondary: 'quote-detail__subtitle quote-detail__subtitle-left' }} secondary="Asignado a:" />
                    <ListItemText classes={{ secondary: 'quote-detail__subtitle quote-detail__subtitle-right' }} secondary={quote.assigned_user} />
                  </ListItem>
                  <ListItem divider className="quote">
                    <ListItemText style={{ width: '50%' }} classes={{ secondary: 'quote-detail__subtitle quote-detail__subtitle-left' }} secondary="Moneda:" />
                    <ListItemText classes={{ secondary: 'quote-detail__subtitle quote-detail__subtitle-right' }} secondary={quote.currency_code} />
                  </ListItem>
                  <ListItem divider className="quote">
                    <ListItemText style={{ width: '50%' }} classes={{ secondary: 'quote-detail__subtitle quote-detail__subtitle-left' }} secondary="Asunto:" />
                    <ListItemText classes={{ secondary: 'quote-detail__subtitle quote-detail__subtitle-right' }} secondary={quote.subject} />
                  </ListItem>
                  <ListItem className="quote">
                    <Button style={{ width: '100%', marginRight: '20px' }} variant="contained" color="primary" onClick={() => openQuotesDetail(quote.crmid)}>
                      Detalles
                    </Button>
                    <Button style={{ width: '100%' }} variant="contained" color="primary" onClick={() => handleClickOpen()}>
                      Compartir
                    </Button>
                  </ListItem>
                </List>
              </Collapse>
            </>
          ))
            : () => (
              <ListItem>
                <ListItemText primary="Sin cotizaciones" />
              </ListItem>
            )
        }
        </List>
      </div>
      {
        cotizaciones.length > 0 && paginator.actualPage < paginator.totalPages && paginator.totalQuotes !== 1 && (
          <InView as="div" onChange={inView => loadMoreQuotes(inView)}>
            <div className="w-100 text-center my-2">
              <span>
                Cargando cotizaciones ...
              </span>
            </div>
          </InView>
        )
      }

      <ShareModal
        open={open}
        title={title}
        handleClose={handleClose}
        order={selectedQuote || {}}
        template={appModules.Quotes}
        isLoading={isLoading}
      />

    </div>
  ); };

Quotes.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
};

export default Quotes;
