import React from 'react';
import PropTypes from 'prop-types';

import {
  Menu,
  List,
  Radio,
  MenuItem,
  ListItem,
  ListItemText,
} from '@material-ui/core';

const RadioSettings = ({
  title,
  value,
  loading,
  options,
  setOption,
  disabled,
  isOrderStatus = false
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleList(e) {
    const { currentTarget } = e;
    setAnchorEl(currentTarget);
  }

  return (
    <>
      <List component="nav" aria-label="Device settings">
        <ListItem
          dense
          button
          disabled={disabled}
          aria-label={title}
          onClick={handleList}
          aria-haspopup="true"
          aria-controls="FilterState"
        >
          <ListItemText
            primary={title}
            secondary={value}
          />
        </ListItem>
      </List>
      <Menu
        id="FilterState"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {options.map(option => (
          <MenuItem
            key={`key_radio_${option.id}`}
            disabled={loading}
            onClick={() => {
              setOption(option);
            }}
          >
            <Radio
              checked={isOrderStatus ? option.value === value : option.id === value}
            />
            <span>{option.value}</span>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

RadioSettings.propTypes = {
  title: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.array,
  setOption: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  isOrderStatus: PropTypes.bool,
};

RadioSettings.defaultProps = {
  title: 'Seleccionar ...',
  options: [],
  disabled: false,
  isOrderStatus: false,
};

export default RadioSettings;
