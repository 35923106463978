import React from 'react';
import './detailButton.scss';
import PropTypes from 'prop-types';

const DetailButton = (props) => {
  const {
    icon,
    title,
    color,
    width,
    hadnleClick,
  } = props;
  return (
    <button className="btn-details d-flex justify-content-around" type="button" style={{ backgroundColor: color, width }} onClick={hadnleClick}>
      {icon}
      {title}
      <div />
    </button>
  );
};
DetailButton.defaultProps = {
  color: '#23A1DB',
  icon: '',
};

DetailButton.prototype = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string,
  icon: PropTypes.any,
};

export default DetailButton;
