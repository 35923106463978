import React from 'react';
import './UpdatesContainer.scss';
import { connect } from 'react-redux';
import { fetchAccountUpdates } from '../../../../redux/actions/accountActions';
import UpdateList from './components/UpdateList';
// import ScrollPaginator from '../../../../components/ScrollPaginator/ScrollPaginator';

const UpdatesContainer = (props) => {
  const { account: { updates } } = props;

  return (
    <UpdateList data={updates.data} />
  );
};


const mapStateToProps = state => ({
  account: state.account,
});
const mapDispatchToProps = {
  fetchAccountUpdates,
};
export default connect(mapStateToProps, mapDispatchToProps)(UpdatesContainer);
