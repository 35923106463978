import React from 'react';
import './AdvancedOne.scss';
import PropTypes from 'prop-types';
import { ButtonBase, Collapse } from '@material-ui/core';

const AdvancedOne = ({
  title,
  subtitle,
  label,
  extra,
  footer,
  contained,
}) => {
  const [open, setOpen] = React.useState(false);
  return (
    <div className="advancedOne_card">
      <ButtonBase
        className="advancedOne_btn"
        onClick={() => setOpen(!open)}
      >
        <div className="advancedOne_detail">
          <div style={{overflowX: 'hidden'}}>
            <div className="advancedOne_card_title">{title}</div>
            <div className="h-100">
              <div className="advancedOne_card_extra">{extra}</div>
            </div>
            <div className="advancedOne_card_subtitle" style={{textAlign: 'left'}}>{subtitle}</div>
          </div>
          <div>
            <div className="advancedOne_card_label">{label}</div>
            <div className="h-100">
              <Collapse in={!open} className="w-100">
                <div className="advancedOne_card_footer">{footer}</div>
              </Collapse>
            </div>
          </div>
        </div>
      </ButtonBase>
      <Collapse in={open} mountOnEnter className="w-100">
        {contained}
      </Collapse>
    </div>
  );
};

AdvancedOne.propTypes = {
  title: PropTypes.string,
  extra: PropTypes.string,
  subtitle: PropTypes.string,
  label: PropTypes.string,
  footer: PropTypes.any,
  contained: PropTypes.any,
};

AdvancedOne.defaultProps = {
  title: '',
  extra: '',
  subtitle: '',
  label: '',
  footer: '',
  contained: '',
};

export default AdvancedOne;
