import React from 'react';
import './ModalAction.scss';
import { useSelector } from 'react-redux';
// import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import PhoneList from './components/phone/PhoneList2';
import ContactEmailList from './components/ContactEmailList';
import ContactAddressList from './components/ContactAddressList';
import SectionTitle from '../SectionTitle/SectionTitle';

const ModalAction = (props) => {
  const {
    onClose, selectedValue, open, title, action,
  } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };
  const loads = useSelector(state => state.loads);
  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} classes={{ paper: 'modal-container' }}>
      <AppBar className="appbar" style={{ position: 'fixed' }}>
        <Toolbar variant="dense" disableGutters>
          <SectionTitle title={title} />
        </Toolbar>
      </AppBar>
      {action === 'phone' || action === 'whatsapp' ? (<PhoneList action={action} />) : null}
      {action === 'Email' ? (<ContactEmailList />) : null}
      {action === 'address' ? (<ContactAddressList />) : null}
      { loads.accountIsLoading && (<div className="modal-loader"><CircularProgress /></div>) }
    </Dialog>
  );
};
ModalAction.prototype = {};
export default ModalAction;
