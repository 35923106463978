import { numberFormat, jsonToArray } from './tools';

export const calculateTotals = (cartProductsByArray, discountTaxes) => {
  const totals = {
    descuentos: 0,
    subTotal: 0,
    totalIva: 0,
    anotherTaxes: 0,
    total: 0,
    totalProducts: 0,
    totalWeight: 0,
    totalWithOutServices: 0,
    discountAmount: 0,
    totalIEPS: 0,
    totalISR: 0,
    totalIvaRet: 0,
    totalIsrRet: 0,
  };

  cartProductsByArray.forEach((product) => {
    const price = numberFormat(product.unit_price, 6);
    const quantity = numberFormat((product.sendQuantity === null || product.sendQuantity === undefined) ? product.quantity : product.sendQuantity);
    const discountAmount = numberFormat(product.discount_amount, 6);
    const iva = ((product.tax1 !== 0 && (discountTaxes === false || !discountTaxes)) ? numberFormat(product.tax1, 6) : (0));
    const weight = numberFormat(product.weight);

    const ieps = ((product.tax2 !== 0 && (discountTaxes === false || !discountTaxes)) ? numberFormat(product.tax2, 6) : (0));
    const isr = ((product.tax3 !== 0 && (discountTaxes === false || !discountTaxes)) ? numberFormat(product.tax3, 6) : (0));
    const ivaRet = ((product.tax4 !== 0 && (discountTaxes === false || !discountTaxes)) ? numberFormat(product.tax4, 6) : (0));
    const isrRet = ((product.tax5 !== 0 && (discountTaxes === false || !discountTaxes)) ? numberFormat(product.tax5, 6) : (0));

    const totalPrice = numberFormat(((price * quantity) - discountAmount), 6);

    const taxKeys = Object.keys(product).filter(x => (x.includes('tax') && x !== 'tax1'));
    const taxes = (taxKeys.map(x => (product[x] !== undefined ? (product[x]) : 0))).reduce((a, b) => a + b, 0);
    totals.discountAmount += discountAmount;
    totals.subTotal = numberFormat((totals.subTotal + totalPrice), 6);

    const productIEPS = numberFormat((totalPrice * (ieps / 100)), 6);
    const productISR = numberFormat((totalPrice * (isr / 100)), 6);


    /**
     * IVA: is a compound tax
     * compound tax = (product price + simple taxes(ieps, isr)) * compound tax(iva) / 100
     */
    // totals.totalIva += (Number.isNaN(iva) || iva === undefined) ? 0 : (totalPrice + productIEPS + productISR) * (iva / 100);
    const recalculateTotalPrice = (Number.isNaN(iva) || iva === undefined) ? 0 : numberFormat(((totalPrice + productIEPS + productISR) * (iva / 100)), 6); // redondeo a 6

    const totalBefore = totals.totalIva;
    totals.totalIva = numberFormat((totalBefore + recalculateTotalPrice), 6);
    totals.anotherTaxes += Number.isNaN(taxes) ? 0 : numberFormat((totalPrice * (taxes / 100)), 6);
    totals.totalProducts += quantity;
    totals.totalWithOutServices = (
      !(product.crmid === '55235'
      || product.crmid === '152666')
        ? (totals.subTotal + totals.totalIva)
        : (totals.totalWithOutServices)
    );
    totals.totalWeight += weight * quantity;

    // Separate taxes
    totals.totalIEPS += numberFormat((totalPrice * (ieps / 100)), 6);
    totals.totalISR += numberFormat((totalPrice * (isr / 100)), 6);
    totals.totalIvaRet += numberFormat((totalPrice * (ivaRet / 100)), 6);
    totals.totalIsrRet += numberFormat((totalPrice * (isrRet / 100)), 6);
  });

  totals.subTotal = numberFormat(totals.subTotal, 2);
  totals.totalIva = numberFormat(totals.totalIva, 2);
  totals.total = totals.subTotal + totals.totalIva + totals.totalIEPS + totals.totalISR - totals.totalIvaRet - totals.totalIsrRet;
  totals.totalWithOutServices = numberFormat(totals.totalWithOutServices);
  totals.totalProducts = numberFormat(totals.totalProducts);
  totals.totalWeight = numberFormat(totals.totalWeight);

  return totals;
};

export const transformCartProductsToArrayAndGetTotals = ({ ...cartProductsById }, discountTaxes) => {
  const cartProductsByArray = jsonToArray(cartProductsById);
  const totals = calculateTotals(cartProductsByArray, discountTaxes);
  /* remove products: cartProductsByArray */
  return { ...totals, cartProductsByArray, products: cartProductsByArray };
};

export const canIncreaseProductInCart = (authUser, product) => (
  authUser.user.config.pos_sininv || product.quantity < product.qtyinstock
);

export const canDecreaseProductInCart = product => (
  product.quantity > 1
);

export const getTotalPrice = (product) => {
  /**
   * Number('3,336.21') = NaN asi se estaba convirtiendo los valore a numeros y funciona bien cuando solo tiene .
   * ej: 335.05 pero ya cuando traen , no jala
   */
  const priceWithoutComma = `${product.unit_price}`.replace(',', '');
  const productPrice = parseFloat(priceWithoutComma);


  return (
    numberFormat((product.quantity * productPrice) - product.discount_amount)
  );
};

export const calculateWeightByPaypalService = (totalWeight) => {
  const newTotalWeight = Math.round(((totalWeight + ((0.025) * totalWeight))) * 100) / 100;
  return { totalWeight: newTotalWeight };
};

export const getShippingData = async (totalWeight, array) => {
  let total = totalWeight;
  if (total < 1) total = 1;
  if (total >= 100) total = 100;
  const weight = Math.trunc(total);
  const sendMethods = await array.map((x, i) => ({ kg: (i + 1), x }));
  return sendMethods.filter(x => x.kg === weight);
};

export const getPriceWithTaxes = (product) => {
  let { listprice } = product;
  const discountPercent = product.discount_percent;
  if (discountPercent > 0) {
    const discount = (listprice * (discountPercent / 100));
    listprice -= discount;
  }
  const taxKeys = Object.keys(product).filter(x => (x.includes('tax')));
  const array = [];
  taxKeys.forEach((tax) => {
    if (product[tax] !== '') {
      const operator = (Number.parseFloat(product[tax]) / 100);
      const taxValue = Number.parseFloat(listprice) * operator;
      array.push(taxValue);
    }
  });
  if (array.length > 0) {
    const sumTaxes = array.reduce((a, b) => (a + b));
    return Number.parseFloat(listprice) + sumTaxes;
  }
  return listprice;
};
export const getPriceWithTaxesUnitPrice = (product) => {
  let unitPrice = product.unit_price;
  const discountPercent = product.discount_percent;
  // console.log('product', product);
  const taxKeys = Object.keys(product).filter(x => (x.includes('tax')));
  // console.log('taxKeys', taxKeys);
  if (discountPercent > 0) {
    const discount = (unitPrice * (discountPercent / 100));
    unitPrice -= discount;
  }
  const array = [];
  taxKeys.forEach((tax) => {
    if (product[tax] !== 0 && product[tax] !== undefined && product[tax] !== null) {
      const operator = (Number.parseFloat(product[tax]) / 100);
      const taxValue = Number.parseFloat(unitPrice) * operator;
      array.push(taxValue);
    }
  });
  // console.log('array', array);
  if (array.length > 0) {
    const sumTaxes = array.reduce((a, b) => (a + b));
    return Number.parseFloat(unitPrice) + sumTaxes;
  }
  return unitPrice;
};

export default {
  calculateTotals,
  transformCartProductsToArrayAndGetTotals,
  canIncreaseProductInCart,
  canDecreaseProductInCart,
  getTotalPrice,
  calculateWeightByPaypalService,
  getShippingData,
  getPriceWithTaxes,
  getPriceWithTaxesUnitPrice,
};
