/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatMoney } from '../../../../../helpers/tools';

import SatLogo from '../../../../Icons/SatLogo';
import AdvancedOne from '../../../../List/AdvancedOne';
import GenericButton from '../../../../Input/GenericButton';
import TopMenuDrawer from '../../../../TopMenuDrawer';
import ShareModal from '../../../../Share/components/ShareModal';
import appModules from '../../../../../constant/appModules';

const OrderListItem = React.forwardRef(({ item, onClick }, ref) => {
  /*
  const user = useSelector(state => state.authUser.user);
  const createUrl = () => `https://api.whatsapp.com/send?text=${user.comercia_tienda_url}payment/${item.crmid}`;
  const shareShopCartToWhatsap = async () => {
    window.open(createUrl(), '_blank');
  }; */
  const {
    sostatus,
    estado_mda,
    estado_fde,
    estadosat,
    assigned_user,
    currency_code,
    subject,
  } = item;
  const [open, setOpen] = React.useState(false);
  const loads = useSelector(state => state.loads);
  const title = 'Compartir venta';
  const isLoading = Boolean(loads && loads.emailTemplateIsLoading);
  const handleModalAction = () => {
    setOpen(!open);
  };
  const [opt, setOpt] = React.useState(false);
  const toggle = () => setOpt(!opt);
  const options = [
    {
      icon: 'share-alt',
      title: 'Compartir',
      onClick: () => {
        toggle();
        handleModalAction();
      },
      disabled: Boolean(false),
    },
  ];

  const renderSOStatusIcon = () => {
    let classInsert = 'd-flex justify-content-center align-items-center';
    let iconSetted = <div />;
    switch (sostatus) {
      case 'Autorizado':
      case 'Approved':
        classInsert = `${classInsert} icon icon__sos__auto mx-1`;
        iconSetted = <FontAwesomeIcon style={{ fontSize: '1.2em' }} color="#ffffff" icon="file-invoice-dollar" />;
        break;
      case 'Apartado':
      case 'Delivered':
        classInsert = `${classInsert} icon icon__sos__apar mx-1`;
        iconSetted = <FontAwesomeIcon style={{ fontSize: '1.2em' }} color="#ffffff" icon="file-invoice-dollar" />;
        break;
      case 'Cancelado':
      case 'Cancelled':
        classInsert = `${classInsert} icon icon__sos__canc mx-1`;
        iconSetted = <FontAwesomeIcon style={{ fontSize: '1.2em' }} color="#ffffff" icon="file-invoice-dollar" />;
        break;
      default:
        classInsert = `${classInsert} icon icon__sos__crea mx-1`;
        iconSetted = <FontAwesomeIcon style={{ fontSize: '1.2em' }} color="#b6bec6" icon="file-invoice-dollar" />;
        break;
    }
    return (
      <div className={classInsert}>
        {iconSetted}
      </div>
    );
  };

  const renderMovIcon = (mda = false) => {
    let classInsert = 'd-flex justify-content-center align-items-center';
    let iconSetted = <div />;
    let estado = (mda ? (estado_mda) : (estado_fde));
    const icon = mda ? 'dolly' : 'hand-holding-usd';
    switch (estado) {
      case 'Entregado':
      case 'Pagado/Cobrado':
        classInsert = `${classInsert} icon icon__mov__succ mx-1`;
        iconSetted = <FontAwesomeIcon style={{ fontSize: '1.2em' }} color="#ffffff" icon={icon} />;
        break;
      case 'Parcialmente Entregado':
      case 'Parcialmente Pagado/Cobrado':
        classInsert = `${classInsert} icon icon__mov__parc mx-1`;
        estado = mda ? 'Parc. Entregado' : 'Parc. Cobrado';
        iconSetted = <FontAwesomeIcon style={{ fontSize: '1.2em' }} color="#ffffff" icon={icon} />;
        break;
      default:
        classInsert = `${classInsert} icon icon__mov__sine mx-1`;
        iconSetted = <FontAwesomeIcon style={{ fontSize: '1.2em' }} color="#b6bec6" icon={icon} />;
        break;
    }
    return (
      <div className={classInsert}>
        {iconSetted}
      </div>
    );
  };

  const renderSatIcon = () => {
    let classInsert = 'd-flex justify-content-center align-items-center';
    let iconSetted = <div />;
    switch (estadosat) {
      case 'Vigente':
        classInsert = `${classInsert} icon icon__sos__auto mx-1`;
        iconSetted = <SatLogo width="18px" fill="#ffffff" />;
        break;
      case 'Cancelado':
      case 'Cancelled':
        classInsert = `${classInsert} icon icon__sos__canc mx-1`;
        iconSetted = <SatLogo width="18px" fill="#ffffff" />;
        break;
      default:
        classInsert = `${classInsert} icon icon__sos__crea mx-1`;
        iconSetted = <SatLogo width="18px" fill="b6bec6" />;
        break;
    }
    return (
      <div className={classInsert}>
        {iconSetted}
      </div>
    );
  };


  const renderExtra = () => (
    <div className="d-flex justify-content-around flex-row-reverse h-100">
      {renderSOStatusIcon()}
      {renderMovIcon(true)}
      {renderMovIcon()}
      {renderSatIcon()}
    </div>
  );

  const renderContained = () => (
    <div className="orderMobile_contained">
      <div>
        <span>Estado:</span>
        <span>
          {sostatus}
          {renderSOStatusIcon()}
        </span>
      </div>
      <div>
        <span>Movimiento de almacén:</span>
        <span>
          {estado_mda}
          {renderMovIcon(true)}
        </span>
      </div>
      <div>
        <span>Flujo de Efectivo:</span>
        <span>
          {estado_fde}
          {renderMovIcon()}
        </span>
      </div>
      <div>
        <span>Estado de factura:</span>
        <span>
          {estadosat}
          {renderSatIcon()}
        </span>
      </div>
      <div>
        <span>Asignado a:</span>
        <span>{assigned_user}</span>
      </div>
      <div>
        <GenericButton
          label="Detalles"
          style={{ marginRight: 2 }}
          onClick={() => onClick(item.crmid)}
        />
      </div>
    </div>
  );

  return (
    <div
      ref={ref}
      className="orderMobile_item"
    >
      <AdvancedOne
        title={item.entity_label}
        subtitle={`${moment(item.modifiedtime).format('DD MMM, h:mm a')} ${item.salesorder_no !== subject ? subject : ''}`}
        label={`$ ${formatMoney(item.total)} ${currency_code}`}
        extra={item.accountname}
        footer={renderExtra()}
        contained={renderContained()}
      />
      <TopMenuDrawer
        opt={opt}
        toggle={toggle}
        options={options}
      />

      <ShareModal
        open={open}
        title={title}
        handleClose={handleModalAction}
        order={item || {}}
        template={appModules.SalesOrder}
        isLoading={isLoading}
      />
    </div>
  );
});

OrderListItem.propTypes = {
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

OrderListItem.defaultProps = {
  onClick: () => {},
};

export default OrderListItem;
