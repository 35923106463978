/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoice, faHandHoldingUsd, faDolly } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import SalesDetail from '../../SalesDetail/SalesDetail';
import Iconwidthbg from '../../../../../components/Icons/IconWidthBG/IconWidthBG';
import ListItemGeneric from '../../ListItemGeneric/ListItemGeneric';
import {
  formatMoney, geteColorSosStatus, getColorStatusMDA, getColorStatusFDE, getColorStatusSAT,
} from '../../../../../helpers/tools';
import SatLogo from '../../../../../components/Icons/SatLogo';

const Sales = ({ data }) => (
  <div className="list-container" id="t">
    {
         data ? data.map(item => (
           <ListItemGeneric
             key={item.crmid}
             expandData={(<SalesDetail data={item} />)}
             titleLeft={`Venta: ${item.entity_label}`}
             subTitleLeft={moment(item.modifiedtime).format('DD MMM, h:mm a')}
             titleRight={`Total: $${formatMoney(item.total)}`}
             subTitleRight={(
               <>
                 <Iconwidthbg
                   primaryColor={geteColorSosStatus(item.sostatus)}
                   secondaryColor={`${item.sostatus === 'Created' || item.sostatus === 'Creado' ? '#B6BEC6' : 'white'}`}
                   width={27}
                   height={27}
                   classes="ml-2"
                   enabled
                   square
                   rounded
                   actionClick={() => {}}
                 >
                   <FontAwesomeIcon style={{ fontSize: 15 }} icon={faFileInvoice} />
                 </Iconwidthbg>

                 <Iconwidthbg
                   primaryColor={getColorStatusMDA(item.estado_mda)}
                   secondaryColor={`${item.estado_mda === 'Sin Entregar' ? '#B6BEC6' : 'white'}`}
                   width={27}
                   height={27}
                   classes="ml-2"
                   enabled
                   square
                   rounded
                   actionClick={() => {}}
                 >
                   <FontAwesomeIcon style={{ fontSize: 15 }} icon={faDolly} />
                 </Iconwidthbg>

                 <Iconwidthbg
                   primaryColor={getColorStatusFDE(item.estado_fde)}
                   secondaryColor={`${item.estado_fde === 'Sin Pago/Cobro' ? '#B6BEC6' : 'white'}`}
                   width={27}
                   height={27}
                   classes="ml-2"
                   enabled
                   square
                   rounded
                   actionClick={() => {}}
                 >
                   <FontAwesomeIcon style={{ fontSize: 15 }} icon={faHandHoldingUsd} />
                 </Iconwidthbg>

                 <Iconwidthbg
                   primaryColor={getColorStatusSAT(item.estadosat)}
                   secondaryColor={`${item.estadosat === null ? '#B6BEC6' : 'white'}`}
                   width={27}
                   height={27}
                   classes="ml-2"
                   enabled
                   square
                   rounded
                   actionClick={() => {}}
                 >
                   <SatLogo fill={`${item.estadosat == null ? '#B6BEC6' : 'white'}`} width="15px" height="15px" />
                 </Iconwidthbg>
               </>
                      )}
           />
         )) : null
            }

  </div>
);
Sales.prototype = {
  data: PropTypes.array.isRequired,
};
export default Sales;
