/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import './getExpensesForm.scss';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMoneyBillWave, faCreditCard, faMoneyCheck, faPlusCircle, faCheck, faMinusCircle, faExchangeAlt,
} from '@fortawesome/free-solid-svg-icons';
import {
  Collapse,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';

import Avatar from '@material-ui/core/Avatar';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Fab from '@material-ui/core/Fab';


import { formatMoney } from '../../../../helpers/tools';
import SpeiIcon2 from '../../../../components/Icons/SpeiIcon2';
import ExpensesApi from '../../../../api/ExpensesApi';

const GetExpenseForm = withRouter(({ history }) => {
  const expensesApi = new ExpensesApi();
  const gastoSeleccionado = useSelector(state => state.gastos.gastoSeleccionado);
  const metodosPago = useSelector(state => state.authUser.user.metodos_pagos);
  const [open, setOpen] = React.useState(false);
  const [done, setDone] = React.useState(false);
  const [payProcess, setPayProcess] = React.useState(false);
  const [value, setValue] = React.useState(metodosPago ? metodosPago[0].method : '');
  const [monto, setMonto] = React.useState(gastoSeleccionado ? gastoSeleccionado.tfde_balance : 0);
  const [balance, setbalance] = React.useState(gastoSeleccionado ? gastoSeleccionado.tfde_balance : 0);
  const [list, setList] = React.useState([]);
  const [pagoCategoria, setPagoCategoria] = React.useState(metodosPago ? metodosPago[0].body[0] : {});
  const [metodosPagoSelect, setmetodosPagoSelect] = React.useState(metodosPago ? metodosPago[0] : {});
  const pagoFdE = {
    type: 'O',
    flow: [],
    related_to: gastoSeleccionado ? gastoSeleccionado.crmid : '',
  };

  React.useEffect(() => {
    setValue(metodosPago[0].method);
    setPagoCategoria(metodosPago[0].body[0]);
    setmetodosPagoSelect(metodosPago[0]);
    setMonto(gastoSeleccionado ? formatMoney(gastoSeleccionado.tfde_balance) : 0);
    setbalance(gastoSeleccionado ? formatMoney(gastoSeleccionado.tfde_balance) : 0);
    // eslint-disable-next-line
  }, [gastoSeleccionado]);


  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseDone = () => {
    setDone(false);
  };

  const handleListItemClick = (item) => {
    setPagoCategoria(item);
    setOpen(false);
  };

  const returnIcon = (selec) => {
    switch (selec) {
      case ('Transferencia (SPEI)'):
        return (
          <SpeiIcon2 style={{
            fontSize: 30, fill: '#313C52',
          }}
          />
        );
      case ('Tarjeta de Credito'):
        return (
          <>
            <span style={{
              fontSize: 18, color: '#313C52',
            }}
            />
            <FontAwesomeIcon
              style={{
                fontSize: 18, color: '#313C52',
              }}
              icon={faCreditCard}
            />
          </>
        );
      case ('Tarjeta de Debito'):
        return (
          <FontAwesomeIcon
            style={{
              fontSize: 18, color: '#313C52',
            }}
            icon={faCreditCard}
          />
        );
      case ('Cheque'):
        return (
          <FontAwesomeIcon
            style={{
              fontSize: 18, color: '#313C52',
            }}
            icon={faMoneyCheck}
          />
        );
      default:
        return (
          <FontAwesomeIcon
            style={{
              fontSize: 18, color: '#313C52',
            }}
            icon={faMoneyBillWave}
          />
        );
    }
  };
  const handleChanhe = async (e) => {
    setValue(e.target.value);
    const pay = await metodosPago.filter(item => item.method === e.target.value);

    await setmetodosPagoSelect(pay[0]);
    if (pay[0].body.length > 1) {
      setOpen(true);
    } else {
      setPagoCategoria(pay[0].body[0]);
    }
  };

  const checkAmount = (val) => {
    let canAdd = false;
    if (val !== 0 && val !== '') {
      if (parseFloat(val) > parseFloat(balance)) {
        toast.error(`No puede sobrepasar el monto a pagar de ${formatMoney(balance)} `);
      } else {
        canAdd = true;
        const total = parseFloat(balance) - parseFloat(val);
        setbalance(total);
      }
    } else {
      toast.error('para realizar el pago debe agregar un monto mayor a 0');
    }

    return canAdd;
  };

  const getTotal = () => {
    let amount = parseFloat(gastoSeleccionado ? gastoSeleccionado.tfde_pagado : 0);
    if (list.length > 0) {
      list.map((item) => {
        amount += parseFloat(item.monto);
      });
    }
    return amount <= parseFloat(gastoSeleccionado ? gastoSeleccionado.total : 0) ? parseFloat(amount) : 0;
  };
  const getBalance = () => {
    let amount = parseFloat(gastoSeleccionado ? gastoSeleccionado.tfde_balance : 0);
    if (list.length > 0) {
      list.map((item) => {
        amount -= parseFloat(item.monto);
      });
    }
    return amount;
  };

  const deletePago = (idx) => {
    const item = list[idx];
    setbalance(balance + item.monto);
    const listaPagos = [...list];
    listaPagos.splice(idx, 1);
    setList(listaPagos);
    setMonto(balance + parseFloat(item.monto));
  };

  const addMonto = async (val) => {
    if (!checkAmount(val)) {
      return;
    }
    // Creando objeto pago
    const pago = {
      monto: parseFloat(val, 10),
      metodo: value,
      ycybid: pagoCategoria.crmid,
      referencia: pagoCategoria.referencia,
    };

    if (list.length) {
      // Buscando coincidencia
      const idx = list.findIndex(item => item.metodo === pago.metodo);
      if (idx !== -1) {
        pago.monto = parseFloat(val, 10) + parseFloat(list[idx].monto, 10);

        const update = [...list];
        update.splice(idx, 1, pago);
        await setList(update);
      } else {
        const montos = [...list, pago];
        await setList(montos);
      }
    } else {
      const montos = [...list, pago];
      await setList(montos);
    }
    await setMonto(getBalance() - val);
  };

  const handleClickPayExpensive = async () => {
    if (list.length === 0) {
      // Creando objeto pago
      const pago = {
        monto: parseFloat(monto, 10),
        metodo: value,
        ycybid: pagoCategoria.crmid,
        referencia: pagoCategoria.referencia,
      };
      const montos = [...list, pago];
      await setList(montos);
      pagoFdE.flow = [...montos];
    } else {
      pagoFdE.flow = [...list];
    }
    setPayProcess(true);
    if (pagoFdE.flow.length < 1) {
      toast.error('Debe agregar un monto a pagar');
      setPayProcess(false);
      return;
    }
    const response = await expensesApi.payExpense(pagoFdE);
    const { success } = response;
    if (success) {
      setDone(true);
      setPayProcess(false);
      setTimeout(() => {
        history.push('/expenses');
      }, 1500);
    } else {
      setPayProcess(false);
    }
  };

  return (
    <>
      <Collapse in={payProcess}>
        <LinearProgress />
      </Collapse>
      <div className="get-expense-form">
        <div className="get-expense-form__action-form">
          { metodosPago === undefined && (
          <Collapse in={metodosPago === undefined}>
            <LinearProgress />
          </Collapse>
          )}
          <div className="get-expense-form__action-form-custom-form-group">
            <div
              style={{
                position: 'absolute', top: 48, right: 18, zIndex: 9,
              }}
              onClick={() => setOpen(true)}
            >
              {(returnIcon(value))}
            </div>
            <span style={{
              position: 'absolute', top: 60, zIndex: 9, left: 30,
            }}
            >
              {pagoCategoria != null ? pagoCategoria.entity_label : ''}
            </span>
            <label htmlFor="" className="custom-label">Método de pago</label>
            <select
              value={value}
              onChange={e => handleChanhe(e)}
              className="custom-input"
              style={{
                height: 53, display: 'flex', alignItems: 'flex-start', padding: '8px 20px',
              }}
            >
              {
                metodosPago.map(item => (
                  <option value={item.method} key={Math.random()}>
                    {item.method}
                  </option>
                ))
              }
            </select>
          </div>
          <div className="get-expense-form__action-form-custom-form-group mt-3">
            <FontAwesomeIcon
              onClick={() => addMonto(monto)}
              style={{
                fontSize: 18, position: 'absolute', top: '60%', zIndex: 9, left: '90%', color: '#313C52',
              }}
              icon={faPlusCircle}
            />
            <label htmlFor="quantity" className="custom-label">
              Monto:
            </label>
            <input className="custom-input" id="quantity" type="number" min="0" max="500" value={monto} onChange={e => setMonto(e.target.value)} />
          </div>
          <Collapse in={list.length > 0}>
            {
            list.length > 0
              ? (
                <div className="my-4">
                  <span className="fz-15 px-3">Registro</span>
                  {list.map((item, idx) => (
                    <div className="custom-input partida mt-2" key={Math.random()}>
                      <span className="mx-2 fz-15">{item.metodo}</span>
                      <span className="font-weight-light fz-12">{pagoCategoria != null ? pagoCategoria.entity_label : ''}</span>
                      <span className="font-weight-bold mx-2">{`$${formatMoney(item.monto)}`}</span>
                      <span onClick={() => deletePago(idx)}>
                        <FontAwesomeIcon
                          style={{
                            fontSize: 18, color: '#313C52',
                          }}
                          icon={faMinusCircle}
                        />
                      </span>
                    </div>
                  ))
              }
                </div>
              )
              : null
          }
          </Collapse>
          <div className="my-3">
            <div className="d-flex justify-content-between px-3">
              <span className="fz-15">Total</span>
              <span className="fz-15">Balance</span>
            </div>
            <div className="custom-input partida">
              <span className="total font-weight-bold">{`$ ${formatMoney(getTotal())}`}</span>
              <span className="icon-arrows">
                <FontAwesomeIcon
                  style={{
                    fontSize: 18, color: '#313C52',
                  }}
                  icon={faExchangeAlt}
                />
              </span>
              <span className="balance font-weight-bold">{`$ ${formatMoney(getBalance())}`}</span>
            </div>
          </div>
        </div>
        <div className="get-expense-form__action-btn">
          <button className="btn-detail" type="button" style={{ maxWidth: '90%' }} onClick={() => handleClickPayExpensive()}>Guardar y Pagar</button>
        </div>
      </div>
      <Dialog onClose={() => handleClose} aria-labelledby="simple-dialog-title" open={open}>
        <DialogTitle id="simple-dialog-title">Seleccione caja o banco</DialogTitle>
        <List>
          {metodosPagoSelect
            ? metodosPagoSelect.body.map(item => (
              <ListItem button onClick={() => handleListItemClick(item)} key={Math.random()}>
                <ListItemAvatar>
                  <Avatar>
                    {item.entity_label[0]}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={item.entity_label} />
              </ListItem>
            ))
            : null
          }
        </List>
      </Dialog>
      <Dialog fullScreen onClose={() => handleCloseDone} aria-labelledby="simple-dialog-title" open={done} classes={{ paper: 'done' }}>
        <div className="dialog-container">
          <Fab color="primary" aria-label="add" classes={{ root: 'done-fab' }} size="large">
            <FontAwesomeIcon
              style={{
                fontSize: 40,
              }}
              icon={faCheck}
            />
          </Fab>
          <span className="done-title mt-3">Finalizado</span>
        </div>
      </Dialog>
    </>
  );
});

export default GetExpenseForm;
