import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { Card } from '@material-ui/core';
/* Components */
import RadioSettings from '../../RadioSettings';

const ConfVenta = ({
  loads,
  authUser,
  generalTitle,
  defaultVentaOptions,
  dispatchChangeGenericButtonHistorico,
}) => {
  const { user } = authUser;
  const { config } = user;
  const generalDisable = Boolean(config.pos_config_alm);
  const loading = Boolean(loads.profileIsLoading);
  return (
    <Card
      className={
        isMobile ? 'settings__cardMobile px-4 py-2' : 'settings__card px-4 py-2'
      }
    >
      <div className="settings__title">{generalTitle}</div>
      <RadioSettings
        loading={loading}
        disabled={generalDisable}
        title="Boton por default en las ventas"
        options={defaultVentaOptions}
        value={user.config.pos_botonventas}
        setOption={dispatchChangeGenericButtonHistorico}
      />
    </Card>
  );
};

ConfVenta.propTypes = {
  generalTitle: PropTypes.string,
  loads: PropTypes.object.isRequired,
  defaultVentaOptions: PropTypes.array,
  authUser: PropTypes.object.isRequired,
  dispatchChangeGenericButtonHistorico: PropTypes.func.isRequired,
};

ConfVenta.defaultProps = {
  generalTitle: 'Preferencias',
  defaultVentaOptions: [
    {
      id: 'Cobrar',
      value: 'Cobrar',
    },
    {
      id: 'Entregar',
      value: 'Entregar',
    },
    {
      id: 'Ambas',
      value: 'Ambas',
    },
  ],
};

export default ConfVenta;
