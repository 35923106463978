/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
/* import MoreVertIcon from '@material-ui/icons/MoreVert'; */

import customerProps from '../../propTypes/customerProps';

import SelectCustomer from './components/SelectCustomer';
import AlertChangeAddress from './components/modal/AlertChangeAddress';
import CustomerActions from './components/CustomerActions';

const Customer = ({
  cart,
  customer,
  dispatchEditOrder,
  dispatchFetchAllCustomers,
  dispatchFilterCustomers,
  dispatchOpenModal,
  dispatchRemoveCustomer,
  dispatchSelectCustomer,
  dispatchSelectAddres,
  dispatchToggleModal,
  loads,
  modals,
}) => {
  const { orderSelected } = cart;
  return (
    <div className="ml-auto">
      <div className="w-100 d-flex justify-content-center align-items-center p1">
        <CustomerActions
          cart={cart}
          customer={customer}
          dispatchOpenModal={dispatchOpenModal}
          dispatchRemoveCustomer={dispatchRemoveCustomer}
          dispatchSelectCustomer={dispatchSelectCustomer}
        />
      </div>
      <SelectCustomer
        customer={customer}
        dispatchEditOrder={dispatchEditOrder}
        dispatchFetchAllCustomers={dispatchFetchAllCustomers}
        dispatchFilterCustomers={dispatchFilterCustomers}
        dispatchSelectCustomer={dispatchSelectCustomer}
        dispatchToggleModal={dispatchToggleModal}
        loads={loads}
        modals={modals}
        orderSelected={orderSelected}
      />
      <AlertChangeAddress
        open={Boolean(modals.alertAddressCustomerModalIsOpen)}
        handleClose={() => dispatchToggleModal('alertAddressCustomer')}
        customer={customer}
        setAddress={(facturaId, envioId) => {
          dispatchSelectAddres(facturaId, envioId);
          dispatchToggleModal('alertAddressCustomer');
        }}
      />
    </div>
  );
};

Customer.propTypes = {
  cart: PropTypes.object.isRequired,
  customer: customerProps.isRequired,
  dispatchEditOrder: PropTypes.func.isRequired,
  dispatchFetchAllCustomers: PropTypes.func.isRequired,
  dispatchFilterCustomers: PropTypes.func.isRequired,
  dispatchOpenModal: PropTypes.func.isRequired,
  dispatchRemoveCustomer: PropTypes.func.isRequired,
  dispatchSelectCustomer: PropTypes.func.isRequired,
  dispatchToggleModal: PropTypes.func.isRequired,
  dispatchSelectAddres: PropTypes.func.isRequired,
  loads: PropTypes.object.isRequired,
  modals: PropTypes.object.isRequired,
};

export default Customer;
