/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import {
  Card,
  ListItem,
  ListItemText,
} from '@material-ui/core';

const ConfFastSearch = ({
  authUser,
  searchPrimary,
  searchSecondary,
  fatsSearchTitle,
}) => {
  const { user: { config: { post_fast_column } } } = authUser;
  const { headerName } = post_fast_column;
  return (
    <Card
      className={
        isMobile ? 'settings__cardMobile px-4 py-2' : 'settings__card px-4 py-2'
      }
    >
      <div className="settings__title">{fatsSearchTitle}</div>
      <ListItem
        dense
      >
        <ListItemText
          primary={`${searchPrimary} ${headerName}`}
          secondary={searchSecondary}
        />
      </ListItem>
    </Card>
  );
};

ConfFastSearch.propTypes = {
  searchPrimary: PropTypes.string,
  searchSecondary: PropTypes.string,
  fatsSearchTitle: PropTypes.string,
  authUser: PropTypes.object.isRequired,
};
ConfFastSearch.defaultProps = {
  fatsSearchTitle: 'Busqueda Rápida',
  searchPrimary: 'Columna de Busqueda Rápida: ',
  searchSecondary: 'La busqueda rápida se realizará filtrando por este campo.',
};

export default ConfFastSearch;
