import React from 'react';
import PropTypes from 'prop-types';
import './FloatMobile.scss';
import { Zoom, Fab } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FloatMobile = ({
  show,
  onClick,
  icon,
  fontColor,
  color,
}) => (
  <div className="FloatMobile_container">
    <Zoom in={show}>
      <Fab
        className="FloatMobile_btn"
        onClick={() => onClick()}
        color={color}
      >
        {typeof (icon) === 'string' ? (
          <FontAwesomeIcon size="lg" style={{ color: fontColor }} icon={icon} />
        ) : (icon)}
      </Fab>
    </Zoom>
  </div>
);

FloatMobile.propTypes = {
  show: PropTypes.bool,
  onClick: PropTypes.func,
  icon: PropTypes.any,
  fontColor: PropTypes.string,
  color: PropTypes.string,
};

FloatMobile.defaultProps = {
  show: false,
  onClick: () => {},
  icon: 'save',
  fontColor: '#ffffff',
  color: 'primary',
};

export default FloatMobile;
