import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPhone,
} from '@fortawesome/free-solid-svg-icons';
import WhatsapIcon from '../../../../../../../components/Icons/WhatsappIcon';

const EmptyContact = ({ action }) => (
  <ListItem button classes={{ root: 'py-0 pl-0' }}>
    <ListItemAvatar>
      <Avatar variant="square" className="modal-avatar">
        {
            action === 'phone' ? <FontAwesomeIcon style={{ fontSize: 26 }} icon={faPhone} /> : <WhatsapIcon fill="white" width="26px" height="26px" />
        }
      </Avatar>
    </ListItemAvatar>
    <ListItemText primary="Sin contactos" classes={{ primary: 'modal-primary-text', secondary: 'modal-secondary-text' }} />
  </ListItem>
);

export default EmptyContact;
