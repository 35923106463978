import React from 'react';
import './quotes.style.scss';
import { useDispatch, useSelector } from 'react-redux';
import { fetchListQuotes } from '../../redux/actions/cotizarActions';
import Quotes from './components/Quotes';

const QuotesMobile = () => {
  const cotizacion = useSelector(state => state.cotizacion);
  const { cotizaciones } = cotizacion;
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchListQuotes(1));
    // eslint-disable-next-line
  }, []);

  return (
    <div className="quotes-mobile bg-white h-100">
      <Quotes isLoading={loading} data={cotizaciones} />
    </div>
  );
};

export default QuotesMobile;
