import React from 'react';

const CajaIcon = props => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    width="100px"
    height="100px"
    viewBox="0 0 100 100"
    enableBackground="new 0 0 100 100"
    xmlSpace="preserve"
    {...props}
  >
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.917,64.547c0.354-4.662,0.69-9.205,1.046-13.745
		c0.356-4.549,0.794-9.093,1.08-13.646c0.307-4.888,3.754-8.403,8.661-8.515c3.635-0.083,7.272-0.017,11.115-0.017
		c0-1.407,0-2.696,0-4.146c-3.91,0-7.745,0-11.736,0c0-4.029,0-7.903,0-11.893c10.609,0,21.132,0,31.777,0c0,3.904,0,7.726,0,11.802
		c-3.871,0-7.753,0-11.763,0c0,1.487,0,2.725,0,4.223c0.839,0,1.604,0,2.367,0c7.922,0,15.844-0.006,23.766,0.002
		c5.228,0.006,9.427,3.555,9.873,8.713c0.78,9.015,1.345,18.05,2.01,27.222C61.393,64.547,38.805,64.547,15.917,64.547z
		 M54.146,36.588c0,2.761,0,5.297,0,7.862c6.719,0,13.301,0,19.894,0c0-2.699,0-5.229,0-7.862
		C67.371,36.588,60.841,36.588,54.146,36.588z M53.928,16.549c-8.025,0-15.893,0-23.87,0c0,1.351,0,2.58,0,3.881
		c8.021,0,15.889,0,23.87,0C53.928,19.144,53.928,17.962,53.928,16.549z M25.937,40.489c2.734,0,5.311,0,7.838,0
		c0-1.395,0-2.629,0-3.877c-2.692,0-5.213,0-7.838,0C25.937,37.922,25.937,39.104,25.937,40.489z M45.903,36.615
		c-2.783,0-5.303,0-7.904,0c0,1.365,0,2.595,0,3.869c2.718,0,5.292,0,7.904,0C45.903,39.133,45.903,37.944,45.903,36.615z
		 M25.938,44.685c0,1.423,0,2.605,0,3.896c2.686,0,5.262,0,7.858,0c0-1.377,0-2.609,0-3.896
		C31.126,44.685,28.605,44.685,25.938,44.685z M45.864,44.722c-2.67,0-5.243,0-7.843,0c0,1.364,0,2.588,0,3.889
		c2.68,0,5.197,0,7.843,0C45.864,47.271,45.864,46.042,45.864,44.722z M25.899,56.49c2.775,0,5.346,0,7.91,0
		c0-1.336,0-2.502,0-3.689c-2.706,0-5.274,0-7.91,0C25.899,54.08,25.899,55.193,25.899,56.49z M45.909,52.783
		c-2.726,0-5.296,0-7.923,0c0,1.314,0,2.479,0,3.725c2.699,0,5.27,0,7.923,0C45.909,55.244,45.909,54.127,45.909,52.783z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10,83.415c0-5.313,0-10.495,0-15.796c26.7,0,53.293,0,80,0
		c0,5.292,0,10.474,0,15.796C63.384,83.415,36.797,83.415,10,83.415z"
      />
    </g>
  </svg>
);

export default CajaIcon;
