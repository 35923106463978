import React from 'react';
import './CommentInput.scss';
// import PropTypes from 'prop-types';

import InputBase from '@material-ui/core/InputBase';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';

const CommentInput = ({ saveComment, comment }) => {
  const [activeComment, setActiveComment] = React.useState(comment?.commentcontent || '');
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && e.target.value !== '') {
      saveComment(e.target.value, 'edit');
      e.target.value = '';
      setActiveComment(null);
    }
  };

  const setValue = async (comment) => {
    await setActiveComment(comment?.commentcontent);
  };

  React.useEffect(() => {
    if (comment !== null && Object.keys(comment).length) {
      setValue(comment);
    }
  }, [comment]);

  return (
    <div className="comments-input-container px-3 py-2">
      <Grid container spacing={8}>
        <Grid item xs={2}>
          <Avatar alt="User" src="/img/ava.png" />
        </Grid>
        <Grid item xs={10} className="input-container">
          <InputBase
            className="input-container__inside-input"
            fullWidth
            value={activeComment}
            placeholder="Commentar"
            inputProps={{ 'aria-label': 'Comments' }}
            onKeyDown={handleKeyDown}
            onChange={e => setActiveComment(e.target.value)}
          />
        </Grid>
      </Grid>
    </div>
  ); };

// CommentInput.prototype = {};

export default CommentInput;
