import React from 'react';
import PropTypes from 'prop-types';
import './inputButton.scss';
import {
  Fade,
  CircularProgress,
  ButtonBase,
} from '@material-ui/core';

const InputButton = ({
  title,
  click,
  backgroundColor,
  disabledColor,
  disabled,
  loading,
}) => {
  const [zoom, setZoom] = React.useState(true);
  const [label, setLabel] = React.useState('');

  React.useEffect(() => {
    if (label !== title) {
      setZoom(false);
      setLabel(title);
      setTimeout(() => {
        setZoom(true);
      }, 300);
    }
  // eslint-disable-next-line
  }, [title]);

  return (
    <ButtonBase
      id={`id_button_${title}`}
      onClick={click}
      className="inputButton"
      style={{
        backgroundColor: `${disabled ? disabledColor : backgroundColor}`,
      }}
      disabled={disabled || loading}
    >
      <Fade in={zoom}>
        {loading ? (
          <div className="inputButton_loading">
            <CircularProgress size={24} />
          </div>
        ) : (<span>{label}</span>)}
      </Fade>
    </ButtonBase>
  );
};

InputButton.propTypes = {
  title: PropTypes.string,
  click: PropTypes.func,
  backgroundColor: PropTypes.string,
  disabledColor: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};

InputButton.defaultProps = {
  title: 'Aceptar',
  click: () => {},
  backgroundColor: '#23a1db',
  disabledColor: '#C6C6C6',
  disabled: false,
  loading: false,
};

export default InputButton;
