/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import './OrderMobile.scss';

import OrderList from './components/OrderList';
import ModalDetail from './components/ModalDetail';

const OrderMobile = ({
  authUser,
  fetchAll,
  loading,
  order,
  type,
  getNextPage,
}) => (
  <div className="orderMobile">
    {type === 'list' && (
      <OrderList
        order={order}
        loading={loading}
        fetchAll={fetchAll}
        getNextPage={getNextPage}
      />
    )}
    {type === 'modalDetail' && (
      <ModalDetail />
    )}
  </div>
);

OrderMobile.propTypes = {
  authUser: PropTypes.object,
  order: PropTypes.object,
  type: PropTypes.string,
  loading: PropTypes.bool,
  fetchAll: PropTypes.func,
  getNextPage: PropTypes.func,
};

OrderMobile.defaultProps = {
  authUser: {},
  order: {},
  type: 'list',
  loading: false,
  fetchAll: () => {},
  getNextPage: () => {},
};

export default OrderMobile;
