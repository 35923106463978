/* eslint-disable import/no-cycle */
import { connect } from 'react-redux';
import CartMobile from './CartMobile';

import {
  saveSell,
  changeSalesOrderStatus,
} from '../../../redux/actions/sellActions';

import {
  evaluateStock,
  clearCart,
} from '../../../redux/actions/cartActions';

import {
  cotizar,
} from '../../../redux/actions/cotizarActions';

const mapStateToProps = (state) => {
  const { cart, authUser, loads } = state;
  const loading = Boolean(loads.orderIsLoading || loads.checkoutIsLoading);
  return ({
    cart,
    loading,
    authUser,
  });
};

const mapDispatchToProps = dispatch => ({
  makeSell: (orderStatus, history) => {
    dispatch(changeSalesOrderStatus(orderStatus));
    dispatch(saveSell(history));
  },
  makeQuote: (history) => {
    dispatch(cotizar(history));
  },
  sync: () => dispatch(evaluateStock()),
  clearCart: () => dispatch(clearCart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CartMobile);
