import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import MdaAPI from '../../../../../api/MdaApi';
import { formatString } from '../../../../../helpers/tools';

const DeliverProductList = ({
  storeId,
  reference,
  selectStoreToCancel,
  deliverSelected,
}) => {
  const mdaApi = new MdaAPI();
  const [products, setProducts] = useState([]);

  const getReferenceDetail = async () => {
    let allProducts = [];
    const response = await mdaApi.cancelToDeliverDetail(storeId, reference);
    const { success, result } = response;

    if (success && result.length) {
      result[0].movimientos.map((mov) => {
        const { detalles, ...rest } = mov;
        allProducts = [...allProducts, ...detalles];
      });
    }

    const notRepeatedProductsIds = [];
    const notRepeatedProducts = [];

    allProducts.map((prod) => {
      if (!notRepeatedProductsIds.includes(prod.productid)) {
        notRepeatedProductsIds.push(prod.productid);
        const producto = {
          id: prod.productid,
          name: prod.productname,
          count: 1,
          treebesmdaid: prod.treebesmdaid,
          qtyinstock: prod.qtyinstock,
          cantidad: parseFloat(prod.cantidad),
        };
        notRepeatedProducts.push(producto);
      } else {
        const prodIndex = notRepeatedProducts.findIndex(
          p => p.id === prod.productid,
        );
        if (prodIndex !== -1) {
          notRepeatedProducts[prodIndex].count += 1;
        }
      }
    });

    setProducts(notRepeatedProducts);
  };

  useEffect(() => {
    getReferenceDetail();
  }, []);

  return (
    <div className="w-100">
      {products.length && (
        <List dense>
          {products.map(item => (
            <ListItem divider key={`${Math.random()}_${item.treebesmdaid}`}>
              <ListItemText primary={formatString(item.name)} secondary={`Stock: ${item.qtyinstock}`} />
              <ListItemSecondaryAction>
                <div className="d-flex justify-content-end align-items-center">
                  <span>
                    Cantidad:
                    {' '}
                    {item.cantidad}
                  </span>
                  <Checkbox
                    onChange={() => selectStoreToCancel(item.treebesmdaid)}
                    checked={deliverSelected.includes(item.treebesmdaid)}
                  />
                </div>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      )}
    </div>
  );
};

DeliverProductList.propTypes = {
  storeId: PropTypes.string.isRequired,
  reference: PropTypes.string.isRequired,
  selectStoreToCancel: PropTypes.func.isRequired,
  deliverSelected: PropTypes.array.isRequired,
};

export default DeliverProductList;
