/* eslint-disable consistent-return */
import fetch from 'cross-fetch';
import ConfigurationAPI from './ConfigurationAPI';

class DownloadAPI extends ConfigurationAPI {
    download = async (url, name, download = true) => {
      try {
        this.credentials.responseType = 'blob';
        const response = await fetch(url, this.credentials);
        if (response.status === 200) {
          await response.blob().then((blob) => {
            const objectURL = URL.createObjectURL(blob);
            if (download) {
              const link = document.createElement('a');
              link.href = objectURL;
              link.setAttribute('download', `${name}`);
              document.body.appendChild(link);
              link.click();
            } else {
              window.open(objectURL);
            }
          }).catch(() => ({ success: false, message: 'Error en la conversión del archivo' }));
        } else {
          const json = await response.json();
          return json;
        }
      } catch (err) {
        return { success: false, message: `Error en el Host: ${err}` };
      }
    }
}

export default DownloadAPI;
