import React from 'react';
import PropTypes from 'prop-types';
import { Collapse, LinearProgress } from '@material-ui/core';
import RefundProduct from './RefundProduct';
import RefundProductsTotals from './RefundProductsTotals';

const RefundProducts = ({
  tab,
  items,
  loading,
  setFieldChange,
}) => (
  <div className="w-100 h-auto d-flex flex-column">
    <Collapse in={loading} className="w-100">
      <LinearProgress />
    </Collapse>
    <Collapse in={!loading && items.length > 0} className="w-100">
      <div className="refund_products_head">Productos a devolver</div>
      <div className="refund_products">
        {items.map(item => (
          <RefundProduct
            key={item.crmid}
            item={{ ...item, tab, setFieldChange }}
          />
        ))}
      </div>
    </Collapse>
    <Collapse in={!loading && items.length > 0} className="w-100">
      <RefundProductsTotals items={items} tab={tab} />
    </Collapse>
  </div>
);

RefundProducts.propTypes = {
  tab: PropTypes.number.isRequired,
  items: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  setFieldChange: PropTypes.func.isRequired,
};

export default RefundProducts;
