/* eslint-disable camelcase */
/* eslint-disable import/no-cycle */
import { connect } from 'react-redux';
/* Component */
import Address from './Address';
// /* Actions */
import {
  addAddresForCustomer,
  fetchAddressByCustomer,
} from '../../redux/actions/customerActions';
import {
  selectAddressOrder,
} from '../../redux/actions/sellActions';
import { toggleModal, openModal } from '../../redux/actions/modalActions';

const mapStateToProps = ({
  customer,
  loads,
  modals,
  cart,
}) => ({
  customer,
  loads,
  modals,
  cart,
});

const mapDispatchToProps = dispatch => ({
  dispatchOpenModal: modal => dispatch(openModal(modal)),
  dispatchToggleModal: modal => dispatch(toggleModal(modal)),
  dispatchGetAddresByCustomer: id => dispatch(fetchAddressByCustomer(id)),
  dispatchSelectAddres: (factura, envio) => dispatch(selectAddressOrder(factura, envio)),
  dispatchAddAddressByCustomer: (id, address) => dispatch(addAddresForCustomer(id, address)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Address);
