/* eslint-disable import/no-cycle */
/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
/* Device */
import { isMobile } from 'react-device-detect';

/* Helper */
// import { toolsHelper } from '../../../../helpers';

/* Components */
import AmountPayable from './components/AmountPayable';
import AmountCustomer from './components/AmountCustomer';
import AmountRegistry from './components/AmountRegistry';
import AmountTendered from './components/AmountTendered';
import AmountCheckOut from './components/AmountCheckOut';
import MethodCheckOut from './components/MethodCheckOut';
import BankCheckOut from './components/BankCheckOut';
import CartCurrencyMessage from '../../../Cart/components/CartCurrencyMessage';

const CheckOutConstructor = ({
  cart,
  customer,
  defaultOrderStatus,
  dispatchEditOrder,
  handleMakeBill,
  isLoading,
  makeBill,
  onSave,
  orderStatuses,
  paymentTypes,
  posCheckoutBill,
  totalToPay,
}) => {
  /* new */
  const [payIndex, setPayIndex] = React.useState(0);

  /* Payable State */
  const inputEl = React.useRef(null);
  const [charge, setCharge] = React.useState(0);
  const [payable, setPayable] = React.useState('');

  /* Registry State */
  const [payments, setPayments] = React.useState([]);

  /* Registry Tendered */
  const [paidOut, setPaidOut] = React.useState(0);

  /* Registry Tendered */
  const [statusOrder, setStatusOrder] = React.useState(defaultOrderStatus);
  const [statusIndex, SetStatusIndex] = React.useState(orderStatuses.findIndex(x => x.id === statusOrder));


  /* Payment selected */
  const [payment, setPayment] = React.useState(null);

  /* Bank selected */
  const [bank, setBank] = React.useState(null);

  function isLetter(c) {
    return c.toLowerCase() !== c.toUpperCase();
  }

  const setPayableValue = (val) => {
    let value = val;
    if (!isLetter(value) && !Number.isNaN(value)) {
      value = value.replace(/[$_'_,]/g, '');
      // if (value[value.length - 1] === '.') value.substring(0, value.length - 1);
      value = Number(value).toString();
      let newValue = (Number.parseFloat(value));
      if (value === '.') {
        newValue = 0.0;
      }
      if (value === '') {
        // @ts-ignore
        setPayable(newValue);
        setCharge(newValue);
        // @ts-ignore
        newValue = '';
      }
      // @ts-ignore
      setPayable(newValue);
      setCharge(newValue);
    }
  };

  const changePayable = () => {
    if (inputEl) {
      const { value } = inputEl.current;
      setPayableValue(value);
    }
  };

  /* General Functions */

  const resetWorkFlowToCheckout = () => {
    setCharge(0);
    setPayable('');
    if (inputEl) {
      inputEl.current.value = '';
      // @ts-ignore
      inputEl.current.focus();
    }
  };

  /* Payable Functions */
  const onBlurCharge = () => {
    const newCharge = Number.parseFloat(payable);
    // @ts-ignore
    setPayable(newCharge);
  };

  const formatPaymentTypes = () => {
    let arr = [];
    paymentTypes.forEach((type, index) => {
      const { body } = type;
      const cbSelected = body.filter(account => account.select)[0];
      const cb = { ...cbSelected, id: type.method, index };
      arr = [...arr, cb];
    });
    return arr;
  };

  const handleSetPayIndex = (index) => {
    setPayIndex(index);
  };

  /* Once mounted */
  React.useEffect(() => {
    // @ts-ignore
    const newCharge = Number.parseFloat(totalToPay);
    setCharge(newCharge);
    setPayable(totalToPay);
    const metaInput = document.querySelector('#payable_dollar');
    if (metaInput) {
      if (newCharge) {
        // @ts-ignore
        metaInput.value = newCharge;
        setTimeout(async () => {
          // @ts-ignore
          metaInput.select();
          setPayable(totalToPay);
          await setCharge(newCharge);
        }, 100);
      } else {
        // @ts-ignore
        metaInput.focus();
      }
    }
  }, [totalToPay]);

  /* Method Functions */
  const newPaymentTypes = formatPaymentTypes();

  /* Registry Functions */

  const addPayment = (inputValue = null) => {
    try {
      // @ts-ignore
      const newMoney = (inputValue ? Number.parseFloat(inputValue) : Number.parseFloat(charge));
      let newPayment = {};
      let array = [];
      if (payment && newMoney > 0 && bank) {
        const exist = payments.find(x => (x.metodo === payment.method && x.referencia === bank?.referencia));
        if (exist) {
          const paymentsMap = payments.map((x) => {
            const item = { ...x };
            if (item.metodo === exist.metodo && item.referencia === exist.referencia) {
              item.monto += newMoney;
            }
            return item;
          });
          array = [...paymentsMap];
        } else {
          newPayment = {
            monto: newMoney,
            // @ts-ignore
            metodo: payment.method,
            // @ts-ignore
            tcybid: bank?.crmid,
            referencia: bank?.referencia,
          };
          array = [...payments, newPayment];
        }
        if (newPaymentTypes.length > 0) {
          setPayments(array);
          resetWorkFlowToCheckout();
        }
      }
      else {
        toast.warn('Asegurese de haber seleccionado un metodo de pago, una caja o banco y de haber agregado el monto que quiere adicionar');
      }
    } catch (error) {
      console.log('addPayment', error);
      toast.error('Error intentando agregar el pago');
    }
  };

  const removePayment = (index) => {
    const actualPayments = [...payments];
    actualPayments.splice(index, 1);
    setPayments(actualPayments);
  };

  /* Tendered Functions */
  React.useEffect(() => {
    if (payments.length > 0) {
      let sum = 0.0;
      payments.forEach(x => (sum += x.monto));
      setPaidOut(sum);
    } else {
      setPaidOut(0.0);
    }
  }, [payments]);

  /* Checkout Function (Submit) */

  const beforeSubmit = arr => new Promise((resolve, reject) => {
    let array = [...arr];
    const needFix = Boolean((paidOut + charge) > totalToPay);
    const existEfectivo = Boolean(array.some(x => x.metodo === 'Efectivo'));
    if (needFix) {
      const balance = Math.abs((totalToPay - (paidOut + charge))).toFixed(2);
      if (existEfectivo) {
        array = [];
        array = arr.map((x) => {
          if (x.metodo === 'Efectivo') {
            // @ts-ignore
            x.monto = Math.abs(x.monto - balance);
          }
          return x;
        });
        resolve({ payments: array, currentOrderStatus: statusOrder });
      } else {
        toast.info('Atención: la suma de los métodos de pago elegidos debe ser exacta');
        reject();
      }
    }
    resolve({ payments: array, currentOrderStatus: statusOrder });
  });

  const onSubmit = async () => {
    if (!isLoading) {
      if (charge > 0) {
        // @ts-ignore
        const newMoney = Number.parseFloat(charge);
        let newPayment = {};
        let array = [];
        if (payment && newMoney > 0 && bank) {
          const exist = payments.find(x => (x.metodo === payment.id && x.referencia === bank?.referencia));
          if (exist) {
            const paymentsMap = payments.map((x) => {
              const item = { ...x };
              if (item.metodo === exist.metodo && item.referencia === exist.referencia) {
                item.monto += newMoney;
              }
              return item;
            });
            array = [...paymentsMap];
          } else {
            newPayment = {
              monto: newMoney,
              // @ts-ignore
              metodo: payment.method,
              // @ts-ignore
              tcybid: bank?.crmid,
              referencia: bank?.referencia,
            };
            array = [...payments, newPayment];
          }
          if (newPaymentTypes.length > 0) {
            beforeSubmit(array).then(response => onSave(response));
          }
        } else {
          toast.warn('Error intentando cobrar la orden');
        }
      } else {
        beforeSubmit(payments).then(response => onSave(response));
      }
    } else {
      toast.warn('Ya estamos procesando un cobro, favor de esperar a que termine');
    }
  };

  const setStatusByIndex = (index) => {
    const newStatus = orderStatuses[index].id;
    setStatusOrder(newStatus);
    SetStatusIndex(index);
  };


  const selectPaymentMethod = (pay) => {
    setPayment(pay);
    try {
      if (pay.body && pay.body.length === 1) {
        setBank(pay.body[0]);
      } else {
        setBank(null);
      }
    } catch (error) {
      toast.error('Error seleccionando el banco o caja');
    }
  };

  const selectBank = async (banc) => {
    await setBank(null);
    await setBank(banc);
  };

  /* CSS */
  const mainCont = isMobile ? 'w-100 d-flex flex-column px-2 py-1 minHeightMobile' : 'w-100 d-flex flex-column';
  const inputMoney = Number(paidOut + charge);
  return (
    <div className={mainCont}>
      <AmountCustomer
        cart={cart}
        customer={customer}
        handleMakeBill={handleMakeBill}
        makeBill={makeBill}
        posCheckoutBill={posCheckoutBill}
        dispatchEditOrder={dispatchEditOrder}
      />
      {
        bank && (
          <CartCurrencyMessage bank={bank} />
        )
      }
      <MethodCheckOut payment={payment} selectPayment={pay => selectPaymentMethod(pay)} />
      {
        payment && (
          <BankCheckOut payment={payment} bank={bank} setBank={banc => selectBank(banc)} setPayIndex={handleSetPayIndex} />
        )
      }


      {
        payment && (bank || payments.length !== 0) && (
          <>
            <AmountPayable
              charge={charge}
              inputEl={inputEl}
              paidOut={paidOut}
              onSubmit={onSubmit}
              payIndex={payIndex}
              isLoading={isLoading}
              addPayment={addPayment}
              onBlurCharge={onBlurCharge}
              changePayable={changePayable}
              setPayIndex={handleSetPayIndex}
              newPaymentTypes={newPaymentTypes}
              totalToPayLegacy={totalToPay}
              totalToPay={Math.abs(totalToPay - inputMoney)}
            />
            <AmountRegistry
              charge={charge}
              onSubmit={onSubmit}
              payments={payments}
              removePayment={removePayment}
            />
            <AmountTendered
              charge={charge}
              paidOut={paidOut}
              totalToPay={totalToPay}
            />
            <AmountCheckOut
              onSubmit={onSubmit}
              statusIndex={statusIndex}
              statusOrder={statusOrder}
              orderStatuses={orderStatuses}
              enableSubmit={Boolean((charge + paidOut) > 0)}
              setStatusOrder={setStatusOrder}
              SetStatusIndex={setStatusByIndex}
            />
          </>
        )
      }

    </div>
  );
};

CheckOutConstructor.propTypes = {
  cart: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
  defaultOrderStatus: PropTypes.string.isRequired,
  handleMakeBill: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  makeBill: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  dispatchEditOrder: PropTypes.func.isRequired,
  orderStatuses: PropTypes.array.isRequired,
  paymentTypes: PropTypes.array.isRequired,
  posCheckoutBill: PropTypes.string.isRequired,
  totalToPay: PropTypes.number.isRequired,
};

CheckOutConstructor.defaultProps = {
  isLoading: false,
};

export default CheckOutConstructor;
