import React from 'react';
import PropTypes from 'prop-types';
import { AppBar, Tabs, Tab } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DetailTabs = ({
  onChange, tabsArr, value,
}) => {
  const handleChange = (event, _value) => onChange(_value);

  return (
    <AppBar position="sticky" className="bg-white">
      <Tabs
        variant="scrollable"
        className="orderDetail_tabs"
        scrollButtons="auto"
        value={value}
        onChange={handleChange}
      >
        {tabsArr.map(x => (
          <Tab
            className="orderDetail_tab"
            key={`key_tab_detail_${Math.random()}`}
            icon={x.icon}
          />
        ))}
      </Tabs>
    </AppBar>
  );
};

DetailTabs.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  tabsArr: PropTypes.array,
};

DetailTabs.defaultProps = {
  tabsArr: [
    {
      icon: <FontAwesomeIcon size="lg" icon="info-circle" />,
    },
    {
      icon: <FontAwesomeIcon size="lg" icon="hand-holding-usd" />,
    },
    {
      icon: <FontAwesomeIcon size="lg" icon="truck" />,
    },
    {
      icon: <FontAwesomeIcon size="lg" icon="dolly" />,
    },
    {
      icon: <FontAwesomeIcon size="lg" icon="file-signature" />,
    },
    {
      icon: <FontAwesomeIcon size="lg" icon="file" />,
    },
  ],
};

export default DetailTabs;
