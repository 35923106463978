import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { Card } from '@material-ui/core';
/* Components */
import ConfProductos from '../../ConfProductos';

const ConfColumnasProducts = ({
  loads,
  product,
  authUser,
  generalTitle,
  dispatchProductColumnDef,
}) => {
  const { user } = authUser;
  const { config } = user;
  const generalDisable = Boolean(config.pos_config_alm);
  const loading = Boolean(loads.profileIsLoading);
  return (
    <Card
      className={
        isMobile ? 'settings__cardMobile px-4 py-2' : 'settings__card px-4 py-2'
      }
    >
      <div className="settings__title">{generalTitle}</div>
      <ConfProductos
        loading={loading || generalDisable}
        columnDef={dispatchProductColumnDef}
        columnsDefs={product.columnsDefs || []}
      />
    </Card>
  );
};

ConfColumnasProducts.propTypes = {
  generalTitle: PropTypes.string,
  loads: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  authUser: PropTypes.object.isRequired,
  dispatchProductColumnDef: PropTypes.func.isRequired,
};

ConfColumnasProducts.defaultProps = {
  generalTitle: 'Tabla de Productos',
};

export default ConfColumnasProducts;
