/* eslint-disable import/no-cycle */
/* eslint-disable react/jsx-filename-extension */
import React, { Component } from 'react';
import './index.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import HeaderTop from './components/HeaderTop/HeaderTop';
import ClientList from './components/CLientList/ClientList';
import LoadComponent from '../../components/Load/LoadComponent';
import { fetchAllClients } from '../../redux/actions/clientActions';
import ScrollPaginator from '../../components/ScrollPaginator/ScrollPaginator';


class Clients extends Component {
  constructor(props) {
    super(props);
    const { dispatch } = props;
    dispatch(fetchAllClients());
  }

  render() {
    const {
      loads, client, history, dispatch,
    } = this.props;
    return (
      <>
        <HeaderTop history={history} dispatch={dispatch} loads={loads} />
        <div>
          {loads.clientsIsLoading && <LoadComponent />}
          {
            client.clients && (
              <ScrollPaginator
                allItems={client.clients.data}
                totalPages={client.clients.pages}
                itemsPerPage={25}
                currentPage={1}
                childComponent={<ClientList />}
                action={fetchAllClients}
                clientId=""
              />
            )
          }
        </div>
      </>
    );
  }
}

Clients.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  loads: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
  loads: state.loads,
  client: state.client,
});
export default withRouter(connect(mapStateToProps)(Clients));
