import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide,
} from '@material-ui/core';
import React from 'react';
import { toast } from 'react-toastify';
import FdeAPI from '../../../../api/FdeAPI';
import BankCheckOut from '../../../CheckOut/_components/constructor/components/BankCheckOut';
import MethodCheckOut from '../../../CheckOut/_components/constructor/components/MethodCheckOut';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const EditFde = ({ isOpen, handleClose, fde, updatedFde }) => {
  /* Payment selected */
  const [payment, setPayment] = React.useState(null);

  /* Bank selected */
  const [bank, setBank] = React.useState(null);


  const selectPaymentMethod = (pay) => {
    setPayment(pay);
    try {
      if (pay.body && pay.body.length === 1) {
        setBank(pay.body[0]);
      } else {
        setBank(null);
      }
    } catch (error) {
      toast.error('Error seleccionando el banco o caja');
    }
  };


  const selectBank = async (banc) => {
    await setBank(null);
    await setBank(banc);
  };

  const editFde = async () => {
    toast.success('Actualizando flujo de efectivo');
    const fdeApi = new FdeAPI();
    const editedFde = {
      ...fde,
      tfde_metodo: payment.method,
      cuenta: bank.referencia || bank.entity_label,
      cuenta_bancaria: bank.crmid,
    };

    const response = await fdeApi.put(editedFde);
    const { success, result } = response;

    if (success && result) {
      updatedFde(result);
      toast.success('Flujo de efectivo actualizado');
    } else {
      toast.success('Error actualizando flujjo de efectivo');
    }

    handleClose();
  };

  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        Editar flujo
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          <MethodCheckOut payment={payment} selectPayment={pay => selectPaymentMethod(pay)} />
          {
        payment && (
          <BankCheckOut payment={payment} bank={bank} setBank={banc => selectBank(banc)} setPayIndex={() => {}} currency={fde.currency_code} />
        )
      }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={editFde} color="primary">
          Acceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditFde;
