import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { faBellSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Avatar, List, ListItem, ListItemAvatar, ListItemText,
} from '@material-ui/core';
import moment from 'moment';
import TaskApi from '../../../../api/TaskApi';
import { setTimerTracking } from '../../../../redux/actions/taskActions';

const TrackingTask = () => {
  const taskApi = new TaskApi();
  const dispatch = useDispatch();
  const selectedTask = useSelector(state => state.tasks.selectedTask);
  const timerData = useSelector(state => state.tasks.timerTracking);
  const user = useSelector(state => state.authUser.user);


  const getControlTime = async () => {
    const response = await taskApi.getTaskTimeTracking(1, '', selectedTask.crmid);
    const { result, success } = response;
    if (success && result.length) {
      dispatch(setTimerTracking(result));
    }
  };

  const formatShowDate = (date, action) => {
    const idx = date.indexOf('at');
    let fixDate = '';
    let subtring = '';
    if (idx !== -1) {
      fixDate = moment(date.replace('at', '')).format('DD MMM, h:mm a');
    } else {
      fixDate = moment(date.replace('en', '')).format('DD MMM, h:mm a');
    }
    subtring = fixDate.split(',');
    if (action === 'date') {
      return subtring[0];
    }

    return subtring[1];
  };

  React.useEffect(() => {
    getControlTime();
  }, []);

  return (
    <div className="update-container">
      {
          timerData.length > 0
            ? (
              <List classes={{ root: 'py-0' }} className="update-container-list">
                {timerData.map((item, i) => (
                  <div style={{ borderBottom: '8px solid #efeff4' }} key={i}>
                    <ListItem divider button>
                      <div className="update-time d-flex flex-column pr-2">
                        <span>{ formatShowDate(item.createdtime, 'date')}</span>
                        <span>{formatShowDate(item.createdtime, 'time')}</span>
                      </div>
                      <ListItemAvatar>
                        <Avatar classes={{ root: 'update-avatar-bg' }}>
                          <span className="update-icon">
                            <i className="far far fa-clock" />
                          </span>
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="Control de tiempo" secondary={`${moment.duration(moment(item.modifiedtime).diff(moment(item.createdtime))).asMinutes().toFixed(2)} min`} classes={{ primary: 'update-title', secondary: 'update-subtitle' }} />
                    </ListItem>
                    <ListItem divider button>
                      <div className="update-time d-flex flex-column pr-2">
                        <span>{ formatShowDate(item.modifiedtime, 'date')}</span>
                        <span>{formatShowDate(item.modifiedtime, 'time')}</span>
                      </div>
                      <ListItemAvatar>
                        <Avatar classes={{ root: 'update-avatar-bg' }} src="/img/ava.png" />
                      </ListItemAvatar>
                      <ListItemText primary={`${user.first_name} ${user.last_name}`} classes={{ primary: 'update-title', secondary: 'update-subtitle' }} />
                    </ListItem>
                  </div>
                ))
                    }
              </List>
            )
            : (
              <div className="empty-email">
                <FontAwesomeIcon
                  style={{
                    fontSize: 40, display: 'flex', justifyContent: 'center', alignItems: 'center',
                  }}
                  color="#64707A"
                  icon={faBellSlash}
                />
                <span>Sin registro de tiempo </span>
              </div>
            )
        }
    </div>
  );
};

export default TrackingTask;
