import React from 'react';
import PropTypes from 'prop-types';
import { Collapse, CircularProgress } from '@material-ui/core';


const ListLoading = ({
  isLoading,
}) => (
  <Collapse in={isLoading}>
    <div className="w-100 p-3 h-auto d-flex align-items-center justify-content-center">
      <CircularProgress color="primary" />
    </div>
  </Collapse>
);

ListLoading.propTypes = {
  isLoading: PropTypes.bool,
};

ListLoading.defaultProps = {
  isLoading: false,
};

export default ListLoading;
