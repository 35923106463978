/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './warehouse.styles.scss';
import {
  faAdjust,
  faClipboardList, faReplyAll, faShoppingCart, faTag,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List, ListItem, ListItemText } from '@material-ui/core';
import { Collapse } from 'reactstrap';
import Search from '../../components/Search';
import WPurchases from './components/WPurchases';
import WSales from './components/WSales';
import FilterFab from '../Pos/components/PosMobileActionsBar/components/FilterFab';
import { fetchAllOrders } from '../../redux/actions/orderActions';
import { fetchAllProducts } from '../../redux/actions/productActions';
import { fetchListQuotes } from '../../redux/actions/cotizarActions';
import { setOrderFilter } from '../../redux/actions/authUserActions';
import SearchFab from '../Pos/components/PosMobileActionsBar/components/SearchFab';
import WDeliver from './components/WDeliver';
import WTransfers from './components/WTransfers';
import WTransferProccess from './components/WTransferProccess';


const Warehouse = () => {
  const dispatch = useDispatch();
  const cart = useSelector(state => state.cart);
  const authUser = useSelector(state => state.authUser);
  const loads = useSelector(state => state.loads);
  const [option, setOption] = React.useState('sales');
  const loading = Boolean(loads.profileIsLoading);

  const dispatchFilterOrders = word => dispatch(fetchAllOrders(word));
  /* const dispatchFilterOrders = word => dispatch(fetchAllOrders(word)); */
  const dispatchFilterProducts = word => dispatch(fetchAllProducts(word));
  const dispatchFilterCotizaciones = word => dispatch(fetchListQuotes(1, word));
  const setFilter = value => dispatch(setOrderFilter(value, false));
  const fetchOrders = () => dispatch(fetchAllOrders());

  const setSelectOption = (section) => {
    localStorage.setItem('deliverOption', section);
    setOption(section);
  };


  useEffect(() => {
    const optionSelected = localStorage.getItem('deliverOption');
    console.log('optionSelected', optionSelected);
    if (optionSelected) {
      setOption(optionSelected);
    } else {
      setOption('sales');
    }
  }, []);

  return (
    <div className="warehouse-container">
      {/* Search */}
      {
        option === 'sales' ? (
          <div className="bg-white w-100 d-flex justify-content-between align-items-center px-2 sticky-top">
            <SearchFab
              cart={cart}
              dispatchFilterOrders={dispatchFilterOrders}
              dispatchFilterProducts={dispatchFilterProducts}
              dispatchFilterCotizaciones={dispatchFilterCotizaciones}
            />
            <FilterFab
              authUser={authUser}
              setFilter={setFilter}
              loading={loading}
              fetchOrders={fetchOrders}
            />
          </div>
        ) : null
      }


      {
        option === 'purchase' && (<WPurchases />)
      }

      {
        option === 'sales' && (<WSales />)
      }
      {
        option === 'deliver' && (<WDeliver />)
      }

      {
        option === 'transfers' && (<WTransfers />)
      }

      {
        option === 'transfers-process' && (<WTransferProccess />)
      }

      {/* Empty value */}
      <Collapse in={false}>
        <List dense>
          <ListItem classes={{ root: 'bg-white' }}>
            <ListItemText
              primary="Sin datos"
              secondary={`No se encontraron ${option === 'sales' ? 'ventas' : 'compras'}`}
            />
          </ListItem>
        </List>
      </Collapse>

      {/* Footer options */}
      <div className="warehouse-container__footer p-2 bg-white w-100 d-flex justify-content-around align-items-center">
        <div role="button" onClick={() => setSelectOption('sales')} className="d-flex justify-content-around align-items-center flex-column">
          <FontAwesomeIcon style={{ fontSize: 20, color: option === 'sales' ? '#313C52' : '#B6BEC6' }} icon={faClipboardList} />
          <span style={{ fontSize: 14, color: option === 'sales' ? '#313C52' : '#B6BEC6' }}>Ventas</span>
        </div>
        <div role="button" onClick={() => setSelectOption('purchase')} className="d-flex justify-content-around align-items-center flex-column">
          <FontAwesomeIcon style={{ fontSize: 20, color: option === 'purchase' ? '#313C52' : '#B6BEC6' }} icon={faShoppingCart} />
          <span style={{ fontSize: 14, color: option === 'purchase' ? '#313C52' : '#B6BEC6' }}>Compras</span>
        </div>
        <div role="button" onClick={() => setSelectOption('deliver')} className="d-flex justify-content-around align-items-center flex-column">
          <FontAwesomeIcon style={{ fontSize: 20, color: option === 'deliver' ? '#313C52' : '#B6BEC6' }} icon={faReplyAll} />
          <span style={{ fontSize: 14, color: option === 'deliver' ? '#313C52' : '#B6BEC6', textAlign: 'center' }}>
            Externas
          </span>
        </div>
        <div role="button" onClick={() => setSelectOption('transfers')} className="d-flex justify-content-around align-items-center flex-column">
          <FontAwesomeIcon style={{ fontSize: 20, color: option === 'transfers' ? '#313C52' : '#B6BEC6' }} icon={faAdjust} />
          <span style={{ fontSize: 14, color: option === 'deliver' ? '#313C52' : '#B6BEC6', textAlign: 'center' }}>
            R. Trans
          </span>
        </div>
        <div role="button" onClick={() => setSelectOption('transfers-process')} className="d-flex justify-content-around align-items-center flex-column">
          <FontAwesomeIcon style={{ fontSize: 20, color: option === 'transfers-process' ? '#313C52' : '#B6BEC6' }} icon={faTag} />
          <span style={{ fontSize: 14, color: option === 'transfers-process' ? '#313C52' : '#B6BEC6', textAlign: 'center' }}>
            P. Trans
          </span>
        </div>
      </div>
    </div>
  );
};

export default Warehouse;
