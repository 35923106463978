/* eslint-disable camelcase */
/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import { toolsHelper } from '../../../../helpers';

class TicketToPrint extends React.Component {
  static propTypes = {
    desc: PropTypes.string,
    store: PropTypes.string,
    Total: PropTypes.string,
    client: PropTypes.string,
    precio: PropTypes.string,
    orgData: PropTypes.string,
    subtotal: PropTypes.string,
    quantity: PropTypes.string,
    productName: PropTypes.string,
    tabs: PropTypes.object.isRequired,
    cart: PropTypes.object.isRequired,
    customer: PropTypes.object.isRequired,
    authUser: PropTypes.object.isRequired,
    discount: PropTypes.object.isRequired,
    discoutnExist: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    Total: 'Total',
    precio: 'Precio',
    store: 'Sucursal',
    desc: 'Descripción ',
    subtotal: 'Subtotal: ',
    quantity: 'Cantidad ',
    productName: 'Producto ',
    client: 'Datos del cliente ',
    orgData: 'Factura en Linea en  ',
  };

  getTotal = (discount, cart) => {
    let { total } = cart;
    if (discount.haveDiscount) {
      total = (total - discount.total).toFixed(2);
      total = Number.parseFloat(total);
    }
    return toolsHelper.formatMoney(total);
  };

  render() {
    const {
      tabs,
      desc,
      cart,
      store,
      Total,
      client,
      precio,
      orgData,
      discount,
      customer,
      subtotal,
      quantity,
      authUser,
      productName,
      discoutnExist,
    } = this.props;
    const { posTypeTab } = tabs;
    const { selectedCustomer } = customer;
    const {
      products, description, subTotal, totalIva,
      totalProducts,
      orderSelected,
    } = cart;
    const {
      user: {
        config,
        logoname,
        organizationname,
        almacenes,
      },
    } = authUser;
    const {
      phone, email1, address, accountname,
    } = selectedCustomer;
    const orgTemp = authUser.domain.split('.');
    const facturar = `clientes.${orgTemp[1]}.com`;
    const currentStore = config.store.name || config.store.almacen;
    const configStore = config.store.campos || '';
    const scale = config.pos_print_size ? config.pos_print_size : '7cm';
    const { haveDiscount } = discount;
    const assigned_user = (Object.keys(orderSelected).length > 0 &&  orderSelected.order.assigned_user)
      ? orderSelected.order.assigned_user : '';

    // Buscando almacen seleccionado
    const selectedStore = almacenes.find(alm => alm.crmid === config.store.crmid);

    return (
      <table style={{ maxWidth: scale, minWidth: scale }} className="tickets_table">
        {configStore.includes('Imagen') && (
          <thead>
            <tr>
              <th colSpan={5}>
                <img
                  src={config.store.image || logoname}
                  alt={organizationname}
                  className="tickets_table_logo"
                />
              </th>
            </tr>
          </thead>
        )}
        <thead>
          <tr>
            <th colSpan={5}>{`${store} : ${currentStore}`}</th>
          </tr>
          <tr>
            <th className="tickets_table_type" colSpan={5}>
              {posTypeTab === 'Historico'
                ? 'TICKET DE VENTA'
                : 'TICKET DE PRE-SURTIDO'}
            </th>
          </tr>
        </thead>
        {posTypeTab === 'Historico' && configStore.includes('No. de Venta') && (
          <thead>
            <tr><td className="tickets_table_client" colSpan={5}>{cart.orderSelected.order.label || ''}</td></tr>
          </thead>
        )}
        {configStore.includes('Cliente') && (
          <thead>
            <tr>
              <th colSpan={5}>----------------------------</th>
            </tr>
          </thead>
        )}
        {configStore.includes('Cliente') && (
          <thead>
            <tr>
              <th colSpan={5}>{client}</th>
            </tr>
          </thead>
        )}
        {configStore.includes('Cliente') && (
          <tbody>
            <tr>
              <td colSpan={5} className="tickets_table_client">
                {accountname}
              </td>
            </tr>
            {address !== ', , , , , , ' && (
              <tr>
                <td colSpan={5} className="tickets_table_client">
                  {address}
                </td>
              </tr>
            )}
            <tr>
              <td colSpan={5} className="tickets_table_client">
                {phone}
              </td>
            </tr>
            <tr>
              <td colSpan={5} className="tickets_table_client">
                {email1}
              </td>
            </tr>
          </tbody>
        )}
        <thead>
          <tr>
            <th colSpan={5}>----------------------------</th>
          </tr>
        </thead>
        {posTypeTab === 'Vender' ? (
          <thead>
            <tr>
              <th>{productName}</th>
              <th>{quantity}</th>
            </tr>
          </thead>
        ) : (
          <thead>
            <tr className="tickets_table_title_product">
              <th colSpan={2}>{productName}</th>
              <th>{precio}</th>
              {discoutnExist && (
                <th>%</th>
              )}
              <th>{Total}</th>
            </tr>
          </thead>
        )}
        {posTypeTab === 'Vender' ? (
          <tbody>
            {products.map(product => (
              <tr key={`key_row_${product.crmid}`}>
                <td>{`${product.productname} ${product.opcion}`}</td>
                <td className="tickets_table_qty">{product.quantity}</td>
              </tr>
            ))}
          </tbody>
        ) : (
          <tbody>
            {products.map(product => (
              <tr key={`key_row_${product.crmid}`}>
                <td
                  colSpan={2}
                  className="tickets_table_desc"
                >
                  {`(${product.quantity}) ${product.productname}`}
                </td>
                <td className="tickets_table_subTotal">
                  {toolsHelper.formatMoney(product.unit_price)}
                </td>
                {discoutnExist && (
                  <td className="tickets_table_subTotal">
                    {product.discount_percent > 0 ? (
                      <div className="w-100 h-100 d-flex flex-column align-items-center pt-3">
                        <span>{`${toolsHelper.numberFormat(product.discount_percent)}%`}</span>
                        <span>{`(${toolsHelper.numberFormat(product.discount_amount)})`}</span>
                      </div>
                    ) : (
                      '0%'
                    )}
                  </td>
                )}
                <td className="tickets_table_totales">
                  {toolsHelper.formatMoney(product.quantity * product.unit_price - product.discount_amount)}
                </td>
              </tr>
            ))}
          </tbody>
        )}
        <thead>
          <tr>
            <th colSpan={4}>------------------------------------------</th>
          </tr>
        </thead>
        {posTypeTab === 'Historico' ? (
          <tbody>
            <tr>
              <td className="tickets_table_subtotal" colSpan={2}>{subtotal}</td>
              <td className="tickets_table_subtotal" colSpan={3}>{`$ ${toolsHelper.formatMoney(subTotal)}`}</td>
            </tr>
            <tr>
              <td className="tickets_table_subtotal" colSpan={2}>IVA-total:</td>
              <td className="tickets_table_subtotal" colSpan={3}>{`$ ${toolsHelper.formatMoney(totalIva)}`}</td>
            </tr>
            {haveDiscount && (
              <tr>
                <td className="tickets_table_subtotal" colSpan={2}>{`Descuento ${discount.name}: `}</td>
                <td className="tickets_table_subtotal" colSpan={3}>{`${discount.percentage !== 0 ? (`(${discount.percentage}%)`) : ('')} $ ${toolsHelper.formatMoney(discount.total) || ''}`}</td>
              </tr>
            )}
            <tr>
              <td className="tickets_table_subtotal" colSpan={2}>{Total}</td>
              <td className="tickets_table_total" colSpan={3}>{`$ ${this.getTotal(discount, cart)}`}</td>
            </tr>
          </tbody>
        ) : (
          <tr>
            <td className="tickets_table_subtotal" colSpan={2}>Total a surtir:</td>
            <td className="tickets_table_subtotal" colSpan={3}>{`${totalProducts}`}</td>
          </tr>
        )}
        {description !== '' && (
          <thead>
            <tr>
              <th colSpan={5}>{desc}</th>
            </tr>
          </thead>
        )}
        {description !== '' && (
          <tbody>
            <tr>
              <td colSpan={2} className="tickets_table_client">
                {description}
              </td>
            </tr>
          </tbody>
        )}
        {description !== '' && (
          <thead>
            <tr>
              <th colSpan={5}>----------------------------</th>
            </tr>
          </thead>
        )}
        <thead>
          <tr>
            <th colSpan={5}>----------------------------</th>
          </tr>
        </thead>
        {posTypeTab === 'Historico' ? (
          <tbody>
            {configStore.includes('Fecha') && (
              <tr>
                <th className="font-size-11 text-center font-weight-normal" colSpan={5}>{new Date(`${cart.orderSelected.order.createdtime} UTC`).toLocaleDateString('es-ES', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' })}</th>
              </tr>
            )}
          </tbody>
        ) : (
          <tbody>
            {configStore.includes('Fecha') && (
              <tr>
                <th className="font-size-11 text-center font-weight-normal" colSpan={5}>{new Date().toLocaleString()}</th>
              </tr>
            )}
          </tbody>
        )}
        <tbody>
          {configStore.includes('Teléfono') && (
            <tr>
              <th className="font-size-11 text-center font-weight-normal" colSpan={5}>{`TEL. ${config.store.telefono}`}</th>
            </tr>
          )}
          {configStore.includes('Dirección') && (
            <tr>
              <th className="font-size-11 text-center font-weight-normal" colSpan={5}>{config.store.direccion}</th>
            </tr>
          )}
          {configStore.includes('Página web') && (
            <tr>
              <th className="font-size-11 text-center font-weight-normal" colSpan={5}>{config.store.url_web}</th>
            </tr>
          )}
          {configStore && configStore.includes('Atendido') && (
            <tr>
              <th className="font-size-11 text-center font-weight-normal" colSpan={5}>{`Atendido por: ${assigned_user}`}</th>
            </tr>
          )}
        </tbody>
        <thead>
          <tr>
            <th colSpan={5}>--------------------------------------</th>
          </tr>
        </thead>
        {configStore.includes('Facturar en Portal de Clientes') && (
          <thead>
            <tr>
              <th colSpan={5}>{`${orgData} : ${facturar}`}</th>
            </tr>
          </thead>
        )}
        {posTypeTab === 'Historico' && configStore.includes('Facturar en Portal de Clientes') && (
          <thead>
            <tr>
              <th colSpan={5}>
                <QRCode value={facturar} />
              </th>
            </tr>
          </thead>
        )}
        <thead>
          <tr>
            <th colSpan={5}>--------------------------------------</th>
          </tr>
        </thead>
        {selectedStore && selectedStore.tpv_leyenda_tiket && (
          <thead>
            <tr>
              <th colSpan={5}>
                <div style={{ maxWidth: 600 }} dangerouslySetInnerHTML={{ __html: selectedStore.tpv_leyenda_tiket }} />
              </th>
            </tr>
          </thead>
        )}
      </table>
    );
  }
}

export default TicketToPrint;
