import React from 'react';
import PropTypes from 'prop-types';
import './LoginFormMobile.scss';
import {
  Input,
  Button,
  InputLabel,
  IconButton,
  FormControl,
  InputAdornment,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

const LoginFormMobile = ({ onSubmit }) => {
  const [viewPass, setViewPass] = React.useState(false);
  return (
    <div className="loginm">
      <div className="loginm_cards">
        <div className="loginm_card">
          <img
            src={`${process.env.PUBLIC_URL}/img/logo.svg`}
            alt="comercia.io"
          />
          <form onSubmit={onSubmit}>
            <FormControl margin="normal" fullWidth>
              <InputLabel htmlFor="domain">Cuenta</InputLabel>
              <Input
                name="domain"
                type="domain"
                id="domain"
                autoComplete="url"
              />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="username">Nombre Usuario</InputLabel>
              <Input
                id="username"
                name="username"
                autoComplete="username"
                  // defaultValue={process.env.REACT_APP_DOMAIN === 'pruebas.comercia.io' ? 'demo' : ''}
                autoFocus
              />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="password">Contraseña</InputLabel>
              <Input
                id="password"
                name="password"
                type={viewPass ? 'text' : 'password'}
                autoComplete="current-password"
                endAdornment={(
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={() => setViewPass(!viewPass)}
                    >
                      {viewPass ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )}
                onBlur={() => setViewPass(false)}
              />
            </FormControl>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
            >
              Acceder
            </Button>
            <Button
              fullWidth
              color="secondary"
              type="anchor"
              href="browser://blanck?link=comercia.io/registro"
            >
              Registrate
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

LoginFormMobile.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default LoginFormMobile;
