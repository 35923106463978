import React from 'react';
import PropTypes from 'prop-types';
import './ScrolToTpButton.scss';
import { Fab, Zoom, CircularProgress } from '@material-ui/core';
import { KeyboardArrowUp } from '@material-ui/icons';
import { withRouter } from 'react-router-dom';

const ScrollTopButton = ({
  location,
  loading,
}) => {
  const [appear, setApper] = React.useState(false);
  const listenScroll = () => {
    if (appear === false && window.pageYOffset !== 0) {
      setApper(true);
    } else {
      setApper(false);
    }
  };

  const setListener = () => {
    document.addEventListener('scroll', listenScroll);
  };

  React.useEffect(() => {
    setListener();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => () => {
    document.removeEventListener('scroll', listenScroll);
    // eslint-disable-next-line
  }, []);

  const scrollToTop = () => {
    window.scroll(0, 0);
  };

  return (
    <div className="scrollTop_btn">
      <Zoom in={appear || loading}>
        <Fab
          className="scrollTop_btn"
          color={location.pathname.includes('/pos/venta') ? 'secondary' : 'primary'}
          onClick={() => { if (!loading) scrollToTop(); }}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <KeyboardArrowUp />
          )}
        </Fab>
      </Zoom>
    </div>
  );
};

ScrollTopButton.propTypes = {
  location: PropTypes.object,
  loading: PropTypes.bool,
};
ScrollTopButton.defaultProps = {
  location: {},
  loading: false,
};

export default withRouter(ScrollTopButton);
