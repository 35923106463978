/* eslint-disable import/no-cycle */
import {
  FETCH_TIMBRADO,
  HANDLE_MAKEBILL,
  PRINT_BILL,
  RESET_TIMBRADO,
  SAVE_TIMBRADO,
} from '../actions/TimbrarActions';

import { toolsHelper } from '../../helpers';

const getDefaultState = () => ({
  crmid: '',
  rs_receptor: '',
  rfc_receptor: '',
  def: {},
  usocfdi: [],
  formaspago: [],
  metodospago: [],
  tc: 0,
  confirma: {},
  makeBill: false,
  ticket: {},
});

const handleMakeBill = (state, { makeBill }) => ({ ...state, makeBill });

const printBill = (state, { result }) => {
  const conceptos = toolsHelper.stringToJson(result.conceptos);
  const impuestos = toolsHelper.stringToJson(result.impuestos);
  const ticket = {
    ...result,
    conceptos,
    impuestos,
  };
  return { ...state, ticket };
};

export default function (state = getDefaultState(), action) {
  const { type } = action;
  switch (type) {
    case FETCH_TIMBRADO:
      return { ...state, ...action.response };
    case SAVE_TIMBRADO:
      return { ...state, ...getDefaultState() };
    case HANDLE_MAKEBILL:
      return handleMakeBill(state, action);
    case RESET_TIMBRADO:
      return getDefaultState();
    case PRINT_BILL:
      return printBill(state, action);
    default:
      return state;
  }
}
