import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import {
  Collapse,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from '@material-ui/core';
// eslint-disable-next-line import/order
import MoreVert from '@material-ui/icons/MoreVert';
import Share from '@material-ui/icons/Share';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { toast } from 'react-toastify';
import SatLogo from '../../../Icons/SatLogo';
import { toolsHelper } from '../../../../helpers';
import { closeModal } from '../../../../redux/actions/modalActions';
import VerificarCobro from '../timbrar/VerificarCobro';
import Sharemenu from '../timbrar/Sharemenu';
import EditFde from '../EditFde';
import { Edit } from '@material-ui/icons';

const FdeList = ({
  fde,
  fetchFde,
  isLoading,
  isOpen,
}) => {
  React.useEffect(() => {
    if (isOpen) {
      fetchFde();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [fdeSelected, setFdeSelected] = React.useState(null);
  const [fdeSelectedObj, setFdeSelectedObj] = React.useState(null);
  const [optionSelected, setOptionSelected] = React.useState(null);
  const open = Boolean(anchorEl);
  const [openShare, setOpenShare] = React.useState(false);
  const [openVerification, setOpenVerification] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [selectedFde, setSelectedFde] = React.useState(null);

  const dispatch = useDispatch();
  const cart = useSelector(state => state.cart);
  const { orderSelected: { order } } = cart;
  const disableMenu = !(order?.estadosat !== 'Cancelado' && order?.metodopago === 'PPD' && fde.xmlpago === undefined);
  const config = useSelector(state => state.authUser.user.config);
  const { pos_no_facturar } = config;
  const canMakeInvoice = pos_no_facturar === '1' ? true : !disableMenu;

  const handleClickOpen = () => {
    setOpenVerification(true);
  };
  const handleClickOpenShare = () => {
    setOpenShare(true);
  };

  const handleCloseVerificacion = () => {
    setOpenVerification(false);
  };
  const handleCloseShare = () => {
    setOpenShare(false);
  };

  const handleClick = (event, item) => {
    setFdeSelected(item.crmid);
    setFdeSelectedObj(item);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (option) => {
    setOptionSelected(option);
    setAnchorEl(null);
    if (option === 'Timbrar') {
      handleClickOpen();
    }
    if (option === 'Compartir') {
      handleClickOpenShare();
    }
  };


  const handleEdit = async (flujo) => {
    await setSelectedFde(flujo);
    await setOpenEdit(true);
    await setAnchorEl(null);
  };


  const updatedFde = (newFde) => {
    const index = fde.findIndex(f => f.crmid === newFde.crmid);
    if (index !== -1) {
      fde.splice(index, 1, newFde);
    } else {
      toast.error('No se encontro el flujo que intenta editar');
    }
  };

  const closeAll = () => {
    handleCloseVerificacion();
    handleCloseShare();
    dispatch(closeModal('fde'));
    /* onClose(); */
  };
  return (
    <div className="w-100 h-auto">
      <Collapse in={isLoading}>
        <LinearProgress />
      </Collapse>
      <Collapse in={!isLoading}>
        {fde.length > 0 ? (
          <List className="fde_list">
            {fde.map(x => (
              <ListItem key={`key_${x.crmid}`} divider>
                <div className="w-100 h-100 d-flex justify-content-between align-items-center">
                  <div
                    className={
                      `fde_list_icon ${
                        x.tfde_tipo === 'Ingreso' ? (
                          'fde_list_icon_in'
                        ) : (
                          'fde_list_icon_out'
                        )
                      }`
                    }
                  >
                    {x.tfde_tipo === 'Ingreso' ? (
                      <FontAwesomeIcon icon={faSignOutAlt} />
                    ) : (
                      <FontAwesomeIcon
                        icon={faSignInAlt}
                        style={{
                          transform: 'rotate(180deg)',
                        }}
                      />
                    )}
                  </div>
                  <div className="d-flex flex-column justify-content-between mr-auto ml-2">
                    <span className="fde_list_meth">{x.tfde_metodo}</span>
                    <span className="fde_list_acco">{x.cuenta}</span>
                  </div>
                  <div className="d-flex flex-column justify-content-between">
                    <span className="fde_list_mont">
                      {`$ ${toolsHelper.formatMoney(
                        x.monto,
                      )}`}
                    </span>
                    <span className="fde_list_date">
                      {`${x.createdtime}`}
                    </span>
                  </div>
                  <div>
                    <IconButton edge="end" aria-label="comments" onClick={event => handleClick(event, x)}>
                      <MoreVert />
                    </IconButton>
                    <Menu
                      id="long-menu"
                      className="horiz-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={open}
                      onClose={() => handleClose()}
                    >
                      <MenuItem onClick={() => handleClose('Compartir')}>
                        <Share fill="#65707a" />
                        Compartir
                      </MenuItem>
                      <MenuItem disabled={canMakeInvoice} onClick={() => handleClose('Timbrar')}>
                        <SatLogo fill="#65707a" />
                        Timbrar
                      </MenuItem>
                      <MenuItem onClick={() => handleEdit(x)}>
                        <Edit fill="#65707a" />
                        Editar
                      </MenuItem>
                    </Menu>
                  </div>
                </div>
              </ListItem>
            ))}
          </List>
        ) : (
          <List dense>
            <ListItem>
              <ListItemText
                primary="Sin flujos de efectivo"
                secondary="La orden no cuenta con movimientos de efectivo"
              />
            </ListItem>
          </List>
        )}
      </Collapse>
      <VerificarCobro open={openVerification} handleClose={handleCloseVerificacion} typo={optionSelected} fde={fdeSelected} closeAll={closeAll} />
      <Sharemenu open={openShare} handleClose={handleCloseShare} typo={optionSelected} fde={fdeSelectedObj} />
      <EditFde isOpen={openEdit} handleClose={() => setOpenEdit(false)} fde={selectedFde} updatedFde={updatedFde} />
    </div>
  );
};

FdeList.propTypes = {
  fde: PropTypes.array.isRequired,
  fetchFde: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default FdeList;
