/* eslint-disable import/no-cycle */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import './SalesDetail.scss';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDolly, faFileInvoice, faHandHoldingUsd,
  faShareAlt,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import Iconwidthbg from '../../../../components/Icons/IconWidthBG/IconWidthBG';
import DetailBtn from '../../../../components/Buttons/detailButton/detailButton';
import { addPreselectOrder } from '../../../../redux/actions/orderActions';
import {
  formatMoney, geteColorSosStatus, getColorStatusMDA, getColorStatusFDE, getColorStatusSAT,
} from '../../../../helpers/tools';
import SatLogo from '../../../../components/Icons/SatLogo';
import ShareModal from '../../../../components/Share/components/ShareModal';
import appModules from '../../../../constant/appModules';

const SalesDetail = withRouter(({ data, history }) => {
  const dispatch = useDispatch();
  const loads = useSelector(state => state.loads);
  const [open, setOpen] = React.useState(false);

  const isLoading = Boolean(loads && loads.emailTemplateIsLoading);
  const title = `Compartir Orden ${data.salesorder_no}`;

  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const goTo = async () => {
    history.push('/pos/ventas');
  };

  const handleClickDetail = async () => {
    await dispatch(addPreselectOrder(data));
    history.push(`/pos/venta/${data.crmid}`);
  };

  return (
    <>
      <List component="nav" aria-label="main mailbox folders" dense>
        <ListItem className="pt-0">
          <ListItemText
            className="ml-2 text-left pt-0"
            primary={data.accountname || ''}
            secondary={moment(data.modifiedtime).format('DD MMM, h:mm a')}
            classes={{
              primary: 'salesDetail-text-primary', // class name, e.g. `classes-nesting-root-x`
              secondary: 'salesDetail-text-secondary', // class name, e.g. `classes-nesting-label-x`
            }}
          />
        </ListItem>
        <ListItem selected classes={{ selected: 'salesDetail-list-selected' }}>
          <ListItemText className="ml-2 text-left" primary="Cobrado:" classes={{ primary: 'salesDetail-list-text-left' }} />
          <ListItemText className="p-0 text-right" primary={`$ ${formatMoney(data.total)} ${data.currency_code}`} classes={{ primary: 'salesDetail-list-text-right' }} />
        </ListItem>
        <ListItem>
          <ListItemText className="ml-2 text-left" primary="Por cobrar:" classes={{ primary: 'salesDetail-list-text-left' }} />
          <ListItemText className="p-0 text-right" primary={`$ ${formatMoney(data.tfde_balance)}`} />
        </ListItem>
        <ListItem selected classes={{ selected: 'salesDetail-list-selected' }}>
          <ListItemText className="ml-2 text-left" primary="Estado:" classes={{ primary: 'salesDetail-list-text-left' }} />
          <ListItemText className="px-1 text-right" primary={data.sostatus} classes={{ primary: 'salesDetail-list-text-right' }} />
          <Iconwidthbg
            primaryColor={geteColorSosStatus(data.sostatus)}
            secondaryColor={`${data.sostatus === 'Created' || data.sostatus === 'Creado' ? '#B6BEC6' : 'white'}`}
            width={27}
            height={27}
            classes="ml-2"
            enabled
            square
            rounded
            actionClick={() => {}}
          >
            <FontAwesomeIcon style={{ fontSize: 15 }} icon={faFileInvoice} />
          </Iconwidthbg>
        </ListItem>
        <ListItem>
          <ListItemText className="ml-2 text-left" primary="Movimiento almacen:" classes={{ primary: 'salesDetail-list-text-left' }} />
          <ListItemText className="px-1 text-right" primary={data.estado_mda} classes={{ primary: 'salesDetail-list-text-right' }} />
          <ListItemIcon className="m-0">
            <Iconwidthbg
              primaryColor={getColorStatusMDA(data.estado_mda)}
              secondaryColor={`${data.estado_mda === 'Sin Entregar' ? '#B6BEC6' : 'white'}`}
              width={27}
              height={27}
              classes="ml-2"
              enabled
              square
              rounded
              actionClick={() => {}}
            >
              <FontAwesomeIcon style={{ fontSize: 15 }} icon={faDolly} />
            </Iconwidthbg>
          </ListItemIcon>
        </ListItem>
        <ListItem selected classes={{ selected: 'salesDetail-list-selected' }}>
          <ListItemText className="ml-2 text-left" primary="Flujo de efectivo:" classes={{ primary: 'salesDetail-list-text-left' }} />
          <ListItemText className="px-1 text-right" primary={data.estado_fde} classes={{ primary: 'salesDetail-list-text-right' }} />
          <ListItemIcon className="m-0">
            <Iconwidthbg
              primaryColor={getColorStatusFDE(data.estado_fde)}
              secondaryColor={`${data.estado_fde === 'Sin Pago/Cobro' ? '#B6BEC6' : 'white'}`}
              width={27}
              height={27}
              classes="ml-2"
              enabled
              square
              rounded
              actionClick={() => {}}
            >
              <FontAwesomeIcon style={{ fontSize: 15 }} icon={faHandHoldingUsd} />
            </Iconwidthbg>
          </ListItemIcon>
        </ListItem>
        <ListItem>
          <ListItemText className="ml-2 text-left" primary="Estado de factura:" classes={{ primary: 'salesDetail-list-text-left' }} />
          <ListItemText className="px-1 text-right" primary={`${data.estadosat ? data.estadosat : 'No Facturado'}`} classes={{ primary: 'salesDetail-list-text-right' }} />
          <ListItemIcon className="m-0">
            <Iconwidthbg
              primaryColor={getColorStatusSAT(data.estadosat)}
              secondaryColor={`${data.estadosat === null ? '#B6BEC6' : 'white'}`}
              width={27}
              height={27}
              classes="ml-2"
              enabled
              square
              rounded
              actionClick={() => {}}
            >
              <SatLogo fill={`${data.estadosat === null ? '#B6BEC6' : 'white'}`} width="15px" height="15px" />
            </Iconwidthbg>
          </ListItemIcon>
        </ListItem>
        <ListItem selected classes={{ selected: 'salesDetail-list-selected' }}>
          <ListItemText className="ml-2 text-left" primary="Vendido por:" classes={{ primary: 'salesDetail-list-text-left' }} />
          <ListItemText className="p-0 text-right" primary={`${data.assigned_user ? data.assigned_user : 'No asignado'}`} classes={{ primary: 'salesDetail-list-text-right' }} />
        </ListItem>
      </List>
      <List component="nav" aria-label="secondary mailbox folders" className="d-flex justify-content-around">
        <DetailBtn
          title="Ver venta"
          color="#23A1DB"
          hadnleClick={() => handleClickDetail()}
          icon={<FontAwesomeIcon style={{ fontSize: 15 }} icon={faFileInvoice} />}
        />
        <DetailBtn
          title="Compartir"
          color="#23A1DB"
          hadnleClick={() => handleClickOpen()}
          icon={<FontAwesomeIcon style={{ fontSize: 15 }} icon={faShareAlt} />}
        />
      </List>

      <ShareModal
        open={open}
        title={title}
        handleClose={handleClose}
        order={data || {}}
        template={appModules.SalesOrder}
        isLoading={isLoading}
      />
    </>
  ); });
SalesDetail.prototype = {
  data: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};
export default SalesDetail;
