/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import DeliverDialog from '../../../../../../components/Deliver/components/DeliverDialog';

const DeliverModal = ({
  cart,
  table,
  loads,
  authUser,
  title,
  selectEmpty,
  changeComment,
  changeFieldValue,
  selectToRefunded,
  selectToDelivered,
  getOrderDocument,
  addItemsToTable,
  setActivityToOrder,
  history,
}) => {
  React.useEffect(() => {
    addItemsToTable('deliver', history);
    // eslint-disable-next-line
    }, []);
  return (
    <DeliverDialog
      isOpen
      table={table}
      loads={loads}
      authUser={authUser}
      dispatchSelectEmpty={selectEmpty}
      dispatchChangeComment={changeComment}
      dispatchChangeFieldValue={changeFieldValue}
      dispatchSelectToRefunded={selectToRefunded}
      dispatchSelectToDelivered={selectToDelivered}
      deliverEnableAction={table.enableAction}
      getOrderDocument={getOrderDocument}
      onClose={() => history.goBack()}
      isLoading={Boolean(loads.orderIsLoading)}
      title={title}
      onSave={() => {
        setActivityToOrder(
          cart.orderSelected.crmid,
          'Entregar',
          history,
        );
      }}
    />
  );
};

DeliverModal.propTypes = {
  cart: PropTypes.object,
  table: PropTypes.object,
  loads: PropTypes.object,
  authUser: PropTypes.object,
  title: PropTypes.string,
  selectEmpty: PropTypes.func,
  changeComment: PropTypes.func,
  changeFieldValue: PropTypes.func,
  selectToRefunded: PropTypes.func,
  selectToDelivered: PropTypes.func,
  getOrderDocument: PropTypes.func,
  addItemsToTable: PropTypes.func,
  setActivityToOrder: PropTypes.func,
  history: {},
};

DeliverModal.defaultProps = {
  cart: {},
  table: {},
  loads: {},
  authUser: {},
  title: '',
  selectEmpty: () => {},
  changeComment: () => {},
  changeFieldValue: () => {},
  selectToRefunded: () => {},
  selectToDelivered: () => {},
  getOrderDocument: () => {},
  addItemsToTable: () => {},
  setActivityToOrder: () => {},
  history: {},
};

export default withRouter(DeliverModal);
