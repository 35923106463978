/* eslint-disable react/prop-types */
/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import '../../../ClientPage/components/SalesDetail/SalesDetail.scss';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDolly,
  faFileInvoice,
  faHandHoldingUsd,
  faShareAlt,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import Iconwidthbg from '../../../../components/Icons/IconWidthBG/IconWidthBG';
import { getColorStatusFDE, getColorStatusMDA, geteColorSosStatus } from '../../../../helpers/tools';
import ShareModal from '../../../../components/Share/components/ShareModal';
import appModules from '../../../../constant/appModules';
import GenericFullModal from '../../../../components/GenericFullModal';
import ShippingGuideList from '../../../../components/ShippingGuide/ShippingGuideList';
import { selectOrder } from '../../../../redux/actions/orderActions';

const WSalesExpand = ({ sale }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const loads = useSelector(state => state.loads);
  const isLoading = Boolean(loads && loads.emailTemplateIsLoading);
  const [openShare, setOpenShare] = React.useState(false);
  const [openGuide, setOpenGuide] = React.useState(false);

  const handleShareClose = () => {
    setOpenShare(false);
    setOpenGuide(false);
  };

  const entregarOrden = async () => {
    await dispatch(selectOrder(sale.crmid));
    history.push('/pos/deliver/');
  };

  return (
    <>
      <List component="nav" aria-label="main mailbox folders" dense>
        <ListItem className="pt-0">
          <ListItemText
            className="ml-2 text-left pt-0"
            primary={`${sale.firstname} ${sale.lastname}`}
            secondary={moment(sale.createdtime).format('DD MMM, h:mm a')}
            classes={{
              primary: 'salesDetail-text-primary', // class name, e.g. `classes-nesting-root-x`
              secondary: 'salesDetail-text-secondary', // class name, e.g. `classes-nesting-label-x`
            }}
          />
        </ListItem>
        <ListItem selected classes={{ selected: 'salesDetail-list-selected' }}>
          <ListItemText className="ml-2 text-left" primary="Estado:" classes={{ primary: 'salesDetail-list-text-left' }} />
          <ListItemText className="p-0 text-right" primary={sale.sostatus} classes={{ primary: 'salesDetail-list-text-right' }} />
          <Iconwidthbg
            primaryColor={geteColorSosStatus(sale.sostatus)}
            secondaryColor={`${sale.sostatus === 'Created' || sale.sostatus === 'Creado' ? '#B6BEC6' : 'white'}`}
            width={27}
            height={27}
            classes="ml-2"
            enabled
            square
            rounded
            actionClick={() => {}}
          >
            <FontAwesomeIcon style={{ fontSize: 15 }} icon={faFileInvoice} />
          </Iconwidthbg>
        </ListItem>

        <ListItem classes={{ selected: 'salesDetail-list-selected' }}>
          <ListItemText className="ml-2 text-left" primary="Estado de almacen:" classes={{ primary: 'salesDetail-list-text-left' }} />
          <ListItemText className="p-0 text-right" primary={sale.estado_mda} classes={{ primary: 'salesDetail-list-text-right' }} />
          <Iconwidthbg
            primaryColor={getColorStatusMDA(sale.estado_mda)}
            secondaryColor={`${sale.estado_mda === 'Sin Entregar' ? '#B6BEC6' : 'white'}`}
            width={27}
            height={27}
            classes="ml-2"
            enabled
            square
            rounded
            actionClick={() => {}}
          >
            <FontAwesomeIcon style={{ fontSize: 15 }} icon={faDolly} />
          </Iconwidthbg>
        </ListItem>

        <ListItem selected classes={{ selected: 'salesDetail-list-selected' }}>
          <ListItemText className="ml-2 text-left" primary="Flujo de Efectivo:" classes={{ primary: 'salesDetail-list-text-left' }} />
          <ListItemText className="p-0 text-right" primary={sale.estado_fde} classes={{ primary: 'salesDetail-list-text-right' }} />
          <Iconwidthbg
            primaryColor={getColorStatusFDE(sale.estado_fde)}
            secondaryColor={`${sale.estado_fde === 'Sin Pago/Cobro' ? '#B6BEC6' : 'white'}`}
            width={27}
            height={27}
            classes="ml-2"
            enabled
            square
            rounded
            actionClick={() => {}}
          >
            <FontAwesomeIcon style={{ fontSize: 15 }} icon={faHandHoldingUsd} />
          </Iconwidthbg>
        </ListItem>

        <ListItem classes={{ selected: 'salesDetail-list-selected' }}>
          <ListItemText className="ml-2 text-left" primary="Asignado a:" classes={{ primary: 'salesDetail-list-text-left' }} />
          <ListItemText className="p-0 text-right" primary={sale.assigned_user} classes={{ primary: 'salesDetail-list-text-right' }} />
        </ListItem>

      </List>
      <List component="nav" aria-label="secondary mailbox folders" className="d-flex justify-content-around">
        <button
          type="button"
          onClick={() => setOpenShare(true)}
          style={{
            backgroundColor: '#23A1DB', color: 'white', fontSize: 15, padding: '10px 20px', borderRadius: 17, border: 0,
          }}
        >
          <FontAwesomeIcon style={{ fontSize: 15 }} icon={faShareAlt} />
        </button>
        <button
          type="button"
          onClick={() => setOpenGuide(true)}
          style={{
            backgroundColor: '#23A1DB', color: 'white', fontSize: 15, padding: '10px 20px', borderRadius: 17, border: 0,
          }}
        >
          Guias
        </button>
        <button
          type="button"
          onClick={() => entregarOrden()}
          style={{
            backgroundColor: '#23A1DB', color: 'white', fontSize: 15, padding: '10px 20px', borderRadius: 17, border: 0,
          }}
        >
          Entregar
        </button>
      </List>

      <ShareModal
        open={openShare}
        title={`Compartir venta ${sale ? sale.entity_label : ''}`}
        handleClose={handleShareClose}
        order={sale || {}}
        template={appModules.SalesOrder}
        isLoading={isLoading}
      />

      <GenericFullModal
        open={openGuide}
        title={`Venta ${sale ? sale.entity_label : ''}`}
        handleClose={handleShareClose}
        componentToShow={<ShippingGuideList itemCrmid={sale.crmid} />}
      />
    </>
  ); };

WSalesExpand.propType = {
  sale: PropTypes.object.isRequired,
};

export default WSalesExpand;
