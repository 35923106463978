import React from 'react';

const SatLogo = props => (
  <svg
    viewBox="0 0 150 150"
    {...props}
  >
    <path d="M7.6,113.5c0.2,0,0.3-0.2,0.3-0.3c0.2-1.4,0.9-2.6,1.7-3.8c1-1.4,2.3-2.4,3.8-3.3c1.9-1.1,4-1.8,6.1-2.3
        c1.6-0.4,3.3-0.7,5-0.9c1.5-0.2,3-0.3,4.5-0.3c0.9,0,1.8,0,2.7,0c1.5,0,3,0,4.4,0.1c1.2,0.1,2.5,0.1,3.7,0.2c2.1,0.2,4.2,0.6,6.3,1
        c0,0,0.1,0,0.1,0c0.7,0.2,0.9,0.4,0.9,1.2c0,0,0,0.1,0,0.1c0,2.9,0,5.8,0,8.7c0,0.9-0.1,0.7-0.7,0.5c-2.3-0.9-4.7-1.6-7.2-2
        c-1.2-0.2-2.4-0.4-3.6-0.5c-0.8-0.1-1.6-0.2-2.3-0.2c-0.5,0-0.9-0.1-1.4-0.1c-1.1,0-2.1,0.1-3.2,0.2c-0.7,0.1-1.3,0.4-1.9,0.8
        c-0.5,0.5-0.5,1.1,0,1.5c0.2,0.2,0.5,0.3,0.8,0.4c0.9,0.3,1.9,0.5,2.8,0.7c3,0.7,6,1.2,8.9,1.9c2.3,0.6,4.5,1.4,6.7,2.4
        c1.1,0.6,2.2,1.3,3.2,2.1c1,1,1.7,2.1,2,3.5c0.2,1.2,0,2.3-0.4,3.4c-0.9,2.4-2.5,4.1-4.6,5.4c-1.8,1.1-3.7,1.8-5.7,2.3
        c-0.2,0.1-0.4,0.1-0.6,0.2c-0.3,0.2-0.6,0.2-0.9,0.2c-0.8,0.2-1.6,0.3-2.5,0.5c-0.8,0.2-1.7,0.3-2.6,0.3c-0.4,0-0.4,0.1-0.3,0.5
        c-0.2,0-0.5,0-0.7,0c0-0.2-0.1-0.2-0.3-0.2c-0.6-0.1-1.1,0-1.7,0c-0.1,0-0.2,0.1-0.2,0.2c-2.4,0-4.9,0-7.3,0c0-0.3-0.2-0.3-0.4-0.3
        c-0.7,0-1.4,0.1-2-0.1c-0.2,0-0.5-0.1-0.7-0.1c-0.7-0.1-1.3-0.1-2-0.2c-1.2-0.1-2.4-0.3-3.6-0.4c-1.2-0.2-2.4-0.4-3.6-0.7
        c-0.7-0.2-1.4-0.5-2.1-0.5c-0.1,0-0.1,0-0.2,0c-0.3-0.1-0.5-0.3-0.5-0.6c0-0.1,0-0.2,0-0.4c0-3.1,0-6.2,0-9.4c0-0.8,0.1-0.9,0.9-0.7
        c1.2,0.4,2.4,0.7,3.6,1.2c3,1.2,6.1,1.7,9.3,2.2c1.4,0.2,2.7,0.3,4.1,0.4c1.4,0.1,2.7,0.1,4.1-0.1c0.7-0.1,1.3-0.3,1.9-0.8
        c0.5-0.4,0.5-1,0-1.5c-0.2-0.2-0.5-0.3-0.8-0.4c-0.8-0.2-1.5-0.4-2.3-0.6c-2.2-0.4-4.4-0.7-6.6-1.1c-2.4-0.5-4.7-1-7-1.9
        c-1.6-0.6-3.1-1.2-4.5-2.2c-1.4-1-2.5-2.2-2.9-3.9c0-0.1-0.1-0.3-0.1-0.4c0-0.1-0.1-0.2-0.2-0.2C7.6,114.8,7.6,114.2,7.6,113.5z
         M94.3,12.5c-0.2,0.2-0.5,0.3-0.8,0.3c-0.8,0.1-1.6,0.2-2.4,0.4c-2.8,0.7-5.2,2-7.3,3.9c-2,1.8-3.6,3.9-4.6,6.3
        c-0.8,1.8-1.3,3.7-1.4,5.7c-0.1,1.3,0,2.7,0.1,4c0.3,2.3,0.9,4.5,2.1,6.5c0.9,1.6,2,3.1,3.4,4.4c1.7,1.5,3.5,2.7,5.6,3.6
        c2.8,1.2,5.8,1.6,8.8,1.2c1.5-0.2,2.9-0.5,4.3-1c2.2-0.8,4.3-2,6-3.6c1.9-1.7,3.3-3.7,4.3-6.1c1-2.3,1.5-4.7,1.5-7.2
        c0-0.5,0-1-0.1-1.5c-0.1-1.5-0.3-2.9-0.8-4.4c-1-3.2-2.9-5.9-5.5-8.1c-1.9-1.7-4.1-2.9-6.5-3.6c-0.9-0.3-1.7-0.5-2.6-0.5
        c-0.5,0-1-0.1-1.3-0.4C96.2,12.5,95.3,12.5,94.3,12.5z M143.4,103.4c-0.2,0-0.4,0-0.6,0c-10.8,0-21.5,0.1-32.3-0.1
        c-3.4,0-6.9,0-10.3,0c-0.9,0-1,0.1-1,1c0,2.7,0,5.4,0,8.1c0,0.7,0,1.5,0,2.2c0,0.4,0.1,0.6,0.6,0.7c0.2,0,0.3,0,0.5,0
        c1.3,0,2.6,0,3.9,0c3-0.1,6,0,9,0c0.2,0,0.3,0,0.5,0.1c0.2,0.1,0.4,0.2,0.4,0.5c0,0.2,0,0.4,0,0.6c0,6.7,0,13.5,0,20.2
        c0,0.2,0,0.3,0,0.5c0,0.5,0.2,0.7,0.7,0.7c0.1,0,0.2,0,0.2,0c4.2,0,8.4,0,12.7,0c0.1,0,0.2,0,0.4,0c0.3,0,0.5-0.2,0.6-0.5
        c0.1-0.2,0.1-0.4,0.1-0.6c0-2.3,0-4.6,0-6.9c-0.1-4.5,0-9,0-13.5c0-0.8,0.1-1,1-1c2.5,0,5,0,7.4,0c2.1,0,4.2-0.1,6.3,0
        C143.4,111.3,143.4,107.3,143.4,103.4z M68.5,103.5c-0.6,0-1,0.2-1.3,0.8c-1.1,2.5-2.4,4.9-3.6,7.3c-1.7,3.5-3.4,6.9-5.1,10.4
        c-1.7,3.5-3.5,7-5.2,10.5c-0.8,1.5-1.5,3-2.2,4.6c-0.3,0.5-0.2,0.6,0.4,0.6c5.5,0,11.1,0,16.6,0c0.7,0,0.8-0.1,1.1-0.8
        c1.1-2.6,2.1-5.2,3.2-7.8c0.5-1.2,0.9-2.3,1.4-3.5c0.2-0.6,0.4-0.6,1-0.4c0.1,0,0.1,0,0.2,0.1c1.1,0.4,2.1,1,3.1,1.5
        c2.2,1.3,4.2,2.9,5.9,4.9c1.3,1.6,2.3,3.3,3.1,5.2c0.2,0.5,0.5,0.7,1.1,0.7c5.5,0,10.9,0,16.4,0c0.1,0,0.2,0,0.3,0
        c0.3,0,0.4-0.2,0.3-0.5c0-0.1-0.1-0.3-0.2-0.4c-0.9-1.8-1.8-3.7-2.6-5.5c-1.3-2.7-2.6-5.5-3.9-8.2c-1.8-3.7-3.5-7.3-5.3-11
        c-1-2.1-2-4.2-3-6.3c-0.3-0.6-0.6-1.2-0.9-1.8c-0.2-0.3-0.4-0.5-0.8-0.5c-0.1,0-0.3,0-0.4,0c-3.2,0-6.4,0-9.6,0
        C75,103.5,71.8,103.5,68.5,103.5z M78.9,113.4c0.9,3.1,1.9,6.2,2.9,9.3c0,0,0,0.1,0,0.1c0.1,0.5,0,0.6-0.5,0.6c-2.1,0-4.1,0-6.2,0
        c-0.5,0-0.6-0.1-0.4-0.6c0.4-1,0.8-2,1.2-3c0.9-2.1,1.7-4.3,2.6-6.4c0-0.1,0.1-0.2,0.2-0.3C78.9,113.1,78.9,113.2,78.9,113.4z
         M52.6,12.9c-3.6,0.6-6.7,2.3-9.4,4.7c-1.1,1.1-2.1,2.3-2.9,3.6c-1.8,2.9-2.6,6.1-2.7,9.5c0,0.4,0.1,0.7,0.1,1.1
        c0,0.7,0.1,1.4,0.2,2.1c0.4,2.1,1.1,4.2,2.2,6.1c1,1.7,2.3,3.3,3.8,4.6c1.3,1.1,2.7,2,4.2,2.7c2.2,1,4.5,1.6,7,1.7c1,0,2,0,3-0.1
        c2.5-0.3,4.8-1.1,7-2.3c1.7-1,3.1-2.2,4.4-3.6c1.2-1.4,2.2-2.9,2.9-4.6c1-2.2,1.5-4.5,1.6-6.9c0-0.9,0-1.8-0.1-2.8
        c-0.3-2.6-1.2-5-2.5-7.3c-1.1-1.8-2.4-3.3-4-4.6c-1.7-1.4-3.7-2.5-5.8-3.2c-1.9-0.6-3.8-0.9-5.8-0.9C54.7,12.6,53.6,12.7,52.6,12.9z
         M55.9,52.5c-5,0-9.4,1.8-12.9,5.3c-3.5,3.5-5.3,7.9-5.3,12.9c0,5,1.8,9.4,5.3,12.9c3.5,3.5,7.8,5.3,12.7,5.3
        c5.1,0,9.6-1.8,13.2-5.5c3.4-3.5,5-7.8,5.1-12.4C74,60.3,65.8,52.6,55.9,52.5z M113.9,69.2c-0.1-1.6-0.4-3.2-1-4.7
        c-0.8-2.1-1.9-4-3.4-5.7c-1.8-2.1-4-3.7-6.5-4.8c-2.2-1-4.6-1.4-7-1.5c-1,0-2,0.1-3,0.2c-1.9,0.3-3.7,0.9-5.5,1.8
        c-2.1,1.1-3.9,2.5-5.4,4.2c-1.3,1.5-2.3,3.1-3.1,4.9c-0.8,1.8-1.3,3.7-1.4,5.7c-0.1,1.1-0.1,2.2,0,3.3c0.2,2,0.8,4,1.6,5.8
        c1,2.1,2.4,4,4.2,5.6c1.2,1.1,2.4,2,3.8,2.7c2.2,1.2,4.6,1.9,7.1,2.1c1.3,0.1,2.6,0,3.9-0.1c2-0.3,3.9-0.9,5.7-1.9
        c2.4-1.2,4.4-2.9,6.1-5c1.3-1.6,2.2-3.4,2.9-5.3c0.6-1.9,0.9-3.8,0.8-5.8C113.9,70.2,113.9,69.7,113.9,69.2z"
    />
  </svg>

);

export default SatLogo;
