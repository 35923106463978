import React from 'react';
import PropTypes from 'prop-types';
import './Facturas.scss';
import ListFacturas from '../components/ListFacturas';

import FacturaModal from '../modal';

const Factura = ({
  dispatchCloseDiscountModal,
  dispatchFetchFacturas,
  facturas,
  loading,
  modals,
  orderSelected,
  type,
}) => (
  <div>
    {type === 'modal' && (
      <FacturaModal
        facturas={facturas}
        fetchFacturas={dispatchFetchFacturas}
        isOpen={Boolean(modals.facturaModalIsOpen)}
        loading={loading}
        onClose={dispatchCloseDiscountModal}
        orderSelected={orderSelected}
      />
    )}
    {type === 'list' && (
      <ListFacturas
        facturas={facturas}
        fetchFacturas={dispatchFetchFacturas}
        isLoading={loading}
        isOpen
        orderSelected={orderSelected}
      />
    )}
  </div>
);

Factura.propTypes = {
  dispatchCloseDiscountModal: PropTypes.func.isRequired,
  dispatchFetchFacturas: PropTypes.func.isRequired,
  facturas: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  modals: PropTypes.object.isRequired,
  orderSelected: PropTypes.object.isRequired,
  type: PropTypes.string,
};

Factura.defaultProps = {
  type: 'modal',
};

export default Factura;
