/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import '../../../ClientPage/components/SalesDetail/SalesDetail.scss';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { formatMoney } from '../../../../helpers/tools';


const WDeliverExpand = ({
  sale, deliverSale, openShare,
}) => {
  const loads = useSelector(state => state.loads);
  return (
    <div style={{ backgroundColor: '#f0f0f0' }}>
      <List component="nav" aria-label="main mailbox folders" dense style={{ backgroundColor: '#f0f0f0' }}>
        <div key={Math.random()} style={{ backgroundColor: '#f0f0f0', cursor: 'pointer' }}>
          <ListItem divider classes={{ selected: 'salesDetail-list-selected' }}>
            <ListItemText className="ml-2 text-left" primary="Venta:" classes={{ primary: 'salesDetail-list-text-left' }} />
            <ListItemText className="p-0 text-right" primary={sale.subject} classes={{ primary: 'salesDetail-list-text-right' }} />
          </ListItem>
          <ListItem divider classes={{ selected: 'salesDetail-list-selected' }}>
            <ListItemText className="ml-2 text-left" primary="Vendedor:" classes={{ primary: 'salesDetail-list-text-left' }} />
            <ListItemText className="p-0 text-right" primary={sale.assigned_user_label} classes={{ primary: 'salesDetail-list-text-right' }} />
          </ListItem>
          <ListItem divider classes={{ selected: 'salesDetail-list-selected' }}>
            <ListItemText className="ml-2 text-left" primary="Balance:" classes={{ primary: 'salesDetail-list-text-left' }} />
            <ListItemText className="p-0 text-right" primary={`$${formatMoney(sale.tfde_balance)}`} classes={{ primary: 'salesDetail-list-text-right' }} />
          </ListItem>
        </div>
      </List>
      <List component="nav" aria-label="secondary mailbox folders" className="d-flex justify-content-around">
        <button
          type="button"
          onClick={() => openShare(sale)}
          style={{
            backgroundColor: '#23A1DB', color: 'white', fontSize: 15, padding: '10px 20px', borderRadius: 17, border: 0,
          }}
        >
          <FontAwesomeIcon style={{ fontSize: 15 }} icon={faShareAlt} />
        </button>
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            deliverSale(true);
          }}
          disabled={sale.estado_mda === 'Entregado' || loads.orderIsLoading}
          style={{
            backgroundColor: sale.estado_mda === 'Entregado' ? '#b7bfc6' : '#23A1DB', color: 'white', fontSize: 15, padding: '10px 20px', borderRadius: 17, border: 0,
          }}
        >
          Entregar
        </button>
      </List>
    </div>
  ); };

WDeliverExpand.propType = {
  sale: PropTypes.object.isRequired,
  deliverSale: PropTypes.func.isRequired,
};

export default WDeliverExpand;
