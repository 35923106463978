import React from 'react';
import PropTypes from 'prop-types';
/* Reatcstrap */
import {
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';

import ListFacturas from '../components/ListFacturas';

const FacturaModal = ({
  facturas,
  fetchFacturas,
  isOpen,
  loading,
  onClose,
  orderSelected,
  title,
}) => (
  <Modal
    isOpen={isOpen}
    toggle={onClose}
    size="lg"
  >
    <ModalHeader toggle={onClose}>{title}</ModalHeader>
    <ModalBody className="mda_modal_body">
      <ListFacturas
        facturas={facturas}
        fetchFacturas={fetchFacturas}
        isLoading={loading}
        isOpen={isOpen}
        orderSelected={orderSelected}
      />
    </ModalBody>
  </Modal>
);

FacturaModal.propTypes = {
  facturas: PropTypes.array,
  fetchFacturas: PropTypes.func,
  isOpen: PropTypes.bool,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  orderSelected: PropTypes.object,
  title: PropTypes.string,
};

FacturaModal.defaultProps = {
  facturas: [],
  fetchFacturas: () => {},
  isOpen: false,
  loading: false,
  onClose: () => {},
  orderSelected: {},
  title: 'Facturas',
};

export default FacturaModal;
