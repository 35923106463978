/* eslint-disable react/prop-types */
import React from 'react';
import './ActivitiesItem.scss';
import PropTypes from 'prop-types';

import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faCalendar,
  faClock,
} from '@fortawesome/free-solid-svg-icons';
import ComingSoonList from '../../../../components/ComingSoonList';

const ActivitiesItem = ({ activities, title }) => (

  <Card className="w-100">
    {title ? (
      <>
        <h3 className="m-2 d-flex justify-content-center SummaryContainer_title">Actividades</h3>
        <Divider />
      </>
    ) : null }
    <List classes={{ root: 'py-0' }}>
      {
        activities.length
          ? activities.map(activity => (
            <ListItem divider key={activity}>
              <ListItemIcon classes={{ root: 'mr-0' }}>
                <div className="icon-border">
                  <FontAwesomeIcon style={{ fontSize: 15 }} color="#64707A" icon={faCheck} />
                </div>
              </ListItemIcon>
              <ListItemText
                id="switch-list-label-wifi"
                primary={activity.what}
                secondary={(
                  <>
                    <span className="mr-1 activities_secondary-title">
                      <FontAwesomeIcon style={{ fontSize: 13 }} color="#B6BEC6" icon={faCalendar} />
                      <span className="ml-1">{activity.update_time_title}</span>
                    </span>
                    <span className="mr-1 activities_secondary-title">
                      <FontAwesomeIcon style={{ fontSize: 13 }} color="#B6BEC6" icon={faClock} />
                      <span className="ml-1">{activity.update_time}</span>
                    </span>
                  </>
            )}
                classes={{
                  primary: 'activities_primary-title',
                }}
              />
              <ListItemAvatar>
                <Avatar src="/img/ava.png" />
              </ListItemAvatar>
            </ListItem>
          ))
          : (
            <ComingSoonList />
          )
        }
    </List>
  </Card>

);

ActivitiesItem.prototype = {
  activities: PropTypes.array.isRequired,
  title: PropTypes.string,
};
export default ActivitiesItem;
