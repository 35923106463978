import React from 'react';
import PropTypes from 'prop-types';
import {
  Menu,
  List,
  Radio,
  MenuItem,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { Store } from '@material-ui/icons';

const StoreSelect = ({
  title,
  loading,
  authUser,
  dispatchSelectStore,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleList(e) {
    const { currentTarget } = e;
    setAnchorEl(currentTarget);
  }
  const stores = authUser.isAuth ? authUser.user.almacenes : [];
  const currentStore = authUser.isAuth ? authUser.user.config.store : {};
  return (
    <>
      <List component="nav" aria-label="StoreCatalog">
        <ListItem
          dense
          button
          aria-label={title}
          onClick={handleList}
          aria-haspopup="true"
          aria-controls="FilterState"
        >
          <ListItemIcon>
            <Store />
          </ListItemIcon>
          <ListItemText
            primary={title}
            secondary={`Actual: ${currentStore.name || currentStore.almacen}`}
          />
        </ListItem>
      </List>
      <Menu
        id="FilterState"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {stores.map(store => (
          <MenuItem
            key={`key_radio_store_${store.crmid}`}
            disabled={loading}
            onClick={(event) => {
              const { currentTarget } = event;
              dispatchSelectStore(currentTarget);
            }}
            data-crmid={store.crmid}
            data-name={store.almacen}
            data-image={(store.images && store.images.length > 0) ? store.images[0] : ''}
            data-campos={store.talm_tpv_campos_en_ticket}
            data-telefono={store.telefono}
            data-email={store.email}
            data-url_web={store.url_web}
            data-direccion={`${store.direccion || ''} ${store.num_ext || ''} , Colonia ${store.neighborhood || ''}, ${store.postcode || ''} ${store.city || ''}, ${store.state || ''} - ${store.country || ''}`}
            data-direccionid={store.treebesdireccionenvioid}
            data-forzaenvios={store.pos_forzaenvio}
          >
            <Radio
              checked={store.crmid === currentStore.crmid}
            />
            <span>{store.almacen}</span>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

StoreSelect.propTypes = {
  title: PropTypes.string,
  loading: PropTypes.bool,
  authUser: PropTypes.object.isRequired,
  dispatchSelectStore: PropTypes.func.isRequired,
};

StoreSelect.defaultProps = {
  loading: false,
  title: 'Selecciona un almacén ...',
};

export default StoreSelect;
