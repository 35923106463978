import React from 'react';
import './PolizaItem.scss';
import PropsTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import ProgresBar from '../ProgresBar/ProgresBar';

const PolizaItem = ({ title, total, amount }) => (
  <div className="poliza-container">
    <div className="poliza-header my-2">{title}</div>
    <ProgresBar pay={amount} total={total} money={false} />
    <div className="poliza-description">
      <Grid container spacing={8}>
        <Grid item xs={4}>
          <div className="poliza-description_item">
            <h4>{`${total} hrs`}</h4>
            <span>Poliza</span>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="poliza-description_item">
            <h4>{amount}</h4>
            <span>Consumidas</span>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className="poliza-description_item">
            <h4>{total - amount}</h4>
            <span>Disponibles</span>
          </div>
        </Grid>
      </Grid>
    </div>
  </div>
);

PolizaItem.prototype = {
  title: PropsTypes.string.isRequired,
  total: PropsTypes.number.isRequired,
  amount: PropsTypes.number.isRequired,
};
export default PolizaItem;
