/* eslint-disable react/prop-types */
import { Paper } from '@material-ui/core';
import React from 'react';
import BankIcon from '../../../../Icons/BankIcon';
import CajaIcon from '../../../../Icons/CajaIcon';

const BankCheckOut = ({
  payment, bank, setBank, setPayIndex,
}) => {
  const optionIcon = {
    Caja: <CajaIcon height={60} width={60} fill={bank?.entity_label === 'Caja' ? '#8252a0' : '#b7bfc6'} />,
    Banco: <BankIcon height={60} width={60} fill={bank?.entity_label === 'Banco' ? '#8252a0' : '#b7bfc6'} />,
  };
  return (
    <div style={{ paddingTop: '15px' }}>
      <div className="w-100 ">
        <p>Selecciona banco o caja</p>
        <span />
      </div>
      <div className="d-flex" style={{ gap: 10, overflowX: 'auto', padding: 8 }}>
        {payment.body.map((banc, index) => (
          <Paper
            elevation={1}
            key={Math.random()}
            style={{
              cursor: 'pointer',
              padding: 10,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              border: bank?.entity_label === banc.entity_label ? '2px solid #8252a0' : '',
            }}
            onClick={() => {
              setBank(banc);
              setPayIndex(index);
            }}
          >
            {optionIcon[`${banc.tcyb_tipo}`]}
            <span style={{ fontSize: 11, textAlign: 'center' }}>
              {banc?.entity_label}
            </span>
          </Paper>
        ))}
      </div>
    </div>
  ); };

export default BankCheckOut;
