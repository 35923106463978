import React from 'react';
import PropTypes from 'prop-types';
/* Reatcstrap */
import {
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';

import MdaList from '../components/ListMda';

const MdaModal = ({
  mda,
  fetchMda,
  isOpen,
  loading,
  onClose,
  orderSelected,
  title,
}) => (
  <Modal
    isOpen={isOpen}
    toggle={onClose}
    size="lg"
  >
    <ModalHeader toggle={onClose}>{title}</ModalHeader>
    <ModalBody className="mda_modal_body">
      <MdaList
        mda={mda}
        fetchMda={fetchMda}
        isLoading={loading}
        isOpen={isOpen}
        orderSelected={orderSelected}
      />
    </ModalBody>
  </Modal>
);

MdaModal.propTypes = {
  mda: PropTypes.array,
  fetchMda: PropTypes.func,
  isOpen: PropTypes.bool,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  orderSelected: PropTypes.object,
  title: PropTypes.string,
};

MdaModal.defaultProps = {
  mda: [],
  fetchMda: () => {},
  isOpen: false,
  loading: false,
  onClose: () => {},
  orderSelected: {},
  title: 'Movimientos de Almacén',
};

export default MdaModal;
