import React, {
  useState, useRef, useEffect,
} from 'react';
import { useDispatch } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';

const ScrollPaginator = ({
  allItems, itemsPerPage, currentPage, childComponent, action, totalPages, clientId,
}) => {
  
  
  const [actualPage, setActualPage] = useState(currentPage);
  const [realPage, setRealPage] = useState(1);
  // eslint-disable-next-line
  const [btnShow, setBtnShow] = useState(true);
  const indexOfLastItem = actualPage * itemsPerPage;
  // const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const newSubItems = [...allItems.slice(0, indexOfLastItem)];
  const renderComponent = childComponent;
  const ref = useRef();
  const dispatch = useDispatch();

  const loadMoreData = () => {
    if (allItems.length > newSubItems.length) {
      console.log('iddd', clientId);
      setActualPage(actualPage + 1);
    } else if (totalPages !== 1 && realPage < totalPages) {
      if (!clientId) {
        dispatch(action(realPage + 1, '', true));
      } else {
        dispatch(action(realPage + 1, '', clientId, true));
      }
      setRealPage(realPage + 1);
    } else {
      setBtnShow(false);
    }
  };

  // Intersection opserver para saber cuando se muestra el loader al final de la pagina.
  const observer = new IntersectionObserver(
    ([entry]) => {
      // Acciones que se realizan al estar visible el loader (scroll al final de la pagina).
      if (entry.isIntersecting) {
        loadMoreData();
      }
    },
  );

  // Verifica cuando el elemento observado esta en pantalla.
  useEffect(() => {
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      // eslint-disable-next-line
      observer.unobserve(ref.current);
    };
    // eslint-disable-next-line
  }, [allItems, actualPage]);

  return (
    <div id="scrollPaginator" className="scrollPaginator_container" style={{ overflowY: 'auto' }}>
      { React.cloneElement(renderComponent, { data: newSubItems })}
      <div ref={ref} className="more" style={{ display: 'flex', justifyContent: 'center' }}>
        {/* {
          btnShow ? (
            <LinearProgress />
          )
            : null
        } */}
        <LinearProgress />
      </div>
    </div>
  ); };
export default ScrollPaginator;
