import React from 'react';
import PropTypes from 'prop-types';
import {
  ButtonBase,
} from '@material-ui/core';


const Pagination = ({ files }) => (
  <div className="w-100 d-flex justify-content-center align-content-center">
    <ButtonBase>
      Anterior
    </ButtonBase>
    <span className="mx-2">
      {`${files.actualPage} / ${files.pages}`}
    </span>
    <ButtonBase>
      Siguiente
    </ButtonBase>
  </div>
);

Pagination.propTypes = {
  files: PropTypes.object.isRequired,
};

export default Pagination;
