/* eslint-disable no-restricted-syntax */
import fetch from 'cross-fetch';
import axios from 'axios';
import ConfigurationAPI from './ConfigurationAPI';
import { pricebookHelper } from '../helpers';

class ProductAPI extends ConfigurationAPI {
  getAll = async (search = '') => {
    try {
      const pricebook = pricebookHelper.getStorage();
      const existPricebook = Boolean(Object.keys(pricebook).length > 0);
      if (!existPricebook) delete this.credentials.headers.pricebook;
      const response = await fetch(`${this.url}/list/TreebesProductosOC/?search=${search}`, this.credentials);
      const json = await response.json();
      return json;
    }

    catch {
      return { succes: false, result: [], message: 'Error en la API' };
    }
  };

  get = async (crmid) => {
    try {
      const response = await fetch(`${this.url}/id/${crmid}`, this.credentials);
      const json = await response.json();
      return json;
    } catch (err) {
      return { succes: false, result: [], message: `Error en la petición del producto: ${err}` };
    }
  };

  getWithData = async (crmid, data) => {
    try {
      const response = await fetch(`${this.url}/id/${crmid}`, this.credentials);
      const json = await response.json();
      return { ...json, ...data };
    } catch (err) {
      return { succes: false, result: [], message: `Error en la petición del producto: ${err}` };
    }
  };

  getSingle = crmid => (new Promise((resolve, reject) => {
    const config = {
      withCredentials: true,
      headers: this.credentials.headers,
    };
    axios.get(`${this.url}/id/${crmid}`, config).then((result) => {
      const { data } = result;
      resolve(data);
    }).catch(err => reject(err.response));
  }));

  getPromotions = async (data) => {
    this.credentials.method = 'POST';
    this.credentials.body = JSON.stringify(data);

    try {
      const response = await fetch(`${this.url}/TreebesPromociones/from-cart`, this.credentials);
      const json = await response.json();
      return { ...json };
    }

    catch {
      return { succes: false, result: [], message: 'Error en la API' };
    }
  };


  getWithPricebook = async (crmid, pricebook) => {
    try {
      if (pricebook) this.credentials.headers.pricebook = pricebook;
      else delete this.credentials.headers.pricebook;
      const response = await fetch(`${this.url}/id/${crmid}`, this.credentials);
      const json = await response.json();
      return { ...json };
    } catch (err) {
      return { succes: false, result: [], message: `Error en la petición del producto: ${err}` };
    }
  };

  getOrderDetail = async (
    returnrecord,
    aDocumentoId,
    almacenId,
    tipoMdA,
  ) => {
    try {
      const response = await fetch(
        `${this.url}/TreebesMdA/QuickCreateAjax?returnrecord=${returnrecord}&a_documentoid=${aDocumentoId}&tipoMdA=${tipoMdA}&almacenid=${almacenId}`,
        this.credentials,
      );
      const json = await response.json();
      return json;
    } catch (err) {
      return {
        succes: false,
        result: [],
        message: `Error en la petición del producto: ${err}`,
      };
    }
  };
}

export default ProductAPI;
