import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import './ShippingGuideList.styles.scss';
import {
  LinearProgress,
  Collapse,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTruck,
} from '@fortawesome/free-solid-svg-icons';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Fab from '@material-ui/core/Fab';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import Share from '@material-ui/icons/Share';
import Download from '@material-ui/icons/ArrowDownward';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import moment from 'moment';
import Avatar from '@material-ui/core/Avatar';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { toast } from 'react-toastify';
import GuideApi from '../../api/GuideApi';
import Iconwidthbg from '../Icons/IconWidthBG/IconWidthBG';
import { formatMoney, getColorStatusGuide, numberFormat } from '../../helpers/tools';
import HeaderTitle from '../HeaderTitle';
import { fetchAllOrderGuide } from '../../redux/actions/guideActions';
import ShareModal from '../Share/components/ShareModal';
import appModules from '../../constant/appModules';

const ShippingGuideList = ({ itemCrmid }) => {
  const dispatch = useDispatch();
  const cart = useSelector(state => state.cart);
  const guide = useSelector(state => state.guide);
  const loads = useSelector(state => state.loads);
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedGuide, setSelectedGuide] = React.useState(null);
  const [openShare, setOpenShare] = React.useState(false);
  // Packing
  const [packingList, setPackingList] = React.useState([]);
  const guideApi = new GuideApi();
  const { orderSelected: { crmid } } = cart;

  const getOrderSendRequest = () => {
    setIsLoading(true);
    dispatch(fetchAllOrderGuide(itemCrmid ? itemCrmid : crmid));
    setIsLoading(false);
  };

  const getAllPacking = async () => {
    const packings = await guideApi.getPackingList();
    const { result, success } = packings;
    if (success && result.length > 0) {
      setPackingList(result);
    }
  };

  React.useEffect(() => {
    getOrderSendRequest();
    getAllPacking();
  }, []);

  const selectGuide = async (guid) => {
    if (selectedGuide && guid.crmid === selectedGuide.crmid) {
      setSelectedGuide(null);
    } else {
      setSelectedGuide(guid);
    }
  };

  const totalWeight = (guid) => {
    let productsWeight = 0;
    guid.products.forEach((prod) => {
      productsWeight += (parseFloat(prod.weight_s) * parseFloat(prod.quantity));
    });

    return productsWeight;
  };

  const getGuideDimensions = (packingId) => {
    const guidePacking = packingList.find(pack => pack.crmid === packingId);
    if (guidePacking) {
      return `${guidePacking.largo} x ${guidePacking.ancho} x ${guidePacking.alto}`;
    }
    return '-';
  };

  const createGuide = async (guid) => {
    setIsLoading(true);
    const response = await guideApi.createGuide(guid.crmid);
    const { success, result } = response;
    if (success) {
      toast.info(`${result.msg}`);
    }
    const generatedGuide = await guideApi.getObjectById(guid.crmid);
    if (generatedGuide.success) {
      getOrderSendRequest();
    }
    setIsLoading(false);
  };

  const deleteGuide = async (guid) => {
    setIsLoading(true);
    const response = await guideApi.cancelGuide(guid.crmid);
    const { success, result } = response;
    if (success) {
      toast.info(`${result.msg}`);
      getOrderSendRequest();
    }
    setIsLoading(false);
  };

  const handleShareOpen = () => {
    setOpenShare(true);
  };
  const handleShareClose = () => {
    setOpenShare(false);
  };

  const shareGuide = async (guid) => {
    setIsLoading(true);
    setSelectedGuide(guid);
    handleShareOpen();
    setIsLoading(false);
  };

  return (
    <div className="guide-list">
      <HeaderTitle title="Guías" />
      <Collapse in={isLoading || loads.guideIsLoading}>
        <LinearProgress />
      </Collapse>
      <div>
        <List classes={{ root: 'bg-white', padding: 'pb-0' }}>
          {
          guide.guides && guide.guides.length ? guide.guides.map(g => (
            <>
              <ListItem divider className="quote" key={g.crmid}>
                <ListItemText
                  classes={{ primary: 'guide__title', secondary: 'guide__subtitle' }}
                  primary={g.carrier}
                  secondary={(
                    <>
                      <span>{`${g.carrier_service_code.slice(0, 20)} ...`}</span>
                      <br />
                      <span className="d-flex justify-content-start align-items-center">
                        <>
                          <Iconwidthbg
                            primaryColor={getColorStatusGuide(g.status_shipment)}
                            secondaryColor="white"
                            width={27}
                            height={27}
                            enabled
                            square
                            rounded
                            actionClick={() => {}}
                          >
                            <FontAwesomeIcon style={{ fontSize: 15 }} icon={faTruck} />
                          </Iconwidthbg>
                        </>
                        <span className="ml-2 guide-date">{moment(g.createdtime).format('ddd DD MMM YYYY')}</span>
                      </span>
                    </>
                  )}
                />
                <ListItemText
                  classes={{ primary: 'quote__money' }}
                  primary={(
                    <div className="d-flex justify-content-end align-items-center">
                      <>
                        {
                          g.status_shipment !== 'Cancelado' && (
                            <Fab className="mr-2 close-icon" size="small" color="primary" aria-label="delete" onClick={() => deleteGuide(g)}>
                              <CloseIcon />
                            </Fab>
                          )
                        }
                      </>
                      {
                        !g.label_share_link ? (
                          <div>
                            {
                              g.status_shipment !== 'Cancelado' && (
                                <Fab className="check-icon" size="small" color="secondary" aria-label="select" onClick={() => createGuide(g)}>
                                  <CheckIcon />
                                </Fab>
                              )
                            }
                          </div>
                        ) : (
                          <div>
                            {
                              g.status_shipment !== 'Cancelado' && (
                                <>
                                  <Fab className="mr-2 close-icon" size="small" color="primary" aria-label="delete" onClick={() => shareGuide(g)}>
                                    <Share />
                                  </Fab>
                                  <a href={g.label_share_link} download>
                                    <Fab className="check-icon" size="small" color="secondary" aria-label="select">
                                      <Download />
                                    </Fab>
                                  </a>
                                </>
                              )
                            }
                          </div>
                        )
                      }
                    </div>
                  )}
                />
              </ListItem>
              <Collapse in={selectedGuide && selectedGuide.crmid === g.crmid} style={{ backgroundColor: '#f7f7f7' }}>

                <List classes={{ padding: 'pb-0' }}>
                  <ListItem divider className="guide">
                    <ListItemText style={{ width: '50%' }} classes={{ secondary: 'guide-detail__subtitle guide-detail__subtitle-left' }} secondary="Estado de envío: " />
                    <ListItemText
                      classes={{ secondary: 'guide-detail__subtitle guide-detail__subtitle-right' }}
                      secondary={
                        g.status_shipment || '-'
                      }
                    />
                    <ListItemIcon className="m-0">
                      <Iconwidthbg
                        primaryColor={getColorStatusGuide(g.status_shipment)}
                        secondaryColor="white"
                        width={27}
                        height={27}
                        classes="ml-2"
                        enabled
                        square
                        rounded
                        actionClick={() => {}}
                      >
                        <FontAwesomeIcon style={{ fontSize: 15 }} icon={faTruck} />
                      </Iconwidthbg>
                    </ListItemIcon>
                  </ListItem>
                  <ListItem divider className="guide">
                    <ListItemText
                      style={{ width: '50%' }}
                      classes={{ secondary: 'guide-detail__subtitle guide-detail__subtitle-left' }}
                      secondary="No. Guía:"
                    />
                    <ListItemText classes={{ secondary: 'guide-detail__subtitle guide-detail__subtitle-right' }} secondary={g.referencia} />
                  </ListItem>

                  <ListItem divider className="guide">
                    <ListItemText
                      style={{ width: '50%' }}
                      classes={{ secondary: 'guide-detail__subtitle guide-detail__subtitle-left' }}
                      secondary="Dimensiones:"
                    />
                    <ListItemText
                      classes={{ secondary: 'guide-detail__subtitle guide-detail__subtitle-right' }}
                      secondary={getGuideDimensions(g?.treebesempaquesid)}
                    />
                  </ListItem>
                  <ListItem divider className="guide">
                    <ListItemText
                      style={{ width: '50%' }}
                      classes={{ secondary: 'guide-detail__subtitle guide-detail__subtitle-left' }}
                      secondary="Peso:"
                    />
                    <ListItemText
                      classes={{ secondary: 'guide-detail__subtitle guide-detail__subtitle-right' }}
                      secondary={`${totalWeight(g)} Kg`}
                    />
                  </ListItem>
                  {
                    (g.products && g.products.length) ? g.products.map(prod => (
                      <ListItem divider className="guide">
                        <ListItemAvatar>
                          <Avatar
                            className="avatar-square"
                            variant="square"
                            alt="Imagen producto"
                            src={prod.image}
                          />
                        </ListItemAvatar>
                        <ListItemText classes={{ primary: 'product__title' }} primary={prod.productname} secondary={
                          (
                            <div className="w-100 d-flex justify-content-between align-items-center">
                              <span>
                                {`Cantidad: ${numberFormat(prod.quantity)}`}
                              </span>
                              <span>
                                {`sku: ${prod.productcode}`}
                              </span>
                            </div>
                          )
                          } />
                      </ListItem>
                    ))
                      : (
                        <ListItem divider className="guide">
                          <ListItemText classes={{ primary: 'product__price' }} secondary="Sin productos" />
                        </ListItem>
                      )
                  }
                </List>
              </Collapse>

              <div className="d-flex justify-content-center align-items-center">
                {
                    selectedGuide && selectedGuide.crmid === g.crmid
                      ? (<KeyboardArrowUpIcon onClick={() => selectGuide(g)} />)
                      : (<KeyboardArrowDownIcon onClick={() => selectGuide(g)} />)
                }
              </div>
            </>
          ))
            : () => (
              <ListItem>
                <ListItemText primary="Sin Guias" />
              </ListItem>
            )
        }
        </List>
      </div>
      <ShareModal
        open={openShare}
        title={`Compartir guia ${selectedGuide ? selectedGuide.carrier : ''}`}
        handleClose={handleShareClose}
        order={selectedGuide || {}}
        template={appModules.TreebesSolicitudesEnvio}
        isLoading={isLoading}
      />
    </div>
  );
};


ShippingGuideList.prototype = {
  itemCrmid: PropTypes.number,
};

ShippingGuideList.defaultProps = {
  itemCrmid: 0,
};

export default ShippingGuideList;
