/* eslint-disable import/no-cycle */
import React, { useEffect } from 'react';
import {
  Route, Switch, Redirect, useHistory,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import Layout from '../components/Layout';
import MainWrapper from '../components/MainWrapper';
import PrivateRoute from '../helpers/PrivateRoute';

import LogIn from '../views/Account/LogIn';
import LogOut from '../views/Account/LogOut';
import Profile from '../views/Account/Profile';

import PosView from '../views/Pos';
import Clients from '../views/Clients';

import Expenses from '../views/Expenses';
import DetailExpense from '../views/Expenses/components/DetailExpense';

import Dashboard from '../views/Dashboard';

import ClientPage from '../views/ClientPage/index';
import ClientCall from '../views/ClientCall';
import Configuration from '../views/Clients/Configuration';

import AddCustomer from '../components/Cart/mobile/AddCustomer';
import EditCustomer from '../components/Cart/mobile/EditCustomer';
import Activities from '../views/Activities';
import ActivityDetail from '../views/Activities/components/ActivityDetail';
import AddActivity from '../views/Activities/components/AddActivity';
import CountUpTimer from '../components/CountUp';
import Warehouse from '../views/Warehouse';
import Deliver from '../components/rkr2-components/deliver/OrderDeliver/Deliver';
import Receiver from '../components/rkr2-components/receiver/OrderReceive/Receiver';
import ExternalDeliver from '../components/rkr2-components/external-delivery/ExternalDeliver';
import TransferReceiver from '../components/rkr2-components/transfers/TransferReceiver';
import ScheduleExternalDeliver from '../components/rkr2-components/schedule-external-deliver';
import TransferProcessDetail from '../components/rkr2-components/transfers/TransferReceiverProducts/components/TransferProcessDetail';
import Shipments from '../components/rkr2-components/Shipments';
import ShipmentDetail from '../components/rkr2-components/Shipments/components/ShipmentDetail';

const AccountWrapped = () => (
  <>
    <Layout />
    <MainWrapper>
      <Profile />
    </MainWrapper>
  </>
);

const AccountRoutes = () => (
  <Switch>
    <Route path="/account/login" component={LogIn} />
    <Route path="/account/logout" component={LogOut} />
    <PrivateRoute path="/account/profile" component={AccountWrapped} />
    <PrivateRoute path="/account/profile/caja" component={AccountWrapped} />
    <PrivateRoute path="/account/profile/ventas" component={AccountWrapped} />
    <PrivateRoute path="/account/profile/general" component={AccountWrapped} />
  </Switch>
);

const DeliverRoutes = () => (
  <Switch>
    <PrivateRoute exact path="/entregas" component={Shipments} />
  </Switch>
);
const ClientRoutes = () => (
  <Switch>
    <Redirect exact from="/" to="/clients" />
    <PrivateRoute exact path="/clients" component={Clients} />
    <PrivateRoute
      exact
      path="/clients/configuration"
      component={Configuration}
    />
    <PrivateRoute exact path="/clients/:crmid" component={ClientPage} />
    <PrivateRoute exact path="/clients/client/call" component={ClientCall} />
  </Switch>
);

const SaleRoutes = () => (
  <Switch>
    <Redirect exact from="/" to="/pos/caja" />
  </Switch>
);

const ExpensesRoutes = () => (
  <Switch>
    <PrivateRoute exact path="/expenses" component={Expenses} />
    <PrivateRoute exact path="/expenses/:crmid" component={DetailExpense} />
  </Switch>
);

const ActivityRoutes = () => (
  <Switch>
    <PrivateRoute exact path="/actividades" component={Activities} />
    <PrivateRoute exact path="/actividades/:id" component={ActivityDetail} />
    <PrivateRoute exact path="/actividades/actividad/add" component={AddActivity} />
  </Switch>
);

const WarehouseRoutes = () => (
  <Switch>
    <PrivateRoute exact path="/almacenes" component={Warehouse} />
    <PrivateRoute exact path="/almacenes/entregar/:orderId" component={Deliver} />
    <PrivateRoute exact path="/almacenes/recibir/:orderId" component={Receiver} />
    <PrivateRoute exact path="/almacenes/entrega/externa/:orderId" component={ExternalDeliver} />
    <PrivateRoute exact path="/almacenes/programar/entrega/externa/:orderId" component={ScheduleExternalDeliver} />
    <PrivateRoute exact path="/almacenes/recibir/transferencia/:orderId/:disableQty?" component={TransferReceiver} />
    <PrivateRoute exact path="/almacenes/procesar/transferencia/:orderId" component={TransferProcessDetail} />
  </Switch>
);

const MainRoutes = () => {
  const history = useHistory();
  const timerStart = useSelector(state => state.tasks.startTimer);
  const authUser = useSelector(state => state.authUser);
  const {
    user: {
      config: {
        deshabilitar_venta,
        deshabilitar_clientes,
        deshabilitar_notas_de_compra,
        deshabilitar_citas,
        deshabilitar_envios,
        deshabilitar_actividades,
        deshabilitar_almacenes,
      },
    },
  } = authUser;
  useEffect(() => {
    if (deshabilitar_venta === '1') {
      if (deshabilitar_almacenes === '0') {
        history.push('/almacenes');
      }
      if (deshabilitar_envios === '0') {
        history.push('/entregas');
      }
      if (deshabilitar_actividades === '0') {
        history.push('/actividades');
      }
      if (deshabilitar_clientes === '0') {
        history.push('/clients');
      }
    }
  }, []);

  return (
    <>
      <Layout />
      <MainWrapper>
        <Switch>
          <PrivateRoute exact path="/" component={SaleRoutes} />
          <PrivateRoute path="/dashboard" component={Dashboard} />
          <PrivateRoute path="/addCustomer" component={AddCustomer} />
          <PrivateRoute path="/editCustomer" component={EditCustomer} />
          {
            deshabilitar_venta !== '1' && (
              <PrivateRoute path="/pos" component={PosView} />
            )
          }
          {
            deshabilitar_venta !== '1' && (
              <PrivateRoute exact path="/pos/caja" component={PosView} />
            )
          }
          {
            deshabilitar_venta !== '1' && (
              <PrivateRoute exact path="/pos/ventas" component={PosView} />
            )
          }
          {
            deshabilitar_venta !== '1' && (
              <PrivateRoute exact path="/pos/cotizaciones" component={PosView} />
            )
          }
          {
            deshabilitar_venta !== '1' && (
              <PrivateRoute exact path="/pos/carro" component={PosView} />
            )
          }
          {
            deshabilitar_venta !== '1' && (
              <PrivateRoute path="/user/profile" component={Profile} />
            )
          }
          {
            deshabilitar_clientes !== '1' && (
              <PrivateRoute path="/clients" component={ClientRoutes} />
            )
          }
           {
            deshabilitar_notas_de_compra !== '1' && (
              <PrivateRoute path="/expenses" component={ExpensesRoutes} />
            )
          }
          {
            deshabilitar_actividades !== '1' && (
              <PrivateRoute path="/actividades" component={ActivityRoutes} />
            )
          }
          {
            deshabilitar_almacenes !== '1' && (
              <PrivateRoute path="/almacenes" component={WarehouseRoutes} />
            )
          }
          <PrivateRoute exact path="/entregas" component={DeliverRoutes} />
          <PrivateRoute exact path="/entregas/detalle/:id" component={() => <ShipmentDetail />} />
        </Switch>
      </MainWrapper>
      {
        (timerStart) && (<CountUpTimer hoursMinSecs={{ hours: 0, minutes: 0, seconds: 0 }} global />)
      }
    </>
  ); };

const Router = () => (
  <Switch>
    <Route path="/account" component={AccountRoutes} />
    <Route path="/" component={MainRoutes} />
  </Switch>
);

export default Router;
