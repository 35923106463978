/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  List,
  ListItem,
  ListItemText,
  Avatar,
} from '@material-ui/core';

const ListData = ({ data, fetchData, type }) => {
  const dispatch = useDispatch();
  const setVendor = (item) => {
    dispatch(fetchData(item));
  };

  const orderFilter = useSelector(state => state.filters.orderBy);
  /*  const searchFilter = useSelector(state => state.filters.searchBy);
  const changeFilter = useSelector(state => state.filters.wichFilterChange); */

  React.useEffect(() => {
    switch (orderFilter) {
      case 'Mas nueva':
        data.sort((a, b) => moment(b.modifiedtime).diff(moment(a.modifiedtime)));
        break;
      case 'Mas antigua':
        data.sort((a, b) => moment(a.modifiedtime).diff(moment(b.modifiedtime)));
        break;
      case 'Alfabético A-Z':
        data.sort((a, b) => b.entity_label.localeCompare(a.entity_label));
        break;
      case 'Alfabético Z-A':
        data.sort((a, b) => a.entity_label.localeCompare(b.entity_label));
        break;

      default:
        break;
    }
  // eslint-disable-next-line
  }, [orderFilter]);

  return (
    <List dense>
      {
        data.map(item => (
          <ListItem classes={{ root: 'bg-white' }} divider onClick={() => setVendor(item)}>
            {type === 'proveedor' && (
              <>
                <Avatar className="bg-avatar"><span className="m-0 text-white">{item.vendorname[0]}</span></Avatar>
                <ListItemText
                  className="br-break"
                  primary={item.vendorname}
                />
              </>
            )}

            {type === 'gasto' && (
              <>
                <Avatar className="bg-avatar"><span className="m-0 text-white">{item.nombre[0]}</span></Avatar>
                <ListItemText
                  className="br-break"
                  primary={item.nombre}
                />
              </>
            )}

            {type === 'almacen' && (
              <>
                <Avatar className="bg-avatar"><span className="m-0 text-white">{item.entity_label[0]}</span></Avatar>
                <ListItemText
                  className="br-break"
                  primary={item.entity_label}
                />
              </>
            )}

            {type === 'proyecto' && (
              <>
                <Avatar className="bg-avatar"><span className="m-0 text-white">{item.entity_label ? item.entity_label[0] : item.projectname[0]}</span></Avatar>
                <ListItemText
                  className="br-break"
                  primary={item.entity_label}
                />
              </>
            )}

            {type === 'usuario' && (
              <>
                <Avatar className="bg-avatar"><span className="m-0 text-white">{item.user_name ? item.user_name[0] : item.user_name[0]}</span></Avatar>
                <ListItemText
                  className="br-break"
                  primary={`${item.user_name} ${item.last_name}`}
                />
              </>
            )}
          </ListItem>
        ))
      }
    </List>
  ); };

ListData.propTypes = {
  data: PropTypes.array.isRequired,
  fetchData: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default ListData;
