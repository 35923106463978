import React from 'react';
import PropTypes from 'prop-types';
import { CustomInput } from 'reactstrap';

const Uploader = ({ upload }) => {
  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result.toString().replace(/^data:(.*,)?/, ''));
    };
    reader.onerror = error => reject(error);
  });
  async function onChangeFileInput(event) {
    const {
      target: { files },
    } = event;
    if (files.length > 0) {
      let arr = [];
      await Object.keys(files).forEach(async (key) => {
        const meta = files[key];
        const { name } = meta;
        toBase64(meta).then(async (file) => {
          arr = [{ name, file }];
          await upload(arr);
        });
      });
    }
  }
  return (
    <CustomInput
      id="inputFileByOrder"
      type="file"
      className="w-100"
      onChange={onChangeFileInput}
    />
  );
};

Uploader.propTypes = {
  upload: PropTypes.func.isRequired,
};
Uploader.defaultProps = {};

export default Uploader;
