import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import IconButton from '@material-ui/core/IconButton';
import styles from './styles';
import DrawerTop from '../DrawerTop/DrawerTop';

const HeaderTop = (
  {
    history,
    client,
  },
) => {
  const [openrDrawer, setopenrDrawerBar] = useState(false);

  const handleSetopenrDrawerBarrOpen = () => {
    setopenrDrawerBar(true);
  };
  const handleSetopenrDrawerBarClose = () => {
    setopenrDrawerBar(false);
  };
  return (
    <div
      className="top-header-container border-bottom w-100 d-flex align-items-center"
    >
      <IconButton
        color="inherit"
        onClick={() => history.goBack()}
      >
        <FontAwesomeIcon className="font-size-18" icon={faChevronLeft} />
      </IconButton>
      <div className="font-size-16 font-weight-bold w-100 text-ellipsis">
        <span>
          {client != null ? `${client.accountname}` : ''}
        </span>
      </div>
      <div className="d-flex float-right">
        <IconButton
          color="inherit"
          size="medium"
          onClick={() => handleSetopenrDrawerBarrOpen()}
        >
          <FontAwesomeIcon className="font-size-18" style={{ width: 20 }} icon={faEllipsisV} />
        </IconButton>
      </div>
      <DrawerTop onClose={handleSetopenrDrawerBarClose} open={openrDrawer} />
    </div>
  );
};

HeaderTop.prototype = {
  history: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
};

HeaderTop.defaultProps = {
  history: {},
  client: {},
};
export default withStyles(styles, { withTheme: true })(HeaderTop);
