/* eslint-disable camelcase */
import React from 'react';
import { useSelector } from 'react-redux';
import Card from '@material-ui/core/Card';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import ProgresBar from '../ProgresBar/ProgresBar';

const Balance = () => {
  const [open, setOpen] = React.useState(false);
  const selectClient = useSelector(state => state.client.selectClient);

  const firstQuarterTotal = Math.max(selectClient?.vedido_autorizado, selectClient?.facturado, selectClient?.cobrado, selectClient?.ndc_aplicadas);
  const secondQuarterTotal = Math.max(selectClient?.vedido_apartado, selectClient?.cxc, selectClient?.ndcxaplicar, selectClient?.xamortizar);

  return (
    <div className="SummaryContainer_secction">
      <Card className="mt-2 w-100">
        <h3 className="m-2 d-flex justify-content-center SummaryContainer_title">Saldos</h3>
        <Collapse in={!open} timeout="auto" mountOnEnter unmountOnExit>
          <div className="m-3">
            <h5 className="mb-2">Vendidos</h5>
            <ProgresBar title="Shopify" total={firstQuarterTotal} pay={selectClient?.vedido_autorizado || 0} money />
          </div>
          <div className="my-2 mx-3">
            <h5 className="mb-2">Por cobrar</h5>
            <ProgresBar title="Shopify" total={selectClient?.vedido_autorizado || 0} pay={selectClient?.cxc || 0} money />
          </div>
        </Collapse>
        <Collapse in={open} timeout="auto" mountOnEnter unmountOnExit>
          <div className="m-3">
            <h5 className="mb-2">Vendido Autorizado</h5>
            <ProgresBar title="Shopify" total={firstQuarterTotal} pay={selectClient?.vedido_autorizado || 0} money />
          </div>
          <div className="my-2 mx-3">
            <h5 className="mb-2">Facturadas</h5>
            <ProgresBar title="Shopify" total={firstQuarterTotal || 0} pay={selectClient?.facturado || 0} money />
          </div>
          <div className="my-2 mx-3">
            <h5 className="mb-2">Cobrado</h5>
            <ProgresBar title="Shopify" total={firstQuarterTotal || 0} pay={selectClient?.cobrado || 0} money />
          </div>
          <div className="my-2 mx-3">
            <h5 className="mb-2">Ndc aplicadas</h5>
            <ProgresBar title="Shopify" total={firstQuarterTotal || 0} pay={selectClient?.ndc_aplicadas || 0} money />
          </div>

          <div className="my-2 mx-3 pt-3">
            <h5 className="mb-2">Vendido Apartado</h5>
            <ProgresBar title="Shopify" total={secondQuarterTotal || 0} pay={selectClient?.vedido_apartado || 0} money />
          </div>
          <div className="my-2 mx-3">
            <h5 className="mb-2">Por cobrar</h5>
            <ProgresBar title="Shopify" total={secondQuarterTotal || 0} pay={selectClient?.cxc || 0} money />
          </div>
          <div className="my-2 mx-3">
            <h5 className="mb-2">NdC por aplicar</h5>
            <ProgresBar title="Shopify" total={secondQuarterTotal || 0} pay={selectClient?.ndcxaplicar || 0} money />
          </div>
          <div className="my-2 mx-3">
            <h5 className="mb-2">Anticipos por amortizar</h5>
            <ProgresBar title="Shopify" total={secondQuarterTotal || 0} pay={selectClient?.xamortizar || 0} money />
          </div>
        </Collapse>
        <div>
          <Button className="w-100" onClick={() => setOpen(!open)}>
            {
                !open ? (<ArrowDropDown />) : (<ArrowDropUp />)
            }

          </Button>
        </div>
      </Card>
    </div>
  ); };

export default Balance;
