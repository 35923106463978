/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Fade } from 'react-reveal';
import {
  ListItem,
  ListItemText,
  ListItemIcon,
  ButtonBase,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import { Warning } from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter } from 'react-router-dom';
import { formatMoney } from '../../../../../../../helpers/tools';
import {
  shouldDeliver,
  shouldCharge,
} from '../../../../../../../helpers/order';
import {
  saveSell,
  editOrder,
  changeSalesOrderStatus,
} from '../../../../../../../redux/actions/sellActions';
import { handleEditOrder, selectOrder } from '../../../../../../../redux/actions/orderActions';
import { setEditProductsComments } from '../../../../../../../redux/actions/cartActions';
import {
  cotizar,
} from '../../../../../../../redux/actions/cotizarActions';
import { openModal } from '../../../../../../../redux/actions/modalActions';
import GuideApi from '../../../../../../../api/GuideApi';

const DetailFooterShort = ({
  cart,
  discount,
  open,
  history,
  showDeliver,
  orderMode,
  toggleCheck,
}) => {
  const dispatch = useDispatch();
  const enableEditOrder = useSelector(state => state.order.enableEditOrder);
  const orderSelect = useSelector(state => state.cart.orderSelected);
  const customer = useSelector(state => state.customer);
  const [client, setClient] = React.useState(orderSelect?.account_id);
  const [currentTotal, setCurrentTotal] = React.useState(cart.total);
  const [oderChange, setOrderChange] = React.useState(false);
  const { totalProducts, products, orderSelected } = cart;
  const authUser = useSelector(state => state.authUser.user);
  const { config: { store, pos_sininv }, almacenes, shipping_id } = authUser;
  const { defaultCustomer, selectedCustomer } = customer;
  const getShippingProduct = products.find(prod => prod.crmid === shipping_id);
  const guideApi = new GuideApi();

  const handleEditMode = async () => {
    if (enableEditOrder && orderSelected?.order) {
      setOrderChange(true);
      return;
    }

    if (orderSelect && orderSelect?.account_id !== client && client !== 'undefined') {
      await setClient(cart.orderSelected?.account_id);
      await setOrderChange(false);
      return;
    }

    if (cart.total /* && currentTotal !== 0 */ && currentTotal !== cart.total && orderSelected?.order) {
      setOrderChange(true);
    }

    if (cart.updateProductsComments && orderSelected?.order) {
      setOrderChange(true);
    }

    if (currentTotal === 0) {
      setCurrentTotal(cart.total);
    }
  };

  React.useEffect(() => {
    handleEditMode();
    // eslint-disable-next-line
  }, [cart.total, cart.updateProductsComments, cart.orderSelected, enableEditOrder]);

  const necesitaEnvio = () => {
    const selectedStore = almacenes.length > 0 ? almacenes.find(almacen => almacen.crmid === store.crmid) : null;
    if (selectedStore && selectedStore.pos_forzaenvio === '1' && !getShippingProduct) {
      return true;
    }
    return false;
  };

  if (totalProducts > 0) {
    const { orderSelected, balance } = cart;
    const almacen = orderSelected.cartStateWarehouse || '';

    const getTotal = (_discount, _cart) => {
      let { total } = _cart;
      if (_discount.haveDiscount) {
        total = (total - _discount.total).toFixed(2);
        total = Number.parseFloat(total);
      }
      return formatMoney(total);
    };
    const generalTotal = (Number.parseFloat(cart.total) - Number.parseFloat(getTotal(cart, discount)));
    const withDiscount = (Number.parseFloat(cart.total) - (discount.total).toFixed(2));
    const generalBalance = (withDiscount - Number.parseFloat(cart.pagado));
    const enableDeliver = Boolean(shouldDeliver(almacen) && totalProducts > 0);
    const enableCharge = orderMode
      ? (Boolean(shouldCharge(balance) && totalProducts > 0))
      : (Boolean(totalProducts > 0));

    const {
      config: {
        pos_no_cobrar, sostatus, pos_botoncaja, pos_botonventas,
      },
    } = authUser;

    const mostrarCotizar = Boolean(pos_botoncaja === 'Cotizar');
    const mostrarCobrar = Boolean(pos_botoncaja === 'Cobrar');
    const mostrarGuardar = Boolean(pos_botoncaja === 'Guardar');

    const makeSell = () => {
      dispatch(changeSalesOrderStatus(sostatus));
      dispatch(saveSell(history));
    };

    const makeQuote = (history) => {
      dispatch(cotizar(history));
    };

    const saveOrder = () => {
      dispatch(editOrder());
      setCurrentTotal(0);
      setOrderChange(false);
    };


    const openLocalModal = async () => {
      const sinInventario = products.some(p => parseFloat(p.quantity) > parseFloat(p.qtyinstock));
      if (sinInventario && !pos_sininv) {
        toast.error('No puedes vender sin inventario');

        return;
      }

      if (enableEditOrder) {
        dispatch(handleEditOrder());
      }

      if (selectedCustomer.crmid === defaultCustomer.crmid) {
        toggleCheck();

        return;
      }
      if (store.forzaEnvio === '1') {
        const shippingIsSelected = cart.products.findIndex((prod) => {
          let productid = '';
          if (prod.crmid) {
            productid = (prod.crmid && prod.crmid.indexOf('x') !== -1)
              ? prod.crmid.split('x').pop()
              : prod.crmid;
          } else {
            productid = (prod.productid && prod.productid.indexOf('x') !== -1)
              ? prod.productid.split('x').pop()
              : prod.productid;
          }

          return productid === shipping_id;
        });
        if (shippingIsSelected === -1) {
          if (Object.keys(orderSelected).length > 0) {
            const response = await guideApi.getOrderGuides(orderSelected.crmid);
            const { success, result } = response;
            if (!result || (!result?.length && success)) {
              toast.info('Debes seleccionar un envío antes de cobrar');
              dispatch(openModal('shipping'));
              return;
            }
          } else {
            toast.info('Debes seleccionar un envío antes de cobrar');
            dispatch(openModal('shipping'));
            return;
          }
        }
      }
      toggleCheck();
    };


    return (
      <div className="detailFooter_short">
        <div className={!open ? 'detailFooter_o' : 'detailFooter_ o detailFooter_c'}>
          <div className="detailFooter_row">
            <span>Balance: </span>
            <span>
              {`$${formatMoney(cart.balance === 0 ? (generalTotal) : (generalBalance))}`}
            </span>
          </div>
          <div className="detailFooter_row">
            <span>Total: </span>
            <span className="detailFooter_total">
              {`$ ${getTotal(discount, cart)}`}
            </span>
          </div>
        </div>
        <div>
          {
            history.location.pathname === '/pos/cart' && !oderChange && (
              <>
                {
                  mostrarCotizar && (
                    <ButtonBase
                      className="detailFooter_btn"
                      style={{ backgroundColor: '#F7C379' }}
                      disabled={!enableCharge}
                      onClick={() => makeQuote(history)}
                    >
                      <FontAwesomeIcon size="2x" icon="file-invoice-dollar" />
                      <span>Cotizar</span>
                    </ButtonBase>
                  )
                }

                {
                  mostrarCobrar && pos_no_cobrar === '0' && (
                    <ButtonBase
                      className="detailFooter_btn"
                      style={{ backgroundColor: '#8051A1' }}
                      disabled={!enableCharge}
                      onClick={() => {
                        openLocalModal();
                      }}
                    >
                      <FontAwesomeIcon size="2x" icon="hand-holding-usd" />
                      <span>Cobrar</span>
                    </ButtonBase>
                  )
                }

                {
                  (mostrarGuardar || pos_no_cobrar === '1') && (
                    <ButtonBase
                      className="detailFooter_btn"
                      style={{ backgroundColor: '#313c52' }}
                      onClick={() => {
                        makeSell();
                      }}
                    >
                      <FontAwesomeIcon size="2x" icon="save" />
                      <span>Guardar</span>
                    </ButtonBase>
                  )
                }
              </>
            )
          }

          {/*     {showDeliver && !oderChange && (
            <ButtonBase
              className="detailFooter_btn"
              style={{ backgroundColor: '#23A1DB' }}
              disabled={!enableDeliver}
              onClick={() => {
                history.push('/pos/deliver');
              }}
            >
              <FontAwesomeIcon size="2x" icon="shopping-cart" />
              <span>Entregar</span>
            </ButtonBase>
          )} */}

          {(history.location.pathname.includes('cotizacion')) && (
            <>
              <ButtonBase
                className="detailFooter_btn"
                style={{ backgroundColor: '#313c52' }}
                onClick={() => {
                  makeSell();
                }}
              >
                <FontAwesomeIcon size="2x" icon="clipboard-list" />
                <span>Generar Orden</span>
              </ButtonBase>
              {
                oderChange && (
                  <ButtonBase
                    className="detailFooter_btn"
                    style={{ backgroundColor: '#23A1DB' }}
                    onClick={() => {
                      saveOrder();
                    }}
                  >
                    <FontAwesomeIcon size="2x" icon="save" />
                    <span>Guardar</span>
                  </ButtonBase>
                )
              }
            </>
          )}
          {(history.location.pathname.includes('venta')) && (
            <>
              {
              pos_botonventas === 'Ambas' && !oderChange && (
                <>
                  <ButtonBase
                    className="detailFooter_btn"
                    style={{ backgroundColor: '#23A1DB' }}
                    disabled={!enableDeliver}
                    onClick={() => {
                      history.push('/pos/deliver');
                    }}
                  >
                    <FontAwesomeIcon size="2x" icon="shopping-cart" />
                    <span>Entregar</span>
                  </ButtonBase>

                  <ButtonBase
                    className="detailFooter_btn"
                    style={{ backgroundColor: '#8051A1' }}
                    disabled={!enableCharge}
                    onClick={() => {
                      openLocalModal();
                    }}
                  >
                    <FontAwesomeIcon size="2x" icon="hand-holding-usd" />
                    <span>Cobrar</span>
                  </ButtonBase>
                </>
              )
            }
              {
              pos_botonventas === 'Cobrar' && !oderChange && (
                <>
                  <ButtonBase
                    className="detailFooter_btn"
                    style={{ backgroundColor: '#8051A1' }}
                    disabled={!enableCharge}
                    onClick={() => {
                      openLocalModal();
                    }}
                  >
                    <FontAwesomeIcon size="2x" icon="hand-holding-usd" />
                    <span>Cobrar</span>
                  </ButtonBase>
                </>
              )
            }
              {
              pos_botonventas === 'Entregar' && !oderChange && (
                <>
                  <ButtonBase
                    className="detailFooter_btn"
                    style={{ backgroundColor: '#23A1DB' }}
                    disabled={!enableDeliver}
                    onClick={() => {
                      history.push('/pos/deliver');
                    }}
                  >
                    <FontAwesomeIcon size="2x" icon="shopping-cart" />
                    <span>Entregar</span>
                  </ButtonBase>
                </>
              )
            }
              {
                oderChange && (
                  <>
                    <ButtonBase
                      className="detailFooter_btn"
                      style={{ backgroundColor: '#313c52' }}
                      onClick={() => {
                        saveOrder();
                      }}
                    >
                      <FontAwesomeIcon size="2x" icon="save" />
                      <span>Guardar</span>
                    </ButtonBase>
                    <ButtonBase
                      className="detailFooter_btn"
                      style={{ backgroundColor: '#f05b70' }}
                      onClick={() => {
                        dispatch(selectOrder(orderSelected.order.crmid));
                        dispatch(setEditProductsComments(false));
                        dispatch(handleEditOrder());
                        setCurrentTotal(0);
                        setOrderChange(false);
                      }}
                    >
                      <FontAwesomeIcon size="2x" icon="times" />
                      <span>Cancelar</span>
                    </ButtonBase>
                  </>
                )
              }
            </>
          )}
        </div>
      </div>
    );
  }
  return (
    <Fade delay={3000}>
      <ListItem>
        <ListItemIcon>
          <Warning />
        </ListItemIcon>
        <ListItemText
          primary="Sin totales"
        />
      </ListItem>
    </Fade>
  );
};

DetailFooterShort.propTypes = {
  cart: PropTypes.object,
  discount: PropTypes.object,
  history: PropTypes.object,
  open: PropTypes.bool,
  showDeliver: PropTypes.bool,
  orderMode: PropTypes.bool,
  toggleCheck: PropTypes.func,
};

DetailFooterShort.defaultProps = {
  cart: {},
  discount: {},
  history: {},
  open: false,
  showDeliver: false,
  orderMode: false,
  toggleCheck: () => {},
};

export default withRouter(DetailFooterShort);
