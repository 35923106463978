import React from 'react';
import PropTypes from 'prop-types';
import {
  List, ListItem, ListItemText, Tooltip,
} from '@material-ui/core';
import ListLoading from '../../../List/ListLoading';
import InputNumber from '../../../Input/Number';
import { formatMoney, formatString } from '../../../../helpers/tools';

/* const products = [
  {
    crmid: '66506',
    treebessolicitudesenvioid: '73454',
    productname: 'OSMOSIS 1,000 LTS - C P S E',
    product_no: 'PRO16',
    productcode: 'OSM-C P S E',
    quantity: '10.000',
    unit_price: '258.62069000',
    tpoc_id: '66293',
    weight_s: '1000.00000000',
    height_s: '0.00000000',
    width_s: '0.00000000',
    length_s: '0.00000000',
    listprice: '125.00000000',
    precio_neto: '125.00000000',
    image: false,
  },
]; */


const ShipmentProducts = ({
  order, loading, products, add, rest, changeInput,
}) => {
  const disableQuantity = () => {
    if (!order) {
      return false;
    }
    const status = ['3- Preparando recoleccion', '4- Pendiente de recoleccion', '4- Recoleccion Pendiente'];
    return status.includes(`${order.tse_estado_i}`);
  };

  return (
    <div>
      <ListLoading isLoading={loading} />
      <div className="detailInfo_products">
        {
          products && products.length > 0 ? (

            products.map(prod => (
              <div className="refund_product" key={prod.crmid}>
                <div className="refund_product_detail">
                  <div className="" style={{ width: '100%', fontSize: 16, fontWeight: 500 }}>
                    <Tooltip placement="left-start" title={formatString(prod.productname) || ''}>
                      <span>{prod.productcode}</span>
                    </Tooltip>
                  </div>
                  <div>
                    $
                    {formatMoney(prod.precio_neto)}
                  </div>
                  <div className="refund_product_desc">
                    {`${formatString(prod.productname)}`}
                  </div>
                </div>

                <div className="refund_product_qty">
                  <InputNumber
                    number={parseFloat(prod.cantidad)}
                    disabledMinus={prod.cantidad === 0 || disableQuantity() || order.tse_estado_i === '7- Entregado parcial'}
                    disabledPlus={prod.cantidad >= prod.quantity || disableQuantity() || order.tse_estado_i === '7- Entregado parcial'}
                    onClickMinus={() => rest(prod.crmid)}
                    onClickPlus={() => add(prod.crmid)}
                    circleDiameter="48px"
                    disabledInput={prod.cantidad === 0 || prod.cantidad >= prod.quantity || disableQuantity()}
                    onChange={(value) => {
                      changeInput(parseFloat(value), prod.crmid);
                    }}
                  />
                </div>
              </div>
            ))

          ) : (<span>Sin productos</span>)
            }

      </div>
    </div>
  ); };

ShipmentProducts.propTypes = {
  order: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  products: PropTypes.array,
  add: PropTypes.func,
  rest: PropTypes.func,
  changeInput: PropTypes.func,
};

ShipmentProducts.defaultProps = {
  loading: false,
  products: [],
  add: () => {},
  rest: () => {},
  changeInput: () => {},
};

export default ShipmentProducts;
