import React from 'react';
import PropTypes from 'prop-types';

import {
  List,
  Divider,
  ListItem,
  Collapse,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import {
  Dvr,
  ExpandLess,
  ExpandMore,
} from '@material-ui/icons';
/* Components */
import BCMenu from './BCMenu';

const BCSelect = ({
  title,
  loading,
  authUser,
  selectBC,
}) => {
  const [open, setOpen] = React.useState(false);
  const metodos = authUser.isAuth ? authUser.user.metodos_pagos : [];
  const metodoString = metodos.map(method => method.method);
  const subTitle = metodoString.join(' - ');
  return (
    <List
      component="nav"
      aria-labelledby="nested-list-payMethods"
    >
      <ListItem
        dense
        button
        disabled={loading}
        onClick={() => setOpen(!open)}
      >
        <ListItemIcon>
          <Dvr />
        </ListItemIcon>
        <ListItemText className="method_list_header" primary={title} secondary={subTitle} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
        className="method_list"
      >
        {metodos.map(metodo => (
          <BCMenu
            key={`key_method_select_${metodo.method}`}
            metodo={metodo}
            loading={loading}
            selectBC={selectBC}
          />
        ))}
      </Collapse>
      <Divider />
    </List>
  );
};

BCSelect.propTypes = {
  title: PropTypes.string,
  loading: PropTypes.bool,
  selectBC: PropTypes.func,
  authUser: PropTypes.object.isRequired,
};
BCSelect.defaultProps = {
  loading: false,
  title: 'Cajas/Bancos',
  selectBC: () => {},
};

export default BCSelect;
