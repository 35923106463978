import React from 'react';
import { withRouter } from 'react-router-dom';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { dom } from '@fortawesome/fontawesome-svg-core';
import { Swipeable } from 'react-swipeable';

const SwipHeader = (props) => {
  const {
    activeTab, handleChange, onSwipeAction, tabs,
  } = props;
  dom.watch();
  return (
    <>
      <div className="w-100">
        <Swipeable
          className="d-flex flex-column f-1"
          onSwipedLeft={eventData => onSwipeAction(eventData)}
          onSwipedRight={eventData => onSwipeAction(eventData)}
        >
          <div>
            <Tabs
              value={activeTab}
              onChange={handleChange}
              variant="fullWidth"
              indicatorColor="primary"
              textColor="primary"
              scrollButtons="on"
              TabIndicatorProps={{ style: { background: '#313C52', height: 3 } }}
            >
              {
                tabs.map((item, idx) => (
                  <Tab
                    className="tab bg-white"
                    key={item.icon}
                    icon={(
                      <span className="update-icon" style={{ fontSize: 25, color: `${activeTab === idx ? '#313C52' : '#B6BEC6'}` }}>
                        <i className={item.icon} />
                      </span>
                  )}
                  />
                ))
              }
            </Tabs>
          </div>
        </Swipeable>
      </div>
    </>
  );
};
export default withRouter(SwipHeader);
