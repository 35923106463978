/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Button,
  Collapse,
  InputAdornment,
  LinearProgress,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { Spinner } from 'reactstrap';
import { toolsHelper } from '../../../helpers';
/* Components */
import ListMdaItem from '../../Mda/components/ListMda/ListMdaItem';

import RefundConstructor from './Refund';
import Deliver from './Deliver';

class WharehouseForm extends Component {
  static propTypes = {
    activity: PropTypes.string.isRequired,
    authUser: PropTypes.object.isRequired,
    dispatchChangeComment: PropTypes.func.isRequired,
    dispatchSelectEmpty: PropTypes.func.isRequired,
    dispatchSelectToDelivered: PropTypes.func.isRequired,
    dispatchSelectToRefunded: PropTypes.func.isRequired,
    loads: PropTypes.object.isRequired,
    refundAll: PropTypes.func,
    table: PropTypes.object.isRequired,
  };

  static defaultProps = {
    refundAll: () => {},
  };

  constructor(props) {
    super(props);
    const { authUser } = this.props;
    const { user: { metodos_pagos } } = authUser;
    this.state = {
      metodo: metodos_pagos[0],
      subMetodo: metodos_pagos[0].body[0],
    };
  }

  getDate = () => {
    const actual = new Date();
    const day = `0${actual.getDate()}`.slice(-2);
    const month = `0${actual.getMonth() + 1}`.slice(-2);
    const year = actual.getFullYear();

    return `${year}-${month}-${day}`;
  };

  getInputsAdornaments = () => {
    const { loads } = this.props;
    if (loads.tableIsLoading) {
      return {
        endAdornment: (
          <InputAdornment position="end">
            <Spinner />
          </InputAdornment>
        ),
      };
    }
    return {};
  };

  changeComment = (value) => {
    const { dispatchChangeComment } = this.props;
    dispatchChangeComment(value);
  }

  handleMetodo = (x) => {
    this.setState({
      metodo: x,
      subMetodo: x.body[0],
    });
  };

  handleSubMetodo = (x) => {
    this.setState({ subMetodo: x });
  };

  handleMethodMenu = (event) => {
    const { name } = event.currentTarget;
    this.setState({ [name]: event.currentTarget });
  };

  handleMethodOnClose = (name) => {
    this.setState({ [name]: null });
  }

  render() {
    const {
      activity,
      dispatchSelectEmpty,
      dispatchSelectToDelivered,
      dispatchSelectToRefunded,
      loads,
      refundAll,
      table,
    } = this.props;
    const focusUsernameInputField = (input) => {
      // eslint-disable-next-line no-unused-expressions
      if (loads.tableIsLoading) { input && input.focus(); }
    };
    const productsSelected = Boolean(table.items && table.items.some(x => x.field > 0));
    // const qtySelected = (table.items.length > 0 ? (table.items.map(x => x.field).reduce((a, b) => (a + b))) : (0));
    let moneySelected = (table.items.length > 0 ? (table.items.map(x => (x.price * x.field)).reduce((a, b) => (a + b))) : (0));
    if (table.totalDiscount > 0) moneySelected -= (moneySelected * (table.totalDiscount / 100));
    const dif = (table.total - (table.total * (table.totalDiscount / 100))) - table.balance;
    const somePaid = Boolean(dif > 0);
    if (Math.round(table.balance) < Math.round(moneySelected)) moneySelected -= table.balance;
    const {
      metodo,
      subMetodo,
    } = this.state;

    return (

      <div className="w-100 d-flex flex-column">
        {Boolean(!loads.tableIsLoading) && (
        <div className="w-100">
          <div className="infoDetail">
            <ListMdaItem
              item={{
                createdtime: table.date,
                entity_label: table.ref,
                de_documentoid_label: activity === 'deliver' ? table.store.name : table.toSell,
                a_documentoid_label: activity === 'deliver' ? table.toSell : table.store.name,
              }}
              isCollapse={false}
              sc="Entrega"
            />
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  {table.account.nombre.substring(0, 1)}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={table.account.nombre}
                secondary="Cliente"
              />
            </ListItem>
          </div>
          <TextField
            id="outlined-comments"
            label="Comentarios"
            className="w-100"
            styke={{ margin: '0 !important' }}
            variant="outlined"
            multiline
            rows={1}
            rowsMax={4}
            inputRef={focusUsernameInputField}
            onKeyUp={(e) => {
              // @ts-ignore
              const { value } = e.target;
              this.changeComment(value);
            }}
          />
        </div>
        )}
        {activity !== 'deliver' ? (
          <RefundConstructor />
        ) : (
          <>
            {activity === 'deliver' ? (
              <div className="wharehouse_form_btn w-100 d-flex justify-content-around mt-2">
                <Button
                  onClick={() => {
                    dispatchSelectToDelivered();
                  }}
                  variant="outlined"
                  color="primary"
                >
                  {'Seleccionar Por Entregar'}
                </Button>
                <Button
                  onClick={() => {
                    dispatchSelectEmpty();
                  }}
                  variant="outlined"
                  color="secondary"
                >
                  {'Seleccionar Nada'}
                </Button>
              </div>
            ) : (
              <div className="wharehouse_form_btn w-100 d-flex justify-content-around">
                <Button
                  onClick={() => {
                    dispatchSelectToRefunded();
                  }}
                  variant="outlined"
                  color="primary"
                >
                  {'Selecciona todo'}
                </Button>
                <Collapse
                  in={productsSelected && somePaid}
                >
                  <Tooltip title={`por $${toolsHelper.formatMoney(moneySelected)} en una salida de dinero via ${metodo.method} - ${subMetodo.entity_label}`}>
                    <Button
                      onClick={() => {
                        if (productsSelected && somePaid) {
                          const referencia = table.ref;
                          const payments = [{
                            metodo: metodo.method,
                            tcybid: subMetodo.crmid,
                            monto: moneySelected,
                            referencia,
                          }];
                          const crmid = table.toSell;
                          refundAll({ payments, crmid });
                        }
                      }}
                      variant="outlined"
                      color="inherit"
                    >
                      {'Devolver productos y dinero'}
                    </Button>
                  </Tooltip>
                </Collapse>
                <Button
                  onClick={() => {
                    dispatchSelectEmpty();
                  }}
                  variant="outlined"
                  color="secondary"
                >
                  {'Vacía selección'}
                </Button>
              </div>
            )}
            {activity === 'deliver' && (
              <Deliver />
            )}
            {loads.tableIsLoading && loads.orderIsLoading && (
              <div className="w-100 h-100 py-4 mt-2">
                <LinearProgress color="primary" />
              </div>
            )}
          </>
        )}
        <div style={{ marginTop: '12.5vh', marginBottom: '12.5vh' }} className="w-100" />
      </div>
    );
  }
}

export default WharehouseForm;
