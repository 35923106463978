import React from 'react';
import PropTypes from 'prop-types';
import './ListProduct.scss';
import { Collapse, ButtonBase } from '@material-ui/core';

const ListProduct = ({
  image,
  primary,
  secondary,
  label,
  badge,
  footer,
  onDanger,
  isCollapse,
  collapseChild,
}) => {
  const [open, setOpen] = React.useState(false);

  return (
    <ButtonBase
      className={`listProduct_btn ${open ? 'listProduct_selected' : ''}`}
      
    >
      <div className="listProduct_container" onClick={() => {
        setOpen(!open);
      }}>
        {badge !== '' && (
        <div
          className={onDanger ? 'listProduct_badge listProduct_onDanger' : 'listProduct_badge'}
        >
          {badge}
        </div>
        )}
        <div
          className="listProduct_image"
          style={{
            backgroundImage: `url(${image !== ''
              ? image.replace(/\(/g, '%28').replace(/\)/g, '%29').replace(/\s/g, '%20')
              : '/img/no_image.jpg'})`,
          }}
        />
        <div className="listProduct_detail">
          <div>
            <span className="listProduct_primary">
              {
              primary.length > 30 && !open
                ? (`${primary.substring(0, 30)}...`)
                : (primary)
              }
            </span>
            {label !== '' && (
            <span className="listProduct_label">
              {label}
            </span>
            )}
          </div>
          <div>
            <span className="listProduct_secondary">
              {secondary}
            </span>
            <span className="listProduct_footer">
              {footer}
            </span>
          </div>
        </div>
      </div>
      {isCollapse && (
      <Collapse in={open} mountOnEnter unmountOnExit className="w-100">
        {collapseChild}
      </Collapse>
      )}
    </ButtonBase>
  );
};

ListProduct.propTypes = {
  image: PropTypes.string,
  primary: PropTypes.string,
  secondary: PropTypes.string,
  label: PropTypes.string,
  badge: PropTypes.any,
  footer: PropTypes.any,
  collapseChild: PropTypes.any,
  isCollapse: PropTypes.bool,
  onDanger: PropTypes.bool,
};

ListProduct.defaultProps = {
  image: '',
  primary: '',
  secondary: '',
  label: '',
  badge: '',
  footer: '',
  collapseChild: '',
  isCollapse: false,
  onDanger: false,
};

export default ListProduct;
