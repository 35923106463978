import React from 'react';
import PropTypes from 'prop-types';
import './IconWidthBG.scss';

export const IconWidthBG = ({
  title,
  children,
  primaryColor,
  secondaryColor,
  actionClick,
  enabled,
  height,
  width,
  square,
  rounded,
  classes,
  // type,
}) => (
  <div
    className={`${square ? 'rootSquare' : 'root'} ${classes}`}
    onClick={enabled ? actionClick : null}
  >
    <div
      className={`${square ? 'wrapSquare' : 'wrap'} ${rounded ? 'wrapRounded' : ''}`}
      style={{
        color: secondaryColor, backgroundColor: primaryColor, height: height, width: width,
      }}
    >
      <div className="child">
        {children}
      </div>
    </div>
    <span className={`${square ? 'titleSquare' : 'title'} `}>{title}</span>
  </div>
);


IconWidthBG.defaultProps = {
  title: '',
  primaryColor: '#313c52',
  secondaryColor: '#fff',
  enabled: true,
  square: false,
  rounded: false,
  height: 52,
  width: 52,
  classes: '',
  // type: '',
  actionClick: () => null,
};
IconWidthBG.propTypes = {
  title: PropTypes.string,
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string,
  enabled: PropTypes.bool,
  square: PropTypes.bool,
  rounded: PropTypes.bool,
  actionClick: PropTypes.func,
  children: PropTypes.node,
 // size: PropTypes.number,
  height: PropTypes.number,
  width: PropTypes.number,
  classes: PropTypes.string,
  // type: PropTypes.oneOf(['', 'square', 'rounded']),
};
export default IconWidthBG;
