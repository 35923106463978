/* eslint-disable react/prop-types */
/* eslint-disable import/no-cycle */
import React from 'react';
import { useSelector } from 'react-redux';
import {
  Collapse,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import SearchTopBar from '../SearchTopBar';
import ListData from '../ListData';
import { setUser } from '../../../../redux/actions/taskActions';

const Users = ({
  type,
  fetchData,
}) => {
  const users = useSelector(state => state.tasks.users);
  const loads = useSelector(state => state.loads);
  return (
    <div className="proveedor-container" style={{ height: '94%' }}>
      <SearchTopBar type={type} />
      { loads.updateIsLoading && (
        <Collapse in={loads.updateIsLoading}>
          <LinearProgress />
        </Collapse>
      )}
      { !loads.updateIsLoading && users.length === 0 && (
        <Collapse in={!loads.updateIsLoading && users.length === 0} style={{ height: 'inherit', overflow: 'auto' }}>
          <List dense>
            <ListItem classes={{ root: 'bg-white' }}>
              <ListItemText
                primary="Sin usuarios"
                secondary="No se encontraron usuarios"
              />
            </ListItem>
          </List>
        </Collapse>
      )}
      { !loads.updateIsLoading && users.length > 0 && (
        <Collapse in={!loads.updateIsLoading && users.length > 0} style={{ height: 'inherit', overflow: 'auto' }}>
          <ListData data={users} fetchData={setUser} type={type} />
        </Collapse>
      )}
    </div>
  );
};

export default Users;
