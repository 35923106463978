import React from 'react';
import PropTypes from 'prop-types';

import CCTipoItem from './CCTipoItem';
import DocType from '../documents/document';

const CCTipo = ({ elements, click }) => (
  <div className="balance__contenedor-hijo balance__lista" style={{ display: 'flex', flexDirection: 'column' }}>
    {elements.map((el) => {
      const {
        cantidad,
        enable,
        label,
        movimientos,
        id,
        type,
        // total,
      } = el;
      let countCheck = 0;
      let totalCheck = 0.0;
      movimientos.forEach((mov) => {
        mov.detalles.forEach((det) => {
          if (det.enable === true) {
            countCheck += 1;
            if (det.mantener_efe && det.monto > 0) {
              totalCheck -= parseFloat(det.monto);
            } else {
              totalCheck += parseFloat(det.monto);
            }
          }
        });
      });
      return (
        label !== 'DOCS' ? (
          <CCTipoItem
            cantidad={cantidad}
            click={click}
            countCheck={countCheck}
            enable={enable}
            id={id}
            key={id}
            label={label}
            movimientos={movimientos}
            totalCheck={totalCheck}
            type={type}
          />
        ) : (
          // New docs component
          <DocType
            cantidad={cantidad}
            click={click}
            enable={enable}
            id={id}
            key={id}
            label={label}
            totalCheck={totalCheck}
            type={type}
            movimientos={movimientos}
          />
        )
      );
    })}
  </div>
);

CCTipo.propTypes = {
  elements: PropTypes.array,
  click: PropTypes.func,
};

CCTipo.defaultProps = {
  elements: [],
  click: () => {},
};

export default CCTipo;
