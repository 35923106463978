import React from 'react';
import {
  Collapse,
  Checkbox,
  Button,
} from '@material-ui/core';
import {
  Refresh,
  VerticalAlignCenter,
} from '@material-ui/icons';
import PropTypes from 'prop-types';

const RefundFdeControls = ({
  checked,
  indeter,
  open,
  enableJoin,
  selectAndUnSelectAll,
  joinSelection,
  returnSelection,
}) => (
  <Collapse in={open} className="w-100">
    <div className="refund_controls">
      <Checkbox
        className="p-1 mr-auto"
        checked={checked}
        indeterminate={indeter}
        onClick={() => selectAndUnSelectAll()}
        disableRipple={false}
        color="secondary"
      />
      <Button
        variant="outlined"
        color="primary"
        size="small"
        className="ml-2"
        disabled={!enableJoin}
        onClick={() => joinSelection()}
      >
        Unir
        <VerticalAlignCenter />
      </Button>
      <Button
        variant="outlined"
        color="secondary"
        size="small"
        className="ml-2"
        onClick={() => returnSelection()}
      >
        Restaurar
        <Refresh />
      </Button>
    </div>
  </Collapse>
);

RefundFdeControls.propTypes = {
  checked: PropTypes.bool,
  indeter: PropTypes.bool,
  open: PropTypes.bool,
  enableJoin: PropTypes.bool,
  selectAndUnSelectAll: PropTypes.func,
  joinSelection: PropTypes.func,
  returnSelection: PropTypes.func,
};

RefundFdeControls.defaultProps = {
  checked: false,
  indeter: false,
  open: false,
  enableJoin: false,
  selectAndUnSelectAll: () => {},
  joinSelection: () => {},
  returnSelection: () => {},
};

export default RefundFdeControls;
