/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import './OrderDetail.scss';
import { withRouter } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';

import DetailTabs from './components/DetailTabs';
import DetailInfo from './components/DetailInfo';
import DetailFooter from './components/DetailFooter';

import Fde from '../../../../Fde';
import Mda from '../../../../Mda';
import Factura from '../../../../Factura';
import Files from '../../../../Files';
import ShippingGuideList from '../../../../ShippingGuide/ShippingGuideList';

const OrderDetail = ({
  match,
  setOrder,
}) => {
  const [value, setValue] = React.useState(0);

  React.useEffect(() => {
    setTimeout(() => {
      setOrder(match.params.id);
    }, 100);
  // eslint-disable-next-line
  }, []);

  return (
    <div className="orderDetail_container">
      <DetailTabs
        onChange={index => setValue(index)}
        value={value}
      />
      <SwipeableViews
        axis="x"
        resistance
        index={value}
        enableMouseEvents
        onChangeIndex={index => setValue(index)}
      >
        <DetailInfo />
        {value === 1 ? (<Fde type="list" />) : <div />}
        {value === 2 ? (<ShippingGuideList />) : <div />}
        {value === 3 ? (<Mda type="list" />) : <div />}
        {value === 4 ? (<Factura type="list" />) : <div />}
        {value === 5 ? (<Files type="list" />) : <div />}
      </SwipeableViews>
      <DetailFooter showDeliver />
    </div>
  );
};

OrderDetail.propTypes = {
  match: PropTypes.object.isRequired,
  setOrder: PropTypes.func.isRequired,
};

export default withRouter(OrderDetail);
