import React, { useEffect } from 'react';
import './DocumentsContainer.scss';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import DocumentsItems from './components/DocumentsItems';
import { fetchAccountDocuments } from '../../../../redux/actions/accountActions';
import ScrollPaginator from '../../../../components/ScrollPaginator/ScrollPaginator';

const DocumentsContainer = withRouter(({ match, ...props }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAccountDocuments(1, '', match.params.crmid || match.params.id));
    // eslint-disable-next-line
  }, []);

  const documents = useSelector(state => state.account.documents);

  return (
    <ScrollPaginator
      allItems={documents.data}
      totalPages={documents.pages}
      itemsPerPage={25}
      currentPage={1}
      childComponent={<DocumentsItems />}
      action={fetchAccountDocuments}
      clientId={match.params.crmid}
    />
  );
});

export default DocumentsContainer;
