import React from 'react';
import PropTypes from 'prop-types';
import {
  List,
  Checkbox,
  Collapse,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import CCDetalle from '../detalle';
import { formatMoney } from '../../../../../../helpers/tools';

const DocumentoItem = ({
  client,
  click,
  fechaRealizado,
  enable,
  id,
  key,
  venta,
  type,
  tipo,
  liga,
}) => (
  <List key={id}>
    <ListItem
      button
      className="detalle_box detalle_box_divider"
    >
      <ListItemText
        primary={(
          <>
            <Typography component="span" variant="body2" color="textPrimary">
              <div className="balance-detalles__flex-space">
                <strong>{venta}</strong>
                <span className="detalle_cantidad">{fechaRealizado}</span>
                <span>{`${tipo}`.split('/').pop()}</span>
              </div>
            </Typography>
          </>
          )}
        secondary={(
          <>
            <Typography component="span" variant="body2" color="textPrimary">
              <div className="balance-detalles__flex-space">
                <span>{client}</span>
                <a target="_blank" href={liga} rel="noreferrer">Ver documento</a>
              </div>
            </Typography>
          </>
          )}
      />
      <Checkbox
        className="balance-detalles__no-padding-lr"
        checked={Boolean(enable)}
        data-id={id}
        data-kind={type}
        onClick={(e) => {
          const { currentTarget } = e;
          click(currentTarget);
          e.preventDefault();
          e.stopPropagation();
        }}
      />
    </ListItem>
  </List>
);

DocumentoItem.propTypes = {
  client: PropTypes.string.isRequired,
  click: PropTypes.func.isRequired,
  fechaRealizado: PropTypes.string.isRequired,
  enable: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  key: PropTypes.string,
  venta: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  tipo: PropTypes.string.isRequired,
  liga: PropTypes.string.isRequired,
};

DocumentoItem.defaultProps = {
  key: '',
};

export default DocumentoItem;
