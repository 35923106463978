import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fab from '@material-ui/core/Fab';
import Collapse from '@material-ui/core/Collapse';


const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const GenericFullModal = ({
  open,
  title,
  handleClose,
  componentToShow,
}) => (
  <Dialog fullScreen open={open} onClose={() => handleClose()} TransitionComponent={Transition}>
    <AppBar style={{ position: 'relative' }}>
      <Toolbar classes={{ root: 'tollbar-header' }}>
        <Button autoFocus color="inherit" onClick={() => {}}> </Button>
        <Typography variant="h6" classes={{ root: 'text-white' }}>
          {title}
        </Typography>
        <IconButton edge="start" color="inherit" onClick={() => handleClose()} aria-label="close">
          <CloseIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
    <div className="main-content">
      {componentToShow}
    </div>
  </Dialog>
);

export default GenericFullModal;
