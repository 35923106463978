/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import {
  List,
  Divider,
  ListItem,
  Collapse,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import {
  Book, Reorder, ExpandLess, ExpandMore, Bookmarks, BookmarksOutlined,
} from '@material-ui/icons';
import { getStorage } from '../../../helpers/pricebook';

import PricebookSelect from './PricebookSelect';

const PricebookMenu = ({ auth, loading, setPricebook }) => {
  const [open, setOpen] = React.useState(false);
  const { user } = auth;
  const arePricebooks = Boolean(user.PriceBooks && user.PriceBooks.length > 0);
  const select = getStorage();
  const isSelected = Boolean(Object.keys(select).length > 3);
  const accountSelected = Boolean(select.crmid !== '' && select.isCustomer);
  const secondary = isSelected ? select.entity_label : (accountSelected ? 'Pred. cliente' : 'Predeterminada');
  return (
    <List
      component="nav"
      aria-labelledby="nested-list-payMethods"
    >
      <ListItem
        dense
        button
        disabled={loading || !arePricebooks}
        onClick={() => {
          if (arePricebooks) setOpen(!open);
        }}
      >
        <ListItemIcon>
          {arePricebooks ? (<Book />) : (<Reorder />)}
        </ListItemIcon>
        {arePricebooks ? (
          <ListItemText
            className="method_list_header"
            primary="Listas de Precios"
            secondary={secondary}
          />
        ) : (
          <ListItemText
            className="method_list_header"
            primary={accountSelected ? 'Lista del cliente' : 'Sin lista de precios'}
          />
        )}
        {arePricebooks && (
        <span>
          {open ? <ExpandLess /> : <ExpandMore />}
        </span>
        )}
      </ListItem>
      {arePricebooks && (
        <Collapse
          in={open}
          timeout="auto"
          unmountOnExit
          className="method_list"
        >
          <ListItem
            dense
            button
            disabled={loading}
            style={{ paddingLeft: '2.4em' }}
            onClick={() => {
              setOpen(false);
              setPricebook({});
            }}
          >
            <ListItemIcon>
              {!isSelected ? (<Bookmarks />) : (<BookmarksOutlined />)}
            </ListItemIcon>
            <ListItemText
              className="method_list_header"
              primary="Predeterminada"
            />
          </ListItem>
            {user.PriceBooks.map(item => (
              <PricebookSelect
                key={`key_pricebook_${item.crmid}`}
                item={item}
                loading={loading}
                onClick={() => {
                  setOpen(false);
                  setPricebook(item);
                }}
                select={Boolean(select.crmid === item.crmid)}
              />
            ))}
        </Collapse>
      )}
      <Divider />
    </List>
  );
};

PricebookMenu.propTypes = {
  auth: PropTypes.object,
  loading: PropTypes.bool,
  setPricebook: PropTypes.func,
};

PricebookMenu.defaultProps = {
  auth: {},
  loading: false,
  setPricebook: () => {},
};

export default PricebookMenu;
