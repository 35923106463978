import React from 'react';

const BankIcon = props => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    width="100px"
    height="100px"
    viewBox="0 0 100 100"
    enableBackground="new 0 0 100 100"
    xmlSpace="preserve"
    {...props}
  >
    <g>
      <path d="M89.997,34.485c0.031-0.658-0.189-1.04-0.756-1.414c-9.889-6.534-19.759-13.092-29.634-19.646
		c-3.213-2.133-6.427-4.262-9.657-6.405c-0.255,0.16-0.496,0.305-0.728,0.46c-9.037,6.017-18.072,12.035-27.108,18.051
		c-3.849,2.563-7.703,5.117-11.542,7.692c-0.242,0.162-0.525,0.461-0.538,0.716c-0.058,1.17-0.025,2.56-0.025,3.56
		c26.712,0,53.306,0,79.976,0C89.984,36.5,89.949,35.474,89.997,34.485z M50,35.578c-6.361,0-11.518-5.156-11.518-11.518
		S43.639,12.542,50,12.542s11.518,5.156,11.518,11.518S56.361,35.578,50,35.578z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.041,29.906c0,0.482-0.005,0.883,0.001,1.284
		c0.009,0.503-0.224,0.754-0.734,0.739c-0.191-0.006-0.383-0.003-0.574-0.003c-0.474,0.001-0.694-0.237-0.692-0.71
		c0.002-0.426,0.128-0.969-0.08-1.24c-0.199-0.26-0.767-0.226-1.165-0.347c-1.303-0.393-2.258-1.172-2.669-2.503
		c-0.213-0.695-0.062-0.89,0.659-0.899c0.319-0.003,0.641,0.029,0.957-0.004c0.563-0.048,0.958,0.08,1.203,0.669
		c0.115,0.272,0.454,0.513,0.75,0.643c0.98,0.434,1.987,0.399,2.963-0.035c0.474-0.207,0.822-0.549,0.814-1.111
		c-0.008-0.577-0.394-0.897-0.888-1.037c-0.915-0.261-1.859-0.433-2.772-0.702c-0.792-0.235-1.601-0.469-2.322-0.857
		c-1.86-1.012-2.127-3.52-0.484-4.872c0.63-0.518,1.483-0.77,2.249-1.116c0.225-0.098,0.492-0.1,0.789-0.158
		c0-0.472-0.001-0.916,0-1.359c0.001-0.415,0.213-0.631,0.624-0.644c0.235-0.007,0.471-0.003,0.702-0.004
		c0.448-0.002,0.66,0.223,0.667,0.668c0.005,0.444-0.14,1.014,0.083,1.297c0.212,0.278,0.81,0.246,1.228,0.374
		c1.258,0.38,2.181,1.124,2.585,2.416c0.227,0.741,0.088,0.931-0.672,0.938c-0.256,0.002-0.512-0.022-0.766,0.004
		c-0.632,0.057-1.161,0.003-1.42-0.734c-0.082-0.232-0.403-0.438-0.666-0.552c-1.026-0.469-2.067-0.415-3.08,0.049
		c-0.448,0.21-0.756,0.563-0.728,1.104c0.022,0.512,0.335,0.839,0.79,0.97c0.938,0.27,1.897,0.46,2.827,0.738
		c0.791,0.237,1.61,0.459,2.321,0.854c1.944,1.081,2.161,3.611,0.398,4.993c-0.607,0.48-1.428,0.693-2.165,1.005
		C51.568,29.847,51.322,29.851,51.041,29.906z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.015,80.176c12.779,0,25.558,0,38.336,0.002c1.402,0,1.58,0.18,1.58,1.582
		c0,1.355,0.006,2.709-0.003,4.064c-0.005,0.859-0.229,1.096-1.076,1.141c-0.407,0.023-0.817,0.012-1.227,0.012
		c-25.353,0.002-50.706,0.004-76.058,0.004c-1.308,0-1.507-0.197-1.508-1.5c-0.001-1.381-0.008-2.762,0.005-4.141
		c0.008-0.867,0.277-1.127,1.155-1.16c0.332-0.014,0.665-0.004,0.997-0.004C24.815,80.176,37.415,80.176,50.015,80.176z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.177,42.237c1.318,0,2.618,0.001,3.917,0c1.995,0,3.989,0.022,5.982-0.016
		c0.598-0.012,0.828,0.163,0.955,0.762c1.03,4.894,1.47,9.847,1.53,14.839c0.07,5.891-0.303,11.742-1.53,17.521
		c-0.13,0.613-0.345,0.82-0.995,0.813c-3.016-0.041-6.034-0.045-9.05,0c-0.686,0.01-0.909-0.221-1.041-0.852
		c-1.273-6.086-1.639-12.248-1.501-18.443c0.103-4.656,0.533-9.279,1.522-13.839C18.02,42.777,18.096,42.537,18.177,42.237z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M83.489,59.105c0.049,5.369-0.317,10.705-1.39,15.982
		c-0.168,0.826-0.471,1.092-1.331,1.074c-2.962-0.061-5.926-0.035-8.889-0.01c-0.565,0.004-0.779-0.158-0.902-0.732
		c-0.911-4.258-1.35-8.568-1.496-12.912c-0.165-4.881-0.019-9.752,0.648-14.594c0.236-1.717,0.605-3.415,0.881-5.126
		c0.074-0.464,0.29-0.561,0.718-0.559c3.167,0.015,6.335,0.022,9.503-0.006c0.539-0.005,0.676,0.216,0.775,0.68
		c0.881,4.131,1.348,8.31,1.477,12.529C83.521,56.654,83.489,57.881,83.489,59.105z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.397,76.137c-3.544,0-6.942,0.012-10.339-0.027
		c-0.189-0.002-0.504-0.393-0.54-0.633c-0.487-3.307-1.083-6.607-1.359-9.932c-0.443-5.332-0.351-10.678,0.28-15.998
		c0.267-2.251,0.707-4.484,1.066-6.725c0.057-0.355,0.158-0.597,0.619-0.594c3.324,0.021,6.647,0.014,9.971,0.02
		c0.072,0,0.146,0.036,0.326,0.084C57.661,53.559,57.657,64.805,55.397,76.137z"
      />
    </g>
  </svg>
);

export default BankIcon;
