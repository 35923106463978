/* eslint-disable import/no-cycle */
import { connect } from 'react-redux';
import { fetchMda } from '../../redux/actions/orderActions';
import { closeModal } from '../../redux/actions/modalActions';
import { fetchListMda } from '../../redux/actions/financeActions';
import Mda from './constructor/Mda';

const mapStateToProps = (state) => {
  const {
    order, loads, cart, finance, client, gastos,
  } = state;
  const { mda } = order;
  const { orderSelected } = cart;
  const { mdas } = finance;
  const { selectClient } = client;
  const { gastoSeleccionado } = gastos;
  return ({
    loading: Boolean(loads.orderIsLoading),
    modals: state.modals,
    mda,
    clientsMda: mdas,
    client: selectClient,
    orderSelected,
    gasto: gastoSeleccionado,
  });
};

const mapDispatchToProps = dispatch => ({
  dispatchCloseDiscountModal: () => dispatch(closeModal('mda')),
  dispatchFetchMda: () => dispatch(fetchMda()),
  dispatchFetchClientsMda: () => dispatch(fetchListMda()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Mda);
