/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */
import {
  ADD_ORDERS,
  CLEAR_MULTIFILTER_ORDER,
  CONFIG_SELL_COLUMN_DEF,
  DEFINE_COLUMN_ORDER,
  FETCH_FACTURAS,
  FETCH_FDE,
  FETCH_FILTER,
  FETCH_MDA,
  FILTER_ORDERS,
  GET_COLUMN_DEF_ORDER,
  GET_FILES,
  GET_NEXT_PAGE_ORDER,
  GET_ORDERS_BY_PAGE,
  HANDLE_EDIT_ORDER,
  REMOVE_MULTIFILTER_ORDER,
  RESET_COLUMN_DEF,
  RESET_EDIT_ORDER,
  RESET_STATE,
  SET_COLUMN_DEF,
  SET_MULTIFILTER_ORDER,
  SET_PRESELECT_ORDER,
  IS_EDIT_ORDER,
  COMBINED_PRODUCT_ID,
  SET_ORDER_VIEW_DATA,
} from '../actions/orderActions';

import { orderHelper, toolsHelper, storageHelper } from '../../helpers';

const totalItemsForPage = 25;
const numberParser = (params) => {
  const { newValue } = params;
  let valueAsNumber;
  if (newValue === null || newValue === undefined || newValue === '') {
    valueAsNumber = null;
  } else {
    valueAsNumber = parseFloat(params.newValue);
  }
  return valueAsNumber;
};
const defaultState = () => ({
  actualPage: 1,
  totalPages: 1,
  orders: [],
  paginatedOrders: [],
  filters: [],
  mda: [],
  fde: [],
  facturas: [],
  files: {
    result: [],
    count: 0,
    pages: 0,
    actualPage: 0,
  },
  categories: [{
    title: 'Todos',
    word: 'general',
    cats: [
      { title: 'ID', column: 'crmid', type: 'general' },
      { title: 'Numero de Orden', column: 'salesorder_no', type: 'general' },
      { title: 'Estado', column: 'sostatus', type: 'general' },
      { title: 'Total', column: 'hdnGrandTotal', type: 'general' },
      { title: 'Movimiento de almacen', column: 'estado_mda', type: 'general' },
      { title: 'Flujo de efectivo', column: 'estado_fde', type: 'general' },
    ],
  }],
  columnsDefs: [
    {
      headerName: 'ID',
      children: [
        {
          headerName: 'ID',
          field: 'salesorder_no',
          hide: false,
        },
        {
          headerName: 'Cliente',
          field: 'accountname',
          hide: false,
        },
        {
          headerName: 'Almacenista',
          field: 'almacenista',
          hide: false,
        },
      ],
    },
    {
      headerName: 'Fechas',
      children: [
        {
          headerName: 'Autorizados',
          field: 'f_estado_autori',
          hide: false,
        },
        {
          headerName: 'Apartados',
          field: 'f_estado_aparta',
          hide: false,
        },
        {
          headerName: 'Cancelados',
          field: 'f_estado_cancel',
          hide: false,
        },
        {
          headerName: 'Timbrado',
          field: 'f_ultima_factura',
          hide: false,
        },
      ],
    },
    {
      headerName: 'Estados',
      children: [
        {
          headerName: 'Pago',
          field: 'estado_fde',
          hide: false,
        },
        {
          headerName: 'Almacen',
          field: 'estado_mda',
          hide: false,
        },
        {
          headerName: 'Estado',
          field: 'sostatus',
          hide: false,
        },
        {
          headerName: 'SAT',
          field: 'estadosat',
          hide: false,
        },
      ],
    },
    {
      headerName: 'Totales',
      children: [
        {
          headerName: 'Balance',
          field: 'tfde_balance',
          cellStyle: { textAlign: 'right' },
          valueGetter: params => (`$ ${toolsHelper.formatMoney(params.data.hdnGrandTotal)}`),
          hide: false,
          valueParser: numberParser,
          cellClassRules: {
            'rag-green': 'x === 0.0',
            'rag-amber': 'x > 0.0',
          },
        },
        {
          headerName: 'Total',
          field: 'hdnGrandTotal',
          cellStyle: { textAlign: 'right' },
          valueGetter: params => (`$ ${toolsHelper.formatMoney(params.data.hdnGrandTotal)}`),
          hide: false,
        },
      ],
    },
  ],
  enableEditOrder: false,
  preselectOrder: null,
  isEditOrder: false,
  combinedProductId: null,
});

const getOrdersByPage = (state, page = 0) => {
  const { orders } = state;
  const index = page > 0 ? page - 1 : page;
  const paginatedOrders = orderHelper.paginateOrders(
    orders,
    totalItemsForPage,
    index,
  );
  return { ...state, ...paginatedOrders };
};

const getNextOrder = (state, page = 0) => {
  const { orders } = state;
  const index = page > 0 ? page - 1 : page;
  const newState = orderHelper.paginateOrders(
    orders,
    totalItemsForPage,
    index,
  );
  return {
    ...state,
    actualPage: newState.actualPage,
    totalPages: newState.totalPages,
    paginatedOrders: [...state.paginatedOrders, ...newState.paginatedOrders],
  };
};

const filterOrder = (state, word) => {
  let paginatedOrders = [...state.orders];
  const orders = word.length <= 1 ? paginatedOrders : orderHelper.findOrders(paginatedOrders, word);
  paginatedOrders = orderHelper.paginateOrders(orders, totalItemsForPage, 0);
  return { ...state, ...paginatedOrders };
};

const addOrders = (state, items) => {
  let raw = toolsHelper.jsonToArray(items);
  raw = raw.map((x) => {
    if (x.estadosat === null) x.estadosat = 'Sin Factura';
    return x;
  });
  const paginatedOrders = orderHelper.paginateOrders(raw, totalItemsForPage, 0);
  return { ...state, ...paginatedOrders, orders: raw };
};

const setFilter = (state, filters, element) => {
  const array = filters.filter(x => x.type === element.type);
  if (array.length === 0) {
    filters.push(element);
  } else {
    filters.forEach((x, index) => {
      if (x.type === array[0].type) { filters[index] = element; }
    });
  }
  return { ...state, filters };
};

const removeFilter = (state, filters, word) => {
  const array = filters.filter(x => x.type === word);
  if (array.length !== 0) {
    filters.forEach((filter, i) => {
      if (filter.type === word) filters.splice(i, 1);
    });
  }
  return { ...state, filters };
};

const clearFilter = (state) => {
  const filters = [];
  return { ...state, filters };
};

const fetchFilter = (state, order) => {
  const ordersTable = orderHelper.filterOrders(order.orders, order.filters);
  return { ...state, ordersTable };
};

const configSellColumnDef = (state, field) => {
  const mainArray = [];
  state.columnsDefs.forEach((main) => {
    const columnDef = [];
    main.children.forEach((def) => {
      const newDef = def;
      if (newDef.field === field) {
        newDef.hide = !(def.hide);
      }
      columnDef.push(newDef);
    });
    const newMain = { ...main, children: columnDef };
    mainArray.push(newMain);
  });
  storageHelper.setItem(CONFIG_SELL_COLUMN_DEF, mainArray, true);
  const newState = { columnsDefs: mainArray };
  return { ...state, ...newState };
};

const setColumnDefs = (state, defs) => {
  let newState;
  if (defs !== {}) {
    newState = { columnsDefs: defs };
  } else {
    const relativeState = defaultState();
    const { columnsDefs } = relativeState;
    newState = { columnsDefs };
  }
  return { ...state, ...newState };
};

const getConfigColumns = (state) => {
  const element = storageHelper.findItem('CONFIG_SELL_COLUMN_DEF');
  let newState;
  if (JSON.stringify(element) !== JSON.stringify({})) {
    newState = { columnsDefs: element };
    return { ...state, ...newState };
  }
  return state;
};

const resetColumnDefs = (state) => {
  const newState = defaultState();
  return { ...state, ...newState };
};

const defineColumnsOrder = (state, { config }) => {
  const { columnsDefs } = defaultState();
  const { pos_cols_ventas } = config;
  const columns = pos_cols_ventas.split(' |##| ');
  const newsColumnsDefs = columnsDefs.map((header) => {
    const { headerName, children } = header;
    const newChildren = children.map((a) => {
      a.hide = true;
      if (columns.some(column => column === a.headerName)) {
        a.hide = false;
      }
      return a;
    });
    const newHeader = {
      headerName,
      children: newChildren,
    };
    return newHeader;
  });
  return { ...state, columnsDefs: newsColumnsDefs };
};

const setFde = (state, fde) => ({
  ...state,
  fde: [...fde],
});

const setMda = (state, mda) => ({
  ...state,
  mda: [...mda],
});

const setFacturas = (state, facturas) => ({
  ...state,
  facturas: [...facturas],
});


const setFiles = (state, payload) => ({
  ...state,
  files: { ...payload },
});

const setPreselectOrder = (state, order) => {
  return {
    ...state,
    preselectOrder: order,
  }; };

const setOrderViewData = (state, action) => (
    {
      ...state,
      orderData: action.orderView,
    }
  );

export default (state = defaultState(), action) => {
  const { type } = action;
  switch (type) {
    case ADD_ORDERS:
      return addOrders(state, action.orders);
    case HANDLE_EDIT_ORDER:
      return { ...state, enableEditOrder: !state.enableEditOrder };
    case RESET_EDIT_ORDER:
      return { ...state, enableEditOrder: false };
    case GET_ORDERS_BY_PAGE:
      return getOrdersByPage(state, action.page);
    case GET_NEXT_PAGE_ORDER:
      return getNextOrder(state, action.page);
    case FILTER_ORDERS:
      return filterOrder(state, action.word);
    case SET_MULTIFILTER_ORDER:
      return setFilter(state, action.filters, action.item);
    case REMOVE_MULTIFILTER_ORDER:
      return removeFilter(state, action.filters, action.word);
    case CLEAR_MULTIFILTER_ORDER:
      return clearFilter(state);
    case FETCH_FILTER:
      return fetchFilter(state, action.order);
    case CONFIG_SELL_COLUMN_DEF:
      return configSellColumnDef(state, action.field);
    case SET_COLUMN_DEF:
      return setColumnDefs(state, action.defs);
    case GET_COLUMN_DEF_ORDER:
      return getConfigColumns(state);
    case RESET_COLUMN_DEF:
      return resetColumnDefs(state);
    case DEFINE_COLUMN_ORDER:
      return defineColumnsOrder(state, action);
    case GET_FILES:
      return setFiles(state, action.payload);
    case FETCH_FDE:
      return setFde(state, action.fde);
    case FETCH_MDA:
      return setMda(state, action.mda);
    case FETCH_FACTURAS:
      return setFacturas(state, action.facturas);
    case RESET_STATE:
      return defaultState();
    case SET_PRESELECT_ORDER:
      return setPreselectOrder(state, action.order);
    case IS_EDIT_ORDER:
      return { ...state, isEditOrder: action.isEditOrder };
    case COMBINED_PRODUCT_ID:
      return { ...state, combinedProductId: action.combinedProductId };
    case SET_ORDER_VIEW_DATA:
      return setOrderViewData(state, action);
    default:
      return state;
  }
};
