import React from 'react';
import PropTypes from 'prop-types';
import './Search.styles.scss';
import Proveedor from '../components/Proveedor';
import ExpenseType from '../components/ExpenseType';
import Almacen from '../components/Almacen';
import TaskType from '../components/TaskType';
/* import Client from '../components/Clients'; */
import Projects from '../components/Projects';
import Users from '../components/Users';

const Search = ({
  dispatchFetchListVendors,
  dispatchFetchListExpensesType,
  dispatchFetchListAlmacen,
  dispatchFetchListProjects,
  dispatchFetchListUser,
  loading,
  gasto,
  type,
}) => (
  <div>
    {type === 'proveedor' && (
      <Proveedor
        type={type}
        fetchData={dispatchFetchListVendors}
      />
    )}
    {type === 'almacen' && (
      <Almacen
        type={type}
        fetchData={dispatchFetchListAlmacen}
      />
    )}
    {type === 'gasto' && (
      <ExpenseType
        type={type}
        fetchData={dispatchFetchListExpensesType}
      />
    )}
    {type === 'task' && (
      <TaskType
        type={type}
      />
    )}
    {type === 'usuario' && (
      <Users
        type={type}
        fetchData={dispatchFetchListUser}
      />
    )}
    {type === 'proyecto' && (
      <Projects
        type={type}
        fetchData={dispatchFetchListProjects}
      />
    )}
  </div>
);

Search.propTypes = {
  dispatchFetchListVendors: PropTypes.func.isRequired,
  dispatchFetchListExpensesType: PropTypes.func.isRequired,
  dispatchFetchListAlmacen: PropTypes.func.isRequired,
  dispatchFetchListProjects: PropTypes.func.isRequired,
  dispatchFetchListUser: PropTypes.func.isRequired,
  gasto: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  type: PropTypes.string,
};

Search.defaultProps = {
  loading: false,
  type: 'proveedor',
};

export default Search;
