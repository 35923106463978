import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import InputNumber from '../../../../Input/Number';
import { toolsHelper } from '../../../../../helpers';

const RefundProduct = ({
  item,
}) => (
  <div className="refund_product">
    <div className="refund_product_stock">
      {item.qtyinstock + item.field}
      <span>Stock</span>
    </div>
    <div className="refund_product_detail">
      <div className="refund_product_title mb-auto">
        <Tooltip placement="left-start" title={item.productname || ''}>
          <span>{item.productname}</span>
        </Tooltip>
      </div>
      <div className="refund_product_desc">
        {`Por Devolver: ${item.toRefund}`}
      </div>
      <div className="refund_product_price">
        {`$ ${toolsHelper.formatMoney(item.price)}`}
      </div>
    </div>
    <div className="refund_product_qty">
      <InputNumber
        onlyNumber={!item.tab}
        number={item.field}
        disabledMinus={item.field === 0}
        disabledPlus={item.field >= item.limit}
        onClickMinus={() => {
          const { setFieldChange } = item;
          const value = item.field - 1;
          setFieldChange(item.crmid, value);
        }}
        onClickPlus={() => {
          const { setFieldChange } = item;
          const value = item.field + 1;
          setFieldChange(item.crmid, value);
        }}
        circleDiameter="48px"
        onChange={(value) => {
          const { setFieldChange } = item;
          setFieldChange(item.crmid, value);
        }}
        onDanger={item.field > item.limit}
      />
    </div>
  </div>
);

RefundProduct.propTypes = {
  item: PropTypes.object.isRequired,
};

export default RefundProduct;
