/* eslint-disable import/no-cycle */
/* eslint-disable radix */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fab from '@material-ui/core/Fab';
import Collapse from '@material-ui/core/Collapse';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ProductMobile from '../Product/mobile';
import MobileActionsBar from '../../views/Pos/components/PosMobileActionsBar';
import ModalOptions from '../Product/mobile/components/Options';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const MobileOrderPoducts = ({
  open,
  title,
  handleClose,
  isLoading,
}) => {
  const loads = useSelector(state => state.loads);
  const isEditOrder = useSelector(state => state.order.isEditOrder);
  const combinedProductId = useSelector(state => state.order.combinedProductId);
  const isLoadingProducts = Boolean(loads && loads.orderIsLoading);
  const showMessage = () => {
    if (isLoadingProducts && open) {
      toast.info('Agregando producto a la orden');
    }
  };
  React.useEffect(() => {
    showMessage();
  }, [isLoadingProducts]);
  return (
    <Dialog fullScreen open={open} onClose={() => handleClose()} TransitionComponent={Transition}>
      <AppBar style={{ position: 'relative' }}>
        <Toolbar classes={{ root: 'tollbar-header' }}>
          <Typography variant="h6" classes={{ root: 'text-white' }}>
            {title}
          </Typography>
          <IconButton edge="start" color="inherit" onClick={() => handleClose()} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <div style={{ 
        overflow: 'hidden',
        overflowY: 'auto',
      }}
      >
        <MobileActionsBar />
        <ProductMobile />
        {
            isEditOrder && combinedProductId !== null && (
              <ModalOptions />
            )
        }
      </div>
      <Collapse in={isLoading} style={{ right: '32px', position: 'fixed', bottom: '15px' }}>
        <Fab color="primary">
          <CircularProgress style={{ color: '#fff' }} />
        </Fab>
      </Collapse>
    </Dialog>
  ); };

MobileOrderPoducts.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default MobileOrderPoducts;
