/* eslint-disable import/no-cycle */
import React, { useState } from 'react';
import moment from 'moment';
import {
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Collapse,
} from '@material-ui/core';
import { useSelector } from 'react-redux';

import { useHistory } from 'react-router';
import ShareModal from '../../../../components/Share/components/ShareModal';
import appModules from '../../../../constant/appModules';
import MdaAPI from '../../../../api/MdaApi';
import { formatString } from '../../../../helpers/tools';
import WTransferProcessExpand from './WTransferProcessExpand';

const WTransferProccess = () => {
  const history = useHistory();
  const [purchase, setPurchase] = useState({});
  const [loading, setLoading] = useState(false);
  const [transfers, setTransfersReceive] = useState([]);

  const loads = useSelector(state => state.loads);
  const authUser = useSelector(state => state.authUser);
  const isLoading = Boolean(loads && loads.emailTemplateIsLoading);
  const [openShare, setOpenShare] = React.useState(false);

  const {
    user: {
      config: { store },
    },
  } = authUser;

  const handleShareClose = () => {
    setOpenShare(false);
  };

  const shareModal = async (item) => {
    await setPurchase(item);
    setOpenShare(true);
  };

  const selectLocalOrder = (sale) => {
    if (purchase && sale.referencia === purchase.referencia) {
      setPurchase(null);
    } else {
      setPurchase(sale);
    }
  };

  const transfersToProcess = async () => {
    setLoading(true);
    const mdaApi = new MdaAPI();
    const response = await mdaApi.transfersToProcess(store.crmid);
    const { success, result } = response;

    if (success) {
      setTransfersReceive(result);
    }

    setLoading(false);
  };

  const processTransfer = () => {
    history.push(`/almacenes/procesar/transferencia/${purchase.referencia}`);
  };

  React.useEffect(() => {
    transfersToProcess();
  }, [store]);

  return (
    <>
      {loading && (
        <Collapse in={loading}>
          <LinearProgress />
        </Collapse>
      )}

      <div>
        {transfers.length ? (
          <div style={{ paddingBottom: 66 }}>
            {transfers.map(sale => (
              <div
                key={sale.referencia}
                style={{ backgroundColor: '#fff', cursor: 'pointer' }}
              >
                <ListItem
                  divider
                  className="quote"
                  onClick={() => selectLocalOrder(sale)}
                >
                  <ListItemText
                    classes={{
                      primary: 'quote__title',
                      secondary: 'quote__subtitle',
                    }}
                    primary={sale.referencia}
                    secondary={(
                      <>
                        <span>{sale.assigned_user_label}</span>
                        <br />
                        <span>
                          {moment(sale.fecha).format('DD MMM YYYY h:mm a')}
                        </span>
                      </>
                    )}
                  />
                  <div className="d-flex flex-column">
                    <ListItemText
                      classes={{ primary: 'quote__money' }}
                      primary={formatString(sale.treebesalmid_label)}
                      style={{ textAlign: 'right', padding: 0 }}
                    />
                  </div>
                </ListItem>
                <Collapse
                  in={purchase && purchase.referencia === sale.referencia}
                  style={{ backgroundColor: '#fafafa', marginBottom: 10 }}
                >
                  <WTransferProcessExpand
                    purchase={sale}
                    openShare={shareModal}
                    processTransfer={() => processTransfer()}
                  />
                </Collapse>
              </div>
            ))}
          </div>
        ) : (
          <List dense>
            <ListItem classes={{ root: 'bg-white' }}>
              <ListItemText primary="No se encontraron transferencias" />
            </ListItem>
          </List>
        )}
      </div>
      <ShareModal
        open={openShare}
        title={`Compartir ${purchase ? purchase.entity_label : ''}`}
        handleClose={handleShareClose}
        order={purchase || {}}
        template={appModules.PurchaseOrder}
        isLoading={isLoading}
      />
    </>
  );
};

export default WTransferProccess;
