/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-use-before-define */
import React from 'react';
import './CountUp.styles.scss';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faStop } from '@fortawesome/free-solid-svg-icons';
import Fab from '@material-ui/core/Fab';
import Update from '@material-ui/icons/Update';
import { selectTasks, toggleTimer } from '../../redux/actions/taskActions';
import TaskApi from '../../api/TaskApi';

const CountUpTimer = ({ hoursMinSecs, global = false }) => {
  const history = useHistory();
  const taskApi = new TaskApi();
  let timerId = null;
  const storageTimer = localStorage.getItem('timeTask');
  const selectedStorageTask = JSON.parse(localStorage.getItem('selectedStorageTask'));
  // const selectedTaskNo = localStorage.getItem('selectedTaskNo');
  let { hours = 0, minutes = 0, seconds = 0 } = hoursMinSecs;

  if (storageTimer) {
    const timepart = storageTimer.split(':');
    if (timepart.length) {
      hours = parseInt(timepart[0], 10);
      minutes = parseInt(timepart[1], 10);
      seconds = parseInt(timepart[2], 10);
    }
  }
  const [[hrs, mins, secs], setTime] = React.useState([hours, minutes, seconds]);
  const [hide, setHide] = React.useState(true);


  const dispatch = useDispatch();
  const timerStart = useSelector(state => state.tasks.startTimer);
  const selectedTask = useSelector(state => state.tasks.selectedTask);
  // const timer = useSelector(state => state.tasks.setTimer);

  const tick = () => {
    setTime((prevTime) => {
      if (prevTime[0] === 59 && prevTime[1] === 59 && prevTime[2] === 59) {
        localStorage.setItem('timeTask', `${parseInt(hours, 10)} : ${parseInt(minutes, 10)} : ${parseInt(seconds, 10)}`);
        return [parseInt(hours, 10), parseInt(minutes, 10), parseInt(seconds, 10)];
      }

      if (prevTime[1] === 59 && prevTime[2] === 59) {
        localStorage.setItem('timeTask', `${(prevTime[0] + 1).toString().padStart(2, '0')} : ${parseInt(minutes, 10).toString().padStart(2, '0')} : ${parseInt(seconds, 10).toString().padStart(2, '0')}`);
        return [prevTime[0] + 1, 0, 0];
      }

      if (prevTime[2] === 59) {
        localStorage.setItem('timeTask', `${prevTime[0].toString().padStart(2, '0')} : ${(prevTime[1] + 1).toString().padStart(2, '0')} : ${'0'.toString().padStart(2, '0')}`);
        return [prevTime[0], prevTime[1] + 1, 0];
      }
      localStorage.setItem('timeTask', `${prevTime[0].toString().padStart(2, '0')} : ${(prevTime[1]).toString().padStart(2, '0')} : ${(prevTime[2] + 1).toString().padStart(2, '0')}`);
      return [prevTime[0], prevTime[1], prevTime[2] + 1];
    });
  };

  const makePlay = () => {
    dispatch(toggleTimer());
    taskApi.startTaskTimeTracking(selectedTask.crmid);
  };
  const makeStop = async () => {
    if (timerStart) {
      dispatch(toggleTimer());
    }
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i < 99999; i++) {
      clearInterval(i);
    }
    setTime([0, 0, 0]);
    localStorage.setItem('timeTask', '00:00:00');
    await taskApi.stopTaskTimeTracking();
    const selectedTaskId = localStorage.getItem('selectedTask');
    if (selectedTaskId) {
      const response = await taskApi.getObjectById(selectedTaskId);
      const { success, result } = response;
      if (success && result) {
        dispatch(selectTasks(result));
      }
      localStorage.removeItem('selectedTask');
    }
  };

  const gotoTask = () => {
    dispatch(selectTasks(selectedStorageTask));
    history.push(`/actividades/${selectedStorageTask.crmid}`);
  };

  React.useEffect(() => {
    if (timerStart) {
      timerId = setInterval(() => tick(), 1000);
    } else {
      makeStop();
    }
  }, [timerStart]);

  return (
    <div className={`timer-container  ${global ? 'global-style' : ''} ${(global && hide === true) ? 'hide-left' : ''}`}>
      <div className="d-flex align-items-center" style={{ gap: 10 }}>
        <p>
          <span
            onClick={() => gotoTask()}
            style={{ fontSize: 16, fontWeight: 600 }}
          >
            {`${selectedStorageTask.projecttask_no}: `}
          </span>
          {`
            ${hrs.toString().padStart(2, '0')}:
            ${mins.toString().padStart(2, '0')}:
            ${secs.toString().padStart(2, '0')}
          `}
        </p>
        {!timerStart && (
        <FontAwesomeIcon onClick={() => makePlay()} style={{ fontSize: 20, cursor: 'pointer' }} icon={faPlay} />
        )
            }
        {
              timerStart && (
                <FontAwesomeIcon onClick={() => makeStop()} style={{ fontSize: 20 }} icon={faStop} />
              )
            }
      </div>

      {
        global && (
          <div className="loader-container">
            <Fab
              aria-label="save"
              color="primary"
              className="fab-btn"
              onClick={() => setHide(!hide)}
            >
              <Update className="rotating" />
            </Fab>
          </div>
        )
      }

    </div>
  );
};

export default CountUpTimer;
