/* eslint-disable no-throw-literal */
import { toast } from 'react-toastify';
import TableAPI from '../../api/TableAPI';
import { loading, loaded } from './loadActions';

export const ADD_ITEMS_TO_TABLE = 'ADD_ITEMS_TO_TABLE';
export const SELECT_EMPTY_TO_TABLE = 'SELECT_EMPTY_TO_TABLE';
export const SELECT_TO_DELIVERED = 'SELECT_TO_DELIVERED';
export const SELECT_TO_REFUNDED = 'SELECT_TO_REFUNDED';
export const CHANGE_FIELD = 'CHANGE_FIELD';
export const CLEAR_TABLE = 'CLEAR_TABLE';
export const CHANGE_COMMENT = 'CHANGE_COMMENT';
export const RESET_TO_ORIGIN = 'RESET_TO_ORIGIN';

export function clearTable() {
  return {
    type: CLEAR_TABLE,
  };
}

export function addItemsToTable(typeAction, history = {}) {
  const tableApi = new TableAPI();
  return async (dispatch, getState) => {
    try {
      await new Promise(resolve => setTimeout(resolve));
      await dispatch(clearTable());
      await dispatch(loading('table'));
      const { discount, authUser: { user: { config: { store, id } } } } = getState();
      const { cart } = getState();
      const { orderSelected } = cart;
      const response = await tableApi.getOrderDetails(orderSelected.crmid, store.crmid, typeAction);
      if (response.success) {
        const { cartProductsByArray, balance, total } = cart;
        await dispatch({
          type: ADD_ITEMS_TO_TABLE,
          cart,
          typeAction,
          response,
          store,
          cartProductsByArray,
          discount,
          balance,
          total,
          id,
        });
      } else {
        if (Object.keys(history).length > 0) history.goBack();
        if (Object.keys(response).length > 0) {
          const { message } = response;
          toast.warn(message.warning || message.warning);
        } else {
          throw `Mensaje del sistema: ${response}`;
        }
      }
    } catch (err) {
      toast.error(`No se pudo obtener datos para realizar el movimiento de almacén: ${err}`);
    } finally {
      dispatch(loaded('table'));
    }
  };
}


export function selectEmptyToTable() {
  return (dispatch, getState) => {
    const { table } = getState();
    dispatch({
      type: SELECT_EMPTY_TO_TABLE,
      table,
    });
  };
}

export function selectToDelivered() {
  return (dispatch, getState) => {
    const { table } = getState();
    dispatch({
      type: SELECT_TO_DELIVERED,
      table,
    });
  };
}

export function changeFieldValue(obj) {
  return (dispatch, getState) => {
    const { table } = getState();
    dispatch({
      type: CHANGE_FIELD,
      obj,
      table,
    });
  };
}

export function selectToRefunded() {
  return (dispatch, getState) => {
    const { table } = getState();
    dispatch({
      type: SELECT_TO_REFUNDED,
      table,
    });
  };
}

export function chnageComment(word) {
  return async (dispatch) => {
    try {
      await new Promise(resolve => setTimeout(resolve));
      dispatch({
        type: CHANGE_COMMENT,
        word,
      });
    } catch {
      /* Continue regardless of error */
    }
  };
}

export function resetToOrigin() {
  return async (dispatch) => {
    try {
      await new Promise(resolve => setTimeout(resolve));
      dispatch({ type: RESET_TO_ORIGIN });
    } catch {
      /* Continue regardless of error */
    }
  };
}
