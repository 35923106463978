/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import DocumentoItem from './documento-item';

const DocumentItems = ({ movimientos, click }) => {
  console.log('DocumentItems', movimientos);
  return (
    <div className="balance__contenedor-hijo balance__lista flex-column">
      {movimientos.map((el) => {
        const {
          cliente,
          enable,
          id,
          fecha_realizado,
          venta,
          type,
          tipo,
          liga,
        } = el;
        return (
          <>
            {
              el.label?.includes('Puntos Recompensa') ? null
                : (
                  <DocumentoItem
                    client={cliente}
                    click={click}
                    fechaRealizado={fecha_realizado}
                    enable={enable}
                    id={id}
                    key={id}
                    venta={venta}
                    type={type}
                    tipo={tipo}
                    liga={liga}
                  />
                )
            }
          </>
        );
      })}
    </div>
  ); };

DocumentItems.propTypes = {
  click: PropTypes.func.isRequired,
  movimientos: PropTypes.array,
};

DocumentItems.defaultProps = {
  movimientos: [],
};

export default DocumentItems;
