import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, withRouter } from 'react-router-dom';
import { clearCart } from '../../../../../../redux/actions/cartActions';

const PosBottomNavegation = ({ location }) => {
  const dispatch = useDispatch();
  const resetCart = () => {
    dispatch(clearCart());
  };

  return (
    <BottomNavigation showLabels className="fixed-bottom posMobile_tabs ">
      <BottomNavigationAction
        id="navLinkCaja"
        className={location.pathname.includes('/pos/caja') ? 'active' : ''}
        component={Link}
        to="/pos/caja"
        label="Caja"
        icon={<FontAwesomeIcon icon="plus" size="lg" />}
      />
      <BottomNavigationAction
        id="navLinkVenta"
        className={location.pathname.includes('/pos/venta') ? 'active' : ''}
        component={Link}
        to="/pos/venta"
        label="Ventas"
        icon={<FontAwesomeIcon icon="clipboard-list" size="lg" />}
        onClick={() => resetCart()}
      />
      <BottomNavigationAction
        id="navLinkCotizacion"
        className={location.pathname.includes('/pos/cotizacion') ? 'active' : ''}
        component={Link}
        to="/pos/cotizacion"
        label="Cotizaciones"
        icon={<FontAwesomeIcon icon="file-invoice-dollar" size="lg" />}
        onClick={() => resetCart()}
      />
    </BottomNavigation>
  ); };

PosBottomNavegation.propTypes = {
  location: PropTypes.object,
};

PosBottomNavegation.defaultProps = {
  location: {},
};

export default withRouter(PosBottomNavegation);
