/* eslint-disable no-case-declarations */
import {
  ADD_SALES_ORDER,
  ADD_SALES_ORDER_SUMMARY,
  ADD_LIST_INVOICE,
  ADD_TRANSFERS,
  ADD_CREDIT_NOTES,
  ADD_CASH_FLOW,
  ADD_SALES_ORDER_PAGINATOR,
  ADD_SALES_ORDER_SUMMARY_PAGINATOR,
  ADD_LIST_INVOICE_PAGINATOR,
  ADD_TRANSFERS_PAGINATOR,
  ADD_CREDIT_NOTES_PAGINATOR,
  ADD_CASH_FLOW_PAGINATOR,
  ADD_QUOTES_PAGINATOR,
  ADD_QUOTES,
  ADD_MDA,
  ADD_MDA_PAGINATOR,
} from '../types/financeActions.type';

const INITIAL_STATE = {
  quotes: { data: [], pages: 0 },
  salesOrders: { data: [], pages: 0 },
  salesOrdersSummary: { data: [], pages: 0 },
  invoices: { data: [], pages: 0 },
  transfers: { data: [], pages: 0 },
  mdas: { data: [], pages: 0 },
  creditNotes: { data: [], pages: 0 },
  cashFlow: { data: [], pages: 0 },

};

const financeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_QUOTES:
      return {
        ...state,
        quotes: { data: [...action.payload.result], pages: action.payload.pages },
      };
    case ADD_QUOTES_PAGINATOR:
      return {
        ...state,
        quotes: { data: [...state.quotes.data, ...action.payload.result], pages: action.payload.pages },
      };
    case ADD_SALES_ORDER:
      return {
        ...state,
        salesOrders: { data: [...action.payload.result], pages: action.payload.pages },
      };
    case ADD_SALES_ORDER_PAGINATOR:
      return {
        ...state,
        salesOrders: { data: [...state.salesOrders.data, ...action.payload.result], pages: action.payload.pages },
      };
    case ADD_SALES_ORDER_SUMMARY:
      return {
        ...state,
        salesOrdersSummary: { data: [...action.payload.result], pages: action.payload.pages },
      };
    case ADD_SALES_ORDER_SUMMARY_PAGINATOR:
      return {
        ...state,
        salesOrdersSummary: { data: [...state.salesOrdersSummary.data, ...action.payload.result], pages: action.payload.pages },
      };
    case ADD_LIST_INVOICE:
      return {
        ...state,
        invoices: { data: [...action.payload.result], pages: action.payload.pages },
      };
    case ADD_LIST_INVOICE_PAGINATOR:
      return {
        ...state,
        invoices: { data: [...state.invoices.data, ...action.payload.result], pages: action.payload.pages },
      };
    case ADD_TRANSFERS:
      return {
        ...state,
        transfers: { data: [...action.payload.result], pages: action.payload.pages },
      };
    case ADD_TRANSFERS_PAGINATOR:
      return {
        ...state,
        transfers: { data: [...state.transfers.data, ...action.payload.result], pages: action.payload.pages },
      };
    case ADD_MDA:
      return {
        ...state,
        mdas: { data: [...action.payload.result], pages: action.payload.pages },
      };
    case ADD_MDA_PAGINATOR:
      let mda = [];
      if (action.payload.count > state.mdas.data.length) {
        mda = [...state.mdas.data, ...action.payload.result];
      } else {
        mda = [...state.mdas.data];
      }
      return {
        ...state,
        mdas: { data: [...mda], pages: action.payload.pages },
      };
    case ADD_CREDIT_NOTES:
      return {
        ...state,
        creditNotes: { data: [...action.payload.result], pages: action.payload.pages },
      };
    case ADD_CREDIT_NOTES_PAGINATOR:
      return {
        ...state,
        creditNotes: { data: [...state.creditNotes.data, ...action.payload.result], pages: action.payload.pages },
      };
    case ADD_CASH_FLOW:
      return {
        ...state,
        cashFlow: { data: [...action.payload.result], pages: action.payload.pages },
      };
    case ADD_CASH_FLOW_PAGINATOR:
      return {
        ...state,
        cashFlow: { data: [...state.cashFlow.data, ...action.payload.result], pages: action.payload.pages },
      };

    default:
      return state;
  }
};
export default financeReducer;
