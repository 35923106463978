import fetch from 'cross-fetch';
import ConfigurationAPI from './ConfigurationAPI';

class BalanceAPI extends ConfigurationAPI {
  constructor() {
    super();
    delete this.credentials.headers.pricebook;
    this.credentials.method = 'POST';
  }

  getBalance = async () => {
    try {
      const uri = `${this.url}/TreebesFdE/TotalesCC`;
      const repsonse = await fetch(uri, this.credentials);
      const json = await repsonse.json();
      return { ...json };
    } catch {
      return {
        success: false,
        result: [],
        message: 'Error al obtener Balance',
      };
    }
  };

  getCC = async (rango = '') => {
    /*
      tfde_rango_fechas 0000-00-00 to 0000-00-00 { format }
    */
    try {
      let uri = `${this.url}/TreebesFdE/SolicitaCC?desdeApp=Simonalamona`;
      uri = rango ? `${uri}&tfde_rango_fechas=${rango}` : uri;
      const repsonse = await fetch(uri, this.credentials);
      const json = await repsonse.json();
      return { ...json };
    }
    catch {
      return {
        success: false,
        result: [],
        message: 'Error al obtener Información del Corte de Caja',
      };
    }
  };

  getACC = async () => {
    try {
      const uri = `${this.url}/TreebesFdE/AutorizaCC`;
      const repsonse = await fetch(uri, this.credentials);
      const json = await repsonse.json();
      return { ...json };
    } catch {
      return {
        success: false,
        result: [],
        message: 'Error al obtener Información de Autorizar Corte de Caja',
      };
    }
  }

  getMCC = async () => {
    try {
      const uri = `${this.url}/TreebesFdE/ModificaCC`;
      const repsonse = await fetch(uri, this.credentials);
      const json = await repsonse.json();
      return { ...json };
    } catch {
      return {
        success: false,
        result: [],
        message: 'Error al obtener Información para Modificar Corte de Caja',
      };
    }
  }

  saveCC = async (data) => {
    const {
      selectedCheckboxes, unselectedCheckboxes, startDate, endDate, ...cash
    } = data;
    const url = `${this.url}/TreebesFdE/GuardaSolicitudCC`;
    this.credentials.method = 'POST';
    this.credentials.body = JSON.stringify({
      funcion: 'guarda_solicitudcc',
      desdeApp: 'Simonalamona',
      ids_solcc: JSON.stringify(selectedCheckboxes),
      ids_quicc: JSON.stringify(unselectedCheckboxes),
      ...cash,
    });
    try {
      const repsonse = await fetch(url, this.credentials);
      const json = await repsonse.json();
      return { ...json };
    }

    catch {
      return {
        success: false,
        result: [],
        message: 'Error al obtener Información de Autorizar Corte de Caja',
      };
    }
  }

  saveACC = async (idsSolcc) => {
    const url = `${this.url}/TreebesFdE/GuardaAutorizaCC`;

    this.credentials.method = 'POST';
    this.credentials.body = JSON.stringify({
      funcion: 'guarda_autorizacc',
      desdeApp: 'Simonalamona',
      ids_autcc: JSON.stringify(idsSolcc),
    });

    try {
      const repsonse = await fetch(url, this.credentials);
      const json = await repsonse.json();
      return { ...json };
    }

    catch {
      return {
        success: false,
        result: [],
        message: 'Error al obtener Información de Autorizar Corte de Caja',
      };
    }
  }
}

export default BalanceAPI;
