import React from 'react';
import PropTypes from 'prop-types';
import './ProgresBar.styles.scss';
import { formatMoney } from '../../../../helpers/tools';

const ProgresBar = ({ pay, total, money }) => {
  const amount = parseFloat(pay);
  const totalAmount = parseFloat(total);
  const part = amount/*  > totalAmount ? totalAmount : amount */;
  const percent = (part < totalAmount ? (part * 100 / totalAmount) : 100);
  const amountLeft = part < totalAmount ? totalAmount - part : 0;
  return (
    // eslint-disable-next-line quotes
    <div className={` "progresBar" ${amountLeft === 0 || amount === 0 ? 'progresBarDone' : ''}`}>
      <div className="progresBar">
        {/* <div className={`${percent < 10 ? 'progresBarTransparent' : null} filter`} style={{ width: 'max-content' }}> */}
        <div className={`${amount !== 0 ? 'filter' : ''} ${(totalAmount === 0 && part !== 0) ? 'danger' : '' }`} style={{ minWidth: 'max-content', width: `${(totalAmount === 0 && part !== 0) ? 100 : (amount === 0 ? amount : percent)}%` }}>
          <span className={`${amount !== 0 ? 'amount' : 'amount__dark'}`}>
            { money ? `$${formatMoney(Math.abs(part))}` : `${totalAmount === 0 ? 0 : percent}%` }
          </span>
        </div>
      </div>
    </div>
  );
};
ProgresBar.defaultProps = {
  money: false,
  pay: 0,
};

ProgresBar.propTypes = {
  pay: PropTypes.number,
  total: PropTypes.number.isRequired,
  money: PropTypes.bool,
};
export default ProgresBar;
