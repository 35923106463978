/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import { Search } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { fetchListVendors, fetchListExpensesType } from '../../../../redux/actions/expensesActions';
import {
  fetchAllProject, fetchAllUsers, setTaskSearchCriteria,
} from '../../../../redux/actions/taskActions';

let timeout = null;

const SearchBtn = ({ type }) => {
  const dispatch = useDispatch();
  const tasks = useSelector(state => state.tasks);
  const { taskSearchCriteria } = tasks;
  const inputRef = React.createRef();
  const [toggle, setToggle] = React.useState(false);
  const [search, setSearch] = React.useState(type === 'task' ? taskSearchCriteria : '');

  const slickSearchIcon = () => {
    if (!toggle) inputRef.current.focus();
    setToggle(!toggle);
  };

  const handleChangeSearch = (event) => {
    setSearch(event.target.value);
  };

  const searchClient = () => {
    switch (type) {
      case 'proveedor':
        dispatch(fetchListVendors(1, search));
        break;
      case 'gasto':
        dispatch(fetchListExpensesType(1, search));
        break;
      case 'task':
        dispatch(setTaskSearchCriteria(search));
        break;
      case 'usuario':
        dispatch(fetchAllUsers(1, search));
        break;
      case 'proyecto':
        dispatch(fetchAllProject(0, 1, search));
        break;
      default:
        break;
    }
  };

  const classAppear = 'searchFab__circle d-flex align-items-center justify-content-start';

  return (
    <div className="searchFab d-flex align-items-center justify-content-between w-100 h-100">

      <div className={toggle ? `${classAppear} extended` : classAppear}>
        <IconButton onClick={() => { slickSearchIcon(); }}>
          <Search id="actions_search_tag" />
        </IconButton>
        <input
          id="mobile_input_search"
          autoComplete="false"
          ref={inputRef}
          type="text"
          value={search}
          placeholder="Buscar"
          onFocus={() => { setToggle(true); }}
          onChange={(event) => { handleChangeSearch(event); }}
          onBlur={() => { slickSearchIcon(); }}
          onKeyUp={(e) => {
            clearTimeout(timeout);
            if (e.keyCode !== 13) {
              timeout = setTimeout(() => {
                searchClient();
              }, 750);
            } else {
              searchClient();
            }
          }}
        />
      </div>
    </div>
  );
};

SearchBtn.propTypes = {
  type: PropTypes.string.isRequired,
};

export default SearchBtn;
