/* eslint-disable import/no-cycle */
/* eslint-disable no-shadow */
/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import {
  Button, LinearProgress, Paper, Table, TableBody, TableCell, TableHead, TableRow,
} from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import Select from 'react-select';
import ExpensesAPI from '../../../api/ExpensesApi';
import ProductAPI from '../../../api/ProductAPI';
import TableAPI from '../../../api/TableAPI';
import { fetchOrderView, selectOrder } from '../../../redux/actions/orderActions';
import { addItemsToTable } from '../../../redux/actions/tableActions';
import FullDialog from '../../FullDialog';
import CancelDeliver from '../external-delivery/CancelDeliver/CancelDeliver';

const ScheduleExternalDeliver = () => {
  const history = useHistory();
  const params = useParams();
  const authUser = useSelector(state => state.authUser);

  const {
    user: {
      config: { store, id },
    },
  } = authUser;

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [refreshCancel, setRefreshCancel] = useState(false);
  const [comment, setComment] = useState('');
  const [isLoadingStores, setIsLoadingStores] = useState(false);
  const orderSelected = useSelector(state => state.cart?.orderSelected);
  const [almacenes, setAlmacenes] = useState([]);
  const [stock, setStock] = useState([]);
  const [stockSelected, setStockSelected] = useState(null);
  const [products, setProducts] = useState([]);
  const [date, setDate] = useState(moment(new Date()).format('YYYY-MM-DD'));


  const orderListSelected = useSelector(state => state.order?.orderListSelected);
  const loads = useSelector(state => state.loads);
  const table = useSelector(state => state.table);
  const cart = useSelector(state => state.cart);

  const move = 'SCP';
  const actual = new Date();
  const seconds = `0${actual.getSeconds()}`.slice(-2);
  const minutes = `0${actual.getMinutes()}`.slice(-2);
  const hours = `0${actual.getHours()}`.slice(-2);
  const day = `0${actual.getDate()}`.slice(-2);
  const month = `0${actual.getMonth() + 1}`.slice(-2);
  const year = actual.getFullYear();
  const ref = `${move}-${year}${month}${day}${hours}${minutes}${seconds}-${id || '-1'}`;

  const getStores = async () => {
    setIsLoadingStores(true);
    const { almid } = orderSelected;
    const storeApi = new ExpensesAPI();
    const response = await storeApi.getAlmacen();

    const { success, result } = response;
    if (success) {
      const publicStore = result.filter(store => store.public_inventory === '1' && store.crmid !== almid);
      setAlmacenes(publicStore);
    }
    setIsLoadingStores(false);
  };

  const selectStore = async (storeValue) => {
    if (storeValue) {
      setIsLoadingStores(true);
      const storeSelected = almacenes.find(s => s.crmid === storeValue.value);
      setStockSelected(storeSelected);
      const { crmid } = orderSelected;
      const productApi = new ProductAPI();
      const response = await productApi.getOrderDetail(crmid, crmid, storeSelected.crmid, 'SCP');

      if (response.stock) {
        await setStock(response.stock);
      }

      if (response.PRODUCTOS) {
        const prods = [];
        response.PRODUCTOS.map((item) => {
          const stockSelect = response.stock.find(s => `${s.productid}` === item.id);
          prods.push({
            ...item,
            a_entregar: parseFloat(item['por entregar']) < stockSelect.qtyinstock ? parseFloat(item['por entregar']) : 0,
            en_existencia: stockSelect.qtyinstock || 0,
          });
        });
        setProducts(prods);
      }
      setIsLoadingStores(false);
    } else {
      setProducts([]);
    }
  };

  const selectAll = (value) => {
    const editedProducts = [...products];
    // debugger;
    if (value) {
      editedProducts.map((item) => {
        item.a_entregar = parseFloat(item['por entregar']) < parseFloat(item.en_existencia) ? parseFloat(item['por entregar']) : 0;
      });
      setProducts(editedProducts);

      return;
    }

    editedProducts.map((item) => {
      item.a_entregar = 0;
    });
    setProducts(editedProducts);
  };

  const changeInput = (value, prod) => {
    const editedProducts = [...products];
    const prodIndex = editedProducts.findIndex(item => item.id === prod.id);
    prod.a_entregar = value;

    editedProducts.splice(prodIndex, 1, prod);
    setProducts(editedProducts);
  };

  const onChangeDate = (e) => {
    const newDate = moment(new Date(e.target.value)).format('YYYY-MM-DD');
    setDate(newDate);
  };

  const addExternalDelivery = async () => {
    setIsLoadingStores(true);
    if (!products.length || !Object.keys(stockSelected)) {
      toast.error('Debe seleccionar un almacén o tener productos para entregar');
      setIsLoadingStores(false);

      return;
    }
    const { crmid, account_id } = orderSelected;
    const tableApi = new TableAPI();
    const response = await tableApi.setEntregaExterna(products, ref, stockSelected.crmid, crmid, account_id, date, comment);

    if (response.success) {
      toast.success('La entrega se programo correctamente');
    } else {
      toast.error('Error programando la entrega');
    }

    setIsLoadingStores(true);
  };

  const changeQty = (e, item) => {
    if (parseFloat(e.target.value) > item.en_existencia) {
      toast.error('No hay suficientes en almacen ');

      return;
    }
    if (parseFloat(e.target.value) > item['por entregar']) {
      toast.error('Haz llegado al total de productos ');

      return;
    }
    changeInput(e.target.value, item);
  };

  const saveDeliver = async () => {
    setRefreshCancel(true);
    await addExternalDelivery();
    if (orderListSelected?.cvid) {
      dispatch(fetchOrderView(orderListSelected.cvid));
    }
    dispatch(selectOrder(orderSelected.crmid));
    setProducts([]);
    setOpen(false);
    setRefreshCancel(false);
  };


  useEffect(() => {
    if (params.orderId && store.crmid) {
      dispatch(selectOrder(params.orderId));
    }
  }, []);

  useEffect(() => {
    getStores();
  }, [orderSelected]);

  return (
    <FullDialog
      title="Programar entrega externa"
      isOpen
      isLoading={Boolean(loads.orderIsLoading)}
      onClose={() => history.goBack()}
      onSave={() => saveDeliver()}
    >

      <div style={{ maxWidth: 1200, width: '90%' }}>
        {
      isLoadingStores && <LinearProgress />
    }
        <div className="form-section">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <div className="d-flex flex-column">
              <span className="deliver-input-title">Referencia: </span>
              <input disabled type="text" className="deliver-input" value={ref} />

            </div>
            <div className="d-flex flex-column">
              <span className="deliver-input-title">Fecha: </span>
              <input disabled type="date" className="deliver-input" value={date} onChange={e => onChangeDate(e)} />
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center  mb-2">
            <div className="d-flex flex-column">
              <span className="deliver-input-title">Almacén origen: </span>
              <Select
                className="basic-single user_search"
                classNamePrefix="select"
                isClearable
                isSearchable
                name="usuarios"
                options={almacenes.map(item => ({ value: item.crmid, label: item.almacen }))}
                onChange={selectStore}
              />
            </div>
            <div className="d-flex flex-column">
              <span className="deliver-input-title">Venta destino: </span>
              <input disabled type="text" className="deliver-input" value={cart?.orderSelected?.order?.salesorder_no} />
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center  mb-2">
            <div className="d-flex flex-column">
              <span className="deliver-input-title">Cliente: </span>
              <input disabled type="text" className="deliver-input" value={cart?.orderSelected?.order?.accountname} />
            </div>
            <div>
              <Button variant="contained" className="mr-2" size="small" type="button" onClick={() => selectAll(true)}>Seleccionar todo</Button>
              <Button variant="contained" size="small" type="button" onClick={() => selectAll(false)}>Seleccionar nada</Button>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center  mb-2">
            <div className="d-flex flex-column w-100">
              <span className="deliver-input-title">Comentario: </span>
              <textarea className="deliver-input" value={comment} onChange={e => setComment(e.target.value)} rows="5" />
            </div>
          </div>
        </div>
        <Paper className="mt-5" style={{ overflowX: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="deliver-table-cell"> Codigo </TableCell>
                <TableCell className="deliver-table-cell" align="right">Producto</TableCell>
                <TableCell className="deliver-table-cell" align="right">Cantidad</TableCell>
                <TableCell className="deliver-table-cell" align="right">Entregados</TableCell>
                <TableCell className="deliver-table-cell" align="right">Devueltos</TableCell>
                <TableCell className="deliver-table-cell" align="right">Por Entregar</TableCell>
                <TableCell className="deliver-table-cell" align="right">Existencia</TableCell>
                <TableCell className="deliver-table-cell" align="right">Entregar</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
              products.length > 0 && products.map(item => (
                <TableRow key={item.id}>
                  <TableCell className="deliver-table-cell">
                    {item.codigo}
                  </TableCell>
                  <TableCell className="deliver-table-cell" component="th" scope="row">
                    {item.producto}
                  </TableCell>
                  <TableCell className="deliver-table-cell">
                    {parseFloat(item.cantidad)}
                  </TableCell>
                  <TableCell className="deliver-table-cell">
                    {parseFloat(item.entregados)}
                  </TableCell>
                  <TableCell className="deliver-table-cell">
                    {parseFloat(item.devueltos)}
                  </TableCell>
                  <TableCell className="deliver-table-cell">
                    {parseFloat(item['por entregar'])}
                  </TableCell>
                  <TableCell className="deliver-table-cell">
                    {item.en_existencia}
                  </TableCell>
                  <TableCell className="deliver-table-cell">
                    <input
                      style={{
                        border: '1px solid #cccccc',
                        padding: 8,
                        borderRadius: 5,
                      }}
                      type="number"
                      value={item.a_entregar}
                      max={parseFloat(item['por entregar'])}
                      min={0}
                      onChange={e => changeQty(e, item)}
                    />
                  </TableCell>
                </TableRow>
              ))
            }
            </TableBody>
          </Table>
        </Paper>
        {
              !refreshCancel ? <CancelDeliver /> : <div className="mt-2"><LinearProgress /></div>
            }
      </div>
    </FullDialog>
  );
};

export default ScheduleExternalDeliver;
