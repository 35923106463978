import { SET_SEARCH, SET_ORDER, SET_DISCOUNT_TAXES, SET_FILTER_VALUE } from '../types/filterActions.type';

export function changeSearchFilter(searchValue) {
  return {
    type: SET_SEARCH,
    payload: searchValue,
  };
}

export function changeOrderFilter(orderValue) {
  return {
    type: SET_ORDER,
    payload: orderValue,
  };
}

export function changeFilter(filterValue) {
  return {
    type: SET_FILTER_VALUE,
    payload: filterValue,
  };
}


export function toggleDiscountTaxes() {
  return {
    type: SET_DISCOUNT_TAXES,
  };
}

export const setSearchFilter = searchValue => async (dispatch) => {
  dispatch(changeSearchFilter(searchValue));
};

export const setOrderFilter = orderValue => async (dispatch) => {
  dispatch(changeOrderFilter(orderValue));
};

export const setFilterValue = filterValue => async (dispatch) => {
  dispatch(changeFilter(filterValue));
};

export const setDiscountTaxes = () => async (dispatch) => {
  dispatch(toggleDiscountTaxes());
};
