/* eslint-disable import/no-cycle */
import { toast } from 'react-toastify';
import CustomerAPI from '../../api/CustomerAPI';
import AddressAPI from '../../api/AddressAPI';
import { loading, loaded } from './loadActions';
import { closeModal } from './modalActions';
import { addPricebook } from './priceBookActions';
import { fetchAllProducts } from './productActions';
import { updateProductsInCartWhenChangePriceBook } from './cartActions';

export const ADD_CUSTOMERS = 'ADD_CUSTOMERS';
export const CREATE_CUSTOMER = 'CREATE_CUSTOMER';
export const EDIT_CUSTOMER = 'EDIT_CUSTOMER';
export const FETCH_ALL_CUSTOMERS = 'FETCH_ALL_CUSTOMERS';
export const FETCH_SEARCH_CUSTOMERS = 'FETCH_SEARCH_CUSTOMERS';
export const FILTER_CUSTOMERS = 'FILTER_CUSTOMERS';
export const GET_ADDRESS = 'GET_ADDRESS';
export const REMOVE_CUSTOMER = 'REMOVE_CUSTOMER';
export const SELECT_CUSTOMER = 'SELECT_CUSTOMER';
export const SET_DEFAULT_CUSTOMER = 'SET_DEFAULT_CUSTOMER';
export const SET_SCHEMA = 'SET_SCHEMA';
export const SET_LAST_ORDER_CUSTOMER_ID = 'SET_LAST_ORDER_CUSTOMER_ID';

export function setLastOrderCustomerId(customerId) {
  return {
    type: SET_LAST_ORDER_CUSTOMER_ID,
    customerId,
  };
}

export function addCustomers(customers) {
  return {
    type: ADD_CUSTOMERS,
    customers,
  };
}

export function setDefaultCustomer(customer) {
  return {
    type: SET_DEFAULT_CUSTOMER,
    customer,
  };
}

export function selectCustomer(customer, fetchProducts = true) {
  return async (dispatch) => {
    if (customer.pricebookid !== null) {
      await dispatch(addPricebook({
        crmid: customer.pricebookid,
        entity_label: customer.pricebookid_label,
        isCustomer: true,
      }));
    } else {
      await dispatch(addPricebook({}));
    }
    await dispatch({
      type: SELECT_CUSTOMER,
      customer,
    });
    await dispatch(closeModal('customerSelect'));
    if (fetchProducts) await dispatch(fetchAllProducts());
  };
}

export function removeCustomer() {
  return {
    type: REMOVE_CUSTOMER,
  };
}

export function fetchAccountById(id) {
  console.log({ id });
  const customerApi = new CustomerAPI();
  return async (dispatch) => {
    dispatch(loading('selectCustomer'));
    try {
      const response = await customerApi.getByAccointId(id);
      const { success, result } = response;
      if (success) {
        dispatch(selectCustomer(result));
      }
      else {
        dispatch(addCustomers([]));
      }
    } catch (err) {
      toast.info(`Al momento de buscar al cliente surgió este error ${err}`);
    } finally {
      dispatch(loaded('selectCustomer'));
    }
  };
}

export function fetchAllCustomers() {
  const customerApi = new CustomerAPI();
  return async (dispatch) => {
    dispatch(loading('selectCustomer'));
    dispatch({
      type: FETCH_ALL_CUSTOMERS,
    });
    try {
      const response = await customerApi.getAll();
      const { success, result } = response;
      if (success) {
        dispatch(addCustomers(result));
      }

      else {
        dispatch(addCustomers([]));
      }
    }

    catch {
      dispatch(addCustomers([]));
    }

    finally {
      dispatch(loaded('selectCustomer'));
    }
  };
}

export function fetchSearchCustomers(name = '') {
  const customerApi = new CustomerAPI();
  return async (dispatch) => {
    dispatch(loading('selectCustomer'));
    dispatch({
      type: FETCH_SEARCH_CUSTOMERS,
      name,
    });
    try {
      const response = await customerApi.getAccountByName(name);
      const { success, result } = response;
      if (success) {
        dispatch(addCustomers(result));
      }

      else {
        dispatch(addCustomers([]));
      }
    }

    catch {
      dispatch(addCustomers([]));
    }

    finally {
      dispatch(loaded('selectCustomer'));
    }
  };
}

export function fetchAddressByCustomer(id) {
  const addressApi = new AddressAPI();
  return async (dispatch) => {
    await dispatch(loading('address'));
    try {
      const response = await addressApi.get(id);
      const { success, result } = response;
      if (success) {
        await dispatch({
          type: GET_ADDRESS,
          result,
        });
      } else {
        toast.warning(response.message.warning ? response.message.warning : response.message.error);
      }
    } catch (getAddressErr) {
      console.log({ getAddressErr });
    } finally {
      await dispatch(loaded('address'));
    }
  };
}

export function editCustomer(customer) {
  const customerApi = new CustomerAPI();
  return async (dispatch) => {
    dispatch(loading('editCustomer'));
    dispatch({
      type: EDIT_CUSTOMER,
    });

    try {
      const response = await customerApi.edit(customer);
      const { success, result, message } = response;
      if (success) {
        toast.success(` ✔ Tu cliente ${customer.accountname} ha sido editado`);
        await dispatch(closeModal('customerEdit'));
        await dispatch(fetchAllCustomers());
        await dispatch(selectCustomer(result));
      }
      else {
        toast.error(` ❌${message.error} `);
      }
    }

    catch {
      toast.error(' ❌ Error en la API');
    }

    finally {
      dispatch(loaded('editCustomer'));
    }
  };
}

export function editEnvioFactura(customer) {
  const customerApi = new CustomerAPI();
  return async (dispatch) => {
    dispatch(loading('editCustomer'));
    dispatch({
      type: EDIT_CUSTOMER,
    });

    try {
      const response = await customerApi.edit(customer);
      const { success, result, message } = response;
      if (success) {
        toast.success('Direcciones de facturación y de envio ');
        await dispatch(selectCustomer(result));
      }
      else {
        toast.error(` ❌${message.error} `);
      }
    }

    catch {
      toast.error(' ❌ Error en la API');
    }

    finally {
      dispatch(loaded('editCustomer'));
    }
  };
}

export function createCustomer(customer, address = []) {
  const customerApi = new CustomerAPI();
  return async (dispatch) => {
    dispatch(loading('createCustomer'));
    dispatch({
      type: CREATE_CUSTOMER,
    });

    try {
      const response = await customerApi.create(customer);
      const { success, result, message } = response;
      let actualCustomer = { ...result };
      if (success) {
        await dispatch(fetchAllCustomers());
        if (address.length > 0) {
          toast.info(`Cuenta creada: ${result.accountname}, enviando direcciones ...`);
          const addressApi = new AddressAPI();
          const requests = address.map(add => addressApi.createAddress({ ...add, relatedtoid: result.crmid }));
          await Promise.all(requests)
            .then(async (createCustomerRes) => {
              const resAddress = [];
              const paso = [];
              const noPaso = [];
              const total = createCustomerRes.length;
              createCustomerRes.forEach((res) => {
                if (res.success) {
                  paso.push(res.message.success);
                  resAddress.push(res);
                } else {
                  noPaso.push(res.message.warning ? res.message.warning : res.message.error);
                }
              });
              toast.info(`Direcciones creadas: ${paso.length} de ${total}`);
              if (noPaso.length > 0) {
                paso.map(msg => toast.success(msg));
                noPaso.map(msg => toast.warning(msg));
              }
              const factura = resAddress.find(x => x.send.factura);
              const treebesdireccionfacturaid = factura.result.crmid;
              const envio = resAddress.find(x => x.send.envio);
              const treebesdireccionenvioid = envio.result.crmid;
              if (treebesdireccionfacturaid !== '' && treebesdireccionenvioid !== '') {
                const setCustomerDefaults = {
                  crmid: result.crmid,
                  accountname: result.accountname,
                  nombre: result.firstname,
                  apellidos: result.lastname,
                  treebesdireccionfacturaid,
                  treebesdireccionenvioid,
                };
                const defaultRes = await customerApi.edit(setCustomerDefaults);
                if (defaultRes.success) {
                  toast.success('Direcciones de facturación y de envio guardadas');
                  await dispatch(closeModal('customerEdit'));
                  await dispatch(fetchAllCustomers());
                  actualCustomer = { ...defaultRes.result };
                }
                else {
                  toast.error(` ❌${defaultRes.message.error} `);
                }
              }
            });
        } else {
          toast.success(` ✔ ${message.success}`);
        }
        await dispatch(selectCustomer(actualCustomer));
        await dispatch(closeModal('customerCreate'));
      }

      else {
        toast.error(` ❌ ${message.error}`);
        await dispatch(removeCustomer());
      }
    }

    catch (createCustomerErr) {
      console.log({ createCustomerErr });
      toast.error(' ❌ Error en la API');
      await dispatch(removeCustomer());
    }

    finally {
      await dispatch(loaded('createCustomer'));
    }
  };
}

export function addAddresForCustomer(idCustomer, _newAddress) {
  const addressApi = new AddressAPI();
  return async (dispatch) => {
    await dispatch(loading('address'));
    try {
      const newAddress = { ..._newAddress, relatedtoid: idCustomer };
      const response = await addressApi.createAddress(newAddress);
      const { success, message } = response;
      if (success) {
        toast.success(message.success);
        await dispatch(fetchAccountById(idCustomer));
        await dispatch(fetchAddressByCustomer(idCustomer));
      } else {
        toast.warning(response.message.warning ? response.message.warning : response.message.error);
      }
    } catch (getAddressErr) {
      console.log({ getAddressErr });
    } finally {
      await dispatch(loaded('address'));
    }
  };
}

export function editAddresForCustomer(idCustomer, _newAddress) {
  const addressApi = new AddressAPI();
  return async (dispatch) => {
    await dispatch(loading('address'));
    try {
      const newAddress = { ..._newAddress };
      const response = await addressApi.editAddress(newAddress);
      const { success, message } = response;
      if (success) {
        toast.success(message.success);
        await dispatch(fetchAddressByCustomer(idCustomer));
      } else {
        toast.warning(response.message.warning ? response.message.warning : response.message.error);
      }
    } catch (getAddressErr) {
      console.log({ getAddressErr });
    } finally {
      await dispatch(loaded('address'));
    }
  };
}

export function deleteAddressByCustomer(customerId, crmid, label) {
  return async (dispatch) => {
    await dispatch(loading('address'));
    try {
      const addressApi = new AddressAPI();
      const response = await addressApi.deleteAddress(crmid);
      if (response.success) {
        await dispatch(fetchAddressByCustomer(customerId));
        await toast.success(`Dirección: ${label} eliminada`);
      } else {
        toast.warning(response.message.error || response.message.warning);
      }
    } catch (err) {
      console.log({ deleteAddressByCustomerErr: err });
    } finally {
      await dispatch(loaded('address'));
    }
  };
}

export function filterCustomers(word) {
  return async (dispatch) => {
    dispatch(loading('selectCustomer'));
    try {
      await new Promise(resolve => setTimeout(resolve));
      dispatch({
        type: FILTER_CUSTOMERS,
        word,
      });
    }

    catch {
      /* Continue regardless of error */
    }

    finally {
      dispatch(loaded('selectCustomer'));
    }
  };
}

export function setSchema() {
  const customerApi = new CustomerAPI();
  return async (dispatch) => {
    try {
      await new Promise(resolve => setTimeout(resolve));
      const response = await customerApi.getSchema();
      const { success, result, message } = response;
      if (success) {
        const customerFormSchema = result;
        dispatch({
          type: SET_SCHEMA,
          customerFormSchema,
        });
      }

      else {
        toast.error(` ❌ ${message}`);
        dispatch(removeCustomer());
      }
    } catch {
      /* Catch error */
    } finally {
      dispatch(loaded('selectCustomer'));
    }
  };
}
