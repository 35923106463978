/* eslint-disable camelcase */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import {
  Avatar,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import {
  Lock,
} from '@material-ui/icons';

import preval from 'preval.macro';

import IconButton from '@material-ui/core/IconButton';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { changeTab } from '../../../redux/actions/tabActions';
import { closeModal } from '../../../redux/actions/modalActions';
import { fetchAllOrders } from '../../../redux/actions/orderActions';
import { fetchAllProducts } from '../../../redux/actions/productActions';
import { removePricebook } from '../../../redux/actions/priceBookActions';
import { selectStore, selectBC } from '../../../redux/actions/authUserActions';
import {
  fetchBalance,
  saveACC,
  saveCC,
  setTicket,
} from '../../../redux/actions/BalanceActions';

/* Rutines */
import { getCC } from '../../../redux/actions/posActions';

import authUserProps from '../../../propTypes/authUserProps';

import Balance from '../../Balance';
import Connection from './Connection';
import LayoutUserInfo from './LayoutUserInfo';
import StoreSelect from '../../Store/StoreSelect';
import BCSelect from '../../Bank/BCSelect';
import Tickets from '../../Tickets';
import Pricebook from '../../Pricebook';

class LayoutBarMenu extends PureComponent {
  static propTypes = {
    authUser: authUserProps.isRequired,
    balance: PropTypes.object.isRequired,
    loads: PropTypes.object.isRequired,
    modals: PropTypes.object.isRequired,

    dispatchSelectStore: PropTypes.func.isRequired,

    /* Fetch */
    dispatchFetchBalance: PropTypes.func.isRequired,
    dispatchGetCC: PropTypes.func.isRequired,
    dispatchGetACC: PropTypes.func.isRequired,

    /* CC */
    dispatchCloseDialogCC: PropTypes.func.isRequired,
    dispatchSaveCC: PropTypes.func.isRequired,

    /* ACC */
    dispatchCloseDialogACC: PropTypes.func.isRequired,
    dispatchSaveACC: PropTypes.func.isRequired,

    /* MCC */
    dispatchGetMCC: PropTypes.func.isRequired,
    dispatchReSendTicket: PropTypes.func.isRequired,
    dispatchCloseDialogMCC: PropTypes.func.isRequired,

    /* BC */
    dispatchSelectBC: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = { rightDrawer: false };
  }

  toggleDrwawer = () => {
    const { rightDrawer } = this.state;
    if (!rightDrawer) {
      const { dispatchFetchBalance } = this.props;
      dispatchFetchBalance();
    }
    this.setState(prevState => ({ rightDrawer: !prevState.rightDrawer }));
  }

  slideList = () => {
    const {
      loads,
      modals,
      balance,
      authUser,
      dispatchSelectStore,
      /* Fetch */
      dispatchFetchBalance,
      dispatchGetCC,
      dispatchGetACC,

      /* CC */
      dispatchCloseDialogCC,
      dispatchSaveCC,

      /* AC */
      dispatchCloseDialogACC,
      dispatchSaveACC,

      /* MCC */
      dispatchGetMCC,
      dispatchCloseDialogMCC,
      dispatchReSendTicket,

      /* BC */
      dispatchSelectBC,
    } = this.props;
    const dateTimeStamp = preval`module.exports = new Date().toLocaleString();`;
    return (
      <div
        className="drawer_content"
        role="presentation"
        // onClick={() => { this.toggleDrwawer(); }}
        // onKeyDown={() => { this.toggleDrwawer(); }}
      >
        <div className="w-100">
          <LayoutUserInfo authUser={authUser} />
          <Divider />
          <Connection authUser={authUser} />
          <StoreSelect authUser={authUser} dispatchSelectStore={dispatchSelectStore} />
          {/* <BCSelect authUser={authUser} selectBC={dispatchSelectBC} /> */}
          <Pricebook />
        </div>
        <Tickets type="CC" />
        <div className="w-100 h-auto">

          <Balance
            authUser={authUser}
            balance={balance}
            balanceIsLoading={Boolean(loads.balanceIsLoading)}
            dispatchFetchBalance={dispatchFetchBalance}

            /* Fetch */
            dispatchGetCC={dispatchGetCC}
            dispatchGetACC={dispatchGetACC}

            /* dialogCC */
            dialogCCIsOpen={Boolean(modals.dialogCCModalIsOpen)}
            dialogCCIsLoading={Boolean(loads.dialogCCIsLoading)}
            closeDialogCC={dispatchCloseDialogCC}
            dispatchSaveCC={dispatchSaveCC}

            /* dialogACC */
            dialogACCIsOpen={Boolean(modals.dialogACCModalIsOpen)}
            dialogACCIsLoading={Boolean(loads.dialogACCIsLoading)}
            closeDialogACC={dispatchCloseDialogACC}
            dispatchSaveACC={dispatchSaveACC}

            /* dialogMCC */
            dispatchGetMCC={dispatchGetMCC}
            dialogMCCIsOpen={Boolean(modals.dialogMCCModalIsOpen)}
            dialogMCCIsLoading={Boolean(loads.dialogMCCIsLoading)}
            closeDialogMCC={dispatchCloseDialogMCC}
            sendTicket={dispatchReSendTicket}
          />

          <div className="w-100 d-flex align-items-end">
            <Divider />
            <List>
              <ListItem button component="a" href="/account/profile">
                <ListItemIcon><AccountCircleIcon /></ListItemIcon>
                <ListItemText primary="Perfil" />
              </ListItem>
              <ListItem button component="a" href="/account/logout">
                <ListItemIcon><Lock /></ListItemIcon>
                <ListItemText primary="Cerrar Sesión" />
              </ListItem>
              <ListItem dense>
                <ListItemText primary="Versión" secondary={`Build: ${dateTimeStamp}`} />
              </ListItem>
            </List>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {
      authUser,
    } = this.props;
    const { rightDrawer } = this.state;
    const { user } = authUser;
    const last = user.last_name.charAt(0);

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <IconButton
          onClick={() => this.toggleDrwawer()}
          color="inherit"
          className="mx-2"
        >
          <Avatar className="General__UserAvatarStyle">{last}</Avatar>
        </IconButton>
        <Drawer
          anchor="right"
          open={rightDrawer}
          onClose={() => { this.toggleDrwawer(); }}
        >
          { this.slideList() }
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  tabs: state.tabs,
  loads: state.loads,
  modals: state.modals,
  balance: state.balance,
  authUser: state.authUser,
});

const mapDispatchToProps = dispatch => ({
  dispatchSelectStore: async (element, pos) => {
    const { crmid, name, image, direccion, telefono, email, url_web, campos, direccionid, forzaenvios } = element.dataset;
    await dispatch(removePricebook());
    await dispatch(selectStore(crmid, name, image, direccion, telefono, email, url_web, campos, direccionid, pos, forzaenvios));
    await dispatch(fetchAllProducts());
    await dispatch(fetchAllOrders());
  },
  dispatchFetchBalance: () => dispatch(fetchBalance()),

  /* CC */
  dispatchGetCC: (date = '') => dispatch(getCC('dialogCC', 'cc', date)),
  dispatchCloseDialogCC: () => dispatch(closeModal('dialogCC')),
  dispatchSaveCC: (data, mcc = false) => dispatch(saveCC(data, mcc)),

  /* ACC */
  dispatchGetACC: (date = '') => dispatch(getCC('dialogACC', 'ACC', date)),
  dispatchCloseDialogACC: () => dispatch(closeModal('dialogACC')),
  dispatchSaveACC: data => dispatch(saveACC(data)),

  /* MCC */
  dispatchGetMCC: (date = '') => dispatch(getCC('dialogMCC', 'MCC', date)),
  dispatchCloseDialogMCC: () => dispatch(closeModal('dialogMCC')),
  dispatchReSendTicket: data => dispatch(setTicket(data)),

  /* Tabs */
  dispatchChangeProfileView: actual => dispatch(changeTab('profileView', actual)),

  /* Bank */
  dispatchSelectBC: (element) => {
    const { crmid, parent } = element.dataset;
    dispatch(selectBC(crmid, parent));
  },
});

// @ts-ignore
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LayoutBarMenu));
