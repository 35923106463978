import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toolsHelper } from '../../../../helpers';

const ItemFile = ({ file, download, remove }) => {
  const realSize = toolsHelper.formatBytes(file.filesize);
  const secundTitle = `${realSize} - ${moment(file.createdtime).fromNow()}`;

  const renderIcon = (extension) => {
    const ext = extension.toLowerCase();
    switch (ext) {
      case 'pdf':
        return 'file-pdf';
      case 'png':
        return 'file-image';
      case 'jpg':
        return 'file-image';
      default:
        return 'file';
    }
  };

  return (
    <ListItem
      button
      onClick={() => download(file.url, file.filename)}
    >
      <ListItemIcon className="file_icon">
        <FontAwesomeIcon icon={renderIcon(file.extension)} />
      </ListItemIcon>
      <ListItemText
        className="file_text"
        primary={file.filename}
        secondary={secundTitle}
      />
      <ListItemSecondaryAction>
        <IconButton
          onClick={() => remove(file.crmid, file.filename)}
        >
          <Delete />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

ItemFile.propTypes = {
  download: PropTypes.func.isRequired,
  file: PropTypes.object.isRequired,
  remove: PropTypes.func.isRequired,
};

export default ItemFile;
