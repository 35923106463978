import React, { useCallback, useRef, useState } from 'react';
import moment from 'moment';
import {
  faDolly, faFileInvoice, faHashtag,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  LinearProgress, List, ListItem, ListItemText, Collapse,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import Iconwidthbg from '../../../../components/Icons/IconWidthBG/IconWidthBG';
import { getColorStatusFDE, getColorStatusMDA } from '../../../../helpers/tools';
import ListItemGeneric from '../../../ClientPage/components/ListItemGeneric/ListItemGeneric';
import WharehouseExpand from './WPurchaseExpand';
import usePagination from '../../../../hooks/usePagination';
import ShareModal from '../../../../components/Share/components/ShareModal';
import appModules from '../../../../constant/appModules';
import { useHistory } from 'react-router';

const WPurchases = () => {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [purchase, setPurchase] = useState({});

  const loads = useSelector(state => state.loads);
  const isLoading = Boolean(loads && loads.emailTemplateIsLoading);
  const [openShare, setOpenShare] = React.useState(false);

  const handleShareClose = () => {
    setOpenShare(false);
  };

  const shareModal = async (purchase) => {
    await setPurchase(purchase);
    setOpenShare(true);
  };

  const {
    result, hasMore, error, loading,
  } = usePagination('list/PurchaseOrder', page);

  const observer = useRef();
  const lastPurchaseElement = useCallback((node) => {
    if (hasMore) {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) setPage(page + 1);
      });
      if (node) observer.current.observe(node);
    }
  }, [loading, hasMore]);

  const recibirOrden = () => {
    console.log('holaaaa');
    history.push(`/almacenes/recibir/${purchase.crmid}`);
  };


  return (
    <>
      {
        result.map((purchase, index) => {
          if ((result.length === index + 1)) {
            return (
              <div
                ref={lastPurchaseElement}
                key={`${purchase.crmid}_key_${Math.random()}`}
              >

                <ListItemGeneric
                  titleLeft={purchase.entity_label}
                  expandData={(<WharehouseExpand purchase={purchase} openShare={shareModal} orderReceiver={recibirOrden} />)}
                  subTitleLeft={moment(purchase.createdtime).format('DD MMM, h:mm a')}
                  subTitleRight={(
                    <>
                      <Iconwidthbg
                        primaryColor={getColorStatusFDE(purchase.estado_fde)}
                        secondaryColor="#FFF"
                        width={27}
                        height={27}
                        classes="ml-2"
                        enabled
                        square
                        rounded
                        actionClick={() => {}}
                      >
                        <FontAwesomeIcon style={{ fontSize: 15 }} icon={faFileInvoice} />
                      </Iconwidthbg>

                      <Iconwidthbg
                        primaryColor={getColorStatusMDA(purchase.estado_mda)}
                        secondaryColor="#FFF"
                        width={27}
                        height={27}
                        classes="ml-2"
                        enabled
                        square
                        rounded
                        actionClick={() => {}}
                      >
                        <FontAwesomeIcon style={{ fontSize: 15 }} icon={faDolly} />
                      </Iconwidthbg>

                      <Iconwidthbg
                        primaryColor={getColorStatusMDA('Entregado')}
                        secondaryColor="#FFF"
                        width={27}
                        height={27}
                        classes="ml-2"
                        enabled
                        square
                        rounded
                        actionClick={() => {}}
                      >
                        <FontAwesomeIcon style={{ fontSize: 15 }} icon={faHashtag} />
                      </Iconwidthbg>
                    </>
                    )}
                />
              </div>
            );
          }
          return (

            <ListItemGeneric
              key={`${purchase.crmid}_key_${Math.random()}`}
              titleLeft={purchase.entity_label}
              expandData={(<WharehouseExpand purchase={purchase} openShare={shareModal} />)}
              subTitleLeft={`${purchase.purchaseorder_no} - ${moment(purchase.createdtime).format('DD MMM, h:mm a')}`}
              subTitleRight={(
                <>
                  <Iconwidthbg
                    primaryColor={getColorStatusFDE(purchase.estado_fde)}
                    secondaryColor="#FFF"
                    width={27}
                    height={27}
                    classes="ml-2"
                    enabled
                    square
                    rounded
                    actionClick={() => {}}
                  >
                    <FontAwesomeIcon style={{ fontSize: 15 }} icon={faFileInvoice} />
                  </Iconwidthbg>

                  <Iconwidthbg
                    primaryColor={getColorStatusMDA(purchase.estado_mda)}
                    secondaryColor="#FFF"
                    width={27}
                    height={27}
                    classes="ml-2"
                    enabled
                    square
                    rounded
                    actionClick={() => {}}
                  >
                    <FontAwesomeIcon style={{ fontSize: 15 }} icon={faDolly} />
                  </Iconwidthbg>

                  {/*  <Iconwidthbg
                    primaryColor={getColorStatusMDA('Entregado')}
                    secondaryColor="#FFF"
                    width={27}
                    height={27}
                    classes="ml-2"
                    enabled
                    square
                    rounded
                    actionClick={() => {}}
                  >
                    <FontAwesomeIcon style={{ fontSize: 15 }} icon={faHashtag} />
                  </Iconwidthbg> */}
                </>
                  )}
            />
          );
        })
    }
      {loading && (
        <Collapse in={loading}>
          <LinearProgress />
        </Collapse>
      )}
      {!loading && !result.length && (
        <List dense>
          <ListItem classes={{ root: 'bg-white' }}>
            <ListItemText
              primary="No se encontró ninguna compra"
            />
          </ListItem>
        </List>
      )}

      <ShareModal
        open={openShare}
        title={`Compartir ${purchase ? purchase.entity_label : ''}`}
        handleClose={handleShareClose}
        order={purchase || {}}
        template={appModules.PurchaseOrder}
        isLoading={isLoading}
      />
    </>
  );
};

export default WPurchases;
