import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import {
  Fab,
  Zoom,
  Tooltip,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import InputButton from '../../../../Input/GenericButton';

const RefundConfirm = ({
  tab,
  loading,
  showButton,
  tooltip,
  onSubmit,
  disabled,
}) => {
  if (isMobile) {
    return (
      <div className="refund_confirm">
        <Zoom
          in={showButton}
          unmountOnExit
        >
          <Tooltip
            title={tooltip}
          >
            <Fab
              color={tab === 1 ? 'primary' : 'secondary'}
              onClick={() => onSubmit()}
              disabled={disabled}
            >
              <FontAwesomeIcon
                icon="dolly"
                style={{ width: '28px', height: '28px' }}
              />
            </Fab>
          </Tooltip>
        </Zoom>
      </div>
    );
  }
  return (
    <div className="refund_confirm_desktop">
      <InputButton
        title={`Realizar Devolución ${tab === 0 ? 'Total' : 'Parcial'}`}
        click={() => onSubmit()}
        disabled={disabled}
        loading={loading}
      />
    </div>
  );
};

RefundConfirm.propTypes = {
  tab: PropTypes.number.isRequired,
  showButton: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  tooltip: PropTypes.string,
  onSubmit: PropTypes.func,
};

RefundConfirm.defaultProps = {
  showButton: true,
  loading: false,
  disabled: false,
  tooltip: 'Realizar devolución',
  onSubmit: () => {},
};

export default RefundConfirm;
