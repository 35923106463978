import { toast } from 'react-toastify';
import OrderAPI from '../../api/OrderAPI';
import { loading, loaded } from './loadActions';

import {
  ADD_GUIDE,
  ADD_ALL_GUIDE,
  ADD_SELECTED_GUIDE,
} from '../types/guideActions.type';
import GuideApi from '../../api/GuideApi';

export function addAllOrderGuides(guides) {
  return {
    type: ADD_ALL_GUIDE,
    payload: guides,
  };
}

export function addGuide(guide) {
  return {
    type: ADD_GUIDE,
    payload: guide,
  };
}

export function setSelectedGuide(guide) {
  return {
    type: ADD_SELECTED_GUIDE,
    payload: guide,
  };
}


export const fetchAllOrderGuide = orderId => async (dispatch) => {
  const guideApi = new GuideApi();
  dispatch(loading('guide'));
  try {
    const response = await guideApi.getOrderGuides(orderId);
    const { success, result } = response;
    if (process.env.NODE_ENV === 'development') {
      console.log('===================fetchAllOrderGuide=================');
      console.log({ result });
      console.log('====================================');
    }
    if (success) {
      dispatch(addAllOrderGuides(result));
    }
    else {
      console.error('Error obteniendo las guias de la orden');
    }
  } catch (err) {
    toast.error(err);
  }
  finally {
    dispatch(loaded('guide'));
  }
};
