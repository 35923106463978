import fetch from 'cross-fetch';
import ConfigurationAPI from './ConfigurationAPI';

class GuideApi extends ConfigurationAPI {
    create = async (order, guide, customer, packing) => {
      const { selectedCustomer } = customer;
      const data = {
        referencia: '00000',
        relatedtoid: order.crmid,
        shipment_method: guide.shipment_method,
        shipment_number: '',
        shipment_content: 'Productos varios',
        carrier: guide.carrier,
        carrier_service_code: guide.carrier_service_code,
        date_added: null,
        last_modified: null,
        status_shipment: 'Pendiente',
        quote_amount: guide.total_amount,
        businessid: selectedCustomer.crmid,
        carrier_pickup_number: '',
        pickup_date: null,
        status_pickup: null,
        treebesempaquesid: packing.crmid,
        label_s: null,
        label_url: null,
        label_share_link: null,
        treebesempaquesid_label: null,
      };

      this.credentials.method = 'POST';
      this.credentials.body = JSON.stringify(data);

      try {
        const response = await fetch(`${this.url}/TreebesSolicitudesEnvio`, this.credentials);
        const json = await response.json();
        return json;
      }

      catch {
        return { success: false, message: 'Error en el Host' };
      }
    }

    createProductRequest = async (solicitudEnvioId, almacenDirectionId, orderProducts) => {
      const products = [];
      orderProducts.map((prod) => {
        const prodId = prod.productid.split('x').pop() || prod.productid;
        const productDimensions = {
          crmid: prodId,
          quantity: (prod.sendQuantity === null || prod.sendQuantity === undefined) ? prod.quantity : prod.sendQuantity,
          weight_s: prod.weight,
          height_s: '0.00000000',
          width_s: '0.00000000',
          length_s: '0.00000000',
        };
        products.push(productDimensions);
      });

      const data = {
        crmid: solicitudEnvioId,
        direccionenvioid: almacenDirectionId,
        products: [...products],
      };

      this.credentials.method = 'PUT';
      this.credentials.body = JSON.stringify(data);
      try {
        const response = await fetch(`${this.url}/TreebesSolicitudesEnvio/solicitud-productos`, this.credentials);
        const json = await response.json();
        return json;
      }

      catch {
        return { success: false, message: 'Error en el Host' };
      }
    }

  getOrderGuides= async (id) => {
    try {
      const response = await fetch(`${this.url}/list/TreebesSolicitudesEnvio?related_to=${id}`, this.credentials);
      const json = await response.json();
      return json;
    } catch {
      return { success: false, message: 'Error en el Host' };
    }
  }

  getObjectById = async (id) => {
    try {
      const response = await fetch(`${this.url}/id/${id}`, this.credentials);
      const json = await response.json();
      return json;
    } catch {
      return { success: false, message: 'Error en el Host' };
    }
  }

  getPackingList = async () => {
    try {
      const response = await fetch(`${this.url}/list/TreebesEmpaques`, this.credentials);
      const json = await response.json();
      return json;
    } catch {
      return { success: false, message: 'Error en el Host' };
    }
  }

  createGuide = async (id) => {
    try {
      const response = await fetch(`${this.url}/createShipment/${id}`, this.credentials);
      const json = await response.json();
      return json;
    } catch {
      return { success: false, message: 'Error en el Host' };
    }
  }

  cancelGuide = async (id) => {
    try {
      const response = await fetch(`${this.url}/cancelShipment/${id}`, this.credentials);
      const json = await response.json();
      return json;
    } catch {
      return { success: false, message: 'Error en el Host' };
    }
  }
}

export default GuideApi;
