import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { Card, LinearProgress } from '@material-ui/core';
/* Components */
import FilterSettings from '../../FilterSettings';

const ConfFiltrosVentas = ({
  loads,
  authUser,
  fdeTitle,
  mdaTitle,
  fdeOptions,
  mdaOptions,
  stateTitle,
  stateOptions,
  generalTitle,
  dispatchSetOrderFilter,
}) => {
  const { user } = authUser;
  const { config } = user;
  const loading = Boolean(loads.profileIsLoading);
  const generalDisable = Boolean(config.pos_config_alm);
  const fdeFilterValue = (config.estado_fde ? (config.estado_fde) : (fdeOptions[0].value));
  const mdaFilterValue = (config.estado_mda ? (config.estado_mda) : (mdaOptions[0].value));
  const stateFilterValue = (config.state ? (config.state) : (stateOptions[0].value));
  return (
    <Card
      className={
        isMobile ? 'settings__cardMobile px-4 py-2' : 'settings__card px-4 py-2'
      }
    >
      <div className="settings__title">{generalTitle}</div>
      {loading && <LinearProgress />}
      <FilterSettings
        title={fdeTitle}
        options={fdeOptions}
        setFilter={dispatchSetOrderFilter}
        filter="estado_fde"
        value={fdeFilterValue}
        loading={loading}
        disabled={generalDisable}
      />
      <FilterSettings
        title={mdaTitle}
        options={mdaOptions}
        setFilter={dispatchSetOrderFilter}
        filter="estado_mda"
        value={mdaFilterValue}
        loading={loading}
        disabled={generalDisable}
      />
      <FilterSettings
        title={stateTitle}
        options={stateOptions}
        setFilter={dispatchSetOrderFilter}
        filter="state"
        value={stateFilterValue}
        loading={loading}
        disabled={generalDisable}
      />
    </Card>
  );
};

ConfFiltrosVentas.propTypes = {
  fdeTitle: PropTypes.string,
  mdaTitle: PropTypes.string,
  fdeOptions: PropTypes.array,
  mdaOptions: PropTypes.array,
  stateTitle: PropTypes.string,
  stateOptions: PropTypes.array,
  generalTitle: PropTypes.string,
  loads: PropTypes.object.isRequired,
  authUser: PropTypes.object.isRequired,
  dispatchSetOrderFilter: PropTypes.func.isRequired,
};

ConfFiltrosVentas.defaultProps = {
  generalTitle: 'Filtros',
  fdeTitle: 'Filtrar por Flujo de Efectivo',
  stateTitle: 'Filtrar por Estado de la Orden',
  mdaTitle: 'Filtrar por Movimiento de Almacén',
  fdeOptions: [
    {
      label: 'Pagado/Cobrado',
      value: 'Pagado/Cobrado',
      column: 'estado_fde',
    },
    {
      label: 'Sin Pago/Cobro',
      value: 'Sin Pago/Cobro',
      column: 'estado_fde',
    },
    {
      label: 'Parcialmente Pagado/Cobrado',
      value: 'Parcialmente Pagado/Cobrado',
      column: 'estado_fde',
    },
  ],
  mdaOptions: [
    {
      label: 'Entregado',
      value: 'Entregado',
      column: 'estado_mda',
    },
    {
      label: 'Sin Entregar',
      value: 'Sin Entregar',
      column: 'estado_mda',
    },
    {
      label: 'Parcialmente Entregado',
      value: 'Parcialmente Entregado',
      column: 'estado_mda',
    },
  ],
  stateOptions: [
    {
      label: 'Creados',
      value: 'Creado',
      column: 'state',
    },
    {
      label: 'Apartados',
      value: 'Apartado',
      column: 'state',
    },
    {
      label: 'Autorizados',
      value: 'Autorizado',
      column: 'state',
    },
    {
      label: 'Cancelados',
      value: 'Cancelado',
      column: 'state',
    },
  ],
};

export default ConfFiltrosVentas;
