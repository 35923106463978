/* eslint-disable no-unused-expressions */
/* eslint-disable radix */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import {
  Card,
  CardActionArea,
  CardHeader,
  Collapse,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import {
  Cancel,
  ExpandLess,
  ExpandMore,
  Save,
  Receipt,
} from '@material-ui/icons';

import CCTipo from './cc/components/tipo';

const BalanceCorte = ({
  corteDeCaja,
  isLoading,
  isOpen,
  onSave,
  sendTicket,
  title,
}) => {
  const mcc = useSelector(state => state.balance.mcc);
  const [open, setOpen] = React.useState(isOpen);
  const [elements, setElements] = React.useState([]);

  const dateYear = title.toString().substring(0, 4);
  const month = title.toString().substring(4, 6);
  const dateMonth = (parseInt(month) - 1).toString();
  const dateDay = title.toString().substring(6, 8);

  const dateHour = title.toString().substring(8, 10);
  const dateMinute = title.toString().substring(10, 12);
  const dateSecond = title.toString().substring(12, 14);

  const date = new Date(dateYear, dateMonth, dateDay, dateHour, dateMinute, dateSecond);
  const nowDate = moment(date).fromNow();

  /* Functions */
  const setInitElements = ({ corte }) => {
    let arr = [...corte];
    arr = arr.map((type) => {
      const el = { ...type };
      el.enable = true;
      el.id = `id_${el.label}`;
      el.type = 'tipo';
      el.movimientos = type.movimientos ? type.movimientos.map((method) => {
        const _method = { ...method };
        _method.enable = true;
        _method.id = `id_${el.label}_${method.label}`;
        _method.type = 'metodo';
        _method.detalles = method.detalles.map((detail) => {
          const _detail = { ...detail };
          _detail.enable = true;
          _detail.type = 'detalle';
          return _detail;
        });
        return _method;
      }) : type.documentos.map((method) => {
        const _method = { ...method };
        _method.enable = true;
        _method.id = `id_${el.label}_${method.id}`;
        _method.type = 'docs';
        _method.detalles = [];
        return _method;
      });
      return el;
    });
    setElements(arr);
  };

  const checkTipo = (id) => {
    let arr = [];
    arr = elements.map((x) => {
      const el = { ...x };
      if (el.id === id) {
        const flag = !x.enable;
        el.enable = flag;
        el.movimientos = x.movimientos.map((mov) => {
          const movimiento = { ...mov };
          movimiento.enable = flag;
          movimiento.detalles = mov.detalles.map((det) => {
            const detail = { ...det };
            detail.enable = flag;
            return detail;
          });
          return movimiento;
        });
      }
      return el;
    });
    setElements(arr);
  };

  const checkMovimiento = (id) => {
    let arr = [];
    arr = elements.map((x) => {
      const el = { ...x };
      el.enable = true;
      el.movimientos = x.movimientos.map((mov) => {
        const movimiento = { ...mov };
        if (movimiento.id === id) {
          const flag = !mov.enable;
          movimiento.enable = flag;
          movimiento.detalles = mov.detalles.map((det) => {
            const detail = { ...det };
            detail.enable = flag;
            return detail;
          });
        }
        return movimiento;
      });
      return el;
    });
    setElements(arr);
  };

  const checkDocs = (id) => {
    let arr = [];
    arr = elements.map((x) => {
      const el = { ...x };
      el.enable = true;
      el.movimientos = x.movimientos.map((mov) => {
        const movimiento = { ...mov };
        if (movimiento.id === id) {
          const flag = !mov.enable;
          movimiento.enable = flag;
        }
        return movimiento;
      });
      return el;
    });
    setElements(arr);
  };

  const checkDetalle = (id) => {
    let arr = [];
    arr = elements.map((x) => {
      const el = { ...x };
      el.movimientos = x.movimientos.map((mov) => {
        const movimiento = { ...mov };
        movimiento.detalles = mov.detalles.map((det) => {
          const detail = { ...det };
          if (detail.id === parseInt(id)) {
            const flag = !det.enable;
            detail.enable = flag;
          }
          return detail;
        });
        if (movimiento.detalles.every(det => det.enable === false)) {
          movimiento.enable = false;
        } else {
          movimiento.enable = true;
        }
        return movimiento;
      });
      if (el.movimientos.every(mov => mov.enable === false)) {
        el.enable = false;
      } else {
        el.enable = true;
      }
      return el;
    });
    setElements(arr);
  };

  const onClickCheck = (target) => {
    const { kind, id } = target.dataset;
    switch (kind) {
      case 'tipo':
        checkTipo(id);
        break;
      case 'metodo':
        checkMovimiento(id);
        break;
      case 'detalle':
        checkDetalle(id);
        break;
      case 'docs':
        checkDocs(id);
        break;
      default:
        break;
    }
  };

  const onSaveCC = (_elements) => {
    const els = [..._elements];
    let selectedCheckboxes = [];
    let unselectedCheckboxes = [];
    els.forEach((el) => {
      el.movimientos.forEach((mov) => {
        let order = [];
        let unOrder = [];

        if (el.label === 'DOCS') {
          const id = mov.id.split('_').pop();
          const parseId = parseInt(id);
          mov.enable ? selectedCheckboxes.push(parseId) : unselectedCheckboxes.push(parseId);
        } else {
          order = mov.detalles.filter(det => det.enable === true).map(x => x.id);
          unOrder = mov.detalles.filter(det => det.enable === false).map(x => x.id);
        }

        selectedCheckboxes = [...selectedCheckboxes, ...order];
        unselectedCheckboxes = [...unselectedCheckboxes, ...unOrder];
      });
    });
    onSave({
      selectedCheckboxes,
      unselectedCheckboxes,
    });
  };

  const onCancelCC = (_elements) => {
    const els = [..._elements];
    const selectedCheckboxes = [];
    let unselectedCheckboxes = [];
    els.forEach((el) => {
      el.movimientos.forEach((mov) => {
        let order = [];
        if (el.label === 'DOCS') {
          const id = mov.id.split('_').pop();
          const parseId = parseInt(id);
          unselectedCheckboxes.push(parseId);
        } else {
          order = mov.detalles.map(det => det.id);
        }

        unselectedCheckboxes = [...unselectedCheckboxes, ...order];
      });
    });
    toast.info(`Cancelando ${title}`);
    onSave({
      selectedCheckboxes,
      unselectedCheckboxes,
    });
  };

  /* Effect */
  React.useEffect(() => {
    if (!isLoading) {
      setInitElements(corteDeCaja);
    }
  }, [isLoading, corteDeCaja]);

  return (
    <>
      {
        Object.keys(mcc).length ? (
          <Card className="w-100 mb-2">
            <CardActionArea
              onClick={() => setOpen(!open)}
            >
              <CardHeader
                title={(
                  <div className="w-100 d-flex justify-content-between align-items-center">
                    <span>{title}</span>
                    <div className="w-auto d-flex justify-content-around">
                      <Tooltip title="Re-Imprimir Ticket">
                        <span>
                          <IconButton
                            disbled={isLoading}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              const data = {
                                title: `Re-impresión ${title}`,
                                data: [...corteDeCaja.corte],
                              };
                              sendTicket(data);
                            }}
                          >
                            <Receipt />
                          </IconButton>
                        </span>
                      </Tooltip>
                      <Tooltip title="Cancelar solicitud">
                        <span>
                          <IconButton
                            disbled={isLoading}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              onCancelCC(elements);
                            }}
                          >
                            <Cancel />
                          </IconButton>
                        </span>
                      </Tooltip>
                      <Tooltip title="Guardar">
                        <span>
                          <IconButton
                            disbled={isLoading}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              onSaveCC(elements);
                            }}
                          >
                            <Save />
                          </IconButton>
                        </span>
                      </Tooltip>
                      <IconButton
                        onClick={() => setOpen(!open)}
                      >
                        {open ? (<ExpandLess />) : (<ExpandMore />)}
                      </IconButton>
                    </div>
                  </div>
                )}
                subheader={nowDate}
              />
            </CardActionArea>
            <Collapse
              in={open}
              mountOnEnter
            >
              <div className="w-100 h-auto p-2 d-flex flex-column">
                <CCTipo
                  elements={elements}
                  click={onClickCheck}
                />
              </div>
            </Collapse>
          </Card>
        ): (
          <div>
            <span>Sin corte de caja</span>
          </div>
        )
      }
    </>
  );
};

BalanceCorte.propTypes = {
  corteDeCaja: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  onSave: PropTypes.func,
  sendTicket: PropTypes.func,
  title: PropTypes.string,
};

BalanceCorte.defaultProps = {
  isLoading: false,
  isOpen: false,
  onSave: () => {},
  sendTicket: () => {},
  title: '',
};

export default BalanceCorte;
