/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
import { toast } from 'react-toastify';
import SellAPI from '../../api/SellAPI';
import OrderAPI from '../../api/OrderAPI';
import CotizarAPI from '../../api/CotizarAPI';
import GuideApi from '../../api/GuideApi';
import { fetchTimbrado } from './TimbrarActions';
import { removeCustomer } from './customerActions';
import { fetchAllProducts } from './productActions';
import { clearCart, alertProducts, setEditProductsComments } from './cartActions';
import { addItemsToTable } from './tableActions';
import { removePricebook } from './priceBookActions';
import {
  selectOrder,
  fetchAllOrders,
  resetEditOrder,
  setActivityToOrder,
  changeOrderStatus,
} from './orderActions';
import { selectCotizacion, fetchAllCotizaciones } from './cotizarActions';
import { openModal, closeModal } from './modalActions';
import { loading, loaded } from './loadActions';
import { changeTab } from './tabActions';

export const CHANGE_SELL_CASH = 'CHANGE_SELL_CASH';
export const CHANGE_SELL_CARD = 'CHANGE_SELL_CARD';
export const CHANGE_SELL_ORDER_STATUS = 'CHANGE_SELL_ORDER_STATUS';
export const CLEAR_SELL = 'CLEAR_SELL';

export function changeSellCash(cash) {
  return {
    type: CHANGE_SELL_CASH,
    cash,
  };
}

export function changeSellCard(card) {
  return {
    type: CHANGE_SELL_CARD,
    card,
  };
}

export function changeSalesOrderStatus(sostatus) {
  return {
    type: CHANGE_SELL_ORDER_STATUS,
    sostatus,
  };
}

export function clearSell() {
  return {
    type: CLEAR_SELL,
  };
}

const displayGroupMessage = (message) => {
  if (message.success) {
    toast.success(` ✔ ${message.success}`);
  } else if (message.warning) {
    toast.warn(` ❕ ${message.warning}`);
  } else if (message.error) {
    toast.error(` ❌ ${message.error}`);
  } else {
    toast.success(` ❔ ${message.information}`);
  }
};

export function makeSell(_payments, history = () => {}) {
  let toastId = null;
  return async (dispatch, getState) => {
    const sellApi = new SellAPI();
    const guideApi = new GuideApi();
    const {
      cart,
      sell,
      discount,
      customer,
      authUser,
      timbrar: { makeBill },
    } = getState();
    dispatch(loading('sell'));
    dispatch(loading('checkout')); /* Hot FIX */
    const msg = ' 🚀 Realizando cobro...';
    toastId = toast(msg, {
      autoClose: false,
      type: toast.TYPE.INFO,
      progress: 0.2,
    });
    try {
      const { sostatus } = sell;
      const { user: { almacenes, config: { pos_auto_alm, store }, currencies } } = authUser;
      const payments = _payments.map(x => ({ ...x, referencia: '' }));
      const deliver = Boolean(pos_auto_alm === 'Siempre');
      const response = await sellApi.create(
        cart,
        discount,
        customer,
        sostatus,
        payments,
        deliver,
      );
      const { success, message, result } = await response;
      if (success) {
        const { crmid, label } = result;
        await toast.update(toastId, {
          type: toast.TYPE.SUCCESS,
          progress: 0.6,
          autoClose: false,
          render: message.success || message.warning,
        });
        const {
          user: {
            config: { pos_checkout_bill },
          },
        } = authUser;
        const billConfig = Boolean(pos_checkout_bill === 'Siempre');


        // Create guide
        const { selectedGuide, selectedPacking } = cart;
        if (selectedGuide?.carrier) {
          const createSendRequest = await guideApi.create(result, selectedGuide, customer, selectedPacking);

          if (createSendRequest.success) {
            let storeDirectionId = null;
            if ((!store.direccionid || store.direccionid === undefined || store.direccionid === null) && almacenes.length) {
              const storeIdx = almacenes.findIndex(alm => alm.crmid === store.crmid);
              if (storeIdx !== -1) {
                storeDirectionId = almacenes[storeIdx].treebesdireccionenvioid;
              }
            } else {
              storeDirectionId = store.direccionid;
            }

            await guideApi.createProductRequest(createSendRequest.result.crmid, storeDirectionId, result.LineItems);
          }
        }


        await dispatch(fetchAllOrders());
        await dispatch(selectOrder(crmid));
        switch (pos_auto_alm) {
          case 'Preguntar':
            await dispatch(openModal('deliver'));
            await dispatch(addItemsToTable('deliver'));
            await dispatch(changeTab('posType', 'Historico'));
            break;
          default:
            dispatch(fetchAllOrders());
            dispatch(removeCustomer());
            await dispatch(clearCart());
            if (billConfig) {
              await toast.update(toastId, {
                type: toast.TYPE.INFO,
                progress: 0.7,
                autoClose: false,
                render: `Facturación automatica para ${label}`,
              });
              setTimeout(() => {
                dispatch(fetchTimbrado(crmid));
              }, 500);
            } else if (makeBill) {
              await toast.update(toastId, {
                type: toast.TYPE.INFO,
                progress: 0.7,
                autoClose: false,
                render: `Facturación para la orden ${label}`,
              });
              setTimeout(() => {
                dispatch(fetchTimbrado(crmid));
              }, 500);
            }
            break;
        }
        await dispatch(removePricebook());
        dispatch(fetchAllProducts());
        setTimeout(() => {
          dispatch(closeModal('sell'));
          dispatch(loaded('checkout')); /* Hot FIX */
          dispatch(closeModal('checkout')); /* Hot FIX */
          dispatch(clearSell());
        }, 50);
        // if (pos_print_after_sell) {
        //   await toast.update(toastId, {
        //     type: toast.TYPE.INFO,
        //     progress: 0.70,
        //     autoClose: false,
        //     render: 'Impresión de Ticket ...',
        //   });
        //   setTimeout(async () => {
        //     await dispatch(selectOrder(crmid));
        //     await dispatch(changeTab('posType', 'Historico'));
        //     await dispatch(openModal('tickets'));
        //   }, 500);
        // }
        await toast.update(toastId, {
          type: toast.TYPE.INFO,
          progress: 0.9,
          autoClose: false,
          render: 'Mostrando orden ...',
        });
        history.push(`/pos/venta/${crmid}`);
        if (result.noHayDe && Object.keys(result.noHayDe).length > 0) {
          await dispatch(alertProducts(result.noHayDe));
        }
      } else {
        await toast.update(toastId, {
          type: toast.TYPE.WARNING,
          autoClose: false,
          progress: 0,
          render: message.warning || message.error,
        });
      }
    } catch (err) {
      toast.error('');
      await toast.update(toastId, {
        type: toast.TYPE.ERROR,
        autoClose: false,
        progress: 0,
        render: `No se pudo completar el pago debido a: ${err}`,
      });
    } finally {
      dispatch(loaded('sell'));
      dispatch(loaded('checkout')); /* Hot FIX */
      await setTimeout(() => {
        toast.dismiss(toastId);
      }, 4000);
    }
  };
}

const _fde = (
  type,
  _payments,
  orderId,
  modal,
  loader,
  all = false,
  showT = true,
) => async (dispatch, getState) => {
  const sellApi = new SellAPI();
  dispatch(loading(loader));
  if (showT) { toast.info(
    type === 'I' ? 'Cobrando la orden...' : 'Devolución en proceso ...',
  ); }
  let payments = [];
  if (!all) {
    payments = _payments.map(x => ({ ...x, referencia: '' }));
  } else payments = _payments;
  const {
    authUser,
    timbrar: { makeBill },
  } = getState();
  try {
    let response = {};
    if (type === 'I') {
      response = await sellApi.deliver(payments, orderId);
    } else if (type === 'O') {
      response = await sellApi.refund(payments, orderId);
    }
    const { success, message } = response;
    if (success) {
      if (showT) displayGroupMessage(message);
      dispatch(clearCart());
      await dispatch(fetchAllOrders());
      await dispatch(selectOrder(orderId));
      dispatch(closeModal(modal));
      if (type === 'I') {
        const {
          user: {
            config: { pos_checkout_bill },
          },
        } = authUser;
        const billConfig = Boolean(pos_checkout_bill === 'Siempre');
        if (billConfig) {
          toast.info('Facturación automatica para una orden...');
          setTimeout(() => {
            dispatch(fetchTimbrado(orderId));
          }, 500);
        } else if (makeBill) {
          toast.info('Facturación para una orden... ');
          setTimeout(() => {
            dispatch(fetchTimbrado(orderId));
          }, 500);
        }
      }
    } else {
      toast.error(message);
    }
  } catch {
    toast.error('Error en la API');
  } finally {
    dispatch(loaded(loader));
  }
};

export function deliverMoney(payments, orderId) {
  return _fde('I', payments, orderId, 'checkout', 'checkout');
}

export function refundMoney(payments, orderId, all = false, showT = true) {
  return _fde(
    'O',
    payments,
    orderId,
    'checkoutRefound',
    'checkoutRefound',
    all,
    showT,
  );
}

export function saveSell(history = {}) {
  console.log('SAVE SELL');
  let toastId = null;
  return async (dispatch, getState) => {
    const sellApi = new SellAPI();
    const guideApi = new GuideApi();
    const {
      cart, sell, discount, customer, authUser, almacenes
    } = getState();
    const msg = ' 🚀 Generando Venta...';
    toastId = toast(msg, {
      autoClose: false,
      type: toast.TYPE.INFO,
      progress: 0.2,
    });
    dispatch(loading('order'));
    try {
      const { sostatus } = sell;
      const { user: { shipping_id, config: { store }}, user } = authUser;
      console.log('shipping_id', shipping_id);
      const realSaleStatus = user.sostatus.find(status => status.value === sostatus || status.id === sostatus);
      const response = await sellApi.create(
        cart,
        discount,
        customer,
        realSaleStatus.id,
        [],
        false,
        shipping_id,
      );
      const { success, message, result } = response;
      if (success) {
        // Create guide
        const { selectedGuide, selectedPacking } = cart;
        if (selectedGuide?.carrier) {
          const createSendRequest = await guideApi.create(result, selectedGuide, customer, selectedPacking);

          if (createSendRequest.success) {
            let storeDirectionId = null;
            if ((!store.direccionid || store.direccionid === undefined || store.direccionid === null) && almacenes.length) {
              const storeIdx = almacenes.findIndex(alm => alm.crmid === store.crmid);
              if (storeIdx !== -1) {
                storeDirectionId = almacenes[storeIdx].treebesdireccionenvioid;
              }
            } else {
              storeDirectionId = store.direccionid;
            }

            await guideApi.createProductRequest(createSendRequest.result.crmid, storeDirectionId, result.LineItems);
          }
        }

        await dispatch(fetchAllOrders());
        await toast.update(toastId, {
          type: toast.TYPE.SUCCESS,
          progress: 0.6,
          autoClose: false,
          render: message.success || message.warning,
        });
        await dispatch(clearCart());
        await dispatch(removePricebook());
        dispatch(fetchAllProducts());
        const { crmid } = result;
        await dispatch(selectOrder(crmid));
        await toast.update(toastId, {
          type: toast.TYPE.INFO,
          progress: 0.9,
          autoClose: false,
          render: 'Mostrando orden ...',
        });

        history.push(`/pos/venta/${crmid}`);
        if (result.noHayDe && Object.keys(result.noHayDe).length > 0) {
          await dispatch(alertProducts(result.noHayDe));
        }
      } else {
        toast.error(message.error);
        await toast.update(toastId, {
          type: toast.TYPE.ERROR,
          progress: 0,
          autoClose: false,
          render: message.error,
        });
      }
    } catch (err) {
      console.log('error', err);
      toast.error('Error en la API');
    } finally {
      dispatch(loaded('order'));
      await setTimeout(() => {
        toast.dismiss(toastId);
      }, 4000);
    }
  };
}

export function editOrder() {
  return async (dispatch, getState) => {
    const orderAPI = new OrderAPI();
    const cotizarApi = new CotizarAPI();
    const {
      cart, discount, customer, authUser,
    } = getState();
    dispatch(loading('sell'));
    dispatch(loading('order'));
    dispatch(loading('product'));
    dispatch(loading('checkout'));
    dispatch(setEditProductsComments(false));
    try {
      const {
        user: { quotestage, sostatus },
      } = authUser;
      const {
        orderSelected: { status, order },
      } = cart;
      let response = {};
      const isOrder = order.sostatus ? true : false;
      if (isOrder) {
        toast.info(' 🚀 Editando Orden...');
        const orderStatus = sostatus.find(estado => estado.value === status);
        const {
          orderSelected: {
            order: { treebesdireccionfacturaid, treebesdireccionenvioid },
          },
        } = cart;
        const facturaId = treebesdireccionfacturaid !== undefined
          ? treebesdireccionfacturaid
          : '';
        const envioId = treebesdireccionenvioid !== undefined ? treebesdireccionenvioid : '';
        const factura = facturaId.split('x').pop();
        const envio = envioId.split('x').pop();
        response = await orderAPI.editOrder(
          cart,
          discount,
          customer,
          [],
          orderStatus.value,
          factura,
          envio,
        );
      } else {
        toast.info(' 🚀 Editando Cotización...');
        response = await cotizarApi.edit(cart, discount, customer, []);
      }
      const { success, message, result } = response;
      if (success) {
        const { crmid } = result;
        toast.success(message.success || message.warning);
        if (status) {
          await dispatch(fetchAllOrders());
          await dispatch(selectOrder(crmid));
          await dispatch(changeTab('posType', 'Historico'));
        } else {
          await dispatch(fetchAllCotizaciones());
          await dispatch(selectCotizacion(crmid));
          await dispatch(changeTab('posType', 'Cotizaciones'));
        }
        await dispatch(resetEditOrder());
        dispatch(fetchAllProducts());
        if (result.noHayDe && Object.keys(result.noHayDe).length > 0) {
          await dispatch(alertProducts(result.noHayDe));
        }
      } else {
        toast.error(message.error);
      }
    } catch (e) {
      toast.error('No se completo la edición');
      toast.warn('Error: ', e);
    } finally {
      dispatch(loaded('sell'));
      dispatch(loaded('order'));
      dispatch(loaded('product'));
      dispatch(loaded('checkout')); /* Hot FIX */
    }
  };
}

export function selectAddressOrder(factura = '', envio = '') {
  let toastId = null;
  return async (dispatch, getState) => {
    const orderAPI = new OrderAPI();
    const cotizarApi = new CotizarAPI();
    dispatch(loading('address'));
    dispatch(loading('order'));
    try {
      const {
        cart, discount, customer, authUser,
      } = getState();
      const {
        user: { quotestage },
      } = authUser;
      const {
        orderSelected: { status },
      } = cart;
      let response = {};
      if (status) {
        const msg = ' 🚀 Cambiando direcciones de la Orden...';
        toastId = toast(msg, {
          autoClose: false,
          type: toast.TYPE.INFO,
          progress: 0.25,
        });
        const sostatus = Object.entries(quotestage).find(
          x => x[1] === status,
        );
        response = await orderAPI.editOrder(
          cart,
          discount,
          customer,
          [],
          sostatus,
          factura,
          envio,
        );
      } else {
        const msg = ' 🚀 Cambiando direcciones de la Cotización...';
        toastId = toast(msg, {
          autoClose: false,
          type: toast.TYPE.INFO,
          progress: 0.25,
        });
        response = await cotizarApi.edit(
          cart,
          discount,
          customer,
          [],
          factura,
          envio,
        );
      }
      const { success, message, result } = await response;
      if (success) {
        const { crmid } = result;
        await toast.update(toastId, {
          type: toast.TYPE.SUCCESS,
          autoClose: 7500,
          render: message.success || message.warning,
          progress: 1,
        });
        if (status) {
          await dispatch(fetchAllOrders());
          await dispatch(selectOrder(crmid));
        } else {
          await dispatch(fetchAllCotizaciones());
          await dispatch(selectCotizacion(crmid));
        }
      } else {
        await toast.update(toastId, {
          type: toast.TYPE.WARNING,
          autoClose: 7500,
          render: message.warning || message.error,
        });
      }
      await setTimeout(() => {
        toast.dismiss(toastId);
      }, 7500);
    } catch (selectAddressOrderErr) {
      console.log({ selectAddressOrderErr });
      toast.warn(
        `Durante el cambio de direcciones surgio un error: ${selectAddressOrderErr}`,
      );
    } finally {
      dispatch(loaded('address'));
      dispatch(loaded('order'));
    }
  };
}

/**
 * Deploy a mda out and a money refund for a single order
 * @param {object} refund Includes payments and crmid order
 */
export function refundAll(refund, all = false) {
  let toastId = null;
  return async (dispatch, getState) => {
    try {
      await dispatch(loading('order'));
      await dispatch(closeModal('refund'));
      const { payments, crmid } = refund;
      const activity = 'Devolver Productos';
      const { table } = getState();
      const selection = table.items.reduce((a, el) => a + el.field, 0);
      const msg = 'Iniciamos devolución espere un momento, favor de revisar los mensajes de cada movimiento.';
      const finalMsg = selection > 0 && payments.length > 0 && all
        ? `${msg} Al finalizar, la orden se cancelará`
        : msg;
      toastId = toast(finalMsg, {
        autoClose: false,
        type: toast.TYPE.INFO,
        progress: 0,
      });
      if (selection > 0) {
        toast.update(toastId, {
          type: toast.TYPE.INFO,
          autoClose: false,
          render: 'Devolviendo movimientos de almacén ...',
          progress: 0.3,
        });
        await dispatch(setActivityToOrder(crmid, activity, false));
      }
      if (payments.length > 0) {
        toast.update(toastId, {
          type: toast.TYPE.INFO,
          autoClose: false,
          render: 'Devolviendo flujos de efectivo ...',
          progress: 0.6,
        });
        await dispatch(refundMoney(payments, crmid, true, false));
      }
      if (selection > 0 && payments.length > 0 && all) {
        toast.update(toastId, {
          type: toast.TYPE.INFO,
          autoClose: false,
          render: 'Devolución total, cancelando orden ...',
          progress: 0.9,
        });
        await dispatch(changeOrderStatus('Cancelled', false));
      }
      await toast.update(toastId, {
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
        render: 'Transacción Realizada',
        progress: 1,
      });
      await setTimeout(() => {
        toast.dismiss(toastId);
      }, 3000);
    } catch (err) {
      console.log('err refundAll : ', err);
    } finally {
      await dispatch(loaded('order'));
    }
  };
}
