/* eslint-disable import/no-cycle */
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import OrderMobile from './OrderMobile';

import {
  fetchAllOrders,
  getNextOrderPage,
} from '../../../redux/actions/orderActions';

const mapStateToProps = (state) => {
  const { authUser, order, loads } = state;
  const loading = Boolean(loads.orderIsLoading);
  return ({
    authUser,
    order,
    loading,
  });
};

const mapDispatchToProps = dispatch => ({
  fetchAll: () => dispatch(fetchAllOrders()),
  getNextPage: page => dispatch(getNextOrderPage(page)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderMobile));
