import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Chip,
} from '@material-ui/core';
import { Place } from '@material-ui/icons';

const AlertChangeAddress = ({
  open,
  handleClose,
  customer,
  setAddress,
}) => {
  const [arr, setArr] = React.useState([]);

  const initAddress = (_customer) => {
    const _arr = _customer.address.map((x) => {
      const _address = { ...x };
      _address.factura = false;
      _address.envio = false;
      return _address;
    });
    setArr(_arr);
  };

  React.useEffect(() => {
    if (open) {
      initAddress(customer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleBooleanParam = (id, keyJson) => {
    const _arr = arr.map((x) => {
      const address = { ...x };
      address[keyJson] = false;
      if (x.crmid === id) address[keyJson] = !address[keyJson];
      return address;
    });
    setArr(_arr);
  };

  const onSubmit = (_arr) => {
    const facturaId = _arr.find(x => x.factura === true).crmid;
    const envioId = _arr.find(x => x.envio === true).crmid;
    setAddress(facturaId, envioId);
  };
  const enableByFactura = Boolean(arr.some(x => x.factura === true));
  const enableByEnvio = Boolean(arr.some(x => x.envio === true));
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Direcciones del cliente
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Notamos que el cliente nuevo anexado tiene dirección de facturación y/o de envío.
            ¿Añadimos las direcciones a la orden/cotización?
          </DialogContentText>
          {arr.length > 0 && (
            <div className="w-100">
              <List>
                {arr.map(el => (
                  <ListItem key={`key_addressCustomer_${el.crmid}`}>
                    <ListItemIcon>
                      <Place />
                    </ListItemIcon>
                    <ListItemText
                      primary={(
                        <div className="w-100 d-flex flex-column">
                          <span>{`${el.direccion} #${el.num_ext}`}</span>
                          <span className="mr-1 d-flex">
                            <Chip
                              color={el.factura ? ('primary') : ('default')}
                              label="Facturación"
                              className="mr-1 chip-small"
                              clickable
                              variant={el.factura ? ('default') : ('outlined')}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleBooleanParam(el.crmid, 'factura');
                              }}
                            />
                            <Chip
                              color={el.envio ? ('secondary') : ('default')}
                              label="Envío"
                              className="chip-small"
                              clickable
                              variant={el.envio ? ('default') : ('outlined')}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleBooleanParam(el.crmid, 'envio');
                              }}
                            />
                          </span>
                        </div>
                    )}
                      secondary={`${el.city} - ${el.state}, ${el.country}`}
                    />
                  </ListItem>
                ))}
              </List>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="inherit" autoFocus>
            No
          </Button>
          <Button
            onClick={() => onSubmit(arr)}
            color="primary"
            disabled={!(enableByFactura && enableByEnvio)}
          >
            Añadir
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

AlertChangeAddress.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  setAddress: PropTypes.func,
  customer: PropTypes.object,
};

AlertChangeAddress.defaultProps = {
  open: false,
  handleClose: () => {},
  setAddress: () => {},
  customer: {},
};

export default AlertChangeAddress;
