/* eslint-disable object-curly-newline */

import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { loading, loaded } from '../../redux/actions/loadActions';

import authUserProps from '../../propTypes/authUserProps';
import loadsProps from '../../propTypes/loadsProps';

import LoadComponent from '../../components/Load/LoadComponent';

const Dashboard = ({ authUser, loads, dispatchLoadingDashboard, dispatchLoadedDashboard }) => (
  <div className="position-relative">
    <iframe
      sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
      src={`https://${authUser.domain}/comercia_dashboard_app`}
      title="dashboard"
      width="100%"
      height="100%"
      style={{ height: '90vh', border: 'none', overflow: 'hidden' }}
      ref={dispatchLoadingDashboard}
      onLoad={dispatchLoadedDashboard}
    />
    { loads.dashboardIsLoading && <LoadComponent /> }
  </div>
);

Dashboard.propTypes = {
  authUser: authUserProps.isRequired,
  loads: loadsProps.isRequired,
  dispatchLoadingDashboard: PropTypes.func.isRequired,
  dispatchLoadedDashboard: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  authUser: state.authUser,
  loads: state.loads,
});

const mapDispatchToProps = dispatch => ({
  dispatchLoadingDashboard: () => dispatch(loading('dashboard')),
  dispatchLoadedDashboard: () => dispatch(loaded('dashboard')),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
