/* eslint-disable object-curly-newline */

import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/core/styles';

import { connect } from 'react-redux';
import { showElement, hideElement } from '../../redux/actions/displayActions';

import LayoutBar from './components/LayoutBar';
import LayoutMenu from './components/LayoutMenu';
import styles from './styles';

const Layout = ({
  theme,
  history,
  classes,
  displays,
  authUser,
  dispatchOpenDashboard,
  dispatchCloseDashboard,
}) => {
  const { user: { logoname, organizationname } } = authUser;
  const { store } = authUser.user.config;
  return (
    <div className={classes.root}>
      <CssBaseline />
      <LayoutBar
        classes={classes}
        logoname={logoname}
        almacen={store}
        organizationname={organizationname}
        openDashboardMenu={dispatchOpenDashboard}
        closeDashboardMenu={dispatchCloseDashboard}
        dashboardMenuIsDisplayed={Boolean(displays.dashboardMenuIsDisplayed)}
      />
      <LayoutMenu
        theme={theme}
        classes={classes}
        logoname={logoname}
        organizationname={organizationname}
        closeDashboardMenu={dispatchCloseDashboard}
        dashboardMenuIsDisplayed={Boolean(displays.dashboardMenuIsDisplayed)}
      />
    </div>
  );
};

Layout.propTypes = {
  theme: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  authUser: PropTypes.object.isRequired,
  displays: PropTypes.object.isRequired,
  dispatchOpenDashboard: PropTypes.func.isRequired,
  dispatchCloseDashboard: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  displays: state.displays,
  authUser: state.authUser,
});

const mapDispatchToProps = dispatch => ({
  dispatchOpenDashboard: () => dispatch(showElement('dashboardMenu')),
  dispatchCloseDashboard: () => dispatch(hideElement('dashboardMenu'))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(Layout)));
