import fetch from 'cross-fetch';
import ConfigurationAPI from './ConfigurationAPI';

class AccountAPI extends ConfigurationAPI {
    getAll = async (page = 1, search = '') => {
      try {
        const response = await fetch(`${this.url}/Accounts?page=${page}&search=${search}`, this.credentials);
        const json = await response.json();
        return json;
      }

      catch {
        return { success: false, message: 'Error en el Host' };
      }
    };

    getSchema = async () => {
      try {
        const response = await fetch(`${this.url}/Accounts/schema`, this.credentials);
        const json = await response.json();
        return json;
      }

      catch {
        return { success: false, message: 'Error en el Host' };
      }
    };

    getAccountById = async (id) => {
      try {
        const response = await fetch(`${this.url}/Accounts?id=${id}`, this.credentials);
        const json = await response.json();
        return json;
      } catch (e) {
        return { success: false, message: 'Error en el Host' };
      }
    };

    getUpdatesById = async (id) => {
      try {
        const response = await fetch(`${this.url}/id/${id}/updates`, this.credentials);
        const json = await response.json();
        return json;
      } catch (e) {
        return { success: false, message: 'Error en el Host' };
      }
    };

    getListEmailsByAccount = async (page = 1, search = '', accountId = 0) => {
      try {
        const response = await fetch(`${this.url}/list/Emails?page=${page}&search=${search}&related_to=${accountId}`, this.credentials);
        const json = await response.json();
        return json;
      }

      catch {
        return { success: false, message: 'Error en el Host' };
      }
    };

    getAccountBalance = async (accountId) => {
      try {
        const response = await fetch(`${this.url}/Accounts/${accountId}/balance`, this.credentials);
        const json = await response.json();
        return json;
      }
      catch {
        return { success: false, message: 'Error en el Host' };
      }
    };

    getAccountContactsEmail = async (accountId) => {
      try {
        const response = await fetch(`${this.url}/list/Contacts/email?related_to=${accountId}`, this.credentials);
        const json = await response.json();
        return json;
      }
      catch {
        return { success: false, message: 'Error en el Host' };
      }
    };

    getAccountContactsPhones = async (accountId) => {
      try {
        const response = await fetch(`${this.url}/list/Contacts/phone?related_to=${accountId}`, this.credentials);
        const json = await response.json();
        return json;
      }
      catch {
        return { success: false, message: 'Error en el Host' };
      }
    };

    getAccountContactsAddress = async (accountId) => {
      try {
        const response = await fetch(`${this.url}/list/Contacts/address?related_to=${accountId}`, this.credentials);
        const json = await response.json();
        return json;
      }
      catch {
        return { success: false, message: 'Error en el Host' };
      }
    };

    getAccountDocuments = async (page = 1, search = '', accountId = 0) => {
      try {
        const response = await fetch(`${this.url}/list/Documents?page=${page}&search=${search}&related_to=${accountId}`, this.credentials);
        const json = await response.json();
        return json;
      }
      catch {
        return { success: false, message: 'Error en el Host' };
      }
    };

    getEmailAttach = async (emailId = 0) => {
      try {
        const response = await fetch(`${this.url}/id/${emailId}`, this.credentials);
        const json = await response.json();
        return json;
      }
      catch {
        return { success: false, message: 'Error en el Host' };
      }
    };

    getEmailBody= async (module, templateId, crmid) => {
      try {
        const response = await fetch(`${this.url}/${module}/plantilla/${templateId}/${crmid}`, this.credentials);
        const json = await response.json();
        return json;
      }

      catch {
        return { success: false, message: 'Error en el Host' };
      }
    };
}
export default AccountAPI;
