import React from 'react';
import './ComingSoon.scss';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHourglassHalf,
} from '@fortawesome/free-solid-svg-icons';

const ComingSoon = ({ title }) => (
  <div className="coming-soon">
    <FontAwesomeIcon
      style={{
        fontSize: 40, display: 'flex', justifyContent: 'center', alignItems: 'center',
      }}
      color="#64707A"
      icon={faHourglassHalf}
    />
    <span className="coming-soon_title">
      { title }
    </span>
  </div>
);

ComingSoon.defaultProps = {
  title: 'Próximamente',
};
ComingSoon.prototype = {
  title: PropTypes.string,
};

export default ComingSoon;
