import React from 'react';
import PropTypes from 'prop-types';
import PricebookMenu from './components/PricebookMenu';

const PriceBook = ({
  auth, type, loading, setPricebook,
}) => (
  <div>
    {type === 'menu' && (
      <PricebookMenu
        auth={auth}
        loading={loading}
        setPricebook={setPricebook}
      />
    )}
  </div>
);

PriceBook.propTypes = {
  auth: PropTypes.object,
  type: PropTypes.string,
  loading: PropTypes.bool,
  setPricebook: PropTypes.func,
};

PriceBook.defaultProps = {
  auth: {},
  type: 'menu',
  loading: false,
  setPricebook: () => {},
};

export default PriceBook;
