import React from 'react';
import PropTypes from 'prop-types';

const RefundProductsTotals = ({
  items,
  tab,
}) => {
  const totalProducts = items.reduce((a, b) => a + b.field, 0);
  return (
    <div className="refund_totals">
      <div className="refund_totals_products">
        {`${tab === 0 ? 'Total' : 'Seleccionados'} : ${totalProducts}`}
      </div>
    </div>
  );
};
RefundProductsTotals.propTypes = {
  items: PropTypes.array.isRequired,
  tab: PropTypes.number.isRequired,
};

export default RefundProductsTotals;
