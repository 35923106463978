/* eslint-disable import/no-cycle */
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ProductMobile from './Product';
import { addProductToCart } from '../../../redux/actions/cartActions';
import {
  fetchAllProducts,
  getNextProductPage,
} from '../../../redux/actions/productActions';

const mapStateToProps = (state) => {
  const { product, loads } = state;
  const loading = Boolean(loads.productIsLoading || loads.subProductsIsLoading);
  return ({
    product,
    loading,
  });
};

const mapDispatchToProps = dispatch => ({
  fetchAll: () => dispatch(fetchAllProducts()),
  getNextPage: page => dispatch(getNextProductPage(page)),
  addCartProduct: (element) => {
    const { crmid, parentid, quantity } = element.dataset;
    dispatch(addProductToCart(parentid, crmid, quantity));
    return true;
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductMobile));
