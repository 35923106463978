/* eslint-disable radix */
import React from 'react';
import PropTypes from 'prop-types';
import { ButtonBase, Zoom } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatMoney } from '../../../../../helpers/tools';
import { haveStock } from '../../../../../helpers/product';
import InputNumber from '../../../../Input/Number';

const ModalOptionItem = ({
  item,
  authUser,
  parentid,
  selectOption,
  changeQtySelected,
  addCartProduct,
  handleClose,
}) => (
  <ButtonBase
    className="productOption_item_button"
    onClick={() => selectOption(item.crmid)}
  >
    <div className={`productOption_item ${item.select ? 'productOption_item_select' : ''}`}>
      <div className="productOption_item_stock">
        <span>{parseInt(item.qtyinstock)}</span>
        <span>Stock</span>
      </div>
      <div className="productOption_item_detail">
        <div
          className="productOption_item_product"
          style={{ width: item.select ? '65%' : '80%' }}
        >
          <div className="productOption_item_label">
            <span>{item.tpoc_id_label}</span>
            <span>{item.opcion ? item.opcion : item.entity_label}</span>
          </div>
          <span>{`$ ${formatMoney(item.totalPrice || item.unit_price)}`}</span>
        </div>
        <div
          className="productOption_item_action"
          style={{ width: item.select ? '35%' : '20%' }}
        >
          {item.select ? (
            <InputNumber
              number={item.qtySelect}
              height="42px"
              width="100%"
              onWarning={Boolean(item.qtySelect >= parseInt(item.qtyinstock))}
              onClickMinus={() => changeQtySelected(item.crmid, item.qtySelect - 1)}
              onClickPlus={() => changeQtySelected(item.crmid, item.qtySelect + 1)}
              onChange={value => changeQtySelected(item.crmid, value)}
            />
          ) : (
            <Zoom in>
              <span>
                <ButtonBase
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleClose();
                    /* if (haveStock(item, authUser)) { */
                      const dataset = {
                        crmid: item.crmid,
                        parentid,
                        quantity: item.quantity,
                      };
                      addCartProduct({ dataset });
                   /*  } */
                  }}
                  className="productOption_item_btnSelect"
                >
                  <FontAwesomeIcon color="#ffffff" size="lg" icon="plus" />
                </ButtonBase>
              </span>
            </Zoom>
          )}
        </div>
      </div>
    </div>
  </ButtonBase>
);

ModalOptionItem.propTypes = {
  parentid: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  authUser: PropTypes.object.isRequired,
  selectOption: PropTypes.func.isRequired,
  changeQtySelected: PropTypes.func.isRequired,
  addCartProduct: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ModalOptionItem;
