import fetch from 'cross-fetch';
import ConfigurationAPI from './ConfigurationAPI';

class OrderAPI extends ConfigurationAPI {
  getAll = async (fde = '', mda = '', sos = '', search = '') => {
    try {
      let params = '';
      const existParams = `${(fde !== '' || mda !== '' || sos !== '') ? ('?') : ('')}`;
      if (existParams === '?') {
        const fdeString = fde !== '' ? (`${fde}&`) : ('');
        const mdaString = mda !== '' ? (`${mda}&`) : ('');
        const sosString = sos !== '' ? (`${sos}&`) : ('');
        params = params.concat(existParams, fdeString, mdaString, sosString);
      }
      const response = await fetch(
        `${this.url}/list/SalesOrder${params}search=${search}`,
        this.credentials,
      );
      const json = await response.json();
      return { ...json };
    } catch {
      return { succes: false, result: [], message: 'Error en la API' };
    }
  };

  editOrder = async (cart, discount, customer, payments = [], sostatus, factura = null, envio = null) => {
    this.credentials.method = 'PUT';

    const { orderSelected: { crmid } } = cart;
    const totalCart = cart.total;
    const totalDiscount = discount.total;
    const totalToPay = Number((totalCart - totalDiscount).toFixed(2));

    const products = [];
    cart.products.forEach((product) => {
      const productid = (product.productid && product.productid.indexOf('x') !== -1)
        ? product.productid.split('x').pop()
        : product.productid;
      const custom = {
        option: [
          {
            product_option_value_id: product.crmid || productid,
          },
        ],
        discount_percent: product.discount_percent,
        price: product.priceWithTaxes === 0 ? (0.01) : (product.priceWithTaxes / (1 - (product.discount_percent / 100))),
        pu: product.unit_price,
        product_id: product.parentId,
        productid: product.crmid || productid,
        quantity: product.quantity,
        comment: product.comment,
      };
      products.push(custom);
    });
    const data = {
      crmid,
      products,
      subtotal: cart.subTotal,
      total: totalToPay,
      account_no: customer.selectedCustomer.account_no,
      tpv_payment: payments,
      tpv_discount_amount: discount.fixed,
      tpv_discount_percentage: discount.percentage,
      order_status: sostatus.id,
      comment: cart.description,
      treebesdireccionenvioid: envio,
      treebesdireccionfacturaid: factura,
    };

    this.credentials.body = JSON.stringify(data);

    const response = await fetch(`${this.url}/SalesOrder`, this.credentials);
    try {
      const json = await response.json();
      return json;
    }
    catch (err) {
      return {
        success: false,
        status: response.status,
        message: response.error.message,
      };
    }
  }

  setStatus = async (crmid, sostatus, isQuote = false) => {
    this.credentials.method = 'PUT';
    let data = null;
    if (isQuote) {
      data = {
        crmid,
        quotestage: sostatus,
      };
    } else {
      data = {
        crmid,
        sostatus,
      };
    }
    this.credentials.body = JSON.stringify(data);
    try {
      let response = null;
      if (isQuote) {
        response = await fetch(
          `${this.url}/Quotes/updateStatus`,
          this.credentials,
        );
      } else {
        response = await fetch(
          `${this.url}/SalesOrder/updateStatus`,
          this.credentials,
        );
      }
      const json = await response.json();
      return json;
    } catch {
      return { succes: false, result: [], message: 'Error en la API' };
    }
  }

  setAccount = async (crmid, accountid, isQuote = false) => {
    this.credentials.method = 'PUT';
    const data = {
      crmid,
      accountid,
    };
    this.credentials.body = JSON.stringify(data);
    try {
      let response = null;
      if (isQuote) {
        response = await fetch(
          `${this.url}/Quotes/updateAccount`,
          this.credentials,
        );
      } else {
        response = await fetch(
          `${this.url}/SalesOrder/updateAccount`,
          this.credentials,
        );
      }
      const json = await response.json();
      return json;
    } catch {
      return { succes: false, result: [], message: 'Error en la API' };
    }
  }

  getFiles = async (crmid, page = 1, search = '') => {
    try {
      this.credentials.method = 'GET';
      delete this.credentials.body;
      const response = await fetch(
        `${this.url}/list/Documents?related_to=${crmid}&page=${page}&search=${search}`,
        this.credentials,
      );
      const json = await response.json();
      return json;
    } catch (err) {
      return { succes: false, result: [], message: err };
    }
  }

  uploadFile = async (crmid, files, isOrder = true, markAsCC = false) => {
    this.credentials.method = 'POST';
    const data = {
      salesorderid: crmid,
      files,
      
    };
    this.credentials.body = JSON.stringify(data);
    try {
      let response = null;
      if (isOrder) {
        response = await fetch(
          `${this.url}/SalesOrder/document`,
          this.credentials,
        );
      } else {
        response = await fetch(
          `${this.url}/Quotes/${crmid}/document`,
          this.credentials,
        );
      }
      const json = await response.json();
      return json;
    } catch {
      return { succes: false, result: [], message: 'Error al subir archivo(s)' };
    }
  }

  deleteFile = async (crmid) => {
    this.credentials.method = 'DELETE';
    try {
      const response = await fetch(`${this.url}/Documents/${crmid}`, this.credentials);
      const json = await response.json();
      return json;
    } catch (err) {
      return { succes: false, result: [], message: `Error al borra un archivo: ${err}` };
    }
  }

  shippingCuots = async (data) => {
    this.credentials.method = 'POST';
    this.credentials.body = JSON.stringify(data);
    try {
      const response = await fetch(
        `${this.url}/get/Rates`,
        this.credentials,
      );
      const json = await response.json();
      return json;
    } catch {
      return { succes: false, result: [], message: 'Error al realizar cotizacion' };
    }
  }

  orderSendRequest = async (orderId) => {
    try {
      const response = await fetch(
        `${this.url}/list/TreebesSolicitudesEnvio?related_to=${orderId}`,
        this.credentials,
      );
      const json = await response.json();
      return json;
    } catch (err) {
      return { succes: false, result: [], message: err };
    }
  }

  orderDeliverRequest = async (storeId) => {
    try {
      const response = await fetch(
        `${this.url}/TreebesSolicitudesEnvio/Pending/${storeId}`,
        this.credentials,
      );
      const json = await response.json();
      return json;
    } catch (err) {
      return { succes: false, result: [], message: err };
    }
  }

  orderDeliverRequestDetail = async (id) => {
    try {
      const response = await fetch(
        `${this.url}/TreebesSolicitudesEnvio/${id}`,
        this.credentials,
      );
      const json = await response.json();
      return json;
    } catch (err) {
      return { succes: false, result: [], message: err };
    }
  }
}

export default OrderAPI;
