import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHourglassHalf,
} from '@fortawesome/free-solid-svg-icons';

const ComingSoonList = ({ title }) => (
  <>
    <ListItem>
      <ListItemIcon classes={{ root: 'mr-0' }}>
        <div className="icon-border">
          <FontAwesomeIcon style={{ fontSize: 15 }} color="#64707A" icon={faHourglassHalf} />
        </div>
      </ListItemIcon>
      <ListItemText
        id="switch-list-label-wifi"
        primary={title}
        classes={{
          primary: 'activities_primary-title',
        }}
      />
    </ListItem>
  </>
);

ComingSoonList.defaultProps = {
  title: 'Próximamente',
};
ComingSoonList.prototype = {
  title: PropTypes.string,
};

export default ComingSoonList;
