/* eslint-disable no-case-declarations */
import {
  ADD_GUIDE,
  ADD_ALL_GUIDE,
  ADD_SELECTED_GUIDE,
} from '../types/guideActions.type';

const INITIAL_STATE = {
  guides: [],
  selectedGuide: {},

};

const guideReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_GUIDE:
      return {
        ...state,
        guides: [...state.guides, action.payload],
      };

    case ADD_ALL_GUIDE:
      return {
        ...state,
        guides: [...action.payload],
      };

    case ADD_SELECTED_GUIDE:
      return {
        ...state,
        selectedGuide: { ...action.payload },
      };

    default:
      return state;
  }
};
export default guideReducer;
