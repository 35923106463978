import React from 'react';
import PropTypes from 'prop-types';
import './ListItemImage.scss';
import { ButtonBase } from '@material-ui/core';
import { Image } from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import VizSensor from 'react-visibility-sensor';

const LazyImage = ({
  showBadge,
  badge,
  image,
}) => {
  const [view, setView] = React.useState(false);
  return (
    <VizSensor
      intervalDelay={500}
      partialVisibility
      onChange={isVisible => setView(isVisible)}
    >
      {view ? (
        <div
          style={{ backgroundImage: `url(${image.replace(/\(/g, '%28').replace(/\)/g, '%29')})` }}
          className="listItemImage_image"
        >
          {showBadge && (
          <div className="listItemImage_badge">
            <FontAwesomeIcon icon={badge} />
          </div>
          )}
        </div>
      ) : (
        <div
          style={{ width: '96px', height: '96px' }}
          className="d-flex align-items-center justify-content-center"
        >
          <Image />
        </div>
      )}
    </VizSensor>
  );
};

LazyImage.propTypes = {
  image: PropTypes.string,
  badge: PropTypes.string,
  showBadge: PropTypes.bool,
};

LazyImage.defaultProps = {
  image: '',
  badge: '',
  showBadge: false,
};

const ListItemImageContent = ({
  title,
  subtitle,
  countNumber,
  countTitle,
  image,
  badge,
  showBadge,
}) => (
  <div className="listItemImage_child">
    <div className="listItemImage_detail">
      {title !== '' && (<div className="listItemImage_title">{title}</div>)}
      {subtitle !== '' && (<div className="listItemImage_subtitle">{subtitle}</div>)}
      <div className="listItemImage_count">
        {countNumber !== '' && (<div className="listItemImage_count_number">{countNumber}</div>)}
        {countTitle !== '' && (<div className="listItemImage_count_title">{countTitle}</div>)}
      </div>
    </div>
    <LazyImage image={image} badge={badge} showBadge={showBadge} />
  </div>
);

ListItemImageContent.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  countNumber: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  countTitle: PropTypes.string,
  image: PropTypes.string,
  badge: PropTypes.string,
  showBadge: PropTypes.bool,
};

ListItemImageContent.defaultProps = {
  title: '',
  subtitle: '',
  countNumber: '',
  countTitle: '',
  image: '',
  badge: '',
  showBadge: false,
};

const ListItemImage = (props) => {
  const {
    type,
    style,
    className,
    onClick,
  } = props;
  if (type === 'button') {
    return (
      <ButtonBase
        style={{ ...style }}
        className={`${className} listItemImage_button`}
        onClick={() => onClick()}
      >
        <ListItemImageContent {...props} />
      </ButtonBase>
    );
  }
  return (
    <div
      style={{ ...style }}
      className={`${className} listItemImage_card`}
    >
      <ListItemImageContent {...props} />
    </div>
  );
};

ListItemImage.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  subtitle: PropTypes.string,
  countNumber: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  countTitle: PropTypes.string,
  badge: PropTypes.string,
  showBadge: PropTypes.bool,
  onClick: PropTypes.func,
  image: PropTypes.string,
};

ListItemImage.defaultProps = {
  type: 'button',
  title: '',
  subtitle: '',
  countNumber: '',
  countTitle: '',
  badge: '',
  showBadge: false,
  onClick: () => {},
  image: '',
  style: {},
  className: '',
};

export default ListItemImage;
