import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
/* Reatcstrap */
import {
  LinearProgress,
  Collapse,
  IconButton,
} from '@material-ui/core';
/* Components */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ListAddress from '../ListAddress';

const CustomerModal = ({
  addAddress,
  cart,
  customer,
  getAddress,
  isOpen,
  loading,
  setAddress,
  title,
  toggle,
}) => {
  React.useEffect(() => {
    if (isOpen) {
      getAddress(customer.selectedCustomer.crmid);
    }
    // eslint-disable-next-line
  }, [isOpen]);
  const orderSelect = Boolean(Object.keys(cart.orderSelected).length > 0);
  const isCustomer = Boolean(Object.keys(customer.selectedCustomer).length > 0);
  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={toggle}
      sizer="lg"
    >
      <MuiDialogTitle toggle={toggle} style={{ position: 'relative' }}>
        Cambiar dirección
        <IconButton
          edge="start"
          color="inherit"
          onClick={async () => toggle()}
          aria-label="close"
          style={{
            width: '48px', height: '48px', position: 'absolute', right: '5px', bottom: '15px',
          }}
        >
          <FontAwesomeIcon color="#313C52" icon="times" />
        </IconButton>
      </MuiDialogTitle>
      <MuiDialogContent>
        <Collapse in={loading}>
          <LinearProgress />
        </Collapse>
        <Collapse in={!loading && orderSelect} unmountOnExit>
          <h4>{title}</h4>
          <ListAddress
            addAddress={addAddress}
            address={customer.address}
            cart={cart}
            customer={isCustomer ? customer.selectedCustomer : {}}
            setAddress={setAddress}
          />
        </Collapse>
      </MuiDialogContent>
    </Dialog>
  );
};

CustomerModal.propTypes = {
  addAddress: PropTypes.func.isRequired,
  cart: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
  getAddress: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  setAddress: PropTypes.func.isRequired,
  title: PropTypes.string,
  toggle: PropTypes.func.isRequired,
};

CustomerModal.defaultProps = {
  title: 'Direcciones',
};

export default CustomerModal;
