/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import './refund.scss';
import { Divider } from '@material-ui/core';
import {
  Done,
  DoneAll,
} from '@material-ui/icons';
import RefundConfirm from './components/RefundConfirm';
import RefundFde from './components/RefundFde';
import RefundProducts from './components/RefundProducts';
import RefundTabs from './components/RefundTabs';

import { tableHelper } from '../../../../helpers';

const RefundConstructor = ({
  authUser,
  dispatchChangeField,
  dispatchFetchFde,
  dispatchResetOrigin,
  fde,
  loading,
  modal,
  navTypes,
  refundAll,
  table,
  orderSelected,
}) => {
  const [tab, setTab] = React.useState(0);

  const [openFde, setOpenFde] = React.useState(false);
  const [fluid, setFluid] = React.useState([]);
  const [toDeliver, setToDeliver] = React.useState(0.0);

  const [methods, setMethods] = React.useState([]);
  const { user: { metodos_pagos } } = authUser;

  const initFluid = (_fluid) => {
    const _toDeliver = Object.keys(orderSelected).length > 0
      ? parseFloat(orderSelected.order.tfde_pagado)
      : tableHelper.getRefundBalance(_fluid);
    const arr = _fluid.reverse();
    const _methods = tableHelper.getRefundMethods(_toDeliver, arr, metodos_pagos);
    setMethods(_methods);
    setToDeliver(_toDeliver);
    setFluid(_fluid.reverse());
  };

  const setOrigin = () => {
    dispatchResetOrigin();
    initFluid(fde);
  };

  const setUniqueMethod = () => {
    const _methods = tableHelper.setUniqueMethod(metodos_pagos, table.items);
    setMethods(_methods);
  };

  const selectMethod = (key, id) => {
    const _methods = tableHelper.setMethod(key, metodos_pagos, methods, id);
    setMethods(_methods);
  };

  const selectSub = (id, tcybid) => {
    const _methods = tableHelper.setSub(methods, id, tcybid);
    setMethods(_methods);
  };

  const selectSingle = (id) => {
    const _methods = methods.map((x) => {
      const el = { ...x };
      if (x.id === id) el.select = !x.select;
      return el;
    });
    setMethods(_methods);
  };

  const selectAndUnSelectAll = () => {
    const select = methods.every(x => x.select === false);
    const _methods = methods.map(x => ({ ...x, select }));
    setMethods(_methods);
  };

  const joinSelection = () => {
    if (methods.filter(x => x.select === true).length > 1) {
      const _methods = tableHelper.joinSelection(methods);
      setMethods(_methods);
    }
  };

  const onChangeMonto = (obj) => {
    const _methods = tableHelper.changeMonto(methods, obj);
    setMethods(_methods);
  };

  const returnSelection = () => {
    if (tab === 0) initFluid(fde);
    else setUniqueMethod();
  };

  React.useEffect(() => {
    if (!loading) initFluid(fde);
    // eslint-disable-next-line
  }, [loading]);

  React.useEffect(() => {
    if (modal) {
      setTimeout(() => {
        dispatchFetchFde();
      }, 500);
    }
    // eslint-disable-next-line
  }, [modal]);

  React.useEffect(() => {
    if (tab === 1) { setUniqueMethod(); }
    // eslint-disable-next-line
  }, [table]);

  const tanOnChange = (e, newValue) => {
    if (newValue === 0) setOrigin();
    else setUniqueMethod();
    setTab(newValue);
  };

  const setFieldChange = (crmdi, value) => {
    dispatchChangeField({ crmdi, value });
  };

  const onSubmit = () => {
    const referencia = table.ref;
    const payments = methods.map(x => ({ ...x, referencia }));
    const crmid = table.toSell;
    const all = Boolean(tab === 0);
    refundAll({ payments, crmid }, all);
  };
  const { items } = table;
  return (
    <div className="refund_container">
      <RefundTabs
        navTypes={navTypes}
        tab={tab}
        tanOnChange={tanOnChange}
      />
      <div className="refund_layout">
        <RefundFde
          fluid={fluid}
          joinSelection={joinSelection}
          loading={loading}
          methods={methods}
          metodos_pagos={metodos_pagos}
          modal={modal}
          openFde={openFde}
          toDeliver={toDeliver}
          returnSelection={returnSelection}
          selectAndUnSelectAll={selectAndUnSelectAll}
          selectMethod={selectMethod}
          selectSingle={selectSingle}
          selectSub={selectSub}
          setOpenFde={setOpenFde}
          onChangeMonto={onChangeMonto}
        />
        <Divider className="w-100 my-1" />
        <RefundProducts
          items={items}
          loading={loading}
          setFieldChange={setFieldChange}
          tab={tab}
        />
        <RefundConfirm
          tab={tab}
          onSubmit={onSubmit}
          loading={loading}
        />
      </div>
    </div>
  );
};

RefundConstructor.propTypes = {
  authUser: PropTypes.object.isRequired,
  orderSelected: PropTypes.object.isRequired,
  dispatchChangeField: PropTypes.func.isRequired,
  dispatchFetchFde: PropTypes.func.isRequired,
  dispatchResetOrigin: PropTypes.func.isRequired,
  fde: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  modal: PropTypes.bool.isRequired,
  navTypes: PropTypes.array,
  refundAll: PropTypes.func.isRequired,
  table: PropTypes.object.isRequired,
};

RefundConstructor.defaultProps = {
  navTypes: [
    {
      label: 'Total',
      icon: <DoneAll />,
      tooltip: 'Devolución total de productos y flujos de efectivo, al finalizar la orden se cancelará',
    },
    {
      label: 'Parcial',
      icon: <Done />,
      tooltip: 'Devolución parcial, esta opción habilita las cantidades a devolver al igual que el monto. Esta opción no cancela la orden',
    },
  ],
};

export default RefundConstructor;
