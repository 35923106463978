import React from 'react';
import './EmailContainer.scss';
// import PropTypes from 'prop-types';
import {
  List,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInbox,
} from '@fortawesome/free-solid-svg-icons';
import EmailItem from './components/EmailItem/EmailItem';
import EmailModalDetail from './components/EmailModal/EmailModalDetail';


const EmailContainer = ({ data }) => {
  const [open, setOpen] = React.useState(false);
  const [selectedEmail, setSelectedEmail] = React.useState(null);

  const handleClickOpen = (item) => {
    setSelectedEmail(item);
    setOpen(true);
  };

  const handleClose = () => {    
    setOpen(false);
  };

  return (
    <div className="email-container">
      {
          data.length > 0
            ? (
              <List classes={{ root: 'py-0' }} className="email-list-container">
                {data.map(email => (
                  <EmailItem email={email} key={email.crmid} clickAction={() => handleClickOpen(email)} />
                ))}
              </List>

            )
            : (
              <div className="empty-email">
                <FontAwesomeIcon
                  style={{
                    fontSize: 40, display: 'flex', justifyContent: 'center', alignItems: 'center',
                  }}
                  color="#64707A"
                  icon={faInbox}
                />
                <span>No hay correos </span>
              </div>
            )
        }
      <EmailModalDetail open={open} onClose={handleClose} email={selectedEmail} />
    </div>
  ); };

// EmailContainer.prototype = {};

export default EmailContainer;
