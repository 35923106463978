
import { GET_CLIENTS, GET_CLIENTS_PAGINATOR, GET_CLIENT_DETAIL } from '../types/clientActions.type';

const INITIAL_STATE = {
  clients: { data: [], pages: 1 },
  selectClient: null,
};

const clientReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CLIENTS:
      return {
        ...state,
        clients: { data: [...action.payload.result], pages: action.payload.pages },
      };
    case GET_CLIENTS_PAGINATOR:
      return {
        ...state,
        clients: { data: [...state.clients.data, ...action.payload.result], pages: action.payload.pages },
      };
    case GET_CLIENT_DETAIL:
      return {
        ...state,
        selectClient: action.payload,
      };

    default:
      return state;
  }
};
export default clientReducer;
