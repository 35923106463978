/* eslint-disable import/no-cycle */
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import OrderDetail from './OrderDetail';

import { selectOrder } from '../../../../../redux/actions/orderActions';

const mapStateToProps = (state) => {
  const { loads } = state;
  const loading = Boolean(loads.orderIsLoading);
  return ({ loading });
};

const mapDispatchToProps = dispatch => ({
  setOrder: crmid => dispatch(selectOrder(crmid)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderDetail));
