/* eslint-disable import/no-cycle */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  isMobile,
  // isAndroid,
  MobileView,
  BrowserView,
} from 'react-device-detect';

import { Container } from 'reactstrap';
// import Grid from '@material-ui/core/Grid';

// import tabsProps from '../../propTypes/tabsProps';
// import productProps from '../../propTypes/productProps';
import loadsProps from '../../propTypes/loadsProps';

// import PosItemContainerAction from './components/PosItemContainerAction';
// import PosItemContainerActionMovile from './components/PosItemContainerActionMovile';
// import Product from '../../components/Product';
// import Order from '../../components/Order';
// import Cotizacion from '../../components/Cotizacion';
// import Timbre from '../../components/Timbre';

// import Customer from '../../components/Customer/index';
// import Cart from '../../components/Cart';
// import PosCartContainerAction from './components/PosCartContainerAction';
// import PosMobileActionsBar from './components/PosMobileActionsBar';

import PosMobile from './components/PosMobile';

import LoadComponent from '../../components/Load/LoadComponent';

class Pos extends PureComponent {
  static propTypes = {
    loads: loadsProps.isRequired,

    /* news */
    dispatchFetchAll: PropTypes.func.isRequired,

    // dispatchCloseModals: PropTypes.func.isRequired,
  };

  async componentDidMount() {
    if (isMobile) {
      const { dispatchFetchAll } = this.props;
      dispatchFetchAll();
    }
  }

  render() {
    const { loads } = this.props;
    return (
      <div className={isMobile ? 'pos w-100' : 'pos'}>
        <BrowserView>
          <Container>
            Versión mobile
          </Container>
        </BrowserView>
        <MobileView>
          <PosMobile />
        </MobileView>
        {loads.posIsLoading && <LoadComponent />}
      </div>
    );
  }
}

export default Pos;
