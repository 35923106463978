/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async/';
import { connect, useDispatch, useSelector } from 'react-redux';
import CustomerAPI from '../../../api/CustomerAPI';
import {
  selectCustomer,
} from '../../../redux/actions/customerActions';
import { handleEditOrder } from '../../../redux/actions/orderActions';
import { updateProductsInCartWhenChangePriceBook } from '../../../redux/actions/cartActions';

let timeout = null;

const SearchCustomer = ({
  customer,
  single,
  control,
  disabled,
  menuList,
  container,
  orderSelected,
  dispatchEditOrder,
  dispatchSelectCustomer,
}) => {
  const dispatch = useDispatch();
  const { enableEditOrder } = useSelector(state => state.order);
  const { customers, selectedCustomer } = customer;
  const [customerSelection, setCustomerSelection] = React.useState({});

  const setCustomer = (_customer) => {
    setCustomerSelection(
      {
        id: _customer.crmid,
        label: _customer.accountname,
      },
    );
  };

  React.useEffect(() => {
    if (selectedCustomer !== undefined) {
      setCustomer(selectedCustomer);
    }
  }, [selectedCustomer]);

  const onSelect = async (selected) => {
    const { entity } = selected;
    if (entity.crmid !== selectedCustomer.crmid) {
      if (orderSelected && Object.keys(orderSelected).length > 0 && !enableEditOrder) {
        await dispatch(handleEditOrder());
      }
      await dispatchSelectCustomer(entity);
      await dispatch(updateProductsInCartWhenChangePriceBook(entity.pricebookid));
    }
  };

  const onFormattData = (data) => {
    const dataFormatted = data.map(x => ({
      value: x.crmid,
      label: x.accountname,
      entity: x,
    }));
    return dataFormatted;
  };

  const onSearch = (value, callback) => new Promise(() => {
    const customerApi = new CustomerAPI();
    customerApi.getAccountByName(value).then((response) => {
      if (response.success) {
        callback(onFormattData(response.result));
      }
    });
  });

  const formattedCustomer = customers.map(x => ({
    value: x.crmid,
    label: x.accountname,
    entity: x,
  }));
  return (
    <AsyncSelect
      tabIndex="0"
      maxMenuHeight={200}
      onChange={onSelect}
      isDisabled={disabled}
      value={customerSelection}
      defaultOptions={formattedCustomer}
      placeholder="Cambia de cliente"
      inputId="select_customer_searchbar"
      styles={{
        singleValue: base => ({
          ...base,
          ...single,
        }),
        control: base => ({
          ...base,
          ...control,
        }),
        container: base => ({
          ...base,
          ...container,
        }),
        menuList: base => ({
          ...base,
          ...menuList,
        }),
      }}
      loadOptions={(value, callback) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => onSearch(value, callback), 500);
      }}
    />
  );
};

SearchCustomer.propTypes = {
  customer: PropTypes.object.isRequired,
  single: PropTypes.object,
  control: PropTypes.object,
  container: PropTypes.object,
  menuList: PropTypes.object,
  orderSelected: PropTypes.object,
  disabled: PropTypes.bool,
  dispatchSelectCustomer: PropTypes.func.isRequired,
  dispatchEditOrder: PropTypes.func.isRequired,
};
SearchCustomer.defaultProps = {
  single: {},
  control: {},
  container: {},
  menuList: {},
  orderSelected: {},
  disabled: false,
};

const mapStateToProps = state => ({
  customer: state.customer,
});

const mapDispatchToProps = dispatch => ({
  dispatchSelectCustomer: customer => dispatch(selectCustomer(customer)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchCustomer);
