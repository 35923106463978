import React from 'react';
import PropTypes from 'prop-types';
import { toolsHelper } from '../../../../../helpers';

const RefundFdeTotals = ({
  methods,
  balance,
}) => {
  const reduce = methods.map(x => x.monto).reduce((a, m) => (a + m), 0);
  const equal = balance - reduce;
  return (
    <div className="refund_fde_totals">
      <span>{`Balance: $${toolsHelper.formatMoney(equal)}`}</span>
      <span>{`Monto: $${toolsHelper.formatMoney(reduce)}`}</span>
    </div>
  );
};

RefundFdeTotals.propTypes = {
  methods: PropTypes.array.isRequired,
  balance: PropTypes.number,
};

RefundFdeTotals.defaultProps = {
  balance: 0.0,
};

export default RefundFdeTotals;
