/* eslint-disable camelcase */
/* eslint-disable import/no-cycle */

import { connect } from 'react-redux';
import { changeTab } from '../../redux/actions/tabActions';
import {
  fetchSubProducts,
  getProductsByPage,
  fetchAllProducts,
} from '../../redux/actions/productActions';
import { addProductToCart, clearCart } from '../../redux/actions/cartActions';
import {
  selectOrder,
  fetchAllOrders,
} from '../../redux/actions/orderActions';
import { toggleModal, openModal, closeModals } from '../../redux/actions/modalActions';
import { addKeyboardItem, emptyKeyBoardItems } from '../../redux/actions/keyBoardActions';
import { fetchAll, openModalAndGetSubProducts } from '../../redux/actions/posActions';
import {
  selectCotizacion,
  fetchAllCotizaciones,
} from '../../redux/actions/cotizarActions';

import Pos from './Pos';

const mapStateToProps = state => ({
  cart: state.cart,
  tabs: state.tabs,
  order: state.order,
  loads: state.loads,
  modals: state.modals,
  product: state.product,
  authUser: state.authUser,
  keyBoard: state.keyBoard,
  cotizacion: state.cotizacion,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangeTabDisplayPosToProduct: () => dispatch(changeTab('posProduct', 'product')),
  dispatchChangeTabDisplayPosToCart: () => dispatch(changeTab('posProduct', 'cart')),

  dispatchAddProductToCart: (element) => {
    const { crmid, parentid, quantity } = element.dataset;
    dispatch(addProductToCart(parentid, crmid, quantity));
    return true;
  },
  dispatchGetProductByPage: i => dispatch(getProductsByPage(i)),

  dispatchOpenModal: modal => dispatch(openModal(modal)),
  dispatchToggleModal: modal => dispatch(toggleModal(modal)),

  dispatchAddKeyboardItem: item => dispatch(addKeyboardItem(item)),
  dispatchEmptyKeyBoardItems: () => dispatch(emptyKeyBoardItems()),

  /* news */
  dispatchFetchAll: () => dispatch(fetchAll()),
  dispatchFetchAllProducts: () => dispatch(fetchAllProducts()),
  dispatchFetchSubProducts: crmid => dispatch(fetchSubProducts(crmid)),
  dispatchFetchAllOrders: () => dispatch(fetchAllOrders()),
  dispatchOpenModalAndGetSubProducts: (modal, products, crmid = null) => dispatch(openModalAndGetSubProducts(modal, products, crmid)),

  /* Refactor Orders */
  dispatchSelectOrder: (crmid) => { dispatch(selectOrder(crmid)); },
  dispatchClearCart: () => { dispatch(clearCart()); },

  /* Cotizaciones Actions */
  dispatchSelectCotizacion: (crmid) => { dispatch(selectCotizacion(crmid)); },
  dispatchFetchAllCotizaciones: () => dispatch(fetchAllCotizaciones()),

  dispatchCloseModals: () => dispatch(closeModals()),

});

export default connect(mapStateToProps, mapDispatchToProps)(Pos);
