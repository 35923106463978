import React from 'react';
import PropTypes from 'prop-types';
/* Material */
import { Button, Collapse, LinearProgress } from '@material-ui/core';
/* Reactstrap */
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';

/* ReactToPrint */
import ReactToPrint from 'react-to-print';

import TicketBillToPrint from './tickets/TicketBillToPrint';

const TicketsModalBill = ({
  authUser,
  isLoading,
  isOpen,
  print,
  timbrar,
  title,
  toggle,
}) => {
  const toPrintRef = React.useRef();
  //   React.useEffect(() => {
  //     if (isOpen) {
  //       setTimeout(() => {
  //         const btnPrint = document.querySelector('#button_to_print');
  //         // @ts-ignore
  //         if (btnPrint) btnPrint.click();
  //       }, 500);
  //     }
  //   }, [isOpen]);
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="sm" className="tickets_modal">
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody className="tickets_modal_body w-100 d-flex justify-content-center">
        {isLoading && <LinearProgress />}
        <Collapse in={!isLoading}>
          <TicketBillToPrint
            timbrar={timbrar}
            authUser={authUser}
            ref={toPrintRef}
          />
        </Collapse>
      </ModalBody>
      <ModalFooter>
        <ReactToPrint
          removeAfterPrint
          copyStyles
          trigger={() => (
            <Button
              id="button_to_print"
              color="primary"
              className="w-100"
              variant="contained"
            >
              {print}
            </Button>
          )}
          content={() => toPrintRef.current}
        />
      </ModalFooter>
    </Modal>
  );
};

TicketsModalBill.propTypes = {
  authUser: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  print: PropTypes.string,
  timbrar: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
};
TicketsModalBill.defaultProps = {
  print: 'Imprimir Factura',
};

export default TicketsModalBill;
