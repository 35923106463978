/* eslint-disable import/no-cycle */
import { connect } from 'react-redux';
import PosMobileActionsBar from './PosMobileActionsBar';

import { addItemsToTable } from '../../../../redux/actions/tableActions';
import { changeTab } from '../../../../redux/actions/tabActions';
import { clearCart } from '../../../../redux/actions/cartActions';
import { fetchTimbrado } from '../../../../redux/actions/TimbrarActions';
import { fetchListQuotes } from '../../../../redux/actions/cotizarActions';
import {
  changeOrderStatus,
  fetchAllOrders,
} from '../../../../redux/actions/orderActions';
import { fetchAllProducts } from '../../../../redux/actions/productActions';
import { openModal, closeModal } from '../../../../redux/actions/modalActions';
import { saveSell, changeSalesOrderStatus } from '../../../../redux/actions/sellActions';
import { sendEmail } from '../../../../redux/actions/posActions';
import { setOrderFilter } from '../../../../redux/actions/authUserActions';

const mapStateToProps = (state) => {
  const { cart, authUser, loads } = state;
  const loading = Boolean(loads.profileIsLoading);
  return ({
    cart,
    loading,
    authUser,
  });
};

const mapDispatchToProps = dispatch => ({
  /* OLD WAy */
  /* dispatchFilterOrders: word => dispatch(filterOrder(word)), */
  /* dispatchFilterProducts: word => dispatch(filterProducts(word)), */
  /* dispatchFilterCotizaciones: word => dispatch(filterCotizacion(word)), */

  dispatchFilterOrders: word => dispatch(fetchAllOrders(word)),
  dispatchFilterProducts: word => dispatch(fetchAllProducts(word)),
  dispatchFilterCotizaciones: word => dispatch(fetchListQuotes(1, word)),

  dispatchChangeTabToProduct: () => {
    dispatch(changeTab('posProduct', 'product'));
  },
  dispatchClearCart: () => {
    dispatch(clearCart());
  },
  dispatchMakeSell: (orderStatus) => {
    dispatch(changeSalesOrderStatus(orderStatus));
    dispatch(saveSell());
  },
  dispatchFetchTimbrado: crmid => dispatch(fetchTimbrado(crmid)),
  dispatchOpenRefundModal: () => {
    dispatch(openModal('refund'));
    dispatch(addItemsToTable('refund'));
  },
  dispatchSetOrderStatus: sostatus => dispatch(changeOrderStatus(sostatus)),
  dispatchOpenCheckOutRefunModal: () => dispatch(openModal('checkoutRefound')),
  dispatchOpenSendEmaillModal: () => dispatch(openModal('sendEmail')),
  dispatchCloseSendEmaillModal: () => dispatch(closeModal('sendEmail')),
  dispatchSendEmail: data => dispatch(sendEmail(data)),

  setFilter: option => dispatch(setOrderFilter(option, false)),
  fetchOrders: () => dispatch(fetchAllOrders()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PosMobileActionsBar);
