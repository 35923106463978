import React, { useState } from 'react';
import PropTypes from 'prop-types';

/* Material */
import { Button } from '@material-ui/core';

const AmountCheckOut = ({ title, onSubmit, enableSubmit }) => {
  const [loading, setLoading] = useState(false);
  const handleSubmit = () => {
    setLoading(true);
    onSubmit();
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  };

  return (
    <div className="w-100 pt-4 h-auto amount_checkout">
      <Button
        id="checkout_submit"
        onClick={() => handleSubmit()}
        tabIndex={-1}
        disabled={loading || !enableSubmit}
      >
        {title}
      </Button>
    </div>
  );
};
AmountCheckOut.propTypes = {
  title: PropTypes.string,
  enableSubmit: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

AmountCheckOut.defaultProps = {
  title: 'Cobrar',
  enableSubmit: false,
};

export default AmountCheckOut;
