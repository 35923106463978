import React, { Component } from "react";
import List from '@material-ui/core/List';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import HeaderTop from "./components/HeaderTop";
import CallItem from "./components/CallItem";

const options = [
    { id: 1, name: 'Juan', icon: 'call', phone: '123456789' },
    { id: 2, name: 'Leon', icon: 'call', phone: '123456789' },
    { id: 3, name: 'Mario', icon: 'call', phone: '123456789' },
    { id: 4, name: 'Rosa', icon: 'call', phone: '123456789' },
    ];

class ClientCall extends Component {
    render() {
        return (
            <>
                <HeaderTop history={this.props.history} />
                <div className="container">
                    <List>
                        {options.map(option =>
                            <CallItem key={option.id} call={option} />
                        )}
                    </List>
                </div>
            </>
        )
    }
}
export default withRouter(connect()(ClientCall));
