import fetch from 'cross-fetch';
import ConfigurationAPI from './ConfigurationAPI';

class FdeAPI extends ConfigurationAPI {
    get = async (id) => {
      const url = `${this.url}/list/TreebesFdE?related_to=${id}`;
      try {
        const response = await fetch(url, this.credentials);
        const json = await response.json();
        return { ...json };
      } catch (getErr) {
        return {
          success: false,
          result: [],
          message: `Error al crear una dirección: ${getErr}`,
        };
      }
    }
}

export default FdeAPI;
