import React from 'react';
import PropTypes from 'prop-types';
/* SwipableViews */
import SwipeableViews from 'react-swipeable-views';

const AmountSelect = ({
  payIndex,
  setPayIndex,
  newPaymentTypes,
}) => (
  <SwipeableViews
    axis="x"
    resistance
    index={payIndex}
    enableMouseEvents
    onChangeIndex={index => setPayIndex(index)}
    className="h-100 payments"
  >
    {newPaymentTypes.map((x, i) => (x.id !== '') && (
      <div
        id={`key_payment_${i}`}
        key={`key_payment_${x.id}`}
        className="d-flex flex-column align-items-start paymentType"
      >
        <div className="paymentType_id">{x.id}</div>
        <div className="paymentType_ref">{x.referencia}</div>
      </div>
    ))}
  </SwipeableViews>
);

AmountSelect.propTypes = {
  payIndex: PropTypes.number,
  setPayIndex: PropTypes.func.isRequired,
  newPaymentTypes: PropTypes.array.isRequired,
};
AmountSelect.defaultProps = {
  payIndex: 0,
};

export default AmountSelect;
