import React from 'react';
import PropTypes from 'prop-types';

import CustomerModal from './modals/CustomerModal';

const Address = ({
  cart,
  customer,
  dispatchAddAddressByCustomer,
  dispatchGetAddresByCustomer,
  dispatchSelectAddres,
  dispatchToggleModal,
  loads,
  modals,
  type,
}) => {
  const orderSelect = Boolean(Object.keys(cart.orderSelected).length > 0);
  return (
    <div>
      {type === 'customer' && (
      <CustomerModal
        customer={customer}
        cart={cart}
        title={orderSelect ? (`Direcciones predeterminadas de la orden ${cart.orderSelected.order.label}`) : ''}
        addAddress={dispatchAddAddressByCustomer}
        setAddress={dispatchSelectAddres}
        getAddress={dispatchGetAddresByCustomer}
        loading={Boolean(loads.addressIsLoading)}
        isOpen={Boolean(modals.addressCustomerModalIsOpen)}
        toggle={() => dispatchToggleModal('addressCustomer')}
      />
      )}
    </div>
  );
};

Address.propTypes = {
  cart: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
  dispatchAddAddressByCustomer: PropTypes.func.isRequired,
  dispatchGetAddresByCustomer: PropTypes.func.isRequired,
  dispatchSelectAddres: PropTypes.func.isRequired,
  dispatchToggleModal: PropTypes.func.isRequired,
  loads: PropTypes.object.isRequired,
  modals: PropTypes.object.isRequired,
  type: PropTypes.string,
};

Address.defaultProps = {
  type: 'customer',
};

export default Address;
