import React from 'react';
import PropTypes from 'prop-types';
import { ButtonBase, Zoom } from '@material-ui/core';
import { ShoppingCart } from '@material-ui/icons';
import { Link } from 'react-router-dom';

const CartFab = ({ cart }) => (
  <Zoom in>
    <ButtonBase
      className="pos_cart_fab"
      component={Link}
      to="/pos/cart"
    >
      <div className={cart.totalProducts > 0 ? 'pos_cart_fab_op' : 'pos_cart_fab_cl'}>
        {cart.totalProducts}
      </div>
      <ShoppingCart />
    </ButtonBase>
  </Zoom>
);

CartFab.propTypes = {
  cart: PropTypes.object.isRequired,
};

export default CartFab;
