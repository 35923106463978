import React from 'react';
import PropTypes from 'prop-types';

import {
  Menu,
  List,
  Checkbox,
  MenuItem,
  ListItem,
  ListItemText,
} from '@material-ui/core';

const FilterSettings = ({
  title,
  value,
  filter,
  loading,
  options,
  disabled,
  setFilter,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [list, setList] = React.useState([]);

  React.useEffect(() => {
    setList(value);
  }, [value]);

  function handleList(e) {
    const { currentTarget } = e;
    setAnchorEl(currentTarget);
  }

  function setOption(item) {
    let newArray = [];
    if (list.some(x => x.value === item.value)) {
      newArray = list.filter(x => x.value !== item.value);
    } else {
      newArray = [...list, item];
    }
    const option = { values: newArray, filter };
    setFilter(option);
  }

  return (
    <>
      <List component="nav" aria-label="Device settings">
        <ListItem
          dense
          button
          disabled={disabled}
          aria-label={title}
          onClick={handleList}
          aria-haspopup="true"
          aria-controls="FilterState"
        >
          <ListItemText
            primary={title}
            secondary={value.map(x => x.label).join(', ')}
          />
        </ListItem>
      </List>
      <Menu
        id="FilterState"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {options.map(option => (
          <MenuItem
            key={`key_option_${option.label}`}
            disabled={loading || disabled}
            onClick={() => {
              setOption(option);
            }}
          >
            <Checkbox
              checked={value.some(x => x.value === option.value)}
            />
            <span>{option.label}</span>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

FilterSettings.propTypes = {
  title: PropTypes.string,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  value: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  filter: PropTypes.string.isRequired,
  setFilter: PropTypes.func.isRequired,
};

FilterSettings.defaultProps = {
  title: 'Filtrar ...',
  options: [],
  disabled: false,
};

export default FilterSettings;
