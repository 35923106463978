/* eslint-disable array-callback-return */
import {
  Button,
  Collapse,
  LinearProgress,
  Paper,
  Tooltip,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import InputNumber from '../../../Input/Number';
import { formatString } from '../../../../helpers/tools';
import useSound from '../../../../hooks/sound/useSound';

const ReceiverTransferProducts = ({
  products,
  changeInput,
  rest,
  add,
  loading,
  selectAll,
  selectNone,
  disableQty,
}) => {
  const { playSound } = useSound();

  const getPartidas = () => {
    let count = 0;
    products.map((item) => {
      if (item.por_recibir !== 0) {
        count += 1;
      }
    });

    return count;
  };

  const getCantidad = () => {
    let count = 0;
    count = products.reduce((acc, item) => acc + item.por_recibir, 0);

    return count;
  };

  const handleReadBarCode = (e) => {
    const barCode = localStorage.getItem('barCode') || '';
    // const barCode = e.target.value;
    if (e.keyCode === 13 && barCode && products.length) {
      const findIndex = products.findIndex(prod => prod.codigo_barras === barCode);
      if (findIndex !== -1) {
        // Product
        const item = products[findIndex];
        if (item.cantidad >= item.por_recibir + 1) {
          add(item.treebesmdaid);
          playSound('success');
          toast.success('Producto seleccionado');
          localStorage.setItem('barCode', '');
        } else {
          playSound('error');
          toast.warn('Cantidad máxima agregada');
          localStorage.setItem('barCode', '');
        }
      } else {
        playSound('invalid');
        toast.error('No existe el codigo');
        localStorage.setItem('barCode', '');
      }
    } else {
      localStorage.setItem('barCode', `${barCode}${e.key}`);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleReadBarCode, false);
    localStorage.setItem('barCodeT', '');
    return () => {
      document.removeEventListener('keydown', handleReadBarCode, false);
      localStorage.setItem('barCode', '');
    };
  }, [products]);

  return (
    <div className="deliver__products">
      <div className="d-flex flex-column justify-content-between align-items-start mb-2">
        <h4>Productos</h4>
        <div className="d-flex">
          <div className="d-flex justify-content-center align-items-center mr-2">
            <span className="mr-2">
              Partidas:
              {' '}
              {getPartidas()}
            </span>
            <span>
              Cantidad:
              {' '}
              {getCantidad()}
            </span>
          </div>
          {
            disableQty ? null : (
              <>
                <Button
                  disabled={loading}
                  className="mr-1"
                  variant="outlined"
                  onClick={selectAll}
                >
                  Recibir todo
                </Button>
                <Button disabled={loading} variant="outlined" onClick={selectNone}>
                  Recibir nada
                </Button>
              </>
            )
          }
        </div>
      </div>
      <Collapse in={loading}>
        <LinearProgress />
      </Collapse>
      <Paper className="w-100 product-list" style={{ maxHeight: '55vh' }}>
        {products.length > 0
        && products.map(item => (
          <div className="refund_product" key={item.productcode} style={{ borderBottom: '2px solid darkgray' }}>
            <div className="refund_product_detail">
              <div className="refund_product_title full-width">
                <Tooltip placement="left-start" title={item.productname || ''}>
                  <span>{formatString(item.productname)}</span>
                </Tooltip>
              </div>
              <div className="refund_product_desc">
                {`Por Recibir: ${item.cantidad}`}
              </div>
              <div className="refund_product_desc">
                {`codigo: ${item.productcode}`}
              </div>
            </div>
            <div
              className="refund_product_qty"
              style={{
                display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column',
              }}
            >
              <div>
                <span style={{ whiteSpace: 'nowrap', width: '100%' }}>
                  {`Cant: ${item.cantidad}`}
                </span>
              </div>
              <InputNumber
                number={item.por_recibir}
                disabledMinus={item.cantidad === 0 || item.por_recibir === 0 || disableQty}
                disabledPlus={item.cantidad === item.por_recibir || disableQty}
                onClickMinus={() => rest(item.treebesmdaid)}
                onClickPlus={() => add(item.treebesmdaid)}
                circleDiameter="48px"
                onChange={(value) => {
                  changeInput(parseFloat(value), item.treebesmdaid);
                }}
                onWarning={item.field === 0}
                onDanger={item.por_recibir > item.cantidad}
                disabledInput={disableQty}
              />
            </div>
          </div>
        ))}
      </Paper>
    </div>
  ); };

ReceiverTransferProducts.propTypes = {
  products: PropTypes.array,
  loading: PropTypes.bool,
  changeInput: PropTypes.func,
  rest: PropTypes.func,
  add: PropTypes.func,
  selectAll: PropTypes.func,
  selectNone: PropTypes.func,
};

ReceiverTransferProducts.defaultProps = {
  products: [],
  loading: false,
  changeInput: () => {},
  rest: () => {},
  add: () => {},
  selectAll: () => {},
  selectNone: () => {},
};

export default ReceiverTransferProducts;
