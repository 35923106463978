/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-cycle */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft, faEllipsisV,
  faClipboardCheck, faChevronDown,
} from '@fortawesome/free-solid-svg-icons';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import { Popover } from '@material-ui/core';

import { toast } from 'react-toastify';
import moment from 'moment';
import Flag from '../../../../components/Flag';
import Iconwidthbg from '../../../../components/Icons/IconWidthBG/IconWidthBG';
import {
  getColorTaskStatus,
} from '../../../../helpers/tools';
import TaskApi from '../../../../api/TaskApi';
import SearchByType from '../SearchByType';
import { closeModal, openModal } from '../../../../redux/actions/modalActions';
import { setAllProjects, setAllUsers } from '../../../../redux/actions/taskActions';

const AddActivity = () => {
  const history = useHistory();

  const taskApi = new TaskApi();
  const {
    register, formState: { errors }, control, handleSubmit,
  } = useForm();
  const [openDrawer, setOpenDrawerBar] = React.useState(false);
  const [anchorEl, setAchorEl] = React.useState(null);
  const [project, setProject] = React.useState([]);
  const [projects, setProjects] = React.useState([]);
  const [user, setUser] = React.useState([]);
  const [type, setType] = React.useState('');
  const open = Boolean(anchorEl);
  const noImage = `${process.env.PUBLIC_URL}/img/ava.png`;
  const modals = useSelector(state => state.modals);
  const taskStorage = useSelector(state => state.tasks);
  const {
    selectedUser: selectedClient,
    selectedProject,
    projects: taskProjects,
    users,
  } = taskStorage;
  const authUser = useSelector(state => state.authUser.user);
  const activityStatus = useSelector(state => state.authUser.user.pos_filter_projecttaskstatus_values);
  const dispatch = useDispatch();
  const [selectedStatus, setSelectedStatus] = React.useState(activityStatus ? activityStatus[0] : {});



  const createTask = async (task) => {
    const response = await taskApi.createTask(task);
    const { success } = response;
    if (success) {
      toast.info('Tarea agregada');
    } else {
      toast.info('No se pudo agregar la tarea');
    }
  };

  const onSubmit = async (data, e) => {
    const isBeforeToday = moment(data.fecha).isBefore(moment().toDate());
    const isBeforeStartDate = moment(data.fechaFin).isBefore(moment(data.fechaInicio));
    if (isBeforeToday) {
      toast.error('La fecha debe ser posterior al dia actual');
      return;
    }
    // Verificar proyecto
    if (!selectedProject || !Object.keys(selectedProject).length > 0) {
      toast.error('Debe seleccionar un proyecto antes de crear la tarea');
      return;
    }

    if (isBeforeStartDate) {
      toast.error('La fecha inicial debe ser menor a la fecha final');
      return;
    }

    if (Object.keys(selectedClient).length === 0) {
      toast.error('Debes selecionar a quien esta asignada la tarea');
      return;
    }

    const newTask = {
      projecttaskname: data.taskName,
      projecttaskpriority: data.prioridad,
      projectid: selectedProject.crmid,
      projecttaskstatus: selectedStatus.value,
      startdate: data.fechaInicio,
      enddate: data.fechaFin,
      description: data.description,
      assigned_user_id: selectedClient.id,
      assigned_user: `${selectedClient.first_name.concat(' ', selectedClient.last_name)}`,
    };

    await createTask(newTask);
    e.target.reset();
    history.goBack();
  };


  const getAllProjects = async () => {
    if (taskProjects.length === 0) {
      const response = await taskApi.getAllProjects();
      const { success, result } = response;
      if (success && result.length) {
        setProjects(result);
        dispatch(setAllProjects(result));
      }
    }
  };

  const getAllUsers = async () => {
    if (!users || !users.length) {
      const response = await taskApi.getAllUsers();
      const { success, result } = response;
      const userList = Object.values(result);
      if (success && userList.length) {
        dispatch(setAllUsers((userList)));
      }
    }
  };


  const setActivityStatus = (status) => {
    setSelectedStatus(status);
  };

  const handleCloseSearch = () => {
    dispatch(closeModal('search'));
  };

  const openSearchDialog = (tipo) => {
    setType(tipo);
    dispatch(openModal('search'));
  };


  React.useEffect(() => {
    // Load projects
    getAllProjects();
    getAllUsers();
  }, []);

  React.useEffect(() => {
    // close modal
    handleCloseSearch();
  }, [selectedClient, selectedProject]);

  return (
    <div
      style={{
        overflow: 'auto',
        height: '100vh',
        backgroundColor: 'white',
      }}
    >
      <AppBar className="appBar" color="inherit" style={{ position: 'relative' }}>
        <Toolbar variant="dense" disableGutters elevation={0} classes={{ root: 'toolbar-container' }}>
          <div className="d-flex justify-content-start align-items-center">
            <IconButton edge="start" onClick={() => history.goBack()} aria-label="close">
              <FontAwesomeIcon style={{ fontSize: 16 }} icon={faChevronLeft} />
            </IconButton>
            <Typography variant="h6" className="toolbar-title title-type">
              Nueva actividad
            </Typography>
          </div>
          <IconButton
            color="inherit"
            size="medium"
            onClick={() => {}}
          >
            <FontAwesomeIcon className="font-size-18" style={{ width: 20 }} icon={faEllipsisV} />
          </IconButton>
        </Toolbar>
      </AppBar>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="activity-container p-3">
          <div className="activity-detail d-flex justify-content-between align-item-center mb-4">
            <div className="d-flex flex-column justify-content-start w-100 ml-2">
              <input className="input-title w-100 " type="text" placeholder="Nombre de la tarea" {...register('taskName', { required: true })} />
              {errors.taskName && <span className="text-danger ml-2">Este campo es obligatorio</span>}
            </div>
          </div>
          <div className="activity-detail d-flex justify-content-between align-item-center mb-4">
            <div className="d-flex justify-content-start align-item-center w-100 mr-2">
              <Avatar src={noImage} />
              <div className="d-flex flex-column ml-3" onClick={() => openSearchDialog('usuario')}>
                <span className="sub-title">Asignado a</span>
                <span className="title">{`${Object.keys(selectedClient).length ? selectedClient.first_name.concat(' ', selectedClient.last_name) : 'Selecciona un usuario'}`}</span>
              </div>
            </div>
          </div>

          <div className="activity-detail d-flex justify-content-between align-item-center mb-4">
            <div
              className="status-container ml-2"
              onClick={e => setAchorEl(e.currentTarget)}
            >
              <Iconwidthbg
                primaryColor={getColorTaskStatus(selectedStatus?.label)}
                secondaryColor="white"
                width={27}
                height={27}
                classes="ml-2"
                enabled
                square
                rounded
                actionClick={() => {}}
              >
                <FontAwesomeIcon style={{ fontSize: 15 }} icon={faClipboardCheck} />
              </Iconwidthbg>
              <span className="title">{selectedStatus?.label}</span>
              <FontAwesomeIcon style={{ fontSize: 15, color: '#313C52' }} icon={faChevronDown} />
            </div>
            <div className="d-flex align-item-center  custom-input-group ml-2" onClick={() => openSearchDialog('proyecto')}>
              <span className="d-flex align-items-center">{(selectedProject && Object.keys(selectedProject).length > 0) ? selectedProject.entity_label : 'Selecciona un proyecto'}</span>
            </div>
          </div>

          <div className="activity-detail d-flex justify-content-between align-item-center">
            <div className="d-flex flex-column custom-input-group mr-2" style={{ overflow: 'auto' }}>
              <span className="sub-title">Fecha inicio</span>
              <input className="title custom-input-group__inside-input w-100 " type="date" {...register('fechaInicio', { required: true })} />
            </div>
            <div className="d-flex flex-column custom-input-group" style={{ overflow: 'auto' }}>
              <span className="sub-title">Fecha fin</span>
              <input className="title custom-input-group__inside-input w-100 " type="date" {...register('fechaFin', { required: false })} />
            </div>
          </div>
          <div className="mb-4">
            {errors.fechaInicio && <span className="text-danger ml-2">El campo fecha inicio es obligatorio</span>}
            <br />
            {errors.fechaFin && <span className="text-danger ml-2">El campo fecha fin es obligatorio</span>}
          </div>
          <div className="activity-detail d-flex justify-content-between align-item-center mb-4">
            <div className="d-flex flex-column custom-input-group mr-2">
              <span className="sub-title">Prioridad</span>
              <select className="custom-input-group__inside-input w-100 " {...register('prioridad')}>
                <option value="Alto" className="title">Alta</option>
                <option value="Medio" className="title">Media</option>
                <option value="Bajo" className="title">Baja</option>
              </select>
            </div>
          </div>

          <div className="mb-3">
            <textarea className="w-100 input-title" placeholder="Descripcion" rows="5" name="description" {...register('descripcion')} />
          </div>
          <button type="submit" className="input-title w-100">Enviar</button>
        </div>
      </form>

      {/* Popover */}
      <Popover
        id="status-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAchorEl(null)}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        className="detailInfo_customer_paper"
      >
        <div
          className="detailInfo_customer_popover detailInfo_customer_popoend flex-wrap"
        >
          {
          activityStatus.map(stat => (
            <span key={`key_stat_${stat}`}>
              <Flag
                size="1x"
                icon="clipboard-check"
                ariaOwns={open ? 'status-popover' : undefined}
                color="white"
                backgroundColor={getColorTaskStatus(stat.label)}
                onClick={() => {
                  setActivityStatus(stat);
                  setAchorEl(null);
                }}
              />
              <span>
                {stat.label === 'En progreso' ? 'Progreso' : stat.label}
              </span>
            </span>
          ))
          }
        </div>
      </Popover>

      <SearchByType open={Boolean(modals.searchModalIsOpen)} handleClose={handleCloseSearch} type={type} />
    </div>

  ); };

export default AddActivity;
