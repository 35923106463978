import React from 'react';
import './Percentage.scss';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const InputPercentage = ({
  number,
  fontSize,
  maxLength,
  width,
  height,
  onChange,
  onBlur,
  onWarning,
  onDanger,
  disabledInput,
}) => (
  <div className="inputContainerP">
    <div
      className={`inputPercent ${
        onDanger && ('inputPercent_dang')
      } ${
        onWarning && ('inputPercent_warn')
      }`}
      style={{
        width,
        height,
      }}
    >
      <div className="inputPercet_space" />
      <input
        className="inputPercent_number"
        style={{
          fontSize: `${fontSize}px`,
        }}
        defaultValue={number}
       /*  onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }} */
        onFocus={(e) => {
          e.preventDefault();
          e.stopPropagation();
          e.target.select();
        }}
        onChange={(e) => {
          const { target: { value } } = e;
          onChange(value);
        }}
        onBlur={(e) => {
          const { target: { value } } = e;
          onBlur(value);
        }}
        maxLength={maxLength}
        disabled={disabledInput}
      />
      <div className="inputPercet_space">
        <FontAwesomeIcon
          icon="percent"
          style={{
            width: `${fontSize}px`,
            heigth: `${fontSize}px`,
            color: '#8051A1',
          }}
        />
      </div>
    </div>
  </div>
);

InputPercentage.propTypes = {
  number: PropTypes.number,
  fontSize: PropTypes.number,
  maxLength: PropTypes.number,
  width: PropTypes.string,
  height: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onWarning: PropTypes.bool,
  onDanger: PropTypes.bool,
  disabledInput: PropTypes.bool,
};

InputPercentage.defaultProps = {
  number: 0,
  fontSize: 18,
  maxLength: 2,
  width: '100%',
  height: '100%',
  onChange: () => {},
  onBlur: () => {},
  onWarning: false,
  onDanger: false,
  disabledInput: false,
};

export default InputPercentage;
