/* eslint-disable import/no-cycle */
import { connect } from 'react-redux';
import { fetchFde } from '../../redux/actions/orderActions';
import { closeModal } from '../../redux/actions/modalActions';
import Fde from './constructor/Fde';

const mapStateToProps = (state) => {
  const { order, loads, cart } = state;
  const { fde } = order;
  const { orderSelected } = cart;
  return ({
    loading: Boolean(loads.orderIsLoading),
    modals: state.modals,
    fde,
    orderSelected,
  });
};

const mapDispatchToProps = dispatch => ({
  dispatchCloseDiscountModal: () => dispatch(closeModal('fde')),
  dispatchFetchFde: () => dispatch(fetchFde()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Fde);
