/* eslint-disable prefer-destructuring */
/* eslint-disable no-use-before-define */
import React from 'react';
import './CountUp.styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { toggleTimer } from '../../redux/actions/taskActions';
import TaskApi from '../../api/TaskApi';

const CountUpStatic = ({ hoursMinSecs }) => {
  const taskApi = new TaskApi();
  const dispatch = useDispatch();
  const selectedTask = useSelector(state => state.tasks.selectedTask);
  let { hours = 0, minutes = 0, seconds = 0 } = hoursMinSecs;
  const [[hrs, mins, secs], setTime] = React.useState([hours, minutes, seconds]);

  const makePlay = () => {
    dispatch(toggleTimer());
    localStorage.setItem('selectedStorageTask', JSON.stringify(selectedTask));
    taskApi.startTaskTimeTracking(selectedTask.crmid);
  };

  return (
    <div className="timer-container">
      <div className="d-flex align-items-center" style={{ gap: 10 }}>
        <p>
          {`
                ${hrs.toString().padStart(2, '0')}:
                ${mins.toString().padStart(2, '0')}:
                ${secs.toString().padStart(2, '0')}
              `}
        </p>
        <FontAwesomeIcon onClick={() => makePlay()} style={{ fontSize: 20, cursor: 'pointer' }} icon={faPlay} />
      </div>
    </div>
  );
};

export default CountUpStatic;
