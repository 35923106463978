import React from 'react';
import PropTypes from 'prop-types';
/* Material */
import {} from '@material-ui/core';
import TicketsModal from './components/TicketsModal';
import TicketsModalCC from './components/TicketsModalCC';
import TicketsModalBill from './components/TicketsModalBill';

/* Components */

const Tickets = ({
  authUser,
  balance,
  cart,
  customer,
  discount,
  dispatchToggleTicketsCCModal,
  dispatchToggleTicketsFactura,
  dispatchToggleTicketsModal,
  loads,
  modals,
  tabs,
  timbrar,
  title,
  type,
}) => (
  <>
    {type === 'venta' && (
      <TicketsModal
        authUser={authUser}
        cart={cart}
        customer={customer}
        discount={discount}
        isLoading={Boolean(loads.ticketsIsLoading)}
        isOpen={Boolean(modals.ticketsModalIsOpen)}
        tabs={tabs}
        title={title}
        toggle={dispatchToggleTicketsModal}
      />
    )}
    {type === 'factura' && (
      <TicketsModalBill
        authUser={authUser}
        isLoading={Boolean(loads.ticketsIsLoading)}
        isOpen={Boolean(modals.ticketsFacturaModalIsOpen)}
        timbrar={timbrar}
        title={title}
        toggle={dispatchToggleTicketsFactura}
      />
    )}
    {type === 'CC' && (
      <TicketsModalCC
        authUser={authUser}
        balance={balance}
        isLoading={Boolean(loads.balanceIsLoading)}
        isOpen={Boolean(modals.ticketCCModalIsOpen)}
        title={title}
        toggle={dispatchToggleTicketsCCModal}
      />
    )}
  </>
);

Tickets.propTypes = {
  authUser: PropTypes.object.isRequired,
  balance: PropTypes.object,
  cart: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
  discount: PropTypes.object.isRequired,
  dispatchToggleTicketsCCModal: PropTypes.func.isRequired,
  dispatchToggleTicketsFactura: PropTypes.func.isRequired,
  dispatchToggleTicketsModal: PropTypes.func.isRequired,
  loads: PropTypes.object.isRequired,
  modals: PropTypes.object.isRequired,
  tabs: PropTypes.object.isRequired,
  timbrar: PropTypes.object,
  title: PropTypes.string,
  type: PropTypes.string,
};
Tickets.defaultProps = {
  balance: {},
  title: 'Ticket',
  type: 'venta',
  timbrar: {},
};

export default Tickets;
