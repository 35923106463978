import React from 'react';
import PropTypes from 'prop-types';
import { formatMoney } from '../../../../../helpers/tools';

import ListItemImage from '../../../../List/ListItemImage';

const ProductListItem = React.forwardRef(({ item, selectProduct }, ref) => (
  <div ref={ref} className="productMobile_list_item">
    <ListItemImage
      title={item.nombre}
      subtitle={`$ ${formatMoney(item.unit_price)}`}
      countNumber={item.existencia}
      countTitle="stock"
      image={item.image}
      showBadge={item.Products.length > 1}
      badge="ellipsis-h"
      onClick={() => selectProduct()}
    />
  </div>
));

ProductListItem.propTypes = {
  item: PropTypes.object,
  selectProduct: PropTypes.func,
};

ProductListItem.defaultProps = {
  item: {},
  selectProduct: () => {},
};

export default ProductListItem;
