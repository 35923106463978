import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';

import 'react-datepicker/dist/react-datepicker.css';

const CustomDatePicker = ({ onChange, headerName, range }) => {
  const [dateRange, setDateRange] = useState(range);
  const [startDate, endDate] = dateRange;

  useEffect(() => {
    if (range[0] !== null) {
      setDateRange(range);
    }
  }, [range]);

  return (
    <DatePicker
      selectsRange
      startDate={startDate}
      endDate={endDate}
      onChange={(update) => {
        setDateRange(update);
        onChange(headerName, update);
      }}
      dateFormat="dd/MMM/yyyy"
    />
  );
};

CustomDatePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  headerName: PropTypes.string.isRequired,
  range: PropTypes.array,
};

CustomDatePicker.defaultProps = {
  range: [null, null],
};

export default CustomDatePicker;
