import React from 'react';
import './DrawerTop.styles.scss';
import Drawer from '@material-ui/core/Drawer';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPhone, faEnvelope, faMapMarkerAlt, faPen, faShareAlt, faPenSquare,
} from '@fortawesome/free-solid-svg-icons';
import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router-dom';
import { Fab } from '@material-ui/core';
import ModalAction from '../ModalAction/ModalAction';
import WhatsapIcon from '../../../../components/Icons/WhatsappIcon';
import ShareModal from '../../../../components/Share/components/ShareModal';
import appModules from '../../../../constant/appModules';
import CustomFields from '../../../../components/customFields';

const DrawerTop = (props) => {
  const [open1, setOpen] = React.useState(false);
  const [title, setTitle] = React.useState();
  const [action, setAction] = React.useState();
  const [selectedValue, setSelectedValue] = React.useState('username@gmail.com');
  const [openShare, setOpenShare] = React.useState(false);
  const [openFields, setOpenFields] = React.useState(false);
  const loads = useSelector(state => state.loads);
  const isLoading = Boolean(loads && loads.emailTemplateIsLoading);
  const selectedClient = useSelector(state => state.client.selectClient);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleShareClose = () => {
    setOpenShare(false);
  };

  const handleShareOpen = () => {
    setOpenShare(true);
  };

  const handleFieldsOpen = () => {
    setOpenFields(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  const { open, onClose } = props;

  const handleAction = (title, action) => {
    onClose();
    setTitle(title);
    setAction(action);
    handleClickOpen();
  };

  const items = [
    {
      text: 'Llamar', icon: <FontAwesomeIcon style={{ fontSize: 22 }} icon={faPhone} />, enable: true, clickAction: () => handleAction('Llamar', 'phone'),
    },
    {
      text: 'Whatsapp', icon: <WhatsapIcon fill="white" width="22px" height="22px" />, enable: true, clickAction: () => handleAction('Llamar', 'whatsapp'),
    },
    {
      text: 'Dirección', icon: <FontAwesomeIcon style={{ fontSize: 22 }} icon={faMapMarkerAlt} />, enable: true, clickAction: () => handleAction('Dirección', 'address'),
    },
    {
      text: 'Editar cuenta', icon: <FontAwesomeIcon style={{ fontSize: 22 }} icon={faPen} />, enable: true, clickAction: () => props.history.push('/editCustomer'),
    },
    {
      text: 'Compartir', icon: <FontAwesomeIcon style={{ fontSize: 22 }} icon={faShareAlt} />, enable: true, clickAction: () => handleShareOpen(),
    },
    {
      text: 'Campos personalizados', icon: <FontAwesomeIcon style={{ fontSize: 22 }} icon={faPenSquare} />, enable: true, clickAction: () => handleFieldsOpen(),
    },
  ];


  const fullList = () => (
    <div className="pd w-100 d-flex p-3 flex-wrap align-items-center">
      {items.map((el, i) => (
        <div className="d-flex flex-column align-items-center w-25">
          <Fab
            disabled={!el.enable}
            onClick={el.clickAction}
            style={{ backgroundColor: el.enable ? '#313C52' : '#c6c6c6', color: '#ffffff' }}
          >
            {el.icon}
          </Fab>
          <div className="mt-1 font-size-12 w-80 text-center">
            {el.text}
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div>
      <Drawer
        anchor="top"
        elevation={0}
        open={open}
        onClose={onClose}
        className="mt"
        classes={{ paper: 'drawer-client-container' }}
      >
        {fullList()}
      </Drawer>
      <ModalAction selectedValue={selectedValue} open={open1} onClose={handleClose} title={title} action={action} />
      <ShareModal
        open={openShare}
        title={`Compartir cliente ${selectedClient ? selectedClient.firstname : ''}`}
        handleClose={handleShareClose}
        order={selectedClient || {}}
        template={appModules.Accounts}
        isLoading={isLoading}
      />
      <CustomFields isOpen={openFields} close={() => setOpenFields(!openFields)} module={appModules.Accounts} />
    </div>
  );
};

export default withRouter(DrawerTop);
