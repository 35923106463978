import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { formatMoney } from '../../../../../helpers/tools';
import moment from 'moment';

import IconwidthBg from '../../../../../components/Icons/IconWidthBG/IconWidthBG';
import ListItemGeneric from '../../ListItemGeneric/ListItemGeneric';

const CashFlow = ({ data }) => (
  <div className="cotizacion-container">
    {
                data ? data.map((item, i) => (
                  <ListItemGeneric
                    key={item.crmid}
                    appendIcon={item.tfde_tipo === 'Ingreso' ? (
                      <IconwidthBg primaryColor="#23a1db" width={49} height={70} classes="mr-2" square><FontAwesomeIcon className="font-size-18" icon={faSignInAlt} /></IconwidthBg>
                    ) : (
                      <IconwidthBg primaryColor="#f05b70" width={49} height={70} classes="mr-2" square><FontAwesomeIcon className="font-size-18" icon={faSignOutAlt} /></IconwidthBg>
                    )}
                    // prependIcon={<IconWidthBG size={30} enabled={false} square><FontAwesomeIcon className="font-size-18" icon={faEdit} /></IconWidthBG>}
                    titleLeft={item.documento}
                    subTitleLeft={item.cuenta}
                    titleRight={`$${formatMoney(item.monto)}`}
                    subTitleRight={moment(item.modifiedtime).format('DD MMM, h:mm a')}
                  />
                )) : null
            }

  </div>
);

CashFlow.prototype = {
  data: PropTypes.array.isRequired,
};

export default CashFlow;
