import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  IconButton,
  Menu,
  Tooltip,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButtonInput from '../../Input/IconButton';

const CustomerActions = ({
  cart,
  history,
  customer,
  dispatchRemoveCustomer,
  dispatchSelectCustomer,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const { orderSelected } = cart;
  const { selectedCustomer, isDefault } = customer;

  const enableChangeCustomer = Boolean(Object.keys(orderSelected).length === 0
    || orderSelected.estadosat === 'Sin Factura');

  const enableEditCustomer = Boolean(!isDefault && enableChangeCustomer);
  const enableRemoveCustomer = Boolean(!isDefault && Object.keys(orderSelected).length === 0);

  const rest = { width: '60px', height: '60px', iconSize: '18px' };

  return (
    <>
      <Tooltip
        title="Acciones del Cliente"
        placement="bottom"
        classes={{ tooltip: 'maxToolTip' }}
      >
        <span>
          <IconButton
            id="customer-actions-btn"
            aria-label="More"
            aria-controls="customer-actions-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <FontAwesomeIcon
              icon="user"
              style={{ width: '18px', heigth: '18px' }}
            />
          </IconButton>
        </span>
      </Tooltip>
      <Menu
        id="customer-actions-menu"
        keepMounted
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className="cart_controls"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        getContentAnchorEl={null}
      >
        <IconButtonInput
          icon="user-plus"
          label="Crear Cliente"
          enable={enableChangeCustomer}
          onClick={() => {
            history.push('/pos/addCustomer');
            handleClose();
          }}
          tooltip={enableChangeCustomer
            ? 'Crear Cliente'
            : 'Ordenes de Venta que tengan una factura no podran cambiar de cliente'
          }
          {...rest}
        />
        <IconButtonInput
          icon="user-cog"
          enable={enableEditCustomer}
          onClick={async () => {
            await history.push('/pos/editCustomer');
            await dispatchSelectCustomer(selectedCustomer);
            handleClose();
          }}
          label="Editar Cliente"
          tooltip={enableEditCustomer
            ? 'Editar Cliente'
            : 'Público en General no puede editarse al igual que con ordenes facturadas, si necesita editar al cliente dirijase a Caja'
          }
          {...rest}
        />
        <IconButtonInput
          icon="user-times"
          enable={enableRemoveCustomer}
          onClick={() => {
            dispatchRemoveCustomer();
            handleClose();
          }}
          label="Remover"
          tooltip={enableRemoveCustomer ? 'Regresar cliente a Público en General' : ''}
          {...rest}
        />
      </Menu>
    </>
  );
};

CustomerActions.propTypes = {
  cart: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  dispatchOpenModal: PropTypes.func.isRequired,
  dispatchRemoveCustomer: PropTypes.func.isRequired,
  dispatchSelectCustomer: PropTypes.func.isRequired,
};

export default withRouter(CustomerActions);
