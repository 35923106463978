/* eslint-disable no-case-declarations */
import {
  GET_ALL_TASK,
  SELECT_TASK,
  GET_TASK_DOCUMENTS,
  GET_TASK_DOCUMENTS_PAGINATOR,
  SET_PROJECT,
  SET_PROJECTS,
  SET_PROJECT_SEARCH,
  TOGGLE_TIMER,
  SET_TIMER,
  SET_TIMER_TRACKING,
  SET_USERS,
  SET_USER,
  SET_STATUS_FILTER,
  SET_PROJECT_FILTER,
  SET_USERS_FILTER,
  SET_TASK_SEARCH,
} from '../types/taskActions.type';

const INITIAL_STATE = {
  tasks: [],
  selectedTask: {},
  documentos: {
    result: [],
    pages: 0,
    count: 0,
  },
  startTimer: false,
  setTimer: null,
  projects: [],
  selectedProject: {},
  projectSearchCriteria: '',
  taskSearchCriteria: '',
  timerTracking: [],
  users: [],
  selectedUser: {},
  statusFilter: [],
  projectFilter: [],
  userFilter: [],
};

const guideReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ALL_TASK:
      return {
        ...state,
        tasks: [...action.payload],
      };

    case SELECT_TASK:
      return {
        ...state,
        selectedTask: { ...action.payload },
      };
    case GET_TASK_DOCUMENTS:
      return {
        ...state,
        documentos: { result: [...action.payload.result], pages: action.payload.pages, count: action.payload.count },
      };
    case GET_TASK_DOCUMENTS_PAGINATOR:
      return {
        ...state,
        documentos: { result: [...state.documentos.data, ...action.payload.result], pages: action.payload.pages, count: action.payload.count },
      };
    case SET_PROJECTS:
      return {
        ...state,
        projects: action.payload,
      };
    case SET_PROJECT:
      return {
        ...state,
        selectedProject: action.payload,
      };
    case SET_PROJECT_SEARCH:
      return {
        ...state,
        projectSearchCriteria: action.payload,
      };
    case SET_TASK_SEARCH:
      return {
        ...state,
        taskSearchCriteria: action.payload,
      };
    case TOGGLE_TIMER:
      return {
        ...state,
        startTimer: !state.startTimer,
      };
    case SET_TIMER:
      return {
        ...state,
        setTimer: action.payload,
      };
    case SET_TIMER_TRACKING:
      return {
        ...state,
        timerTracking: action.payload,
      };
    case SET_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case SET_USER:
      return {
        ...state,
        selectedUser: action.payload,
      };
    case SET_STATUS_FILTER:
      return {
        ...state,
        statusFilter: [...action.payload],
      };
    case SET_PROJECT_FILTER:
      return {
        ...state,
        projectFilter: [...action.payload],
      };
    case SET_USERS_FILTER:
      return {
        ...state,
        userFilter: [...action.payload],
      };

    default:
      return state;
  }
};
export default guideReducer;
