/* eslint-disable import/no-cycle */
/* eslint-disable array-callback-return */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import {
  Bookmarks, BookmarksOutlined, Label, LabelOutlined,
} from '@material-ui/icons';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {
  AppBar, Dialog, IconButton, Toolbar,
} from '@material-ui/core';
import { Slide } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getStorage } from '../../../helpers/pricebook';
import { updatePriceByPricebook } from '../../../redux/actions/cartActions';
import { addPricebook } from '../../../redux/actions/priceBookActions';
import { handleEditOrder } from '../../../redux/actions/orderActions';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const PricebookListModal = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.authUser.user);
  const cart = useSelector(state => state.cart);
  const { enableEditOrder } = useSelector(state => state.order);
  const { cartProductsByArray, orderSelected } = cart;

  const select = getStorage();
  const isSelected = Boolean(Object.keys(select).length > 3);

  const setPricebook = async (pricebook) => {
    await dispatch(addPricebook(pricebook));
  };

  const updatePricebook = async (row, pricebook) => {
    await dispatch(updatePriceByPricebook(row, pricebook));
  };

  const changeProductPrice = async (priceBook) => {
    const crmid = priceBook ? priceBook.crmid : null;
    if (cartProductsByArray.length > 0) {
      await setPricebook(priceBook);
      if (Object.keys(orderSelected).length && !enableEditOrder) {
        await dispatch(handleEditOrder());
      }
      cartProductsByArray.map((product) => {
        updatePricebook(product.row, crmid);
      });
    }
    onClose();
  };

  return (

    <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition}>
      <AppBar
        className="modalDetail_appBar"
        style={{ backgroundColor: '#ffffff' }}
        position="static"
      >
        <Toolbar className="d-flex px-0" classes={{ root: 'toolbar' }}>
          <span className="modalDetail_header ml-3">
            Cambiar lista de precios
          </span>
          <IconButton
            edge="start"
            color="inherit"
            className="ml-auto"
            aria-label="close"
            style={{ width: '48px', height: '48px' }}
            onClick={
                () => onClose()
              }
          >
            <FontAwesomeIcon color="#313C52" icon="times" />
          </IconButton>
        </Toolbar>
      </AppBar>

      <List component="nav" aria-label="contacts">
        <ListItem
          dense
          button
          onClick={() => {
            changeProductPrice(null);
          }}
        >
          <ListItemIcon>
            {!isSelected ? (<Bookmarks />) : (<BookmarksOutlined />)}
          </ListItemIcon>
          <ListItemText
            primary="Predeterminada"
          />
        </ListItem>
        {user.PriceBooks.map(item => (
          <ListItem button dense onClick={() => changeProductPrice(item)}>
            <ListItemIcon>
              {select && select.crmid === item.crmid ? <Label /> : <LabelOutlined /> }
            </ListItemIcon>
            <ListItemText primary={item.entity_label} />
          </ListItem>
        ))}
      </List>

    </Dialog>
  ); };

PricebookListModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
PricebookListModal.defaultProps = {};

export default PricebookListModal;
