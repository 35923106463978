/* eslint-disable camelcase */
/* eslint-disable import/no-cycle */
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DetailFooter from './DetailFooter';

import {
  setOrderCustomer,
} from '../../../../../../../redux/actions/orderActions';

import {
  handleMakeBill,
} from '../../../../../../../redux/actions/TimbrarActions';

import {
  makeSell,
  deliverMoney,
  changeSalesOrderStatus,
} from '../../../../../../../redux/actions/sellActions';

const mapStateToProps = (state) => {
  const {
    authUser,
    loads,
    cart,
    discount,
    customer,
    timbrar,
  } = state;
  const { user } = authUser;
  const paymentTypes = user.metodos_pagos;
  const orderStatuses = user.sostatus;
  const { sostatus, pos_checkout_bill } = user.config;
  const loading = Boolean(loads.orderIsLoading
    || loads.sellIsLoading
    || loads.checkoutIsLoading);
  return ({
    loading,
    cart,
    sostatus,
    discount,
    customer,
    timbrar,
    orderStatuses,
    paymentTypes,
    pos_checkout_bill,
  });
};

const mapDispatchToProps = dispatch => ({
  editOrder: account_id => dispatch(setOrderCustomer(account_id)),
  handleMakeBill: mode => dispatch(handleMakeBill(mode)),
  changeSalesOrderStatus: orderStatus => dispatch(changeSalesOrderStatus(orderStatus)),
  makeSell: (payments, history = () => {}) => dispatch(makeSell(payments, history)),
  deliverMoney: (payments, orderId) => dispatch(deliverMoney(payments, orderId)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DetailFooter));
