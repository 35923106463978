import React from 'react';
import PropTypes from 'prop-types';
import './ModalOption.scss';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Slide,
  Button,
  LinearProgress,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  normalizeOptions,
  selectProductOption,
  handleQtyOption,
} from '../../../../../helpers/product';

import ModalOptionItem from './ModalOptionItem';
import FloatMobile from '../../../../Input/FloatMobile';
import { setCombinedProductId } from '../../../../../redux/actions/orderActions';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const ModalOptions = ({
  authUser,
  product,
  history,
  match,
  areOrders,
  getSubProducts,
  resetState,
  loading,
  addCartProduct,
}) => {
  const dispatch = useDispatch();
  const isEditOrder = useSelector(state => state.order.isEditOrder);
  const combinedProductId = useSelector(state => state.order.combinedProductId);
  const [arr, setArr] = React.useState([]);

  const handleClose = async () => {
    if (isEditOrder && combinedProductId !== null) {
      dispatch(setCombinedProductId(null));
    } else {
      history.goBack();
    }
  };

  const initArr = (init) => {
    const _arr = normalizeOptions([...init]);
    setArr(_arr);
  };

  const selectOption = (crmid) => {
    const _arr = selectProductOption(crmid, arr);
    setArr(_arr);
  };

  const changeQtySelected = (crmid, value) => {
    const _arr = handleQtyOption(crmid, value, arr);
    setArr(_arr);
  };

  const selectSingle = item => addCartProduct(item);

  const selectBulk = async () => {
    const _arr = arr.filter(x => x.select === true);
    if (_arr && _arr.length > 0) {
      _arr.forEach(async (x) => {
        const dataset = {
          crmid: x.crmid,
          parentid: (isEditOrder && combinedProductId !== null) ? combinedProductId : match.params.id,
          quantity: x.qtySelect,
        };
        await addCartProduct({ dataset });
      });
    }
    await handleClose();
  };

  React.useEffect(() => {
    if (!loading && product.subProducts.length > 0) {
      initArr(product.subProducts);
    }
  // eslint-disable-next-line
  }, [loading, product]);

  React.useEffect(() => {
    if (isEditOrder && combinedProductId !== null) {
      getSubProducts(combinedProductId);
    } else {
      if (areOrders) resetState();
      getSubProducts(match.params.id);
    }
  // eslint-disable-next-line
  }, []);

  return (
    <Dialog
      open
      fullScreen
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <AppBar
        position="static"
        style={{ backgroundColor: '#23A1DB' }}
      >
        <Toolbar className="d-flex px-0" classes={{ root: 'toolbar' }}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            style={{ width: '48px', height: '48px' }}
          >
            <FontAwesomeIcon color="#ffffff" icon={faTimes} />
          </IconButton>
          <span className="d-flex font-size-14 align-items-end">
            {!loading && arr.length > 0 && (
              <div className="productOption_header">
                {product.productSelected.label}
              </div>
            )}
            {!loading && arr.length > 0 && (
              <span className="ml-1">opciones:</span>
            )}
          </span>
        </Toolbar>
      </AppBar>
      {loading && (
        <LinearProgress className="w-100" />
      )}
      {!loading && arr.length === 0 && (
        <div className="w-100 h-100 d-flex align-items-center justify-content-center flex-column">
          <FontAwesomeIcon color="#b1b1b1" size="3x" icon="shopping-cart" />
          <span
            className="font-size-12 align-items-center"
            style={{ color: '#b1b1b1' }}
          >
            Sin opciones de producto
          </span>
          <Button
            variant="outlined"
            onClick={() => ((isEditOrder && combinedProductId !== null) ? getSubProducts(combinedProductId) : getSubProducts(match.params.id))}
          >
            <FontAwesomeIcon color="#b1b1b1" icon="sync" />
          </Button>
        </div>
      )}
      {!loading && arr.length > 0 && (
        <div className="productOption_modal">
          {arr.map(item => (
            <ModalOptionItem
              key={`key_product_option_${item.crmid}`}
              item={item}
              parentid={(isEditOrder && combinedProductId !== null) ? combinedProductId : match.params.id}
              authUser={authUser}
              selectOption={selectOption}
              changeQtySelected={changeQtySelected}
              addCartProduct={selectSingle}
              handleClose={handleClose}
            />
          ))}
        </div>
      )}
      {!loading && arr.length > 0 && (
        <FloatMobile
          show={Boolean(arr.some(x => x.select === true))}
          onClick={() => selectBulk()}
        />
      )}
    </Dialog>
  );
};

ModalOptions.propTypes = {
  product: PropTypes.object,
  authUser: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  loading: PropTypes.bool,
  areOrders: PropTypes.bool,
  resetState: PropTypes.func,
  getSubProducts: PropTypes.func,
  addCartProduct: PropTypes.func,
};

ModalOptions.defaultProps = {
  product: {},
  authUser: {},
  history: {},
  match: {},
  loading: false,
  areOrders: false,
  resetState: () => {},
  getSubProducts: () => {},
  addCartProduct: () => {},
};

export default withRouter(ModalOptions);
