import React from 'react';
import PropTypes from 'prop-types';
/* Material */
import { Button, Collapse, LinearProgress } from '@material-ui/core';
/* Reactstrap */
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';

/* ReactToPrint */
import ReactToPrint from 'react-to-print';

import TicketCCToPrint from './tickets/TicketCCToPrint';

const TicketsModalCC = ({
  authUser,
  balance,
  isLoading,
  isOpen,
  print,
  title,
  toggle,
}) => {
  const toPrintRef = React.useRef();
  const { ticket } = balance;
  React.useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        const btnPrint = document.querySelector('#button_to_print');
        // @ts-ignore
        if (btnPrint) btnPrint.click();
      }, 500);
    }
  }, [isOpen]);
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="sm" className="tickets_modal">
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody className="tickets_modal_body w-100 d-flex justify-content-center">
        {isLoading && <LinearProgress />}
        <Collapse in={!isLoading}>
          <TicketCCToPrint
            ref={toPrintRef}
            authUser={authUser}
            data={ticket.data}
            title={`Solicitud: ${ticket.title}`}
          />
        </Collapse>
      </ModalBody>
      <ModalFooter>
        <ReactToPrint
          removeAfterPrint
          copyStyles
          trigger={() => (
            <Button
              id="button_to_print"
              color="primary"
              className="w-100"
              variant="contained"
            >
              {print}
            </Button>
          )}
          content={() => toPrintRef.current}
          onAfterPrint={() => toggle()}
        />
      </ModalFooter>
    </Modal>
  );
};

TicketsModalCC.propTypes = {
  authUser: PropTypes.object.isRequired,
  balance: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  print: PropTypes.string,
  title: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
};
TicketsModalCC.defaultProps = {
  print: 'Imprimir Ticket de Solicitud de Corte de Caja',
};

export default TicketsModalCC;
