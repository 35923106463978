import React from 'react';
import { useSelector } from 'react-redux';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import PropTypes from 'prop-types';

const ListItemSimple1 = (props) => {
  const { client, onClickItem } = props;
  const handleClick = () => {
    onClickItem(client.crmid);
  };
  const selectFilter = useSelector(state => state.filters.searchBy);

  const showClientDescriptionByFIlter = () => {
    let userFilter = '';
    switch (selectFilter) {
      case 'RFC':
        userFilter = 'RFC';
        break;
      case 'Nombre':
        userFilter = client.firstname;
        break;
      case 'Apellido':
        userFilter = client.lastname;
        break;
      case 'Razon Social':
        userFilter = client.razon_social;
        break;
      case 'Cuenta':
        userFilter = '';
        break;

      default:
        break;
    }
    return userFilter;
  }

  return (
    <>
      <ListItem button onClick={handleClick} classes={{ root: 'bg-white' }}>
        <Avatar className="bg-avatar"><span className="m-0 text-white">{client.accountname[0]}</span></Avatar>
        <ListItemText
          className="br-break"
          primary={client.accountname}
          secondary={showClientDescriptionByFIlter()}
        />
      </ListItem>
      <Divider />
    </>
  );
};

ListItemSimple1.prototype = {
  client: PropTypes.object.isRequired,
  onClickItem: PropTypes.func.isRequired,
};

export default ListItemSimple1;
