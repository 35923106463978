import React from 'react';
import './fab-btn.scss';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

const FabBtn = ({ clickAction }) => (
  <div className="fab-container">
    <Fab color="primary" aria-label="add" onClick={() => clickAction()}>
      <AddIcon />
    </Fab>
  </div>

);

export default FabBtn;
