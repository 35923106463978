import fetch from 'cross-fetch';
import ConfigurationAPI from './ConfigurationAPI';

class AddressAPI extends ConfigurationAPI {
    get = async (id) => {
      const url = `${this.url}/list/TreebesDirecciones?related_to=${id}`;
      try {
        const response = await fetch(url, this.credentials);
        const json = await response.json();
        return { ...json };
      } catch (getErr) {
        return {
          success: false,
          result: [],
          message: `Error al crear una dirección: ${getErr}`,
        };
      }
    }

    createAddress = async (body) => {
      const url = `${this.url}/TreebesDirecciones`;
      this.credentials.method = 'POST';
      this.credentials.body = JSON.stringify(body);
      try {
        const response = await fetch(url, this.credentials);
        const json = await response.json();
        return { ...json, send: body };
      } catch (err) {
        return {
          success: false,
          result: [],
          message: `Error al crear una dirección: ${err}`,
        };
      }
    };

    editAddress = async (body) => {
      const url = `${this.url}/TreebesDirecciones`;
      this.credentials.method = 'PUT';
      this.credentials.body = JSON.stringify(body);
      try {
        const response = await fetch(url, this.credentials);
        const json = await response.json();
        return { ...json };
      } catch (err) {
        return {
          success: false,
          result: [],
          message: `Error al editar una dirección: ${err}`,
        };
      }
    };

    deleteAddress = async (id) => {
      const url = `${this.url}/TreebesDirecciones/${id}`;
      this.credentials.method = 'DELETE';
      try {
        const response = await fetch(url, this.credentials);
        const json = await response.json();
        return { ...json };
      } catch (err) {
        return {
          success: false,
          result: [],
          message: `Error al eliminar una dirección: ${err}`,
        };
      }
    };
}

export default AddressAPI;
