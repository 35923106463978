/* eslint-disable import/no-cycle */
import React from 'react';
import PropTypes from 'prop-types';
import './deliver.scss';

import DeliverMda from './components/DeliverMda';
import DeliverList from './components/DeliverList';

const Deliver = ({
  loading,
  modal,
  table,
  dispatchChangeField,
}) => {
  const [openMda, setOpenMda] = React.useState(false);
  return (
    <div className="deliver_container">
      <DeliverList
        loading={loading}
        table={table}
        setFieldChange={dispatchChangeField}
      />
      <DeliverMda
        loading={loading}
        openMda={openMda}
        isShow={modal}
        setOpenMda={setOpenMda}
      />
    </div>
  );
};

Deliver.propTypes = {
  loading: PropTypes.bool.isRequired,
  modal: PropTypes.bool.isRequired,
  table: PropTypes.object.isRequired,
  dispatchChangeField: PropTypes.func.isRequired,
};

export default Deliver;
