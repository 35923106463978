import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import styles from './styles'
const ClientConfigurationMobileActionsBar = (
  {
    classes,
    history
  },
) => {
  return (
    <div
      className="main_actions_container w-100 d-flex align-items-center px-2 sticky-top"
    >
      <IconButton
        color="inherit"
        onClick={() => history.goBack()}
        className={classes.menuButton}
      >
        <ArrowBack />
      </IconButton>
      <div className="text-center font-size-16 font-weight-bold">Select Option</div>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(ClientConfigurationMobileActionsBar);
