/* eslint-disable react/prop-types */
/* eslint-disable import/no-cycle */
import React from 'react';
import { useSelector } from 'react-redux';
import {
  Collapse,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import SearchTopBar from '../SearchTopBar';
import ListData from '../ListData';
import { fetchProject } from '../../../../redux/actions/taskActions';

const Projects = ({
  type,
  fetchData,
}) => {
  const history = useHistory();
  const taskSearch = useSelector(state => state.tasks.projectSearchCriteria);
  const isAddTaskPath = history.location.pathname.includes('/actividad/add');
 /*  React.useEffect(() => {
    fetchData(0, 1, taskSearch || 'lolo');
  }, []); */

  const projects = useSelector(state => state.tasks.projects);
  const loads = useSelector(state => state.loads);
  return (
    <div className="proveedor-container" style={{ height: '94%' }}>
      <SearchTopBar type={type} />
      { loads.updateIsLoading && (
        <Collapse in={loads.updateIsLoading}>
          <LinearProgress />
        </Collapse>
      )}
      { !loads.updateIsLoading && projects.length === 0 && (
        <Collapse in={!loads.updateIsLoading && projects.length === 0} style={{ height: 'inherit', overflow: 'auto' }}>
          <List dense>
            <ListItem classes={{ root: 'bg-white' }}>
              <ListItemText
                primary="Sin proyectos"
                secondary="No se encontraron proyectos"
              />
            </ListItem>
          </List>
        </Collapse>
      )}
      { !loads.updateIsLoading && projects.length > 0 && (
        <Collapse in={!loads.updateIsLoading && projects.length > 0} style={{ height: 'inherit', overflow: 'auto' }}>
          <ListData data={projects} fetchData={fetchProject} type={type} />
        </Collapse>
      )}
    </div>
  );
};

export default Projects;
