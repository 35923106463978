/* eslint-disable react/prop-types */
import React from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const CartCurrencyMessage = ({ bank }) => {
  const authUser = useSelector(state => state.authUser);
  const { user: { currencies } } = authUser;

  const paymentMethodSelectedCurrency = currencies.find(c => c.id === bank?.currency_id);
  const [saleCurrency, setSaleCurrency] = React.useState(null);

  const getCurrencyConvertRate = () => {
    try {
      const activeCurrencyId = localStorage.getItem('currencyId');
      const activeCurrency = currencies.find(c => c.id === activeCurrencyId);

      if (activeCurrency && paymentMethodSelectedCurrency) {
        setSaleCurrency(activeCurrency);
      }
    } catch (error) {
      toast.error('Error convirtiendo montos a una unica moneda');
    }
  };

  React.useEffect(() => {
    getCurrencyConvertRate();
  }, [bank]);

  return (
    <>
      {
      saleCurrency && paymentMethodSelectedCurrency && saleCurrency.id !== paymentMethodSelectedCurrency.id && (
      <div
        className="d-flex justify-content-between align-items-center"
        style={{
          padding: '5px 10px',
          backgroundColor: '#313c52',
          borderRadius: 10,
          marginTop: 15,
          color: 'white',
        }}
      >
        <div className="d-flex justify-content-center align-items-center flex-column">
          <p>Moneda venta</p>
          <div>
            <strong>{saleCurrency.currency_code}</strong>
          </div>
        </div>
        <hr style={{ height: 26, width: 1, backgroundColor: 'white' }} />
        <div className="d-flex justify-content-center align-items-center flex-column">
          <p>Moneda Banco</p>
          <div>
            <strong>{paymentMethodSelectedCurrency.currency_code}</strong>
          </div>
        </div>
        <hr style={{ height: 26, width: 1, backgroundColor: 'white' }} />
        <div className="d-flex justify-content-center align-items-center flex-column">
          <p>Tasa de conversión</p>
          <div>
            <strong>{(paymentMethodSelectedCurrency.conversion_rate / saleCurrency.conversion_rate).toFixed(4)}</strong>
          </div>
        </div>
      </div>
      )
      }
    </>
  );
};

export default CartCurrencyMessage;
