/* eslint-disable no-return-assign */
import axios from 'axios';
import { useEffect, useState } from 'react';
import ConfigurationAPI from '../api/ConfigurationAPI';

const usePagination = (url, page = 1, search = '') => {
  const requestConfig = new ConfigurationAPI();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [result, setResult] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => { setResult([])}, [search]);

  useEffect(() => {
    setLoading(true);
    setError(false);
    let cancel;
    axios({
      ...requestConfig.credentials,
      params: { page, search },
      url: `${requestConfig.url}/${url}`,
      cancelToken: new axios.CancelToken(c => cancel = c),
    })
      .then((res) => {
        setResult([...result, ...res.data.result]);
        setLoading(false);
        setHasMore(page < res.data.pages);
      })
      .catch((e) => {
        setLoading(false);
        if (axios.isCancel(e)) {
          // eslint-disable-next-line no-useless-return
          return;
        }
      });
    return () => cancel();
  }, [url, page]);

  return {
    loading, error, result, hasMore,
  };
};

export default usePagination;
