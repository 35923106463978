/* eslint-disable react/prop-types */
import {
  Checkbox, Collapse, Divider, List, ListItem, ListItemText, Typography,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { formatMoney } from '../../../../../../helpers/tools';
import CCMovimiento from '../movimiento';
import DocumentItems from './document-items';

const DocType = ({
  cantidad,
  click,
  enable,
  id,
  label,
  totalCheck,
  movimientos,
  type,
}) => {
  const [open, setOpen] = React.useState(false);
  const [countCheck, setCountCheck] = React.useState(0);
  /* const calculateTotalDiscount = () => {
    let details = [];
    let total = 0;

    if (movimientos.length > 0) {
      movimientos.forEach((m) => {
        details = [...details, ...m.detalles];
      });

      details.map((d) => {
        total += parseFloat(formatMoney(d.sum_discount));
      });
    }

    return formatMoney(total);
  }; */
  const checkedItems = () => {
    let count = 0;
    movimientos.forEach((mov) => {
      if (mov.enable === true) {
        count += 1;
      }
    });
    setCountCheck(count);
  };

  useEffect(() => {
    checkedItems();
  }, [movimientos]);


  return (
    <List key={id}>
      <ListItem
        button
        className="detalle_box detalle_box_divider"
        onClick={() => setOpen(!open)}
      >
        {open ? (<ExpandLess />) : (<ExpandMore />)}
        <ListItemText
          primary={(
            <>
              <Typography component="span" variant="body2" color="textPrimary">
                <div className="balance-detalles__flex-space">
                  <strong>{label}</strong>
                  <span className="detalle_cantidad">{`${countCheck} / ${cantidad}`}</span>
                  {/* <strong>{`$ ${formatMoney(totalCheck)}`}</strong> */}
                </div>
              </Typography>
            </>
                    )}
          secondary={(
            <>
              <Typography component="span" variant="body2" color="textPrimary">
                <div className="balance-detalles__flex-space">
                  <strong> </strong>
                  {/* <strong>{calculateTotalDiscount()}</strong> */}
                </div>
              </Typography>
            </>
                    )}
        />
        <Checkbox
          data-id={id}
          data-kind={type}
          checked={Boolean(enable)}
          onClick={(e) => {
            const { currentTarget } = e;
            click(currentTarget);
            e.preventDefault();
            e.stopPropagation();
          }}
          className="balance-detalles__no-padding-lr"
        />
      </ListItem>
      <Collapse in={open} timeout="auto" className="detalle_box-wrapper">
        <DocumentItems
          movimientos={movimientos}
          click={click}
        />
      </Collapse>
      <Divider className="my-1" />
    </List>
  ); };

export default DocType;
